export async function fetchParts() {
  try {
    // const url = 'https://layout2d-api-dev.zipcpq.com/api/v1/objects/parts/batch/read';
    const url = 'https://layout2d-api-dev.zipcpq.com/api/v1/parts/batch/read';
    const data = { approved: false };

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if required
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    return responseData; // Array of objects
  } catch (error) {
    console.error('Error:', error);
    return [];
  }
}

// const fetchParts = [
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-51+.pdf",
//     description: "F=50M-4G G=16.1dB P1dB=18 IP3=35 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a111-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-51%2B",
//     mfgPart: "Gali-51+SMT",
//     models: "this part is missing models",
//     pn: "XM-A111-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 4000, 16.1, 3.5, 18, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-6+.pdf",
//     description: "F=50M-4G G=11.8dB P1dB=18.2 IP3=35.5 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a113-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-6F%2B",
//     mfgPart: "Gali-6+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A113D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A113-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 4000, 11.8, 4.5, 18.2, null, 35.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-84+.pdf",
//     description: "F=50M-6G G=25.6dB P1dB=20.9 IP3=38 NF=4.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a114-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GALI-S66%2B",
//     mfgPart: "Gali-84+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A114D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A114-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [50, 6000, 25.6, 4.4, 20.9, null, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0001",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ERA-2SM+.pdf",
//     description: "F=50M-6G G=14.9dB P1dB=13 IP3=29 NF=3.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0001H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a115-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ERA-4SM%2B",
//     mfgPart: "ERA-2SM+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A115D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A115-0404D",
//     price: 8200,
//     priceType: "cents",
//     props: [50, 6000, 14.9, 3.3, 13, null, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0001_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0001",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ERA-5SM+.pdf",
//     description: "F=50M-4G G=17.6dB P1dB=17 IP3=30 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0001H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a116-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ERA-6SM%2B",
//     mfgPart: "ERA-5SM+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A116D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A116-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [50, 4000, 17.6, 3.5, 17, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0001_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0001",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ERA-9SM+.pdf",
//     description: "F=50M-8G G=8.4dB P1dB=14.1 IP3=25.4 NF=5.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0001H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a117-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-1+2B",
//     mfgPart: "ERA-9SM+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A117D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A117-0404D",
//     price: 8200,
//     priceType: "cents",
//     props: [50, 8000, 8.4, 5.3, 14.1, null, 25.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0001_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0001",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ERA-21SM+.pdf",
//     description: "F=50M-8G G=12.2dB P1dB=12.6 IP3=27 NF=3.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0001H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a118-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ERA-2SM+2B",
//     mfgPart: "ERA-21SM+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A118D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A118-0404D",
//     price: 8200,
//     priceType: "cents",
//     props: [50, 8000, 12.2, 3.4, 12.6, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0001_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0002",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000094",
//     description: "F=50M-12G G=12dB P1dB=13 IP3=27.1 NF=5.1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0002K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a119-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/NBB-310",
//     mfgPart: "NBB-300SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A119D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A119-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [50, 12000, 12, 5.1, 13, null, 27.1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0002_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0002",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000100",
//     description: "F=50M-12G G=13dB P1dB=13.8 IP3=24 NF=4.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0002K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a121-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/NBB-312",
//     mfgPart: "NBB-310SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A121D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A121-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [50, 12000, 13, 4.9, 13.8, null, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0002_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0002",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000105",
//     description: "F=50M-8G G=15.5dB P1dB=14.6 IP3=28.1 NF=4.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0002K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a122-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA2213",
//     mfgPart: "NBB-400SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A122D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A122-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [50, 8000, 15.5, 4.3, 14.6, null, 28.1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0002_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-1+.pdf",
//     description: "F=50M-8G G=11.3dB P1dB=12.2 IP3=27 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a135-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GALI-19+2B",
//     mfgPart: "Gali-1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A135D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A135-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 8000, 11.3, 4.5, 12.2, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-19+.pdf",
//     description: "F=50M-7G G=10.7dB P1dB=10.6 IP3=23.7 NF=6.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a136-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-2+2B",
//     mfgPart: "Gali-19+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A136D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A136-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 7000, 10.7, 6.5, 10.6, null, 23.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-21+.pdf",
//     description: "F=50M-8G G=11.5dB P1dB=12.6 IP3=27 NF=4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a137-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-24+2B",
//     mfgPart: "Gali-21+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A137D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A137-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 8000, 11.5, 4, 12.6, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-24+.pdf",
//     description: "F=50M-6G G=16.6dB P1dB=19.3 IP3=35.3 NF=4.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a138-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-29+2B",
//     mfgPart: "Gali-24+SMT",
//     models: "this part is missing models",
//     pn: "XM-A138-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 6000, 16.6, 4.3, 19.3, null, 35.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-29+.pdf",
//     description: "F=50M-7G G=13.7dB P1dB=11.2 IP3=24.7 NF=6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a139-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-3+2B",
//     mfgPart: "Gali-29+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A139D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A139-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 7000, 13.7, 6, 11.2, null, 24.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-3+.pdf",
//     description: "F=50M-3G G=19.1dB P1dB=12.5 IP3=25 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a141-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-33+2B",
//     mfgPart: "Gali-3+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A141D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A141-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 3000, 19.1, 3.5, 12.5, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-33+.pdf",
//     description: "F=50M-4G G=17.5dB P1dB=13.4 IP3=28 NF=3.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a142-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-39+2B",
//     mfgPart: "Gali-33+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A142D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A142-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 4000, 17.5, 3.9, 13.4, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-39+.pdf",
//     description: "F=50M-7G G=17.0dB P1dB=10.5 IP3=22.9 NF=2.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a143-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GALI-4+2B",
//     mfgPart: "Gali-39+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A143D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A143-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 7000, 17, 2.4, 10.5, null, 22.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-49+.pdf",
//     description: "F=50M-5G G=12.9dB P1dB=16.4 IP3=33.3 NF=4.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a144-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GALI-4F+2B",
//     mfgPart: "Gali-49+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A144D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A144-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 5000, 12.9, 4.4, 16.4, null, 33.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-5+.pdf",
//     description: "F=50M-4G G=17.5dB P1dB=18.0 IP3=35 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a145-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-51+2BB",
//     mfgPart: "Gali-5+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A145D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A145-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 4000, 17.5, 3.5, 18, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-51F+.pdf",
//     description: "F=50M-4G G=15.9dB P1dB=15.9 IP3=32 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a146-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-52+2B",
//     mfgPart: "Gali-51F+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A146D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A146-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 4000, 15.9, 3.5, 15.9, null, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-52+.pdf",
//     description: "F=50M-2G G=20.8dB P1dB=15.5 IP3=32 NF=2.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a147-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-55+2B",
//     mfgPart: "Gali-52+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A147D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A147-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 2000, 20.8, 2.7, 15.5, null, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-55+.pdf",
//     description: "F=50M-4G G=18.5dB P1dB=15.0 IP3=28.5 NF=3.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a148-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-59+2B",
//     mfgPart: "Gali-55+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A148D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A148-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 4000, 18.5, 3.3, 15, null, 28.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-59+.pdf",
//     description: "F=50M-5G G=16.7dB P1dB=17.6 IP3=33.3 NF=4.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a149-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GALI-5F+2B",
//     mfgPart: "Gali-59+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A149D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A149-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 5000, 16.7, 4.3, 17.6, null, 33.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-6F+.pdf",
//     description: "F=50M-4G G=11.6dB P1dB=15.8 IP3=35.5 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a151-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-74+2B",
//     mfgPart: "Gali-6F+SMT",
//     models: "this part is missing models",
//     pn: "XM-A151-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 4000, 11.6, 4.5, 15.8, null, 35.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-74+.pdf",
//     description: "F=50M-1G G=21.8dB P1dB=19.2 IP3=33 NF=2.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a152-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-84+2B",
//     mfgPart: "Gali-74+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A152D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A152-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [50, 1000, 21.8, 2.7, 19.2, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0001",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ERA-4SM+.pdf",
//     description: "F=50M-4G G=13.0dB P1dB=16.1 IP3=35 NF=4.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0001H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a157-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ERA-50SM+2B",
//     mfgPart: "ERA-4SM+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A157D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A157-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [50, 4000, 13, 4.2, 16.1, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0001_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0001",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ERA-6SM+.pdf",
//     description: "F=50M-4G G=11.7dB P1dB=17.1 IP3=33 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0001H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a158-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ERA-9SM+2B",
//     mfgPart: "ERA-6SM+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A158D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A158-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [50, 4000, 11.7, 4.5, 17.1, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0001_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0844",
//     catId: "AMP",
//     ctlBoard: "XM-A161-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc994apm5e.pdf",
//     description: "F=50M-28G G=15dB P1dB=28 IP3=38 NF=3.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0844A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a266-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/power-amplifiers/hmc994apm5e.html",
//     mfgPart: "HMC994APM5ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A266D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A266-0804D",
//     price: 55900,
//     priceType: "cents",
//     props: [50, 28000, 15, 3.5, 28, null, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0844_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0003",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc441lc3b.pdf",
//     description: "F=6G-18G G=14dB P1dB=20 IP3=32 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0003J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2a3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/driver-amplifiers/hmc441lc3b.html",
//     mfgPart: "HMC441LC3BSMT",
//     models: "this part is missing models",
//     pn: "XM-A2A3-0404D",
//     price: 16700,
//     priceType: "cents",
//     props: [6000, 18000, 14, 4.5, 20, null, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0003_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0278",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc451lc3.pdf",
//     description: "F=5G-20G G=19dB P1dB=19 IP3=30 NF=6.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0278F.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2a4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/driver-amplifiers/hmc451lc3.html",
//     mfgPart: "HMC451LC3SMT",
//     models: "this part is missing models",
//     pn: "XM-A2A4-0404D",
//     price: 16400,
//     priceType: "cents",
//     props: [5000, 20000, 19, 6.5, 19, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0278_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0005",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc606lc5.pdf",
//     description: "F=2G-18G G=13.5dB P1dB=15 IP3=27 NF=7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0005I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2a5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/wideband-distributed-amplifiers/hmc606lc5.html",
//     mfgPart: "HMC606LC5SMT",
//     models: "this part is missing models",
//     pn: "XM-A2A5-0404D",
//     price: 29800,
//     priceType: "cents",
//     props: [2000, 18000, 13.5, 7, 15, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0005_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0145",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000104",
//     description: "F=50M-12G G=12.9dB P1dB=14.9 IP3=24 NF=4.9 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0145G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2a6-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/NBB-400",
//     mfgPart: "NBB-312SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2A6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2A6-0604D",
//     price: 16200,
//     priceType: "cents",
//     props: [50, 12000, 12.9, 4.9, 14.9, null, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0107",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000104",
//     description: "F=50M-12G G=12.9dB P1dB=14.9 IP3=24 NF=4.9 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0107G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2a7-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/NBB-400",
//     mfgPart: "NBB-312SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2A7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2A7-0604D",
//     price: 14000,
//     priceType: "cents",
//     props: [50, 12000, 12.9, 4.9, 14.9, null, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0136",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CMA-545+.pdf",
//     description: "F=50M-6G G=20dB P1dB=20.3 IP3=35 NF=1.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0136I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2n4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CMA-545G1+2B",
//     mfgPart: "CMA-545+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2N4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2N4-0404D",
//     price: 9200,
//     priceType: "cents",
//     props: [50, 6000, 20, 1.3, 20.3, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0136_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0124",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LVA-123+.pdf",
//     description: "F=10M-12G G=17.3dB P1dB=13.3 IP3=23.6 NF=4.1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0124G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2t1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MAR-1SM+2B",
//     mfgPart: "LVA-123+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2T1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2T1-0404D",
//     price: 8900,
//     priceType: "cents",
//     props: [10, 12000, 17.3, 4.1, 13.3, null, 23.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0124_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0130",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MERA-533+.pdf",
//     description: "F=50M-4G G=20.5dB P1dB=16.7 IP3=33 NF=3.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0130I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2t2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MGVA-63+2B",
//     mfgPart: "MERA-533+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2T2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2T2-0604D",
//     price: 11600,
//     priceType: "cents",
//     props: [50, 4000, 20.5, 3.5, 16.7, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0130_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0106",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da000104",
//     description: "F=50M-2.7G G=12.9dB P1dB=14.9 IP3=24 NF=4.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0106K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a312-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/NBB-400",
//     mfgPart: "NBB-312SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A312D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A312-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [50, 2700, 12.9, 4.9, 14.9, null, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0106_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0205",
//     catId: "AMP",
//     ctlBoard: "XM-A2J6-0404D",
//     datasheetURL: "https://assets.apitech.com/amplifiers/TM6509.pdf",
//     description: "F=5M-500M G=14.5dB P1dB=23 IP3=36 NF=4.6 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0205L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a314-0804d/",
//     mfgCode: "APITE",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TN6509PMSMT",
//     models: "this part is missing models",
//     pn: "XM-A314-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 500, 14.5, 4.6, 23, null, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0205_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0961",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc3653.pdf",
//     description: "F=7G-15G G=15dB P1dB=15 IP3=28 NF=5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0961A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a339-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/hmc3653.html",
//     mfgPart: "HMC3653LP3BESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A339D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A339-0404D",
//     price: 12700,
//     priceType: "cents",
//     props: [7000, 15000, 15, 5, 15, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0961_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0134-L",
//     catId: "AMP",
//     ctlBoard: "XM-A378-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc797apm5e.pdf",
//     description: "F=50M-22G G=15dB P1dB=29 IP3=41 NF=3.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0844A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a342-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/power-amplifiers/hmc797apm5e.html",
//     mfgPart: "HMC797APM5ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A342D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A342-0804D",
//     price: 53500,
//     priceType: "cents",
//     props: [50, 22000, 15, 3.5, 29, null, 41],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0844_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0236",
//     catId: "AMP",
//     ctlBoard: "XM-A344-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc463lp5.pdf",
//     description: "F=2G-20G G=13dB P1dB=16 IP3=24 NF=2.8 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0236K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a343-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/wideband-distributed-amplifiers/hmc463lp5.html",
//     mfgPart: "HMC463LP5ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A343D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A343-0604D",
//     price: 37300,
//     priceType: "cents",
//     props: [2000, 20000, 13, 2.8, 16, null, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0236_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1989",
//     catId: "AMP",
//     ctlBoard: "XM-A4E3-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAM-011101.pdf",
//     description: "F=4G-20G G=16dB P1dB=19 IP3=30 NF=4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0232I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a345-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAM-011101",
//     mfgPart: "MAAM-011101SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A345D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A345-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [4000, 20000, 16, 4, 19, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1989_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-123+.pdf",
//     description: "F=10M-12G G=16.9dB P1dB=13.4 IP3=24.5 NF=4.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a358-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-60+2B",
//     mfgPart: "GVA-123+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A358D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A358-0404D",
//     price: 9200,
//     priceType: "cents",
//     props: [10, 12000, 16.9, 4.4, 13.4, null, 24.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0450",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc519.pdf",
//     description: "F=18G-31G G=14dB P1dB=11 IP3=23 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0450B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a373-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifier/hmc519lc4.html",
//     mfgPart: "HMC519LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A373D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A373-0404D",
//     price: 19800,
//     priceType: "cents",
//     props: [18000, 31000, 14, 3.5, 11, null, 23],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0450_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0278",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc451lp3.pdf",
//     description: "F=5G-18G G=18dB P1dB=19.5 IP3=28 NF=7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0278F.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a376-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/driver-amplifiers/hmc451lp3.html",
//     mfgPart: "HMC451LP3ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A376D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A376-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [5000, 18000, 18, 7, 19.5, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0278_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0134",
//     catId: "AMP",
//     ctlBoard: "XM-A384-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc465.pdf",
//     description: "F=50M-20G G=15dB P1dB=23 IP3=28 NF=3 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0134M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a383-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/wideband-distributed-amplifiers/hmc465lp5.html",
//     mfgPart: "HMC465LP5ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A383D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A383-0804D",
//     price: 85200,
//     priceType: "cents",
//     props: [50, 20000, 15, 3, 23, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0134_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0450",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc383.pdf",
//     description: "F=12G-30G G=15dB P1dB=15 IP3=25 NF=8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0450B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3a3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/driver-amplifiers/hmc383lc4.html",
//     mfgPart: "HMC383LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3A3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3A3-0404D",
//     price: 18700,
//     priceType: "cents",
//     props: [12000, 30000, 15, 8, 15, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0450_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0450",
//     catId: "AMP",
//     ctlBoard: "XM-A6E9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc963.pdf",
//     description: "F=6G-26.5G G=22dB P1dB=10 IP3=18 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0450B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3a4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/hmc963.html",
//     mfgPart: "HMC963LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3A4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3A4-0404D",
//     price: 17100,
//     priceType: "cents",
//     props: [6000, 26500, 22, 2.5, 10, null, 18],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0450_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0317",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc464lp5e.pdf",
//     description: "F=2G-20G G=14dB P1dB=25 IP3=26 NF=4 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0317D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3b1-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/wideband-distributed-amplifiers/hmc464lp5.html",
//     mfgPart: "HMC464LP5ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3B1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3B1-0804D",
//     price: 62200,
//     priceType: "cents",
//     props: [2000, 20000, 14, 4, 25, null, 26],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0269",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/06/AM1016B-Datasheet-Latest.pdf",
//     description: "F=20M-6G G=14dB P1dB=17 IP3=33 NF=2.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0269E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3b4-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1016b/",
//     mfgPart: "AM1016BSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3B4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3B4-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [20, 6000, 14, 2.3, 17, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0269_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0269",
//     catId: "AMP",
//     ctlBoard: "XM-A4E3-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/12/AM1025B-Datasheet-Latest.pdf",
//     description: "F=20M-3G G=13.5dB P1dB=26 IP3=40 NF=3.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0269E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3b9-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1025b/",
//     mfgPart: "AM1025BSMT",
//     models: "this part is missing models",
//     pn: "XM-A3B9-0404D",
//     price: 10900,
//     priceType: "cents",
//     props: [20, 3000, 13.5, 3.8, 26, null, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0269_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0318",
//     catId: "AMP",
//     ctlBoard: "XM-A3C3-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc460lc5.pdf",
//     description: "F=50M-20G G=14dB P1dB=16.5 IP3=29 NF=2.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0318E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3c2-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/wideband-distributed-amplifiers/hmc460lc5.html",
//     mfgPart: "HMC460LC5SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3C2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3C2-0604D",
//     price: 35600,
//     priceType: "cents",
//     props: [50, 20000, 14, 2.5, 16.5, null, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0316",
//     catId: "AMP",
//     ctlBoard: "XM-A3C5-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd163c4_ver_1.3_1017.pdf",
//     description: "F=17G-27G G=23dB P1dB=17.5 IP3=25 NF=1.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0316E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3c4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd163c4_low_noise_amplifier/",
//     mfgPart: "CMD163C4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3C4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3C4-0404D",
//     price: 22400,
//     priceType: "cents",
//     props: [17000, 27000, 23, 1.7, 17.5, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0278",
//     catId: "AMP",
//     ctlBoard: "XM-A2J7-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1040.pdf",
//     description: "F=24G-43.5G G=23dB P1dB=12 IP3=22 NF=2.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0278F.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3e1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc1040lp3ce.html",
//     mfgPart: "HMC1040LP3CESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3E1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3E1-0404D",
//     price: 16500,
//     priceType: "cents",
//     props: [24000, 43500, 23, 2.2, 12, null, 22],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0278_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0134",
//     catId: "AMP",
//     ctlBoard: "XM-A379-0804D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd192c5-0418.pdf",
//     description: "F=50M-20G G=19dB P1dB=25 IP3=33 NF=1.9 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0134M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3e6-0804d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd192c5_distributed_amplifier/",
//     mfgPart: "CMD192C5SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3E6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3E6-0804D",
//     price: 50200,
//     priceType: "cents",
//     props: [50, 20000, 19, 1.9, 25, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0134_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0313",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MNA-2A+.pdf",
//     description: "F=500M-2.5G G=15.2dB P1dB=18.6 IP3=31 NF=5.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0313C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3h6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MNA-4A+2B",
//     mfgPart: "MNA-2A+SMT",
//     models: "this part is missing models",
//     pn: "XM-A3H6-0404D",
//     price: 7300,
//     priceType: "cents",
//     props: [500, 2500, 15.2, 5.4, 18.6, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0313_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0313",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MNA-4A+.pdf",
//     description: "F=500M-2.5G G=17.8dB P1dB=18.6 IP3=30.8 NF=4.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0313C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3h7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MNA-4W+2B",
//     mfgPart: "MNA-4A+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3H7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3H7-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [500, 2500, 17.8, 4.4, 18.6, null, 30.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0313_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0313",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MNA-4W+.pdf",
//     description: "F=500M-4.5G G=17dB P1dB=16.8 IP3=27.9 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0313C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3h8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MNA-6A+2B",
//     mfgPart: "MNA-4W+SMT",
//     models: "this part is missing models",
//     pn: "XM-A3H8-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [500, 4500, 17, 4.5, 16.8, null, 27.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0313_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0313",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MNA-6A+.pdf",
//     description: "F=500M-2.5G G=25dB P1dB=20.2 IP3=32.4 NF=2.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0313C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3h9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MNA-6W+2B",
//     mfgPart: "MNA-6A+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3H9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3H9-0404D",
//     price: 7500,
//     priceType: "cents",
//     props: [500, 2500, 25, 2.6, 20.2, null, 32.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0313_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0313",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MNA-6W+.pdf",
//     description: "F=500M-5.5G G=23.2dB P1dB=19.2 IP3=30 NF=2.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0313C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3j1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PGA-102+2B",
//     mfgPart: "MNA-6W+SMT",
//     models: "this part is missing models",
//     pn: "XM-A3J1-0404D",
//     price: 7500,
//     priceType: "cents",
//     props: [500, 5500, 23.2, 2.7, 19.2, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0313_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0314",
//     catId: "AMP",
//     ctlBoard: "XM-A7F7-0604D",
//     datasheetURL: "https://www.qorvo.com/products/d/da004103",
//     description: "F=2G-20G G=17dB P1dB=19 IP3=29 NF=2 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0314C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3j2-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA2597-SM",
//     mfgPart: "TGA2567-SMSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3J2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3J2-0604D",
//     price: 34300,
//     priceType: "cents",
//     props: [2000, 20000, 17, 2, 19, null, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0314_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0315",
//     catId: "AMP",
//     ctlBoard: "XM-C121-0810D",
//     datasheetURL: "https://www.qorvo.com/products/d/da003927",
//     description: "F=2G-18G G=22dB ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0315B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3j3-0810d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA2222",
//     mfgPart: "TGA2214-CPFlange",
//     models: "this part is missing models",
//     pn: "XM-A3J3-0810D",
//     price: 188500,
//     priceType: "cents",
//     props: [2000, 18000, 22],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0315_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0450",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc564lc4.pdf",
//     description: "F=7G-14G G=17dB P1dB=13 IP3=25 NF=1.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0450B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3k4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifier/hmc564lc4.html",
//     mfgPart: "HMC564LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3K4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3K4-0404D",
//     price: 15300,
//     priceType: "cents",
//     props: [7000, 14000, 17, 1.8, 13, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0450_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0633",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc516.pdf",
//     description: "F=9G-18G G=20dB P1dB=14 IP3=25 NF=2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0633A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3k5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifier/hmc516lc5.html",
//     mfgPart: "HMC516LC5SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3K5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3K5-0404D",
//     price: 16400,
//     priceType: "cents",
//     props: [9000, 18000, 20, 2, 14, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0633_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0142",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000104",
//     description: "F=50M-12G G=12.9dB P1dB=14.9 IP3=24 NF=4.9 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0142I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3l6-0804d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/NBB-400",
//     mfgPart: "NBB-312SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3L6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3L6-0804D",
//     price: 24900,
//     priceType: "cents",
//     props: [50, 12000, 12.9, 4.9, 14.9, null, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0936",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PMA3-83LN+.pdf",
//     description: "F=500M-8G G=21.5dB P1dB=20.7 IP3=34 NF=1.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0936B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3r4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA3-83LN-DG+2B",
//     mfgPart: "PMA3-83LN+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3R4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3R4-0404D",
//     price: 9400,
//     priceType: "cents",
//     props: [500, 8000, 21.5, 1.5, 20.7, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0936_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0419",
//     catId: "AMP",
//     ctlBoard: "XM-A3U6-0804D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAM-011109.pdf",
//     description: "F=10M-40G G=13dB P1dB=18 IP3=24 NF=3.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0419B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3u5-0804d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAM-011109",
//     mfgPart: "MAAM-011109SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3U5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3U5-0804D",
//     price: 23800,
//     priceType: "cents",
//     props: [10, 40000, 13, 3.5, 18, null, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0403",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAL-011078.pdf",
//     description: "F=700M-6G G=22dB P1dB=17.5 IP3=33 NF=.35 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0403C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3u8-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAL-011078",
//     mfgPart: "MAAL-011078SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3U8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3U8-0404D",
//     price: 14600,
//     priceType: "cents",
//     props: [700, 6000, 22, 0.35, 17.5, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0403_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5545.PDF",
//     description: "F=30M-6G G=24.1dB P1dB=18.1 IP3=36.4 NF=2.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3v1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/gain-blocks/adl5545.html",
//     mfgPart: "ADL5545ARKZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3V1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3V1-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [30, 6000, 24.1, 2.9, 18.1, null, 36.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0205",
//     catId: "AMP",
//     ctlBoard: "XM-A2J6-0404D",
//     datasheetURL: "0",
//     description: "F=10M-1G G=13dB P1dB=24 IP3=32 NF=5.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0205L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3w9-0804d/",
//     mfgCode: "APITE",
//     mfgLandingPage:
//       "https://www.rfmw.com/products/detail/hmmc5628tr1-keysight-technologies/679179/?pid=",
//     mfgPart: "QBH-5024PMSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3W9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3W9-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [10, 1000, 13, 5.5, 24, null, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0205_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0404",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAL-011129.pdf",
//     description: "F=18G-31.5G G=23dB P1dB=16 IP3=25 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0404C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3y1-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAL-011129",
//     mfgPart: "MAAL-011129SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3Y1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3Y1-0404D",
//     price: 14200,
//     priceType: "cents",
//     props: [18000, 31500, 23, 2.5, 16, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0404_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0403",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAL-011130.pdf",
//     description: "F=2.4G-18.5G G=21dB P1dB=14 IP3=20 NF=1.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0403C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3y2-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAL-011130",
//     mfgPart: "MAAL-011130SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3Y2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3Y2-0404D",
//     price: 15900,
//     priceType: "cents",
//     props: [2400, 18500, 21, 1.4, 14, null, 20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0403_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0205",
//     catId: "AMP",
//     ctlBoard: "XM-A2J6-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/A87_SMA87.pdf",
//     description: "F=10M-400M G=16dB P1dB=17 IP3=31 NF=3.4 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0205L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3y3-0804d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SMA87",
//     mfgPart: "SMA87SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3Y3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3Y3-0804D",
//     price: 83000,
//     priceType: "cents",
//     props: [10, 400, 16, 3.4, 17, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0205_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0205",
//     catId: "AMP",
//     ctlBoard: "XM-A2J6-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/A89_SMA89.pdf",
//     description: "F=100M-800M G=22dB P1dB=17.5 IP3=30 NF=4.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0205L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3y4-0804d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SMA89",
//     mfgPart: "SMA89SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3Y4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3Y4-0804D",
//     price: 57100,
//     priceType: "cents",
//     props: [100, 800, 22, 4.5, 17.5, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0205_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0205",
//     catId: "AMP",
//     ctlBoard: "XM-A2J6-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/A181_SMA181.pdf",
//     description: "F=10M-250M G=16.5dB P1dB=22 IP3=35 NF=3.8 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0205L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3y5-0804d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SMA181",
//     mfgPart: "SMA181SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3Y5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3Y5-0804D",
//     price: 114000,
//     priceType: "cents",
//     props: [10, 250, 16.5, 3.8, 22, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0205_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0205",
//     catId: "AMP",
//     ctlBoard: "XM-A2J6-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/A19-1_SMA19-1.pdf",
//     description: "F=10M-1G G=11.5dB P1dB=22.5 IP3=35 NF=5.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0205L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3y6-0804d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SMA19-1",
//     mfgPart: "SMA19-1SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3Y6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3Y6-0804D",
//     price: 45200,
//     priceType: "cents",
//     props: [10, 1000, 11.5, 5.5, 22.5, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0205_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0205",
//     catId: "AMP",
//     ctlBoard: "XM-A2J6-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/A29-1_SMA29-1.pdf",
//     description: "F=10M-1.5G G=9dB P1dB=22 IP3=32 NF=5.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0205L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3y7-0804d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SMA29-1",
//     mfgPart: "SMA29-1SMT",
//     models: "this part is missing models",
//     pn: "XM-A3Y7-0804D",
//     price: 48500,
//     priceType: "cents",
//     props: [10, 1500, 9, 5.5, 22, null, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0205_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1952",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/adl8104.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a436-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl8104.html",
//     mfgPart: "ADL8104SMT",
//     models: "this is the new part",
//     pn: "XM-A436-0404D",
//     price: 26100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1952_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0508",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAM-011229.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a486-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAM-011229",
//     mfgPart: "MAAL-011229SMT",
//     models: "this is the new part",
//     pn: "XM-A486-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0508_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1895",
//     catId: "AMP",
//     ctlBoard: "XM-A494-0304D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc8412.pdf",
//     description: "F=400M-11G G=15.5dB P1dB=18 IP3=33 NF=1.4 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1895A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a493-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc8412.html",
//     mfgPart: "HMC8412LP2FESMT",
//     models: "this part is missing models",
//     pn: "XM-A493-0304D",
//     price: 17000,
//     priceType: "cents",
//     props: [400, 11000, 15.5, 1.4, 18, 20.5, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1895_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0408",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAM-011206.pdf",
//     description: "F=50M-15G G=13.5dB P1dB=18 IP3=28 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0408C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4d6-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAM-011206",
//     mfgPart: "MAAM-011206SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4D6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4D6-0404D",
//     price: 15700,
//     priceType: "cents",
//     props: [50, 15000, 13.5, 4.5, 18, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0560",
//     catId: "AMP",
//     ctlBoard: "XM-B1C1-0204D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc341chips.pdf",
//     description: "F=24G-30G G=13dB P1dB=6 IP3=16 NF=2.5 ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0560A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4f1-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc341-die.html",
//     mfgPart: "HMC341DIE",
//     models: "this part is missing models",
//     pn: "XM-A4F1-0204D",
//     price: 46700,
//     priceType: "cents",
//     props: [24000, 30000, 13, 2.5, 6, null, 16],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B1",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0560_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-63+.pdf",
//     description: "F=10M-6G G=18.5dB P1dB=16.9 IP3=28.6 NF=3.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4g4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-81+2B",
//     mfgPart: "GVA-63+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4G4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4G4-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [10, 6000, 18.5, 3.7, 16.9, null, 28.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0403",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5530.pdf",
//     description: "F=50M-1G G=16.5dB P1dB=21.7 IP3=37 NF=3.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0403C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4h8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/adl5530.html",
//     mfgPart: "ADL5530ACPZ-WPSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4H8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4H8-0404D",
//     price: 14300,
//     priceType: "cents",
//     props: [50, 1000, 16.5, 3.2, 21.7, null, 37],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0403_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5611.pdf",
//     description: "F=30M-6G G=22.2dB P1dB=21 IP3=40 NF=2.1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4h9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/gain-blocks/adl5611.html",
//     mfgPart: "ADL5611ARKZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4H9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4H9-0404D",
//     price: 8900,
//     priceType: "cents",
//     props: [30, 6000, 22.2, 2.1, 21, null, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0144",
//     catId: "AMP",
//     ctlBoard: "XM-A4J3-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc5805a.pdf",
//     description: "F=50M-40G G=11.5dB P1dB=24.5 IP3=29 NF=4 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0144I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4j2-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/wideband-distributed-amplifiers/hmc5805a.html",
//     mfgPart: "HMC5805ALS6SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4J2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4J2-0804D",
//     price: 45000,
//     priceType: "cents",
//     props: [50, 40000, 11.5, 4, 24.5, null, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0144_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0458",
//     catId: "AMP",
//     ctlBoard: "XM-A4V7-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/CMD219C4_ver_17_0619.pdf",
//     description: "F=4G-8G G=22.5dB P1dB=17 IP3=28 NF=1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0458B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4v6-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd219c4_low_noise_amplifier/",
//     mfgPart: "CMD219C4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4V6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4V6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [4000, 8000, 22.5, 1, 17, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0458_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0459",
//     catId: "AMP",
//     ctlBoard: "XM-A4W2-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/custom-mmic-cmd240p4-22-ghz-distributed-amplifier-datasheet.pdf",
//     description: "F=50M-22G G=15dB P1dB=19 IP3=26 NF=2.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0459C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4w1-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd240p4-distributed-amplifier/",
//     mfgPart: "CMD240P4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4W1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4W1-0404D",
//     price: 37100,
//     priceType: "cents",
//     props: [50, 22000, 15, 2.2, 19, null, 26],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0459_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0440",
//     catId: "AMP",
//     ctlBoard: "XM-A4W6-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd169p4-5-7-ghz-driver-amplifier-mmic.pdf",
//     description: "F=5G-7G G=19dB P1dB=28.4 IP3=34 NF=6.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0440B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4w5-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd169p4_power_amplifier/",
//     mfgPart: "CMD169P4SMT",
//     models: "this part is missing models",
//     pn: "XM-A4W5-0404D",
//     price: 21100,
//     priceType: "cents",
//     props: [5000, 7000, 19, 6.5, 28.4, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0440",
//     catId: "AMP",
//     ctlBoard: "XM-A4W8-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd170p4-7.5-9-ghz-driver-amplifier-mmic.pdf",
//     description: "F=7.5G-9G G=30dB P1dB=28.3 IP3=34 NF=6.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0440B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4w7-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd170p4_power_amplifier/",
//     mfgPart: "CMD170P4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4W7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4W7-0404D",
//     price: 18600,
//     priceType: "cents",
//     props: [7500, 9000, 30, 6.5, 28.3, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "2012",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2543/GRF2543DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a578-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2543",
//     mfgPart: "GRF2543SMT",
//     models: "this is the new part",
//     pn: "XM-A578-0404D",
//     price: 10700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2012_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2011/GRF2011DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a579-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/",
//     mfgPart: "GRF2011SMT",
//     models: "this is the new part",
//     pn: "XM-A579-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2012/GRF2012DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a582-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/",
//     mfgPart: "GRF2012SMT",
//     models: "this is the new part",
//     pn: "XM-A582-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2013/GRF2013DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a584-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2013",
//     mfgPart: "GRF2013SMT",
//     models: "this is the new part",
//     pn: "XM-A584-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2013",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2070/GRF2070DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a585-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2070",
//     mfgPart: "GRF2070SMT",
//     models: "this is the new part",
//     pn: "XM-A585-0404D",
//     price: 9500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2013_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2013",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2071/GRF2071DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a586-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2071",
//     mfgPart: "GRF2071SMT",
//     models: "this is the new part",
//     pn: "XM-A586-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2013_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2013",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2072/GRF2072DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a587-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2072",
//     mfgPart: "GRF2072SMT",
//     models: "this is the new part",
//     pn: "XM-A587-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2013_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2013",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2073/GRF2073DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a588-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2073",
//     mfgPart: "GRF2073SMT",
//     models: "this is the new part",
//     pn: "XM-A588-0404D",
//     price: 9600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2013_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2013",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2114/GRF2114DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a589-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2114",
//     mfgPart: "GRF2114SMT",
//     models: "this is the new part",
//     pn: "XM-A589-0404D",
//     price: 9600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2013_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2014/GRF2014DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a591-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2014",
//     mfgPart: "GRF2014SMT",
//     models: "this is the new part",
//     pn: "XM-A591-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2012",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2133/GRF2133DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a592-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2133",
//     mfgPart: "GRF2133SMT",
//     models: "this is the new part",
//     pn: "XM-A592-0404D",
//     price: 10800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2012_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2014",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2080/GRF2080DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a594-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2080",
//     mfgPart: "GRF2080SMT",
//     models: "this is the new part",
//     pn: "XM-A594-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2014",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2081/GRF2081DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a595-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2081",
//     mfgPart: "GRF2081SMT",
//     models: "this is the new part",
//     pn: "XM-A595-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2014",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2082/GRF2082DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a596-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2082",
//     mfgPart: "GRF2082SMT",
//     models: "this is the new part",
//     pn: "XM-A596-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2014",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2083/GRF2083DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a597-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2083",
//     mfgPart: "GRF2083SMT",
//     models: "this is the new part",
//     pn: "XM-A597-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0495",
//     catId: "AMP",
//     ctlBoard: "XM-A5C3-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd245c4_ver_1.2_1017.pdf",
//     description: "F=6G-18G G=18dB P1dB=18 IP3=29 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0495A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5c2-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd245c4/",
//     mfgPart: "CMD245C4SMT",
//     models: "this part is missing models",
//     pn: "XM-A5C2-0404D",
//     price: 30800,
//     priceType: "cents",
//     props: [6000, 18000, 18, 4.5, 18, null, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0495_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0495",
//     catId: "AMP",
//     ctlBoard: "XM-A5C5-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd246c4_ver_1.2_1017.pdf",
//     description: "F=8G-22G G=17dB P1dB=13 IP3=25 NF=5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0495A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5c4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd246c4/",
//     mfgPart: "CMD246C4SMT",
//     models: "this part is missing models",
//     pn: "XM-A5C4-0404D",
//     price: 32700,
//     priceType: "cents",
//     props: [8000, 22000, 17, 5, 13, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0495_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0568",
//     catId: "AMP",
//     ctlBoard: "XM-A5D3-0204D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd247_ver_1.0_0816.pdf",
//     description: "F=30G-40G G=13dB P1dB=13.5 IP3=21.5 NF=5 ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0568B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5d2-0204d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd247/",
//     mfgPart: "CMD247DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5D2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5D2-0204D",
//     price: 49200,
//     priceType: "cents",
//     props: [30000, 40000, 13, 5, 13.5, null, 21.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B1",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0568_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5535.pdf",
//     description: "F=20M-1G G=16dB P1dB=18.9 IP3=45.5 NF=3.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5e1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/gain-blocks/adl5535.html",
//     mfgPart: "ADL5535ARKZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5E1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5E1-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: [20, 1000, 16, 3.3, 18.9, null, 45.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5536.pdf",
//     description: "F=20M-1G G=20dB P1dB=19.7 IP3=49 NF=2.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5e2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/gain-blocks/adl5536.html",
//     mfgPart: "ADL5536ARKZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5E2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5E2-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: [20, 1000, 20, 2.7, 19.7, null, 49],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5544.PDF",
//     description: "F=30M-4G G=17.4dB P1dB=17.6 IP3=34.9 NF=2.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5e3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/gain-blocks/adl5544.html",
//     mfgPart: "ADL5544ARKZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5E3-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [30, 4000, 17.4, 2.9, 17.6, null, 34.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5601.pdf",
//     description: "F=50M-4G G=15dB P1dB=19 IP3=43 NF=3.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5e5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/gain-blocks/adl5601.html",
//     mfgPart: "ADL5601ARKZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5E5-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: [50, 4000, 15, 3.7, 19, null, 43],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5602.pdf",
//     description: "F=50M-4G G=20dB P1dB=19.3 IP3=42 NF=3.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5e6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/gain-blocks/adl5602.html",
//     mfgPart: "ADL5602ARKZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5E6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5E6-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: [50, 4000, 20, 3.3, 19.3, null, 42],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc311st89.pdf",
//     description: "F=50M-6G G=16dB P1dB=15 IP3=30 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5e9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/hmc311st89.html",
//     mfgPart: "HMC311ST89ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5E9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5E9-0404D",
//     price: 9300,
//     priceType: "cents",
//     props: [50, 6000, 16, 4.5, 15, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A4E3-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc480.pdf",
//     description: "F=50M-5G G=19dB P1dB=18.5 IP3=32 NF=3.25 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5f1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/hmc480.html",
//     mfgPart: "HMC480ST89SMT",
//     models: "this part is missing models",
//     pn: "XM-A5F1-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [50, 5000, 19, 3.25, 18.5, null, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc589a.pdf",
//     description: "F=50M-4G G=21dB P1dB=21 IP3=33 NF=4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5f2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/gain-blocks/hmc589a.html",
//     mfgPart: "HMC589AST89SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5F2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5F2-0404D",
//     price: 9600,
//     priceType: "cents",
//     props: [50, 4000, 21, 4, 21, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc636.pdf",
//     description: "F=200M-4G G=13dB P1dB=22 IP3=39 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5f3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/hmc636.html",
//     mfgPart: "HMC636ST89SMT",
//     models: "this part is missing models",
//     pn: "XM-A5F3-0404D",
//     price: 9200,
//     priceType: "cents",
//     props: [200, 4000, 13, 2.5, 22, null, 39],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc639.pdf",
//     description: "F=200M-4G G=13dB P1dB=21 IP3=38 NF=2.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5f4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc639.html",
//     mfgPart: "HMC639ST89ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5F4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5F4-0404D",
//     price: 9100,
//     priceType: "cents",
//     props: [200, 4000, 13, 2.3, 21, null, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc740.pdf",
//     description: "F=50M-3G G=15dB P1dB=18 IP3=40 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5f5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/hmc740.html",
//     mfgPart: "HMC740ST89ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5F5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5F5-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [50, 3000, 15, 3.5, 18, null, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0512",
//     catId: "AMP",
//     ctlBoard: "XM-B123-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc902lp3e.pdf",
//     description: "F=5G-10G G=19.5dB P1dB=16 IP3=28 NF=1.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0512A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5g5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifier/hmc902lp3e.html",
//     mfgPart: "HMC902LP3ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5G5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5G5-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [5000, 10000, 19.5, 1.8, 16, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0512_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2093/GRF2093DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a5h5-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2093",
//     mfgPart: "GRF2093SMT",
//     models: "this is the new part",
//     pn: "XM-A5H5-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2012",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2101/GRF2101DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a5h7-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2101",
//     mfgPart: "GRF2101SMT",
//     models: "this is the new part",
//     pn: "XM-A5H7-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2012_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2105/GRF2105DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a5h8-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2105",
//     mfgPart: "GRF2105SMT",
//     models: "this is the new part",
//     pn: "XM-A5H8-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0429",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc406.pdf",
//     description: "F=5G-6G G=17dB P1dB=24 IP3=38 NF=6 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0429B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5j4-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/hmc406.html",
//     mfgPart: "HMC406MS8GESMT",
//     models: "this part is missing models",
//     pn: "XM-A5J4-0804D",
//     price: 16600,
//     priceType: "cents",
//     props: [5000, 6000, 17, 6, 24, null, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0638",
//     catId: "AMP",
//     ctlBoard: "XM-B1D1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc499.pdf",
//     description: "F=21G-32G G=17dB P1dB=23 IP3=34 NF=5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0638A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5l5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/driver-amplifiers/hmc499lc4.html",
//     mfgPart: "HMC499LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5L5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5L5-0404D",
//     price: 19000,
//     priceType: "cents",
//     props: [21000, 32000, 17, 5, 23, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0638_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0496",
//     catId: "AMP",
//     ctlBoard: "XM-A794-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc633.pdf",
//     description: "F=5.5G-17G G=30dB P1dB=23 IP3=30 NF=10 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0496A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5l6-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/wideband-distributed-amplifiers/hmc633lc4.html",
//     mfgPart: "HMC633LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5L6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5L6-0604D",
//     price: 21000,
//     priceType: "cents",
//     props: [5500, 17000, 30, 10, 23, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0496_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0496",
//     catId: "AMP",
//     ctlBoard: "XM-A794-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc634.pdf",
//     description: "F=5G-20G G=21dB P1dB=22 IP3=29 NF=7.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0496A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5l7-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/wideband-distributed-amplifiers/hmc634lc4.html",
//     mfgPart: "HMC634LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5L7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5L7-0604D",
//     price: 21600,
//     priceType: "cents",
//     props: [5000, 20000, 21, 7.5, 22, null, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0496_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0450",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc342.pdf",
//     description: "F=13G-25G G=22dB P1dB=8 IP3=19 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0450B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5n7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifier/hmc342lc4.html",
//     mfgPart: "HMC342LC4SMT",
//     models: "this part is missing models",
//     pn: "XM-A5N7-0404D",
//     price: 16500,
//     priceType: "cents",
//     props: [13000, 25000, 22, 3.5, 8, null, 19],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0450_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0450",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc517.pdf",
//     description: "F=17G-26G G=19dB P1dB=13 IP3=23 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0450B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5n8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifier/hmc517lc4.html",
//     mfgPart: "HMC517LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5N8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5N8-0404D",
//     price: 17700,
//     priceType: "cents",
//     props: [17000, 26000, 19, 2.5, 13, null, 23],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0450_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0450",
//     catId: "AMP",
//     ctlBoard: "XM-A6E9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc962.pdf",
//     description: "F=7.5G-26.5G G=13dB P1dB=13 IP3=23 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0450B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5n9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/hmc962.html",
//     mfgPart: "HMC962LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5N9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5N9-0404D",
//     price: 16700,
//     priceType: "cents",
//     props: [7500, 26500, 13, 2.5, 13, null, 23],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0450_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0236",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc462.pdf",
//     description: "F=2G-20G G=13dB P1dB=14.5 IP3=25 NF=2.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0236K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5p1-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/wideband-distributed-amplifiers/hmc462lp5.html",
//     mfgPart: "HMC462LP5SMT",
//     models: "this part is missing models",
//     pn: "XM-A5P1-0604D",
//     price: 34300,
//     priceType: "cents",
//     props: [2000, 20000, 13, 2.5, 14.5, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0236_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0134",
//     catId: "AMP",
//     ctlBoard: "XM-A7F5-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc637a.pdf",
//     description: "F=100M-6G G=13dB P1dB=29 IP3=44 NF=5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0134M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5r8-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/wideband-distributed-amplifiers/hmc637alp5.html",
//     mfgPart: "HMC637ALP5ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5R8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5R8-0804D",
//     price: 41500,
//     priceType: "cents",
//     props: [100, 6000, 13, 5, 29, null, 44],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0134_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0800",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc453st89.pdf",
//     description: "F=400M-410M G=20.5dB P1dB=31.5 IP3=49 NF=6.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0800A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5t7-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/hmc453st89.html",
//     mfgPart: "HMC453STE89SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5T7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5T7-0804D",
//     price: 22500,
//     priceType: "cents",
//     props: [400, 410, 20.5, 6.5, 31.5, null, 49],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0800_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0134",
//     catId: "AMP",
//     ctlBoard: "XM-A8A4-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1049lp5e.pdf",
//     description: "F=300M-20G G=15dB P1dB=14.5 IP3=29 NF=1.8 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0134M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5u2-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifier/hmc1049lp5e.html",
//     mfgPart: "HMC1049LP5ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5U2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5U2-0804D",
//     price: 38700,
//     priceType: "cents",
//     props: [300, 20000, 15, 1.8, 14.5, null, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0134_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0636",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc752.pdf",
//     description: "F=24G-28G G=25dB P1dB=13 IP3=26 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0636A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5u4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc752.html",
//     mfgPart: "HMC752LC4SMT",
//     models: "this part is missing models",
//     pn: "XM-A5U4-0404D",
//     price: 16100,
//     priceType: "cents",
//     props: [24000, 28000, 25, 2.5, 13, null, 26],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0636_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0834",
//     catId: "AMP",
//     ctlBoard: "XM-B6A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc772.pdf",
//     description: "F=2G-12G G=15dB P1dB=13 IP3=25 NF=1.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0834A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5u6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc772.html",
//     mfgPart: "HMC772LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5U6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5U6-0404D",
//     price: 25800,
//     priceType: "cents",
//     props: [2000, 12000, 15, 1.8, 13, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0834_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0499",
//     catId: "AMP",
//     ctlBoard: "XM-B5G7-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc751.pdf",
//     description: "F=17G-27G G=25dB P1dB=13 IP3=25 NF=2.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0499A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5u9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc751.html",
//     mfgPart: "HMC751LC4SMT",
//     models: "this part is missing models",
//     pn: "XM-A5U9-0404D",
//     price: 16400,
//     priceType: "cents",
//     props: [17000, 27000, 25, 2.2, 13, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0499_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0623",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/AD8353.pdf#",
//     description: "F=1M-2.7G G=20dB P1dB=9 IP3=20.6 NF=5.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0623A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5w8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/ad8353.html",
//     mfgPart: "AD8353ACPZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5W8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5W8-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [1, 2700, 20, 5.3, 9, null, 20.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0623_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0623",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/AD8354.pdf#",
//     description: "F=1M-2.7G G=20dB P1dB=4 IP3=16 NF=4.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0623A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5w9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/ad8354.html",
//     mfgPart: "AD8354ACPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5W9-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [1, 2700, 20, 4.2, 4, null, 16],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0623_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1235",
//     catId: "AMP",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5521.pdf",
//     description: "F=400M-4G G=20.8dB P1dB=21.8 IP3=37 NF=.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1235B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a622-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/adl5521.html",
//     mfgPart: "ADL5521ACPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A622-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [400, 4000, 20.8, 0.8, 21.8, null, 37],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1235_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1235",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5523.pdf",
//     description: "F=300M-700M G=21.5dB P1dB=21 IP3=34 NF=.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1235B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a623-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/adl5523.html",
//     mfgPart: "ADL5523ACPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A623-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [300, 700, 21.5, 0.8, 21, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1235_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0631",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5531.pdf",
//     description: "F=20M-500M G=20dB P1dB=20.4 IP3=39 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0631A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a624-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/adl5531.html",
//     mfgPart: "ADL5531ACPZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A624D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A624-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [20, 500, 20, 2.5, 20.4, null, 39],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0631_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0632",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5541.pdf",
//     description: "F=20M-6G G=15.2dB P1dB=19.7 IP3=40.8 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0632A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a626-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/adl5541.html",
//     mfgPart: "ADL5541ACPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A626-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: [20, 6000, 15.2, 3.5, 19.7, null, 40.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0632",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5542.pdf",
//     description: "F=20M-6G G=20dB P1dB=20.6 IP3=46 NF=3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0632A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a627-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/adl5542.html",
//     mfgPart: "ADL5542ACPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A627-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: [20, 6000, 20, 3, 20.6, null, 46],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0462",
//     catId: "AMP",
//     ctlBoard: "XM-A7P7-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1082.pdf",
//     description: "F=5.5G-18G G=22dB P1dB=24 IP3=35 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a641-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/hmc1082.html",
//     mfgPart: "HMC1082LP4ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A641D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A641-0804D",
//     price: 22600,
//     priceType: "cents",
//     props: [5500, 18000, 22, 0, 24, 25.5, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0636",
//     catId: "AMP",
//     ctlBoard: "XM-C7P8-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1131.pdf",
//     description: "F=24G-35G G=22dB P1dB=24 IP3=35 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0636A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a648-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/driver-amplifiers/hmc1131.html",
//     mfgPart: "HMC1131LC4SMT",
//     models: "this part is missing models",
//     pn: "XM-A648-0404D",
//     price: 19300,
//     priceType: "cents",
//     props: [24000, 35000, 22, 0, 24, 25, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0636_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0635",
//     catId: "AMP",
//     ctlBoard: "XM-B5G7-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc263.pdf",
//     description: "F=24G-36G G=20dB P1dB=8 IP3=18 NF=2.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0635A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a657-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc263lp4e.html",
//     mfgPart: "HMC263LP4ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A657D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A657-0404D",
//     price: 19900,
//     priceType: "cents",
//     props: [24000, 36000, 20, 2.2, 8, null, 18],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0635_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0269",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc311lp3.pdf",
//     description: "F=50M-6G G=14.5dB P1dB=15.5 IP3=32 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0269E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a662-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/hmc311lp3.html",
//     mfgPart: "HMC311LP3SMT",
//     models: "this part is missing models",
//     pn: "XM-A662-0404D",
//     price: 9200,
//     priceType: "cents",
//     props: [50, 6000, 14.5, 4.5, 15.5, null, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0269_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0470",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc311sc70.pdf",
//     description: "F=50M-8G G=15dB P1dB=15 IP3=30 NF=5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0470A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a663-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/hmc311sc70.html",
//     mfgPart: "HMC311SC70ESMT",
//     models: "this part is missing models",
//     pn: "XM-A663-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [50, 8000, 15, 5, 15, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1127",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc313.pdf",
//     description: "F=50M-6G G=17dB P1dB=14 IP3=27 NF=6.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1127A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a664-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/hmc313.html",
//     mfgPart: "HMC313SMT",
//     models: "this part is missing models",
//     pn: "XM-A664-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 6000, 17, 6.5, 14, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1127_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0961",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc341.pdf",
//     description: "F=21G-29G G=13dB P1dB=8.5 IP3=19 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0961A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a667-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc341lc3b.html",
//     mfgPart: "HMC341LC3BSMT",
//     models: "this part is missing models",
//     pn: "XM-A667-0404D",
//     price: 18100,
//     priceType: "cents",
//     props: [21000, 29000, 13, 2.5, 8.5, null, 19],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0961_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0961",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc3587.pdf",
//     description: "F=4G-10G G=14.5dB P1dB=11 IP3=25 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0961A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a671-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/hmc3587.html",
//     mfgPart: "HMC3587LP3BESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A671D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A671-0404D",
//     price: 12300,
//     priceType: "cents",
//     props: [4000, 10000, 14.5, 4.5, 11, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0961_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0470",
//     catId: "AMP",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc374.pdf",
//     description: "F=300M-3G G=15dB P1dB=17 IP3=33.5 NF=1.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0470A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a675-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifier/hmc374sc70e.html",
//     mfgPart: "HMC374SC70ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A675D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A675-0404D",
//     price: 8300,
//     priceType: "cents",
//     props: [300, 3000, 15, 1.6, 17, null, 33.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1163",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc375.pdf",
//     description: "F=1.7G-2.2G G=17dB P1dB=18.5 IP3=34 NF=.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1163A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a676-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc375.html",
//     mfgPart: "HMC375LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A676-0404D",
//     price: 9300,
//     priceType: "cents",
//     props: [1700, 2200, 17, 0.9, 18.5, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1163_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0644",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc407.pdf",
//     description: "F=5G-7G G=15dB P1dB=25 IP3=37 NF=5.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0644A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a679-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/driver-amplifiers/hmc407.html",
//     mfgPart: "HMC407MS8GESMT",
//     models: "this part is missing models",
//     pn: "XM-A679-0404D",
//     price: 13200,
//     priceType: "cents",
//     props: [5000, 7000, 15, 5.5, 25, null, 37],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0644_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1273",
//     catId: "AMP",
//     ctlBoard: "XM-C744-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc408.pdf",
//     description: "F=5.1G-5.9G G=20dB P1dB=30 IP3=43 NF=6 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1273A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a681-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/hmc408.html",
//     mfgPart: "HMC408LP3ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A681D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A681-0604D",
//     price: 20700,
//     priceType: "cents",
//     props: [5100, 5900, 20, 6, 30, 32.5, 43, null, null, null, 1000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1273_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0633",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc565lc5.pdf",
//     description: "F=6G-20G G=21dB P1dB=10 IP3=20 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0633A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6b1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc565lc5.html",
//     mfgPart: "HMC565LC5SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6B1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6B1-0404D",
//     price: 16900,
//     priceType: "cents",
//     props: [6000, 20000, 21, 2.5, 10, null, 20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0633_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0635",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc566.pdf",
//     description: "F=28G-36G G=21dB P1dB=12 IP3=24.5 NF=2.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0635A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6b2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc566lp4e.html",
//     mfgPart: "HMC566LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A6B2-0404D",
//     price: 15100,
//     priceType: "cents",
//     props: [28000, 36000, 21, 2.8, 12, null, 24.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0635_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1912",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc608.pdf",
//     description: "F=9.5G-11.5G G=29.5dB P1dB=27 IP3=33 NF=6 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1912A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6b6-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/driver-amplifiers/hmc608.html",
//     mfgPart: "HMC608LC4SMT",
//     models: "this part is missing models",
//     pn: "XM-A6B6-0604D",
//     price: 31900,
//     priceType: "cents",
//     props: [9500, 11500, 29.5, 6, 27, 0, 33, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1912_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1034",
//     catId: "AMP",
//     ctlBoard: "XM-C7B9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc635.pdf",
//     description: "F=18G-40G G=18.5dB P1dB=22 IP3=27 NF=7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1034A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6b9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/driver-amplifiers/hmc635lc4.html",
//     mfgPart: "HMC635LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6B9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6B9-0404D",
//     price: 23200,
//     priceType: "cents",
//     props: [18000, 40000, 18.5, 7, 22, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1026",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/HMC788A.pdf",
//     description: "F=10M-10G G=14dB P1dB=20 IP3=33 NF=6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1026A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6d3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc788a.html",
//     mfgPart: "HMC788ALP2ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6D3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6D3-0404D",
//     price: 16800,
//     priceType: "cents",
//     props: [10, 10000, 14, 6, 20, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0690",
//     catId: "AMP",
//     ctlBoard: "XM-B1E1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC8410.pdf",
//     description: "F=10M-10G G=19.5dB P1dB=21 IP3=31.5 NF=1.1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0690B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6d9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc8410lp2fe.html",
//     mfgPart: "HMC8410LP2FESMT",
//     models: "this part is missing models",
//     pn: "XM-A6D9-0404D",
//     price: 17600,
//     priceType: "cents",
//     props: [10, 10000, 19.5, 1.1, 21, null, 31.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0690_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0512",
//     catId: "AMP",
//     ctlBoard: "XM-B123-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc903lp3e.pdf",
//     description: "F=6G-17G G=18.5dB P1dB=14.5 IP3=25 NF=1.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0512A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6e1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifier/hmc903lp3e.html",
//     mfgPart: "HMC903LP3ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6E1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6E1-0404D",
//     price: 16800,
//     priceType: "cents",
//     props: [6000, 17000, 18.5, 1.7, 14.5, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0512_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0551",
//     catId: "AMP",
//     ctlBoard: "XM-A752-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc996.pdf",
//     description: "F=5G-12G G=18.5dB P1dB=22 IP3=34 NF=2.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0551A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6e8-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/variable-gain-amplifiers/analog-control-vgas/hmc996.html",
//     mfgPart: "HMC996LP4ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6E8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6E8-0804D",
//     price: 16500,
//     priceType: "cents",
//     props: [5000, 12000, 18.5, 2.5, 22, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0551_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2012",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2243/GRF2243DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a6f1-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2243",
//     mfgPart: "GRF2243SMT",
//     models: "this is the new part",
//     pn: "XM-A6F1-0404D",
//     price: 10700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2012_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0477",
//     catId: "AMP",
//     ctlBoard: "XM-A6K3-0804D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc659.pdf",
//     description: "F=50M-15G G=19dB P1dB=27.5 IP3=35 NF=2.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0477B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k4-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/wideband-distributed-amplifiers/hmc659lc5.html",
//     mfgPart: "HMC659LC5SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6K4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6K4-0804D",
//     price: 70900,
//     priceType: "cents",
//     props: [50, 15000, 19, 2.5, 27.5, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0477_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0269",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM1071-Datasheet-Latest.pdf",
//     description: "F=50M-18G G=12dB P1dB=16 IP3=30 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0269E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k8-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1071/",
//     mfgPart: "AM1071SMT",
//     models: "this part is missing models",
//     pn: "XM-A6K8-0404D",
//     price: 12100,
//     priceType: "cents",
//     props: [50, 18000, 12, 3.5, 16, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0269_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc741.pdf",
//     description: "F=50M-3G G=20dB P1dB=18.5 IP3=42 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a742-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/hmc741.html",
//     mfgPart: "HMC741ST89ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A742D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A742-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [50, 3000, 20, 2.5, 18.5, null, 42],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0569",
//     catId: "AMP",
//     ctlBoard: "XM-A754-0304D",
//     datasheetURL: "https://www.qorvo.com/products/d/da004469",
//     description: "F=17G-43G G=25dB P1dB=20 IP3=28 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0569C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a753-0304d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA4516",
//     mfgPart: "TGA4040 (Amp)DIE",
//     models: "this part is missing models",
//     pn: "XM-A753-0304D",
//     price: 43300,
//     priceType: "cents",
//     props: [17000, 43000, 25, null, 20, null, 28, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0569_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0571",
//     catId: "AMP",
//     ctlBoard: "XM-A756-0304D",
//     datasheetURL: "https://www.qorvo.com/products/d/da004525",
//     description: "F=30G-40G G=18dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0571C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a755-0304d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA4516-TS",
//     mfgPart: "TGA4516DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A755D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A755-0304D",
//     price: 68000,
//     priceType: "cents",
//     props: [30000, 40000, 18, 0, 0, 33, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0571_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0613",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005305",
//     description: "F=600M-4.2G G=20dB P1dB=19 IP3=37 NF=.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0613A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a767-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQL9093",
//     mfgPart: "TQL9092SMT",
//     models: "this part is missing models",
//     pn: "XM-A767-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [600, 4200, 20, 0.6, 19, null, 37],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0613_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005528",
//     description: "F=50M-4G G=14.7dB P1dB=20.7 IP3=40 NF=1.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7b4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQP3M9035",
//     mfgPart: "TQP3M9028SMT",
//     models: "this part is missing models",
//     pn: "XM-A7B4-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [50, 4000, 14.7, 1.8, 20.7, null, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2373/GRF2373DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a7b7-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2373",
//     mfgPart: "GRF2373SMT",
//     models: "this is the new part",
//     pn: "XM-A7B7-0404D",
//     price: 9900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0774",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/CMD233C4_ver_2_0519.pdf",
//     description: "F=2G-20G G=9dB P1dB=20.5 IP3=24 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0774A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7c6-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd233c4/",
//     mfgPart: "CMD233C4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7C6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7C6-0404D",
//     price: 25200,
//     priceType: "cents",
//     props: [2000, 20000, 9, 4.5, 20.5, null, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1277",
//     catId: "AMP",
//     ctlBoard: "XM-B817-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/TSS-53LNB+.pdf",
//     description: "F=500M-5G G=21.7dB P1dB=21 IP3=33.9 NF=1.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1277A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7c7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD188",
//     mfgPart: "TSS-53LNB+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7C7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7C7-0404D",
//     price: 9400,
//     priceType: "cents",
//     props: [500, 5000, 21.7, 1.4, 21, null, 33.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1277_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0601",
//     catId: "AMP",
//     ctlBoard: "XM-A8E9-0404D",
//     datasheetURL: "https://www.idt.com/document/dst/f1451-datasheet-rev-o",
//     description: "F=450M-1.1G G=32dB P1dB=23 IP3=41 NF=3.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0601B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7e9-0404d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/variable-gain-amplifiers-vga/f1451-tx-digital-vga-700-mhz-1100-mhz",
//     mfgPart: "F1451SMT",
//     models: "this part is missing models",
//     pn: "XM-A7E9-0404D",
//     price: 11500,
//     priceType: "cents",
//     props: [450, 1100, 32, 3.6, 23, null, 41],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0601",
//     catId: "AMP",
//     ctlBoard: "XM-A8E9-0404D",
//     datasheetURL: "https://www.idt.com/document/dst/f1455-datasheet-rev1",
//     description: "F=1.4G-2.3G G=32.8dB P1dB=23 IP3=38.5 NF=3.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0601B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7f1-0404d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/variable-gain-amplifiers-vga/f1455-tx-rf-vga-klin-feature",
//     mfgPart: "F1455SMT",
//     models: "this part is missing models",
//     pn: "XM-A7F1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [1400, 2300, 32.8, 3.9, 23, null, 38.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0601",
//     catId: "AMP",
//     ctlBoard: "XM-A8E9-0404D",
//     datasheetURL: "https://www.idt.com/document/dst/f1456-datasheet-revo",
//     description: "F=2.1G-2.95G G=32.1dB P1dB=21.5 IP3=38 NF=3.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0601B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7f2-0404d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/variable-gain-amplifiers-vga/f1456-tx-digital-vga-2100-mhz-2950-mhz",
//     mfgPart: "F1456SMT",
//     models: "this part is missing models",
//     pn: "XM-A7F2-0404D",
//     price: 11500,
//     priceType: "cents",
//     props: [2100, 2950, 32.1, 3.9, 21.5, null, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0766",
//     catId: "AMP",
//     ctlBoard: "XM-A7F7-0604D",
//     datasheetURL: "https://www.qorvo.com/products/d/da003672",
//     description: "F=2G-20G G=17dB P1dB=17 NF=1.7 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0766A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7f6-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/SBB4089Z",
//     mfgPart: "QPM1000SMT",
//     models: "this part is missing models",
//     pn: "XM-A7F6-0604D",
//     price: 34000,
//     priceType: "cents",
//     props: [2000, 20000, 17, 1.7, 17, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0766_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0930",
//     catId: "AMP",
//     ctlBoard: "XM-A581-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd241p4-08-18-1.pdf",
//     description: "F=2G-22G G=13dB P1dB=21 IP3=28 NF=2.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0930A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7j4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd241p4/",
//     mfgPart: "CMD241P4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7J4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7J4-0404D",
//     price: 32200,
//     priceType: "cents",
//     props: [2000, 22000, 13, 2.3, 21, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0930_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0001",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MAR-1SM+.pdf",
//     description: "F=50M-1G G=16.5dB P1dB=2.5 IP3=14 NF=3.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0001H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7j5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MERA-533+2B",
//     mfgPart: "MAR-1SM+SMT",
//     models: "this part is missing models",
//     pn: "XM-A7J5-0404D",
//     price: 8200,
//     priceType: "cents",
//     props: [50, 1000, 16.5, 3.3, 2.5, null, 14],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0001_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0730",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/AVA-24A+.pdf",
//     description: "F=5G-20G G=12.3dB P1dB=18.4 IP3=25 NF=5.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0730A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7j6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CMA-103+2B",
//     mfgPart: "AVA-24A+SMT",
//     models: "this part is missing models",
//     pn: "XM-A7J6-0404D",
//     price: 8000,
//     priceType: "cents",
//     props: [5000, 20000, 12.3, 5.7, 18.4, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0730_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0848",
//     catId: "AMP",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da001879",
//     description: "F=5M-4G G=28.6dB P1dB=1 NF=1.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0848A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7k6-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA2214-CP",
//     mfgPart: "SGL0622ZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7K6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7K6-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [5, 4000, 28.6, 1.4, 1, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0848_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0815",
//     catId: "AMP",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM1053-Datasheet-Latest.pdf",
//     description: "F=5G-20G G=20dB P1dB=16 IP3=28 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0815A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7l1-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1053/",
//     mfgPart: "AM1053SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7L1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7L1-0404D",
//     price: 11700,
//     priceType: "cents",
//     props: [5000, 20000, 20, 2.5, 16, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0815_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1206",
//     catId: "AMP",
//     ctlBoard: "XM-C3Y4-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAM-011238-DIE.pdf",
//     description: "F=100M-67.5G G=14dB P1dB=18 IP3=26.5 NF=4.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1206C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7r7-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MAAM-011238-DIE",
//     mfgPart: "MAAM-011238-DIEDIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7R7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7R7-0404D",
//     price: 70300,
//     priceType: "cents",
//     props: [100, 67500, 14, 4.7, 18, null, 26.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1206_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0792",
//     catId: "AMP",
//     ctlBoard: "XM-A7U1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PMA2-123LN+.pdf",
//     description: "F=500M-12G G=17.6dB P1dB=14.9 IP3=27 NF=2.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0792B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7t9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA2-153LN+2B",
//     mfgPart: "PMA2-123LN+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7T9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7T9-0404D",
//     price: 15900,
//     priceType: "cents",
//     props: [500, 12000, 17.6, 2.6, 14.9, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0792_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2012",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2501/GRF2501DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a812-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2501",
//     mfgPart: "GRF2501SMT",
//     models: "this is the new part",
//     pn: "XM-A812-0404D",
//     price: 10700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2012_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/2505/GRF2505DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a813-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF2505",
//     mfgPart: "GRF2505SMT",
//     models: "this is the new part",
//     pn: "XM-A813-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/4001/GRF4001DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a815-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF4001",
//     mfgPart: "GRF4001SMT",
//     models: "this is the new part",
//     pn: "XM-A815-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/4001/GRF4001DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a816-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF4001",
//     mfgPart: "GRF4002SMT",
//     models: "this is the new part",
//     pn: "XM-A816-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/4003/GRF4003DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a817-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF4003",
//     mfgPart: "GRF4003SMT",
//     models: "this is the new part",
//     pn: "XM-A817-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/4004/GRF4004DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a818-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF4004",
//     mfgPart: "GRF4004SMT",
//     models: "this is the new part",
//     pn: "XM-A818-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/4005/GRF4005DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a819-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF4005",
//     mfgPart: "GRF4005SMT",
//     models: "this is the new part",
//     pn: "XM-A819-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/4014/GRF4014DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a821-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF4014",
//     mfgPart: "GRF4014SMT",
//     models: "this is the new part",
//     pn: "XM-A821-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2011",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/4142/GRF4142DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a822-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF4142",
//     mfgPart: "GRF4142SMT",
//     models: "this is the new part",
//     pn: "XM-A822-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0278",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da007839",
//     description: "F=6G-18G G=27dB P1dB=12 IP3=24 NF=1.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0278F.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a826-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/NBB-300",
//     mfgPart: "CMD328K3SMT",
//     models: "this part is missing models",
//     pn: "XM-A826-0404D",
//     price: 16000,
//     priceType: "cents",
//     props: [6000, 18000, 27, 1.4, 12, null, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0278_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-4+.pdf",
//     description: "F=50M-4G G=13.5dB P1dB=17.5 IP3=34 NF=4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8a6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-49+2B",
//     mfgPart: "Gali-4+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8A6-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 4000, 13.5, 4, 17.5, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-4F+.pdf",
//     description: "F=50M-4G G=13.4dB P1dB=15.3 IP3=34 NF=4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8a7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-5+2B",
//     mfgPart: "Gali-4F+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8A7-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 4000, 13.4, 4, 15.3, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-5F+.pdf",
//     description: "F=50M-4G G=17.4dB P1dB=15.7 IP3=31.5 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8a8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-6+2B",
//     mfgPart: "GALI-5F+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8A8-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 4000, 17.4, 3.5, 15.7, null, 31.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A6E9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-S66+.pdf",
//     description: "F=50M-3G G=18.2dB P1dB=3.3 IP3=19.1 NF=2.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8a9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-123+2B",
//     mfgPart: "Gali-S66+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8A9-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 3000, 18.2, 2.4, 3.3, null, 19.1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-60+.pdf",
//     description: "F=10M-5G G=20dB P1dB=20 IP3=39 NF=4.1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8b1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-62+2B",
//     mfgPart: "GVA-60+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8B1-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [10, 5000, 20, 4.1, 20, null, 39],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-62+.pdf",
//     description: "F=10M-6G G=15.4dB P1dB=19.2 IP3=33.6 NF=5.1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8b2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-63+2B",
//     mfgPart: "GVA-62+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8B2-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [10, 6000, 15.4, 5.1, 19.2, null, 33.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-81+.pdf",
//     description: "F=50M-6G G=10dB P1dB=19.7 IP3=36.6 NF=7.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8b3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-82+2B",
//     mfgPart: "GVA-81+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8B3-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 6000, 10, 7.4, 19.7, null, 36.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-82+.pdf",
//     description: "F=50M-7G G=15dB P1dB=20.6 IP3=34.3 NF=6.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8b4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-83+2B",
//     mfgPart: "GVA-82+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8B4-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 7000, 15, 6.8, 20.6, null, 34.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-83+.pdf",
//     description: "F=50M-7G G=20dB P1dB=18 IP3=33 NF=6.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8b5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-84+2B",
//     mfgPart: "GVA-83+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8B5-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 7000, 20, 6.2, 18, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-84+.pdf",
//     description: "F=50M-7G G=24dB P1dB=20.5 IP3=37 NF=5.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8b6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-84-DG+2B",
//     mfgPart: "GVA-84+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A8B6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A8B6-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 7000, 24, 5.5, 20.5, null, 37],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-91+.pdf",
//     description: "F=869M-2.17G G=20.4dB P1dB=28.8 IP3=39.9 NF=6.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8b7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-92+2B",
//     mfgPart: "GVA-91+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8B7-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: [869, 2170, 20.4, 6.4, 28.8, null, 39.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-92+.pdf",
//     description: "F=869M-2.17G G=21.2dB P1dB=23.3 IP3=42 NF=6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8b8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LEE2-6+2B",
//     mfgPart: "GVA-92+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8B8-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [869, 2170, 21.2, 6, 23.3, null, 42],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PGA-102+.pdf",
//     description: "F=50M-6G G=12.5dB P1dB=17.5 IP3=33 NF=2.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8c1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PGA-1021+2B",
//     mfgPart: "PGA-102+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8C1-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 6000, 12.5, 2.8, 17.5, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PGA-103+.pdf",
//     description: "F=50M-4G G=11dB P1dB=22.5 IP3=44.6 NF=0.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8c2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PGA-105+2B",
//     mfgPart: "PGA-103+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8C2-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 4000, 11, 0.9, 22.5, null, 44.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PGA-105+.pdf",
//     description: "F=40M-2.6G G=15.1dB P1dB=20.5 IP3=39.3 NF=1.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8c3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PHA-1+2B",
//     mfgPart: "PGA-105+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A8C3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A8C3-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [40, 2600, 15.1, 1.9, 20.5, null, 39.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PGA-1021+.pdf",
//     description: "F=50M-6G G=13.4dB P1dB=16.9 IP3=26.7 NF=2.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8c8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PGA-103+2B",
//     mfgPart: "PGA-1021+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8C8-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 6000, 13.4, 2.4, 16.9, null, 26.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PHA-1+.pdf",
//     description: "F=50M-6G G=13.5dB P1dB=22 IP3=42 NF=2.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8c9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PHA-101+2B",
//     mfgPart: "PHA-1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A8C9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A8C9-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 6000, 13.5, 2.2, 22, null, 42],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PHA-1H+.pdf",
//     description: "F=50M-6G G=13.8dB P1dB=22 IP3=40.4 NF=2.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8d1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PHA-23HLN+2B",
//     mfgPart: "PHA-1H+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8D1-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [50, 6000, 13.8, 2.2, 22, null, 40.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0973",
//     catId: "AMP",
//     ctlBoard: "XM-A4E3-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PHA-13HLN+.pdf",
//     description: "F=1M-1G G=22.7dB P1dB=28.7 IP3=43 NF=1.1 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0973A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8d2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PHA-13LN+2B",
//     mfgPart: "PHA-13HLN+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A8D2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A8D2-0604D",
//     price: 11900,
//     priceType: "cents",
//     props: [1, 1000, 22.7, 1.1, 28.7, null, 43],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0973_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0973",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PHA-13LN+.pdf",
//     description: "F=1M-1G G=22.4dB P1dB=24.5 IP3=39 NF=.9 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0973A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8d3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PHA-1H+2B",
//     mfgPart: "PHA-13LN+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8D3-0604D",
//     price: 11900,
//     priceType: "cents",
//     props: [1, 1000, 22.4, 0.9, 24.5, 0, 39],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0973_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0973",
//     catId: "AMP",
//     ctlBoard: "XM-A4E3-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PHA-23HLN+.pdf",
//     description: "F=30M-2G G=21.3dB P1dB=28.4 IP3=44.4 NF=1.4 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0973A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8d4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PHA-23LN+2B",
//     mfgPart: "PHA-23HLN+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8D4-0604D",
//     price: 12000,
//     priceType: "cents",
//     props: [30, 2000, 21.3, 1.4, 28.4, 0, 44.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0973_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0973",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PHA-23LN+.pdf",
//     description: "F=30M-2G G=21dB P1dB=23.9 IP3=37.4 NF=1.2 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0973A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8d5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PHA-83W+2B",
//     mfgPart: "PHA-23LN+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8D5-0604D",
//     price: 11900,
//     priceType: "cents",
//     props: [30, 2000, 21, 1.2, 23.9, 0, 37.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0973_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-B7P9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PHA-101+.pdf",
//     description: "F=50M-1.5G G=15.2dB P1dB=25.8 IP3=45 NF=4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8d6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PHA-13HLN+2B",
//     mfgPart: "PHA-101+SMT",
//     models: "this part is missing models",
//     pn: "XM-A8D6-0404D",
//     price: 9600,
//     priceType: "cents",
//     props: [50, 1500, 15.2, 4, 25.8, null, 45],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0724",
//     catId: "AMP",
//     ctlBoard: "XM-A9G9-0604D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAM-011238.pdf",
//     description: "F=0.1M-50G G=14dB P1dB=17 IP3=25 NF=7 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0724A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9g8-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAM-011238",
//     mfgPart: "MAAM-011238SMT",
//     models: "this part is missing models",
//     pn: "XM-A9G8-0604D",
//     price: 45100,
//     priceType: "cents",
//     props: [0.1, 50000, 14, 7, 17, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0724_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0641",
//     catId: "AMP",
//     ctlBoard: "XM-A9H2-0808D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1087.pdf",
//     description: "F=2G-20G G=11dB IP3=43 ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0641B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9h1-0808d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/hmc1087f10.html",
//     mfgPart: "HMC1087F10Flange",
//     models: "this part is missing models",
//     pn: "XM-A9H1-0808D",
//     price: 174900,
//     priceType: "cents",
//     props: [2000, 20000, 11, 0, 0, 39.5, 43],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0641_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0134",
//     catId: "AMP",
//     ctlBoard: "XM-B1C2-0804D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd201p5-08-18-1.pdf",
//     description: "F=50M-20G G=11dB P1dB=27 IP3=38 NF=3.4 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0134M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9r3-0804d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd201p5/",
//     mfgPart: "CMD201P5SMT",
//     models: "this part is missing models",
//     pn: "XM-A9R3-0804D",
//     price: 70100,
//     priceType: "cents",
//     props: [50, 20000, 11, 3.4, 27, null, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0134_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0134",
//     catId: "AMP",
//     ctlBoard: "XM-B1V9-0804D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd244k5_datasheet.pdf",
//     description: "F=50M-20G G=17dB P1dB=25 IP3=31 NF=2.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0134M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9r4-0804d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd244k5-driver-amplifier/",
//     mfgPart: "CMD244K5SMT",
//     models: "this part is missing models",
//     pn: "XM-A9R4-0804D",
//     price: 62400,
//     priceType: "cents",
//     props: [50, 20000, 17, 2.5, 25, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0134_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0134",
//     catId: "AMP",
//     ctlBoard: "XM-B936-0804D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd249p5-1018.pdf",
//     description: "F=50M-20G G=12.5dB P1dB=28 IP3=39 NF=3.4 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0134M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9r7-0804d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/cmd249p5-distributed-power-amplifier",
//     mfgPart: "CMD249P5SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9R7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9R7-0804D",
//     price: 62500,
//     priceType: "cents",
//     props: [50, 20000, 12.5, 3.4, 28, null, 39],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0134_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0269",
//     catId: "AMP",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM1018A-Datasheet-Latest.pdf",
//     description: "F=20M-6G G=13dB P1dB=19 IP3=35 NF=3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0269E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9t2-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1018a/",
//     mfgPart: "AM1018ASMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9T2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9T2-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [20, 6000, 13, 3, 19, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0269_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0269",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM1018B-Datasheet-Latest.pdf",
//     description: "F=20M-6G G=13dB P1dB=22 IP3=35 NF=3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0269E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9t3-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1018b/",
//     mfgPart: "AM1018BSMT",
//     models: "this part is missing models",
//     pn: "XM-A9T3-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [20, 6000, 13, 3, 22, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0269_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0269",
//     catId: "AMP",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM1070_Datasheet_Rev1.pdf",
//     description: "F=50M-18G G=12dB P1dB=15 IP3=27 NF=3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0269E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9t4-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1070/",
//     mfgPart: "AM1070-1SMT",
//     models: "this part is missing models",
//     pn: "XM-A9T4-0404D",
//     price: 12100,
//     priceType: "cents",
//     props: [50, 18000, 12, 3, 15, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0269_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0269",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM1063-1-Datasheet-Latest.pdf",
//     description: "F=10M-10G G=15dB P1dB=18 IP3=30 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0269E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9t5-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1063/",
//     mfgPart: "AM1063-1SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9T5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9T5-0404D",
//     price: 9800,
//     priceType: "cents",
//     props: [10, 10000, 15, 2.5, 18, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0269_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0269",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM1064-1-Datasheet-Latest.pdf",
//     description: "F=50M-8G G=21dB P1dB=18 IP3=32 NF=2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0269E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9t6-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1064/",
//     mfgPart: "AM1064-1SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9T6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9T6-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [50, 8000, 21, 2, 18, null, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0269_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1080",
//     catId: "AMP",
//     ctlBoard: "XM-A2J4-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc907apm5e.pdf",
//     description: "F=200M-22G G=14dB P1dB=28 IP3=40 NF=3 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1080B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9v6-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/hmc907apm5e.html",
//     mfgPart: "HMC907APM5ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9V6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9V6-0604D",
//     price: 43700,
//     priceType: "cents",
//     props: [200, 22000, 14, 3, 28, null, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1080_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0458",
//     catId: "AMP",
//     ctlBoard: "XM-B126-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/CMD277C4_ver_13_0619.pdf",
//     description: "F=5G-7G G=20dB P1dB=26.5 IP3=33.5 NF=1.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0458B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9w4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/cmd277c4-5-7-ghz-c-band-gan-lna-mmic/",
//     mfgPart: "CMD277C4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9W4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9W4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5000, 7000, 20, 1.2, 26.5, null, 33.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0458_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1250",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd231c3_ver_1.1_1017.pdf",
//     description: "F=2G-6G G=14.5dB P1dB=13.5 IP3=23.5 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1250B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9w7-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd231c3/",
//     mfgPart: "CMD231C3SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9W7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9W7-0404D",
//     price: 13900,
//     priceType: "cents",
//     props: [2000, 6000, 14.5, 4.5, 13.5, null, 23.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0187",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd264p3-08-18-1.pdf",
//     description: "F=6G-18G G=26dB P1dB=12 IP3=24 NF=1.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0187L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9w8-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/low-noise-amplifier-cmd264p3/",
//     mfgPart: "CMD264P3SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9W8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9W8-0404D",
//     price: 16500,
//     priceType: "cents",
//     props: [6000, 18000, 26, 1.6, 12, null, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0187_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0459",
//     catId: "AMP",
//     ctlBoard: "XM-A5C3-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd275p4-08-18-1.pdf",
//     description: "F=50M-26.5G G=16dB P1dB=18 IP3=29 NF=5.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0459C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9w9-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd275p4/",
//     mfgPart: "CMD275P4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9W9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9W9-0404D",
//     price: 42700,
//     priceType: "cents",
//     props: [50, 26500, 16, 5.5, 18, null, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0459_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0186",
//     catId: "AMP",
//     ctlBoard: "XM-A6E9-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd263p3-ver-12-0519.pdf",
//     description: "F=5G-11G G=22dB P1dB=11 IP3=21 NF=1.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0186G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9x1-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/low-noise-amplifier-cmd263p3/",
//     mfgPart: "CMD263P3SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9X1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9X1-0404D",
//     price: 16600,
//     priceType: "cents",
//     props: [5000, 11000, 22, 1.4, 11, null, 21],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0186_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0930",
//     catId: "AMP",
//     ctlBoard: "XM-A5C3-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd274p4-08-18-3.pdf",
//     description: "F=2G-20G G=17dB P1dB=19 IP3=30.5 NF=3.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0930A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9x4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/cmd274p4-low-phase-noise-amplifier-mmic/",
//     mfgPart: "CMD274P4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9X4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9X4-0404D",
//     price: 33500,
//     priceType: "cents",
//     props: [2000, 20000, 17, 3.2, 19, null, 30.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0930_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1235",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5523.pdf",
//     description: "F=600M-1.2G G=21.5dB P1dB=21 IP3=34 NF=.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1235B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b134-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/adl5523.html",
//     mfgPart: "ADL5523ACPZSMT",
//     models: "this part is missing models",
//     pn: "XM-B134-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [600, 1200, 21.5, 0.8, 21, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1235_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1235",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5523.pdf",
//     description: "F=400M-4G G=21.5dB P1dB=21 IP3=34 NF=.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1235B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b135-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/adl5523.html",
//     mfgPart: "ADL5523ACPZSMT",
//     models: "this part is missing models",
//     pn: "XM-B135-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [400, 4000, 21.5, 0.8, 21, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1235_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1742",
//     catId: "AMP",
//     ctlBoard: "XM-B196-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAL-011151.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b194-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAL-011151",
//     mfgPart: "MAAL-011151SMT",
//     models: "this is the new part",
//     pn: "XM-B194-0404D",
//     price: 27600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1742_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1741",
//     catId: "AMP",
//     ctlBoard: "XM-B197-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAL-011151-DIE.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b195-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MAAL-011151-DIE",
//     mfgPart: "MAAL-011151-DIEDIE",
//     models: "this is the new part",
//     pn: "XM-B195-0404D",
//     price: 62000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1741_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0557",
//     catId: "AMP",
//     ctlBoard: "XM-B1B8-0204D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8402.pdf",
//     description: "F=2G-30G G=13.5dB P1dB=21.5 IP3=26 NF=2 ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0557A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1b7-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc8402-die.html",
//     mfgPart: "HMC8402DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1B7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1B7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [2000, 30000, 13.5, 2, 21.5, null, 26],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B1",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0557_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1160",
//     catId: "AMP",
//     ctlBoard: "XM-B1C4-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc7950.pdf",
//     description: "F=2G-28G G=15dB P1dB=16 IP3=26 NF=2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1160A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1c3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc7950.html",
//     mfgPart: "HMC7950SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1C3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1C3-0404D",
//     price: 39500,
//     priceType: "cents",
//     props: [2000, 28000, 15, 2, 16, null, 26],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1160_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1307",
//     catId: "AMP",
//     ctlBoard: "XM-B1E3-0405D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5565.pdf",
//     description: "F=50M-6G G=15.5dB P1dB=16.5 IP3=53 NF=8.7 ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1307A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1d2-0609d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/adc-drivers/fully-differential-amplifiers/adl5565.html",
//     mfgPart: "ADL5565SMT",
//     models: "this part is missing models",
//     pn: "XM-B1D2-0609D",
//     price: 21000,
//     priceType: "cents",
//     props: [50, 6000, 15.5, 8.7, 16.5, null, 53],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1307_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0543",
//     catId: "AMP",
//     ctlBoard: "XM-C3T1-0304D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc-auh312.pdf",
//     description: "F=500M-80G G=8dB P1dB=13.5 IP3=23 NF=5 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0543D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1h2-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc-auh312-die.html",
//     mfgPart: "HMC-AUH312DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1H2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1H2-0304D",
//     price: 137600,
//     priceType: "cents",
//     props: [500, 80000, 8, 5, 13.5, null, 23],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0543_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0566",
//     catId: "AMP",
//     ctlBoard: "XM-C349-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1086chips.pdf",
//     description: "F=2G-6G G=22dB IP3=48 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0566B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1h3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/hmc1086-die.html",
//     mfgPart: "HMC1086DIE",
//     models: "this part is missing models",
//     pn: "XM-B1H3-0404D",
//     price: 211700,
//     priceType: "cents",
//     props: [2000, 6000, 22, 0, 0, 14, 48],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0566_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0567",
//     catId: "AMP",
//     ctlBoard: "XM-B9J2-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/HMC1087chips.pdf",
//     description: "F=2G-20G G=11.0dB IP3=44 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0567B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1h5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/hmc1087-die.html#product-overview",
//     mfgPart: "HMC1087DIE",
//     models: "this part is missing models",
//     pn: "XM-B1H5-0404D",
//     price: 173000,
//     priceType: "cents",
//     props: [2000, 20000, 11, 0, 0, 39, 44],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0567_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0541",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc383chips.pdf",
//     description: "F=12G-30G G=16dB P1dB=16.5 IP3=25 NF=7 ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0541A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1j2-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/driver-amplifiers/hmc383-die.html",
//     mfgPart: "HMC383DIE",
//     models: "this part is missing models",
//     pn: "XM-B1J2-0204D",
//     price: 35000,
//     priceType: "cents",
//     props: [12000, 30000, 16, 7, 16.5, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B1",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0541_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0450",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc392Alc4.pdf",
//     description: "F=3.5G-8G G=17dB P1dB=19 IP3=34.5 NF=1.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0450B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1j3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc392alc4.html",
//     mfgPart: "HMC392ALC4SMT",
//     models: "this part is missing models",
//     pn: "XM-B1J3-0404D",
//     price: 20500,
//     priceType: "cents",
//     props: [3500, 8000, 17, 1.8, 19, null, 34.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0450_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0638",
//     catId: "AMP",
//     ctlBoard: "XM-B5U8-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc498.pdf",
//     description: "F=17G-24G G=22dB P1dB=24.5 IP3=36 NF=4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0638A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1j5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/driver-amplifiers/hmc498lc4.html",
//     mfgPart: "HMC498LC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1J5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1J5-0404D",
//     price: 20200,
//     priceType: "cents",
//     props: [17000, 24000, 22, 4, 24.5, null, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0638_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0558",
//     catId: "AMP",
//     ctlBoard: "XM-C7P5-0304D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc635chips.pdf",
//     description: "F=18G-40G G=19.5dB P1dB=23 IP3=29 NF=8 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0558B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1j9-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/driver-amplifiers/hmc635-die.html",
//     mfgPart: "HMC635DIE",
//     models: "this part is missing models",
//     pn: "XM-B1J9-0304D",
//     price: 57200,
//     priceType: "cents",
//     props: [18000, 40000, 19.5, 8, 23, null, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0792",
//     catId: "AMP",
//     ctlBoard: "XM-A7U1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PMA2-153LN+.pdf",
//     description: "F=500M-15G G=16.7dB P1dB=14.8 IP3=28 NF=2.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0792B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1l6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA2-33LN+2B",
//     mfgPart: "PMA2-153LN+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1L6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1L6-0404D",
//     price: 16000,
//     priceType: "cents",
//     props: [500, 15000, 16.7, 2.6, 14.8, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0792_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0720",
//     catId: "AMP",
//     ctlBoard: "XM-A8A2-0604D",
//     datasheetURL: "https://www.qorvo.com/products/d/da003954",
//     description: "F=2G-22G G=15dB P1dB=22 NF=2.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0720A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1l8-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA2525",
//     mfgPart: "TGA2227-SMSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1L8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1L8-0604D",
//     price: 34000,
//     priceType: "cents",
//     props: [2000, 22000, 15, 2.5, 22, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0720_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0803",
//     catId: "AMP",
//     ctlBoard: "XM-B921-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM1065-Datasheet-Latest.pdf",
//     description: "F=50M-8G G=20dB P1dB=18 IP3=31 NF=2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0803B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1m2-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1065/",
//     mfgPart: "AM1065SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1M2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1M2-0404D",
//     price: 14300,
//     priceType: "cents",
//     props: [50, 8000, 20, 2, 18, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0803_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0919",
//     catId: "AMP",
//     ctlBoard: "XM-B1M8-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006028",
//     description: "F=600M-6G G=21.6dB P1dB=19 IP3=35.5 NF=0.95 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0919A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1m7-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPM1000",
//     mfgPart: "QPL9503SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1M7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1M7-0404D",
//     price: 10300,
//     priceType: "cents",
//     props: [600, 6000, 21.6, 0.95, 19, null, 35.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0926",
//     catId: "AMP",
//     ctlBoard: "XM-B1N1-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da004221",
//     description: "F=2G-6G G=22dB P1dB=18 IP3=30 NF=1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0926A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1m9-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA4036",
//     mfgPart: "TGA2611-SMSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1M9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1M9-0404D",
//     price: 31700,
//     priceType: "cents",
//     props: [2000, 6000, 22, 1, 18, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0926_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0892",
//     catId: "AMP",
//     ctlBoard: "XM-B1N3-0604D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAM-011109.pdf",
//     description: "F=10M-40G G=13dB P1dB=18 IP3=24 NF=3.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0892A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1n2-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAM-011109",
//     mfgPart: "MAAM-011109SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1N2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1N2-0604D",
//     price: 22100,
//     priceType: "cents",
//     props: [10, 40000, 13, 3.5, 18, null, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0892_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1188",
//     catId: "AMP",
//     ctlBoard: "XM-B1P7-0509D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc865.pdf",
//     description: "F=50M-26.5G G=30dB P1dB=-6 ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1188A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1n8-0509d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/optical/fiber-optic-data/optical-limiting-amplifiers/hmc865.html",
//     mfgPart: "HMC865LC3SMT",
//     models: "this part is missing models",
//     pn: "XM-B1N8-0509D",
//     price: 54700,
//     priceType: "cents",
//     props: [50, 26500, 30, 0, -6, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1187",
//     catId: "AMP",
//     ctlBoard: "XM-B5R7-0509D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc750.pdf",
//     description: "F=50M-16G G=44dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1187A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1n9-0509d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc750.html",
//     mfgPart: "HMC750LP4SMT",
//     models: "this part is missing models",
//     pn: "XM-B1N9-0509D",
//     price: 30900,
//     priceType: "cents",
//     props: [50, 16000, 44, 0, 0, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1130",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc-alh369.pdf",
//     description: "F=24G-40G G=22dB P1dB=11 NF=2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1130A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1p2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc-alh369-die.html",
//     mfgPart: "HMC-ALH369DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1P2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1P2-0404D",
//     price: 54600,
//     priceType: "cents",
//     props: [24000, 40000, 22, 2, 11, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1130_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1425",
//     catId: "AMP",
//     ctlBoard: "XM-B1R7-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da004067",
//     description: "F=2G-20G G=17.5dB P1dB=20 IP3=19 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1425A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1r6-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA2567-SM",
//     mfgPart: "TGA2526DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1R6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1R6-0404D",
//     price: 57500,
//     priceType: "cents",
//     props: [2000, 20000, 17.5, 2.5, 20, 22, 19],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1425_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0934",
//     catId: "AMP",
//     ctlBoard: "XM-B1T9-0204D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc1040chips.pdf",
//     description: "F=24G-43.5G G=23dB P1dB=12 IP3=22 NF=2.2 ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0934B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1t8-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc1040-die.html",
//     mfgPart: "HMC1040CHIPSDIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1T8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1T8-0204D",
//     price: 43300,
//     priceType: "cents",
//     props: [24000, 43500, 23, 2.2, 12, null, 22],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B1",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0934_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0542",
//     catId: "AMP",
//     ctlBoard: "XM-B1U2-0304D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1126.pdf",
//     description: "F=2G-50G G=11dB P1dB=17.5 IP3=28 NF=4.5 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0542B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1u1-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc1126.html",
//     mfgPart: "HMC1126DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1U1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1U1-0304D",
//     price: 90800,
//     priceType: "cents",
//     props: [2000, 50000, 11, 4.5, 17.5, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0542_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1235",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5523.pdf",
//     description: "F=400M-4G G=21.5dB P1dB=21 IP3=34 NF=.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1235B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1v5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/adl5523.html",
//     mfgPart: "ADL5523ACPZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1V5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1V5-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [400, 4000, 21.5, 0.8, 21, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1235_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0888",
//     catId: "AMP",
//     ctlBoard: "XM-B1W3-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006392",
//     description: "F=7G-14G G=26dB P1dB=18 IP3=23 NF=1.1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0888B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w2-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA2626",
//     mfgPart: "QPA2609SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1W2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1W2-0404D",
//     price: 19200,
//     priceType: "cents",
//     props: [7000, 14000, 26, 1.1, 18, null, 23],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0888_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0888",
//     catId: "AMP",
//     ctlBoard: "XM-B1W5-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006235",
//     description: "F=13G-20G G=25.5dB P1dB=18 IP3=30 NF=1.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0888B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA4363A",
//     mfgPart: "QPA2735SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1W4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1W4-0404D",
//     price: 19200,
//     priceType: "cents",
//     props: [13000, 20000, 25.5, 1.3, 18, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0888_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0134",
//     catId: "AMP",
//     ctlBoard: "XM-B1W7-0804D",
//     datasheetURL:
//       "https://www.microsemi.com/document-portal/doc_download/1243900-mma041pp5-datasheet-rev-2",
//     description: "F=50M-25G G=17dB P1dB=22 IP3=35 NF=2.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0134M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w6-0804d/",
//     mfgCode: "MCRSM",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=AVA-183A+2B",
//     mfgPart: "MMA041PP5SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1W6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1W6-0804D",
//     price: 49400,
//     priceType: "cents",
//     props: [50, 25000, 17, 2.5, 22, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0134_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0730",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/AVA-183A+.pdf",
//     description: "F=5G-18G G=13.8dB P1dB=19 IP3=26.3 NF=4.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0730A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b216-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=AVA-183A-DG+2B",
//     mfgPart: "AVA-183A+SMT",
//     models: "this part is missing models",
//     pn: "XM-B216-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [5000, 18000, 13.8, 4.8, 19, null, 26.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0730_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1937",
//     catId: "AMP",
//     ctlBoard: "XM-B222-0504D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAP-011250.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b221-0506d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAP-011250",
//     mfgPart: "MAAP-011250SMT",
//     models: "this is the new part",
//     pn: "XM-B221-0506D",
//     price: 71900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1937_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1704",
//     catId: "AMP",
//     ctlBoard: "XM-B226-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da007527",
//     description: "F=50M-22G G=15dB P1dB=18 IP3=27 NF=2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1704A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b224-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD328K3",
//     mfgPart: "CMD240C4SMT",
//     models: "this part is missing models",
//     pn: "XM-B224-0404D",
//     price: 36600,
//     priceType: "cents",
//     props: [50, 22000, 15, 2, 18, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1704_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0802",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc453st89.pdf",
//     description: "F=400M-2.2G G=20.5dB P1dB=31.5 IP3=49 NF=6.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0802B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2a2-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/hmc453st89.html",
//     mfgPart: "HMC453ST89ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2A2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2A2-0604D",
//     price: 20300,
//     priceType: "cents",
//     props: [400, 2200, 20.5, 6.5, 31.5, null, 49],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0802",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc453st89.pdf",
//     description: "F=400M-2.2G G=20.5dB P1dB=31.5 IP3=49 NF=6.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0802B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2a3-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/hmc453st89.html",
//     mfgPart: "HMC453ST89ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2A3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2A3-0604D",
//     price: 20300,
//     priceType: "cents",
//     props: [400, 2200, 20.5, 6.5, 31.5, null, 49],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0800",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc453st89.pdf",
//     description: "F=400M-2.2G G=20.5dB P1dB=31.5 IP3=49 NF=6.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0800A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2a4-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/hmc453st89.html",
//     mfgPart: "HMC453ST89ESMT",
//     models: "this part is missing models",
//     pn: "XM-B2A4-0804D",
//     price: 22500,
//     priceType: "cents",
//     props: [400, 2200, 20.5, 6.5, 31.5, null, 49],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0800_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0800",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc453st89.pdf",
//     description: "F=400M-2.2G G=20.5dB P1dB=31.5 IP3=49 NF=6.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0800A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2a5-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/hmc453st89.html",
//     mfgPart: "HMC453ST89ESMT",
//     models: "this part is missing models",
//     pn: "XM-B2A5-0804D",
//     price: 22500,
//     priceType: "cents",
//     props: [400, 2200, 20.5, 6.5, 31.5, null, 49],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0800_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1235",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5523.pdf",
//     description: "F=400M-4G G=21.5dB P1dB=21 IP3=34 NF=.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1235B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2a8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/adl5523.html",
//     mfgPart: "ADL5523ACPZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2A8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2A8-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [400, 4000, 21.5, 0.8, 21, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1235_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1016",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LEE2-6+.pdf",
//     description: "F=50M-7G G=18.9dB P1dB=2.8 IP3=17.6 NF=2.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1016A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2e2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LHA-23HLN+2B",
//     mfgPart: "LEE2-6+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2E2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2E2-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [50, 7000, 18.9, 2.3, 2.8, null, 17.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1016_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1078",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PSA-5451+.pdf",
//     description: "F=50M-4G G=18.8dB P1dB=16.2 IP3=29 NF=.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1078A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2e3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=TSS-44+2B",
//     mfgPart: "PSA-5451+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2E3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2E3-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 4000, 18.8, 0.7, 16.2, null, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1078_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.skyworksinc.com/-/media/SkyWorks/Documents/Products/101-200/SKY65013_70LF_200360H.pdf",
//     description: "F=100M-7G G=12.5dB P1dB=12.5 NF=5.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2m6-0404d/",
//     mfgCode: "SKYWK",
//     mfgLandingPage: "http://www.skyworksinc.com/Product/334/SKY65013-70LF",
//     mfgPart: "SKY65013-70LFSMT",
//     models: "this part is missing models",
//     pn: "XM-B2M6-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [100, 7000, 12.5, 5.5, 12.5, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1071",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://datasheets.maximintegrated.com/en/ds/MAX2611.pdf",
//     description: "F=50M-1.1G G=18dB P1dB=2.9 IP3=13 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1071A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2m7-0404d/",
//     mfgCode: "MAXIM",
//     mfgLandingPage:
//       "https://www.maximintegrated.com/en/products/comms/wireless-rf/MAX2611.html",
//     mfgPart: "MAX2611SMT",
//     models: "this part is missing models",
//     pn: "XM-B2M7-0404D",
//     price: 8100,
//     priceType: "cents",
//     props: [50, 1100, 18, 3.5, 2.9, null, 13],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0854",
//     catId: "AMP",
//     ctlBoard: "XM-C9P6-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADPA7001CHIPS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b2p8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adpa7001-die.html",
//     mfgPart: "ADPA7001-DIEDIE",
//     models: "this is the new part",
//     pn: "XM-B2P8-0404D",
//     price: 115100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0854_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0815",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2019/09/AM1082-Datasheet-Latest.pdf",
//     description: "F=5G-17G G=22dB P1dB=20 IP3=31 NF=3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0815A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b3u1-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1082/",
//     mfgPart: "AM1082SMT",
//     models: "this part is missing models",
//     pn: "XM-B3U1-0404D",
//     price: 16300,
//     priceType: "cents",
//     props: [5000, 17000, 22, 3, 20, 22, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0815_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0855",
//     catId: "AMP",
//     ctlBoard: "XM-B4T8-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADL7003.pdf",
//     description: "F=50G-95G G=14dB P1dB=14 IP3=21 NF=5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0855B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4r3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/adl7003.html#product-overview",
//     mfgPart: "ADL7003DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4R3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4R3-0404D",
//     price: 102900,
//     priceType: "cents",
//     props: [50000, 95000, 14, 5, 14, null, 21],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0855_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1365",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/HMC8325.pdf",
//     description: "F=71G-86G G=21dB P1dB=13 IP3=22 NF=3.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1365A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4r6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc8325.html",
//     mfgPart: "HMC8325DIE",
//     models: "this part is missing models",
//     pn: "XM-B4R6-0404D",
//     price: 67100,
//     priceType: "cents",
//     props: [71000, 86000, 21, 3.6, 13, null, 22],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1365_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1399",
//     catId: "AMP",
//     ctlBoard: "XM-A2J6-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/RA66_SMRA66.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b4t5-0706d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SMRA66",
//     mfgPart: "SMRA66SMT",
//     models: "this is the new part",
//     pn: "XM-B4T5-0706D",
//     price: 89700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1399_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0844",
//     catId: "AMP",
//     ctlBoard: "XM-C3W4-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc637bpm5e.pdf",
//     description: "F=50M-7.5G G=15.5dB P1dB=28 IP3=39 NF=3.5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0844A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4v2-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "http://www.analog.com/en/products/hmc637bpm5e.html",
//     mfgPart: "HMC637BPM5ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4V2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4V2-0804D",
//     price: 44200,
//     priceType: "cents",
//     props: [50, 7500, 15.5, 3.5, 28, null, 39],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0844_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0643",
//     catId: "AMP",
//     ctlBoard: "XM-A2J6-0404D",
//     datasheetURL:
//       "https://www.teledynedefenseelectronics.com/microwavesolutions/Products/Documents/AS5066.pdf#search=as5066",
//     description: "F=100M-5.2G G=19.5dB P1dB=20 IP3=35 NF=4 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0643A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4v7-0604d/",
//     mfgCode: "TLDYN",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "AS5066SMT",
//     models: "this part is missing models",
//     pn: "XM-B4V7-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [100, 5200, 19.5, 4, 20, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0643_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0643",
//     catId: "AMP",
//     ctlBoard: "XM-A2J6-0404D",
//     datasheetURL:
//       "https://www.teledynedefenseelectronics.com/microwavesolutions/Products/Documents/AS8002.pdf#search=as8002",
//     description: "F=1G-5G G=19.5dB P1dB=20 NF=4 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0643A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4v8-0604d/",
//     mfgCode: "TLDYN",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "AS8002SMT",
//     models: "this part is missing models",
//     pn: "XM-B4V8-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [1000, 5000, 19.5, 4, 20, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0643_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-51+.pdf",
//     description: "F=50M-4G G=16.1dB P1dB=18 IP3=35 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5a2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=Gali-51%2B",
//     mfgPart: "Gali-51+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5A2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5A2-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 4000, 16.1, 3.5, 18, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0983",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PMA2-33LN+.pdf",
//     description: "F=400M-3G G=19.1dB P1dB=17.2 IP3=34.5 NF=0.38 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0983A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5a3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA3-83LN+2B",
//     mfgPart: "PMA2-33LN+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5A3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5A3-0404D",
//     price: 9500,
//     priceType: "cents",
//     props: [400, 3000, 19.1, 0.38, 17.2, null, 34.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0983_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0001",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ERA-50SM+.pdf",
//     description: "F=50M-2G G=19.4dB P1dB=17.2 IP3=33 NF=3.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0001H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5a7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ERA-5SM+2B",
//     mfgPart: "ERA-50SM+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5A7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5A7-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 2000, 19.4, 3.3, 17.2, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0001_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1084",
//     catId: "AMP",
//     ctlBoard: "XM-A4E3-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LHA-23HLN+.pdf",
//     description: "F=30M-2G G=21.5dB P1dB=28.3 IP3=44.7 NF=1.3 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1084A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LTA-183M-DG+2B",
//     mfgPart: "LHA-23HLN+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5E3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5E3-0604D",
//     price: 11800,
//     priceType: "cents",
//     props: [30, 2000, 21.5, 1.3, 28.3, null, 44.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0844",
//     catId: "AMP",
//     ctlBoard: "XM-B5E5-0804D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc998apm5e.pdf",
//     description: "F=50M-22G G=15dB P1dB=32 IP3=42 NF=3 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0844A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e4-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc998apm5e.html",
//     mfgPart: "HMC998APM5ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5E4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5E4-0804D",
//     price: 64100,
//     priceType: "cents",
//     props: [50, 22000, 15, 3, 32, null, 42],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0844_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1085",
//     catId: "AMP",
//     ctlBoard: "XM-B5E7-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc753.pdf",
//     description: "F=1G-11G G=16.5dB P1dB=18 IP3=30 NF=1.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1085A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e6-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/low-noise-amplifiers/hmc753.html",
//     mfgPart: "HMC753LP4ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5E6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5E6-0604D",
//     price: 25900,
//     priceType: "cents",
//     props: [1000, 11000, 16.5, 1.5, 18, 20, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1085_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1189",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM1063-2-Datasheet-Latest.pdf",
//     description: "F=50M-10G G=15dB P1dB=18 IP3=30 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1189B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5r3-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage:
//       "https://www.atlantamicro.com/products/amplifiers/am1063-2/",
//     mfgPart: "AM1063-2SMT",
//     models: "this part is missing models",
//     pn: "XM-B5R3-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [50, 10000, 15, 2.5, 18, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1189_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1157",
//     catId: "AMP",
//     ctlBoard: "XM-B5R6-0409D",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/onet4201pa.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5r4-0409d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/store/ti/en/p/product?p=ONET4201PARGTR",
//     mfgPart: "ONET4201PARGTRSMT",
//     models: "this is the new part",
//     pn: "XM-B5R4-0409D",
//     price: 16000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0962",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PSA4-5043+.pdf",
//     description: "F=50M-4G G=18.4dB P1dB=21 IP3=33.5 NF=0.75 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0962A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5r9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PSA-5451+2B",
//     mfgPart: "PSA4-5043+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5R9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5R9-0404D",
//     price: 7900,
//     priceType: "cents",
//     props: [50, 4000, 18.4, 0.75, 21, null, 33.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1231",
//     catId: "AMP",
//     ctlBoard: "XM-B5T4-0304D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/AMM-6702.pdf",
//     description: "F=20G-55G G=25dB P1dB=14.8 IP3=21 NF=6.5 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1231B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5t3-0304d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/amplifiers/amm-6702.aspx",
//     mfgPart: "AMM-6702CHDIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5T3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5T3-0304D",
//     price: 64800,
//     priceType: "cents",
//     props: [20000, 55000, 25, 6.5, 14.8, 20, 21],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1122",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b6c4-0609d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/amplifiers/xm-b6c4-0609d",
//     mfgPart: "TC964PSMT",
//     models: "this is the new part",
//     pn: "XM-B6C4-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0470",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000266",
//     description: "F=50M-4G G=14.8dB P1dB=14.5 IP3=29.2 NF=3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0470A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6d4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA4501",
//     mfgPart: "QPA4363ASMT",
//     models: "this part is missing models",
//     pn: "XM-B6D4-0404D",
//     price: 7700,
//     priceType: "cents",
//     props: [50, 4000, 14.8, 3, 14.5, null, 29.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0470",
//     catId: "AMP",
//     ctlBoard: "XM-A4E3-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005872",
//     description: "F=50M-3.5G G=20.4dB P1dB=16.2 IP3=29.4 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0470A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6d5-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA5263A",
//     mfgPart: "QPA4563ASMT",
//     models: "this part is missing models",
//     pn: "XM-B6D5-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [50, 3500, 20.4, 2.5, 16.2, null, 29.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0470",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006023",
//     description: "F=50M-4.5G G=12.5dB P1dB=15.2 IP3=30.5 NF=4.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0470A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6d6-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA5389A",
//     mfgPart: "QPA5263ASMT",
//     models: "this part is missing models",
//     pn: "XM-B6D6-0404D",
//     price: 7700,
//     priceType: "cents",
//     props: [50, 4500, 12.5, 4.4, 15.2, null, 30.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A6E9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005894",
//     description: "F=50M-4.5G G=15.9dB P1dB=16.3 IP3=30 NF=3.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6d7-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA6489A",
//     mfgPart: "QPA5389ASMT",
//     models: "this part is missing models",
//     pn: "XM-B6D7-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 4500, 15.9, 3.7, 16.3, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005875",
//     description: "F=20M-3.5G G=19.2dB P1dB=19.4 IP3=31.9 NF=3.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6d9-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA7489A",
//     mfgPart: "QPA6489ASMT",
//     models: "this part is missing models",
//     pn: "XM-B6D9-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [20, 3500, 19.2, 3.4, 19.4, null, 31.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000268",
//     description: "F=50M-3.5G G=17.7dB P1dB=21.1 IP3=34.1 NF=3.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6e2-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA9801",
//     mfgPart: "QPA7489ASMT",
//     models: "this part is missing models",
//     pn: "XM-B6E2-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [50, 3500, 17.7, 3.2, 21.1, null, 34.1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0888",
//     catId: "AMP",
//     ctlBoard: "XM-C3W3-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da003595",
//     description: "F=22G-32G G=23dB P1dB=19 IP3=27 NF=1.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0888B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6f8-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA2735",
//     mfgPart: "QPA2628SMT",
//     models: "this part is missing models",
//     pn: "XM-B6F8-0404D",
//     price: 22800,
//     priceType: "cents",
//     props: [22000, 32000, 23, 1.6, 19, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0888_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0888",
//     catId: "AMP",
//     ctlBoard: "XM-B5U7-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da003591",
//     description: "F=17G-22G G=25dB P1dB=20 IP3=28 NF=1.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0888B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6f9-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA2628",
//     mfgPart: "QPA2626SMT",
//     models: "this part is missing models",
//     pn: "XM-B6F9-0404D",
//     price: 23200,
//     priceType: "cents",
//     props: [17000, 22000, 25, 1.3, 20, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0888_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1174",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006538",
//     description: "F=600M-4.2G G=20dB P1dB=17 IP3=32 NF=0.54 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1174A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6h1-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPL9503",
//     mfgPart: "QPL9057SMT",
//     models: "this part is missing models",
//     pn: "XM-B6H1-0404D",
//     price: 9900,
//     priceType: "cents",
//     props: [600, 4200, 20, 0.54, 17, null, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1174_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1400",
//     catId: "AMP",
//     ctlBoard: "XM-C375-0604D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAL-011141.pdf",
//     description: "F=50M-28G G=17.5dB P1dB=15.5 IP3=26 NF=1.4 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1400A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6k7-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAL-011141",
//     mfgPart: "MAAL-011141SMT",
//     models: "this part is missing models",
//     pn: "XM-B6K7-0604D",
//     price: 37600,
//     priceType: "cents",
//     props: [50, 28000, 17.5, 1.4, 15.5, null, 26],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1400_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0508",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5530.pdf",
//     description: "F=50M-1G G=16.5dB P1dB=21.7 IP3=37 NF=3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0508A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6m9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/gain-blocks/adl5530.html",
//     mfgPart: "ADL5530ACPZSMT",
//     models: "this part is missing models",
//     pn: "XM-B6M9-0404D",
//     price: 8900,
//     priceType: "cents",
//     props: [50, 1000, 16.5, 3, 21.7, null, 37],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0508_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0508",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAM-011229.pdf",
//     description: "F=50M-4G G=19dB P1dB=19.5 IP3=36 NF=1.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0508A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6n1-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAM-011229",
//     mfgPart: "MAAM-011229SMT",
//     models: "this part is missing models",
//     pn: "XM-B6N1-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: [50, 4000, 19, 1.3, 19.5, null, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0508_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0725",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005517",
//     description: "F=20M-4G G=20.5dB P1dB=21 IP3=37 NF=1.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0725A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7f5-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQP3M9028",
//     mfgPart: "TQP3M9018SMT",
//     models: "this part is missing models",
//     pn: "XM-B7F5-0404D",
//     price: 8200,
//     priceType: "cents",
//     props: [20, 4000, 20.5, 1.3, 21, null, 37],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0725_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0891",
//     catId: "AMP",
//     ctlBoard: "XM-B1M1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc998a.pdf",
//     description: "F=50M-22G G=14.5dB P1dB=32.5 IP3=43 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0891B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7h4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/hmc998a-die.html#product-overview",
//     mfgPart: "HMC998ADIE",
//     models: "this part is missing models",
//     pn: "XM-B7H4-0404D",
//     price: 96000,
//     priceType: "cents",
//     props: [50, 22000, 14.5, 2.5, 32.5, 33.5, 43],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0891_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005510",
//     description: "F=50M-4G G=21.8dB P1dB=22 IP3=39.5 NF=1.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7p6-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQP3M9018",
//     mfgPart: "TQP3M9009SMT",
//     models: "this part is missing models",
//     pn: "XM-B7P6-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [50, 4000, 21.8, 1.3, 22, null, 39.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005528",
//     description: "F=50M-4G G=14.7dB P1dB=20.7 IP3=40 NF=1.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b834-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQP3M9035",
//     mfgPart: "TQP3M9028SMT",
//     models: "this part is missing models",
//     pn: "XM-B834-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [50, 4000, 14.7, 1.8, 20.7, null, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005528",
//     description: "F=50M-4G G=14.7dB P1dB=20.7 IP3=40 NF=1.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b835-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQP3M9035",
//     mfgPart: "TQP3M9028SMT",
//     models: "this part is missing models",
//     pn: "XM-B835-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [50, 4000, 14.7, 1.8, 20.7, null, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005528",
//     description: "F=50M-4G G=14.7dB P1dB=20.7 IP3=40 NF=1.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b836-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQP3M9035",
//     mfgPart: "TQP3M9028SMT",
//     models: "this part is missing models",
//     pn: "XM-B836-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [50, 4000, 14.7, 1.8, 20.7, null, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005528",
//     description: "F=50M-4G G=14.7dB P1dB=20.7 IP3=40 NF=1.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b837-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQP3M9035",
//     mfgPart: "TQP3M9028SMT",
//     models: "this part is missing models",
//     pn: "XM-B837-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [50, 4000, 14.7, 1.8, 20.7, null, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0187",
//     catId: "AMP",
//     ctlBoard: "XM-B5G7-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd270p3-datasheet-1.pdf",
//     description: "F=4G-8G G=16dB P1dB=18 IP3=28 NF=1.7 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0187L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8d3-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd270p3-low-noise-amplifier/",
//     mfgPart: "CMD270P3SMT",
//     models: "this part is missing models",
//     pn: "XM-B8D3-0404D",
//     price: 17300,
//     priceType: "cents",
//     props: [4000, 8000, 16, 1.7, 18, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0187_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1209",
//     catId: "AMP",
//     ctlBoard: "XM-B9H5-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd283c3-1218.pdf",
//     description: "F=2G-6G G=27dB P1dB=16 IP3=26 NF=0.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1209A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8d5-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd283c3/",
//     mfgPart: "CMD283C3SMT",
//     models: "this part is missing models",
//     pn: "XM-B8D5-0404D",
//     price: 16300,
//     priceType: "cents",
//     props: [2000, 6000, 27, 0.6, 16, null, 26],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1209_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1234",
//     catId: "AMP",
//     ctlBoard: "XM-B8D7-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006580",
//     description: "F=2G-20G G=25dB NF=4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1234B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8d6-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA2609",
//     mfgPart: "QPA2213SMT",
//     models: "this part is missing models",
//     pn: "XM-B8D6-0404D",
//     price: 64700,
//     priceType: "cents",
//     props: [2000, 20000, 25, 4, 0, 34, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1234_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1225",
//     catId: "AMP",
//     ctlBoard: "XM-B8E5-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005536",
//     description: "F=50M-6G G=16.5dB P1dB=22.5 IP3=37 NF=0.65 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1225A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8d8-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQP3M9036",
//     mfgPart: "TQP3M9035SMT",
//     models: "this part is missing models",
//     pn: "XM-B8D8-0404D",
//     price: 9600,
//     priceType: "cents",
//     props: [50, 6000, 16.5, 0.65, 22.5, null, 37],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1225_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1225",
//     catId: "AMP",
//     ctlBoard: "XM-C811-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005549",
//     description: "F=700M-6G G=20dB P1dB=20 IP3=35 NF=0.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1225A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8d9-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.rfmw.com/ProductDetail/QBH5024PM-API/586324/",
//     mfgPart: "TQP3M9037SMT",
//     models: "this part is missing models",
//     pn: "XM-B8D9-0404D",
//     price: 9300,
//     priceType: "cents",
//     props: [700, 6000, 20, 0.4, 20, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1225_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1225",
//     catId: "AMP",
//     ctlBoard: "XM-B8E5-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005282",
//     description: "F=500M-6G G=16.3dB P1dB=21.3 IP3=43.6 NF=1.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1225A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8e1-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQL9092",
//     mfgPart: "TQL9062SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B8E1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B8E1-0404D",
//     price: 9800,
//     priceType: "cents",
//     props: [500, 6000, 16.3, 1.4, 21.3, null, 43.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1225_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1174",
//     catId: "AMP",
//     ctlBoard: "XM-B8E5-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005311",
//     description: "F=600M-4.2G G=20dB P1dB=21.7 IP3=41.5 NF=0.67 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1174A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8e2-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQP3M9008",
//     mfgPart: "TQL9093SMT",
//     models: "this part is missing models",
//     pn: "XM-B8E2-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [600, 4200, 20, 0.67, 21.7, null, 41.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1174_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da001302",
//     description: "F=50M-6G G=15.5dB P1dB=19.2 IP3=35.2 NF=4.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8e3-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/SBB5089Z",
//     mfgPart: "SBB4089ZSMT",
//     models: "this part is missing models",
//     pn: "XM-B8E3-0404D",
//     price: 8900,
//     priceType: "cents",
//     props: [50, 6000, 15.5, 4.3, 19.2, null, 35.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da001309",
//     description: "F=50M-6G G=20.1dB P1dB=20.4 IP3=38.5 NF=3.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8e4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/SGL0622Z",
//     mfgPart: "SBB5089ZSMT",
//     models: "this part is missing models",
//     pn: "XM-B8E4-0404D",
//     price: 8900,
//     priceType: "cents",
//     props: [50, 6000, 20.1, 3.9, 20.4, null, 38.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0554",
//     catId: "AMP",
//     ctlBoard: "XM-C437-0204D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd162-26-34-ghz-low-noise-amplifier-mmic.pdf",
//     description: "F=26G-34G G=22dB P1dB=7 IP3=16 NF=1.7 ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0554A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8r8-0204d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd162_low_noise_amplifier/",
//     mfgPart: "CMD162DIE",
//     models: "this part is missing models",
//     pn: "XM-B8R8-0204D",
//     price: 42100,
//     priceType: "cents",
//     props: [26000, 34000, 22, 1.7, 7, 8.5, 16],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B1",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0554_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0545",
//     catId: "AMP",
//     ctlBoard: "XM-B8T4-0304D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc-aph596.pdf",
//     description: "F=16G-33G G=17dB P1dB=24 IP3=33 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0545C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8t2-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/hmc-aph596-die.html",
//     mfgPart: "HMC-APH596DIE",
//     models: "this part is missing models",
//     pn: "XM-B8T2-0304D",
//     price: 42100,
//     priceType: "cents",
//     props: [16000, 33000, 17, 0, 24, 0, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0545_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1228",
//     catId: "AMP",
//     ctlBoard: "XM-B8T5-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc-auh256.pdf",
//     description: "F=17.5G-41G G=21dB P1dB=20 IP3=27 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1228A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8t3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc-auh256-die.html",
//     mfgPart: "HMC-AUH256DIE",
//     models: "this part is missing models",
//     pn: "XM-B8T3-0404D",
//     price: 44500,
//     priceType: "cents",
//     props: [17500, 41000, 21, 0, 20, 23, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1228_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1285",
//     catId: "AMP",
//     ctlBoard: "XM-B8U7-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc-alh444.pdf",
//     description: "F=1G-12G G=17dB P1dB=19 IP3=28 NF=1.75 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1285A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8u6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc-alh444-die.html",
//     mfgPart: "HMC-ALH444DIE",
//     models: "this part is missing models",
//     pn: "XM-B8U6-0404D",
//     price: 44500,
//     priceType: "cents",
//     props: [1000, 12000, 17, 1.75, 19, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1285_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1282",
//     catId: "AMP",
//     ctlBoard: "XM-B8U9-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADPA7002CHIP.pdf",
//     description: "F=20G-44G G=15dB P1dB=28 IP3=40 NF=6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1282B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8u8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adpa7002.html",
//     mfgPart: "ADPA7002DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B8U8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B8U8-0404D",
//     price: 61100,
//     priceType: "cents",
//     props: [20000, 44000, 15, 6, 28, 30, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005504",
//     description: "F=50M-4G G=20.6dB P1dB=20 IP3=36 NF=1.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b988-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQP3M9009",
//     mfgPart: "TQP3M9008SMT",
//     models: "this part is missing models",
//     pn: "XM-B988-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [50, 4000, 20.6, 1.3, 20, null, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1344",
//     catId: "AMP",
//     ctlBoard: "XM-C117-0505D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006610",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b997-0506d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA2227-SM",
//     mfgPart: "TGA2222DIE",
//     models: "this is the new part",
//     pn: "XM-B997-0506D",
//     price: 135000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1344_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1415",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lmh5401.pdf",
//     description: "F=DC-2G G=12dB NF=9.6 ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1415A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c352-0609d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LMH5401",
//     mfgPart: "LMH5401SMT",
//     models: "this part is missing models",
//     pn: "XM-C352-0609D",
//     price: 23100,
//     priceType: "cents",
//     props: [0, 2000, 12, 9.6, 0, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1415_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0186",
//     catId: "AMP",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAL-011111.pdf",
//     description: "F=22G-38G G=19dB P1dB=5 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0186G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c366-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAL-011111",
//     mfgPart: "MAAL-011111SMT",
//     models: "this part is missing models",
//     pn: "XM-C366-0404D",
//     price: 14500,
//     priceType: "cents",
//     props: [22000, 38000, 19, 2.5, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0186_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1382",
//     catId: "AMP",
//     ctlBoard: "XM-B9K4-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/TSS-44+.pdf",
//     description: "F=22G-43.5G G=17.6dB P1dB=4.1 IP3=12.7 NF=3.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1382A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c368-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=TSS-44-DG+2B",
//     mfgPart: "TSS-44+SMT",
//     models: "this part is missing models",
//     pn: "XM-C368-0404D",
//     price: 14500,
//     priceType: "cents",
//     props: [22000, 43500, 17.6, 3.2, 4.1, null, 12.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1382_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1417",
//     catId: "AMP",
//     ctlBoard: "XM-C3T3-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd292-1018.pdf",
//     description: "F=50M-30G G=13dB P1dB=27 IP3=33 NF=3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1417A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c388-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/cmd292-distributed-driver-amplifier/",
//     mfgPart: "CMD292DIE",
//     models: "this part is missing models",
//     pn: "XM-C388-0404D",
//     price: 71800,
//     priceType: "cents",
//     props: [50, 30000, 13, 3, 27, 28.5, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1417_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0516",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc589a.pdf",
//     description: "F=50M-4G G=21dB P1dB=21 IP3=33 NF=4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0516B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3m3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/gain-blocks/hmc589a.html",
//     mfgPart: "HMC589AST89SMT",
//     models: "this part is missing models",
//     pn: "XM-C3M3-0404D",
//     price: 9600,
//     priceType: "cents",
//     props: [50, 4000, 21, 4, 21, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0429",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc327.pdf",
//     description: "F=3G-4G G=21dB P1dB=27 IP3=40 NF=5 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0429B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3n4-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/hmc327.html",
//     mfgPart: "HMC327MS8GESMT",
//     models: "this part is missing models",
//     pn: "XM-C3N4-0804D",
//     price: 16800,
//     priceType: "cents",
//     props: [3000, 4000, 21, 5, 27, 30, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1425",
//     catId: "AMP",
//     ctlBoard: "XM-C3U4-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da004065",
//     description: "F=2G-18G G=17dB P1dB=17 IP3=29 NF=2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1425A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3u3-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA2526",
//     mfgPart: "TGA2525DIE",
//     models: "this part is missing models",
//     pn: "XM-C3U3-0404D",
//     price: 76700,
//     priceType: "cents",
//     props: [2000, 18000, 17, 2, 17, 22, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1425_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1464",
//     catId: "AMP",
//     ctlBoard: "XM-C3V3-0304D",
//     datasheetURL: "https://www.qorvo.com/products/d/da004466",
//     description: "F=19G-38G G=20dB P1dB=21 IP3=30 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1464A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3v2-0304d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA4036",
//     mfgPart: "TGA4036DIE",
//     models: "this part is missing models",
//     pn: "XM-C3V2-0304D",
//     price: 54300,
//     priceType: "cents",
//     props: [19000, 38000, 20, 0, 21, 22, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1464_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0571",
//     catId: "AMP",
//     ctlBoard: "XM-C3V5-0304D",
//     datasheetURL: "https://www.qorvo.com/products/d/da004528",
//     description: "F=30G-40G G=18dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0571C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3v4-0304d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQL9062",
//     mfgPart: "TGA4516-TSDIE",
//     models: "this part is missing models",
//     pn: "XM-C3V4-0304D",
//     price: 49600,
//     priceType: "cents",
//     props: [30000, 40000, 18, 0, 0, 33, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0571_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1205",
//     catId: "AMP",
//     ctlBoard: "XM-C3Y8-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/HMC1144.pdf",
//     description: "F=35G-70G G=19dB P1dB=21 IP3=28 NF=5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1205A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3y7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc1144-die.html",
//     mfgPart: "HMC1144DIE",
//     models: "this part is missing models",
//     pn: "XM-C3Y7-0404D",
//     price: 165200,
//     priceType: "cents",
//     props: [35000, 70000, 19, 5, 21, 22, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1205_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1321",
//     catId: "AMP",
//     ctlBoard: "XM-C411-0204D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd299-0119.pdf",
//     description: "F=18G-40G G=17dB P1dB=7 IP3=18 NF=3.5 ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1321B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3y9-0204d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd299-low-noise-amplifier/",
//     mfgPart: "CMD299DIE",
//     models: "this part is missing models",
//     pn: "XM-C3Y9-0204D",
//     price: 41400,
//     priceType: "cents",
//     props: [18000, 40000, 17, 3.5, 7, null, 18],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B1",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1321_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1322",
//     catId: "AMP",
//     ctlBoard: "XM-C413-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/CMD304-datasheet.pdf",
//     description: "F=50M-67G G=9.5dB P1dB=11 IP3=21 NF=3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1322B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c412-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd304/",
//     mfgPart: "CMD304DIE",
//     models: "this part is missing models",
//     pn: "XM-C412-0404D",
//     price: 79800,
//     priceType: "cents",
//     props: [50, 67000, 9.5, 3, 11, 14, 21],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1322_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1366",
//     catId: "AMP",
//     ctlBoard: "XM-C417-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/HMC1082CHIP.pdf",
//     description: "F=5.5G-18G G=24dB P1dB=25.5 IP3=36 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1366A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c416-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/hmc1082.html?doc=hmc1082chip.pdf",
//     mfgPart: "HMC1082CHIPDIE",
//     models: "this part is missing models",
//     pn: "XM-C416-0404D",
//     price: 41400,
//     priceType: "cents",
//     props: [5500, 18000, 24, 0, 25.5, 26, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1366_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1483",
//     catId: "AMP",
//     ctlBoard: "XM-C443-0404D",
//     datasheetURL:
//       "http://www.mwtinc.com/wp-content/uploads/2017/01/MMA-174321-M4.pdf",
//     description: "F=17G-43G G=22dB P1dB=21 IP3=26 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1483A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c442-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.mwtinc.com/products/mmic-amplifiers/",
//     mfgPart: "MMA-174321-M4 (Amp)DIE",
//     models: "this part is missing models",
//     pn: "XM-C442-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [17000, 43000, 22, 0, 21, 22, 26],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1483_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1513",
//     catId: "AMP",
//     ctlBoard: "XM-C445-0505D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAP-015016-DIE.pdf",
//     description: "F=32G-38G G=18dB ",
//     eccn: "",
//     ffNum: 10,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1513A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c444-0505d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MAAP-015016-DIE",
//     mfgPart: "MAAP-015016-DIEDIE",
//     models: "this part is missing models",
//     pn: "XM-C444-0505D",
//     price: 168400,
//     priceType: "cents",
//     props: [32000, 38000, 18, 0, 0, 37, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1513_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1459",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.rfmw.com/datasheets/keysight/HMMC-5628%20TC283P%20rev3_2.pdf",
//     description: "F=DC-26.5G G=17dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1459A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c458-0404d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://www.rfmw.com/products/detail/hmmc5628tr1-keysight-technologies/679179/?pid=",
//     mfgPart: "TC283P / HMMC-5628SMT",
//     models: "this part is missing models",
//     pn: "XM-C458-0404D",
//     price: 31400,
//     priceType: "cents",
//     props: [0, 26500, 17],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1459_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1831",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://literature.cdn.keysight.com/litweb/pdf/5989-6211EN.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4a6-0304d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://www.keysight.com/en/pd-1066220-pn-HMMC-5200/dc-20-ghz-hbt-series-shunt-amplifier?nid=-35628.626520&cc=US&lc=eng",
//     mfgPart: "TC200 / HMMC-5200 / 1GC1-8000DIE",
//     models: "this is the new part",
//     pn: "XM-C4A6-0304D",
//     price: 43600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1831_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1295",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CMA-545G1+.pdf",
//     description: "F=400M-2.2G G=31.5dB P1dB=23.3 IP3=37 NF=1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1295B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c563-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CMA-63+2B",
//     mfgPart: "CMA-545G1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C563-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [400, 2200, 31.5, 1, 23.3, 0, 37],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1295_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1350",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CMA-103+.pdf",
//     description: "F=50M-4G G=10.9dB P1dB=23.1 IP3=45 NF=.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1350A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c564-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CMA-183L+2B",
//     mfgPart: "CMA-103+SMT",
//     models: "this part is missing models",
//     pn: "XM-C564-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [50, 4000, 10.9, 0.5, 23.1, null, 45],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1350_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1564",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAM-009286.pdf",
//     description: "F=250M-4G G=15.5dB P1dB=27 IP3=42 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1564A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c582-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAM-009286",
//     mfgPart: "MAAM-009286SMT",
//     models: "this part is missing models",
//     pn: "XM-C582-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: [250, 4000, 15.5, 3.5, 27, null, 42],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1415",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lmh5401.pdf",
//     description: "F=DC-2G G=12dB NF=9.6 ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1415A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c583-0609d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LMH5401",
//     mfgPart: "LMH5401SMT",
//     models: "this part is missing models",
//     pn: "XM-C583-0609D",
//     price: 23500,
//     priceType: "cents",
//     props: [0, 2000, 12, 9.6, 0, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1415_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1073",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc451lc3.pdf",
//     description: "F=5G-20G G=19dB P1dB=19 IP3=30 NF=6.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1073A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c598-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/driver-amplifiers/hmc451lc3.html",
//     mfgPart: "HMC451LC3SMT",
//     models: "this part is missing models",
//     pn: "XM-C598-0404D",
//     price: 17200,
//     priceType: "cents",
//     props: [5000, 20000, 19, 6.5, 19, 21, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1073_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1589",
//     catId: "AMP",
//     ctlBoard: "XM-C6G8-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006389",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6g7-0909d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA4563A",
//     mfgPart: "QPA4501SMT",
//     models: "this is the new part",
//     pn: "XM-C6G7-0909D",
//     price: 37900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1415",
//     catId: "AMP",
//     ctlBoard: "XM-C6H8-0609D",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lmh5401.pdf",
//     description: "F=DC-2G G=12dB NF=9.6 ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1415A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6h7-0609d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LMH5401",
//     mfgPart: "LMH5401SMT",
//     models: "this part is missing models",
//     pn: "XM-C6H7-0609D",
//     price: 23100,
//     priceType: "cents",
//     props: [0, 2000, 12, 9.6, 0, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B9",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1415_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1485",
//     catId: "AMP",
//     ctlBoard: "XM-C446-0505D",
//     datasheetURL: "https://www.qorvo.com/products/d/da004191",
//     description: "F=2G-6G G=24dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1485A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6j3-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA2598",
//     mfgPart: "TGA2597-SMSMT",
//     models: "this part is missing models",
//     pn: "XM-C6J3-0404D",
//     price: 24400,
//     priceType: "cents",
//     props: [2000, 6000, 24, 0, 0, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1500",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/TSS-44-D+.pdf",
//     description: "F=22G-43.5G G=17.6dB P1dB=4.1 IP3=12.7 NF=3.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1500A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6l3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=TSS-53LNB+2B",
//     mfgPart: "TSS-44-D+DIE",
//     models: "this part is missing models",
//     pn: "XM-C6L3-0404D",
//     price: 45900,
//     priceType: "cents",
//     props: [22000, 43500, 17.6, 3.2, 4.1, null, 12.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1500_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1499",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-84-D+.pdf",
//     description: "F=50M-7G G=24dB P1dB=20.5 IP3=37 NF=5.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1499A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6l4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-91+2B",
//     mfgPart: "GVA-84-D+DIE",
//     models: "this part is missing models",
//     pn: "XM-C6L4-0404D",
//     price: 37800,
//     priceType: "cents",
//     props: [50, 7000, 24, 5.5, 20.5, 22.2, 37],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1499_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1498",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/AVA-183A-D+.pdf",
//     description: "F=5G-20G G=13.5dB P1dB=18.3 IP3=25.8 NF=4.8 ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1498A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6l7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=AVA-24A+2B",
//     mfgPart: "AVA-183A-D+DIE",
//     models: "this part is missing models",
//     pn: "XM-C6L7-0204D",
//     price: 25400,
//     priceType: "cents",
//     props: [5000, 20000, 13.5, 4.8, 18.3, null, 25.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B1",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1498_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1541",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CMA-63+.pdf",
//     description: "F=10M-6G G=20dB P1dB=18.4 IP3=34.6 NF=3.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1541A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c771-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ERA-21SM+2B",
//     mfgPart: "CMA-63+SMT",
//     models: "this part is missing models",
//     pn: "XM-C771-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [10, 6000, 20, 3.9, 18.4, null, 34.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1541_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1601",
//     catId: "AMP",
//     ctlBoard: "XM-C8M9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAM-011286-DIE.pdf",
//     description: "F=30K-44G G=16dB P1dB=22 IP3=30 NF=5.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1601A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c785-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MAAM-011286-DIE",
//     mfgPart: "MAAM-011286-DIEDIE",
//     models: "this part is missing models",
//     pn: "XM-C785-0404D",
//     price: 71800,
//     priceType: "cents",
//     props: [0.03, 44000, 16, 5.3, 22, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1601_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1558",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PMA-545G2+.pdf",
//     description: "F=1.1G-1.6G G=30dB P1dB=22 IP3=34 NF=1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1558A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c787-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PSA4-5043+2B",
//     mfgPart: "PMA-545G2+SMT",
//     models: "this part is missing models",
//     pn: "XM-C787-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: [1100, 1600, 30, 1, 22, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1558_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1548",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/HMC8411LP2FE.pdf",
//     description: "F=10M-10G G=15.5dB P1dB=20 IP3=34 NF=1.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1548A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c789-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc8411.html",
//     mfgPart: "HMC8411LP2FESMT",
//     models: "this part is missing models",
//     pn: "XM-C789-0404D",
//     price: 18700,
//     priceType: "cents",
//     props: [10, 10000, 15.5, 1.8, 20, 19.5, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1542",
//     catId: "AMP",
//     ctlBoard: "XM-C8A6-0414D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADL8111.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c791-0414d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl8111.html",
//     mfgPart: "ADL8111SMT",
//     models: "this is the new part",
//     pn: "XM-C791-0414D",
//     price: 27500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1542_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1512",
//     catId: "AMP",
//     ctlBoard: "XM-C9N2-0304D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd293-1018.pdf",
//     description: "F=20G-45G G=20dB P1dB=26 IP3=31.5 NF=6 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1512A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c792-0304d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd293-driver-amplifier/",
//     mfgPart: "CMD293DIE",
//     models: "this part is missing models",
//     pn: "XM-C792-0304D",
//     price: 48100,
//     priceType: "cents",
//     props: [20000, 45000, 20, 6, 26, null, 31.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1512_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1363",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da006040",
//     description: "F=600M-1G G=20dB P1dB=27.4 IP3=43.2 NF=4.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1363A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c797-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA9842",
//     mfgPart: "QPA9805SMT",
//     models: "this part is missing models",
//     pn: "XM-C797-0604D",
//     price: 13100,
//     priceType: "cents",
//     props: [600, 1000, 20, 4.5, 27.4, null, 43.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1363_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1363",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da006089",
//     description: "F=2.7G-3.8G G=18.7dB P1dB=25 IP3=38.2 NF=2.3 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1363A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c799-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPL1002",
//     mfgPart: "QPA9842SMT",
//     models: "this part is missing models",
//     pn: "XM-C799-0604D",
//     price: 13100,
//     priceType: "cents",
//     props: [2700, 3800, 18.7, 2.3, 25, null, 38.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1363_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1363",
//     catId: "AMP",
//     ctlBoard: "XM-C7Y7-0604D",
//     datasheetURL: "https://www.qorvo.com/products/d/da003613",
//     description: "F=1.805G-2.4G G=20dB P1dB=26.4 IP3=41.6 NF=1.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1363A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7c1-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA9805",
//     mfgPart: "QPA9801SMT",
//     models: "this part is missing models",
//     pn: "XM-C7C1-0604D",
//     price: 13100,
//     priceType: "cents",
//     props: [1805, 2400, 20, 1.5, 26.4, null, 41.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B6",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1363_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1318",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CMA-183L+.pdf",
//     description: "F=50M-18G G=14dB P1dB=5.6 IP3=15.8 NF=5.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1318A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7c7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CMA-545+2B",
//     mfgPart: "CMA-183L+SMT",
//     models: "this part is missing models",
//     pn: "XM-C7C7-0404D",
//     price: 24900,
//     priceType: "cents",
//     props: [50, 18000, 14, 5.3, 5.6, null, 15.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1318_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0187",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd271p3-datasheet.pdf",
//     description: "F=6G-11G G=20dB P1dB=16.5 IP3=27 NF=1.8 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0187L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7d5-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd271p3-low-noise-amplifier/",
//     mfgPart: "CMD271P3SMT",
//     models: "this part is missing models",
//     pn: "XM-C7D5-0404D",
//     price: 14400,
//     priceType: "cents",
//     props: [6000, 11000, 20, 1.8, 16.5, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0187_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0230",
//     catId: "AMP",
//     ctlBoard: "XM-A349-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd284p4_datasheet.pdf",
//     description: "F=50M-22G G=17dB P1dB=19 IP3=28 NF=2.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0230K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7d6-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/cmd284p4-distributed-driver-amplifier/",
//     mfgPart: "CMD284P4SMT",
//     models: "this part is missing models",
//     pn: "XM-C7D6-0404D",
//     price: 40300,
//     priceType: "cents",
//     props: [50, 22000, 17, 2.5, 19, null, 28],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0452",
//     catId: "AMP",
//     ctlBoard: "XM-A5C8-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd295c4-datasheet.pdf",
//     description: "F=2G-20G G=27dB P1dB=16 IP3=29 NF=3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0452B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7d7-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd295c4-driver-amplifier/",
//     mfgPart: "CMD295C4SMT",
//     models: "this part is missing models",
//     pn: "XM-C7D7-0404D",
//     price: 26500,
//     priceType: "cents",
//     props: [2000, 20000, 27, 3, 16, null, 29],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0452_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0316",
//     catId: "AMP",
//     ctlBoard: "XM-B4V1-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd298c4_ver_1_0119.pdf",
//     description: "F=17G-25G G=27dB P1dB=8 IP3=19 NF=1.4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0316E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7d8-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd298c4-low-noise-amplifier/",
//     mfgPart: "CMD298C4SMT",
//     models: "this part is missing models",
//     pn: "XM-C7D8-0404D",
//     price: 20600,
//     priceType: "cents",
//     props: [17000, 25000, 27, 1.4, 8, null, 19],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0251",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd305C4-datasheet.pdf",
//     description: "F=6G-16G G=18.5dB P1dB=20 IP3=33 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0251D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7e1-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd305c4-driver-amplifiers/",
//     mfgPart: "CMD305C4SMT",
//     models: "this part is missing models",
//     pn: "XM-C7E1-0404D",
//     price: 16900,
//     priceType: "cents",
//     props: [6000, 16000, 18.5, 4.5, 20, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0251_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0187",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd305p3_datasheet.pdf",
//     description: "F=6G-14G G=18dB P1dB=19 IP3=31.5 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0187L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7e2-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd305p3-driver-amplifiers/",
//     mfgPart: "CMD305P3SMT",
//     models: "this part is missing models",
//     pn: "XM-C7E2-0404D",
//     price: 15200,
//     priceType: "cents",
//     props: [6000, 14000, 18, 4.5, 19, null, 31.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0187_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0187",
//     catId: "AMP",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd307p3_datasheet.pdf",
//     description: "F=8G-16G G=17dB P1dB=12 IP3=25 NF=2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0187L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7e3-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd307p3-low-noise-amplifier/",
//     mfgPart: "CMD307P3SMT",
//     models: "this part is missing models",
//     pn: "XM-C7E3-0404D",
//     price: 17900,
//     priceType: "cents",
//     props: [8000, 16000, 17, 2, 12, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0187_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0251",
//     catId: "AMP",
//     ctlBoard: "XM-B5G7-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd308p4_datasheet.pdf",
//     description: "F=2G-6G G=31dB P1dB=12 IP3=22.5 NF=1.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0251D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7e4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd308p4-low-noise-amplifier/",
//     mfgPart: "CMD308P4SMT",
//     models: "this part is missing models",
//     pn: "XM-C7E4-0404D",
//     price: 16800,
//     priceType: "cents",
//     props: [2000, 6000, 31, 1.6, 12, null, 22.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0251_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0251",
//     catId: "AMP",
//     ctlBoard: "XM-B5G7-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd309p4_datasheet.pdf",
//     description: "F=5G-11G G=27dB P1dB=13 IP3=23 NF=1.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0251D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7e5-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd309p4-low-noise-amplifier/",
//     mfgPart: "CMD309P4SMT",
//     models: "this part is missing models",
//     pn: "XM-C7E5-0404D",
//     price: 16100,
//     priceType: "cents",
//     props: [5000, 11000, 27, 1.5, 13, null, 23],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0251_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0444",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd315c4-datasheet.pdf",
//     description: "F=4G-10G G=19.5dB P1dB=21 IP3=33 NF=5.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0444B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7e6-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd315c4-driver-amplifiers/",
//     mfgPart: "CMD315C4SMT",
//     models: "this part is missing models",
//     pn: "XM-C7E6-0404D",
//     price: 17800,
//     priceType: "cents",
//     props: [4000, 10000, 19.5, 5.5, 21, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0444_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0737",
//     catId: "AMP",
//     ctlBoard: "XM-B5G7-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd316c3_ver_a_0120.pdf",
//     description: "F=6G-20G G=20dB P1dB=15.5 IP3=26 NF=2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0737A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7e7-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd-316c3-low-noise-amplifier/",
//     mfgPart: "CMD316C3SMT",
//     models: "this part is missing models",
//     pn: "XM-C7E7-0404D",
//     price: 16200,
//     priceType: "cents",
//     props: [6000, 20000, 20, 2, 15.5, null, 26],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0737_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0320",
//     catId: "AMP",
//     ctlBoard: "XM-A4E3-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd317C4-datasheet.pdf",
//     description: "F=1G-24G G=16dB P1dB=23 IP3=35 NF=3.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0320E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7e8-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd317c4/",
//     mfgPart: "CMD317C4SMT",
//     models: "this part is missing models",
//     pn: "XM-C7E8-0404D",
//     price: 42700,
//     priceType: "cents",
//     props: [1000, 24000, 16, 3.5, 23, null, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0320_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0186",
//     catId: "AMP",
//     ctlBoard: "XM-A6E9-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd318p3_datasheet.pdf",
//     description: "F=5G-9G G=22dB P1dB=14 IP3=25.5 NF=1.25 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0186G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7e9-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd318p3-low-noise-amplifier/",
//     mfgPart: "CMD318P3SMT",
//     models: "this part is missing models",
//     pn: "XM-C7E9-0404D",
//     price: 15900,
//     priceType: "cents",
//     props: [5000, 9000, 22, 1.25, 14, null, 25.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0186_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1209",
//     catId: "AMP",
//     ctlBoard: "XM-B9H5-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd319c3_ver_a_0320.pdf",
//     description: "F=8G-12G G=20dB P1dB=16 IP3=22 NF=0.92 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1209A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7f1-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/cmd319c3-8-12-ghz-ultra-low-noise-amplifier/",
//     mfgPart: "CMD319C3SMT",
//     models: "this part is missing models",
//     pn: "XM-C7F1-0404D",
//     price: 18700,
//     priceType: "cents",
//     props: [8000, 12000, 20, 0.92, 16, null, 22],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1209_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1645",
//     catId: "AMP",
//     ctlBoard: "XM-C7R3-0604D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/adm-0026-5929sm.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7r2-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/amplifiers/adm-0026-5929sm.aspx",
//     mfgPart: "ADM-0026-5929SMSMT",
//     models: "this is the new part",
//     pn: "XM-C7R2-0404D",
//     price: 39400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1645_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0316",
//     catId: "AMP",
//     ctlBoard: "XM-C7Y5-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd299k4_datasheet.pdf",
//     description: "F=18G-40G G=16dB P1dB=7 IP3=18 NF=4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0316E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7r5-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd299k4-low-noise-amplifier/",
//     mfgPart: "CMD299K4SMT",
//     models: "this part is missing models",
//     pn: "XM-C7R5-0404D",
//     price: 19300,
//     priceType: "cents",
//     props: [18000, 40000, 16, 4, 7, null, 18],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1704",
//     catId: "AMP",
//     ctlBoard: "XM-C7Y6-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd242k4_datasheet.pdf",
//     description: "F=50M-40G G=10.5dB P1dB=17.5 IP3=25 NF=5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1704A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7r6-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/cmd242k4-distributed-amplifier/",
//     mfgPart: "CMD242K4SMT",
//     models: "this part is missing models",
//     pn: "XM-C7R6-0404D",
//     price: 50900,
//     priceType: "cents",
//     props: [50, 40000, 10.5, 5, 17.5, null, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1704_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1225",
//     catId: "AMP",
//     ctlBoard: "XM-B8E5-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005543",
//     description: "F=50M-2G G=19.8dB P1dB=20 IP3=36 NF=0.45 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1225A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7w7-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TQP3M9037",
//     mfgPart: "TQP3M9036SMT",
//     models: "this part is missing models",
//     pn: "XM-C7W7-0404D",
//     price: 9400,
//     priceType: "cents",
//     props: [50, 2000, 19.8, 0.45, 20, null, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1225_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1534",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7y1-0404d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/keysight/keysight-amplifiers/xm-c7y1-0404d",
//     mfgPart: "TC297P / 1GC1-4289SMT",
//     models: "this is the new part",
//     pn: "XM-C7Y1-0409D",
//     price: 29400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0001",
//     catId: "AMP",
//     ctlBoard: "XM-A2J5-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ERA-50SM+.pdf",
//     description: "F=50M-2G G=19.4dB P1dB=17.2 IP3=33 NF=3.3 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0001H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c882-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ERA-5SM+2B",
//     mfgPart: "ERA-50SM+SMT",
//     models: "this part is missing models",
//     pn: "XM-C882-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 2000, 19.4, 3.3, 17.2, 18, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0001_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1668",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/APM-6849SM.pdf?v=050720",
//     description: "F=2G-30G G=11dB P1dB=20 IP3=11 NF=5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1668A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c887-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/amplifiers/apm-6849sm.aspx",
//     mfgPart: "APM-6849SMSMT",
//     models: "this part is missing models",
//     pn: "XM-C887-0404D",
//     price: 24800,
//     priceType: "cents",
//     props: [2000, 30000, 11, 5, 20, 19, 11],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1668_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1496",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PMA3-83LN-D+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8b1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA-545G2+2B",
//     mfgPart: "PMA3-83LN-D+DIE",
//     models: "this is the new part",
//     pn: "XM-C8B1-0404D",
//     price: 35100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1496_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0404A.png|1914",
//     catId: "AMP",
//     ctlBoard: "XM-C9M8-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006240",
//     description: "F=30M-3G G=17dB P1dB=23 NF=1.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1914A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8m3-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPL9057",
//     mfgPart: "QPL1002SMT",
//     models: "this part is missing models",
//     pn: "XM-C8M3-0404D",
//     price: 21200,
//     priceType: "cents",
//     props: [30, 3000, 17, 1.2, 23, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1914_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-B7P9-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/PHA-83W+.pdf",
//     description: "F=50M-8G G=15.7dB P1dB=23.3 IP3=35.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8y6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA2-123LN+2B",
//     mfgPart: "PHA-83W+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8Y6-0404D",
//     price: 9300,
//     priceType: "cents",
//     props: [50, 8000, 15.7, 0, 23.3, 0, 35.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1606",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc-abh209.pdf",
//     description: "F=55G-65G G=113dB P1dB=16 IP3=25 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1606A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9f2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc-abh209-die.html",
//     mfgPart: "HMC-ABH209DIE",
//     models: "this part is missing models",
//     pn: "XM-C9F2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [55000, 65000, 113, 0, 16, 18, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1606_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1607",
//     catId: "AMP",
//     ctlBoard: "XM-C1E5-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc-alh382.pdf",
//     description: "F=57G-65G G=21dB P1dB=12 NF=4 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9f3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc-alh382-die.html",
//     mfgPart: "HMC-ALH382DIE",
//     models: "this part is missing models",
//     pn: "XM-C9F3-0404D",
//     price: 47700,
//     priceType: "cents",
//     props: [57000, 65000, 21, 4, 12, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1607_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1724",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/adpa7007chip.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9f6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adpa7007.html",
//     mfgPart: "ADPA7007CHIPDIE",
//     models: "this is the new part",
//     pn: "XM-C9F6-0404D",
//     price: 73600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1724_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1561",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc-alh509.pdf",
//     description: "F=71G-86G G=14dB P1dB=7 NF=4.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1561A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9f8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc-alh509-die.html",
//     mfgPart: "HMC-ALH509DIE",
//     models: "this part is missing models",
//     pn: "XM-C9F8-0404D",
//     price: 84100,
//     priceType: "cents",
//     props: [71000, 86000, 14, 4.5, 7, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1561_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1845",
//     catId: "AMP",
//     ctlBoard: "XM-C9G1-0405D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006580",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9g2-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA2609",
//     mfgPart: "QPA2213SMT",
//     models: "this is the new part",
//     pn: "XM-C9G2-0404D",
//     price: 75000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1778",
//     catId: "AMP",
//     ctlBoard: "XM-C8M7-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/HMC8411LP2FE.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9g9-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc8411.html",
//     mfgPart: "HMC8411LP2FESMT",
//     models: "this is the new part",
//     pn: "XM-C9G9-0304D",
//     price: 18700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1779",
//     catId: "AMP",
//     ctlBoard: "XM-C9G1-0405D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006580",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9h1-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA2609",
//     mfgPart: "QPA2213SMT",
//     models: "this is the new part",
//     pn: "XM-C9H1-0404D",
//     price: 64700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1779_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1511",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da004195",
//     description: "F=6G-12G G=21dB P1dB=33 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1511A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9r5-0304d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA2611-SM",
//     mfgPart: "TGA2598DIE",
//     models: "this part is missing models",
//     pn: "XM-C9R5-0304D",
//     price: 58400,
//     priceType: "cents",
//     props: [6000, 12000, 21, 0, 33, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1511_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1497",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GVA-123-D+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GVA-123-DG%2B",
//     mfgPart: "GVA-123-D+DIE",
//     models: "this is the new part",
//     pn: "XM-C6L5-0304D",
//     price: 34100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1497_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/tool/XMICR-3P-TRF1208",
//     mfgPart: "TRF1208",
//     models: "this is the new part",
//     pn: "XM-D2L9-0409D",
//     price: 22000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2219_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0530",
//     catId: "AMP",
//     ctlBoard: "XM-A2K1-0404D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GALI-51+.pdf",
//     description: "F=50M-8G G=13.7dB P1dB=12.9 IP3=27 NF=4.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0530A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a111-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GALI-2%2B",
//     mfgPart: "Gali-2+",
//     models: "this part is missing models",
//     pn: "XM-A112-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [50, 8000, 13.7, 4.6, 12.9, null, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B3",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adpa7008.html",
//     mfgPart: "ADPA7008CHIP",
//     models: "this is the new part",
//     pn: "XM-D284-0404D",
//     price: 102100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1724_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc8413.html",
//     mfgPart: "HMC8413LP2FE",
//     models: "this is the new part",
//     pn: "XM-D2D3-0404D",
//     price: 20700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ad8138.html",
//     mfgPart: "AD8138",
//     models: "this is the new part",
//     pn: "XM-B2Y1-0409D",
//     price: 16700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1957_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ad8367.html",
//     mfgPart: "AD8367",
//     models: "this is the new part",
//     pn: "XM-D3A9-0604D",
//     price: 12300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2512_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl5324.html",
//     mfgPart: "ADL5324ARKZ",
//     models: "this is the new part",
//     pn: "XM-A5T5-0804D",
//     price: 12500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1688_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl8106.html",
//     mfgPart: "ADL8106ACEZ",
//     models: "this is the new part",
//     pn: "XM-D3A5-0404D",
//     price: 48800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2550_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl8142.html",
//     mfgPart: "ADL8142ACPZN",
//     models: "this is the new part",
//     pn: "XM-D3U7-0304D",
//     price: 20300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2482_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl9005.html",
//     mfgPart: "ADL9005ACPZN",
//     models: "this is the new part",
//     pn: "XM-D2D7-0404D",
//     price: 55900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2257_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl9006.html",
//     mfgPart: "ADL9006ACGZN",
//     models: "this is the new part",
//     pn: "XM-A831-0404D",
//     price: 34100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2046_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adpa7002.html",
//     mfgPart: "ADPA7002",
//     models: "this is the new part",
//     pn: "XM-D2F5-0404D",
//     price: 42900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2118_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adpa7005.html",
//     mfgPart: "ADPA7005",
//     models: "this is the new part",
//     pn: "XM-D2P6-0604D",
//     price: 53500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2049_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adpa7006.html",
//     mfgPart: "ADPA7006AEHZ",
//     models: "this is the new part",
//     pn: "XM-D412-0604D",
//     price: 48900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2118_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adpa7009.html",
//     mfgPart: "ADPA7009CHIP",
//     models: "this is the new part",
//     pn: "XM-C9R7-0304D",
//     price: 82500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1916_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc1022a-die.html",
//     mfgPart: "HMC1022ACHIPS",
//     models: "this is the new part",
//     pn: "XM-A496-0404D",
//     price: 166700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2082_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc1121.html",
//     mfgPart: "HMC1121LP6GE",
//     models: "this is the new part",
//     pn: "XM-A647-0506D",
//     price: 74800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2122_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc327.html",
//     mfgPart: "HMC327MS8GE",
//     models: "this is the new part",
//     pn: "XM-A4E4-0804D",
//     price: 17200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0448_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc409.html",
//     mfgPart: "HMC409LP4E",
//     models: "this is the new part",
//     pn: "XM-D2G4-0604D",
//     price: 22400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2379_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc457.html",
//     mfgPart: "HMC457QS16G",
//     models: "this is the new part",
//     pn: "XM-A6A1-0804D",
//     price: 18100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2188_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc465-die.html",
//     mfgPart: "HMC465-SX",
//     models: "this is the new part",
//     pn: "XM-D329-0404D",
//     price: 108400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2394_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc487.html",
//     mfgPart: "HMC487LP5E",
//     models: "this is the new part",
//     pn: "XM-A6A3-0604D",
//     price: 37100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0639_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc498lc4.html",
//     mfgPart: "HMC498LC4",
//     models: "this is the new part",
//     pn: "XM-A5L4-0804D",
//     price: 22900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0497_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc504.html",
//     mfgPart: "HMC504LC4B",
//     models: "this is the new part",
//     pn: "XM-A6A5-0404D",
//     price: 16200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2455_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc591lp5.html",
//     mfgPart: "HMC591LP5E",
//     models: "this is the new part",
//     pn: "XM-A6B5-0604D",
//     price: 30300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0639_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc613.html",
//     mfgPart: "HMC613LC4B",
//     models: "this is the new part",
//     pn: "XM-D3P8-0404D",
//     price: 250400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2258_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc8410-die.html",
//     mfgPart: "HMC8410CHIPS-SX",
//     models: "this is the new part",
//     pn: "XM-D336-0404D",
//     price: 62200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2395_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc-abh241-die.html",
//     mfgPart: "HMC-ABH241",
//     models: "this is the new part",
//     pn: "XM-B1R2-0404D",
//     price: 96100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0998_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl5320.html",
//     mfgPart: "ADL5320ARKZ",
//     models: "this is the new part",
//     pn: "XM-A5T3-0404D",
//     price: 9100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl5321.html",
//     mfgPart: "ADL5321ARKZ",
//     models: "this is the new part",
//     pn: "XM-A5T4-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0516_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl8121.html",
//     mfgPart: "ADL8121",
//     models: "this is the new part",
//     pn: "XM-D4F8-0304D",
//     price: 21100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc1086f10.html",
//     mfgPart: "HMC1086F10",
//     models: "this is the new part",
//     pn: "XM-B1H4-0808D",
//     price: 197500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0641_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EHC-24L%2B",
//     mfgPart: "EHC-24L+",
//     models: "this is the new part",
//     pn: "XM-D129-0404D",
//     price: 11000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0002_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MGVA-63%2B",
//     mfgPart: "MGVA-63+",
//     models: "this is the new part",
//     pn: "XM-B4V3-0604D",
//     price: 11700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0130_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1031c/",
//     mfgPart: "AM1031C",
//     models: "this is the new part",
//     pn: "XM-A3C1-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0269_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA5389A",
//     mfgPart: "QPA5389A",
//     models: "this is the new part",
//     pn: "XM-B6D8-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CMA-84%2B",
//     mfgPart: "CMA-84+",
//     models: "this is the new part",
//     pn: "XM-C8A7-0404D",
//     price: 8900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1541_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD311P34",
//     mfgPart: "CMD311P34",
//     models: "this is the new part",
//     pn: "XM-C8M1-0404D",
//     price: 38700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1865_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc8412.html",
//     mfgPart: "HMC8412TCPZ-EP",
//     models: "this is the new part",
//     pn: "XM-D1R1-0304D",
//     price: 25500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1895_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LHA-13LN%2B",
//     mfgPart: "LHA-13LN+",
//     models: "this is the new part",
//     pn: "XM-B5T1-0604D",
//     price: 11600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1965_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1067/",
//     mfgPart: "AM1067",
//     models: "this is the new part",
//     pn: "XM-C7B5-0404D",
//     price: 17000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2004_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF4003",
//     mfgPart: "GRF4003",
//     models: "this is the new part",
//     pn: "XM-A519-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1102/",
//     mfgPart: "AM1102",
//     models: "this is the new part",
//     pn: "XM-A477-0404D",
//     price: 17200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2121_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/amplifiers/amm-6702sm.aspx",
//     mfgPart: "AMM-6702SM",
//     models: "this is the new part",
//     pn: "XM-D133-0304D",
//     price: 41500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2150_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF5010",
//     mfgPart: "GRF5010",
//     models: "this is the new part",
//     pn: "XM-D171-0404D",
//     price: 9500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl8111.html",
//     mfgPart: "ADL8111ACCZN",
//     models: "this is the new part",
//     pn: "XM-D1P2-0404D",
//     price: 20900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2229_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD192C5",
//     mfgPart: "CMD192C5",
//     models: "this is the new part",
//     pn: "XM-D258-0604D",
//     price: 64100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2291_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MGVA-63%2B",
//     mfgPart: "MGVA-63+",
//     models: "this is the new part",
//     pn: "XM-B4V9-0804D",
//     price: 18800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2283_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAL-010528",
//     mfgPart: "MAAL-010528",
//     models: "this is the new part",
//     pn: "XM-D192-0404D",
//     price: 15500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2236_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0800",
//     catId: "AMP",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc453st89.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3r8-0804c-01/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/hmc453st89.html",
//     mfgPart: "HMC453ST89ESMT",
//     models: "this is the new part",
//     pn: "XM-C3R8-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0800_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/NBB-500",
//     mfgPart: "NBB-500-T1",
//     models: "this is the new part",
//     pn: "XM-A123-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0002_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAM-011252",
//     mfgPart: "MAAM-011252",
//     models: "this is the new part",
//     pn: "XM-D1V4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0508_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD291P4",
//     mfgPart: "CMD291P4",
//     models: "this is the new part",
//     pn: "XM-D2F1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0638_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA2-123LN5%2B",
//     mfgPart: "PMA2-123LN5+",
//     models: "this is the new part",
//     pn: "XM-D483-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0792_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPL9547",
//     mfgPart: "QPL9547",
//     models: "this is the new part",
//     pn: "XM-D4H6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1174_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/amplifiers/adm-0026-5929sm.aspx",
//     mfgPart: "ADM-0026-5929SM",
//     models: "this is the new part",
//     pn: "XM-D184-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1645_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA2-183LN%2B",
//     mfgPart: "PMA2-183LN+",
//     models: "this is the new part",
//     pn: "XM-D1N8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2045_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD262",
//     mfgPart: "CMD262",
//     models: "this is the new part",
//     pn: "XM-D116-0505D",
//     price: 135000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2088_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA-183PLN5%2B",
//     mfgPart: "PMA-183PLN+",
//     models: "this is the new part",
//     pn: "XM-D157-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2196_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAP-011327",
//     mfgPart: "MAAP-011327",
//     models: "this is the new part",
//     pn: "XM-D1U1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2234_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAPC-S1000",
//     mfgPart: "MAPC-S1000",
//     models: "this is the new part",
//     pn: "XM-D1V2-1211D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2284_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/ADL5240.html",
//     mfgPart: "ADL5240ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-D1U3-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2286_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage:
//       "https://www.atlantamicro.com/products/amplifiers/am1164-1/",
//     mfgPart: "AM1164-1",
//     models: "this is the new part",
//     pn: "XM-D248-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage:
//       "https://www.atlantamicro.com/products/amplifiers/am1090-1/",
//     mfgPart: "AM1090-1",
//     models: "this is the new part",
//     pn: "XM-D1Y7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2346_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage:
//       "https://www.atlantamicro.com/products/amplifiers/am1084-1/",
//     mfgPart: "AM1084-1",
//     models: "this is the new part",
//     pn: "XM-D1Y8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2346_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LEE-29%2B",
//     mfgPart: "LEE-29+",
//     models: "this is the new part",
//     pn: "XM-D2B4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2360_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPA2962",
//     mfgPart: "QPA2962",
//     models: "this is the new part",
//     pn: "XM-D229-0505D",
//     price: 135000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2363_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1127/",
//     mfgPart: "AM1127",
//     models: "this is the new part",
//     pn: "XM-D1M8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2374_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/power-amplifiers/ADL8107.html",
//     mfgPart: "ADL8107",
//     models: "this is the new part",
//     pn: "XM-D3C6-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2482_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA3-223GLN%2B",
//     mfgPart: "PMA3-223GLN+",
//     models: "this is the new part",
//     pn: "XM-D484-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2566_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/amplifiers-2/",
//     mfgPart: "AM1074",
//     models: "this is the new part",
//     pn: "XM-B8R7-0404D",
//     price: 16200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0815_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1016a/",
//     mfgPart: "AM1016A",
//     models: "this is the new part",
//     pn: "XM-C9V1-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0269_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1053/",
//     mfgPart: "AM1053",
//     models: "this is the new part",
//     pn: "XM-D246-0304D",
//     price: 11500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2297_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD327",
//     mfgPart: "CMD327",
//     models: "this is the new part",
//     pn: "XM-D3A2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0776_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD326",
//     mfgPart: "CMD326",
//     models: "this is the new part",
//     pn: "XM-D3A3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "0776_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc994apm5e.html",
//     mfgPart: "HMC994APM5E",
//     models: "this is the new part",
//     pn: "XM-D3B3-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2447_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage:
//       "https://www.atlantamicro.com/products/amplifiers/am1064-2/",
//     mfgPart: "AM1064-2",
//     models: "this is the new part",
//     pn: "XM-D3H8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "1189_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1144/",
//     mfgPart: "AM1144",
//     models: "this is the new part",
//     pn: "XM-D3J2-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2489_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/amplifiers/am1134/",
//     mfgPart: "AM1134",
//     models: "this is the new part",
//     pn: "XM-D3J4-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2489_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ad8367.html",
//     mfgPart: "AD8367",
//     models: "this is the new part",
//     pn: "XM-D461-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2512_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPL9504",
//     mfgPart: "QPL9504",
//     models: "this is the new part",
//     pn: "XM-D4A1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA3-453%2B",
//     mfgPart: "PMA3-453+",
//     models: "this is the new part",
//     pn: "XM-D4D5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2566_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "AMP",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=PMA3-14LN%2B",
//     mfgPart: "PMA3-14LN+",
//     models: "this is the new part",
//     pn: "XM-D4U9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Amplifiers",
//     newBRDImage: "2627_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0101",
//     catId: "SW2",
//     ctlBoard: "XM-A768-0409D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CSWA2-63DR+.pdf",
//     description: "SP2T F=500M-6G IL=1.1dB P1dB=27dBm IP3=47dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0101H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2l7-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CSWA2-63DR%2B",
//     mfgPart: "CSWA2-63DR+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2L7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2L7-0409D",
//     price: 13100,
//     priceType: "cents",
//     props: [500, 6000, 1.1, 27, 47],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0101_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0100",
//     catId: "SW2",
//     ctlBoard: "XM-A768-0409D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CSWA2-63DR+.pdf",
//     description: "SP2T F=500M-6G IL=1.1dB P1dB=27dBm IP3=47dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0100G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2l8-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CSWA2-63DR%2B",
//     mfgPart: "CSWA2-63DR+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2L8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2L8-0409D",
//     price: 13100,
//     priceType: "cents",
//     props: [500, 6000, 1.1, 27, 47],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0100_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0211",
//     catId: "SW2",
//     ctlBoard: "XM-A8E3-0404D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42524ds.pdf",
//     description: "SP2T F=10M-40G IL=2.5dB P1dB=31.5dBm IP3=41.5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0211I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a335-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42524",
//     mfgPart: "PE42524SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A335D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A335-0404D",
//     price: 20100,
//     priceType: "cents",
//     props: [10, 40000, 2.5, 31.5, 41.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0211_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0944",
//     catId: "SW2",
//     ctlBoard: "XM-A9P1-0604D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW-008322.pdf",
//     description: "SP2T F=DC-20G IL=1.9dB P1dB=30dBm IP3=48dBm ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0944A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a351-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MASW-008322",
//     mfgPart: "MASW-008322SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A351D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A351-0604D",
//     price: 18200,
//     priceType: "cents",
//     props: [0, 20000, 1.9, 30, 48],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G6",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0944_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0298",
//     catId: "SW2",
//     ctlBoard: "XM-C3T4-0409D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42520ds.pdf",
//     description: "SP2T F=9K-13G IL=2dB P1dB=39dBm IP3=66dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0298C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3b2-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42520",
//     mfgPart: "PE42520SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3B2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3B2-0409D",
//     price: 17900,
//     priceType: "cents",
//     props: [0.009, 13000, 2, 39, 66],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0298_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0255",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42522ds.pdf",
//     description: "SP2T F=9K-26.5G IL=5.3dB IP3=59dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0255C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3b3-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42522",
//     mfgPart: "PE42522SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3B3D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3B3-0409D",
//     price: 15100,
//     priceType: "cents",
//     props: [0.009, 26500, 5.3, 0, 59],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0255_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0555",
//     catId: "SW2",
//     ctlBoard: "XM-A3M9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW-011036.pdf",
//     description: "SP2T F=26G-40G IL=1dB IP3=23dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0555A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3m8-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MASW-011036",
//     mfgPart: "MASW-011036DIE",
//     models: "this part is missing models",
//     pn: "XM-A3M8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [26000, 40000, 1, 0, 23],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0555_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0300",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42820ds.pdf",
//     description: "SP2T F=30M-2.7G IL=0.8dB IP3=85dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0300B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3n2-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42820",
//     mfgPart: "PE42820SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3N2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3N2-0409D",
//     price: 23500,
//     priceType: "cents",
//     props: [30, 2700, 0.8, 0, 85],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0300_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0300",
//     catId: "SW2",
//     ctlBoard: "XM-C7A8-0409D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42722ds.pdf",
//     description: "SP2T F=5M-2.794G IL=0.8dB IP3=84dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0300B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3n3-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42722",
//     mfgPart: "PE42722SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3N3D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3N3-0409D",
//     price: 34700,
//     priceType: "cents",
//     props: [5, 2794, 0.8, 0, 84],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0300_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0298",
//     catId: "SW2",
//     ctlBoard: "XM-C3T4-0409D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42521ds.pdf",
//     description: "SP2T F=9K-13G IL=2dB IP3=65dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0298C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3n5-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42521",
//     mfgPart: "PE42521SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3N5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3N5-0409D",
//     price: 18200,
//     priceType: "cents",
//     props: [0.009, 13000, 2, 0, 65],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0298_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0297",
//     catId: "SW2",
//     ctlBoard: "XM-B1W1-0619D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42542ds.pdf",
//     description: "SP4T F=9K-13G IL=3.1dB IP3=58dBm ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0297D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3n6-0819d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42542",
//     mfgPart: "PE42542SMT",
//     models: "this part is missing models",
//     pn: "XM-A3N6-0819D",
//     price: 41200,
//     priceType: "cents",
//     props: [0.009, 13000, 3.1, 0, 58],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0297_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0297",
//     catId: "SW2",
//     ctlBoard: "XM-B1W1-0619D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42543ds.pdf",
//     description: "SP4T F=9K-18G IL=3.2dB IP3=59dBm ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0297D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3n7-0819d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42543",
//     mfgPart: "PE42543SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3N7D.s4p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3N7-0819D",
//     price: 42600,
//     priceType: "cents",
//     props: [0.009, 18000, 3.2, 0, 59],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0297_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0220",
//     catId: "SW2",
//     ctlBoard: "XM-A6K1-0409D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42524ds.pdf",
//     description: "SP2T F=10M-40G IL=2.5dB P1dB=31.5dBm IP3=41.5dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0220I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3n9-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42524",
//     mfgPart: "PE42524SMT",
//     models: "this part is missing models",
//     pn: "XM-A3N9-0409D",
//     price: 43000,
//     priceType: "cents",
//     props: [10, 40000, 2.5, 31.5, 41.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0460",
//     catId: "SW2",
//     ctlBoard: "XM-A745-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5020.pdf",
//     description: "SP2T F=100M-30G IL=3dB P1dB=28dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0460B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3r1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5020.html",
//     mfgPart: "ADRF5020BCCZNSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3R1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3R1-0409D",
//     price: 27200,
//     priceType: "cents",
//     props: [100, 30000, 3, 28, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0460_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0695",
//     catId: "SW2",
//     ctlBoard: "XM-A9S3-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc547Alc3.pdf",
//     description: "SP2T F=DC-28G IL=2.2dB P1dB=26dBm IP3=46dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0695A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a421-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc547alc3.html",
//     mfgPart: "HMC547ALC3SMT",
//     models: "this part is missing models",
//     pn: "XM-A421-0409D",
//     price: 24400,
//     priceType: "cents",
//     props: [0, 28000, 2.2, 26, 46],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0695_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0696",
//     catId: "SW2",
//     ctlBoard: "XM-A9S3-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc547Alc3.pdf",
//     description: "SP2T F=DC-28G IL=2.2dB P1dB=26dBm IP3=46dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0696A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a422-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc547alc3.html",
//     mfgPart: "HMC547ALC3SMT",
//     models: "this part is missing models",
//     pn: "XM-A422-0409D",
//     price: 24000,
//     priceType: "cents",
//     props: [0, 28000, 2.2, 26, 46],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0696_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0449",
//     catId: "SW2",
//     ctlBoard: "XM-A749-0419D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42525ds.pdf",
//     description: "SP4T F=9K-50G IL=4dB P1dB=35dBm IP3=46dBm ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0449C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4e5-0419d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42525",
//     mfgPart: "PE42525SMT",
//     models: "this part is missing models",
//     pn: "XM-A4E5-0419D",
//     price: 53800,
//     priceType: "cents",
//     props: [0.009, 50000, 4, 35, 46],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0449_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2018",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/6011/GRF6011DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a599-0409d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "https://www.guerrilla-rf.com/products/detail/sku/GRF6011",
//     mfgPart: "GRF6011SMT",
//     models: "this is the new part",
//     pn: "XM-A599-0409D",
//     price: 16200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2018_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0177",
//     catId: "SW2",
//     ctlBoard: "XM-A5A3-0419D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/CMD195C3_ver_1.6_0319.pdf",
//     description: "SP4T F=DC-18G IL=4dB P1dB=25dBm IP3=40dBm ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0177H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5a2-0419d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd195c3_switch/",
//     mfgPart: "CMD195C3SMT",
//     models: "this part is missing models",
//     pn: "XM-A5A2-0419D",
//     price: 54800,
//     priceType: "cents",
//     props: [0, 18000, 4, 25, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0177_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0177",
//     catId: "SW2",
//     ctlBoard: "XM-A5A5-0419D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd196c3_ver_1_7_0319.pdf",
//     description: "SP2T F=DC-18G IL=1.5dB P1dB=23dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0177H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5a4-0419d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd196c3_switch/",
//     mfgPart: "CMD196C3SMT",
//     models: "this part is missing models",
//     pn: "XM-A5A4-0419D",
//     price: 51600,
//     priceType: "cents",
//     props: [0, 18000, 1.5, 23, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0177_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0361",
//     catId: "SW2",
//     ctlBoard: "XM-A7A6-0409D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd203c4_ver_1.4_1017.pdf",
//     description: "SP4T F=DC-20G IL=2.4dB P1dB=21dBm IP3=30dBm ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0361C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5a6-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd203c4_switch/",
//     mfgPart: "CMD203C4SMT",
//     models: "this part is missing models",
//     pn: "XM-A5A6-0409D",
//     price: 37400,
//     priceType: "cents",
//     props: [0, 20000, 2.4, 21, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0824",
//     catId: "SW2",
//     ctlBoard: "XM-A5A9-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd204c3_ver_1.3_0917.pdf",
//     description: "SP1T F=DC-20G IL=1.3dB P1dB=25dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0824A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5a8-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd204c3_switch/",
//     mfgPart: "CMD204C3SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5A8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5A8-0404D",
//     price: 20400,
//     priceType: "cents",
//     props: [0, 20000, 1.3, 25, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0824_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0514",
//     catId: "SW2",
//     ctlBoard: "XM-A8D7-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADG918_919.pdf",
//     description: "SP2T F=DC-2G IL=0.8dB P1dB=17dBm IP3=36dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0514A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5g1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adg918.html",
//     mfgPart: "ADG918BRMZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5G1-0409D",
//     price: 12100,
//     priceType: "cents",
//     props: [0, 2000, 0.8, 17, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0514",
//     catId: "SW2",
//     ctlBoard: "XM-A8D7-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADG918_919.pdf",
//     description: "SP2T F=DC-2G IL=0.8dB P1dB=17dBm IP3=36dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0514A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5g2-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adg919.html",
//     mfgPart: "ADG919BRMZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5G2-0409D",
//     price: 11100,
//     priceType: "cents",
//     props: [0, 2000, 0.8, 17, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0513",
//     catId: "SW2",
//     ctlBoard: "XM-A8D7-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADG918_919.pdf",
//     description: "SP2T F=DC-2G IL=0.8dB P1dB=17dBm IP3=36dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0513A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5g3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adg918.html",
//     mfgPart: "ADG918BRMZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5G3D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5G3-0409D",
//     price: 12300,
//     priceType: "cents",
//     props: [0, 2000, 0.8, 17, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0513_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0513",
//     catId: "SW2",
//     ctlBoard: "XM-A8D7-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADG918_919.pdf",
//     description: "SP2T F=DC-2G IL=0.8dB P1dB=17dBm IP3=36dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0513A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5g4-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adg919.html",
//     mfgPart: "ADG919BRMZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5G4-0409D",
//     price: 12300,
//     priceType: "cents",
//     props: [0, 2000, 0.8, 17, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0513_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0506",
//     catId: "SW2",
//     ctlBoard: "XM-A7H9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADG901_902.pdf",
//     description: "SP1T F=DC-2.5G IL=0.8dB P1dB=17dBm IP3=36dBm ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0506A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5g6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adg901.html",
//     mfgPart: "ADG901BRMZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5G6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5G6-0404D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 2500, 0.8, 17, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0506",
//     catId: "SW2",
//     ctlBoard: "XM-A7H9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADG901_902.pdf",
//     description: "SP1T F=DC-2.5G IL=0.8dB P1dB=17dBm IP3=36dBm ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0506A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5g7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adg902.html",
//     mfgPart: "ADG902BRMZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5G7-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 2500, 0.8, 17, 36],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0460",
//     catId: "SW2",
//     ctlBoard: "XM-A745-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5021.pdf",
//     description: "SP2T F=9K-30G IL=2dB P1dB=28dBm IP3=52dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0460B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5m1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5021.html",
//     mfgPart: "ADRF5021BCCZNSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5M1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5M1-0409D",
//     price: 27900,
//     priceType: "cents",
//     props: [0.009, 30000, 2, 28, 52],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0460_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0461",
//     catId: "SW2",
//     ctlBoard: "XM-A745-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5021.pdf",
//     description: "SP2T F=9K-30G IL=2dB P1dB=28dBm IP3=52dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0461B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5m2-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5021.html",
//     mfgPart: "ADRF5021BCCZNSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5M2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5M2-0409D",
//     price: 27900,
//     priceType: "cents",
//     props: [0.009, 30000, 2, 28, 52],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0461_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0682",
//     catId: "SW2",
//     ctlBoard: "XM-B1L2-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/adrf5040.pdf",
//     description: "SP4T F=9K-12G IL=0.8dB P1dB=37dBm IP3=58dBm ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0682A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a636-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5040.html",
//     mfgPart: "ADRF5040BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A636-0409D",
//     price: 24800,
//     priceType: "cents",
//     props: [0.009, 12000, 0.8, 37, 58],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0682_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0818",
//     catId: "SW2",
//     ctlBoard: "XM-A8D8-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5130.pdf",
//     description: "SP2T F=700M-3.5G IL=0.6dB P1dB=46dBm IP3=68dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0818C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a637-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5130.html",
//     mfgPart: "ADRF5130BCPZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A637D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A637-0409D",
//     price: 26100,
//     priceType: "cents",
//     props: [700, 3500, 0.6, 46, 68],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0818_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0838",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1118.pdf",
//     description: "SP2T F=9K-13G IL=0.68dB P1dB=37dBm IP3=62dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0838A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a645-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/hmc1118.html",
//     mfgPart: "HMC1118LP3DESMT",
//     models: "this part is missing models",
//     pn: "XM-A645-0409D",
//     price: 20200,
//     priceType: "cents",
//     props: [0.009, 13000, 0.68, 37, 62],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0838_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0695",
//     catId: "SW2",
//     ctlBoard: "XM-A9S3-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC347ALP3E.pdf",
//     description: "SP2T F=DC-14G IL=1.8dB P1dB=29dBm IP3=47dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0695A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a668-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/hmc347alp3e.html",
//     mfgPart: "HMC347ALP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A668-0409D",
//     price: 22800,
//     priceType: "cents",
//     props: [0, 14000, 1.8, 29, 47],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0695_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0461",
//     catId: "SW2",
//     ctlBoard: "XM-A745-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5020.pdf",
//     description: "SP2T F=100M-30G IL=3dB P1dB=28dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0461B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6f3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5020.html",
//     mfgPart: "ADRF5020BCCZNSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6F3D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6F3-0409D",
//     price: 27200,
//     priceType: "cents",
//     props: [100, 30000, 3, 28, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0461_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0455-D",
//     catId: "SW2",
//     ctlBoard: "XM-A6K1-0409D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42525ds.pdf",
//     description: "SP2T F=9K-60G IL=4dB P1dB=35dBm IP3=46dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0456C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6j9-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42525",
//     mfgPart: "PE42525SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6J9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6J9-0409D",
//     price: 26200,
//     priceType: "cents",
//     props: [0.009, 60000, 4, 35, 46],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0456_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0717",
//     catId: "SW2",
//     ctlBoard: "XM-A727-0419D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW-004103-1365.pdf",
//     description: "SP4T F=50M-20G IL=1.2dB IP3=60dBm ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0717B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a726-0419d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MASW-004103-1365",
//     mfgPart: "MASW-004103-1365SMT",
//     models: "this part is missing models",
//     pn: "XM-A726-0419D",
//     price: 53600,
//     priceType: "cents",
//     props: [50, 20000, 1.2, 0, 60],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0719",
//     catId: "SW2",
//     ctlBoard: "XM-A729-0419D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005120",
//     description: "SP4T F=500M-12G IL=1dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0719A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a728-0419d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS2352-2-SM",
//     mfgPart: "TGS2352-2-SMSMT",
//     models: "this part is missing models",
//     pn: "XM-A728-0419D",
//     price: 107600,
//     priceType: "cents",
//     props: [500, 12000, 1, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0719_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0510",
//     catId: "SW2",
//     ctlBoard: "XM-B1B5-0409D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW-011098.pdf",
//     description: "SP2T F=26G-40G IL=1.2dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0510A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a747-0409d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MASW-011098",
//     mfgPart: "MASW-011098SMT",
//     models: "this part is missing models",
//     pn: "XM-A747-0409D",
//     price: 34300,
//     priceType: "cents",
//     props: [26000, 40000, 1.2, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0511",
//     catId: "SW2",
//     ctlBoard: "XM-B1B5-0409D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW-011098.pdf",
//     description: "SP2T F=26G-40G IL=1.2dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0511A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a748-0409d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MASW-011098",
//     mfgPart: "MASW-011098SMT",
//     models: "this part is missing models",
//     pn: "XM-A748-0409D",
//     price: 34300,
//     priceType: "cents",
//     props: [26000, 40000, 1.2, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0547",
//     catId: "SW2",
//     ctlBoard: "XM-A3W5-0404D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42525ds.pdf",
//     description: "SP2T F=9K-60G IL=4dB P1dB=35dBm IP3=46dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0547A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7a9-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42525",
//     mfgPart: "PE42525SMT",
//     models: "this part is missing models",
//     pn: "XM-A7A9-0404D",
//     price: 22600,
//     priceType: "cents",
//     props: [0.009, 60000, 4, 35, 46],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0547_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0680",
//     catId: "SW2",
//     ctlBoard: "XM-C3R9-0404D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42525ds.pdf",
//     description: "SP2T F=9K-60G IL=4dB P1dB=35dBm IP3=46dBm ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0680A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7c3-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42525",
//     mfgPart: "PE42525SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7C3D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7C3-0404D",
//     price: 22400,
//     priceType: "cents",
//     props: [0.009, 60000, 4, 35, 46],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0680_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0681",
//     catId: "SW2",
//     ctlBoard: "XM-C3R9-0404D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42525ds.pdf",
//     description: "SP2T F=9K-60G IL=4dB P1dB=35dBm IP3=46dBm ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0681A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7c4-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42525",
//     mfgPart: "PE42525SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7C4D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7C4-0404D",
//     price: 22400,
//     priceType: "cents",
//     props: [0.009, 60000, 4, 35, 46],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0681_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0622",
//     catId: "SW2",
//     ctlBoard: "XM-A8E2-0409D",
//     datasheetURL: "https://www.idt.com/document/dst/f2932-datasheet-rev1",
//     description: "SP2T F=50M-8G IL=1dB P1dB=36.5dBm IP3=64dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0622A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7d7-0409d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-switches/f2932-high-reliability-sp2t-rf-switch",
//     mfgPart: "F2932SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7D7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7D7-0409D",
//     price: 18600,
//     priceType: "cents",
//     props: [50, 8000, 1, 36.5, 64],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0622_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0622",
//     catId: "SW2",
//     ctlBoard: "XM-A8E2-0409D",
//     datasheetURL: "https://www.idt.com/document/dst/f2933-datasheet-revo",
//     description: "SP2T F=50M-8G IL=0.8dB P1dB=36.5dBm IP3=64dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0622A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7d8-0409d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-switches/f2933-high-reliability-sp2t-rf-switch",
//     mfgPart: "F2933SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7D8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7D8-0409D",
//     price: 18100,
//     priceType: "cents",
//     props: [50, 8000, 0.8, 36.5, 64],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0622_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0726",
//     catId: "SW2",
//     ctlBoard: "XM-A7K7-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/CMD195C3_ver_1.6_0319.pdf",
//     description: "SP2T F=DC-18G IL=4dB P1dB=25dBm IP3=40dBm ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0726A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7k1-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd195c3_switch/",
//     mfgPart: "CMD195C3SMT",
//     models: "this part is missing models",
//     pn: "XM-A7K1-0404D",
//     price: 19400,
//     priceType: "cents",
//     props: [0, 18000, 4, 25, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0726_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0726",
//     catId: "SW2",
//     ctlBoard: "XM-A7K7-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd196c3_ver_1_7_0319.pdf",
//     description: "SP2T F=DC-18G IL=1.5dB P1dB=23dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0726A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7k2-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd196c3_switch/",
//     mfgPart: "CMD196C3SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7K2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7K2-0404D",
//     price: 18300,
//     priceType: "cents",
//     props: [0, 18000, 1.5, 23, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0726_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0733",
//     catId: "SW2",
//     ctlBoard: "XM-A7K7-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/CMD195C3_ver_1.6_0319.pdf",
//     description: "SP2T F=DC-18G IL=4dB P1dB=25dBm IP3=40dBm ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0733A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7k3-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd195c3_switch/",
//     mfgPart: "CMD195C3SMT",
//     models: "this part is missing models",
//     pn: "XM-A7K3-0404D",
//     price: 19600,
//     priceType: "cents",
//     props: [0, 18000, 4, 25, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0733_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0733",
//     catId: "SW2",
//     ctlBoard: "XM-A7K7-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd196c3_ver_1_7_0319.pdf",
//     description: "SP2T F=DC-18G IL=1.5dB P1dB=23dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0733A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7k4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd196c3_switch/",
//     mfgPart: "CMD196C3SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7K4D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7K4-0404D",
//     price: 18600,
//     priceType: "cents",
//     props: [0, 18000, 1.5, 23, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0733_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0900",
//     catId: "SW2",
//     ctlBoard: "XM-A7T6-0809D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MA4AGSW5.pdf",
//     description: "SP5T F=50M-70G IL=1.7dB ",
//     eccn: "",
//     ffNum: 20,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0900B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7t5-0809d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MA4AGSW5",
//     mfgPart: "MA4AGSW5DIE",
//     models: "this part is missing models",
//     pn: "XM-A7T5-0809D",
//     price: 0,
//     priceType: "cents",
//     props: [50, 70000, 1.7, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0916",
//     catId: "SW2",
//     ctlBoard: "XM-A9S3-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc232a.pdf",
//     description: "SP2T F=20M-12G IL=1.5dB P1dB=30dBm IP3=48dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0916A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9r5-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/hmc232a.html",
//     mfgPart: "HMC232ALP4ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9R5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9R5-0409D",
//     price: 24700,
//     priceType: "cents",
//     props: [20, 12000, 1.5, 30, 48],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0916_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1212",
//     catId: "SW2",
//     ctlBoard: "XM-C9P9-0409D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd272p3_ver2_0618.pdf",
//     description: "DP2T F=DC-10G IL=1dB P1dB=25dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 21,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1212A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9x3-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd272p3/",
//     mfgPart: "CMD272P3SMT",
//     models: "this part is missing models",
//     pn: "XM-A9X3-0409D",
//     price: 23500,
//     priceType: "cents",
//     props: [0, 10000, 1, 25, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1251",
//     catId: "SW2",
//     ctlBoard: "XM-B199-0409D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW_011105.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b198-0409d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MASW-011105",
//     mfgPart: "MASW-011105SMT",
//     models: "this is the new part",
//     pn: "XM-B198-0409D",
//     price: 14200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1251_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0722",
//     catId: "SW2",
//     ctlBoard: "XM-B1E2-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5024.pdf",
//     description: "SP2T F=100M-44G IL=1dB P1dB=27.5dBm IP3=50dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0722B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1c9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5024.html",
//     mfgPart: "ADRF5024BCCZNSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1C9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1C9-0409D",
//     price: 29000,
//     priceType: "cents",
//     props: [100, 44000, 1, 27.5, 50],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0722_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0682",
//     catId: "SW2",
//     ctlBoard: "XM-B1L2-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5044.pdf",
//     description: "SP4T F=100M-30G IL=2.6dB P1dB=28dBm IP3=50dBm ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0682A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1d8-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5044.html",
//     mfgPart: "ADRF5044BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-B1D8-0409D",
//     price: 35400,
//     priceType: "cents",
//     props: [100, 30000, 2.6, 28, 50],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0682_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0696",
//     catId: "SW2",
//     ctlBoard: "XM-A9S3-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC347ALP3E.pdf",
//     description: "SP2T F=DC-14G IL=1.8dB P1dB=29dBm IP3=47dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0696A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1j1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/hmc347alp3e.html",
//     mfgPart: "HMC347ALP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1J1-0409D",
//     price: 22800,
//     priceType: "cents",
//     props: [0, 14000, 1.8, 29, 47],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0696_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0695",
//     catId: "SW2",
//     ctlBoard: "XM-A9S3-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC547ALP3E.pdf",
//     description: "SP2T F=DC-20G IL=2.5dB P1dB=29dBm IP3=47dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0695A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1j6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/hmc547alp3e.html",
//     mfgPart: "HMC547ALP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1J6-0409D",
//     price: 23300,
//     priceType: "cents",
//     props: [0, 20000, 2.5, 29, 47],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0695_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0696",
//     catId: "SW2",
//     ctlBoard: "XM-A9S3-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC547ALP3E.pdf",
//     description: "SP2T F=DC-20G IL=2.5dB P1dB=29dBm IP3=47dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0696A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1j7-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/hmc547alp3e.html",
//     mfgPart: "HMC547ALP3ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1J7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1J7-0409D",
//     price: 25300,
//     priceType: "cents",
//     props: [0, 20000, 2.5, 29, 47],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0696_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0561",
//     catId: "SW2",
//     ctlBoard: "XM-B1B9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc986a.pdf",
//     description: "SP2T F=100M-50G IL=2.1dB P1dB=28dBm IP3=40dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0561A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1k7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/hmc986a.html",
//     mfgPart: "HMC986ADIE",
//     models: "this part is missing models",
//     pn: "XM-B1K7-0404D",
//     price: 74300,
//     priceType: "cents",
//     props: [100, 50000, 2.1, 28, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0561_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0659",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC546LP2E.pdf",
//     description: "SP2T F=200M-2.7G IL=0.4dB P1dB=40dBm IP3=67dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0659A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1k9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spdt-t-r/hmc546lp2e.html",
//     mfgPart: "HMC546LP2ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1K9-0409D",
//     price: 24100,
//     priceType: "cents",
//     props: [200, 2700, 0.4, 40, 67],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0831",
//     catId: "SW2",
//     ctlBoard: "XM-B1R1-0819D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adgm1004.pdf",
//     description: "SP4T F=DC-13G IL=0.45dB IP3=67dBm ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0831B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1p9-0819d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/switches-multiplexers/analog-switches-multiplexers/mems-switches/adgm1004.html",
//     mfgPart: "ADGM1004SMT",
//     models: "this part is missing models",
//     pn: "XM-B1P9-0819D",
//     price: 49500,
//     priceType: "cents",
//     props: [0, 13000, 0.45, 0, 67],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0979",
//     catId: "SW2",
//     ctlBoard: "XM-B1R9-0409D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MA4AGSW2.pdf",
//     description: "SP2T F=50M-70G IL=0.7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0979C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1r8-0409d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MA4AGSW2",
//     mfgPart: "MA4AGSW2DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1R8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1R8-0409D",
//     price: 86400,
//     priceType: "cents",
//     props: [50, 70000, 0.7, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0958",
//     catId: "SW2",
//     ctlBoard: "XM-B1T2-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MA4SWx10Series.pdf",
//     description: "SP1T F=50M-26.5G IL=1.2dB ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0958A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1t1-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MA4SW110",
//     mfgPart: "MA4SW110DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1T1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1T1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [50, 26500, 1.2, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0958_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0945",
//     catId: "SW2",
//     ctlBoard: "XM-B2J6-0604D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW-008322.pdf",
//     description: "SP2T F=DC-20G IL=1.9dB P1dB=30dBm IP3=48dBm ",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0945A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1v2-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MASW-008322",
//     mfgPart: "MASW-008322SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1V2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1V2-0604D",
//     price: 18200,
//     priceType: "cents",
//     props: [0, 20000, 1.9, 30, 48],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G6",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1939",
//     catId: "SW2",
//     ctlBoard: "XM-B219-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MA4AGSW2.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b218-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MA4AGSW2",
//     mfgPart: "MA4AGSW2DIE",
//     models: "this is the new part",
//     pn: "XM-B218-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1939_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1031",
//     catId: "SW2",
//     ctlBoard: "XM-A768-0409D",
//     datasheetURL: "https://www.minicircuits.com/pdfs/VSWA2-63DR+.pdf",
//     description: "SP2T F=500M-6G IL=1dB P1dB=24dBm IP3=50dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1031A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2e9-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=VSWA2-63DR%2B",
//     mfgPart: "VSWA2-63DR+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2E9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2E9-0409D",
//     price: 17100,
//     priceType: "cents",
//     props: [500, 6000, 1, 24, 50],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1031_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1068",
//     catId: "SW2",
//     ctlBoard: "XM-A768-0409D",
//     datasheetURL: "https://www.nxp.com/docs/en/data-sheet/SA630.pdf?",
//     description: "SP2T F=DC-1G IL=1dB IP3=33dBm ",
//     eccn: "",
//     ffNum: 16,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1068B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2m8-0609d/",
//     mfgCode: "NXPSC",
//     mfgLandingPage:
//       "https://www.nxp.com/products/rf/rf-mixers/low-power-fm-if-systems/single-pole-double-throw-spdt-switch:SA630D",
//     mfgPart: "SA630DSMT",
//     models: "this part is missing models",
//     pn: "XM-B2M8-0609D",
//     price: 17800,
//     priceType: "cents",
//     props: [0, 1000, 1, 0, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1125",
//     catId: "SW2",
//     ctlBoard: "XM-B2N6-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MA4AGSW1.pdf",
//     description: "SP1T F=50M-70G IL=0.5dB ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1125B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2n5-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MA4AGSW1",
//     mfgPart: "MA4AGSW1ADIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2N5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2N5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [50, 70000, 0.5, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1125_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1258",
//     catId: "SW2",
//     ctlBoard: "XM-B9L7-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW-011107-DIE.pdf",
//     description: "SP2T F=DC-26.5G IL=1.3dB P1dB=27dBm IP3=45dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1258A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2t4-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MASW-011107-DIE",
//     mfgPart: "MASW-011107DIE",
//     models: "this part is missing models",
//     pn: "XM-B2T4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 26500, 1.3, 27, 45],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1258_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0622",
//     catId: "SW2",
//     ctlBoard: "XM-A8E2-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/HMC349ALP4CE.pdf",
//     description: "SP2T F=100M-4G IL=1dB P1dB=34dBm IP3=53dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0622A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5d8-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc349alp4ce.html",
//     mfgPart: "HMC349ALP4CESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5D8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5D8-0409D",
//     price: 19500,
//     priceType: "cents",
//     props: [100, 4000, 1, 34, 53],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0622_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1180",
//     catId: "SW2",
//     ctlBoard: "XM-C435-0409D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005120",
//     description: "SP2T F=500M-12G IL=1dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1180A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6f4-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS2352-2-SM",
//     mfgPart: "TGS2352-2-SMSMT",
//     models: "this part is missing models",
//     pn: "XM-B6F4-0409D",
//     price: 46200,
//     priceType: "cents",
//     props: [500, 12000, 1, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1180_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1182",
//     catId: "SW2",
//     ctlBoard: "XM-C435-0409D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005120",
//     description: "SP2T F=500M-12G IL=1dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1182A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6f5-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS2352-2-SM",
//     mfgPart: "TGS2352-2-SMSMT",
//     models: "this part is missing models",
//     pn: "XM-B6F5-0409D",
//     price: 45700,
//     priceType: "cents",
//     props: [500, 12000, 1, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1180",
//     catId: "SW2",
//     ctlBoard: "XM-C435-0409D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005129",
//     description: "SP2T F=500M-18G IL=1.5dB P1dB=40dBm IP3=58dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1180A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6f6-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS2353-2-SM",
//     mfgPart: "TGS2353-2-SMSMT",
//     models: "this part is missing models",
//     pn: "XM-B6F6-0409D",
//     price: 44800,
//     priceType: "cents",
//     props: [500, 18000, 1.5, 40, 58],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1180_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1182",
//     catId: "SW2",
//     ctlBoard: "XM-C435-0409D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005129",
//     description: "SP2T F=500M-18G IL=1.5dB P1dB=40dBm IP3=58dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1182A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6f7-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS2353-2-SM",
//     mfgPart: "TGS2353-2-SMSMT",
//     models: "this part is missing models",
//     pn: "XM-B6F7-0409D",
//     price: 44800,
//     priceType: "cents",
//     props: [500, 18000, 1.5, 40, 58],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1183",
//     catId: "SW2",
//     ctlBoard: "XM-B6C3-0619D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000270",
//     description: "SP3T F=5M-6G IL=1dB P1dB=36dBm IP3=59dBm ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1183A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6g2-0619d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPC6034",
//     mfgPart: "QPC6034SMT",
//     models: "this part is missing models",
//     pn: "XM-B6G2-0619D",
//     price: 30300,
//     priceType: "cents",
//     props: [5, 6000, 1, 36, 59],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1183_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0990",
//     catId: "SW2",
//     ctlBoard: "XM-B6C3-0619D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000272",
//     description: "SP4T F=5M-6G IL=1.2dB P1dB=37dBm IP3=61dBm ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0990B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6g3-0619d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPC6044",
//     mfgPart: "QPC6044SMT",
//     models: "this part is missing models",
//     pn: "XM-B6G3-0619D",
//     price: 31800,
//     priceType: "cents",
//     props: [5, 6000, 1.2, 37, 61],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0991",
//     catId: "SW2",
//     ctlBoard: "XM-B6C3-0619D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000272",
//     description: "SP4T F=5M-6G IL=1.2dB P1dB=37dBm IP3=61dBm ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0991B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6g4-0619d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPC6044",
//     mfgPart: "QPC6044SMT",
//     models: "this part is missing models",
//     pn: "XM-B6G4-0619D",
//     price: 30200,
//     priceType: "cents",
//     props: [5, 6000, 1.2, 37, 61],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0991_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1183",
//     catId: "SW2",
//     ctlBoard: "XM-B6C3-0619D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000273",
//     description: "SP5T F=5M-6G IL=2.2dB P1dB=36dBm IP3=59dBm ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1183A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6g5-0619d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPC6054",
//     mfgPart: "QPC6054SMT",
//     models: "this part is missing models",
//     pn: "XM-B6G5-0619D",
//     price: 30200,
//     priceType: "cents",
//     props: [5, 6000, 2.2, 36, 59],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1183_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1183",
//     catId: "SW2",
//     ctlBoard: "XM-B6C3-0619D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000275",
//     description: "SP6T F=5M-6G IL=1.3dB P1dB=36dBm IP3=59dBm ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1183A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6g6-0619d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPC6064",
//     mfgPart: "QPC6064SMT",
//     models: "this part is missing models",
//     pn: "XM-B6G6-0619D",
//     price: 30300,
//     priceType: "cents",
//     props: [5, 6000, 1.3, 36, 59],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1183_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1184",
//     catId: "SW2",
//     ctlBoard: "XM-A8E2-0409D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006332",
//     description: "SP2T F=5M-6G IL=1.35dB P1dB=37dBm IP3=60dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1184A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6g7-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPC6324",
//     mfgPart: "QPC6324SMT",
//     models: "this part is missing models",
//     pn: "XM-B6G7-0409D",
//     price: 16700,
//     priceType: "cents",
//     props: [5, 6000, 1.35, 37, 60],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1184_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1185",
//     catId: "SW2",
//     ctlBoard: "XM-A8E2-0409D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006332",
//     description: "SP2T F=5M-6G IL=1.35dB P1dB=37dBm IP3=60dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1185A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6g8-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPC6324",
//     mfgPart: "QPC6324SMT",
//     models: "this part is missing models",
//     pn: "XM-B6G8-0409D",
//     price: 16700,
//     priceType: "cents",
//     props: [5, 6000, 1.35, 37, 60],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1185_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1186",
//     catId: "SW2",
//     ctlBoard: "XM-C6A9-0409D",
//     datasheetURL: "https://www.qorvo.com/products/d/da000269",
//     description: "SP1T F=5M-6G IL=1.05dB P1dB=36dBm IP3=58dBm ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1186B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6g9-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPC6014",
//     mfgPart: "QPC6014SMT",
//     models: "this part is missing models",
//     pn: "XM-B6G9-0404D",
//     price: 13300,
//     priceType: "cents",
//     props: [5, 6000, 1.05, 36, 58],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1186_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0723",
//     catId: "SW2",
//     ctlBoard: "XM-B1E2-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5024.pdf",
//     description: "SP2T F=100M-44G IL=1dB P1dB=27.5dBm IP3=50dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0723B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6h9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5024.html",
//     mfgPart: "ADRF5024BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-B6H9-0409D",
//     price: 29300,
//     priceType: "cents",
//     props: [100, 44000, 1, 27.5, 50],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0723_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0722",
//     catId: "SW2",
//     ctlBoard: "XM-C565-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/adrf5025.pdf",
//     description: "SP2T F=9K-44G IL=1dB P1dB=27.5dBm IP3=50dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0722B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6j1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5025.html",
//     mfgPart: "ADRF5025SMT",
//     models: "this part is missing models",
//     pn: "XM-B6J1-0409D",
//     price: 29800,
//     priceType: "cents",
//     props: [0.009, 44000, 1, 27.5, 50],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0722_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0723",
//     catId: "SW2",
//     ctlBoard: "XM-C565-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/adrf5025.pdf",
//     description: "SP2T F=9K-44G IL=1dB P1dB=27.5dBm IP3=50dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0723B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6j2-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5025.html",
//     mfgPart: "ADRF5025SMT",
//     models: "this part is missing models",
//     pn: "XM-B6J2-0409D",
//     price: 30100,
//     priceType: "cents",
//     props: [0.009, 44000, 1, 27.5, 50],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0723_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0460",
//     catId: "SW2",
//     ctlBoard: "XM-B9F3-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5026.pdf",
//     description: "SP2T F=100M-44G IL=2dB P1dB=27dBm IP3=53dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0460B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6j3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5026.html",
//     mfgPart: "ADRF5026SMT",
//     models: "this part is missing models",
//     pn: "XM-B6J3-0409D",
//     price: 29200,
//     priceType: "cents",
//     props: [100, 44000, 2, 27, 53],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0460_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0461",
//     catId: "SW2",
//     ctlBoard: "XM-B9F3-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5026.pdf",
//     description: "SP2T F=100M-44G IL=2dB P1dB=27dBm IP3=53dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0461B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6j4-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5026.html",
//     mfgPart: "ADRF5026SMT",
//     models: "this part is missing models",
//     pn: "XM-B6J4-0409D",
//     price: 29200,
//     priceType: "cents",
//     props: [100, 44000, 2, 27, 53],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0461_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0460",
//     catId: "SW2",
//     ctlBoard: "XM-B9F3-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5027.pdf",
//     description: "SP2T F=9K-44G IL=2dB P1dB=26dBm IP3=54dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0460B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6j5-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5027.html",
//     mfgPart: "ADRF5027SMT",
//     models: "this part is missing models",
//     pn: "XM-B6J5-0409D",
//     price: 29500,
//     priceType: "cents",
//     props: [0.009, 44000, 2, 26, 54],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0460_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0461",
//     catId: "SW2",
//     ctlBoard: "XM-B9F3-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5027.pdf",
//     description: "SP2T F=9K-44G IL=2dB P1dB=26dBm IP3=54dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0461B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6j6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5027.html",
//     mfgPart: "ADRF5027SMT",
//     models: "this part is missing models",
//     pn: "XM-B6J6-0409D",
//     price: 29500,
//     priceType: "cents",
//     props: [0.009, 44000, 2, 26, 54],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0461_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1170",
//     catId: "SW2",
//     ctlBoard: "XM-B9E1-0419D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5046.pdf",
//     description: "SP4T F=100M-44G IL=3dB P1dB=27.5dBm IP3=50dBm ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1170A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6j9-0419d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5046.html",
//     mfgPart: "ADRF5046BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-B6J9-0419D",
//     price: 41700,
//     priceType: "cents",
//     props: [100, 44000, 3, 27.5, 50],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1170_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1170",
//     catId: "SW2",
//     ctlBoard: "XM-B9E1-0416D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5047.pdf",
//     description: "SP4T F=9K-44G IL=2.9dB IP3=50dBm ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1170A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6k1-0419d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5047.html",
//     mfgPart: "ADRF5047BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-B6K1-0419D",
//     price: 38500,
//     priceType: "cents",
//     props: [0.009, 44000, 2.9, 0, 50],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1170_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0656",
//     catId: "SW2",
//     ctlBoard: "XM-C9R1-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc199a.pdf",
//     description: "SP2T F=DC-2.5G IL=0.5dB P1dB=28dBm IP3=55dBm ",
//     eccn: "",
//     ffNum: 21,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0656A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6n3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/bypass-diversity-matrix-transfer/hmc199a.html",
//     mfgPart: "HMC199AMS8ESMT",
//     models: "this part is missing models",
//     pn: "XM-B6N3-0409D",
//     price: 13600,
//     priceType: "cents",
//     props: [0, 2500, 0.5, 28, 55],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0656_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1855",
//     catId: "SW2",
//     ctlBoard: "XM-B767-0409D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW-010646.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b765-0409d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MASW-010646",
//     mfgPart: "MASW-010646DIE",
//     models: "this is the new part",
//     pn: "XM-B765-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1855_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1930",
//     catId: "SW2",
//     ctlBoard: "XM-B767-0409D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW-010646.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b766-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MASW-010646",
//     mfgPart: "MASW-010646DIE",
//     models: "this is the new part",
//     pn: "XM-B766-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1930_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0682",
//     catId: "SW2",
//     ctlBoard: "XM-B1L2-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5045.pdf",
//     description: "SP4T F=9K-30G IL=2.4dB P1dB=28dBm IP3=50dBm ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0682A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7d9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5045.html",
//     mfgPart: "ADRF5045BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-B7D9-0409D",
//     price: 33200,
//     priceType: "cents",
//     props: [0.009, 30000, 2.4, 28, 50],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0682_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0990",
//     catId: "SW2",
//     ctlBoard: "XM-B6C3-0619D",
//     datasheetURL: "https://www.psemi.com/pdf/datasheets/pe42442ds.pdf",
//     description: "SP4T F=30M-6G IL=3.25dB P1dB=35dBm IP3=58dBm ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0990B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7j4-0619d/",
//     mfgCode: {},
//     mfgLandingPage: "https://www.psemi.com/products/rf-switches/pe42442",
//     mfgPart: "PE42442SMT",
//     models: "this part is missing models",
//     pn: "XM-B7J4-0619D",
//     price: 30200,
//     priceType: "cents",
//     props: [30, 6000, 3.25, 35, 58],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0991",
//     catId: "SW2",
//     ctlBoard: "XM-B6C3-0619D",
//     datasheetURL: "https://www.psemi.com/pdf/datasheets/pe42442ds.pdf",
//     description: "SP4T F=30M-6G IL=3.25dB P1dB=35dBm IP3=58dBm ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0991B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7p8-0619d/",
//     mfgCode: {},
//     mfgLandingPage: "https://www.psemi.com/products/rf-switches/pe42442",
//     mfgPart: "PE42442SMT",
//     models: "this part is missing models",
//     pn: "XM-B7P8-0619D",
//     price: 32300,
//     priceType: "cents",
//     props: [30, 6000, 3.25, 35, 58],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0991_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1220",
//     catId: "SW2",
//     ctlBoard: "XM-B8R4-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5021.pdf",
//     description: "SP2T F=9K-30G IL=2dB P1dB=28dBm IP3=52dBm ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1220A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7r1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5021.html",
//     mfgPart: "ADRF5021BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-B7R1-0404D",
//     price: 24600,
//     priceType: "cents",
//     props: [0.009, 30000, 2, 28, 52],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1220_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1221",
//     catId: "SW2",
//     ctlBoard: "XM-B8R4-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5021.pdf",
//     description: "SP2T F=9K-30G IL=2dB P1dB=28dBm IP3=52dBm ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1221A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7r2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5021.html",
//     mfgPart: "ADRF5021BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-B7R2-0404D",
//     price: 22700,
//     priceType: "cents",
//     props: [0.009, 30000, 2, 28, 52],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1221_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1212",
//     catId: "SW2",
//     ctlBoard: "XM-B9D5-0409D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd273p3-datasheet-1.pdf",
//     description: "DP2T F=DC-12G IL=1.7dB P1dB=25dBm IP3=44dBm ",
//     eccn: "",
//     ffNum: 21,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1212A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8c6-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd273p3-transfer-switch/",
//     mfgPart: "CMD273P3SMT",
//     models: "this part is missing models",
//     pn: "XM-B8C6-0409D",
//     price: 22600,
//     priceType: "cents",
//     props: [0, 12000, 1.7, 25, 44],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1224",
//     catId: "SW2",
//     ctlBoard: "XM-B8C5-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5021.pdf",
//     description: "SP2T F=9K-30G IL=2dB P1dB=28dBm IP3=52dBm ",
//     eccn: "",
//     ffNum: 18,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1224A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8r3-0406d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5021.html",
//     mfgPart: "ADRF5021BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-B8R3-0406D",
//     price: 24500,
//     priceType: "cents",
//     props: [0.009, 30000, 2, 28, 52],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G6",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1224_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1289",
//     catId: "SW2",
//     ctlBoard: "XM-C578-0404D",
//     datasheetURL: "https://www.teledynerelays.com/Datasheets/GRF311.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b992-0509d/",
//     mfgCode: "TLDYN",
//     mfgLandingPage:
//       "https://www.teledynedefenseelectronics.com/relays/Pages/home.aspx",
//     mfgPart: "GRF311-12SMT",
//     models: "this is the new part",
//     pn: "XM-B992-0509D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1289_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1306",
//     catId: "SW2",
//     ctlBoard: "XM-C578-0404D",
//     datasheetURL: "https://www.teledynerelays.com/Datasheets/GRF311.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b993-0509d/",
//     mfgCode: "TLDYN",
//     mfgLandingPage:
//       "https://www.teledynedefenseelectronics.com/relays/Pages/home.aspx",
//     mfgPart: "GRF311-12SMT",
//     models: "this is the new part",
//     pn: "XM-B993-0509D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1306_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1220",
//     catId: "SW2",
//     ctlBoard: "XM-B8R4-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5020.pdf",
//     description: "SP2T F=100M-30G IL=3dB P1dB=28dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1220A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9m6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5020.html",
//     mfgPart: "ADRF5020BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-B9M6-0404D",
//     price: 24200,
//     priceType: "cents",
//     props: [100, 30000, 3, 28, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1220_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1220",
//     catId: "SW2",
//     ctlBoard: "XM-B9P2-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5026.pdf",
//     description: "SP2T F=100M-44G IL=2dB P1dB=27dBm IP3=53dBm ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1220A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9n3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5026.html",
//     mfgPart: "ADRF5026SMT",
//     models: "this part is missing models",
//     pn: "XM-B9N3-0404D",
//     price: 25800,
//     priceType: "cents",
//     props: [100, 44000, 2, 27, 53],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1220_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1221",
//     catId: "SW2",
//     ctlBoard: "XM-B9P2-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5026.pdf",
//     description: "SP2T F=100M-44G IL=2dB P1dB=27dBm IP3=53dBm ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1221A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9n4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5026.html",
//     mfgPart: "ADRF5026SMT",
//     models: "this part is missing models",
//     pn: "XM-B9N4-0404D",
//     price: 25800,
//     priceType: "cents",
//     props: [100, 44000, 2, 27, 53],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1221_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1224",
//     catId: "SW2",
//     ctlBoard: "XM-B9P3-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5026.pdf",
//     description: "SP2T F=100M-44G IL=2dB P1dB=27dBm IP3=53dBm ",
//     eccn: "",
//     ffNum: 18,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1224A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9n5-0406d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5026.html",
//     mfgPart: "ADRF5026SMT",
//     models: "this part is missing models",
//     pn: "XM-B9N5-0406D",
//     price: 27100,
//     priceType: "cents",
//     props: [100, 44000, 2, 27, 53],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G6",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1224_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1221",
//     catId: "SW2",
//     ctlBoard: "XM-B8R4-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5020.pdf",
//     description: "SP2T F=100M-30G IL=3dB P1dB=28dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1221A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9n9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5020.html",
//     mfgPart: "ADRF5020BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-B9N9-0404D",
//     price: 23900,
//     priceType: "cents",
//     props: [100, 30000, 3, 28, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1221_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1224",
//     catId: "SW2",
//     ctlBoard: "XM-B8C5-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5020.pdf",
//     description: "SP2T F=100M-30G IL=3dB P1dB=28dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 18,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1224A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9p1-0406d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5020.html",
//     mfgPart: "ADRF5020BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-B9P1-0406D",
//     price: 25200,
//     priceType: "cents",
//     props: [100, 30000, 3, 28, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G6",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1224_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0696",
//     catId: "SW2",
//     ctlBoard: "XM-A9S3-0409D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd196c3_ver_1_7_0319.pdf",
//     description: "SP2T F=DC-18G IL=1.5dB P1dB=23dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0696A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9t2-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd196c3_switch/",
//     mfgPart: "CMD196C3SMT",
//     models: "this part is missing models",
//     pn: "XM-B9T2-0409D",
//     price: 21300,
//     priceType: "cents",
//     props: [0, 18000, 1.5, 23, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0696_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1448",
//     catId: "SW2",
//     ctlBoard: "XM-C3P8-0409D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005156",
//     description: "SP2T F=32G-40G IL=1dB P1dB=33dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1448A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3p7-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS4304",
//     mfgPart: "TGS4304DIE",
//     models: "this part is missing models",
//     pn: "XM-C3P7-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [32000, 40000, 1, 33, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1448_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1503",
//     catId: "SW2",
//     ctlBoard: "XM-C3U6-0409D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005152",
//     description: "SP2T F=27G-46G IL=0.9dB P1dB=35dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1503A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3u5-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS4302",
//     mfgPart: "TGS4302DIE",
//     models: "this part is missing models",
//     pn: "XM-C3U5-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [27000, 46000, 0.9, 35, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1503_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1463",
//     catId: "SW2",
//     ctlBoard: "XM-C3V8-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc347b.pdf",
//     description: "SP2T F=100M-20G IL=1.7dB P1dB=25dBm IP3=41dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1463A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3v7-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc347b.html",
//     mfgPart: "HMC347BDIE",
//     models: "this part is missing models",
//     pn: "XM-C3V7-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [100, 20000, 1.7, 25, 41],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1463_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1428",
//     catId: "SW2",
//     ctlBoard: "XM-C419-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005156",
//     description: "SP2T F=32G-40G IL=1dB P1dB=33dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1428A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c418-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS4304",
//     mfgPart: "TGS4304DIE",
//     models: "this part is missing models",
//     pn: "XM-C418-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [32000, 40000, 1, 33, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1428_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1461",
//     catId: "SW2",
//     ctlBoard: "XM-C423-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005152",
//     description: "SP2T F=27G-46G IL=0.9dB P1dB=35dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1461A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c422-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS4302",
//     mfgPart: "TGS4302DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C422D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C422-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [27000, 46000, 0.9, 35, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1461_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1486",
//     catId: "SW2",
//     ctlBoard: "XM-A6K1-0409D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42525ds.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c432-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42525",
//     mfgPart: "PE42525SMT",
//     models: "this is the new part",
//     pn: "XM-C432-0409D",
//     price: 24400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1486_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1829",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c472-0404d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/switches/xm-c472-0404d",
//     mfgPart: "TC1100DIE",
//     models: "this is the new part",
//     pn: "XM-C472-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1829_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1830",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.xmicrowave.com/non-catalog-keysight-mmic/",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c473-0404d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/switches/xm-c473-0404d",
//     mfgPart: "TC1100PSMT",
//     models: "this is the new part",
//     pn: "XM-C473-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1830_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1821",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c474-0604d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/switches/xm-c474-0604d",
//     mfgPart: "TC1101DIE",
//     models: "this is the new part",
//     pn: "XM-C474-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1821_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1822",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c475-0604d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/switches/xm-c475-0604d",
//     mfgPart: "TC1101PSMT",
//     models: "this is the new part",
//     pn: "XM-C475-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1822_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1809",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c478-0604d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/switches/xm-c478-0604d",
//     mfgPart: "TC1106DIE",
//     models: "this is the new part",
//     pn: "XM-C478-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1809_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1851",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c493-0604d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/switches/xm-c493-0604d",
//     mfgPart: "TC1203DIE",
//     models: "this is the new part",
//     pn: "XM-C493-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1851_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1811",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4r8-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/switches/xm-c4r8-0409d",
//     mfgPart: "TC741DIE",
//     models: "this is the new part",
//     pn: "XM-C4R8-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1811_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1813",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4r9-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/switches/xm-c4r9-0409d",
//     mfgPart: "TC741PSMT",
//     models: "this is the new part",
//     pn: "XM-C4R9-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1813_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1811",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4t1-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/switches/xm-c4t1-0409d",
//     mfgPart: "TC742DIE",
//     models: "this is the new part",
//     pn: "XM-C4T1-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1811_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1816",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4t2-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/switches/xm-c4t2-0409d",
//     mfgPart: "TC742PSMT",
//     models: "this is the new part",
//     pn: "XM-C4T2-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1816_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1816",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4t9-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/switches/xm-c4t9-0409d",
//     mfgPart: "TC751PSMT",
//     models: "this is the new part",
//     pn: "XM-C4T9-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1816_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1504",
//     catId: "SW2",
//     ctlBoard: "XM-C3U6-0409D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005152",
//     description: "SP2T F=27G-46G IL=0.9dB P1dB=35dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1504A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c567-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS4302",
//     mfgPart: "TGS4302DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C567D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C567-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [27000, 46000, 0.9, 35, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1504_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0695",
//     catId: "SW2",
//     ctlBoard: "XM-A9S3-0409D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/CMD195C3_ver_1.6_0319.pdf",
//     description: "SP2T F=DC-18G IL=4dB P1dB=25dBm IP3=40dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0695A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c585-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd195c3_switch/",
//     mfgPart: "CMD195C3SMT",
//     models: "this part is missing models",
//     pn: "XM-C585-0409D",
//     price: 23300,
//     priceType: "cents",
//     props: [0, 18000, 4, 25, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0695_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0696",
//     catId: "SW2",
//     ctlBoard: "XM-A9S3-0409D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/CMD195C3_ver_1.6_0319.pdf",
//     description: "SP2T F=DC-18G IL=4dB P1dB=25dBm IP3=40dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0696A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c586-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd195c3_switch/",
//     mfgPart: "CMD195C3SMT",
//     models: "this part is missing models",
//     pn: "XM-C586-0409D",
//     price: 22300,
//     priceType: "cents",
//     props: [0, 18000, 4, 25, 40],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0696_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0695",
//     catId: "SW2",
//     ctlBoard: "XM-A9S3-0409D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd196c3_ver_1_7_0319.pdf",
//     description: "SP2T F=DC-18G IL=1.5dB P1dB=23dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0695A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c587-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd196c3_switch/",
//     mfgPart: "CMD196C3SMT",
//     models: "this part is missing models",
//     pn: "XM-C587-0409D",
//     price: 21300,
//     priceType: "cents",
//     props: [0, 18000, 1.5, 23, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0695_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1616",
//     catId: "SW2",
//     ctlBoard: "XM-C6G2-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5130.pdf",
//     description: "SP2T F=700M-3.5G IL=0.6dB P1dB=46dBm IP3=68dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1616A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6g1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5130.html",
//     mfgPart: "ADRF5130BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-C6G1-0404D",
//     price: 22400,
//     priceType: "cents",
//     props: [700, 3500, 0.6, 46, 68],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1579",
//     catId: "SW2",
//     ctlBoard: "XM-A8D8-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/adrf5132.pdf",
//     description: "SP2T F=700M-5G IL=0.6dB P1dB=42.5dBm IP3=65dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1579A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6g9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5132.html",
//     mfgPart: "ADRF5132SMT",
//     models: "this part is missing models",
//     pn: "XM-C6G9-0409D",
//     price: 25800,
//     priceType: "cents",
//     props: [700, 5000, 0.6, 42.5, 65],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1559",
//     catId: "SW2",
//     ctlBoard: "XM-C6L1-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc-sdd112.pdf",
//     description: "SP2T F=55G-86G IL=2dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1559A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6k9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc-sdd112.html",
//     mfgPart: "HMC-SDD112DIE",
//     models: "this part is missing models",
//     pn: "XM-C6K9-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [55000, 86000, 2, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1559_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0494",
//     catId: "SW2",
//     ctlBoard: "XM-A6K1-0409D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42525ds.pdf",
//     description: "SP2T F=DC-60G IL=4dB P1dB=35dBm IP3=46dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0494B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6l8-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42525",
//     mfgPart: "PE42525SMT",
//     models: "this part is missing models",
//     pn: "XM-C6L8-0409D",
//     price: 26200,
//     priceType: "cents",
//     props: [0, 60000, 4, 35, 46],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "#NENÍ",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5046.html",
//     mfgPart: "ADRF5046BCCZNSMT",
//     models: "this is the new part",
//     pn: "XM-C6M5-0419D",
//     price: 41700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2026_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1502",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da005156",
//     description: "SP2T F=32G-40G IL=1dB P1dB=33dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1502A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c793-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS4304",
//     mfgPart: "TGS4304DIE",
//     models: "this part is missing models",
//     pn: "XM-C793-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [32000, 40000, 1, 33, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1502_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1251",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW_011102.pdf",
//     description: "SP2T F=DC-30G IL=1.8dB P1dB=23dBm IP3=45dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1251A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7d1-0409d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MASW-011102",
//     mfgPart: "MASW-011102SMT",
//     models: "this part is missing models",
//     pn: "XM-C7D1-0409D",
//     price: 20800,
//     priceType: "cents",
//     props: [0, 30000, 1.8, 23, 45],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1251_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0822",
//     catId: "SW2",
//     ctlBoard: "XM-A8D8-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5130.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0822A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7n1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5130.html",
//     mfgPart: "ADRF5130BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-C7N1-0409D",
//     price: 26000,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0822_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0456",
//     catId: "SW2",
//     ctlBoard: "XM-A6K1-0409D",
//     datasheetURL: "https://www.psemi.com/pdf/datasheets/pe426525ds.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0456C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7p3-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "https://www.psemi.com/products/rf-switches/pe426525",
//     mfgPart: "PE426525SMT",
//     models: "this part is missing models",
//     pn: "XM-C7P3-0409D",
//     price: 26500,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0456_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0449",
//     catId: "SW2",
//     ctlBoard: "XM-A749-0419D",
//     datasheetURL: "https://www.psemi.com/pdf/datasheets/pe426525ds.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0449C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7p4-0419d/",
//     mfgCode: {},
//     mfgLandingPage: "https://www.psemi.com/products/rf-switches/pe426525",
//     mfgPart: "PE426525SMT",
//     models: "this part is missing models",
//     pn: "XM-C7P4-0419D",
//     price: 62800,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0449_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0547",
//     catId: "SW2",
//     ctlBoard: "XM-A3W5-0404D",
//     datasheetURL: "https://www.psemi.com/pdf/datasheets/pe426525ds.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0547A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7p6-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "https://www.psemi.com/products/rf-switches/pe426525",
//     mfgPart: "PE426525SMT",
//     models: "this part is missing models",
//     pn: "XM-C7P6-0404D",
//     price: 23100,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0547_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1031",
//     catId: "SW2",
//     ctlBoard: "XM-A768-0409D",
//     datasheetURL:
//       "https://www.skyworksinc.com/-/media/SkyWorks/Documents/Products/1601-1700/SKY13372_467LF_201443F.pdf",
//     description: "SP2T F=100M-6G IL=1.3dB P1dB=30dBm IP3=45dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1031A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c822-0409d/",
//     mfgCode: "SKYWK",
//     mfgLandingPage:
//       "https://www.skyworksinc.com/en/Products/Switches/SKY13372-467LF",
//     mfgPart: "SKY13372-467LFSMT",
//     models: "this part is missing models",
//     pn: "XM-C822-0409D",
//     price: 16400,
//     priceType: "cents",
//     props: [100, 6000, 1.3, 30, 45],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1031_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1258",
//     catId: "SW2",
//     ctlBoard: "XM-B9L7-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MASW_011128_DIE.pdf",
//     description: " F=50K-26.5G IL=1.7dB P1dB=27dBm IP3=42dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1258A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8a1-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MASW-011128-DIE",
//     mfgPart: "MASW-011128-DIEDIE",
//     models: "this part is missing models",
//     pn: "XM-C8A1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [0.05, 26500, 1.7, 27, 42],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1258_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1653",
//     catId: "SW2",
//     ctlBoard: "XM-C8A9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5020.pdf",
//     description: "SP2T F=100M-30G IL=3dB P1dB=28dBm IP3=38dBm ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1653B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8a8-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5020.html",
//     mfgPart: "ADRF5020BCCZNSMT",
//     models: "this part is missing models",
//     pn: "XM-C8A8-0604D",
//     price: 25600,
//     priceType: "cents",
//     props: [100, 30000, 3, 28, 38],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G6",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1653_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1672",
//     catId: "SW2",
//     ctlBoard: "XM-C3R9-0404D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42525ds.pdf",
//     description: "SP2T F=9K-60G IL=4dB P1dB=35dBm IP3=46dBm ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1672A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8j4-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42525",
//     mfgPart: "PE42525SMT",
//     models: "this part is missing models",
//     pn: "XM-C8J4-0404D",
//     price: 22500,
//     priceType: "cents",
//     props: [0.009, 60000, 4, 35, 46],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1672_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1673",
//     catId: "SW2",
//     ctlBoard: "XM-C3R9-0404D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe42525ds.pdf",
//     description: "SP2T F=9K-60G IL=4dB P1dB=35dBm IP3=46dBm ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1673A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8j5-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42525",
//     mfgPart: "PE42525SMT",
//     models: "this part is missing models",
//     pn: "XM-C8J5-0404D",
//     price: 20900,
//     priceType: "cents",
//     props: [0.009, 60000, 4, 35, 46],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G3",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1673_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1455",
//     catId: "SW2",
//     ctlBoard: "XM-C8L8-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5019.pdf",
//     description: "SP2T F=100M-13G IL=0.8dB P1dB=39dBm IP3=60dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1455A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8l7-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/adrf5019.html#product-overview",
//     mfgPart: "ADRF5019SMT",
//     models: "this part is missing models",
//     pn: "XM-C8L7-0409D",
//     price: 19800,
//     priceType: "cents",
//     props: [100, 13000, 0.8, 39, 60],
//     quote: "",
//     sparamLinks: [],
//     testCode: "G9",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1455_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1722",
//     catId: "SW2",
//     ctlBoard: "XM-C9N3-0409D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005129",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9a7-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGS2353-2-SM",
//     mfgPart: "TGS2353-2-SMSMT",
//     models: "this is the new part",
//     pn: "XM-C9A7-0409D",
//     price: 44800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1722_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1936",
//     catId: "SW2",
//     ctlBoard: "XM-C9K3-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5046.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9k1-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-switches/spst-spdt-sp3t-sp4t-sp6t-sp8t/adrf5046.html",
//     mfgPart: "ADRF5046BCCZNSMT",
//     models: "this is the new part",
//     pn: "XM-C9K1-0604D",
//     price: 33500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1936_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2087",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://menlomicro.com/images/general/Menlo_MM5130_Datasheet_v2.1.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-d119-0619d/",
//     mfgCode: {},
//     mfgLandingPage: "https://menlomicro.com/products/rf",
//     mfgPart: "MM5130SMT",
//     models: "this is the new part",
//     pn: "XM-D119-0619D",
//     price: 33100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2087_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5424.html",
//     mfgPart: "ADRF5424",
//     models: "this is the new part",
//     pn: "XM-D4J3-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc641alp4e.html",
//     mfgPart: "HMC641ALP4E",
//     models: "this is the new part",
//     pn: "XM-A6C1-0409D",
//     price: 27000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc8038.html",
//     mfgPart: "HMC8038LP4CE",
//     models: "this is the new part",
//     pn: "XM-A6D5-0409D",
//     price: 16900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0622_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc849a.html",
//     mfgPart: "HMC849ALP4CE",
//     models: "this is the new part",
//     pn: "XM-D3T1-0409D",
//     price: 16900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0622_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc546ms8g.html",
//     mfgPart: "HMC546MS8GE",
//     models: "this is the new part",
//     pn: "XM-B1L1-0409D",
//     price: 21000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adgm1304.html",
//     mfgPart: "ADGM1304",
//     models: "this is the new part",
//     pn: "XM-A837-0819D",
//     price: 49700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5047.html",
//     mfgPart: "ADRF5047BCCZN",
//     models: "this is the new part",
//     pn: "XM-D3N1-0419D",
//     price: 38500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1170_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5042.html",
//     mfgPart: "ADRF5042BCCZN",
//     models: "this is the new part",
//     pn: "XM-A832-0419D",
//     price: 39100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2006_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5043.html",
//     mfgPart: "ADRF5043BCCZN",
//     models: "this is the new part",
//     pn: "XM-D2B3-0419D",
//     price: 39400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2006_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adgm1001.html",
//     mfgPart: "ADGM1001",
//     models: "this is the new part",
//     pn: "XM-D1H7-0609D",
//     price: 40300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2165_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/search.html?q=adrf5420",
//     mfgPart: "ADRF5420",
//     models: "this is the new part",
//     pn: "XM-D416-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2180_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/search.html?q=adrf5142",
//     mfgPart: "ADRF5142XCCZN",
//     models: "this is the new part",
//     pn: "XM-D2V1-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2382_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CSWA2-63DR%2B",
//     mfgPart: "CSWA2-63DR+",
//     models: "this is the new part",
//     pn: "XM-A2L9-0409D",
//     price: 13900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "0101_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5044.html",
//     mfgPart: "ADRF5044BCCZN",
//     models: "this is the new part",
//     pn: "XM-C2Y5-0409D",
//     price: 35400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1946_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD196C3",
//     mfgPart: "CMD196C3",
//     models: "this is the new part",
//     pn: "XM-C2W5-0604D",
//     price: 20300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1997_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc349alp4ce.html",
//     mfgPart: "HMC349ALP4CE",
//     models: "this is the new part",
//     pn: "XM-A829-0409D",
//     price: 19500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2063_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMF-011070",
//     mfgPart: "MAMF-011070",
//     models: "this is the new part",
//     pn: "XM-C7N3-0809D",
//     price: 49800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2081_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MA4AGSW8-1",
//     mfgPart: "MA4AGSW8-1",
//     models: "this is the new part",
//     pn: "XM-D1W9-0729D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2186_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/rf-switches/pe42525",
//     mfgPart: "PE42525A",
//     models: "this is the new part",
//     pn: "XM-D1B8-0414D",
//     price: 44100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2214_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.psemi.com/products/rf-switches/pe42426",
//     mfgPart: "PE42426A-Z",
//     models: "this is the new part",
//     pn: "XM-D1H6-0409D",
//     price: 16300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "2295_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SW2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HSWA2-63DR%2B",
//     mfgPart: "HSWA2-63DR+",
//     models: "this is the new part",
//     pn: "XM-D4K1-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Switches",
//     newBRDImage: "1184_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0008",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNThf",
//     description: "Atten=0dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0008K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a216-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0900SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A216D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A216-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [0, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0008_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0008",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNThf",
//     description: "Atten=1dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0008K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a217-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0901SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A217D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A217-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [1, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0008_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0008",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNThf",
//     description: "Atten=2dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0008K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a218-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0902SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A218D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A218-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [2, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0008_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0008",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNThf",
//     description: "Atten=3dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0008K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a219-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0903SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A219D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A219-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [3, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0008_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0008",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNThf",
//     description: "Atten=4dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0008K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a221-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0904SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A221D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A221-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [4, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0008_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0008",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNThf",
//     description: "Atten=5dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0008K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a222-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0905SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A222D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A222-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [5, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0008_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0008",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNThf",
//     description: "Atten=6dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0008K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a223-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0906SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A223D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A223-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [6, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0008_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0008",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNThf",
//     description: "Atten=7dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0008K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a224-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0907SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A224D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A224-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [7, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0008_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0008",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNThf",
//     description: "Atten=8dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0008K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a225-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0908SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-A225-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [8, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0008_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0008",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNThf",
//     description: "Atten=9dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0008K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a226-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0909SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-A226-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [9, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0008_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0008",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNThf",
//     description: "Atten=10dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0008K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a227-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0910SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A227D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A227-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [10, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0008_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-00+.pdf",
//     description: "Atten=0dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a228-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-00%2B",
//     mfgPart: "RCAT-00+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A228D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A228-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [0, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-01+.pdf",
//     description: "Atten=1dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a229-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-01%2B",
//     mfgPart: "RCAT-01+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A229D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A229-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-02+.pdf",
//     description: "Atten=2dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a231-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-02%2B",
//     mfgPart: "RCAT-02+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A231D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A231-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-03+.pdf",
//     description: "Atten=3dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a232-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-03%2B",
//     mfgPart: "RCAT-03+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A232D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A232-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [3, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-04+.pdf",
//     description: "Atten=4dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a233-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-04%2B",
//     mfgPart: "RCAT-04+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A233D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A233-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [4, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-05+.pdf",
//     description: "Atten=5dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a234-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-05%2B",
//     mfgPart: "RCAT-05+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A234D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A234-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [5, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-06+.pdf",
//     description: "Atten=6dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a235-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-06%2B",
//     mfgPart: "RCAT-06+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A235D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A235-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [6, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-07+.pdf",
//     description: "Atten=7dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a236-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-07%2B",
//     mfgPart: "RCAT-07+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A236D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A236-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [7, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-08+.pdf",
//     description: "Atten=8dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a237-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-08%2B",
//     mfgPart: "RCAT-08+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A237D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A237-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [8, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-09+.pdf",
//     description: "Atten=9dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a238-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-09%2B",
//     mfgPart: "RCAT-09+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A238D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A238-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [9, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-10+.pdf",
//     description: "Atten=10dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a239-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-10%2B",
//     mfgPart: "RCAT-10+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A239D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A239-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [10, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-12+.pdf",
//     description: "Atten=12dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a241-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-12%2B",
//     mfgPart: "RCAT-12+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A241D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A241-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [12, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-15+.pdf",
//     description: "Atten=15dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a242-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-15%2B",
//     mfgPart: "RCAT-15+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A242D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A242-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [15, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-20+.pdf",
//     description: "Atten=20dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a243-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-20%2B",
//     mfgPart: "RCAT-20+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A243D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A243-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [20, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0007-I",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RCAT-30+.pdf",
//     description: "Atten=30dB F=DC-20G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0007I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a244-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RCAT-30%2B",
//     mfgPart: "RCAT-30+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A244D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A244-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [30, 2000, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=0dB F=DC-15G PWR=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2c5-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0805-C-00DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2C5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2C5-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [0, 100, 0, 15000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=1dB F=DC-15G PWR=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2c6-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0805-C-01DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2C6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2C6-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [1, 100, 0, 15000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=2dB F=DC-15G PWR=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2c7-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0805-C-02DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2C7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2C7-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [2, 100, 0, 15000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=3dB F=DC-15G PWR=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2c8-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0805-C-03DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2C8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2C8-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [3, 100, 0, 15000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=4dB F=DC-15G PWR=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2c9-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0805-C-04DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2C9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2C9-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [4, 100, 0, 15000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=5dB F=DC-15G PWR=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2d1-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0805-C-05DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2D1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2D1-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [5, 100, 0, 15000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=6dB F=DC-15G PWR=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2d2-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0805-C-06DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2D2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2D2-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [6, 100, 0, 15000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=7dB F=DC-15G PWR=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2d3-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0805-C-07DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2D3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2D3-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [7, 100, 0, 15000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=8dB F=DC-15G PWR=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2d4-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0805-C-08DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2D4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2D4-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [8, 100, 0, 15000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=9dB F=DC-15G PWR=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2d5-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0805-C-09DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2D5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2D5-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [9, 100, 0, 15000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=10dB F=DC-15G PWR=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2d6-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0805-C-10DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2D6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2D6-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [10, 100, 0, 15000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0223",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=1dB F=DC-10G PWR=32mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0223J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a361-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0402-C-01DBSMT",
//     models: "this part is missing models",
//     pn: "XM-A361-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [1, 32, 0, 10000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0223",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=2dB F=DC-10G PWR=32mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0223J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a362-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0402-C-02DBSMT",
//     models: "this part is missing models",
//     pn: "XM-A362-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [2, 32, 0, 10000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0223",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=3dB F=DC-10G PWR=32mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0223J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a363-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0402-C-03DBSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A363D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A363-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [3, 32, 0, 10000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0223",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=4dB F=DC-10G PWR=32mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0223J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a364-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0402-C-04DBSMT",
//     models: "this part is missing models",
//     pn: "XM-A364-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [4, 32, 0, 10000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0223",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=5dB F=DC-10G PWR=32mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0223J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a365-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0402-C-05DBSMT",
//     models: "this part is missing models",
//     pn: "XM-A365-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [5, 32, 0, 10000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0223",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=6dB F=DC-10G PWR=32mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0223J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a366-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0402-C-06DBSMT",
//     models: "this part is missing models",
//     pn: "XM-A366-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [6, 32, 0, 10000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0223",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=7dB F=DC-10G PWR=32mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0223J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a367-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0402-C-07DBSMT",
//     models: "this part is missing models",
//     pn: "XM-A367-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [7, 32, 0, 10000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0223",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=8dB F=DC-10G PWR=32mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0223J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a368-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0402-C-08DBSMT",
//     models: "this part is missing models",
//     pn: "XM-A368-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [8, 32, 0, 10000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0223",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=9dB F=DC-10G PWR=32mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0223J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a369-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0402-C-09DBSMT",
//     models: "this part is missing models",
//     pn: "XM-A369-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [9, 32, 0, 10000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0223",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/A-Series_1-10-18.compressed.pdf",
//     description: "Atten=10dB F=DC-30G PWR=32mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0223J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a371-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/a-series-attenuators/",
//     mfgPart: "A-0402-C-10DBSMT",
//     models: "this part is missing models",
//     pn: "XM-A371-0204D",
//     price: 4500,
//     priceType: "cents",
//     props: [10, 32, 0, 30000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjRf",
//     description: "Atten=0dB F=27G-36G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3c7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Attenuators/Chip/KFA",
//     mfgPart: "KFA00.00-6SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-A3C7-0204D",
//     price: 10400,
//     priceType: "cents",
//     props: [0, 200, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjRf",
//     description: "Atten=1dB F=27G-36G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3c8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Attenuators/Chip/KFA",
//     mfgPart: "KFA01.00-6SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-A3C8-0204D",
//     price: 8500,
//     priceType: "cents",
//     props: [1, 200, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjRf",
//     description: "Atten=2dB F=27G-36G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3c9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Attenuators/Chip/KFA",
//     mfgPart: "KFA02.00-6SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3C9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3C9-0204D",
//     price: 10400,
//     priceType: "cents",
//     props: [2, 200, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjRf",
//     description: "Atten=3dB F=27G-36G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3d1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Attenuators/Chip/KFA",
//     mfgPart: "KFA03.00-6SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3D1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3D1-0204D",
//     price: 10400,
//     priceType: "cents",
//     props: [3, 200, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjRf",
//     description: "Atten=4dB F=27G-36G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3d2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Attenuators/Chip/KFA",
//     mfgPart: "KFA04.00-6SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-A3D2-0204D",
//     price: 8500,
//     priceType: "cents",
//     props: [4, 200, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjRf",
//     description: "Atten=5dB F=27G-36G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3d3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Attenuators/Chip/KFA",
//     mfgPart: "KFA05.00-6SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-A3D3-0204D",
//     price: 9500,
//     priceType: "cents",
//     props: [5, 200, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjRf",
//     description: "Atten=6dB F=27G-36G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3d4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Attenuators/Chip/KFA",
//     mfgPart: "KFA06.00-6SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3D4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3D4-0204D",
//     price: 10400,
//     priceType: "cents",
//     props: [6, 200, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjRf",
//     description: "Atten=7dB F=27G-36G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3d5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Attenuators/Chip/KFA",
//     mfgPart: "KFA07.00-6SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-A3D5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [7, 200, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjRf",
//     description: "Atten=8dB F=27G-36G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3d6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Attenuators/Chip/KFA",
//     mfgPart: "KFA08.00-6SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-A3D6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [8, 200, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjRf",
//     description: "Atten=9dB F=27G-36G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3d7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Attenuators/Chip/KFA",
//     mfgPart: "KFA09.00-6SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-A3D7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [9, 200, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjRf",
//     description: "F=27G-36G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3d8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Attenuators/Chip/KFA",
//     mfgPart: "KFA10.00-6SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3D8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3D8-0204D",
//     price: 10400,
//     priceType: "cents",
//     props: [0, 200, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-1+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a562-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-1%2B",
//     mfgPart: "GAT-1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A562-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-2+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a563-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-2%2B",
//     mfgPart: "GAT-2+SMT",
//     models: "this part is missing models",
//     pn: "XM-A563-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-4+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a564-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-4%2B",
//     mfgPart: "GAT-4+SMT",
//     models: "this part is missing models",
//     pn: "XM-A564-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-5+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a565-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-5%2B",
//     mfgPart: "GAT-5+SMT",
//     models: "this part is missing models",
//     pn: "XM-A565-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-6+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a566-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-6%2B",
//     mfgPart: "GAT-6+SMT",
//     models: "this part is missing models",
//     pn: "XM-A566-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-7+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a567-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-7%2B",
//     mfgPart: "GAT-7+SMT",
//     models: "this part is missing models",
//     pn: "XM-A567-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-7+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a568-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-7%2B",
//     mfgPart: "GAT-7+SMT",
//     models: "this part is missing models",
//     pn: "XM-A568-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-8+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a569-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-8%2B",
//     mfgPart: "GAT-8+SMT",
//     models: "this part is missing models",
//     pn: "XM-A569-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-9+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a571-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-9%2B",
//     mfgPart: "GAT-9+SMT",
//     models: "this part is missing models",
//     pn: "XM-A571-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-10+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a572-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-10%2B",
//     mfgPart: "GAT-10+SMT",
//     models: "this part is missing models",
//     pn: "XM-A572-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-12+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a573-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-12%2B",
//     mfgPart: "GAT-12+SMT",
//     models: "this part is missing models",
//     pn: "XM-A573-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-15+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a574-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-15%2B",
//     mfgPart: "GAT-15+SMT",
//     models: "this part is missing models",
//     pn: "XM-A574-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-20+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a575-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-20%2B",
//     mfgPart: "GAT-20+SMT",
//     models: "this part is missing models",
//     pn: "XM-A575-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-30+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a576-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-30%2B",
//     mfgPart: "GAT-30+SMT",
//     models: "this part is missing models",
//     pn: "XM-A576-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc652lp2-hmc655lp2.pdf",
//     description: "Atten=2dB F=DC-25G PWR=300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5m8-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/fixed-attenuators/hmc652lp2e.html",
//     mfgPart: "HMC652LP2ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5M8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5M8-0204D",
//     price: 5500,
//     priceType: "cents",
//     props: [2, 300, 0, 25000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc652lp2-hmc655lp2.pdf",
//     description: "Atten=3dB F=DC-25G PWR=300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5m9-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/fixed-attenuators/hmc653lp2e.html",
//     mfgPart: "HMC653LP2ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5M9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5M9-0204D",
//     price: 5500,
//     priceType: "cents",
//     props: [3, 300, 0, 25000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc652lp2-hmc655lp2.pdf",
//     description: "Atten=4dB F=DC-25G PWR=300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5n1-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/fixed-attenuators/hmc654lp2e.html",
//     mfgPart: "HMC654LP2ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5N1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5N1-0204D",
//     price: 5500,
//     priceType: "cents",
//     props: [4, 300, 0, 25000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc656lp2-hmc658lp2.pdf",
//     description: "Atten=10dB F=DC-25G PWR=300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5n2-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/fixed-attenuators/hmc656lp2.html",
//     mfgPart: "HMC656LP2ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5N2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5N2-0204D",
//     price: 5300,
//     priceType: "cents",
//     props: [10, 300, 0, 25000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc656lp2-hmc658lp2.pdf",
//     description: "Atten=15dB F=DC-25G PWR=300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5n3-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/fixed-attenuators/hmc657lp2.html",
//     mfgPart: "HMC657LP2ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5N3-0204D",
//     price: 5500,
//     priceType: "cents",
//     props: [15, 300, 0, 25000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc656lp2-hmc658lp2.pdf",
//     description: "Atten=20dB F=DC-25G PWR=300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5n4-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/fixed-attenuators/hmc658lp2.html",
//     mfgPart: "HMC658LP2ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5N4-0204D",
//     price: 5500,
//     priceType: "cents",
//     props: [20, 300, 0, 25000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-0+.pdf",
//     description: "Atten=0dB F=DC-18G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9t9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-0%2B",
//     mfgPart: "YAT-0+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9T9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9T9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 2000, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-1+.pdf",
//     description: "Atten=1dB F=DC-18G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9u1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-1%2B",
//     mfgPart: "YAT-1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9U1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9U1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [1, 2000, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-2+.pdf",
//     description: "Atten=2dB F=DC-18G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9u2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-2%2B",
//     mfgPart: "YAT-2+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9U2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9U2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 2000, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-3+.pdf",
//     description: "Atten=3dB F=DC-18G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9u3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-3%2B",
//     mfgPart: "YAT-3+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9U3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9U3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [3, 2000, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-4+.pdf",
//     description: "Atten=4dB F=DC-18G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9u4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-4%2B",
//     mfgPart: "YAT-4+SMT",
//     models: "this part is missing models",
//     pn: "XM-A9U4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [4, 2000, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-5+.pdf",
//     description: "Atten=5dB F=DC-18G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9u5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-5%2B",
//     mfgPart: "YAT-5+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9U5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9U5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 2000, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-6+.pdf",
//     description: "Atten=6dB F=DC-18G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9u6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-6%2B",
//     mfgPart: "YAT-6+SMT",
//     models: "this part is missing models",
//     pn: "XM-A9U6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [6, 2000, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-7+.pdf",
//     description: "Atten=7dB F=DC-18G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9u7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-7%2B",
//     mfgPart: "YAT-7+SMT",
//     models: "this part is missing models",
//     pn: "XM-A9U7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [7, 2000, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-8+.pdf",
//     description: "Atten=8dB F=DC-18G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9u8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-8%2B",
//     mfgPart: "YAT-8+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9U8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9U8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [8, 2000, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-9+.pdf",
//     description: "Atten=9dB F=DC-18G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9u9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-9%2B",
//     mfgPart: "YAT-9+SMT",
//     models: "this part is missing models",
//     pn: "XM-A9U9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [9, 2000, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-10+.pdf",
//     description: "Atten=10dB F=DC-18G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9v1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-10%2B",
//     mfgPart: "YAT-10+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9V1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9V1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [10, 2000, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-12+.pdf",
//     description: "Atten=12dB F=DC-18G PWR=1800mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9v2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-12%2B",
//     mfgPart: "YAT-12+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9V2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9V2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [12, 1800, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-15+.pdf",
//     description: "Atten=15dB F=DC-18G PWR=1600mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9v3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-15%2B",
//     mfgPart: "YAT-15+SMT",
//     models: "this part is missing models",
//     pn: "XM-A9V3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [15, 1600, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-20+.pdf",
//     description: "Atten=20dB F=DC-18G PWR=1500mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9v4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-20%2B",
//     mfgPart: "YAT-20+SMT",
//     models: "this part is missing models",
//     pn: "XM-A9V4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [20, 1500, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/YAT-30A+.pdf",
//     description: "F=DC-18G PWR=1300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9v5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-30A%2B",
//     mfgPart: "YAT-30A+SMT",
//     models: "this part is missing models",
//     pn: "XM-A9V5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 1300, 0, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-4-D+.pdf",
//     description: "Atten=4dB F=DC-43.5G PWR=1700mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1a1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-4-DG%2B",
//     mfgPart: "KAT-4-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1A1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1A1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [4, 1700, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-6-D+.pdf",
//     description: "Atten=5dB F=DC-43.5G PWR=1400mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1a2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-5-DG%2B",
//     mfgPart: "KAT-5-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1A2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1A2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 1400, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-6-D+.pdf",
//     description: "Atten=6dB F=DC-43.5G PWR=1600mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1a3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-6-DG%2B",
//     mfgPart: "KAT-6-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1A3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1A3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [6, 1600, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-7-D+.pdf",
//     description: "Atten=7dB F=DC-43.5G PWR=1300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1a4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-7-DG%2B",
//     mfgPart: "KAT-7-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1A4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1A4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [7, 1300, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-8-D+.pdf",
//     description: "Atten=8dB F=DC-43.5G PWR=1200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1a5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-8-DG%2B",
//     mfgPart: "KAT-8-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1A5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1A5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [8, 1200, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-9-D+.pdf",
//     description: "Atten=9dB F=DC-43.5G PWR=1100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1a6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-9-DG%2B",
//     mfgPart: "KAT-9-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1A6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1A6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [9, 1100, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-10-D+.pdf",
//     description: "Atten=10dB F=DC-43.5G PWR=1700mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1a7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-10-DG%2B",
//     mfgPart: "KAT-10-D+DIE",
//     models: "this part is missing models",
//     pn: "XM-B1A7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [10, 1700, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-12-D+.pdf",
//     description: "Atten=12dB F=DC-43.5G PWR=1100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1a8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-12-DG%2B",
//     mfgPart: "KAT-12-D+DIE",
//     models: "this part is missing models",
//     pn: "XM-B1A8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [12, 1100, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-15-D+.pdf",
//     description: "Atten=15dB F=DC-43.5G PWR=1400mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1a9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-15-DG%2B",
//     mfgPart: "KAT-15-D+DIE",
//     models: "this part is missing models",
//     pn: "XM-B1A9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [15, 1400, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-20-D+.pdf",
//     description: "Atten=20dB F=DC-43.5G PWR=800mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1b1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-20-DG%2B",
//     mfgPart: "KAT-20-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1B1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1B1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [20, 800, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-30-D+.pdf",
//     description: "Atten=30dB F=DC-43.5G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1b2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-30-DG%2B",
//     mfgPart: "KAT-30-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1B2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1B2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [30, 1000, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-0-D+.pdf",
//     description: "Atten=0dB F=DC-43.5G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1g6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-0-DG%2B",
//     mfgPart: "KAT-0-D+DIE",
//     models: "this part is missing models",
//     pn: "XM-B1G6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 2000, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-1-D+.pdf",
//     description: "Atten=1dB F=DC-43.5G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1g7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-1-DG%2B",
//     mfgPart: "KAT-1-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1G7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1G7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [1, 2000, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-2-D+.pdf",
//     description: "Atten=2dB F=DC-43.5G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1g8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-2-DG%2B",
//     mfgPart: "KAT-2-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1G8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1G8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 2000, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1462",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-3-D+.pdf",
//     description: "Atten=3dB F=DC-43.5G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1462A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1g9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-3-DG%2B",
//     mfgPart: "KAT-3-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1G9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1G9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [3, 2000, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1462_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc652lp2-hmc655lp2.pdf",
//     description: "Atten=6dB F=DC-25G PWR=300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1k4-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/fixed-attenuators/hmc655lp2e.html",
//     mfgPart: "HMC655LP2ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1K4-0204D",
//     price: 5500,
//     priceType: "cents",
//     props: [6, 300, 0, 25000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=0dB F=36G-40G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1x7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0004SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1X7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 200, 36000, 40000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=1dB F=36G-40G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1x8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0104SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1X8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [1, 200, 36000, 40000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=2dB F=36G-40G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1x9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0204SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1X9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 200, 36000, 40000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=3dB F=36G-40G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1y1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0304SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Y1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [3, 200, 36000, 40000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=4dB F=36G-40G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1y2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0404SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Y2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [4, 200, 36000, 40000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=5dB F=36G-40G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1y3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0504SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Y3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 200, 36000, 40000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=6dB F=36G-40G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1y4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0604SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Y4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [6, 200, 36000, 40000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=7dB F=36G-40G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1y5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0704SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Y5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [7, 200, 36000, 40000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=8dB F=36G-40G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1y6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0804SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Y6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [8, 200, 36000, 40000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=9dB F=36G-40G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1y7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0904SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Y7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [9, 200, 36000, 40000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=10dB F=36G-40G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1y8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA1004SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Y8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [10, 200, 36000, 40000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=0dB F=40G-50G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1y9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0005SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Y9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 200, 40000, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=2dB F=40G-50G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1z2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0205SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Z2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 200, 40000, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=3dB F=40G-50G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1z3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0305SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Z3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [3, 200, 40000, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=4dB F=40G-50G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1z4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0405SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Z4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [4, 200, 40000, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=5dB F=40G-50G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1z5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0505SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Z5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 200, 40000, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=6dB F=40G-50G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1z6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0605SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Z6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [6, 200, 40000, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=7dB F=40G-50G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1z7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0705SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Z7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [7, 200, 40000, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=8dB F=40G-50G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1z8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0805SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Z8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [8, 200, 40000, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=9dB F=40G-50G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1z9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0905SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1Z9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [9, 200, 40000, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=10dB F=40G-50G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2a1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA1005SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-B2A1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [10, 200, 40000, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0563",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc650-hmc658.pdf",
//     description: "Atten=0dB F=DC-50G PWR=300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0563C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2n7-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/fixed-attenuators/hmc650.html",
//     mfgPart: "HMC650DIE",
//     models: "this part is missing models",
//     pn: "XM-B2N7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 300, 0, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0563_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0563",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc650-hmc658.pdf",
//     description: "Atten=2dB F=DC-50G PWR=500mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0563C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2n9-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc652-die.html",
//     mfgPart: "HMC652DIE",
//     models: "this part is missing models",
//     pn: "XM-B2N9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 500, 0, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0563_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0563",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc650-hmc658.pdf",
//     description: "Atten=3dB F=DC-50G PWR=390mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0563C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2p1-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/fixed-attenuators/hmc653-die.html",
//     mfgPart: "HMC653DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2P1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2P1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [3, 390, 0, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0563_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0563",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc650-hmc658.pdf",
//     description: "Atten=4dB F=DC-50G PWR=300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0563C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2p2-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/fixed-attenuators/hmc654-die.html",
//     mfgPart: "HMC654DIE",
//     models: "this part is missing models",
//     pn: "XM-B2P2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [4, 300, 0, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0563_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0563",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc650-hmc658.pdf",
//     description: "Atten=6dB F=DC-50G PWR=390mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0563C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2p3-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/fixed-attenuators/hmc655-die.html",
//     mfgPart: "HMC655DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2P3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2P3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [6, 390, 0, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0563_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0563",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc650-hmc658.pdf",
//     description: "Atten=10dB F=DC-50G PWR=300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0563C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2p4-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc656-die.html",
//     mfgPart: "HMC656DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2P4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2P4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [10, 300, 0, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0563_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0563",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc650-hmc658.pdf",
//     description: "Atten=15dB F=DC-50G PWR=300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0563C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2p5-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc657-die.html",
//     mfgPart: "HMC657DIE",
//     models: "this part is missing models",
//     pn: "XM-B2P5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [15, 300, 0, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0563_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-0+.pdf",
//     description: "Atten=0dB F=DC-43.5G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4w1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-0%2B",
//     mfgPart: "KAT-0+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4W1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4W1-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 2000, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-1+.pdf",
//     description: "Atten=1dB F=DC-43.5G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4w2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-1%2B",
//     mfgPart: "KAT-1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4W2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4W2-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1, 2000, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-2+.pdf",
//     description: "Atten=2dB F=DC-43.5G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4w3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-2%2B",
//     mfgPart: "KAT-2+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4W3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4W3-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [2, 2000, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-3+.pdf",
//     description: "Atten=3dB F=DC-43.5G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4w4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-3%2B",
//     mfgPart: "KAT-3+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4W4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4W4-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [3, 2000, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-4+.pdf",
//     description: "Atten=4dB F=DC-43.5G PWR=1700mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4w5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-4%2B",
//     mfgPart: "KAT-4+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4W5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4W5-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [4, 1700, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-5+.pdf",
//     description: "Atten=5dB F=DC-43.5G PWR=1400mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4w6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-5%2B",
//     mfgPart: "KAT-5+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4W6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4W6-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [5, 1400, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-6+.pdf",
//     description: "Atten=6dB F=DC-43.5G PWR=1600mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4w7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-6-DG%2B",
//     mfgPart: "KAT-6+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4W7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4W7-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [6, 1600, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-7+.pdf",
//     description: "Atten=7dB F=DC-43.5G PWR=1300mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4w8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-7%2B",
//     mfgPart: "KAT-7+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4W8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4W8-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [7, 1300, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-8+.pdf",
//     description: "Atten=8dB F=DC-43.5G PWR=1200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4w9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/MCLStore/ModelSearch?model=KAT-8%2B",
//     mfgPart: "KAT-8+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4W9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4W9-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [8, 1200, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-9+.pdf",
//     description: "Atten=9dB F=DC-43.5G PWR=1100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4y1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-9%2B",
//     mfgPart: "KAT-9+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4Y1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4Y1-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [9, 1100, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-10+.pdf",
//     description: "Atten=10dB F=DC-43.5G PWR=1700mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4y2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-10%2B",
//     mfgPart: "KAT-10+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4Y2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4Y2-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [10, 1700, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-12+.pdf",
//     description: "Atten=12dB F=DC-43.5G PWR=1100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4y3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-12%2B",
//     mfgPart: "KAT-12+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4Y3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4Y3-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [12, 1100, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-15+.pdf",
//     description: "Atten=15dB F=DC-43.5G PWR=1400mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4y4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-15%2B",
//     mfgPart: "KAT-15+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4Y4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4Y4-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [15, 1400, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-20+.pdf",
//     description: "Atten=20dB F=DC-43.5G PWR=800mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4y5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-20%2B",
//     mfgPart: "KAT-20+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4Y5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4Y5-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [20, 800, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KAT-30+.pdf",
//     description: "Atten=30dB F=DC-43.5G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4y6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KAT-30-DG%2B",
//     mfgPart: "KAT-30+SMT",
//     models: "this part is missing models",
//     pn: "XM-B4Y6-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [30, 1000, 0, 43500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/d/MzBfMTQ3NDczMzFf/TS03XX-1000435.pdf",
//     description: "Atten=0dB F=DC-12.4G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0300W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5E9-0204D",
//     price: 7300,
//     priceType: "cents",
//     props: [0, 2000, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/d/MzBfMTQ3NDczMzFf/TS03XX-1000435.pdf",
//     description: "Atten=1dB F=DC-12.4G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5f1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0301W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5F1-0204D",
//     price: 7300,
//     priceType: "cents",
//     props: [1, 2000, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/d/MzBfMTQ3NDczMzFf/TS03XX-1000435.pdf",
//     description: "Atten=2dB F=DC-12.4G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5f2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0302W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5F2-0204D",
//     price: 7300,
//     priceType: "cents",
//     props: [2, 2000, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/d/MzBfMTQ3NDczMzFf/TS03XX-1000435.pdf",
//     description: "Atten=3dB F=DC-12.4G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5f3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0303W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5F3-0204D",
//     price: 7300,
//     priceType: "cents",
//     props: [3, 2000, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/d/MzBfMTQ3NDczMzFf/TS03XX-1000435.pdf",
//     description: "Atten=4dB F=DC-12.4G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5f4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0304W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5F4-0204D",
//     price: 7300,
//     priceType: "cents",
//     props: [4, 2000, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/d/MzBfMTQ3NDczMzFf/TS03XX-1000435.pdf",
//     description: "Atten=5dB F=DC-12.4G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5f5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0305W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5F5-0204D",
//     price: 7300,
//     priceType: "cents",
//     props: [5, 2000, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/d/MzBfMTQ3NDczMzFf/TS03XX-1000435.pdf",
//     description: "Atten=6dB F=DC-12.4G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5f6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0306W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5F6-0204D",
//     price: 7300,
//     priceType: "cents",
//     props: [6, 2000, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/d/MzBfMTQ3NDczMzFf/TS03XX-1000435.pdf",
//     description: "Atten=8dB F=DC-12.4G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5f7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0308W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5F7-0204D",
//     price: 7300,
//     priceType: "cents",
//     props: [8, 2000, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/d/MzBfMTQ3NDczMzFf/TS03XX-1000435.pdf",
//     description: "Atten=10dB F=DC-12.4G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5f8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0310W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5F8-0204D",
//     price: 7300,
//     priceType: "cents",
//     props: [10, 2000, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/d/MzBfMTQ3NDczMzFf/TS03XX-1000435.pdf",
//     description: "Atten=20dB F=DC-12.4G PWR=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5f9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0320W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5F9-0204D",
//     price: 7300,
//     priceType: "cents",
//     props: [20, 2000, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "Atten=0dB F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5v6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-00SMT",
//     models: "this part is missing models",
//     pn: "XM-B5V6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "Atten=1dB F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5v7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-01SMT",
//     models: "this part is missing models",
//     pn: "XM-B5V7-0204D",
//     price: 20700,
//     priceType: "cents",
//     props: [1, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "Atten=2dB F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5v8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-02SMT",
//     models: "this part is missing models",
//     pn: "XM-B5V8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "Atten=3dB F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5v9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-03SMT",
//     models: "this part is missing models",
//     pn: "XM-B5V9-0204D",
//     price: 20700,
//     priceType: "cents",
//     props: [3, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "Atten=4dB F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5y1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-04SMT",
//     models: "this part is missing models",
//     pn: "XM-B5Y1-0204D",
//     price: 20700,
//     priceType: "cents",
//     props: [4, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "Atten=5dB F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5y2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-05SMT",
//     models: "this part is missing models",
//     pn: "XM-B5Y2-0204D",
//     price: 20700,
//     priceType: "cents",
//     props: [5, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "Atten=6dB F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5y3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-06SMT",
//     models: "this part is missing models",
//     pn: "XM-B5Y3-0204D",
//     price: 20700,
//     priceType: "cents",
//     props: [6, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "Atten=0dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6a3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0600FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6A3-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "Atten=1dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6a4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0601FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6A4-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [1, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "Atten=2dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6a5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0602FSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B6A5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B6A5-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [2, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "Atten=3dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6a6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0603FSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B6A6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B6A6-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [3, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "Atten=4dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6a7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0604FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6A7-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [4, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "Atten=5dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6a8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0605FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6A8-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [5, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "Atten=6dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6a9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0606FSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B6A9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B6A9-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [6, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "Atten=7dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6b1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0607FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6B1-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [7, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "Atten=8dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6b2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0608FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6B2-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [8, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "Atten=9dB F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6b3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0609FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6B3-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [9, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6b4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0610FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6B4-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6b5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0611FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6B5-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6b6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0612FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6B6-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6b7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0613FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6B7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6b8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0614FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6B8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6b9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0615FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6B9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1237",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/s/MzBfMTQ3NDczNTVf/TS06XXF-1014215.pdf",
//     description: "F=DC-20G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1237A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6c1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0620FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6C1-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "1237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0711",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNDFf",
//     description: "F=DC-6G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0711A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7e8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0403W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B7E8-0204D",
//     price: 5600,
//     priceType: "cents",
//     props: [0, 1000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0711_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0711",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNDFf",
//     description: "F=DC-6G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0711A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7e9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0410W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B7E9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 1000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0711_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1030",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GAT-3+.pdf",
//     description: "Atten=3dB F=DC-8G PWR=500mW ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1030A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7k8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GAT-3%2B",
//     mfgPart: "GAT-3+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B7K8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B7K8-0404D",
//     price: 5200,
//     priceType: "cents",
//     props: [3, 500, 0, 8000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "Atten=20dB F=DC-26G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9l6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-20SMT",
//     models: "this part is missing models",
//     pn: "XM-B9L6-0204D",
//     price: 20700,
//     priceType: "cents",
//     props: [20, 20000, 0, 26000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0711",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNDFf",
//     description: "Atten=1dB F=DC-6G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0711A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3m4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0401W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3M4-0204D",
//     price: 5500,
//     priceType: "cents",
//     props: [1, 1000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0711_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0711",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNDFf",
//     description: "Atten=2dB F=DC-6G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0711A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3m5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0402W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3M5-0204D",
//     price: 5500,
//     priceType: "cents",
//     props: [2, 1000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0711_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0711",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNDFf",
//     description: "Atten=4dB F=DC-6G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0711A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3m6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0404W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3M6-0204D",
//     price: 5500,
//     priceType: "cents",
//     props: [4, 1000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0711_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0711",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNDFf",
//     description: "Atten=5dB F=DC-6G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0711A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3m7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0405W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3M7-0204D",
//     price: 5500,
//     priceType: "cents",
//     props: [5, 1000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0711_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0711",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNDFf",
//     description: "Atten=6dB F=DC-6G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0711A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3m8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0406W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3M8-0204D",
//     price: 5500,
//     priceType: "cents",
//     props: [6, 1000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0711_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0711",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNDFf",
//     description: "Atten=7dB F=DC-6G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0711A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3m9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0407W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3M9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [7, 1000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0711_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0711",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNDFf",
//     description: "Atten=8dB F=DC-6G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0711A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3n1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0408W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3N1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [8, 1000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0711_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0711",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNDFf",
//     description: "Atten=9dB F=DC-6G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0711A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3n2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0409W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3N2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [9, 1000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0711_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0711",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczNDFf",
//     description: "Atten=4dB F=DC-6G PWR=1000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0711A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3n3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/fixed-attenuators-small-signal/",
//     mfgPart: "TS0420W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3N3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [4, 1000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0711_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c747-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-09SMT",
//     models: "this part is missing models",
//     pn: "XM-C747-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c748-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-10SMT",
//     models: "this part is missing models",
//     pn: "XM-C748-0204D",
//     price: 20700,
//     priceType: "cents",
//     props: [0, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c749-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-15SMT",
//     models: "this part is missing models",
//     pn: "XM-C749-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c751-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-30SMT",
//     models: "this part is missing models",
//     pn: "XM-C751-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0321",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDczMjdf",
//     description: "Atten=1dB F=40G-50G PWR=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0321C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7w4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/CMSPages/GetFile.aspx?guid=26632f13-1267-4596-9014-3bde964819d3",
//     mfgPart: "QFA0105SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C7W4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [1, 200, 40000, 50000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "Atten=7dB F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8k7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-07SMT",
//     models: "this part is missing models",
//     pn: "XM-C8K7-0204D",
//     price: 20700,
//     priceType: "cents",
//     props: [7, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0710",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyNjRf",
//     description: "Atten=8dB F=DC-26.5G PWR=20000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0710A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8k8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "http://www.emc-rflabs.com/Passive-Components/Diamond-Rf-Resistives/Diamond-Attenuator/Diamond-Chip-Attenuator",
//     mfgPart: "CA0505D-08SMT",
//     models: "this part is missing models",
//     pn: "XM-C8K8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [8, 20000, 0, 26500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0710_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc651.html",
//     mfgPart: "HMC651",
//     models: "this is the new part",
//     pn: "XM-B2N8-0304D",
//     price: 30700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0572_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc658-die.html",
//     mfgPart: "HMC658",
//     models: "this is the new part",
//     pn: "XM-B2P6-0304D",
//     price: 28700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0572_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-5A%2B",
//     mfgPart: "YAT-5A+",
//     models: "this is the new part",
//     pn: "XM-D2G2-0204D",
//     price: 4800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-3A%2B",
//     mfgPart: "YAT-3A+",
//     models: "this is the new part",
//     pn: "XM-D2N4-0204D",
//     price: 4800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-1A%2B",
//     mfgPart: "YAT-1A+",
//     models: "this is the new part",
//     pn: "XM-D3E1-0204D",
//     price: 4800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-2A%2B",
//     mfgPart: "YAT-2A+",
//     models: "this is the new part",
//     pn: "XM-D3E2-0204D",
//     price: 4800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-6A%2B",
//     mfgPart: "YAT-6A+",
//     models: "this is the new part",
//     pn: "XM-D3E6-0204D",
//     price: 4800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-0A%2B",
//     mfgPart: "YAT-0A+",
//     models: "this is the new part",
//     pn: "XM-D3F1-0204D",
//     price: 4800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PAD",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=YAT-10A%2B",
//     mfgPart: "YAT-10A+",
//     models: "this is the new part",
//     pn: "XM-D3F2-0204D",
//     price: 4800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Fixed Attenuators",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0299",
//     catId: "DSA",
//     ctlBoard: "XM-A763-0604D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe43704ds.pdf",
//     description: "F=9K-8G MaxAtten=31.75dB Step=.25dB IL=2.8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0299D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3k6-0604d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "http://www.psemi.com/products/digital-step-attenuators-dsa/pe43704",
//     mfgPart: "PE43704",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3K6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3K6-0604D",
//     price: 12800,
//     priceType: "cents",
//     props: [null, 0.009, 8000, 31.75, 0.25, 2.8, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0299_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0701",
//     catId: "DSA",
//     ctlBoard: "XM-A8E5-0604D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe43705ds.pdf",
//     description: "F=50M-8G MaxAtten=31.75dB Step=.25dB IL=2.8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0701D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3k7-0604d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "http://www.psemi.com/products/digital-step-attenuators-dsa/pe43705",
//     mfgPart: "PE43705",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3K7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3K7-0604D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 50, 8000, 31.75, 0.25, 2.8, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0701_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0701",
//     catId: "DSA",
//     ctlBoard: "XM-A8E5-0604D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe43712ds.pdf",
//     description: "F=9K-6G MaxAtten=31.75dB Step=.25dB IL=2.8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0701D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3k8-0604d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "http://www.psemi.com/products/digital-step-attenuators-dsa/pe43712",
//     mfgPart: "PE43712",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3K8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3K8-0604D",
//     price: 11400,
//     priceType: "cents",
//     props: [null, 0.009, 6000, 31.75, 0.25, 2.8, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0701_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0701",
//     catId: "DSA",
//     ctlBoard: "XM-A763-0604D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe43713ds.pdf",
//     description: "F=9K-6G MaxAtten=31.75dB Step=0.25dB IL=2.8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0701D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3k9-0604d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "http://www.psemi.com/products/digital-step-attenuators-dsa/pe43713",
//     mfgPart: "PE43713",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3K9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3K9-0604D",
//     price: 14500,
//     priceType: "cents",
//     props: [null, 0.009, 6000, 31.75, 0.25, 2.8, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0701_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1602",
//     catId: "DSA",
//     ctlBoard: "XM-A498-0804D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe43713ds.pdf",
//     description: "F=9K-6G MaxAtten=31.75dB Step=0.25dB IL=2.8dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1602A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a497-0804d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "http://www.psemi.com/products/digital-step-attenuators-dsa/pe43713",
//     mfgPart: "PE43713",
//     models: "this part is missing models",
//     pn: "XM-A497-0804D",
//     price: 13800,
//     priceType: "cents",
//     props: [null, 0.009, 6000, 31.75, 0.25, 2.8, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1602_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0432",
//     catId: "DSA",
//     ctlBoard: "XM-A769-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1018a.pdf",
//     description: "F=100M-30G MaxAtten=31dB Step=1dB IL=5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0432C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5d6-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/hmc1018a.html",
//     mfgPart: "HMC1018ALP4E",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5D6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5D6-0604D",
//     price: 32900,
//     priceType: "cents",
//     props: [null, 100, 30000, 31, 1, 5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0432_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0432",
//     catId: "DSA",
//     ctlBoard: "XM-A769-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1019a.pdf",
//     description: "F=100M-30G MaxAtten=15.5dB Step=.5dB IL=5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0432C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5d7-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/hmc1019a.html",
//     mfgPart: "HMC1019ALP4E",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5D7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5D7-0604D",
//     price: 32800,
//     priceType: "cents",
//     props: [null, 100, 30000, 15.5, 0.5, 5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0432_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1089",
//     catId: "DSA",
//     ctlBoard: "XM-B2G9-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc424a.pdf",
//     description: "F=DC-13G MaxAtten=31.5dB Step=.5dB IL=3.9dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1089B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a689-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/hmc424alp3e.html",
//     mfgPart: "HMC424ALP3E",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A689D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A689-0604D",
//     price: 17000,
//     priceType: "cents",
//     props: [null, 0, 13000, 31.5, 0.5, 3.9, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1087",
//     catId: "DSA",
//     ctlBoard: "XM-B2J2-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc540s.pdf",
//     description: "F=100M-8G MaxAtten=15dB Step=1dB IL=1.7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1087B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6a7-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/hmc540s.html",
//     mfgPart: "HMC540SLP3E",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6A7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6A7-0604D",
//     price: 13000,
//     priceType: "cents",
//     props: [null, 100, 8000, 15, 1, 1.7, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1087_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0671",
//     catId: "DSA",
//     ctlBoard: "XM-B128-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc939alp4e.pdf",
//     description: "F=100M-33G MaxAtten=31dB Step=1dB IL=6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0671B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6e3-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/hmc939alp4e.html",
//     mfgPart: "HMC939ALP4E",
//     models: "this part is missing models",
//     pn: "XM-A6E3-0604D",
//     price: 27800,
//     priceType: "cents",
//     props: [null, 100, 33000, 31, 1, 6, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0671",
//     catId: "DSA",
//     ctlBoard: "XM-B129-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC941ALP4E.pdf",
//     description: "F=100M-33G MaxAtten=15.5dB Step=.5dB IL=4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0671B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6e4-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/hmc941alp4e.html",
//     mfgPart: "HMC941ALP4E",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6E4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6E4-0604D",
//     price: 28200,
//     priceType: "cents",
//     props: [null, 100, 33000, 15.5, 0.5, 4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0612",
//     catId: "DSA",
//     ctlBoard: "XM-A762-0604D",
//     datasheetURL:
//       "http://www.richardsonrfpd.com/resources/RellDocuments/SYS_27/CHT4012-QDG_Full-1039.pdf",
//     description: "F=DC-6G MaxAtten=31.5dB Step=.5dB IL=2.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0612A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a761-0604d/",
//     mfgCode: "UMSMI",
//     mfgLandingPage: "https://www.ums-rf.com/product/cht4012-qdg/",
//     mfgPart: "CHT4012-QDG",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A761D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A761-0604D",
//     price: 23500,
//     priceType: "cents",
//     props: [null, 0, 6000, 31.5, 0.5, 2.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0599",
//     catId: "DSA",
//     ctlBoard: "XM-A8E4-0404D",
//     datasheetURL: "https://www.idt.com/document/dst/f1953-final-datasheet",
//     description: "F=400M-4G MaxAtten=31.5dB Step=.5dB IL=1.4dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0599B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7d2-0404d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-attenuators/f1953-6-bit-050db-glitch-free-digital-step-attenuator-internal-dc-blocks",
//     mfgPart: "F1953",
//     models: "this part is missing models",
//     pn: "XM-A7D2-0404D",
//     price: 10400,
//     priceType: "cents",
//     props: [null, 400, 4000, 31.5, 0.5, 1.4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0599",
//     catId: "DSA",
//     ctlBoard: "XM-A8E4-0404D",
//     datasheetURL: "https://www.idt.com/document/dst/f1912-datasheet",
//     description: "F=1M-4G MaxAtten=31.5dB Step=.5dB IL=1.4dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0599B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7d3-0404d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-attenuators/f1912-6-bit-05-db-glitch-free-wideband-digital-step-attenuator",
//     mfgPart: "F1912",
//     models: "this part is missing models",
//     pn: "XM-A7D3-0404D",
//     price: 10400,
//     priceType: "cents",
//     props: [null, 1, 4000, 31.5, 0.5, 1.4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1169",
//     catId: "DSA",
//     ctlBoard: "XM-A8E5-0604D",
//     datasheetURL: "https://www.idt.com/document/dst/f1956-datasheet-rev02",
//     description: "F=1M-6G MaxAtten=31.75dB Step=.25dB IL=1.3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1169B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7d4-0604d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-attenuators/f1956-7-bit-025-db-glitch-free-wideband-digital-step-attenuator",
//     mfgPart: "F1956",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7D4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7D4-0604D",
//     price: 12200,
//     priceType: "cents",
//     props: [null, 1, 6000, 31.75, 0.25, 1.3, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1169_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0509",
//     catId: "DSA",
//     ctlBoard: "XM-A7M2-0604D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAD-011021.pdf",
//     description: "F=DC-30G MaxAtten=31.5dB Step=.5dB IL=7.2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0509A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7m1-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAAD-011021",
//     mfgPart: "MAAD-011021",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7M1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7M1-0604D",
//     price: 26500,
//     priceType: "cents",
//     props: [null, 0, 30000, 31.5, 0.5, 7.2, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0509_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1215",
//     catId: "DSA",
//     ctlBoard: "XM-B938-0604D",
//     datasheetURL: "https://www.psemi.com/pdf/datasheets/pe43508ds.pdf",
//     description: "F=9K-55G MaxAtten=31.5dB Step=0.5dB IL=5.9dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1215A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8f2-0604d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "http://www.psemi.com/products/digital-step-attenuators-dsa/pe43508",
//     mfgPart: "PE43508DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A8F2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A8F2-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 0.009, 55000, 31.5, 0.5, 5.9, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1215_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0833",
//     catId: "DSA",
//     ctlBoard: "XM-B1C6-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5730.pdf",
//     description: "F=100M-40G MaxAtten=31.5dB Step=0.5dB IL=4.8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0833C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1c5-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/adrf5730.html",
//     mfgPart: "ADRF5730BCCZN",
//     models: "this part is missing models",
//     pn: "XM-B1C5-0604D",
//     price: 28900,
//     priceType: "cents",
//     props: [null, 100, 40000, 31.5, 0.5, 4.8, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0833_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0833",
//     catId: "DSA",
//     ctlBoard: "XM-B1M6-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5720.pdf",
//     description: "F=9K-40G MaxAtten=31.5dB Step=0.5dB IL=4.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0833C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1m3-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/adrf5720.html",
//     mfgPart: "ADRF5720",
//     models: "this part is missing models",
//     pn: "XM-B1M3-0604D",
//     price: 29800,
//     priceType: "cents",
//     props: [null, 0.009, 40000, 31.5, 0.5, 4.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0833_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1086",
//     catId: "DSA",
//     ctlBoard: "XM-B2E4-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1119.pdf",
//     description: "F=100M-6G MaxAtten=31.75dB Step=0.25dB IL=1.3dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1086A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2e1-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/hmc1119.html",
//     mfgPart: "HMC1119LP4ME",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2E1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2E1-0804D",
//     price: 16600,
//     priceType: "cents",
//     props: [null, 100, 6000, 31.75, 0.25, 1.3, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E9",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1086_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0939",
//     catId: "DSA",
//     ctlBoard: "XM-B2J4-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5730.pdf",
//     description: "F=100M-40G MaxAtten=31.5dB Step=0.5dB IL=4.8dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0939B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2j3-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/adrf5730.html",
//     mfgPart: "ADRF5730BCCZN",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2J3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2J3-0804D",
//     price: 31500,
//     priceType: "cents",
//     props: [null, 100, 40000, 31.5, 0.5, 4.8, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E9",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0939_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1278",
//     catId: "DSA",
//     ctlBoard: "XM-B814-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1122.pdf",
//     description: "F=100M-6G MaxAtten=31.5dB Step=0.5dB IL=1.3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1278A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2r5-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/hmc1122.html",
//     mfgPart: "HMC1122LP4ME",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2R5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2R5-0604D",
//     price: 13600,
//     priceType: "cents",
//     props: [null, 100, 6000, 31.5, 0.5, 1.3, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1278_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1081",
//     catId: "DSA",
//     ctlBoard: "XM-B5G3-0604D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc624a.pdf",
//     description: "F=100M-6G MaxAtten=31.5dB Step=.5dB IL=1.6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1081A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5g2-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc624a.html",
//     mfgPart: "HMC624ALP4E",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5G2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5G2-0604D",
//     price: 14800,
//     priceType: "cents",
//     props: [null, 100, 6000, 31.5, 0.5, 1.6, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1081_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0821",
//     catId: "DSA",
//     ctlBoard: "XM-A8E1-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005902",
//     description: "F=50M-6G MaxAtten=18dB Step=6dB IL=0.8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0821A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6e4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPC3223",
//     mfgPart: "QPC3223",
//     models: "this part is missing models",
//     pn: "XM-B6E4-0404D",
//     price: 9200,
//     priceType: "cents",
//     props: [null, 50, 6000, 18, 6, 0.8, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0821_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1181",
//     catId: "DSA",
//     ctlBoard: "XM-B129-0604D",
//     datasheetURL: "https://www.qorvo.com/products/d/da001112",
//     description: "F=5M-6G MaxAtten=15dB Step=1dB IL=1.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1181A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6e5-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/RFSA3413",
//     mfgPart: "RFSA3413",
//     models: "this part is missing models",
//     pn: "XM-B6E5-0604D",
//     price: 10600,
//     priceType: "cents",
//     props: [null, 5, 6000, 15, 1, 1.4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1173",
//     catId: "DSA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da001116",
//     description: "F=5M-6G MaxAtten=31dB Step=1dB IL=1.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1173A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6e6-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/RFSA3513",
//     mfgPart: "RFSA3513",
//     models: "this part is missing models",
//     pn: "XM-B6E6-0604D",
//     price: 12600,
//     priceType: "cents",
//     props: [null, 5, 6000, 31, 1, 1.4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1173_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1173",
//     catId: "DSA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da001123",
//     description: "F=5M-6G MaxAtten=31.5dB Step=0.5dB IL=1.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1173A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6e7-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/RFSA3613",
//     mfgPart: "RFSA3613",
//     models: "this part is missing models",
//     pn: "XM-B6E7-0604D",
//     price: 9900,
//     priceType: "cents",
//     props: [null, 5, 6000, 31.5, 0.5, 1.4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1173_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1173",
//     catId: "DSA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da001129",
//     description: "F=5M-6G MaxAtten=31.75dB Step=0.25dB IL=1.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1173A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6e8-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/RFSA3713",
//     mfgPart: "RFSA3713",
//     models: "this part is missing models",
//     pn: "XM-B6E8-0604D",
//     price: 9900,
//     priceType: "cents",
//     props: [null, 5, 6000, 31.75, 0.25, 1.4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1173_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1086",
//     catId: "DSA",
//     ctlBoard: "XM-B5W1-0804D",
//     datasheetURL: "https://www.qorvo.com/products/d/da001133",
//     description: "F=50M-6G MaxAtten=31.75dB Step=0.25dB IL=1.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1086A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6e9-0804d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/RFSA3714",
//     mfgPart: "RFSA3714",
//     models: "this part is missing models",
//     pn: "XM-B6E9-0804D",
//     price: 15500,
//     priceType: "cents",
//     props: [null, 50, 6000, 31.75, 0.25, 1.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E9",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1086_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0701",
//     catId: "DSA",
//     ctlBoard: "XM-A8E5-0604D",
//     datasheetURL: "https://www.qorvo.com/products/d/da001138",
//     description: "F=5M-4G MaxAtten=31.75dB Step=0.25dB IL=1.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0701D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6f1-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/RFSA3715",
//     mfgPart: "RFSA3715",
//     models: "this part is missing models",
//     pn: "XM-B6F1-0604D",
//     price: 10600,
//     priceType: "cents",
//     props: [null, 5, 4000, 31.75, 0.25, 1.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0701_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1172",
//     catId: "DSA",
//     ctlBoard: "XM-B1M6-0604D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5731.pdf",
//     description: "F=100M-40G MaxAtten=30dB Step=2dB IL=3.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1172A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6j7-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5731.html",
//     mfgPart: "ADRF5731",
//     models: "this part is missing models",
//     pn: "XM-B6J7-0604D",
//     price: 32100,
//     priceType: "cents",
//     props: [null, 100, 40000, 30, 2, 3.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1172_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1172",
//     catId: "DSA",
//     ctlBoard: "XM-B1M6-0604D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5721.pdf",
//     description: "F=9K-40G MaxAtten=30dB Step=2dB IL=3.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1172A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6j8-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/adrf5721.html?doc=ADRF5721.pdf",
//     mfgPart: "ADRF5721",
//     models: "this part is missing models",
//     pn: "XM-B6J8-0604D",
//     price: 33200,
//     priceType: "cents",
//     props: [null, 0.009, 40000, 30, 2, 3.4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1172_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1171",
//     catId: "DSA",
//     ctlBoard: "XM-B2J4-0804D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5740.pdf",
//     description: "F=100M-60G MaxAtten=22dB Step=2dB IL=2.9dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1171A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7p2-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5740.html",
//     mfgPart: "ADRF5740",
//     models: "this part is missing models",
//     pn: "XM-B7P2-0804D",
//     price: 34700,
//     priceType: "cents",
//     props: [null, 100, 60000, 22, 2, 2.9, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E9",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1171_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0939",
//     catId: "DSA",
//     ctlBoard: "XM-C9G5-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5720.pdf",
//     description: "F=9K-40G MaxAtten=31.5dB Step=0.5dB IL=4.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0939B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7p7-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/adrf5720.html",
//     mfgPart: "ADRF5720",
//     models: "this part is missing models",
//     pn: "XM-B7P7-0804D",
//     price: 31900,
//     priceType: "cents",
//     props: [null, 0.009, 40000, 31.5, 0.5, 4.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E9",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0939_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1210",
//     catId: "DSA",
//     ctlBoard: "XM-C588-0604D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd279_ver_1.0_0418.pdf",
//     description: "F=2G-30G MaxAtten=15.5dB Step=0.2dB IL=3.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1210A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8c7-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd279-digital-attenuator/",
//     mfgPart: "CMD279DIE",
//     models: "this part is missing models",
//     pn: "XM-B8C7-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2000, 30000, 15.5, 0.2, 3.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1211",
//     catId: "DSA",
//     ctlBoard: "XM-C588-0604D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd279c3-datasheet.pdf",
//     description: "F=2G-18G MaxAtten=15.5dB Step=0.2dB IL=3.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1211A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8c8-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd279c3-digital-attenuator/",
//     mfgPart: "CMD279C3",
//     models: "this part is missing models",
//     pn: "XM-B8C8-0604D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 2000, 18000, 15.5, 0.2, 3.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1211_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1213",
//     catId: "DSA",
//     ctlBoard: "XM-B9D7-0604D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd280c3-datasheet.pdf",
//     description: "F=DC-18G MaxAtten=15.5dB Step=0.1dB IL=3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1213A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8c9-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd280c3-digital-attenuator/",
//     mfgPart: "CMD280C3",
//     models: "this part is missing models",
//     pn: "XM-B8C9-0604D",
//     price: 18500,
//     priceType: "cents",
//     props: [null, 0, 18000, 15.5, 0.1, 3, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1213_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1214",
//     catId: "DSA",
//     ctlBoard: "XM-B9D8-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd281c3-datasheet.pdf",
//     description: "F=DC-22G MaxAtten=6dB Step=0.1dB IL=1.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1214A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8d1-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd281c3-digital-attenuator/",
//     mfgPart: "CMD281C3",
//     models: "this part is missing models",
//     pn: "XM-B8D1-0404D",
//     price: 14100,
//     priceType: "cents",
//     props: [null, 0, 22000, 6, 0.1, 1.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1214_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1214",
//     catId: "DSA",
//     ctlBoard: "XM-B9D9-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd282c3-datasheet.pdf",
//     description: "F=DC-22G MaxAtten=12dB Step=0.2dB IL=1.9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1214A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8d2-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd282c3-digital-attenuator/",
//     mfgPart: "CMD282C3",
//     models: "this part is missing models",
//     pn: "XM-B8D2-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [null, 0, 22000, 12, 0.2, 1.9, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1214_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0939",
//     catId: "DSA",
//     ctlBoard: "XM-C9G5-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5730.pdf",
//     description: "F=100M-40G MaxAtten=31.5dB Step=0.5dB IL=4.8dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0939B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9u9-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/adrf5730.html",
//     mfgPart: "ADRF5730BCCZN",
//     models: "this part is missing models",
//     pn: "XM-B9U9-0804D",
//     price: 31100,
//     priceType: "cents",
//     props: [null, 100, 40000, 31.5, 0.5, 4.8, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E9",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0939_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1171",
//     catId: "DSA",
//     ctlBoard: "XM-C379-0804D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5731.pdf",
//     description: "F=100M-40G MaxAtten=30dB Step=2dB IL=3.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1171A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c377-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5731.html",
//     mfgPart: "ADRF5731",
//     models: "this part is missing models",
//     pn: "XM-C377-0804D",
//     price: 34000,
//     priceType: "cents",
//     props: [null, 100, 40000, 30, 2, 3.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E9",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1171_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1171",
//     catId: "DSA",
//     ctlBoard: "XM-C379-0804D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADRF5721.pdf",
//     description: "F=9K-40G MaxAtten=30dB Step=2dB IL=3.4dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1171A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c378-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/adrf5721.html?doc=ADRF5721.pdf",
//     mfgPart: "ADRF5721",
//     models: "this part is missing models",
//     pn: "XM-C378-0804D",
//     price: 35000,
//     priceType: "cents",
//     props: [null, 0.009, 40000, 30, 2, 3.4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E9",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1171_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1602",
//     catId: "DSA",
//     ctlBoard: "XM-C562-0804D",
//     datasheetURL: "https://www.qorvo.com/products/d/da001138",
//     description: "F=5M-4G MaxAtten=31.75dB Step=0.25dB IL=1.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1602A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3t2-0804d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/RFSA3715",
//     mfgPart: "RFSA3715",
//     models: "this part is missing models",
//     pn: "XM-C3T2-0804D",
//     price: 16100,
//     priceType: "cents",
//     props: [null, 5, 4000, 31.75, 0.25, 1.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E9",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1602_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1465",
//     catId: "DSA",
//     ctlBoard: "XM-C3V1-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC424ACHIPS.pdf",
//     description: "F=DC-13G MaxAtten=31.5dB Step=0.5dB IL=4.2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1465A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3u9-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/hmc424a-die.html",
//     mfgPart: "HMC424A-DIE",
//     models: "this part is missing models",
//     pn: "XM-C3U9-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 0, 13000, 31.5, 0.5, 4.2, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E6",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1465_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1826",
//     catId: "DSA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4r4-0604d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/keysight/keysight-digital-step-attenuators/xm-c4r4-0604d",
//     mfgPart: "TC735DIE",
//     models: "this is the new part",
//     pn: "XM-C4R4-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1826_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1828",
//     catId: "DSA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4r5-0404d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/keysight/keysight-digital-step-attenuators/xm-c4r5-0404d",
//     mfgPart: "TC736DIE",
//     models: "this is the new part",
//     pn: "XM-C4R5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1828_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1359",
//     catId: "DSA",
//     ctlBoard: "XM-C778-0609D",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c774-0606d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/keysight/keysight-digital-step-attenuators/xm-c774-0606d",
//     mfgPart: "TC1107P/1GN2-4207",
//     models: "this is the new part",
//     pn: "XM-C774-0606D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1359_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1376",
//     catId: "DSA",
//     ctlBoard: "XM-C778-0609D",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c775-0606d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/keysight/keysight-digital-step-attenuators/xm-c775-0606d",
//     mfgPart: "TC1108P/1GN2-4208",
//     models: "this is the new part",
//     pn: "XM-C775-0606D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1376_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1377",
//     catId: "DSA",
//     ctlBoard: "XM-C779-0609D",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c776-0606d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/keysight/keysight-digital-step-attenuators/xm-c776-0606d",
//     mfgPart: "TC1112P/1GN2-4213",
//     models: "this is the new part",
//     pn: "XM-C776-0606D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1377_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1378",
//     catId: "DSA",
//     ctlBoard: "XM-C781-0609D",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c777-0606d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/keysight/keysight-digital-step-attenuators/xm-c777-0606d",
//     mfgPart: "TC1113P/1GN2-4113",
//     models: "this is the new part",
//     pn: "XM-C777-0606D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "1378_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0939",
//     catId: "DSA",
//     ctlBoard: "XM-C9G5-0804D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adrf5720.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9g4-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/adrf5720.html",
//     mfgPart: "ADRF5720",
//     models: "this is the new part",
//     pn: "XM-C9G4-0804D",
//     price: 31900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0939_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "DSA",
//     ctlBoard: "XM-D3D4-0804D-SP",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5473.html",
//     mfgPart: "ADRF5473",
//     models: "this is the new part",
//     pn: "XM-D326-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "2089_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "DSA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adrf5474.html",
//     mfgPart: "ADRF5474",
//     models: "this is the new part",
//     pn: "XM-D4J4-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "2085_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "DSA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/digital-step-attenuators/adrf5730.html",
//     mfgPart: "ADRF5730BCCZN",
//     models: "this is the new part",
//     pn: "XM-D3A1-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Digital Step Attenuators",
//     newBRDImage: "0939_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0865",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1LH+.pdf",
//     description: "LOlvl=10dBm RF=0.5M-500M LO=0.5M-500M IF=DC-500M CL=5dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0865B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a155-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1LH%2B",
//     mfgPart: "ADE-1LH+",
//     models: "this part is missing models",
//     pn: "XM-A155-0604D",
//     price: 14900,
//     priceType: "cents",
//     props: [10, 0.5, 500, 0.5, 500, 0, 500, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0866",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1LH+.pdf",
//     description: "LOlvl=10dBm RF=0.5M-500M LO=0.5M-500M IF=DC-500M CL=5dB ",
//     eccn: "",
//     ffNum: 10,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0866A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a156-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1LH%2B",
//     mfgPart: "ADE-1LH+",
//     models: "this part is missing models",
//     pn: "XM-A156-0604D",
//     price: 14900,
//     priceType: "cents",
//     props: [10, 0.5, 500, 0.5, 500, 0, 500, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-14LH+.pdf",
//     description: "LOlvl=10dBm RF=3.7G-10G LO=3.7G-10G IF=DC-4G CL=7.3dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1m7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-14LH%2B",
//     mfgPart: "SIM-14LH+",
//     models: "this part is missing models",
//     pn: "XM-A1M7-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [10, 3700, 10000, 3700, 10000, 0, 4000, 7.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43LH+.pdf",
//     description: "LOlvl=10dBm RF=824M-4.2G LO=824M-4.2G IF=DC-1.5G CL=6.3dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1m8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43LH%2B",
//     mfgPart: "SIM-43LH+",
//     models: "this part is missing models",
//     pn: "XM-A1M8-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [10, 824, 4200, 824, 4200, 0, 1500, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-63LH+.pdf",
//     description: "LOlvl=10dBm RF=750M-6G LO=750M-6G IF=DC-1.5G CL=6.6dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1m9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-63LH%2B",
//     mfgPart: "SIM-63LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1M9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1M9-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [10, 750, 6000, 750, 6000, 0, 1500, 6.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-83LH+.pdf",
//     description: "LOlvl=10dBm RF=1.7G-8G LO=1.7G-8G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1n1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-83LH%2B",
//     mfgPart: "SIM-83LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1N1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1N1-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [10, 1700, 8000, 1700, 8000, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-153LH+.pdf",
//     description: "LOlvl=10dBm RF=3.2G-15G LO=3.2G-15G IF=DC-4G CL=7.5dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1n2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-153LH%2B",
//     mfgPart: "SIM-153LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1N2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1N2-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [10, 3200, 15000, 3200, 15000, 0, 4000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-24MH+.pdf",
//     description: "LOlvl=13dBm RF=7.3G-20G LO=7.3G-20G IF=DC-7.5G CL=5.7dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1n3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-24MH%2B",
//     mfgPart: "SIM-24MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1N3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1N3-0404D",
//     price: 13300,
//     priceType: "cents",
//     props: [13, 7300, 20000, 7300, 20000, 0, 7500, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43MH+.pdf",
//     description: "LOlvl=13dBm RF=824M-4.2G LO=824M-4.2G IF=DC-1.5G CL=6.3dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1n4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43MH%2B",
//     mfgPart: "SIM-43MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1N4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1N4-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [13, 824, 4200, 824, 4200, 0, 1500, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-153MH+.pdf",
//     description: "LOlvl=13dBm RF=3.2G-15G LO=3.2G-15G IF=DC-4G CL=7.5dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1n5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-153MH%2B",
//     mfgPart: "SIM-153MH+",
//     models: "this part is missing models",
//     pn: "XM-A1N5-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [13, 3200, 15000, 3200, 15000, 0, 4000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-722MH+.pdf",
//     description: "LOlvl=13dBm RF=2.3G-7.2G LO=2.3G-7.2G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1n6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-722MH%2B",
//     mfgPart: "SIM-722MH+",
//     models: "this part is missing models",
//     pn: "XM-A1N6-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [13, 2300, 7200, 2300, 7200, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-83LH+.pdf",
//     description: "LOlvl=13dBm RF=3.7G-8.5G LO=3.7G-8.5G IF=DC-4G CL=6.9dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1n7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-852MH%2B",
//     mfgPart: "SIM-852MH+",
//     models: "this part is missing models",
//     pn: "XM-A1N7-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [13, 3700, 8500, 3700, 8500, 0, 4000, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-14H+.pdf",
//     description: "LOlvl=17dBm RF=3.7G-10G LO=3.7G-10G IF=DC-4G CL=7dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1n8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-14H%2B",
//     mfgPart: "SIM-14H+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1N8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1N8-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [17, 3700, 10000, 3700, 10000, 0, 4000, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43H+.pdf",
//     description: "LOlvl=17dBm RF=1G-4G LO=1G-4G IF=DC-1.5G CL=6.5dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1n9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43H%2B",
//     mfgPart: "SIM-43H+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1N9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1N9-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [17, 1000, 4000, 1000, 4000, 0, 1500, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-193H+.pdf",
//     description: "LOlvl=17dBm RF=7.3G-19G LO=7.3G-19G IF=DC-7.5G CL=8dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1p1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-193H%2B",
//     mfgPart: "SIM-193H+",
//     models: "this part is missing models",
//     pn: "XM-A1P1-0404D",
//     price: 14000,
//     priceType: "cents",
//     props: [17, 7300, 19000, 7300, 19000, 0, 7500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0033",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-762H+.pdf",
//     description: "LOlvl=17dBm RF=2.3G-7.6G LO=2.3G-7.6G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0033J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1p2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-762H%2B",
//     mfgPart: "SIM-762H+",
//     models: "this part is missing models",
//     pn: "XM-A1P2-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [17, 2300, 7600, 2300, 7600, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0033_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-14LH+.pdf",
//     description: "LOlvl=10dBm RF=3.7G-10G LO=3.7G-10G IF=DC-4G CL=7.3dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1p3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-14LH%2B",
//     mfgPart: "SIM-14LH+",
//     models: "this part is missing models",
//     pn: "XM-A1P3-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [10, 3700, 10000, 3700, 10000, 0, 4000, 7.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43LH+.pdf",
//     description: "LOlvl=10dBm RF=824M-4.2G LO=824M-4.2G IF=DC-1.5G CL=6.3dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1p4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43LH%2B",
//     mfgPart: "SIM-43LH+",
//     models: "this part is missing models",
//     pn: "XM-A1P4-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [10, 824, 4200, 824, 4200, 0, 1500, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-63LH+.pdf",
//     description: "LOlvl=10dBm RF=750M-6G LO=750M-6G IF=DC-1.5G CL=6.6dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1p5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-63LH%2B",
//     mfgPart: "SIM-63LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1P5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1P5-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [10, 750, 6000, 750, 6000, 0, 1500, 6.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-83LH+.pdf",
//     description: "LOlvl=10dBm RF=1.7G-8G LO=1.7G-8G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1p6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-83LH%2B",
//     mfgPart: "SIM-83LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1P6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1P6-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [10, 1700, 8000, 1700, 8000, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-153LH+.pdf",
//     description: "LOlvl=10dBm RF=3.2G-15G LO=3.2G-15G IF=DC-4G CL=7.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1p7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-153LH%2B",
//     mfgPart: "SIM-153LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1P7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1P7-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [10, 3200, 15000, 3200, 15000, 0, 4000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-24MH+.pdf",
//     description: "LOlvl=13dBm RF=7.3G-20G LO=7.3G-20G IF=DC-7.5G CL=5.7dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1p8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-24MH%2B",
//     mfgPart: "SIM-24MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1P8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1P8-0404D",
//     price: 13300,
//     priceType: "cents",
//     props: [13, 7300, 20000, 7300, 20000, 0, 7500, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43MH+.pdf",
//     description: "LOlvl=13dBm RF=824M-4.2G LO=824M-4.2G IF=DC-1.5G CL=6.3dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1p9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43MH%2B",
//     mfgPart: "SIM-43MH+",
//     models: "this part is missing models",
//     pn: "XM-A1P9-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [13, 824, 4200, 824, 4200, 0, 1500, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-153MH+.pdf",
//     description: "LOlvl=13dBm RF=3.2G-15G LO=3.2G-15G IF=DC-4G CL=7.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1r1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-153MH%2B",
//     mfgPart: "SIM-153MH+",
//     models: "this part is missing models",
//     pn: "XM-A1R1-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [13, 3200, 15000, 3200, 15000, 0, 4000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-722MH+.pdf",
//     description: "LOlvl=13dBm RF=2.3G-7.2G LO=2.3G-7.2G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1r2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-722MH%2B",
//     mfgPart: "SIM-722MH+",
//     models: "this part is missing models",
//     pn: "XM-A1R2-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [13, 2300, 7200, 2300, 7200, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-83LH+.pdf",
//     description: "LOlvl=13dBm RF=3.7G-8.5G LO=3.7G-8.5G IF=DC-4G CL=6.9dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1r3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-852MH%2B",
//     mfgPart: "SIM-852MH+",
//     models: "this part is missing models",
//     pn: "XM-A1R3-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [13, 3700, 8500, 3700, 8500, 0, 4000, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-14H+.pdf",
//     description: "LOlvl=17dBm RF=3.7G-10G LO=3.7G-10G IF=DC-4G CL=7dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1r4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-14H%2B",
//     mfgPart: "SIM-14H+",
//     models: "this part is missing models",
//     pn: "XM-A1R4-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [17, 3700, 10000, 3700, 10000, 0, 4000, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43H+.pdf",
//     description: "LOlvl=17dBm RF=1G-4G LO=1G-4G IF=DC-1.5G CL=6.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1r5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43H%2B",
//     mfgPart: "SIM-43H+",
//     models: "this part is missing models",
//     pn: "XM-A1R5-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [17, 1000, 4000, 1000, 4000, 0, 1500, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-193H+.pdf",
//     description: "LOlvl=17dBm RF=7.3G-19G LO=7.3G-19G IF=DC-7.5G CL=8dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1r6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-193H%2B",
//     mfgPart: "SIM-193H+",
//     models: "this part is missing models",
//     pn: "XM-A1R6-0404D",
//     price: 14000,
//     priceType: "cents",
//     props: [17, 7300, 19000, 7300, 19000, 0, 7500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0035",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-762H+.pdf",
//     description: "LOlvl=17dBm RF=2.3G-7.6G LO=2.3G-7.6G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0035I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1r7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-762H%2B",
//     mfgPart: "SIM-762H+",
//     models: "this part is missing models",
//     pn: "XM-A1R7-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [17, 2300, 7600, 2300, 7600, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-14LH+.pdf",
//     description: "LOlvl=10dBm RF=3.7G-10G LO=3.7G-10G IF=DC-4G CL=7.3dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1r8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-14LH%2B",
//     mfgPart: "SIM-14LH+",
//     models: "this part is missing models",
//     pn: "XM-A1R8-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [10, 3700, 10000, 3700, 10000, 0, 4000, 7.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43LH+.pdf",
//     description: "LOlvl=10dBm RF=824M-4.2G LO=824M-4.2G IF=DC-1.5G CL=6.3dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1r9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43LH%2B",
//     mfgPart: "SIM-43LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1R9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1R9-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [10, 824, 4200, 824, 4200, 0, 1500, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-63LH+.pdf",
//     description: "LOlvl=10dBm RF=750M-6G LO=750M-6G IF=DC-1.5G CL=6.6dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1t1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-63LH%2B",
//     mfgPart: "SIM-63LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1T1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1T1-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [10, 750, 6000, 750, 6000, 0, 1500, 6.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-83LH+.pdf",
//     description: "LOlvl=10dBm RF=1.7G-8G LO=1.7G-8G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1t2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-83LH%2B",
//     mfgPart: "SIM-83LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1T2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1T2-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [10, 1700, 8000, 1700, 8000, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-153LH+.pdf",
//     description: "LOlvl=10dBm RF=3.2G-15G LO=3.2G-15G IF=DC-4G CL=7.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1t3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-153LH%2B",
//     mfgPart: "SIM-153LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1T3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1T3-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [10, 3200, 15000, 3200, 15000, 0, 4000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-24MH+.pdf",
//     description: "LOlvl=13dBm RF=7.3G-20G LO=7.3G-20G IF=DC-7.5G CL=5.7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1t4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-24MH%2B",
//     mfgPart: "SIM-24MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1T4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1T4-0404D",
//     price: 13300,
//     priceType: "cents",
//     props: [13, 7300, 20000, 7300, 20000, 0, 7500, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43MH+.pdf",
//     description: "LOlvl=13dBm RF=824M-4.2G LO=824M-4.2G IF=DC-1.5G CL=6.3dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1t5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43MH%2B",
//     mfgPart: "SIM-43MH+",
//     models: "this part is missing models",
//     pn: "XM-A1T5-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [13, 824, 4200, 824, 4200, 0, 1500, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-153MH+.pdf",
//     description: "LOlvl=13dBm RF=3.2G-15G LO=3.2G-15G IF=DC-4G CL=7.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1t6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-153MH%2B",
//     mfgPart: "SIM-153MH+",
//     models: "this part is missing models",
//     pn: "XM-A1T6-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [13, 3200, 15000, 3200, 15000, 0, 4000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-722MH+.pdf",
//     description: "LOlvl=13dBm RF=2.3G-7.2G LO=2.3G-7.2G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1t7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-722MH%2B",
//     mfgPart: "SIM-722MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1T7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1T7-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [13, 2300, 7200, 2300, 7200, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-83LH+.pdf",
//     description: "LOlvl=13dBm RF=3.7G-8.5G LO=3.7G-8.5G IF=DC-4G CL=6.9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1t8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-852MH%2B",
//     mfgPart: "SIM-852MH+",
//     models: "this part is missing models",
//     pn: "XM-A1T8-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [13, 3700, 8500, 3700, 8500, 0, 4000, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-14H+.pdf",
//     description: "LOlvl=17dBm RF=3.7G-10G LO=3.7G-10G IF=DC-4G CL=7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1t9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-14H%2B",
//     mfgPart: "SIM-14H+",
//     models: "this part is missing models",
//     pn: "XM-A1T9-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [17, 3700, 10000, 3700, 10000, 0, 4000, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43H+.pdf",
//     description: "LOlvl=17dBm RF=1G-4G LO=1G-4G IF=DC-1.5G CL=6.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1u1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43H%2B",
//     mfgPart: "SIM-43H+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1U1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1U1-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [17, 1000, 4000, 1000, 4000, 0, 1500, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-193H+.pdf",
//     description: "LOlvl=17dBm RF=7.3G-19G LO=7.3G-19G IF=DC-7.5G CL=8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1u2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-193H%2B",
//     mfgPart: "SIM-193H+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1U2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1U2-0404D",
//     price: 14000,
//     priceType: "cents",
//     props: [17, 7300, 19000, 7300, 19000, 0, 7500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0037",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-762H+.pdf",
//     description: "LOlvl=17dBm RF=2.3G-7.6G LO=2.3G-7.6G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0037I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1u3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-762H%2B",
//     mfgPart: "SIM-762H+",
//     models: "this part is missing models",
//     pn: "XM-A1U3-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [17, 2300, 7600, 2300, 7600, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0037_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0032",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1LH+.pdf",
//     description: "LOlvl=10dBm RF=0.5M-500M LO=0.5M-500M IF=DC-500M CL=5dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0032H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1u4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1LH%2B",
//     mfgPart: "ADE-1LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1U4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1U4-0404D",
//     price: 11900,
//     priceType: "cents",
//     props: [10, 0.5, 500, 0.5, 500, 0, 500, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0032_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0032",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1MH+.pdf",
//     description: "LOlvl=13dBm RF=2M-500M LO=2M-500M IF=DC-500M CL=5.7dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0032H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1u5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1MH%2B",
//     mfgPart: "ADE-1MH+",
//     models: "this part is missing models",
//     pn: "XM-A1U5-0404D",
//     price: 12500,
//     priceType: "cents",
//     props: [13, 2, 500, 2, 500, 0, 500, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0032_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0032",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1MHW+.pdf",
//     description: "LOlvl=13dBm RF=0.5M-600M LO=0.5M-600M IF=DC-600M CL=5.2dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0032H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1u6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1MHW%2B",
//     mfgPart: "ADE-1MHW+",
//     models: "this part is missing models",
//     pn: "XM-A1U6-0404D",
//     price: 12500,
//     priceType: "cents",
//     props: [13, 0.5, 600, 0.5, 600, 0, 600, 5.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0032_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0032",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-12MH+.pdf",
//     description: "LOlvl=13dBm RF=10M-1.2G LO=10M-1.2G IF=DC-1.2G CL=6.3dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0032H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1u8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-12MH%2B",
//     mfgPart: "ADE-12MH+",
//     models: "this part is missing models",
//     pn: "XM-A1U8-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [13, 10, 1200, 10, 1200, 0, 1200, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0032_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0032",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-25MH+.pdf",
//     description: "LOlvl=13dBm RF=5M-2.5G LO=5M-2.5G IF=5M-1.5G CL=6.9dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0032H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1u9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-25MH%2B",
//     mfgPart: "ADE-25MH+",
//     models: "this part is missing models",
//     pn: "XM-A1U9-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [13, 5, 2500, 5, 2500, 5, 1500, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0032_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0032",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-35MH+.pdf",
//     description: "LOlvl=13dBm RF=5M-3.5G LO=5M-3.5G IF=5M-2.5G CL=6.9dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0032H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1v1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-35MH%2B",
//     mfgPart: "ADE-35MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1V1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1V1-0404D",
//     price: 13500,
//     priceType: "cents",
//     props: [13, 5, 3500, 5, 3500, 5, 2500, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0032_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0032",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-42MH+.pdf",
//     description: "LOlvl=13dBm RF=5M-4.2G LO=5M-4.2G IF=5M-3.5G CL=7.5dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0032H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1v2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-42MH%2B",
//     mfgPart: "ADE-42MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1V2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1V2-0404D",
//     price: 14500,
//     priceType: "cents",
//     props: [13, 5, 4200, 5, 4200, 5, 3500, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0032_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0032",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1H+.pdf",
//     description: "LOlvl=17dBm RF=0.5M-500M LO=0.5M-500M IF=DC-500M CL=5.3dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0032H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1v3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1H%2B",
//     mfgPart: "ADE-1H+",
//     models: "this part is missing models",
//     pn: "XM-A1V3-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [17, 0.5, 500, 0.5, 500, 0, 500, 5.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0032_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0032",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1HW+.pdf",
//     description: "LOlvl=17dBm RF=5M-750M LO=5M-750M IF=DC-750M CL=6dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0032H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1v4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1HW%2B",
//     mfgPart: "ADE-1HW+",
//     models: "this part is missing models",
//     pn: "XM-A1V4-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [17, 5, 750, 5, 750, 0, 750, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0032_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0032",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-17H+.pdf",
//     description: "LOlvl=17dBm RF=100M-1.7G LO=100M-1.7G IF=50M-1.5G CL=7.2dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0032H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1v5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-17H%2B",
//     mfgPart: "ADE-17H+",
//     models: "this part is missing models",
//     pn: "XM-A1V5-0404D",
//     price: 13300,
//     priceType: "cents",
//     props: [17, 100, 1700, 100, 1700, 50, 1500, 7.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0032_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0032",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADEX-10H+.pdf",
//     description: "LOlvl=17dBm RF=10M-1G LO=10M-1G IF=DC-800M CL=7dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0032H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1v6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADEX-10H%2B",
//     mfgPart: "ADEX-10H+",
//     models: "this part is missing models",
//     pn: "XM-A1V6-0404D",
//     price: 11900,
//     priceType: "cents",
//     props: [17, 10, 1000, 10, 1000, 0, 800, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0032_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0034",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1LH+.pdf",
//     description: "LOlvl=10dBm RF=0.5M-500M LO=0.5M-500M IF=DC-500M CL=5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0034H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1v7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1LH%2B",
//     mfgPart: "ADE-1LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1V7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1V7-0404D",
//     price: 11900,
//     priceType: "cents",
//     props: [10, 0.5, 500, 0.5, 500, 0, 500, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0034",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1MH+.pdf",
//     description: "LOlvl=13dBm RF=2M-500M LO=2M-500M IF=DC-500M CL=5.7dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0034H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1v8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1MH%2B",
//     mfgPart: "ADE-1MH+",
//     models: "this part is missing models",
//     pn: "XM-A1V8-0404D",
//     price: 12500,
//     priceType: "cents",
//     props: [13, 2, 500, 2, 500, 0, 500, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0034",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1MHW+.pdf",
//     description: "LOlvl=13dBm RF=0.5M-600M LO=0.5M-600M IF=DC-600M CL=5.2dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0034H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1v9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1MHW%2B",
//     mfgPart: "ADE-1MHW+",
//     models: "this part is missing models",
//     pn: "XM-A1V9-0404D",
//     price: 12500,
//     priceType: "cents",
//     props: [13, 0.5, 600, 0.5, 600, 0, 600, 5.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0034",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-12MH+.pdf",
//     description: "LOlvl=13dBm RF=10M-1.2G LO=10M-1.2G IF=DC-1.2G CL=6.3dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0034H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1w2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-12MH%2B",
//     mfgPart: "ADE-12MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1W2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1W2-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [13, 10, 1200, 10, 1200, 0, 1200, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0034",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-25MH+.pdf",
//     description: "LOlvl=13dBm RF=5M-2.5G LO=5M-2.5G IF=5M-1.5G CL=6.9dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0034H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1w3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-25MH%2B",
//     mfgPart: "ADE-25MH+",
//     models: "this part is missing models",
//     pn: "XM-A1W3-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [13, 5, 2500, 5, 2500, 5, 1500, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0034",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-35MH+.pdf",
//     description: "LOlvl=13dBm RF=5M-3.5G LO=5M-3.5G IF=5M-2.5G CL=6.9dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0034H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1w4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-35MH%2B",
//     mfgPart: "ADE-35MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1W4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1W4-0404D",
//     price: 13500,
//     priceType: "cents",
//     props: [13, 5, 3500, 5, 3500, 5, 2500, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0034",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-42MH+.pdf",
//     description: "LOlvl=13dBm RF=5M-4.2G LO=5M-4.2G IF=5M-3.5G CL=7.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0034H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1w5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-42MH%2B",
//     mfgPart: "ADE-42MH+",
//     models: "this part is missing models",
//     pn: "XM-A1W5-0404D",
//     price: 14500,
//     priceType: "cents",
//     props: [13, 5, 4200, 5, 4200, 5, 3500, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0034",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1H+.pdf",
//     description: "LOlvl=17dBm RF=0.5M-500M LO=0.5M-500M IF=DC-500M CL=5.3dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0034H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1w6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1H%2B",
//     mfgPart: "ADE-1H+",
//     models: "this part is missing models",
//     pn: "XM-A1W6-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [17, 0.5, 500, 0.5, 500, 0, 500, 5.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0034",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1HW+.pdf",
//     description: "LOlvl=17dBm RF=5M-750M LO=5M-750M IF=DC-750M CL=6dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0034H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1w7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1HW%2B",
//     mfgPart: "ADE-1HW+",
//     models: "this part is missing models",
//     pn: "XM-A1W7-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [17, 5, 750, 5, 750, 0, 750, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0034",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-17H+.pdf",
//     description: "LOlvl=17dBm RF=100M-1.7G LO=100M-1.7G IF=50M-1.5G CL=7.2dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0034H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1w8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-17H%2B",
//     mfgPart: "ADE-17H+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1W8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1W8-0404D",
//     price: 13300,
//     priceType: "cents",
//     props: [17, 100, 1700, 100, 1700, 50, 1500, 7.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0034",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADEX-10H+.pdf",
//     description: "LOlvl=17dBm RF=10M-1G LO=10M-1G IF=DC-800M CL=7dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0034H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1w9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADEX-10H%2B",
//     mfgPart: "ADEX-10H+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1W9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1W9-0404D",
//     price: 11900,
//     priceType: "cents",
//     props: [17, 10, 1000, 10, 1000, 0, 800, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0034_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0036",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1LH+.pdf",
//     description: "LOlvl=10dBm RF=0.5M-500M LO=0.5M-500M IF=DC-500M CL=5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0036H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1y1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1LH%2B",
//     mfgPart: "ADE-1LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1Y1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1Y1-0404D",
//     price: 11900,
//     priceType: "cents",
//     props: [10, 0.5, 500, 0.5, 500, 0, 500, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0036",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1MH+.pdf",
//     description: "LOlvl=13dBm RF=2M-500M LO=2M-500M IF=DC-500M CL=5.7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0036H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1y2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1MH%2B",
//     mfgPart: "ADE-1MH+",
//     models: "this part is missing models",
//     pn: "XM-A1Y2-0404D",
//     price: 12500,
//     priceType: "cents",
//     props: [13, 2, 500, 2, 500, 0, 500, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0036",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1MHW+.pdf",
//     description: "LOlvl=13dBm RF=0.5M-600M LO=0.5M-600M IF=DC-600M CL=5.2dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0036H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1y3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1MHW%2B",
//     mfgPart: "ADE-1MHW+",
//     models: "this part is missing models",
//     pn: "XM-A1Y3-0404D",
//     price: 12500,
//     priceType: "cents",
//     props: [13, 0.5, 600, 0.5, 600, 0, 600, 5.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0036",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-12MH+.pdf",
//     description: "LOlvl=13dBm RF=10M-1.2G LO=10M-1.2G IF=DC-1.2G CL=6.3dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0036H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1y5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-12MH%2B",
//     mfgPart: "ADE-12MH+",
//     models: "this part is missing models",
//     pn: "XM-A1Y5-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [13, 10, 1200, 10, 1200, 0, 1200, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0036",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-25MH+.pdf",
//     description: "LOlvl=13dBm RF=5M-2.5G LO=5M-2.5G IF=5M-1.5G CL=6.9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0036H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1y6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-25MH%2B",
//     mfgPart: "ADE-25MH+",
//     models: "this part is missing models",
//     pn: "XM-A1Y6-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [13, 5, 2500, 5, 2500, 5, 1500, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0036",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-35MH+.pdf",
//     description: "LOlvl=13dBm RF=5M-3.5G LO=5M-3.5G IF=5M-2.5G CL=6.9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0036H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1y7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-35MH%2B",
//     mfgPart: "ADE-35MH+",
//     models: "this part is missing models",
//     pn: "XM-A1Y7-0404D",
//     price: 13500,
//     priceType: "cents",
//     props: [13, 5, 3500, 5, 3500, 5, 2500, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0036",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-42MH+.pdf",
//     description: "LOlvl=13dBm RF=5M-4.2G LO=5M-4.2G IF=5M-3.5G CL=7.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0036H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1y8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-42MH%2B",
//     mfgPart: "ADE-42MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1Y8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1Y8-0404D",
//     price: 14500,
//     priceType: "cents",
//     props: [13, 5, 4200, 5, 4200, 5, 3500, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0036",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1H+.pdf",
//     description: "LOlvl=17dBm RF=0.5M-500M LO=0.5M-500M IF=DC-500M CL=5.3dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0036H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1y9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1H%2B",
//     mfgPart: "ADE-1H+",
//     models: "this part is missing models",
//     pn: "XM-A1Y9-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [17, 0.5, 500, 0.5, 500, 0, 500, 5.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0036",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1HW+.pdf",
//     description: "LOlvl=17dBm RF=5M-750M LO=5M-750M IF=DC-750M CL=6dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0036H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a211-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1HW%2B",
//     mfgPart: "ADE-1HW+",
//     models: "this part is missing models",
//     pn: "XM-A211-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [17, 5, 750, 5, 750, 0, 750, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0036",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-17H+.pdf",
//     description: "LOlvl=17dBm RF=100M-1.7G LO=100M-1.7G IF=50M-1.5G CL=7.2dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0036H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a212-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-17H%2B",
//     mfgPart: "ADE-17H+",
//     models: "this part is missing models",
//     pn: "XM-A212-0404D",
//     price: 13300,
//     priceType: "cents",
//     props: [17, 100, 1700, 100, 1700, 50, 1500, 7.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0036",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADEX-10H+.pdf",
//     description: "LOlvl=17dBm RF=10M-1G LO=10M-1G IF=DC-800M CL=7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0036H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a213-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADEX-10H%2B",
//     mfgPart: "ADEX-10H+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A213D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A213-0404D",
//     price: 11900,
//     priceType: "cents",
//     props: [17, 10, 1000, 10, 1000, 0, 800, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0565",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-2567L.pdf",
//     description: "LOlvl=11dBm RF=25G-67G LO=25G-67G IF=DC-30G CL=0dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0565A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3m4-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-2567l.aspx",
//     mfgPart: "MM1-2567LCH-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3M4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3M4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [11, 25000, 67000, 25000, 67000, 0, 30000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0565_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0190",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MM1-0626HSM.pdf",
//     description: "LOlvl=20dBm RF=6G-26.5G LO=6G-26.5G IF=DC-9G CL=7.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0190H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3u3-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0626hsm.aspx",
//     mfgPart: "MM1-0626HSM-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3U3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3U3-0404D",
//     price: 24100,
//     priceType: "cents",
//     props: [20, 6000, 26500, 6000, 26500, 0, 9000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0190_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0411",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMXES0117.pdf",
//     description: "LOlvl=17dBm RF=80M-2.5G LO=80M-2.5G IF=DC-1G CL=6.5dB ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0411B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3v2-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMXES0117",
//     mfgPart: "MAMXES0117",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3V2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3V2-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [17, 80, 2500, 80, 2500, 0, 1000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1048a.pdf",
//     description: "LOlvl=13dBm RF=2.25G-12G LO=2.25G-12G IF=DC-4G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3w2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc1048a.html",
//     mfgPart: "HMC1048ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3W2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3W2-0404D",
//     price: 23600,
//     priceType: "cents",
//     props: [13, 2250, 12000, 2250, 12000, 0, 4000, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0192",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/ml1-0110sm.pdf",
//     description: "LOlvl=18dBm RF=1.5G-10G LO=1.5G-10G IF=DC-2G CL=7dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0192J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a419-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/ml1-0110sm.aspx",
//     mfgPart: "ML1-0110HSM-2",
//     models: "this part is missing models",
//     pn: "XM-A419-0404D",
//     price: 23300,
//     priceType: "cents",
//     props: [18, 1500, 10000, 1500, 10000, 0, 2000, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0193",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/T3H-18G.pdf",
//     description: "LOlvl=20dBm RF=10M-18G LO=10M-18G IF=10M-18G CL=8.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0193I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a425-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3h-18g.aspx",
//     mfgPart: "T3H-18GLCTG-2",
//     models: "this part is missing models",
//     pn: "XM-A425-0404D",
//     price: 96800,
//     priceType: "cents",
//     props: [20, 10, 18000, 10, 18000, 10, 18000, 8.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0193",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/T3H-18G.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a426-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3h-18g.aspx",
//     mfgPart: "T3H-18GLCTG-1",
//     models: "this is the new part",
//     pn: "XM-A426-0404D",
//     price: 96800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0193",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/T3H-20G.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a427-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3h-20g.aspx",
//     mfgPart: "T3H-20GLCTG-1",
//     models: "this is the new part",
//     pn: "XM-A427-0404D",
//     price: 99200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0193",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/T3H-20G.pdf",
//     description: "LOlvl=20dBm RF=10M-20G LO=10M-20G IF=10M-18G CL=9.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0193I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a428-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3h-20g.aspx",
//     mfgPart: "T3H-20GLCTG-2",
//     models: "this part is missing models",
//     pn: "XM-A428-0404D",
//     price: 99400,
//     priceType: "cents",
//     props: [20, 10, 20000, 10, 20000, 10, 18000, 9.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0406",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMX-011009.pdf",
//     description: "LOlvl=15dBm RF=14G-32G LO=4G-20G IF=DC-7G CL=11dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0406D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4a4-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMX-011009",
//     mfgPart: "MAMX-011009",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4A4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4A4-0404D",
//     price: 14600,
//     priceType: "cents",
//     props: [15, 4000, 20000, 14000, 32000, 0, 7000, 11],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0405",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.macom.com/products/product-detail/MAMX-011021",
//     description: "LOlvl=14dBm RF=5G-35G LO=3G-33G IF=DC-4.5G CL=8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0405C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4a5-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMX-011021",
//     mfgPart: "MAMX-011021",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4A5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4A5-0404D",
//     price: 24200,
//     priceType: "cents",
//     props: [14, 3000, 33000, 5000, 35000, 0, 4500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0401",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MA4EX240L1-1225T.pdf",
//     description: "LOlvl=5dBm RF=1.7G-2.5G LO=1.7G-2.5G IF=DC-400M CL=7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0401B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4a8-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MA4EX240L1-1225T",
//     mfgPart: "MA4EX240L1-1225T",
//     models: "this part is missing models",
//     pn: "XM-A4A8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 1700, 2500, 1700, 2500, 0, 400, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0398",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MA4EX580L1-1225T.pdf",
//     description: "LOlvl=5dBm RF=4.7G-6G LO=4.7G-6G IF=DC-1.05G CL=8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0398B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4a9-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MA4EX580L1-1225T",
//     mfgPart: "MA4EX580L1-1225T",
//     models: "this part is missing models",
//     pn: "XM-A4A9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 4700, 6000, 4700, 6000, 0, 1050, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0398",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MA4EX600L1-1225.pdf",
//     description: "LOlvl=5dBm RF=4.2G-6G LO=4.2G-6G IF=4.2G-6G CL=6.8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0398B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4b1-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MA4EX600L1-1225T",
//     mfgPart: "MA4EX600L1-1225T",
//     models: "this part is missing models",
//     pn: "XM-A4B1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 4200, 6000, 4200, 6000, 4200, 6000, 6.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0398",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MA4EX950H1-1225T.pdf",
//     description: "LOlvl=15dBm RF=700M-1.2G LO=700M-1.2G IF=DC-400M CL=7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0398B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4b2-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MA4EX950H1-1225T",
//     mfgPart: "MA4EX950H1-1225T",
//     models: "this part is missing models",
//     pn: "XM-A4B2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [15, 700, 1200, 700, 1200, 0, 400, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0398",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MA4EX950L1-1225T.pdf",
//     description: "LOlvl=5dBm RF=700M-1.2G LO=700M-1.2G IF=DC-400M CL=7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0398B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4b3-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MA4EX950L1-1225T",
//     mfgPart: "MA4EX950L1-1225T",
//     models: "this part is missing models",
//     pn: "XM-A4B3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 700, 1200, 700, 1200, 0, 400, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0402",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMXSS0012.pdf",
//     description: "LOlvl=5dBm RF=800M-1G LO=800M-1G IF=DC-200M CL=8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0402C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4b5-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMXSS0012",
//     mfgPart: "MAMXSS0012",
//     models: "this part is missing models",
//     pn: "XM-A4B5-0404D",
//     price: 11600,
//     priceType: "cents",
//     props: [5, 800, 1000, 800, 1000, 0, 200, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0402",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMXSS0013.pdf",
//     description: "LOlvl=5dBm RF=1.4G-2.1G LO=1.4G-2.1G IF=DC-500M CL=8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0402C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4b6-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMXSS0013",
//     mfgPart: "MAMXSS0013",
//     models: "this part is missing models",
//     pn: "XM-A4B6-0404D",
//     price: 11700,
//     priceType: "cents",
//     props: [5, 1400, 2100, 1400, 2100, 0, 500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0394",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/SM4TH.pdf",
//     description: "LOlvl=23dBm RF=1M-3.4G LO=1M-3.4G IF=1M-2G CL=8dB ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0394B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4b7-0904d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SM4TH",
//     mfgPart: "SM4TH",
//     models: "this part is missing models",
//     pn: "XM-A4B7-0904D",
//     price: 49600,
//     priceType: "cents",
//     props: [23, 1, 3400, 1, 3400, 1, 2000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0394",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/SM5TH.pdf",
//     description: "LOlvl=23dBm RF=50M-5G LO=50M-5G IF=50M-3G CL=8dB ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0394B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4b8-0904d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SM5TH",
//     mfgPart: "SM5TH",
//     models: "this part is missing models",
//     pn: "XM-A4B8-0904D",
//     price: 68100,
//     priceType: "cents",
//     props: [23, 50, 5000, 50, 5000, 50, 3000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0394",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/M6EH_SM6EH.pdf",
//     description: "LOlvl=20dBm RF=5M-750M LO=5M-750M IF=1M-500M CL=6dB ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0394B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4b9-0904d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SM6EH",
//     mfgPart: "SM6EH",
//     models: "this part is missing models",
//     pn: "XM-A4B9-0904D",
//     price: 49600,
//     priceType: "cents",
//     props: [20, 5, 750, 5, 750, 1, 500, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0394",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/M2E_M2EC_SM2E.pdf",
//     description: "LOlvl=20dBm RF=10M-1G LO=10M-1G IF=DC-600M CL=8dB ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0394B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4c1-0904d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SM2E",
//     mfgPart: "SM2E",
//     models: "this part is missing models",
//     pn: "XM-A4C1-0904D",
//     price: 129700,
//     priceType: "cents",
//     props: [20, 10, 1000, 10, 1000, 0, 600, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc292alc3b.pdf",
//     description: "LOlvl=13dBm RF=14G-30G LO=14G-30G IF=DC-8G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4j1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc292alc3b.html",
//     mfgPart: "HMC292ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4J1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4J1-0404D",
//     price: 20900,
//     priceType: "cents",
//     props: [13, 14000, 30000, 14000, 30000, 0, 8000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0193",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/T3H-20G.pdf",
//     description: "LOlvl=20dBm RF=10M-20G LO=10M-20G IF=10M-18G CL=9.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0193I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a553-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3h-20g.aspx",
//     mfgPart: "T3H-20GICTG-2",
//     models: "this part is missing models",
//     pn: "XM-A553-0404D",
//     price: 99200,
//     priceType: "cents",
//     props: [20, 10, 20000, 10, 20000, 10, 18000, 9.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0193",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/T3H-18G.pdf",
//     description: "LOlvl=20dBm RF=10M-18G LO=10M-18G IF=10M-18G CL=9.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0193I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a558-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3h-18g.aspx",
//     mfgPart: "T3H-18GICTG-2",
//     models: "this part is missing models",
//     pn: "XM-A558-0404D",
//     price: 96800,
//     priceType: "cents",
//     props: [20, 10, 18000, 10, 18000, 10, 18000, 9.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0193",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/T3H-18G.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a559-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3h-18g.aspx",
//     mfgPart: "T3H-18GICTG-1",
//     models: "this is the new part",
//     pn: "XM-A559-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0190",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd177c3_ver_1.7_1017.pdf",
//     description: "LOlvl=13dBm RF=6G-14G LO=6G-14G IF=DC-5G CL=6.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0190H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5b5-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd177c3_mixer/",
//     mfgPart: "CMD177C3",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5B5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5B5-0404D",
//     price: 21700,
//     priceType: "cents",
//     props: [13, 6000, 14000, 6000, 14000, 0, 5000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0190_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0190",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd178c3_ver_1.5_1017.pdf",
//     description: "LOlvl=13dBm RF=11G-21G LO=11G-21G IF=DC-6G CL=6dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0190H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5b6-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd178c3_mixer/",
//     mfgPart: "CMD178C3",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5B6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5B6-0404D",
//     price: 20800,
//     priceType: "cents",
//     props: [13, 11000, 21000, 11000, 21000, 0, 6000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0190_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0190",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd179c3_ver_1.6_1017.pdf",
//     description: "LOlvl=13dBm RF=16G-26G LO=16G-26G IF=DC-9G CL=6.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0190H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5b7-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd179c3_mixer/",
//     mfgPart: "CMD179C3",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5B7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5B7-0404D",
//     price: 21800,
//     priceType: "cents",
//     props: [13, 16000, 26000, 16000, 26000, 0, 9000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0190_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0190",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd180c3_ver_1.6_1017.pdf",
//     description: "LOlvl=13dBm RF=20G-32G LO=20G-32G IF=DC-10G CL=7dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0190H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5b8-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd180c3_mixer/",
//     mfgPart: "CMD180C3",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5B8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5B8-0404D",
//     price: 21900,
//     priceType: "cents",
//     props: [13, 20000, 32000, 20000, 32000, 0, 10000, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0190_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1166",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd182c4_ver_1.7_1017.pdf",
//     description: "LOlvl=15dBm RF=7.5G-13G LO=7.5G-13G IF=DC-4.5G CL=-5.5dB ",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1166A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5b9-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd182c4_mixer/",
//     mfgPart: "CMD182C4",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5B9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5B9-0409D",
//     price: 26900,
//     priceType: "cents",
//     props: [15, 7500, 13000, 7500, 13000, 0, 4500, -5.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1166_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1166",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd183c4_ver_1.6_1017.pdf",
//     description: "LOlvl=15dBm RF=7.5G-13G LO=7.5G-13G IF=DC-4.5G CL=-5.5dB ",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1166A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5c1-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd183c4_mixer/",
//     mfgPart: "CMD183C4",
//     models: "this part is missing models",
//     pn: "XM-A5C1-0409D",
//     price: 27800,
//     priceType: "cents",
//     props: [15, 7500, 13000, 7500, 13000, 0, 4500, -5.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1166_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc558A.pdf",
//     description: "LOlvl=15dBm RF=5.5G-14G LO=5.5G-14G IF=DC-6G CL=7.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5g8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc558a.html",
//     mfgPart: "HMC558ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5G8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5G8-0404D",
//     price: 19400,
//     priceType: "cents",
//     props: [15, 5500, 14000, 5500, 14000, 0, 6000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc787a.pdf",
//     description: "LOlvl=17dBm RF=3G-10G LO=3G-10G IF=DC-4G CL=9dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5g9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc787a.html",
//     mfgPart: "HMC787ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5G9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5G9-0404D",
//     price: 21000,
//     priceType: "cents",
//     props: [17, 3000, 10000, 3000, 10000, 0, 4000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "#N/A",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "GURLA",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GRF7001",
//     models: "this is the new part",
//     pn: "XM-A5H1-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "2020_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc787a.pdf",
//     description: "LOlvl=17dBm RF=3G-10G LO=3G-10G IF=DC-4G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5l8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc787a.html",
//     mfgPart: "HMC787ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5L8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5L8-0404D",
//     price: 21000,
//     priceType: "cents",
//     props: [17, 3000, 10000, 3000, 10000, 0, 4000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc558A.pdf",
//     description: "LOlvl=15dBm RF=5.5G-14G LO=5.5G-14G IF=DC-6G CL=7.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5l9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc558a.html",
//     mfgPart: "HMC558ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5L9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5L9-0404D",
//     price: 19400,
//     priceType: "cents",
//     props: [15, 5500, 14000, 5500, 14000, 0, 6000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=5G-5.2G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5u3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5U3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5U3-0404D",
//     price: 29700,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 5000, 5200, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0559",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1081.pdf",
//     description: "LOlvl=12dBm RF=50G-75G LO=40G-85G IF=DC-26G CL=7.5dB ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0559A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5v1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc1081.html",
//     mfgPart: "HMC1081",
//     models: "this part is missing models",
//     pn: "XM-A5V1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [12, 40000, 85000, 50000, 75000, 0, 26000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0559_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0640",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1093.pdf",
//     description: "LOlvl=-1dBm RF=37G-46.5G LO=8.5G-11G IF=DC-7.5G CL=11dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0640B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5v2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/sub-harmonic-mixers/hmc1093.html",
//     mfgPart: "HMC1093",
//     models: "this part is missing models",
//     pn: "XM-A5V2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [-1, 8500, 11000, 37000, 46500, 0, 7500, 11],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0640_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1546",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc1106.pdf",
//     description: "LOlvl=15dBm RF=15G-36G LO=20G-50G IF=DC-24G CL=11dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1546A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5v3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc1106.html",
//     mfgPart: "HMC1106",
//     models: "this part is missing models",
//     pn: "XM-A5V3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [15, 20000, 50000, 15000, 36000, 0, 24000, 11],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1546_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1161",
//     catId: "MXR",
//     ctlBoard: "XM-B5G7-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc264lc3b.pdf",
//     description: "LOlvl=-4.0dBm RF=21G-31G LO=10.5G-15.5G IF=DC-6G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1161A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a658-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/sub-harmonic-mixers/hmc264lc3b.html",
//     mfgPart: "HMC264LC3B",
//     models: "this part is missing models",
//     pn: "XM-A658-0404D",
//     price: 24400,
//     priceType: "cents",
//     props: [-4, 10500, 15500, 21000, 31000, 0, 6000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0691",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC412b.pdf",
//     description: "LOlvl=13dBm RF=8G-16G LO=8G-16G IF=DC-2.5G CL=8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0691A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a683-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc412b.html",
//     mfgPart: "HMC412BMS8GE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A683D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A683-0404D",
//     price: 14100,
//     priceType: "cents",
//     props: [13, 8000, 16000, 8000, 16000, 0, 2500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0691_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1027",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc557a.pdf",
//     description: "LOlvl=15dBm RF=2.5G-7G LO=2.5G-7G IF=DC-3G CL=8dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1027A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6a9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc557a.html",
//     mfgPart: "HMC557ALC4",
//     models: "this part is missing models",
//     pn: "XM-A6A9-0404D",
//     price: 19000,
//     priceType: "cents",
//     props: [15, 2500, 7000, 2500, 7000, 0, 3000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-63LH+.pdf",
//     description: "LOlvl=10dBm RF=750M-6G LO=750M-6G IF=DC-1.5G CL=6.6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6f2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-63LH%2B",
//     mfgPart: "SIM-63LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6F2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6F2-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [10, 750, 6000, 750, 6000, 0, 1500, 6.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=5G-5.2G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6f5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6F5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6F5-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 5000, 5200, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC773ALC3B.pdf",
//     description: "LOlvl=13dBm RF=6G-26G LO=6G-26G IF=DC-8G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6g2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc773alc3b.html",
//     mfgPart: "HMC773ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6G2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6G2-0404D",
//     price: 24600,
//     priceType: "cents",
//     props: [13, 6000, 26000, 6000, 26000, 0, 8000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC774ALC3B.pdf",
//     description: "LOlvl=15dBm RF=7G-34G LO=7G-34G IF=DC-8G CL=12dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6g4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc774alc3b.html",
//     mfgPart: "HMC774ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6G4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6G4-0404D",
//     price: 26000,
//     priceType: "cents",
//     props: [15, 7000, 34000, 7000, 34000, 0, 8000, 12],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc554a.pdf",
//     description: "LOlvl=13dBm RF=11G-20G LO=11G-20G IF=DC-6G CL=8.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6g5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc554alc3b.html",
//     mfgPart: "HMC554ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6G5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6G5-0404D",
//     price: 19100,
//     priceType: "cents",
//     props: [13, 11000, 20000, 11000, 20000, 0, 6000, 8.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC553ALC3B.pdf",
//     description: "LOlvl=13dBm RF=6G-14G LO=6G-14G IF=DC-5G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6g6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc553alc3b.html",
//     mfgPart: "HMC553ALC3B",
//     models: "this part is missing models",
//     pn: "XM-A6G6-0404D",
//     price: 19000,
//     priceType: "cents",
//     props: [13, 6000, 14000, 6000, 14000, 0, 5000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc329alc3b.pdf",
//     description: "LOlvl=13dBm RF=24G-32G LO=24G-32G IF=DC-8G CL=10.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6g7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc329alc3b.html",
//     mfgPart: "HMC329ALC3B",
//     models: "this part is missing models",
//     pn: "XM-A6G7-0404D",
//     price: 20100,
//     priceType: "cents",
//     props: [13, 24000, 32000, 24000, 32000, 0, 8000, 10.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc260alc3b.pdf",
//     description: "LOlvl=13dBm RF=10G-26G LO=10G-26G IF=DC-8G CL=8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6g8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc260alc3b.html",
//     mfgPart: "HMC260ALC3B",
//     models: "this part is missing models",
//     pn: "XM-A6G8-0404D",
//     price: 20900,
//     priceType: "cents",
//     props: [13, 10000, 26000, 10000, 26000, 0, 8000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC773ALC3B.pdf",
//     description: "LOlvl=13dBm RF=6G-26G LO=6G-26G IF=DC-8G CL=9dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6g9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc773alc3b.html",
//     mfgPart: "HMC773ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6G9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6G9-0404D",
//     price: 24600,
//     priceType: "cents",
//     props: [13, 6000, 26000, 6000, 26000, 0, 8000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc292alc3b.pdf",
//     description: "LOlvl=13dBm RF=14G-30G LO=14G-30G IF=DC-8G CL=9dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6h1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc292alc3b.html",
//     mfgPart: "HMC292ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6H1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6H1-0404D",
//     price: 20900,
//     priceType: "cents",
//     props: [13, 14000, 30000, 14000, 30000, 0, 8000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1048a.pdf",
//     description: "LOlvl=13dBm RF=2.25G-12G LO=2.25G-12G IF=DC-4G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6h2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc1048a.html",
//     mfgPart: "HMC1048ALC3B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6H2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6H2-0404D",
//     price: 23600,
//     priceType: "cents",
//     props: [13, 2250, 12000, 2250, 12000, 0, 4000, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC774ALC3B.pdf",
//     description: "LOlvl=15dBm RF=7G-34G LO=7G-34G IF=DC-8G CL=12dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6h3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc774alc3b.html",
//     mfgPart: "HMC774ALC3B",
//     models: "this part is missing models",
//     pn: "XM-A6H3-0404D",
//     price: 26000,
//     priceType: "cents",
//     props: [15, 7000, 34000, 7000, 34000, 0, 8000, 12],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc554a.pdf",
//     description: "LOlvl=13dBm RF=11G-20G LO=11G-20G IF=DC-6G CL=8.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6h4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc554alc3b.html",
//     mfgPart: "HMC554ALC3B",
//     models: "this part is missing models",
//     pn: "XM-A6H4-0404D",
//     price: 19100,
//     priceType: "cents",
//     props: [13, 11000, 20000, 11000, 20000, 0, 6000, 8.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC553ALC3B.pdf",
//     description: "LOlvl=13dBm RF=6G-14G LO=6G-14G IF=DC-5G CL=9dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6h5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc553alc3b.html",
//     mfgPart: "HMC553ALC3B",
//     models: "this part is missing models",
//     pn: "XM-A6H5-0404D",
//     price: 19000,
//     priceType: "cents",
//     props: [13, 6000, 14000, 6000, 14000, 0, 5000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc329alc3b.pdf",
//     description: "LOlvl=13dBm RF=24G-32G LO=24G-32G IF=DC-8G CL=10.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6h6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc329alc3b.html",
//     mfgPart: "HMC329ALC3B",
//     models: "this part is missing models",
//     pn: "XM-A6H6-0404D",
//     price: 20100,
//     priceType: "cents",
//     props: [13, 24000, 32000, 24000, 32000, 0, 8000, 10.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc260alc3b.pdf",
//     description: "LOlvl=13dBm RF=10G-26G LO=10G-26G IF=DC-8G CL=8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6h7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc260alc3b.html",
//     mfgPart: "HMC260ALC3B",
//     models: "this part is missing models",
//     pn: "XM-A6H7-0404D",
//     price: 20900,
//     priceType: "cents",
//     props: [13, 10000, 26000, 10000, 26000, 0, 8000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MM1-0626HSM.pdf",
//     description: "LOlvl=20dBm RF=6G-26.5G LO=6G-26.5G IF=DC-9G CL=7.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6j3-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0626hsm.aspx",
//     mfgPart: "MM1-0626HSM-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6J3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6J3-0404D",
//     price: 24500,
//     priceType: "cents",
//     props: [20, 6000, 26500, 6000, 26500, 0, 9000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0484",
//     catId: "MXR",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc687.pdf",
//     description: "LOlvl=0dBm RF=1.7G-2.2G LO=1.7G-2.4G IF=4.5M-500M CL=8dB ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0484B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6j4-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc687.html",
//     mfgPart: "HMC687LP4E",
//     models: "this part is missing models",
//     pn: "XM-A6J4-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 1700, 2400, 1700, 2200, 4.5, 500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-0312HSM.pdf",
//     description: "LOlvl=13dBm RF=3G-12G LO=3G-12G IF=DC-4.5G CL=7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6j7-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0312hsm.aspx",
//     mfgPart: "MM1-0312HSM-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6J7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6J7-0404D",
//     price: 20600,
//     priceType: "cents",
//     props: [13, 3000, 12000, 3000, 12000, 0, 4500, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0478",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.macom.com/products/product-detail/MAMX-011021",
//     description: "LOlvl=14dBm RF=5G-33G LO=3G-35G IF=DC-4.5G CL=8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0478A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6r1-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMX-011021",
//     mfgPart: "MAMX-011021",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6R1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6R1-0404D",
//     price: 13200,
//     priceType: "cents",
//     props: [14, 3000, 35000, 5000, 33000, 0, 4500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0478_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-14H+.pdf",
//     description: "LOlvl=17dBm RF=3.7G-10G LO=3.7G-10G IF=DC-4G CL=7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a779-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-14H%2B",
//     mfgPart: "SIM-14H+",
//     models: "this part is missing models",
//     pn: "XM-A779-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [17, 3700, 10000, 3700, 10000, 0, 4000, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-14LH+.pdf",
//     description: "LOlvl=10dBm RF=3.7G-10G LO=3.7G-10G IF=DC-4G CL=7.3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a781-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-14LH%2B",
//     mfgPart: "SIM-14LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A781D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A781-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [10, 3700, 10000, 3700, 10000, 0, 4000, 7.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-153LH+.pdf",
//     description: "LOlvl=10dBm RF=3.2G-15G LO=3.2G-15G IF=DC-4G CL=7.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a782-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-153LH%2B",
//     mfgPart: "SIM-153LH+",
//     models: "this part is missing models",
//     pn: "XM-A782-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [10, 3200, 15000, 3200, 15000, 0, 4000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-153MH+.pdf",
//     description: "LOlvl=13dBm RF=3.2G-15G LO=3.2G-15G IF=DC-4G CL=7.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a783-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-153MH%2B",
//     mfgPart: "SIM-153MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A783D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A783-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [13, 3200, 15000, 3200, 15000, 0, 4000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-193H+.pdf",
//     description: "LOlvl=17dBm RF=7.3G-19G LO=7.3G-19G IF=DC-7.5G CL=8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a784-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-193H%2B",
//     mfgPart: "SIM-193H+",
//     models: "this part is missing models",
//     pn: "XM-A784-0404D",
//     price: 14000,
//     priceType: "cents",
//     props: [17, 7300, 19000, 7300, 19000, 0, 7500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-24MH+.pdf",
//     description: "LOlvl=13dBm RF=7.3G-20G LO=7.3G-20G IF=DC-7.5G CL=5.7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a785-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-24MH%2B",
//     mfgPart: "SIM-24MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A785D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A785-0404D",
//     price: 13300,
//     priceType: "cents",
//     props: [13, 7300, 20000, 7300, 20000, 0, 7500, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43H+.pdf",
//     description: "LOlvl=17dBm RF=1G-4G LO=1G-4G IF=DC-1.5G CL=6.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a786-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43H%2B",
//     mfgPart: "SIM-43H+",
//     models: "this part is missing models",
//     pn: "XM-A786-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [17, 1000, 4000, 1000, 4000, 0, 1500, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43LH+.pdf",
//     description: "LOlvl=10dBm RF=824M-4.2G LO=824M-4.2G IF=DC-1.5G CL=6.3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a787-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43LH%2B",
//     mfgPart: "SIM-43LH+",
//     models: "this part is missing models",
//     pn: "XM-A787-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [10, 824, 4200, 824, 4200, 0, 1500, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-43MH+.pdf",
//     description: "LOlvl=13dBm RF=824M-4.2G LO=824M-4.2G IF=DC-1.5G CL=6.3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a788-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-43MH%2B",
//     mfgPart: "SIM-43MH+",
//     models: "this part is missing models",
//     pn: "XM-A788-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [13, 824, 4200, 824, 4200, 0, 1500, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-722MH+.pdf",
//     description: "LOlvl=13dBm RF=2.3G-7.2G LO=2.3G-7.2G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a789-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-722MH%2B",
//     mfgPart: "SIM-722MH+",
//     models: "this part is missing models",
//     pn: "XM-A789-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [13, 2300, 7200, 2300, 7200, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-762H+.pdf",
//     description: "LOlvl=17dBm RF=2.3G-7.6G LO=2.3G-7.6G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a791-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-762H%2B",
//     mfgPart: "SIM-762H+",
//     models: "this part is missing models",
//     pn: "XM-A791-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [17, 2300, 7600, 2300, 7600, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-83LH+.pdf",
//     description: "LOlvl=10dBm RF=1.7G-8G LO=1.7G-8G IF=DC-3G CL=6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a792-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-83LH%2B",
//     mfgPart: "SIM-83LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A792D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A792-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [10, 1700, 8000, 1700, 8000, 0, 3000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0476",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-83LH+.pdf",
//     description: "LOlvl=13dBm RF=3.7G-8.5G LO=3.7G-8.5G IF=DC-4G CL=6.9dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a793-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-852MH%2B",
//     mfgPart: "SIM-852MH+",
//     models: "this part is missing models",
//     pn: "XM-A793-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: [13, 3700, 8500, 3700, 8500, 0, 4000, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0191",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MT3-0113HCQG.pdf",
//     description: "LOlvl=18dBm RF=1.5G-13G LO=1.5G-13G IF=10M-7G CL=7.5dB ",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0191I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a796-0406d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mt3-0113hcqg.aspx",
//     mfgPart: "MT3-0113HCQG-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A796D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A796-0406D",
//     price: 47500,
//     priceType: "cents",
//     props: [18, 1500, 13000, 1500, 13000, 10, 7000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0602",
//     catId: "MXR",
//     ctlBoard: "XM-A8E7-0409D",
//     datasheetURL: "https://www.idt.com/document/dst/f1100-datasheet",
//     description: "LOlvl=6dBm RF=698M-915M LO=848M-1.365G IF=150M-450M CL=-9dB ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0602B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7d9-0409d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-mixers/f1100-rf-if-dual-downconverting-mixer",
//     mfgPart: "F1100NBGI",
//     models: "this part is missing models",
//     pn: "XM-A7D9-0409D",
//     price: 21900,
//     priceType: "cents",
//     props: [6, 848, 1365, 698, 915, 150, 450, -9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0602",
//     catId: "MXR",
//     ctlBoard: "XM-A8E7-0409D",
//     datasheetURL: "https://www.idt.com/document/dst/f1102-datasheet",
//     description: "LOlvl=6dBm RF=400M-1G LO=500M-1.15G IF=50M-300M CL=-9dB ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0602B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7e1-0409d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-mixers/f1102-dual-400-1000-mhz-rf-if-mixer",
//     mfgPart: "F1102NBGI",
//     models: "this part is missing models",
//     pn: "XM-A7E1-0409D",
//     price: 22700,
//     priceType: "cents",
//     props: [6, 500, 1150, 400, 1000, 50, 300, -9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0602",
//     catId: "MXR",
//     ctlBoard: "XM-A8E7-0409D",
//     datasheetURL: "https://www.idt.com/document/dst/f1150-datasheet",
//     description:
//       "LOlvl=6dBm RF=1.7G-2.2G LO=1.75G-2.65G IF=50M-450M CL=-8.5dB ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0602B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7e2-0409d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-mixers/f1150-dual-rf-if-downconverting-mixer",
//     mfgPart: "F1150NBGI",
//     models: "this part is missing models",
//     pn: "XM-A7E2-0409D",
//     price: 22900,
//     priceType: "cents",
//     props: [6, 1750, 2650, 1700, 2200, 50, 450, -8.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0602",
//     catId: "MXR",
//     ctlBoard: "XM-A8E7-0409D",
//     datasheetURL: "https://www.idt.com/document/dst/f1152nbgi-datasheet",
//     description: "LOlvl=6dBm RF=1.7G-2.2G LO=1.35G-2.1G IF=50M-350M CL=-8.5dB ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0602B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7e3-0409d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-mixers/f1152-dual-rf-if-downconverting-mixer",
//     mfgPart: "F1152NBGI",
//     models: "this part is missing models",
//     pn: "XM-A7E3-0409D",
//     price: 22900,
//     priceType: "cents",
//     props: [6, 1350, 2100, 1700, 2200, 50, 350, -8.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0602",
//     catId: "MXR",
//     ctlBoard: "XM-A8E7-0409D",
//     datasheetURL: "https://www.idt.com/document/dst/f1162-datasheet",
//     description: "LOlvl=6dBm RF=2.3G-2.7G LO=1.8G-2.9G IF=50M-500M CL=-8.9dB ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0602B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7e4-0409d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-mixers/f1162-dual-rf-if-downconverting-mixer-lte",
//     mfgPart: "F1162NBGI",
//     models: "this part is missing models",
//     pn: "XM-A7E4-0409D",
//     price: 22900,
//     priceType: "cents",
//     props: [6, 1800, 2900, 2300, 2700, 50, 500, -8.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0602",
//     catId: "MXR",
//     ctlBoard: "XM-A8E7-0409D",
//     datasheetURL: "https://www.idt.com/document/dst/f1178-datasheet",
//     description: "LOlvl=3dBm RF=3.4G-3.8G LO=2.9G-3.62G IF=30M-550M CL=-9dB ",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0602B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7e5-0409d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-mixers/f1178-dual-3300-3800-mhz-rf-if-mixer",
//     mfgPart: "F1178NBGI",
//     models: "this part is missing models",
//     pn: "XM-A7E5-0409D",
//     price: 22200,
//     priceType: "cents",
//     props: [3, 2900, 3620, 3400, 3800, 30, 550, -9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0605",
//     catId: "MXR",
//     ctlBoard: "XM-A8E8-0604D",
//     datasheetURL: "https://www.idt.com/document/dst/idtf1701-datasheetrevo",
//     description:
//       "LOlvl=0dBm RF=698M-960M LO=630M-1.26G IF=70M-300M CL=-11.8dB ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0605B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7e6-0604d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-mixers/f1701-ultra-linear-single-channel-mixer-rx-applications",
//     mfgPart: "F1701NBGI",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7E6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7E6-0604D",
//     price: 20600,
//     priceType: "cents",
//     props: [0, 630, 1260, 698, 960, 70, 300, -11.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0605",
//     catId: "MXR",
//     ctlBoard: "XM-A8E8-0604D",
//     datasheetURL: "https://www.idt.com/document/dst/idtf1751-datasheetrev1",
//     description:
//       "LOlvl=0dBm RF=1.43G-2.05G LO=1.4G-2.5G IF=50M-500M CL=-11.8dB ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0605B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7e7-0604d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-mixers/f1751-ultra-linear-single-channel-mixer-rx-applications",
//     mfgPart: "F1751NBGI",
//     models: "this part is missing models",
//     pn: "XM-A7E7-0604D",
//     price: 16900,
//     priceType: "cents",
//     props: [0, 1400, 2500, 1430, 2050, 50, 500, -11.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0605",
//     catId: "MXR",
//     ctlBoard: "XM-A8E8-0604D",
//     datasheetURL: "https://www.idt.com/document/dst/idtf1763-datasheetrevo",
//     description: "LOlvl=0dBm RF=2.3G-2.7G LO=2.3G-2.7G IF=50M-2.3G CL=-11.7dB ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0605B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7e8-0604d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-mixers/f1763-ultra-linear-single-channel-mixer-rx-applications",
//     mfgPart: "F1763NBGI",
//     models: "this part is missing models",
//     pn: "XM-A7E8-0604D",
//     price: 16900,
//     priceType: "cents",
//     props: [0, 2300, 2700, 2300, 2700, 50, 2300, -11.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0767",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1063.pdf",
//     description: "LOlvl=10dBm RF=24G-28G LO=21G-31G IF=DC-3G CL=9.5dB ",
//     eccn: "",
//     ffNum: 18,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0767A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7f8-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc1063.html",
//     mfgPart: "HMC1063LP3E",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7F8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7F8-0409D",
//     price: 22100,
//     priceType: "cents",
//     props: [10, 21000, 31000, 24000, 28000, 0, 3000, 9.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0721",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc560alm3.pdf",
//     description: "LOlvl=13dBm RF=24G-40G LO=24G-40G IF=DC-17G CL=11dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0721B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7g1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc560a.html",
//     mfgPart: "HMC560ALM3",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7G1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7G1-0404D",
//     price: 22500,
//     priceType: "cents",
//     props: [13, 24000, 40000, 24000, 40000, 0, 17000, 11],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0721_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=400M-900M CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7m7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7M7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7M7-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 400, 900, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=400M-900M CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7m8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7M8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7M8-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 400, 900, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=700M-2.7G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7m9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7M9-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 700, 2700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=700M-2.7G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7n1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7N1-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 700, 2700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=800M-1G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7n2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7N2-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 800, 1000, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=800M-1G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7n3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7N3-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 800, 1000, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1G-1.5G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7n4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7N4-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1000, 1500, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1G-1.5G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7n5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7N5-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1000, 1500, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1.5G-1.7G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7n6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7N6-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1500, 1700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1.5G-1.7G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7n7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7N7-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1500, 1700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1.2G-1.7G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7n8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7N8-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1200, 1700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1.2G-1.7G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7n9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7N9-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1200, 1700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=2G-2.3G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7p1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7P1-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 2000, 2300, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=2G-2.3G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7p2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7P2-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 2000, 2300, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=2.3G-2.7G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7p3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7P3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7P3-0404D",
//     price: 29700,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 2300, 2700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=2.3G-2.7G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7p4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7P4-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 2300, 2700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=3.3G-3.7G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7p5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7P5-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 3300, 3700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=3.3G-3.7G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7p6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A7P6-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 3300, 3700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0191",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MT3-0113LCQG.pdf",
//     description: "LOlvl=27dBm RF=1.5G-13G LO=1.5G-13G IF=10M-7G CL=7.5dB ",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0191I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8a3-0406d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mt3-0113lcqg.aspx",
//     mfgPart: "MT3-0113LCQG-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A8A3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A8A3-0406D",
//     price: 49700,
//     priceType: "cents",
//     props: [27, 1500, 13000, 1500, 13000, 10, 7000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1557",
//     catId: "MXR",
//     ctlBoard: "XM-C6J1-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc966.pdf",
//     description: "LOlvl=6dBm RF=17G-20G LO=7.5G-11.75G IF=DC-3.5G CL=-14dB ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1557A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8r1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc966.html",
//     mfgPart: "HMC966LP4E",
//     models: "this part is missing models",
//     pn: "XM-A8R1-0409D",
//     price: 25800,
//     priceType: "cents",
//     props: [6, 7500, 11750, 17000, 20000, 0, 3500, -14],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0913",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=DC-5G CL=10dB ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0913A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9p4-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-A9P4-0409D",
//     price: 35800,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 0, 5000, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0913_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0190",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd253c3_ver_1.0_1017.pdf",
//     description: "LOlvl=19dBm RF=6G-14G LO=6G-14G IF=DC-5G CL=6dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0190H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9p9-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/fundamental-mixer-cmd253c3/#utm_source=EverythingRF",
//     mfgPart: "CMD253C3",
//     models: "this part is missing models",
//     pn: "XM-A9P9-0404D",
//     price: 23300,
//     priceType: "cents",
//     props: [19, 6000, 14000, 6000, 14000, 0, 5000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0190_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0190",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd254c3_ver_1.0_1017.pdf",
//     description: "LOlvl=19dBm RF=11G-21G LO=11G-21G IF=DC-6G CL=6dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0190H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9r1-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/fundamental-mixer-cmd254c3/#utm_source=EverythingRF",
//     mfgPart: "CMD254C3",
//     models: "this part is missing models",
//     pn: "XM-A9R1-0404D",
//     price: 22200,
//     priceType: "cents",
//     props: [19, 11000, 21000, 11000, 21000, 0, 6000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0190_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0190",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd255c3_ver_1.0_1017.pdf",
//     description: "LOlvl=19dBm RF=16G-26G LO=16G-26G IF=DC-9G CL=6.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0190H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9r2-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/fundamental-mixer-cmd255c3/#utm_source=EverythingRF",
//     mfgPart: "CMD255C3",
//     models: "this part is missing models",
//     pn: "XM-A9R2-0404D",
//     price: 23300,
//     priceType: "cents",
//     props: [19, 16000, 26000, 16000, 26000, 0, 9000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0190_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1164",
//     catId: "MXR",
//     ctlBoard: "XM-C6A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/5553f.pdf",
//     description: "LOlvl=0dBm RF=3G-20G LO=1G-20G IF=500M-9G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1164A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9v7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/ltc5553.html",
//     mfgPart: "LTC5553",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9V7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9V7-0404D",
//     price: 21800,
//     priceType: "cents",
//     props: [0, 1000, 20000, 3000, 20000, 500, 9000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1164_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1166",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd258c4_ver_1.0_1017.pdf",
//     description: "RF=7.5G-13G LO=7.5G-13G IF=DC-3.5G ",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1166A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9x5-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd258c4/",
//     mfgPart: "CMD258C4",
//     models: "this part is missing models",
//     pn: "XM-A9X5-0409D",
//     price: 29200,
//     priceType: "cents",
//     props: [0, 7500, 13000, 7500, 13000, 0, 3500, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1166_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1166",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd257c4_ver_1.0_1017.pdf",
//     description: "LOlvl=21dBm RF=6G-8.5G LO=6G-8.5G IF=DC-3.5G CL=6dB ",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1166A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9x6-0409d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd257c4/",
//     mfgPart: "CMD257C4",
//     models: "this part is missing models",
//     pn: "XM-A9X6-0409D",
//     price: 30200,
//     priceType: "cents",
//     props: [21, 6000, 8500, 6000, 8500, 0, 3500, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1166_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd177c3_ver_1.7_1017.pdf",
//     description: "LOlvl=13dBm RF=6G-14G LO=6G-14G IF=DC-5G CL=6.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b111-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd177c3_mixer/",
//     mfgPart: "CMD177C3",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B111D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B111-0404D",
//     price: 21700,
//     priceType: "cents",
//     props: [13, 6000, 14000, 6000, 14000, 0, 5000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd178c3_ver_1.5_1017.pdf",
//     description: "LOlvl=13dBm RF=11G-21G LO=11G-21G IF=DC-6G CL=6dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b112-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd178c3_mixer/",
//     mfgPart: "CMD178C3",
//     models: "this part is missing models",
//     pn: "XM-B112-0404D",
//     price: 20800,
//     priceType: "cents",
//     props: [13, 11000, 21000, 11000, 21000, 0, 6000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd179c3_ver_1.6_1017.pdf",
//     description: "LOlvl=13dBm RF=16G-26G LO=16G-26G IF=DC-9G CL=6.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b113-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd179c3_mixer/",
//     mfgPart: "CMD179C3",
//     models: "this part is missing models",
//     pn: "XM-B113-0404D",
//     price: 21800,
//     priceType: "cents",
//     props: [13, 16000, 26000, 16000, 26000, 0, 9000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd180c3_ver_1.6_1017.pdf",
//     description: "LOlvl=13dBm RF=20G-32G LO=20G-32G IF=DC-10G CL=7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b114-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd180c3_mixer/",
//     mfgPart: "CMD180C3",
//     models: "this part is missing models",
//     pn: "XM-B114-0404D",
//     price: 21900,
//     priceType: "cents",
//     props: [13, 20000, 32000, 20000, 32000, 0, 10000, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd253c3_ver_1.0_1017.pdf",
//     description: "LOlvl=19dBm RF=6G-14G LO=6G-14G IF=DC-5G CL=6dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b115-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/fundamental-mixer-cmd253c3/#utm_source=EverythingRF",
//     mfgPart: "CMD253C3",
//     models: "this part is missing models",
//     pn: "XM-B115-0404D",
//     price: 23300,
//     priceType: "cents",
//     props: [19, 6000, 14000, 6000, 14000, 0, 5000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-1130hsm.pdf",
//     description: "LOlvl=17dBm RF=11G-30G LO=11G-30G IF=DC-12G CL=7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b116-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1130hsm.aspx",
//     mfgPart: "MM1-1130HSM-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B116D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B116-0404D",
//     price: 26800,
//     priceType: "cents",
//     props: [17, 11000, 30000, 11000, 30000, 0, 12000, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd177c3_ver_1.7_1017.pdf",
//     description: "LOlvl=13dBm RF=6G-14G LO=6G-14G IF=DC-5G CL=6.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b117-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "http://www.custommmic.com/cmd177c3_mixer/",
//     mfgPart: "CMD177C3",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B117D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B117-0404D",
//     price: 21700,
//     priceType: "cents",
//     props: [13, 6000, 14000, 6000, 14000, 0, 5000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd178c3_ver_1.5_1017.pdf",
//     description: "LOlvl=13dBm RF=11G-21G LO=11G-21G IF=DC-6G CL=6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b118-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd178c3_mixer/",
//     mfgPart: "CMD178C3",
//     models: "this part is missing models",
//     pn: "XM-B118-0404D",
//     price: 17300,
//     priceType: "cents",
//     props: [13, 11000, 21000, 11000, 21000, 0, 6000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd179c3_ver_1.6_1017.pdf",
//     description: "LOlvl=13dBm RF=16G-26G LO=16G-26G IF=DC-9G CL=6.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b119-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd179c3_mixer/",
//     mfgPart: "CMD179C3",
//     models: "this part is missing models",
//     pn: "XM-B119-0404D",
//     price: 21800,
//     priceType: "cents",
//     props: [13, 16000, 26000, 16000, 26000, 0, 9000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd253c3_ver_1.0_1017.pdf",
//     description: "LOlvl=19dBm RF=6G-14G LO=6G-14G IF=DC-5G CL=6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b121-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/fundamental-mixer-cmd253c3/#utm_source=EverythingRF",
//     mfgPart: "CMD253C3",
//     models: "this part is missing models",
//     pn: "XM-B121-0404D",
//     price: 23300,
//     priceType: "cents",
//     props: [19, 6000, 14000, 6000, 14000, 0, 5000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-1130hsm.pdf",
//     description: "LOlvl=17dBm RF=11G-30G LO=11G-30G IF=DC-12G CL=7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b122-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1130hsm.aspx",
//     mfgPart: "MM1-1130HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B122-0404D",
//     price: 26800,
//     priceType: "cents",
//     props: [17, 11000, 30000, 11000, 30000, 0, 12000, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd180c3_ver_1.6_1017.pdf",
//     description: "LOlvl=13dBm RF=20G-32G LO=20G-32G IF=DC-10G CL=7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b133-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd180c3_mixer/",
//     mfgPart: "CMD180C3",
//     models: "this part is missing models",
//     pn: "XM-B133-0404D",
//     price: 21900,
//     priceType: "cents",
//     props: [13, 20000, 32000, 20000, 32000, 0, 10000, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=DC-5G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b166-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B166-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 0, 5000, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=5G-5.2G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b167-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B167-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 5000, 5200, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=400M-900M CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b168-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B168-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 400, 900, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=400M-900M CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b169-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B169-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 400, 900, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=700M-2.7G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b171-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B171-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 700, 2700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=700M-2.7G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b172-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B172-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 700, 2700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=800M-1G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b173-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B173-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 800, 1000, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=800M-1G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b174-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B174-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 800, 1000, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1G-1.5G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b175-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B175-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1000, 1500, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1G-1.5G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b176-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B176-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1000, 1500, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1.5G-1.7G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b177-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B177-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1500, 1700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1.5G-1.7G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b178-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B178-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1500, 1700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1.2G-1.7G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b179-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B179-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1200, 1700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=1.7G-1.5G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b181-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B181-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 1700, 1500, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=2G-2.3G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b182-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B182-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 2000, 2300, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=2G-2.3G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b183-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B183-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 2000, 2300, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=2.3G-2.7G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b184-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B184-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 2300, 2700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=2.3G-2.7G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b185-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B185-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 2300, 2700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0466",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=3.3G-3.7G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0466C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b186-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B186-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 3300, 3700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0467",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8191.pdf",
//     description: "LOlvl=18dBm RF=6G-26.5G LO=6G-26.5G IF=3.3G-3.7G CL=10dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0467B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b187-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8191.html",
//     mfgPart: "HMC8191LC4",
//     models: "this part is missing models",
//     pn: "XM-B187-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [18, 6000, 26500, 6000, 26500, 3300, 3700, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1719",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMX-011036-DIE.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b191-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MAMX-011036-DIE",
//     mfgPart: "MAMX-011036-DIE",
//     models: "this is the new part",
//     pn: "XM-B191-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1719_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1721",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMX-011037-DIE.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b192-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MAMX-011037-DIE",
//     mfgPart: "MAMX-011037-DIE",
//     models: "this is the new part",
//     pn: "XM-B192-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1721_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1750",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMX-011037-DIE.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b193-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MAMX-011037-DIE",
//     mfgPart: "MAMX-011037-DIE",
//     models: "this is the new part",
//     pn: "XM-B193-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1750_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0913",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-0520LSM.pdf",
//     description: "LOlvl=14dBm RF=5G-20G LO=5G-20G IF=DC-6G ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0913A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1c7-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mmiq-0520lsm.aspx",
//     mfgPart: "MMIQ-0520LSM-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1C7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1C7-0409D",
//     price: 44600,
//     priceType: "cents",
//     props: [14, 5000, 20000, 5000, 20000, 0, 6000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0913_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0190",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-1130hsm.pdf",
//     description: "LOlvl=17dBm RF=11G-30G LO=11G-30G IF=DC-12G CL=7dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0190H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1d7-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1130hsm.aspx",
//     mfgPart: "MM1-1130HSM-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1D7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1D7-0404D",
//     price: 26400,
//     priceType: "cents",
//     props: [17, 11000, 30000, 11000, 30000, 0, 12000, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0190_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1041",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1MH.pdf",
//     description: "LOlvl=13dBm RF=2M-500M LO=2M-500M IF=DC-500M CL=8dB ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1041A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1f8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1MH",
//     mfgPart: "ADE-1MH",
//     models: "this part is missing models",
//     pn: "XM-B1F8-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [13, 2, 500, 2, 500, 0, 500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1041_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0693",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC220B.pdf",
//     description: "LOlvl=10dBm RF=5G-12G LO=5G-12G IF=DC-4G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0693A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1h6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc220b.html",
//     mfgPart: "HMC220BMS8GE",
//     models: "this part is missing models",
//     pn: "XM-B1H6-0404D",
//     price: 13300,
//     priceType: "cents",
//     props: [10, 5000, 12000, 5000, 12000, 0, 4000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0693_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0694",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC220B.pdf",
//     description: "LOlvl=10dBm RF=5G-12G LO=5G-12G IF=DC-4G CL=9dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0694A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1h7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc220b.html",
//     mfgPart: "HMC220BMS8GE",
//     models: "this part is missing models",
//     pn: "XM-B1H7-0404D",
//     price: 13300,
//     priceType: "cents",
//     props: [10, 5000, 12000, 5000, 12000, 0, 4000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0694_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0692",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC412b.pdf",
//     description: "LOlvl=13dBm RF=8G-16G LO=8G-16G IF=DC-2.5G CL=8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0692A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1j4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc412b.html",
//     mfgPart: "HMC412BMS8GE",
//     models: "this part is missing models",
//     pn: "XM-B1J4-0404D",
//     price: 14100,
//     priceType: "cents",
//     props: [13, 8000, 16000, 8000, 16000, 0, 2500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0692_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1124",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-1850S.pdf",
//     description: "LOlvl=20dBm RF=18G-50G LO=18G-50G IF=DC-20G CL=8.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1124B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1p5-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1850s.aspx",
//     mfgPart: "MM1-1850SCH-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1P5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1P5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [20, 18000, 50000, 18000, 50000, 0, 20000, 8.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1124_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-0832lsm.pdf",
//     description: "LOlvl=12dBm RF=8G-32G LO=8G-30G IF=DC-12G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b215-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0832lsm.aspx",
//     mfgPart: "MM1-0832LSM-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B215D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B215-0404D",
//     price: 31700,
//     priceType: "cents",
//     props: [12, 8000, 30000, 8000, 32000, 0, 12000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0865",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1MH+.pdf",
//     description: "LOlvl=13dBm RF=2M-500M LO=2M-500M IF=DC-500M CL=5.7dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0865B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2b4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1MH%2B",
//     mfgPart: "ADE-1MH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2B4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2B4-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [13, 2, 500, 2, 500, 0, 500, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0866",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1MH+.pdf",
//     description: "LOlvl=13dBm RF=2M-500M LO=2M-500M IF=DC-500M CL=5.7dB ",
//     eccn: "",
//     ffNum: 10,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0866A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2b5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1MH%2B",
//     mfgPart: "ADE-1MH+",
//     models: "this part is missing models",
//     pn: "XM-B2B5-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [13, 2, 500, 2, 500, 0, 500, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0906",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SIM-63LH+.pdf",
//     description: "LOlvl=10dBm RF=750M-6G LO=750M-6G IF=DC-1.5G CL=6.6dB ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0906A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2b7-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SIM-63LH%2B",
//     mfgPart: "SIM-63LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2B7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2B7-0604D",
//     price: 15800,
//     priceType: "cents",
//     props: [10, 750, 6000, 750, 6000, 0, 1500, 6.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0906_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MM1-0626SSM.pdf",
//     description: "LOlvl=20dBm RF=6G-26.5G LO=6G-26.5G IF=DC-9G CL=8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2d4-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0626ssm.aspx",
//     mfgPart: "MM1-0626SSM-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2D4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2D4-0404D",
//     price: 26600,
//     priceType: "cents",
//     props: [20, 6000, 26500, 6000, 26500, 0, 9000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1027",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MDB-73H+.pdf",
//     description: "LOlvl=15dBm RF=2.2G-7G LO=2.2G-7G IF=DC-1.6G CL=9dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1027A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2f6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MDB-73H%2B",
//     mfgPart: "MDB-73H+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2F6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2F6-0404D",
//     price: 12400,
//     priceType: "cents",
//     props: [15, 2200, 7000, 2200, 7000, 0, 1600, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0556",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-1857h.pdf",
//     description: "LOlvl=13dBm RF=18G-57G LO=18G-57G IF=DC-21G CL=7.5dB ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0556B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2g1-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1857h.aspx",
//     mfgPart: "MM1-1857HCH-2",
//     models: "this part is missing models",
//     pn: "XM-B2G1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [13, 18000, 57000, 18000, 57000, 0, 21000, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0556_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1076",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-0205HSM.pdf",
//     description: "RF=1.75G-5G LO=1.75G-5G IF=DC-2G ",
//     eccn: "",
//     ffNum: 20,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1076B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2k4-0609d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mmiq-0205hsm.aspx",
//     mfgPart: "MMIQ-0205HSM-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2K4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2K4-0609D",
//     price: 54300,
//     priceType: "cents",
//     props: [0, 1750, 5000, 1750, 5000, 0, 2000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1076_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0866",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1MH.pdf",
//     description: "LOlvl=13dBm RF=2M-500M LO=2M-500M IF=DC-500M CL=8dB ",
//     eccn: "",
//     ffNum: 10,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0866A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2m3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1MH",
//     mfgPart: "ADE-1MH",
//     models: "this part is missing models",
//     pn: "XM-B2M3-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [13, 2, 500, 2, 500, 0, 500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0913",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-0416LSM.pdf",
//     description: "RF=4G-16G LO=4G-16G IF=DC-6G ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0913A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2v1-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/iq-mixers/mmiq-0416lsm.aspx",
//     mfgPart: "MMIQ-0416LSM-2",
//     models: "this part is missing models",
//     pn: "XM-B2V1-0409D",
//     price: 40500,
//     priceType: "cents",
//     props: [0, 4000, 16000, 4000, 16000, 0, 6000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0913_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1612",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MDB-44H+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b2w1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MDB-44H%2B",
//     mfgPart: "MDB-44+",
//     models: "this is the new part",
//     pn: "XM-B2W1-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1612_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1041",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-1LH+.pdf",
//     description: "LOlvl=10dBm RF=0.5M-500M LO=0.5M-500M IF=DC-500M CL=5dB ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1041A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4v5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-1LH%2B",
//     mfgPart: "ADE-1LH+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B4V5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B4V5-0604D",
//     price: 14900,
//     priceType: "cents",
//     props: [10, 0.5, 500, 0.5, 500, 0, 500, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1041_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0992",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LRMS-25J+.pdf",
//     description: "LOlvl=10dBm RF=750M-2.5G LO=750M-2.5G IF=DC-600M CL=9.5dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0992A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5a4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LRMS-25J%2B",
//     mfgPart: "LRMS-25J+",
//     models: "this part is missing models",
//     pn: "XM-B5A4-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [10, 750, 2500, 750, 2500, 0, 600, 9.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0993",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LRMS-25J+.pdf",
//     description: "LOlvl=10dBm RF=750M-2.5G LO=750M-2.5G IF=DC-600M CL=9.5dB ",
//     eccn: "",
//     ffNum: 10,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0993A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5a5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LRMS-25J%2B",
//     mfgPart: "LRMS-25J+",
//     models: "this part is missing models",
//     pn: "XM-B5A5-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [10, 750, 2500, 750, 2500, 0, 600, 9.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1789",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-1040L.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5h1-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mmiq-1040l.aspx",
//     mfgPart: "MMIQ-1040LCH-2",
//     models: "this is the new part",
//     pn: "XM-B5H1-0409D",
//     price: 100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1789_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1128",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc524a.html",
//     description: "LOlvl=17dBm RF=22G-32G LO=22G-32G IF=DC-4.5G CL=9dB ",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1128A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5h2-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc524a.html",
//     mfgPart: "HMC524ALC3B",
//     models: "this part is missing models",
//     pn: "XM-B5H2-0409D",
//     price: 26600,
//     priceType: "cents",
//     props: [17, 22000, 32000, 22000, 32000, 0, 4500, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1128_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0865",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-30+.pdf",
//     description: "LOlvl=7dBm RF=200M-3G LO=200M-3G IF=DC-1G CL=4.5dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0865B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5u4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-30%2B",
//     mfgPart: "ADE-30+",
//     models: "this part is missing models",
//     pn: "XM-B5U4-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [7, 200, 3000, 200, 3000, 0, 1000, 4.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0866",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADE-30+.pdf",
//     description: "LOlvl=7dBm RF=200M-3G LO=200M-3G IF=DC-1G CL=4.5dB ",
//     eccn: "",
//     ffNum: 10,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0866A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5u5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADE-30%2B",
//     mfgPart: "ADE-30+",
//     models: "this part is missing models",
//     pn: "XM-B5U5-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [7, 200, 3000, 200, 3000, 0, 1000, 4.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1416",
//     catId: "MXR",
//     ctlBoard: "XM-B5V3-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/adl5355.pdf",
//     description: "LOlvl=0dBm RF=1.2G-2.5G LO=1.23G-2.47G IF=30M-450M CL=8.4dB ",
//     eccn: "",
//     ffNum: 25,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1416A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5v1-0809d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl5355.html",
//     mfgPart: "ADL5355",
//     models: "this part is missing models",
//     pn: "XM-B5V1-0809D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 1230, 2470, 1200, 2500, 30, 450, 8.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1416_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1416",
//     catId: "MXR",
//     ctlBoard: "XM-B5V3-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/adl5357.pdf",
//     description: "LOlvl=0dBm RF=500M-1.7G LO=730M-1.67G IF=30M-450M CL=8.6dB ",
//     eccn: "",
//     ffNum: 25,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1416A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5v2-0809d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl5357.html",
//     mfgPart: "ADL5357",
//     models: "this part is missing models",
//     pn: "XM-B5V2-0809D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 730, 1670, 500, 1700, 30, 450, 8.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1416_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMX-011036.pdf",
//     description: "LOlvl=15dBm RF=8G-43G LO=8G-43G IF=DC-10G CL=8.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6m4-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMX-011036",
//     mfgPart: "MAMX-011036",
//     models: "this part is missing models",
//     pn: "XM-B6M4-0404D",
//     price: 26500,
//     priceType: "cents",
//     props: [15, 8000, 43000, 8000, 43000, 0, 10000, 8.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMX-011036.pdf",
//     description: "LOlvl=15dBm RF=8G-43G LO=8G-43G IF=DC-10G CL=8.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6m8-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMX-011036",
//     mfgPart: "MAMX-011036",
//     models: "this part is missing models",
//     pn: "XM-B6M8-0404D",
//     price: 26500,
//     priceType: "cents",
//     props: [15, 8000, 43000, 8000, 43000, 0, 10000, 8.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1065",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MDB-44H+.pdf",
//     description: "LOlvl=15dBm RF=10G-40G LO=10G-40G IF=DC-15G CL=8.9dB ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1065A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7k9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MDB-44H%2B",
//     mfgPart: "MDB-44+",
//     models: "this part is missing models",
//     pn: "XM-B7K9-0404D",
//     price: 13100,
//     priceType: "cents",
//     props: [15, 10000, 40000, 10000, 40000, 0, 15000, 8.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1065_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1158",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.keysight.com/upload/cmc_upload/All/TC260-1GC1-8068.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b7n8-0404d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://www.keysight.com/us/en/products/mmic-millimeter-wave-microwave-devices/millimeter-wave-microwave-gaas-mixers.html",
//     mfgPart: "TC260 / 1GC1-8068",
//     models: "this is the new part",
//     pn: "XM-B7N8-0404D",
//     price: 51900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1158_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1166",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc525a.pdf",
//     description: "LOlvl=15dBm RF=4G-8.5G LO=4G-8.5G IF=DC-3.5G CL=8dB ",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1166A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7p1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc525alc4.html",
//     mfgPart: "HMC525ALC4",
//     models: "this part is missing models",
//     pn: "XM-B7P1-0409D",
//     price: 25000,
//     priceType: "cents",
//     props: [15, 4000, 8500, 4000, 8500, 0, 3500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1166_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1232",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc213b.pdf",
//     description: "LOlvl=13dBm RF=1.5G-4.5G LO=1.5G-4.5G IF=DC-1.5G CL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1232A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7p5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc213b.html",
//     mfgPart: "HMC213BMS8E",
//     models: "this part is missing models",
//     pn: "XM-B7P5-0404D",
//     price: 13900,
//     priceType: "cents",
//     props: [13, 1500, 4500, 1500, 4500, 0, 1500, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1232_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1263",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MT3H-0113LSM.pdf",
//     description: "LOlvl=15dBm RF=1.5G-13G LO=1.5G-13G IF=800M-8.5G CL=11.5dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1263A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b811-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mt3h-0113lsm.aspx",
//     mfgPart: "MT3H-0113LSM-2",
//     models: "this part is missing models",
//     pn: "XM-B811-0404D",
//     price: 38100,
//     priceType: "cents",
//     props: [15, 1500, 13000, 1500, 13000, 800, 8500, 11.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1263_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0192",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/ml1-0110sm.pdf",
//     description: "RF=1.5G-10G LO=1.5G-10G IF=DC-2G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0192J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8v2-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/ml1-0110sm.aspx",
//     mfgPart: "ML1-0110LSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8V2-0404D",
//     price: 23300,
//     priceType: "cents",
//     props: [0, 1500, 10000, 1500, 10000, 0, 2000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1027",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MM1-0212HSM.pdf",
//     description: "LOlvl=15dBm RF=2G-12G LO=2G-12G IF=DC-3G CL=9dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1027A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8v4-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0212hsm.aspx",
//     mfgPart: "MM1-0212HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8V4-0404D",
//     price: 22600,
//     priceType: "cents",
//     props: [15, 2000, 12000, 2000, 12000, 0, 3000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1027",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MM1-0212LSM.pdf",
//     description: "RF=2G-12G LO=2G-12G IF=DC-3G ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1027A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8v5-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0212lsm.aspx",
//     mfgPart: "MM1-0212LSM-2",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B8V5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B8V5-0404D",
//     price: 24700,
//     priceType: "cents",
//     props: [0, 2000, 12000, 2000, 12000, 0, 3000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1027",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MM1-0212SSM.pdf",
//     description: "RF=2G-12G LO=2G-12G IF=DC-3G ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1027A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8v6-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0212ssm.aspx",
//     mfgPart: "MM1-0212SSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8V6-0404D",
//     price: 26800,
//     priceType: "cents",
//     props: [0, 2000, 12000, 2000, 12000, 0, 3000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-0312HSM.pdf",
//     description: "LOlvl=17dBm RF=3G-12G LO=3G-12G IF=DC-4.5G CL=7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8v7-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0312hsm.aspx",
//     mfgPart: "MM1-0312HSM-1",
//     models: "this part is missing models",
//     pn: "XM-B8V7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [17, 3000, 12000, 3000, 12000, 0, 4500, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-0312SSM.pdf",
//     description: "LOlvl=20dBm RF=3G-12G LO=3G-12G IF=DC-4.5G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8v8-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0312ssm.aspx",
//     mfgPart: "MM1-0312SSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8V8-0404D",
//     price: 22600,
//     priceType: "cents",
//     props: [20, 3000, 12000, 3000, 12000, 0, 4500, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-0320HSM.pdf",
//     description: "LOlvl=17dBm RF=3.5G-20G LO=3.5G-20G IF=DC-4G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8v9-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0320hsm.aspx",
//     mfgPart: "MM1-0320HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8V9-0404D",
//     price: 20600,
//     priceType: "cents",
//     props: [17, 3500, 20000, 3500, 20000, 0, 4000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MM1-0424SSM.pdf",
//     description: "LOlvl=20dBm RF=4.5G-24G LO=4.5G-24G IF=DC-4G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8w1-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0424ssm.aspx",
//     mfgPart: "MM1-0424SSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8W1-0404D",
//     price: 24300,
//     priceType: "cents",
//     props: [20, 4500, 24000, 4500, 24000, 0, 4000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0190",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MM1-0626SSM.pdf",
//     description: "LOlvl=20dBm RF=6G-26.5G LO=6G-26.5G IF=DC-9G CL=8dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0190H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8w3-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0626ssm.aspx",
//     mfgPart: "MM1-0626SSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8W3-0404D",
//     price: 26100,
//     priceType: "cents",
//     props: [20, 6000, 26500, 6000, 26500, 0, 9000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0190_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1260",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-0726hsm.pdf",
//     description: "LOlvl=15dBm RF=7G-26.5G LO=7G-26.5G IF=DC-9G CL=6.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1260A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8w4-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0726hsm.aspx",
//     mfgPart: "MM1-0726HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8W4-0404D",
//     price: 23700,
//     priceType: "cents",
//     props: [15, 7000, 26500, 7000, 26500, 0, 9000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1260_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-0832hsm.pdf",
//     description: "LOlvl=18dBm RF=8G-32G LO=8G-30G IF=DC-12G ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8w5-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0832hsm.aspx",
//     mfgPart: "MM1-0832HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8W5-0404D",
//     price: 29700,
//     priceType: "cents",
//     props: [18, 8000, 30000, 8000, 32000, 0, 12000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1262",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MM2-0530HSM.pdf",
//     description: "LOlvl=16dBm RF=5G-30G LO=5G-30G IF=2M-20G CL=8dB ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1262A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8w7-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm2-0530hsm.aspx",
//     mfgPart: "MM2-0530HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8W7-0404D",
//     price: 42200,
//     priceType: "cents",
//     props: [16, 5000, 30000, 5000, 30000, 2, 20000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1262_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1262",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MM2-0530LSM.pdf",
//     description: "LOlvl=9dBm RF=5G-30G LO=5G-30G IF=2M-20G CL=10dB ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1262A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8w8-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm2-0530lsm.aspx",
//     mfgPart: "MM2-0530LSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8W8-0404D",
//     price: 46400,
//     priceType: "cents",
//     props: [9, 5000, 30000, 5000, 30000, 2, 20000, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1262_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1262",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MT3H-0113HSM.pdf",
//     description: "RF=1.5G-13G LO=1.5G-13G IF=800M-8.5G ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1262A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8y2-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mt3h-0113hsm.aspx",
//     mfgPart: "MT3H-0113HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8Y2-0404D",
//     price: 36000,
//     priceType: "cents",
//     props: [0, 1500, 13000, 1500, 13000, 800, 8500, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1262_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1262",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MT3H-0113LSM.pdf",
//     description: "LOlvl=15dBm RF=1.5G-13G LO=1.5G-13G IF=800M-8.5G CL=11.5dB ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1262A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8y3-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mt3h-0113lsm.aspx",
//     mfgPart: "MT3H-0113LSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8Y3-0404D",
//     price: 38100,
//     priceType: "cents",
//     props: [15, 1500, 13000, 1500, 13000, 800, 8500, 11.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1262_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0196",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/ml1-0110sm.pdf",
//     description: "RF=1.5G-10G LO=1.5G-10G IF=DC-2G ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0196J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8y4-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/ml1-0110sm.aspx",
//     mfgPart: "ML1-0110LSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8Y4-0404D",
//     price: 23300,
//     priceType: "cents",
//     props: [0, 1500, 10000, 1500, 10000, 0, 2000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0196_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1261",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-0726hsm.pdf",
//     description: "LOlvl=15dBm RF=7G-26.5G LO=7G-26.5G IF=DC-9G CL=6.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1261A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8y6-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0726hsm.aspx",
//     mfgPart: "MM1-0726HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8Y6-0404D",
//     price: 23700,
//     priceType: "cents",
//     props: [15, 7000, 26500, 7000, 26500, 0, 9000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1261_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1263",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MM2-0530HSM.pdf",
//     description: "LOlvl=16dBm RF=5G-30G LO=5G-30G IF=2G-20G CL=8dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1263A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8y7-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm2-0530hsm.aspx",
//     mfgPart: "MM2-0530HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8Y7-0404D",
//     price: 42200,
//     priceType: "cents",
//     props: [16, 5000, 30000, 5000, 30000, 2000, 20000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1263_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1263",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MM2-0530LSM.pdf",
//     description: "LOlvl=9dBm RF=5G-30G LO=5G-30G IF=2G-20G CL=10dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1263A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8y8-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm2-0530lsm.aspx",
//     mfgPart: "MM2-0530LSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8Y8-0404D",
//     price: 46400,
//     priceType: "cents",
//     props: [9, 5000, 30000, 5000, 30000, 2000, 20000, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1263_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1263",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MT3H-0113HSM.pdf",
//     description: "RF=1.5G-13G LO=1.5G-13G IF=800M-8.5G ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1263A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8y9-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mt3h-0113hsm.aspx",
//     mfgPart: "MT3H-0113HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B8Y9-0404D",
//     price: 36000,
//     priceType: "cents",
//     props: [0, 1500, 13000, 1500, 13000, 800, 8500, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1263_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMX-011035.pdf",
//     description: "LOlvl=15dBm RF=5.5G-19G LO=5.5G-19G IF=DC-6G CL=6dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9e2-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMX-011035",
//     mfgPart: "MAMX-011035",
//     models: "this part is missing models",
//     pn: "XM-B9E2-0404D",
//     price: 18600,
//     priceType: "cents",
//     props: [15, 5500, 19000, 5500, 19000, 0, 6000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMX-011035.pdf",
//     description: "LOlvl=15dBm RF=5.5G-19G LO=5.5G-19G IF=DC-6G CL=6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9e3-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMX-011035",
//     mfgPart: "MAMX-011035",
//     models: "this part is missing models",
//     pn: "XM-B9E3-0404D",
//     price: 18600,
//     priceType: "cents",
//     props: [15, 5500, 19000, 5500, 19000, 0, 6000, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0472",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMX-011054.pdf",
//     description: "LOlvl=15dBm RF=18G-45G LO=18G-45G IF=DC-20G CL=6.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0472B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9e4-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMX-011054",
//     mfgPart: "MAMX-011054",
//     models: "this part is missing models",
//     pn: "XM-B9E4-0404D",
//     price: 25200,
//     priceType: "cents",
//     props: [15, 18000, 45000, 18000, 45000, 0, 20000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMX-011054.pdf",
//     description: "LOlvl=15dBm RF=18G-45G LO=18G-45G IF=DC-20G CL=6.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9e5-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMX-011054",
//     mfgPart: "MAMX-011054",
//     models: "this part is missing models",
//     pn: "XM-B9E5-0404D",
//     price: 25200,
//     priceType: "cents",
//     props: [15, 18000, 45000, 18000, 45000, 0, 20000, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MM1-0626SSM.pdf",
//     description: "LOlvl=20dBm RF=6G-26.5G LO=6G-26.5G IF=DC-9G CL=8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9g3-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0626ssm.aspx",
//     mfgPart: "MM1-0626SSM-2",
//     models: "this part is missing models",
//     pn: "XM-B9G3-0404D",
//     price: 26600,
//     priceType: "cents",
//     props: [20, 6000, 26500, 6000, 26500, 0, 9000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1625",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-0218HSM.pdf",
//     description: "LOlvl=20dBm RF=2G-18G LO=2G-18G IF=DC-3G CL=8dB ",
//     eccn: "",
//     ffNum: 24,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1625A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9h1-0609d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/iq-mixers/mmiq-0218hsm.aspx",
//     mfgPart: "MMIQ-0218HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B9H1-0609D",
//     price: 77500,
//     priceType: "cents",
//     props: [20, 2000, 18000, 2000, 18000, 0, 3000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1625_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1166",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8193.pdf",
//     description: "LOlvl=18dBm RF=2.5G-8.5G LO=2.5G-8.5G IF=DC-4G CL=9dB ",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1166A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9h2-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8193.html",
//     mfgPart: "HMC8193LC4",
//     models: "this part is missing models",
//     pn: "XM-B9H2-0409D",
//     price: 28000,
//     priceType: "cents",
//     props: [18, 2500, 8500, 2500, 8500, 0, 4000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1166_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1680",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc8193.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9h4-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc8193.html",
//     mfgPart: "HMC8193LC4",
//     models: "this is the new part",
//     pn: "XM-B9H4-0409D",
//     price: 28000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1680_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA-35H+.pdf",
//     description: "LOlvl=17dBm RF=500M-3.5G LO=500M-3.5G IF=10M-1.5G CL=6.6dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9m1-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA-35H%2B",
//     mfgPart: "MCA-35H+",
//     models: "this part is missing models",
//     pn: "XM-B9M1-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [17, 500, 3500, 500, 3500, 10, 1500, 6.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA-35MH+.pdf",
//     description: "LOlvl=13dBm RF=500M-3.5G LO=500M-3.5G IF=10M-1G CL=6.3dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9m2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA-35MH%2B",
//     mfgPart: "MCA-35MH+",
//     models: "this part is missing models",
//     pn: "XM-B9M2-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [13, 500, 3500, 500, 3500, 10, 1000, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA-50H+.pdf",
//     description: "LOlvl=17dBm RF=1G-5G LO=1G-5G IF=10M-1.5G CL=7.8dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9m3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA-50H%2B",
//     mfgPart: "MCA-50H+",
//     models: "this part is missing models",
//     pn: "XM-B9M3-0604D",
//     price: 15800,
//     priceType: "cents",
//     props: [17, 1000, 5000, 1000, 5000, 10, 1500, 7.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA-50LH+.pdf",
//     description: "LOlvl=10dBm RF=1G-5G LO=1G-5G IF=DC-1.5G CL=7.8dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9m4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA-50LH%2B",
//     mfgPart: "MCA-50LH+",
//     models: "this part is missing models",
//     pn: "XM-B9M4-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [10, 1000, 5000, 1000, 5000, 0, 1500, 7.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA-50MH+.pdf",
//     description: "LOlvl=13dBm RF=1G-5G LO=1G-5G IF=10M-1.5G CL=7.8dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9m5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA-50MH%2B",
//     mfgPart: "MCA-50MH+",
//     models: "this part is missing models",
//     pn: "XM-B9M5-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [13, 1000, 5000, 1000, 5000, 10, 1500, 7.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0505",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-0320HSM.pdf",
//     description: "LOlvl=17dBm RF=3.5G-20G LO=3.5G-20G IF=DC-4G CL=9dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0505B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9n2-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0320hsm.aspx",
//     mfgPart: "MM1-0320HSM-2",
//     models: "this part is missing models",
//     pn: "XM-B9N2-0404D",
//     price: 20600,
//     priceType: "cents",
//     props: [17, 3500, 20000, 3500, 20000, 0, 4000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0886",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc774achips.pdf",
//     description: "LOlvl=13dBm RF=7G-40G LO=7G-40G IF=DC-10G CL=10.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0886B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3v6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/hmc774a-die.html",
//     mfgPart: "HMC774A-DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C3V6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C3V6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [13, 7000, 40000, 7000, 40000, 0, 10000, 10.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0886_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1509",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc329aCHIPS.pdf",
//     description: "LOlvl=13dBm RF=22G-29.9G LO=22G-29G IF=DC-8G CL=11dB ",
//     eccn: "",
//     ffNum: 19,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1509A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3v9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc329a-die.html",
//     mfgPart: "HMC329A",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C3V9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C3V9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [13, 22000, 29000, 22000, 29900, 0, 8000, 11],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1509_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1545",
//     catId: "MXR",
//     ctlBoard: "XM-C6A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/5553f.pdf",
//     description: "LOlvl=0dBm RF=3G-20G LO=1G-20G IF=500M-9G CL=9dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1545A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c433-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/single-double-triple-balanced-mixers/ltc5553.html",
//     mfgPart: "LTC5553",
//     models: "this part is missing models",
//     pn: "XM-C433-0404D",
//     price: 20200,
//     priceType: "cents",
//     props: [0, 1000, 20000, 3000, 20000, 500, 9000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1545_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1729",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4d6-0404d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/mixers/xm-c4d6-0404d",
//     mfgPart: "TC2200",
//     models: "this is the new part",
//     pn: "XM-C4D6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1729_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1814",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4v3-0404d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/mixers/xm-c4v3-0404d",
//     mfgPart: "TC813",
//     models: "this is the new part",
//     pn: "XM-C4V3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1814_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0913",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-0626LSM.pdf",
//     description: "LOlvl=15dBm RF=6G-26G LO=6G-26G IF=DC-6G CL=12dB ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0913A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c561-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/iq-mixers/mmiq-0626lsm.aspx",
//     mfgPart: "MMIQ-0626LSM-2",
//     models: "this part is missing models",
//     pn: "XM-C561-0409D",
//     price: 44600,
//     priceType: "cents",
//     props: [15, 6000, 26000, 6000, 26000, 0, 6000, 12],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0913_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1460",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc329aCHIPS.pdf",
//     description: "LOlvl=13dBm RF=22G-29.9G LO=22G-29G IF=DC-8G CL=11dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1460A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c572-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc329a-die.html",
//     mfgPart: "HMC329A",
//     models: "this part is missing models",
//     pn: "XM-C572-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [13, 22000, 29000, 22000, 29900, 0, 8000, 11],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1460_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0191",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MT3-0113SCQG.pdf",
//     description: "RF=1.5G-13G LO=1.5G-13G IF=10M-7G ",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0191I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5e9-0406d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mt3-0113scqg.aspx",
//     mfgPart: "MT3-0113SCQG-2",
//     models: "this part is missing models",
//     pn: "XM-C5E9-0406D",
//     price: 53500,
//     priceType: "cents",
//     props: [0, 1500, 13000, 1500, 13000, 10, 7000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0191",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/T3-07.pdf",
//     description: "LOlvl=22dBm RF=1M-7G LO=1M-7G IF=1M-4G CL=8dB ",
//     eccn: "",
//     ffNum: 16,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0191I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5f1-0406d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3-07.aspx",
//     mfgPart: "T3-07LCQG-1",
//     models: "this part is missing models",
//     pn: "XM-C5F1-0406D",
//     price: 64600,
//     priceType: "cents",
//     props: [22, 1, 7000, 1, 7000, 1, 4000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0191",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/T3-07.pdf",
//     description: "RF=1M-7G LO=1M-7G IF=1M-4G ",
//     eccn: "",
//     ffNum: 16,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0191I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5f2-0406d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3-07.aspx",
//     mfgPart: "T3-07MCQG-1",
//     models: "this part is missing models",
//     pn: "XM-C5F2-0406D",
//     price: 64600,
//     priceType: "cents",
//     props: [0, 1, 7000, 1, 7000, 1, 4000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0191",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/T3-07.pdf",
//     description: "RF=1M-7G LO=1M-7G IF=1M-4G ",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0191I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5f3-0406d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3-07.aspx",
//     mfgPart: "T3-07LCQG-2",
//     models: "this part is missing models",
//     pn: "XM-C5F3-0406D",
//     price: 64600,
//     priceType: "cents",
//     props: [0, 1, 7000, 1, 7000, 1, 4000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0191",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/T3-07.pdf",
//     description: "RF=1M-7G LO=1M-7G IF=1M-4G ",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0191I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5f4-0406d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3-07.aspx",
//     mfgPart: "T3-07MCQG-2",
//     models: "this part is missing models",
//     pn: "XM-C5F4-0406D",
//     price: 64600,
//     priceType: "cents",
//     props: [0, 1, 7000, 1, 7000, 1, 4000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0191",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/T3-12.pdf",
//     description: "RF=10M-12G LO=10M-12G IF=1M-4G ",
//     eccn: "",
//     ffNum: 16,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0191I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5f5-0406d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3-12.aspx",
//     mfgPart: "T3-12LCQG-1",
//     models: "this part is missing models",
//     pn: "XM-C5F5-0406D",
//     price: 68400,
//     priceType: "cents",
//     props: [0, 10, 12000, 10, 12000, 1, 4000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0191",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/T3-12.pdf",
//     description: "RF=10M-12G LO=10M-12G IF=1M-4G ",
//     eccn: "",
//     ffNum: 16,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0191I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5f6-0406d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3-12.aspx",
//     mfgPart: "T3-12MCQG-1",
//     models: "this part is missing models",
//     pn: "XM-C5F6-0406D",
//     price: 68400,
//     priceType: "cents",
//     props: [0, 10, 12000, 10, 12000, 1, 4000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0191",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/T3-12.pdf",
//     description: "RF=10M-12G LO=10M-12G IF=1M-4G ",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0191I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5f7-0406d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3-12.aspx",
//     mfgPart: "T3-12LCQG-2",
//     models: "this part is missing models",
//     pn: "XM-C5F7-0406D",
//     price: 68400,
//     priceType: "cents",
//     props: [0, 10, 12000, 10, 12000, 1, 4000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0191",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/T3-12.pdf",
//     description: "RF=10M-12G LO=10M-12G IF=1M-4G ",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0191I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5f8-0406d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3-12.aspx",
//     mfgPart: "T3-12MCQG-2",
//     models: "this part is missing models",
//     pn: "XM-C5F8-0406D",
//     price: 68400,
//     priceType: "cents",
//     props: [0, 10, 12000, 10, 12000, 1, 4000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1262",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MT3L-0113HSM.pdf",
//     description: "LOlvl=24dBm RF=1.5G-13G LO=1.5G-13G IF=250M-5G CL=11.5dB ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1262A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5h6-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mt3l-0113hsm.aspx",
//     mfgPart: "MT3L-0113HSM-2",
//     models: "this part is missing models",
//     pn: "XM-C5H6-0404D",
//     price: 36000,
//     priceType: "cents",
//     props: [24, 1500, 13000, 1500, 13000, 250, 5000, 11.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1262_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1263",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MT3L-0113HSM.pdf",
//     description: "LOlvl=24dBm RF=1.5G-13G LO=1.5G-13G IF=250M-5G CL=11.5dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1263A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5h7-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mt3l-0113hsm.aspx",
//     mfgPart: "MT3L-0113HSM-2",
//     models: "this part is missing models",
//     pn: "XM-C5H7-0404D",
//     price: 36000,
//     priceType: "cents",
//     props: [24, 1500, 13000, 1500, 13000, 250, 5000, 11.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1263_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0913",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-0416HSM.pdf",
//     description: "LOlvl=20dBm RF=4G-16G LO=4G-16G IF=DC-6G CL=12dB ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0913A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5h8-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mmiq-0416hsm.aspx",
//     mfgPart: "MMIQ-0416HSM-2",
//     models: "this part is missing models",
//     pn: "XM-C5H8-0409D",
//     price: 34400,
//     priceType: "cents",
//     props: [20, 4000, 16000, 4000, 16000, 0, 6000, 12],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0913_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0913",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-0520HSM.pdf",
//     description: "LOlvl=19dBm RF=5G-20G LO=5G-20G IF=DC-6G CL=12dB ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0913A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5h9-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/iq-mixers/mmiq-0520hsm.aspx",
//     mfgPart: "MMIQ-0520HSM-2",
//     models: "this part is missing models",
//     pn: "XM-C5H9-0409D",
//     price: 38400,
//     priceType: "cents",
//     props: [19, 5000, 20000, 5000, 20000, 0, 6000, 12],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0913_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0913",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-0626HSM.pdf",
//     description: "LOlvl=19dBm RF=6G-26G LO=6G-26G IF=DC-6G CL=12dB ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0913A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5j1-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/iq-mixers/mmiq-0626hsm.aspx",
//     mfgPart: "MMIQ-0626HSM-2",
//     models: "this part is missing models",
//     pn: "XM-C5J1-0409D",
//     price: 38400,
//     priceType: "cents",
//     props: [19, 6000, 26000, 6000, 26000, 0, 6000, 12],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0913_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1789",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-1040S.pdf?v=061820",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c5j9-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/iq-mixers/mmiq-1040s.aspx",
//     mfgPart: "MMIQ-1040SCH-2",
//     models: "this is the new part",
//     pn: "XM-C5J9-0409D",
//     price: 100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1789_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1604",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-1467l.pdf",
//     description: "LOlvl=15dBm RF=14G-67G LO=14G-67G IF=DC-24G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1604A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5m2-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1467l.aspx",
//     mfgPart: "MM1-1467LCH-2",
//     models: "this part is missing models",
//     pn: "XM-C5M2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [15, 14000, 67000, 14000, 67000, 0, 24000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1604_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1604",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-1467h.pdf",
//     description: "LOlvl=15.5dBm RF=14G-67G LO=14G-67G IF=DC-24G CL=9dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1604A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5m3-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1467h.aspx",
//     mfgPart: "MM1-1467HCH-2",
//     models: "this part is missing models",
//     pn: "XM-C5M3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [15.5, 14000, 67000, 14000, 67000, 0, 24000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1604_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1124",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-1850h.pdf",
//     description: "LOlvl=14.5dBm RF=18G-50G LO=18G-50G IF=DC-20G CL=8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1124B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5m4-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1850h.aspx",
//     mfgPart: "MM1-1850HCH-2",
//     models: "this part is missing models",
//     pn: "XM-C5M4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [14.5, 18000, 50000, 18000, 50000, 0, 20000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1124_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1586",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/MT3H-0113LSM.pdf",
//     description: "LOlvl=15dBm RF=1.5G-13G LO=1.5G-13G IF=800M-8.5G CL=11.5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1586A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6j5-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mt3h-0113lsm.aspx",
//     mfgPart: "MT3H-0113LSM-2",
//     models: "this part is missing models",
//     pn: "XM-C6J5-0404D",
//     price: 37400,
//     priceType: "cents",
//     props: [15, 1500, 13000, 1500, 13000, 800, 8500, 11.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D3",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1625",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-0218LSM.pdf",
//     description: "LOlvl=17dBm RF=2G-18G LO=2G-18G IF=DC-3G CL=8dB ",
//     eccn: "",
//     ffNum: 24,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1625A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c769-0609d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/iq-mixers/mmiq-0218lsm.aspx",
//     mfgPart: "MMIQ-0218LSM-2",
//     models: "this part is missing models",
//     pn: "XM-C769-0609D",
//     price: 83400,
//     priceType: "cents",
//     props: [17, 2000, 18000, 2000, 18000, 0, 3000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1625_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1605",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-1467l.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c783-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1467l.aspx",
//     mfgPart: "MM1-1467LCH-1",
//     models: "this is the new part",
//     pn: "XM-C783-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1605_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1605",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-1467h.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c784-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1467h.aspx",
//     mfgPart: "MM1-1467HCH-1",
//     models: "this is the new part",
//     pn: "XM-C784-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1605_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1554",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/HMC8192LG.pdf",
//     description: "LOlvl=18dBm RF=20G-42G LO=20G-42G IF=DC-5G CL=11dB ",
//     eccn: "",
//     ffNum: 17,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1554A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c788-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc8192.html",
//     mfgPart: "HMC8192LG",
//     models: "this part is missing models",
//     pn: "XM-C788-0409D",
//     price: 35500,
//     priceType: "cents",
//     props: [18, 20000, 42000, 20000, 42000, 0, 5000, 11],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1487",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc521chips.pdf",
//     description: "LOlvl=15dBm RF=8.5G-11.7G LO=8.5G-11.7G IF=DC-3.5G CL=8dB ",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1487A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c794-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc521a.html",
//     mfgPart: "HMC521",
//     models: "this part is missing models",
//     pn: "XM-C794-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [15, 8500, 11700, 8500, 11700, 0, 3500, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1487_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1685",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MM1-0222HSM.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1685A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7b6-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0222hsm.aspx",
//     mfgPart: "MM1-0222HSM-2",
//     models: "this part is missing models",
//     pn: "XM-C7B6-0404D",
//     price: 29100,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1685_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1314",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAMX-011009.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7c8-0409d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAMX-011009",
//     mfgPart: "MAMX-011009",
//     models: "this is the new part",
//     pn: "XM-C7C8-0409D",
//     price: 23100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1166",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc521A.pdf",
//     description: "LOlvl=15dBm RF=8.5G-13.5G LO=8.5G-13.5G IF=DC-3.5G CL=9dB ",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1166A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7d2-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-and-image-reject-mixers/hmc521a.html",
//     mfgPart: "HMC521ALC4",
//     models: "this part is missing models",
//     pn: "XM-C7D2-0409D",
//     price: 24400,
//     priceType: "cents",
//     props: [15, 8500, 13500, 8500, 13500, 0, 3500, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1166_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1501",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-0320HSM.pdf",
//     description: "LOlvl=17dBm RF=3.5G-20G LO=3.5G-20G IF=DC-4G CL=9dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1501A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7j3-0604d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0320hsm.aspx",
//     mfgPart: "MM1-0320HSM-2",
//     models: "this part is missing models",
//     pn: "XM-C7J3-0604D",
//     price: 23300,
//     priceType: "cents",
//     props: [17, 3500, 20000, 3500, 20000, 0, 4000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1501_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1256",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 21,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1256A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7j5-1009d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "HMC521AC4/JSPQW-100+",
//     models: "this part is missing models",
//     pn: "XM-C7J5-1009D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1256_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1256",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 21,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1256A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7j6-1009d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "HMC521AC4/JSPQW-100+",
//     models: "this part is missing models",
//     pn: "XM-C7J6-1009D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1256_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1256",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 21,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1256A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7j7-1009d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "HMC521AC4/JSPQW-100+",
//     models: "this part is missing models",
//     pn: "XM-C7J7-1009D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "D9",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1256_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA-35H+.pdf",
//     description: "LOlvl=17dBm RF=500M-3.5G LO=500M-3.5G IF=10M-1.5G CL=6.6dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c823-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA-35H%2B",
//     mfgPart: "MCA-35H+",
//     models: "this part is missing models",
//     pn: "XM-C823-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [17, 500, 3500, 500, 3500, 10, 1500, 6.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA-35MH+.pdf",
//     description: "LOlvl=13dBm RF=500M-3.5G LO=500M-3.5G IF=10M-1G CL=6.3dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c824-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA-35MH%2B",
//     mfgPart: "MCA-35MH+",
//     models: "this part is missing models",
//     pn: "XM-C824-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [13, 500, 3500, 500, 3500, 10, 1000, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA-50H+.pdf",
//     description: "LOlvl=17dBm RF=1G-5G LO=1G-5G IF=10M-1.5G CL=7.8dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c825-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA-50H%2B",
//     mfgPart: "MCA-50H+",
//     models: "this part is missing models",
//     pn: "XM-C825-0604D",
//     price: 15800,
//     priceType: "cents",
//     props: [17, 1000, 5000, 1000, 5000, 10, 1500, 7.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA-50LH+.pdf",
//     description: "LOlvl=10dBm RF=1G-5G LO=1G-5G IF=DC-1.5G CL=7.8dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c826-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA-50LH%2B",
//     mfgPart: "MCA-50LH+",
//     models: "this part is missing models",
//     pn: "XM-C826-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [10, 1000, 5000, 1000, 5000, 0, 1500, 7.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA-50MH+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c827-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA-50MH%2B",
//     mfgPart: "MCA-50MH+",
//     models: "this part is missing models",
//     pn: "XM-C827-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "D6",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1681",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8j1-1009d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "HMC521AC4/JSPQW-100+",
//     models: "this is the new part",
//     pn: "XM-C8J1-1009D",
//     price: 41500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1681_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1681",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8j2-1009d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "HMC521AC4/JSPQW-100+",
//     models: "this is the new part",
//     pn: "XM-C8J2-1009D",
//     price: 40200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1681_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1681",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8j3-1009d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "HMC521AC4/JSPQW-100+",
//     models: "this is the new part",
//     pn: "XM-C8J3-1009D",
//     price: 40200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1681_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-24MH+.pdf",
//     description: "LOlvl=13dBm RF=300M-2.4G LO=300M-2.4G IF=DC-700M CL=6.1dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9a8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-24MH%2B",
//     mfgPart: "MCA1-24MH+",
//     models: "this part is missing models",
//     pn: "XM-C9A8-0604D",
//     price: 15300,
//     priceType: "cents",
//     props: [13, 300, 2400, 300, 2400, 0, 700, 6.1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-113H+.pdf",
//     description: "LOlvl=17dBm RF=3.8G-11G LO=3.8G-11G IF=DC-1.8G CL=6.8dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9a9-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-113H%2B",
//     mfgPart: "MCA1-113H+",
//     models: "this part is missing models",
//     pn: "XM-C9A9-0604D",
//     price: 16600,
//     priceType: "cents",
//     props: [17, 3800, 11000, 3800, 11000, 0, 1800, 6.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-113H+.pdf",
//     description: "LOlvl=17dBm RF=3.8G-11G LO=3.8G-11G IF=DC-1.8G CL=6.8dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9b1-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-113H%2B",
//     mfgPart: "MCA1-113H+",
//     models: "this part is missing models",
//     pn: "XM-C9B1-0604D",
//     price: 16600,
//     priceType: "cents",
//     props: [17, 3800, 11000, 3800, 11000, 0, 1800, 6.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-12G+.pdf",
//     description: "LOlvl=7dBm RF=3.8G-12G LO=3.8G-12G IF=DC-1.8G CL=6.2dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9b2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-12G%2B",
//     mfgPart: "MCA1-12G+",
//     models: "this part is missing models",
//     pn: "XM-C9B2-0604D",
//     price: 16000,
//     priceType: "cents",
//     props: [7, 3800, 12000, 3800, 12000, 0, 1800, 6.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-12G+.pdf",
//     description: "LOlvl=7dBm RF=3.8G-12G LO=3.8G-12G IF=DC-1.8G CL=6.2dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9b3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-12G%2B",
//     mfgPart: "MCA1-12G+",
//     models: "this part is missing models",
//     pn: "XM-C9B3-0604D",
//     price: 16000,
//     priceType: "cents",
//     props: [7, 3800, 12000, 3800, 12000, 0, 1800, 6.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-12GL+.pdf",
//     description: "LOlvl=4dBm RF=3.8G-12G LO=3.8G-12G IF=DC-1.5G CL=6.5dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9b4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-12GL%2B",
//     mfgPart: "MCA1-12GL+",
//     models: "this part is missing models",
//     pn: "XM-C9B4-0604D",
//     price: 16100,
//     priceType: "cents",
//     props: [4, 3800, 12000, 3800, 12000, 0, 1500, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-12GL+.pdf",
//     description: "LOlvl=4dBm RF=3.8G-12G LO=3.8G-12G IF=DC-1.5G CL=6.5dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9b5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-12GL%2B",
//     mfgPart: "MCA1-12GL+",
//     models: "this part is missing models",
//     pn: "XM-C9B5-0604D",
//     price: 16100,
//     priceType: "cents",
//     props: [4, 3800, 12000, 3800, 12000, 0, 1500, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-24+.pdf",
//     description: "LOlvl=7dBm RF=300M-2.4G LO=300M-2.4G IF=DC-700M CL=6.1dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9b6-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-24%2B",
//     mfgPart: "MCA1-24+",
//     models: "this part is missing models",
//     pn: "XM-C9B6-0604D",
//     price: 15200,
//     priceType: "cents",
//     props: [7, 300, 2400, 300, 2400, 0, 700, 6.1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-24+.pdf",
//     description: "LOlvl=7dBm RF=300M-2.4G LO=300M-2.4G IF=DC-700M CL=6.1dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9b7-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-24%2B",
//     mfgPart: "MCA1-24+",
//     models: "this part is missing models",
//     pn: "XM-C9B7-0604D",
//     price: 15200,
//     priceType: "cents",
//     props: [7, 300, 2400, 300, 2400, 0, 700, 6.1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-24LH+.pdf",
//     description: "LOlvl=10dBm RF=300M-2.4G LO=300M-2.4G IF=DC-700M CL=6.5dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9b8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-24LH%2B",
//     mfgPart: "MCA1-24LH+",
//     models: "this part is missing models",
//     pn: "XM-C9B8-0604D",
//     price: 15300,
//     priceType: "cents",
//     props: [10, 300, 2400, 300, 2400, 0, 700, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-24LH+.pdf",
//     description: "LOlvl=10dBm RF=300M-2.4G LO=300M-2.4G IF=DC-700M CL=6.5dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9b9-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-24LH%2B",
//     mfgPart: "MCA1-24LH+",
//     models: "this part is missing models",
//     pn: "XM-C9B9-0604D",
//     price: 15300,
//     priceType: "cents",
//     props: [10, 300, 2400, 300, 2400, 0, 700, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-42+.pdf",
//     description: "LOlvl=7dBm RF=1G-4.2G LO=1G-4.2G IF=DC-1.5G CL=6.1dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9c1-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-42%2B",
//     mfgPart: "MCA1-42+",
//     models: "this part is missing models",
//     pn: "XM-C9C1-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [7, 1000, 4200, 1000, 4200, 0, 1500, 6.1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-24MH+.pdf",
//     description: "LOlvl=13dBm RF=300M-2.4G LO=300M-2.4G IF=DC-700M CL=6.1dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9c2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-24MH%2B",
//     mfgPart: "MCA1-24MH+",
//     models: "this part is missing models",
//     pn: "XM-C9C2-0604D",
//     price: 15300,
//     priceType: "cents",
//     props: [13, 300, 2400, 300, 2400, 0, 700, 6.1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-42+.pdf",
//     description: "LOlvl=7dBm RF=1G-4.2G LO=1G-4.2G IF=DC-1.5G CL=6.1dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9c3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-42%2B",
//     mfgPart: "MCA1-42+",
//     models: "this part is missing models",
//     pn: "XM-C9C3-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [7, 1000, 4200, 1000, 4200, 0, 1500, 6.1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-42LH+.pdf",
//     description: "LOlvl=10dBm RF=1G-4.2G LO=1G-4.2G IF=DC-1.5G CL=6dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9c4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-42LH%2B",
//     mfgPart: "MCA1-42LH+",
//     models: "this part is missing models",
//     pn: "XM-C9C4-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [10, 1000, 4200, 1000, 4200, 0, 1500, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-42LH+.pdf",
//     description: "LOlvl=10dBm RF=1G-4.2G LO=1G-4.2G IF=DC-1.5G CL=6dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9c5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-42LH%2B",
//     mfgPart: "MCA1-42LH+",
//     models: "this part is missing models",
//     pn: "XM-C9C5-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [10, 1000, 4200, 1000, 4200, 0, 1500, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-42MH+.pdf",
//     description: "LOlvl=13dBm RF=1G-4.2G LO=1G-4.2G IF=DC-1.5G CL=6.2dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9c6-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-42MH%2B",
//     mfgPart: "MCA1-42MH+",
//     models: "this part is missing models",
//     pn: "XM-C9C6-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [13, 1000, 4200, 1000, 4200, 0, 1500, 6.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-42MH+.pdf",
//     description: "LOlvl=13dBm RF=1G-4.2G LO=1G-4.2G IF=DC-1.5G CL=6.2dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9c7-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-42MH%2B",
//     mfgPart: "MCA1-42MH+",
//     models: "this part is missing models",
//     pn: "XM-C9C7-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [13, 1000, 4200, 1000, 4200, 0, 1500, 6.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-60+.pdf",
//     description: "LOlvl=7dBm RF=1.6G-6G LO=1.6G-6G IF=DC-2G CL=6.3dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9c8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-60%2B",
//     mfgPart: "MCA1-60+",
//     models: "this part is missing models",
//     pn: "XM-C9C8-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [7, 1600, 6000, 1600, 6000, 0, 2000, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-60+.pdf",
//     description: "LOlvl=7dBm RF=1.6G-6G LO=1.6G-6G IF=DC-2G CL=6.3dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9c9-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-60%2B",
//     mfgPart: "MCA1-60+",
//     models: "this part is missing models",
//     pn: "XM-C9C9-0604D",
//     price: 15500,
//     priceType: "cents",
//     props: [7, 1600, 6000, 1600, 6000, 0, 2000, 6.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-60LH+.pdf",
//     description: "LOlvl=10dBm RF=1.7G-6G LO=1.7G-6G IF=DC-2G CL=6.2dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9d1-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-60LH%2B",
//     mfgPart: "MCA1-60LH+",
//     models: "this part is missing models",
//     pn: "XM-C9D1-0604D",
//     price: 15600,
//     priceType: "cents",
//     props: [10, 1700, 6000, 1700, 6000, 0, 2000, 6.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-60LH+.pdf",
//     description: "LOlvl=10dBm RF=1.7G-6G LO=1.7G-6G IF=DC-2G CL=6.2dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9d2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-60LH%2B",
//     mfgPart: "MCA1-60LH+",
//     models: "this part is missing models",
//     pn: "XM-C9D2-0604D",
//     price: 15600,
//     priceType: "cents",
//     props: [10, 1700, 6000, 1700, 6000, 0, 2000, 6.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-60MH+.pdf",
//     description: "LOlvl=13dBm RF=1.6G-6G LO=1.6G-6G IF=DC-2G CL=6.9dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9d3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-60MH%2B",
//     mfgPart: "MCA1-60MH+",
//     models: "this part is missing models",
//     pn: "XM-C9D3-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [13, 1600, 6000, 1600, 6000, 0, 2000, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-60MH+.pdf",
//     description: "LOlvl=13dBm RF=1.6G-6G LO=1.6G-6G IF=DC-2G CL=6.9dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9d4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-60MH%2B",
//     mfgPart: "MCA1-60MH+",
//     models: "this part is missing models",
//     pn: "XM-C9D4-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [13, 1600, 6000, 1600, 6000, 0, 2000, 6.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-80H+.pdf",
//     description: "LOlvl=17dBm RF=5G-8G LO=5G-8G IF=DC-1.25G CL=6.5dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9d5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-80H%2B",
//     mfgPart: "MCA1-80H+",
//     models: "this part is missing models",
//     pn: "XM-C9D5-0604D",
//     price: 16100,
//     priceType: "cents",
//     props: [17, 5000, 8000, 5000, 8000, 0, 1250, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-80H+.pdf",
//     description: "LOlvl=17dBm RF=5G-8G LO=5G-8G IF=DC-1.25G CL=6.5dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9d6-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-80H%2B",
//     mfgPart: "MCA1-80H+",
//     models: "this part is missing models",
//     pn: "XM-C9D6-0604D",
//     price: 16100,
//     priceType: "cents",
//     props: [17, 5000, 8000, 5000, 8000, 0, 1250, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-80LH+.pdf",
//     description: "LOlvl=10dBm RF=2.8G-8G LO=2.8G-8G IF=DC-1.25G CL=6dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9d7-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-80LH%2B",
//     mfgPart: "MCA1-80LH+",
//     models: "this part is missing models",
//     pn: "XM-C9D7-0604D",
//     price: 15800,
//     priceType: "cents",
//     props: [10, 2800, 8000, 2800, 8000, 0, 1250, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-80LH+.pdf",
//     description: "LOlvl=10dBm RF=2.8G-8G LO=2.8G-8G IF=DC-1.25G CL=6dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9d8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-80LH%2B",
//     mfgPart: "MCA1-80LH+",
//     models: "this part is missing models",
//     pn: "XM-C9D8-0604D",
//     price: 15800,
//     priceType: "cents",
//     props: [10, 2800, 8000, 2800, 8000, 0, 1250, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-80MH+.pdf",
//     description: "LOlvl=13dBm RF=2.8G-8G LO=2.8G-8G IF=DC-1.25G CL=5.7dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9d9-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-80MH%2B",
//     mfgPart: "MCA1-80MH+",
//     models: "this part is missing models",
//     pn: "XM-C9D9-0604D",
//     price: 16000,
//     priceType: "cents",
//     props: [13, 2800, 8000, 2800, 8000, 0, 1250, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-80MH+.pdf",
//     description: "LOlvl=13dBm RF=2.8G-8G LO=2.8G-8G IF=DC-1.25G CL=5.7dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9e1-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-80MH%2B",
//     mfgPart: "MCA1-80MH+",
//     models: "this part is missing models",
//     pn: "XM-C9E1-0604D",
//     price: 16000,
//     priceType: "cents",
//     props: [13, 2800, 8000, 2800, 8000, 0, 1250, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1302",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-85+.pdf",
//     description: "LOlvl=7dBm RF=2.8G-8.5G LO=8.5G-8.5G IF=DC-1.25G CL=5.7dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9e2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-85%2B",
//     mfgPart: "MCA1-85+",
//     models: "this part is missing models",
//     pn: "XM-C9E2-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [7, 8500, 8500, 2800, 8500, 0, 1250, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-85+.pdf",
//     description: "LOlvl=7dBm RF=2.8G-8.5G LO=8.5G-8.5G IF=DC-1.25G CL=5.7dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9e3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-85%2B",
//     mfgPart: "MCA1-85+",
//     models: "this part is missing models",
//     pn: "XM-C9E3-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [7, 8500, 8500, 2800, 8500, 0, 1250, 5.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "LOlvl=4dBm RF=2.8G-8.5G LO=2.8G-8.5G IF=DC-1.2G CL=6dB ",
//     eccn: "",
//     ffNum: 22,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1302A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9e4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-85L%2B",
//     mfgPart: "MCA1-85L+",
//     models: "this part is missing models",
//     pn: "XM-C9E4-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [4, 2800, 8500, 2800, 8500, 0, 1200, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1302_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1303",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MCA1-85L+.pdf",
//     description: "LOlvl=4dBm RF=2.8G-8.5G LO=2.8G-8.5G IF=DC-1.2G CL=6dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1303A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9e5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MCA1-85L%2B",
//     mfgPart: "MCA1-85L+",
//     models: "this part is missing models",
//     pn: "XM-C9E5-0604D",
//     price: 15700,
//     priceType: "cents",
//     props: [4, 2800, 8500, 2800, 8500, 0, 1200, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1303_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1612",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MDB-44H+.pdf",
//     description: "LOlvl=15dBm RF=10G-40G LO=10G-40G IF=DC-15G CL=8.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1612A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9e6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MDB-44H%2B",
//     mfgPart: "MDB-44H+",
//     models: "this part is missing models",
//     pn: "XM-C9E6-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [15, 10000, 40000, 10000, 40000, 0, 15000, 8.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1612_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1065",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/MDB-44H+.pdf",
//     description: "LOlvl=15dBm RF=10G-40G LO=10G-40G IF=DC-15G CL=8.4dB ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1065A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9e7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MDB-44H%2B",
//     mfgPart: "MDB-44H+",
//     models: "this part is missing models",
//     pn: "XM-C9E7-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [15, 10000, 40000, 10000, 40000, 0, 15000, 8.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1065_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1953",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/mm1-0832lsm.pdf",
//     description: "LOlvl=12dBm RF=8G-32G LO=800M-30G IF=DC-12G CL=9dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1953A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9k2-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0832lsm.aspx",
//     mfgPart: "MM1-0832LSM-2",
//     models: "this part is missing models",
//     pn: "XM-C9K2-0404D",
//     price: 31200,
//     priceType: "cents",
//     props: [12, 800, 30000, 8000, 32000, 0, 12000, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1953_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0192",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/ml1-0110sm.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9n5-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/ml1-0110sm.aspx",
//     mfgPart: "ML1-0110SSM-2",
//     models: "this is the new part",
//     pn: "XM-C9N5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1950",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/ml1-0110sm.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9n6-0604d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/ml1-0110sm.aspx",
//     mfgPart: "ML1-0110HSM-2",
//     models: "this is the new part",
//     pn: "XM-C9N6-0604D",
//     price: 26300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1950_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0192",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/ml1-0110sm.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9n7-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/ml1-0110sm.aspx",
//     mfgPart: "ML1-0110LSM-1",
//     models: "this is the new part",
//     pn: "XM-C9N7-0404D",
//     price: 23300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0192",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/ml1-0110sm.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9n8-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/ml1-0110sm.aspx",
//     mfgPart: "ML1-0110SSM-1",
//     models: "this is the new part",
//     pn: "XM-C9N8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0192",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/ml1-0110sm.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9n9-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/ml1-0110sm.aspx",
//     mfgPart: "ML1-0110HSM-1",
//     models: "this is the new part",
//     pn: "XM-C9N9-0404D",
//     price: 23300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1892",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-0218LSM.pdf",
//     description: "LOlvl=17dBm RF=2G-18G LO=2G-18G IF=DC-3G CL=8dB ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1892A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9p7-0609d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/iq-mixers/mmiq-0218lsm.aspx",
//     mfgPart: "MMIQ-0218LSM-2",
//     models: "this part is missing models",
//     pn: "XM-C9P7-0609D",
//     price: 83400,
//     priceType: "cents",
//     props: [17, 2000, 18000, 2000, 18000, 0, 3000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1892_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1892",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMIQ-0218HSM.pdf",
//     description: "LOlvl=20dBm RF=2G-18G LO=2G-18G IF=DC-3G CL=8dB ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1892A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9p8-0609d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/iq-mixers/mmiq-0218hsm.aspx",
//     mfgPart: "MMIQ-0218HSM-2",
//     models: "this part is missing models",
//     pn: "XM-C9P8-0609D",
//     price: 77400,
//     priceType: "cents",
//     props: [20, 2000, 18000, 2000, 18000, 0, 3000, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1892_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0193",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/T3-18G.pdf",
//     description: "LOlvl=15dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0193I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9t1-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3-18g.aspx",
//     mfgPart: "T3-18GLCTG-2",
//     models: "this part is missing models",
//     pn: "XM-C9T1-0404D",
//     price: 88600,
//     priceType: "cents",
//     props: [15, 0, 0, 0, 0, 0, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0193",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/T3-18G.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9t2-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/t3-18g.aspx",
//     mfgPart: "T3-18GLCTG-1",
//     models: "this is the new part",
//     pn: "XM-C9T2-0404D",
//     price: 88600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "LOlvl=4dBm RF=21G-27G LO=10.5G-14.5G IF=DC-3.75G ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0430B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc815b.html",
//     mfgPart: "HMC815BLC5",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3T5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3T5-0609D",
//     price: 34300,
//     priceType: "cents",
//     props: [4, 10500, 14500, 21000, 27000, 0, 3750, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0430_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/LTC5552.html",
//     mfgPart: "LTC5552",
//     models: "this is the new part",
//     pn: "XM-D1A4-0604D",
//     price: 20500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "2212_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "ML1-0110LSM-2",
//     models: "this is the new part",
//     pn: "XM-A347-0404D",
//     price: 40000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0196_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "MDB-24H+",
//     models: "this is the new part",
//     pn: "XM-D1J5-0404D",
//     price: 13100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-2567lsm.aspx",
//     mfgPart: "MM1-2567LSM-2",
//     models: "this is the new part",
//     pn: "XM-D413-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1870_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1850hsm.aspx",
//     mfgPart: "MM1-1850HSM-2",
//     models: "this is the new part",
//     pn: "XM-D414-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "1924_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-1453lsm.aspx",
//     mfgPart: "MM1-1453LSM-2",
//     models: "this is the new part",
//     pn: "XM-D134-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "2153_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/iq-mixers/mmiq-1867lsm.aspx",
//     mfgPart: "MMIQ-1867LSM-2",
//     models: "this is the new part",
//     pn: "XM-D256-0409D",
//     price: 74200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "2010_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/iq-mixers/mmiq-0626hsm.aspx",
//     mfgPart: "MMIQ-0626HSM-2",
//     models: "this is the new part",
//     pn: "XM-D228-0409D",
//     price: 38400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "2325_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MXR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/mixers/mm1-0832hpsm.aspx",
//     mfgPart: "MM1-0832HPSM",
//     models: "this is the new part",
//     pn: "XM-D2L4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Mixers",
//     newBRDImage: "0472_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0382",
//     catId: "SYN",
//     ctlBoard: "XM-A3R9-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4355-2.pdf",
//     description: "F=54M-4.4G Pout=5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0382D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3r8-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4355-2.html",
//     mfgPart: "ADF4355-2BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A3R8-0409D",
//     price: 25900,
//     priceType: "cents",
//     props: [54, 4400, null, null, null, 5, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0382_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0382",
//     catId: "SYN",
//     ctlBoard: "XM-A3R9-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4355-2.pdf",
//     description: "F=54M-4.4G Pout=5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0382D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3r8-0409d-01/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4355-2.html",
//     mfgPart: "ADF4355-2BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A3R8-0409D-01",
//     price: 0,
//     priceType: "cents",
//     props: [54, 4400, null, null, null, 5, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0382_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1007",
//     catId: "SYN",
//     ctlBoard: "XM-B5K5-0609D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/adf4372.pdf",
//     description: "F=62.5M-16G ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1007B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a539-0609d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adf4372.html",
//     mfgPart: "ADF4372SMT",
//     models: "this part is missing models",
//     pn: "XM-A539-0609D",
//     price: 38500,
//     priceType: "cents",
//     props: [62.5, 16000, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "1007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0382",
//     catId: "SYN",
//     ctlBoard: "XM-A3R9-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4355.pdf",
//     description: "F=54M-6.8G Pout=3dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0382D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5m4-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4355.html",
//     mfgPart: "ADF4355BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5M4-0409D",
//     price: 27900,
//     priceType: "cents",
//     props: [54, 6800, null, null, null, 3, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0382_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0382",
//     catId: "SYN",
//     ctlBoard: "XM-A3T4-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4355-3.pdf",
//     description: "F=51.5625M-6.6G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0382D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5m6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4355-3.html",
//     mfgPart: "ADF4355-3BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5M6-0409D",
//     price: 27700,
//     priceType: "cents",
//     props: [51.5625, 6600, null, null, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0382_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0564",
//     catId: "SYN",
//     ctlBoard: "XM-A5R5-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4360-0.pdf",
//     description: "F=2.4G-2.725G Pout=-6.5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0564B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5p5-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4360-0.html",
//     mfgPart: "ADF4360-0SMT",
//     models: "this part is missing models",
//     pn: "XM-A5P5-0409D",
//     price: 12200,
//     priceType: "cents",
//     props: [2400, 2725, null, null, null, -6.5, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0564",
//     catId: "SYN",
//     ctlBoard: "XM-A5R5-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4360-1.pdf",
//     description: "F=2.05G-2.45G Pout=-6dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0564B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5p6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4360-1.html",
//     mfgPart: "ADF4360-1SMT",
//     models: "this part is missing models",
//     pn: "XM-A5P6-0409D",
//     price: 19300,
//     priceType: "cents",
//     props: [2050, 2450, null, null, null, -6, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0564",
//     catId: "SYN",
//     ctlBoard: "XM-A5R5-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4360-2.pdf",
//     description: "F=1.85G-2.17G Pout=-6dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0564B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5p7-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4360-2.html",
//     mfgPart: "ADF4360-2SMT",
//     models: "this part is missing models",
//     pn: "XM-A5P7-0409D",
//     price: 18600,
//     priceType: "cents",
//     props: [1850, 2170, null, null, null, -6, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0564",
//     catId: "SYN",
//     ctlBoard: "XM-A5R5-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4360-3.pdf",
//     description: "F=1.6G-1.95G Pout=-3dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0564B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5p8-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4360-3.html",
//     mfgPart: "ADF4360-3SMT",
//     models: "this part is missing models",
//     pn: "XM-A5P8-0409D",
//     price: 18200,
//     priceType: "cents",
//     props: [1600, 1950, null, null, null, -3, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0564",
//     catId: "SYN",
//     ctlBoard: "XM-A5R5-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4360-4.pdf",
//     description: "F=1.45G-1.75G Pout=-4dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0564B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5p9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4360-4.html",
//     mfgPart: "ADF4360-4SMT",
//     models: "this part is missing models",
//     pn: "XM-A5P9-0409D",
//     price: 12200,
//     priceType: "cents",
//     props: [1450, 1750, null, null, null, -4, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0564",
//     catId: "SYN",
//     ctlBoard: "XM-A5R5-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4360-5.pdf",
//     description: "F=1.2G-1.4G Pout=-4.5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0564B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5r1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4360-5.html",
//     mfgPart: "ADF4360-5SMT",
//     models: "this part is missing models",
//     pn: "XM-A5R1-0409D",
//     price: 12200,
//     priceType: "cents",
//     props: [1200, 1400, null, null, null, -4.5, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0564",
//     catId: "SYN",
//     ctlBoard: "XM-A5R5-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4360-6.pdf",
//     description: "F=1.05G-1.25G Pout=-4.5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0564B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5r2-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4360-6.html",
//     mfgPart: "ADF4360-6SMT",
//     models: "this part is missing models",
//     pn: "XM-A5R2-0409D",
//     price: 12200,
//     priceType: "cents",
//     props: [1050, 1250, null, null, null, -4.5, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0564",
//     catId: "SYN",
//     ctlBoard: "XM-A5R5-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4360-7.pdf",
//     description: "F=350M-1.8G Pout=-5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0564B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5r3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4360-7.html",
//     mfgPart: "ADF4360-7SMT",
//     models: "this part is missing models",
//     pn: "XM-A5R3-0409D",
//     price: 19400,
//     priceType: "cents",
//     props: [350, 1800, null, null, null, -5, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0564",
//     catId: "SYN",
//     ctlBoard: "XM-A5R5-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4360-8.pdf",
//     description: "F=65M-400M Pout=0dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0564B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5r4-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4360-8.html",
//     mfgPart: "ADF4360-8SMT",
//     models: "this part is missing models",
//     pn: "XM-A5R4-0409D",
//     price: 12300,
//     priceType: "cents",
//     props: [65, 400, null, null, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0564",
//     catId: "SYN",
//     ctlBoard: "XM-A5R5-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4360-9.pdf",
//     description: "F=65M-400M ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0564B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5r6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4360-9.html",
//     mfgPart: "ADF4360-9SMT",
//     models: "this part is missing models",
//     pn: "XM-A5R6-0409D",
//     price: 18600,
//     priceType: "cents",
//     props: [65, 400, null, null, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0382",
//     catId: "SYN",
//     ctlBoard: "XM-A3R9-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4356.pdf",
//     description: "F=53.125M-6.8G Pout=2dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0382D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5y8-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4356.html",
//     mfgPart: "ADF4356SMT",
//     models: "this part is missing models",
//     pn: "XM-A5Y8-0409D",
//     price: 27200,
//     priceType: "cents",
//     props: [53.125, 6800, null, null, null, 2, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0382_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0382",
//     catId: "SYN",
//     ctlBoard: "XM-A3R9-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF5356.pdf",
//     description: "F=53.125M-13.6G Pout=2dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0382D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5y9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf5356.html",
//     mfgPart: "ADF5356SMT",
//     models: "this part is missing models",
//     pn: "XM-A5Y9-0409D",
//     price: 34100,
//     priceType: "cents",
//     props: [53.125, 13600, null, null, null, 2, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0382_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0994",
//     catId: "SYN",
//     ctlBoard: "XM-B6C2-0609D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc833.pdf",
//     description: "F=25M-6G ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0994A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6d8-0609d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/hmc833.html",
//     mfgPart: "HMC833LP6GESMT",
//     models: "this part is missing models",
//     pn: "XM-A6D8-0609D",
//     price: 33000,
//     priceType: "cents",
//     props: [25, 6000, null, null, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0994_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0734",
//     catId: "SYN",
//     ctlBoard: "XM-A725-0609D",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lmx2594.pdf",
//     description: "F=10M-15G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0734A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a724-0609d/",
//     mfgCode: "TXINS",
//     mfgLandingPage:
//       "http://www.ti.com/product/LMX2594?keyMatch=LMX2594RHAT&tisearch=Search-EN-Everything",
//     mfgPart: "LMX2594RHATSMT",
//     models: "this part is missing models",
//     pn: "XM-A724-0609D",
//     price: 0,
//     priceType: "cents",
//     props: [10, 15000, null, null, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0734_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0382",
//     catId: "SYN",
//     ctlBoard: "XM-A3R9-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF5355.pdf",
//     description: "F=54M-13.6G Pout=-3dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0382D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a741-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf5355.html",
//     mfgPart: "ADF5355BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A741-0409D",
//     price: 33400,
//     priceType: "cents",
//     props: [54, 13600, null, null, null, -3, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0382_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0604",
//     catId: "SYN",
//     ctlBoard: "XM-A8F1-0609D",
//     datasheetURL: "https://www.idt.com/document/dst/8v97051l-datasheet",
//     description: "F=34.375M-4.4G Pout=7dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0604A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7f3-0609d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/clocks-timing/application-specific-clocks/rf-jesd204b-timing/rf-synthesizers-plls/8v97051l-low-power-wideband-fractional-rf-synthesizer",
//     mfgPart: "8V97051LSMT",
//     models: "this part is missing models",
//     pn: "XM-A7F3-0609D",
//     price: 23300,
//     priceType: "cents",
//     props: [34.375, 4400, null, null, null, 7, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0604",
//     catId: "SYN",
//     ctlBoard: "XM-A8F1-0609D",
//     datasheetURL: "https://www.idt.com/document/dst/8v97053l-datasheet",
//     description: "F=34.375M-4.4G Pout=7dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0604A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7f4-0609d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/clocks-timing/application-specific-clocks/rf-jesd204b-timing/rf-synthesizers-plls/8v97053l-high-performance-wideband-fractional-rf-synthesizer-pll",
//     mfgPart: "8V97053LSMT",
//     models: "this part is missing models",
//     pn: "XM-A7F4-0609D",
//     price: 15300,
//     priceType: "cents",
//     props: [34.375, 4400, null, null, null, 7, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0734",
//     catId: "SYN",
//     ctlBoard: "XM-A725-0609D",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lmx2572.pdf",
//     description: "F=12.5M-6.4G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0734A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7l2-0609d/",
//     mfgCode: "TXINS",
//     mfgLandingPage:
//       "http://www.ti.com/product/LMX2572?keyMatch=LMX-2572&tisearch=Search-EN-Everything",
//     mfgPart: "LMX2572RHATSMT",
//     models: "this part is missing models",
//     pn: "XM-A7L2-0609D",
//     price: 0,
//     priceType: "cents",
//     props: [12.5, 6400, null, null, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0734_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0734",
//     catId: "SYN",
//     ctlBoard: "XM-A725-0609D",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lmx2595.pdf",
//     description: "F=100M-20G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0734A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7m3-0609d/",
//     mfgCode: "TXINS",
//     mfgLandingPage:
//       "http://www.ti.com/product/LMX2595?keyMatch=LMX2595RHAT&tisearch=Search-EN-Everything",
//     mfgPart: "LMX2595RHATSMT",
//     models: "this part is missing models",
//     pn: "XM-A7M3-0609D",
//     price: 0,
//     priceType: "cents",
//     props: [100, 20000, null, null, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0734_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0734",
//     catId: "SYN",
//     ctlBoard: "XM-A725-0609D",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lmx2592.pdf",
//     description: "F=20M-9.8G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0734A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1d9-0609d/",
//     mfgCode: "TXINS",
//     mfgLandingPage:
//       "http://www.ti.com/product/lmx2592/description?jktype=didyoumean",
//     mfgPart: "LMX2592RHATSMT",
//     models: "this part is missing models",
//     pn: "XM-B1D9-0609D",
//     price: 30000,
//     priceType: "cents",
//     props: [20, 9800, null, null, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0734_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0995",
//     catId: "SYN",
//     ctlBoard: "XM-B5K5-0609D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adf4371.pdf",
//     description: "F=8G-32G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0995C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5c2-0609d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4371.html",
//     mfgPart: "ADF4371SMT",
//     models: "this part is missing models",
//     pn: "XM-B5C2-0609D",
//     price: 67200,
//     priceType: "cents",
//     props: [8000, 32000, null, null, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0995_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1007",
//     catId: "SYN",
//     ctlBoard: "XM-B5K5-0609D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/adf4371.pdf",
//     description: "F=62.5M-16G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1007B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5c3-0609d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4371.html",
//     mfgPart: "ADF4371SMT",
//     models: "this part is missing models",
//     pn: "XM-B5C3-0609D",
//     price: 66900,
//     priceType: "cents",
//     props: [62.5, 16000, null, null, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "1007_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0382",
//     catId: "SYN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADF4355-3.pdf",
//     description: "F=51.5625M-6.6G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0382D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5p7-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adf4355-3.html",
//     mfgPart: "ADF4355-3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5P7-0409D",
//     price: 27700,
//     priceType: "cents",
//     props: [51.5625, 6600, null, null, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0382_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1025",
//     catId: "SYN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4360-7.pdf",
//     description: "F=350M-1.8G Pout=-5dBm ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1025A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5r2-0408d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/phase-locked-loop-w-integrated-vco/adf4360-7.html",
//     mfgPart: "ADF4360-7SMT",
//     models: "this part is missing models",
//     pn: "XM-B5R2-0408D",
//     price: 19500,
//     priceType: "cents",
//     props: [350, 1800, null, null, null, -5, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0609B.png|1348",
//     catId: "SYN",
//     ctlBoard: "XM-C9J6-0609D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADF5610.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c558-0609c-01/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LMX2820",
//     mfgPart: "LMX2820RTCT",
//     models: "this is the new part",
//     pn: "XM-C9F7-0609D",
//     price: 43100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "2093_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SYN",
//     ctlBoard: "XM-B5K5-0609D",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/adf4371.html#product-overview",
//     mfgPart: "ADF4371BCCZ",
//     models: "this is the new part",
//     pn: "XM-D1G9-0609D",
//     price: 67400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Lock Loops with Int VCO",
//     newBRDImage: "0995_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1525+.pdf",
//     description: "CF=1.525G BW=320 20dBRej@1290&2230MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a162-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1525%2B",
//     mfgPart: "BFCN-1525+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A162D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A162-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1525, 320, 2.18, 1290, 2230, 1122, 3230],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1840+.pdf",
//     description: "CF=1.84G BW=500 20dBRej@1493&2950MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a163-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1840%2B",
//     mfgPart: "BFCN-1840+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A163D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A163-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1840, 500, 1.79, 1493, 2950, 1360, 4130],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1945+.pdf",
//     description: "CF=1.945G BW=450 20dBRej@1560&3012MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a164-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1945%2B",
//     mfgPart: "BFCN-1945+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A164D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A164-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1945, 450, 1.7, 1560, 3012, 1425, 4171],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2275+.pdf",
//     description: "CF=2.275G BW=530 20dBRej@1770&3555MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a165-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2275%2B",
//     mfgPart: "BFCN-2275+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A165D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A165-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2275, 530, 1.56, 1770, 3555, 1575, 4700],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2555+.pdf",
//     description: "CF=2.555G BW=511 20dBRej@2170&2960MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a166-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2555%2B",
//     mfgPart: "BFCN-2555+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A166D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A166-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2555, 511, 3.73, 2170, 2960, 1820, 3600],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2700+.pdf",
//     description: "CF=2.7G BW=490 20dBRej@2306&3080MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a167-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2700%2B",
//     mfgPart: "BFCN-2700+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A167D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A167-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2700, 490, 1.95, 2306, 3080, 603, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2850+.pdf",
//     description: "CF=2.85G BW=600 20dBRej@2460&3300MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a168-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2850%2B",
//     mfgPart: "BFCN-2850+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A168D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A168-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2850, 600, 1.81, 2460, 3300, 620, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2900+.pdf",
//     description: "CF=2.9G BW=1000 20dBRej@1960&3800MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a169-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2900%2B",
//     mfgPart: "BFCN-2900+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A169D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A169-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2900, 1000, 1.65, 1960, 3800, 1580, 5015],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-3010+.pdf",
//     description: "CF=3.01G BW=500 20dBRej@2650&2370MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a171-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-3010%2B",
//     mfgPart: "BFCN-3010+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A171D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A171-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [3010, 500, 2.56, 2650, 2370, 700, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-3600+.pdf",
//     description: "CF=3.6G BW=1630 20dBRej@1930&4950MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a172-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-3600%2B",
//     mfgPart: "BFCN-3600+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A172D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A172-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [3600, 1630, 1.3, 1930, 4950, 1100, 5580],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-4100+.pdf",
//     description: "CF=4.1G BW=1630 20dBRej@2400&5725MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a173-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-4100%2B",
//     mfgPart: "BFCN-4100+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A173D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A173-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [4100, 1630, 0.96, 2400, 5725, 1350, 13100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-4440+.pdf",
//     description: "CF=4.44G BW=2000 20dBRej@2200&6300MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a174-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-4440%2B",
//     mfgPart: "BFCN-4440+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A174D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A174-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [4440, 2000, 0.9, 2200, 6300, 1200, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-7200+.pdf",
//     description: "CF=7.2G BW=860 20dBRej@6520&8400MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a175-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-7200%2B",
//     mfgPart: "BFCN-7200+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A175D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A175-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [7200, 860, 1.83, 6520, 8400, 116, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-7500+.pdf",
//     description: "CF=7.5G BW=1150 20dBRej@6640&8890MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a176-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-7500%2B",
//     mfgPart: "BFCN-7500+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A176D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A176-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [7500, 1150, 1.53, 6640, 8890, 220, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-7700+.pdf",
//     description: "CF=7.7G BW=1250 20dBRej@6730&9110MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a177-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-7700%2B",
//     mfgPart: "BFCN-7700+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A177D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A177-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [7700, 1250, 1.7, 6730, 9110, 6590, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-8000+.pdf",
//     description: "CF=8G BW=1000 20dBRej@7140&9350MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a178-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-8000%2B",
//     mfgPart: "BFCN-8000+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A178D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A178-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [8000, 1000, 1.6, 7140, 9350, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-8450+.pdf",
//     description: "CF=8.45G BW=1100 20dBRej@7480&9885MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a179-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-8450%2B",
//     mfgPart: "BFCN-8450+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A179D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A179-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [8450, 1100, 1.54, 7480, 9885, 7280, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0029_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-8650+.pdf",
//     description: "CF=8.65G BW=1150 20dBRej@7600&10000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a181-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-8650%2B",
//     mfgPart: "BFCN-8650+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A181D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A181-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [8650, 1150, 1.9, 7600, 10000, 200, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0079",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B148QF0S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=15G BW=7950 20dBRej@10100&20110MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0079H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a265-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B148QF0SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A265D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A265-0604D",
//     price: 30600,
//     priceType: "cents",
//     props: [15000, 7950, 1.09, 10100, 20110, 8410, 21980],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0079_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=160M BW=8900 20dBRej@153&167MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a267-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A267D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A267-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [160, 8900, 5.41, 153, 167, 150, 170],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=240M BW=16450 20dBRej@228&252MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a268-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A268D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A268-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [240, 16450, 4.95, 228, 252, 223, 258],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=300M BW=19 20dBRej@287&316MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a269-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A269D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A269-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [300, 19, 4.51, 287, 316, 283, 326],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=320M BW=19 20dBRej@307&336MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a271-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A271D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A271-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [320, 19, 4.83, 307, 336, 302, 347],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=360M BW=23 20dBRej@346&377MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a272-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A272D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A272-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [360, 23, 4.6, 346, 377, 339, 388],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=480M BW=29 20dBRej@460&503MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a273-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A273D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A273-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [480, 29, 4.86, 460, 503, 452, 517],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=500M BW=30 20dBRej@480&521MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a274-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A274D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A274-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [500, 30, 4.28, 480, 521, 470, 529],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=640M BW=40 20dBRej@615&671MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a275-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A275D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A275-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [640, 40, 4.04, 615, 671, 605, 687],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=720M BW=40 20dBRej@692&749MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a276-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A276D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A276-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [720, 40, 4.69, 692, 749, 680, 760],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=960M BW=59 20dBRej@921&1004MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a277-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A277D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A277-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [960, 59, 4.28, 921, 1004, 904, 1020],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=1G BW=58 20dBRej@959&1044MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a278-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A278D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A278-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [1000, 58, 4.63, 959, 1044, 942, 1060],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=1.28G BW=74 20dBRej@1228&1333MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a279-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A279D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A279-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [1280, 74, 4.53, 1228, 1333, 1206, 1356],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=1.44G BW=78 20dBRej@1383&1496MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a281-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A281D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A281-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [1440, 78, 4.01, 1383, 1496, 1359, 1513],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=1.5G BW=80 20dBRej@1440&1561MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a282-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A282D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A282-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [1500, 80, 4.86, 1440, 1561, 1416, 1585],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=1.92G BW=102 20dBRej@1849&1994MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a283-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A283D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A283-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [1920, 102, 4.49, 1849, 1994, 1822, 2025],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=2G BW=112 20dBRej@1921&2081MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a284-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A284D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A284-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [2000, 112, 4, 1921, 2081, 1890, 2118],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=2.5G BW=145 20dBRej@2401&2606MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a285-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A285D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A285-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [2500, 145, 4.81, 2401, 2606, 2361, 2650],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=2.565G BW=142 20dBRej@2462&2665MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a286-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A286D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A286-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [2565, 142, 4.28, 2462, 2665, 2421, 2708],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=2.88G BW=155 20dBRej@2767&2995MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a287-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A287D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A287-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [2880, 155, 4.66, 2767, 2995, 2724, 3041],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=3G BW=165 20dBRej@2882&3118MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a288-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A288D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A288-1204D",
//     price: 38200,
//     priceType: "cents",
//     props: [3000, 165, 5.08, 2882, 3118, 2839, 3162],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=4G BW=203 20dBRej@3840&4168MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a289-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A289D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A289-1204D",
//     price: 0,
//     priceType: "cents",
//     props: [4000, 203, 5.13, 3840, 4168, 3779, 4224],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0140",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=9.8G BW=1130 20dBRej@8900&10980MHz",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0140G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a296-0804d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "XMW BPFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A296D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A296-0804D",
//     price: 8600,
//     priceType: "cents",
//     props: [9800, 1130, 3.81, 8900, 10980, 8100, 11600],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0140_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0082",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B096QC2S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=10G BW=5440 20dBRej@6920&12920MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0082J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2b3-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B096QC2SSMT",
//     models: "this part is missing models",
//     pn: "XM-A2B3-0404D",
//     price: 21100,
//     priceType: "cents",
//     props: [10000, 5440, 1.52, 6920, 12920, 6380, 13120],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0082_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0080",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B028RF2S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=3G BW=2336 20dBRej@1650&4390MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0080H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2c2-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B028RF2SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2C2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2C2-0604D",
//     price: 32500,
//     priceType: "cents",
//     props: [3000, 2336, 1.15, 1650, 4390, 1390, 4705],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0080_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0081",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B056RC4S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=6G BW=4700 20dBRej@3600&8580MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0081I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2c3-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B056RC4SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2C3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2C3-0604D",
//     price: 31600,
//     priceType: "cents",
//     props: [6000, 4700, 1.63, 3600, 8580, 3340, 8680],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0081_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0168",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B033ND5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=3.3G BW=750 20dBRej@2850&3850MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0168H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2u1-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B033ND5SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2U1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2U1-0604D",
//     price: 29100,
//     priceType: "cents",
//     props: [3300, 750, 1.84, 2850, 3850, 2500, 3950],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0168_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0194",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=18.4G BW=1095 20dBRej@17250&19540MHz",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0194G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2w7-0804d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "XMW BPFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2W7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2W7-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [18400, 1095, 7.47, 17250, 19540, 16500, 19970],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0194_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0195",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=15.1G BW=1300 20dBRej@13850&16400MHz",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0195G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2w8-0804d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "XMW BPFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2W8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2W8-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [15100, 1300, 5.75, 13850, 16400, 13000, 17100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0195_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0173",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B012MD5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=1.227G BW=192 20dBRej@1075&1350MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0173H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2y9-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B012MD5SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2Y9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2Y9-0604D",
//     price: 21100,
//     priceType: "cents",
//     props: [1227, 192, 1.99, 1075, 1350, 976, 1395],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0173_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XBF-282+.pdf",
//     description: "CF=2.75G BW=1340 20dBRej@1710&11260MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3h5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XBF-282%2B",
//     mfgPart: "XBF-282+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3H5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3H5-0404D",
//     price: 7500,
//     priceType: "cents",
//     props: [2750, 1340, 2.14, 1710, 11260, 28, 35360],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0410",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://product.tdk.com/info/en/documents/data_sheet/rf_bpf_dea202450bt-1213c1_en.pdf",
//     description: "CF=2.45G BW=530 20dBRej@1790&3220MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0410B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3u9-0204d/",
//     mfgCode: "TDKCO",
//     mfgLandingPage:
//       "https://product.tdk.com/en/search/rf/rf/filter/info?part_no=DEA202450BT-1213C1",
//     mfgPart: "DEA202450BT-1213C1SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3U9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3U9-0204D",
//     price: 5300,
//     priceType: "cents",
//     props: [2450, 530, 1.06, 1790, 3220, 1350, 3840],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0668",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBP-1950+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a478-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBP-1950%2B",
//     mfgPart: "SYBP-1950+SMT",
//     models: "this is the new part",
//     pn: "XM-A478-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0668_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1093",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1093A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a491-1204d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "Lumped ElementSMT",
//     models: "this part is missing models",
//     pn: "XM-A491-1204D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1346",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1052+.pdf",
//     description: "CF=10.77G BW=2250 20dBRej@8400&14000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1346A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a525-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1052%2B",
//     mfgPart: "BFCN-1052+SMT",
//     models: "this part is missing models",
//     pn: "XM-A525-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [10770, 2250, 1.6, 8400, 14000, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1346_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0940",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-5100+.pdf",
//     description: "CF=5.1G BW=5000 20dBRej@2500&8875MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0940A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6h8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-5100%2B",
//     mfgPart: "BFCN-5100+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6H8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6H8-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [5100, 5000, 0.75, 2500, 8875, 2300, 9300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0471",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B080MB5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=8G BW=1412 20dBRej@7182&8960MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0471B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6h9-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B080MB5SSMT",
//     models: "this part is missing models",
//     pn: "XM-A6H9-0604D",
//     price: 22700,
//     priceType: "cents",
//     props: [8000, 1412, 1.79, 7182, 8960, 6930, 9180],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0471_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1445+.pdf",
//     description: "CF=1.445G BW=275 20dBRej@1165&2140MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6w6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1445%2B",
//     mfgPart: "BFCN-1445+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6W6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6W6-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1445, 275, 2.06, 1165, 2140, 1060, 3120],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1560+.pdf",
//     description: "CF=1.56G BW=408 20dBRej@1178&1958MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6w7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1560%2B",
//     mfgPart: "BFCN-1560+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6W7-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1560, 408, 2.61, 1178, 1958, 950, 2081],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1575+.pdf",
//     description: "CF=1.575G BW=325 20dBRej@1280&2300MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6w8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1575%2B",
//     mfgPart: "BFCN-1575+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6W8-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1575, 325, 2.1, 1280, 2300, 1150, 3250],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1690+.pdf",
//     description: "CF=1.69G BW=530 20dBRej@1300&2080MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6w9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1690%2B",
//     mfgPart: "BFCN-1690+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6W9-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1690, 530, 2.6, 1300, 2080, 1000, 2200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1855+.pdf",
//     description: "CF=1.855G BW=450 20dBRej@1450&3000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6y1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1855%2B",
//     mfgPart: "BFCN-1855+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6Y1-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1855, 450, 1.6, 1450, 3000, 1320, 4330],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1860+.pdf",
//     description: "CF=1.86G BW=1000 20dBRej@1300&2600MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6y2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1860%2B",
//     mfgPart: "BFCN-1860+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6Y2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6Y2-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1860, 1000, 1.5, 1300, 2600, 300, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1900+.pdf",
//     description: "CF=1.9G BW=27 20dBRej@1687&2153MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6y3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1900%2B",
//     mfgPart: "BFCN-1900+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6Y3-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1900, 27, 2.6, 1687, 2153, 1687, 2200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0029_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2360+.pdf",
//     description: "CF=2.36G BW=560 20dBRej@1850&3670MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6y4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2360%2B",
//     mfgPart: "BFCN-2360+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6Y4-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2360, 560, 1.5, 1850, 3670, 1600, 4800],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2435+.pdf",
//     description: "CF=2.435G BW=600 20dBRej@1900&3700MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6y5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2435%2B",
//     mfgPart: "BFCN-2435+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6Y5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6Y5-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2435, 600, 1.5, 1900, 3700, 1700, 4720],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2450+.pdf",
//     description: "CF=2.45G BW=450 20dBRej@2180&3220MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6y6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2450%2B",
//     mfgPart: "BFCN-2450+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6Y6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6Y6-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2450, 450, 1.95, 2180, 3220, 2098, 4740],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2500+.pdf",
//     description: "CF=2.5G BW=800 20dBRej@1620&3700MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6y7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2500%2B",
//     mfgPart: "BFCN-2500+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6Y7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6Y7-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2500, 800, 1.6, 1620, 3700, 1450, 500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2840+.pdf",
//     description: "CF=2.84G BW=500 20dBRej@2500&3200MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6y8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2840%2B",
//     mfgPart: "BFCN-2840+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6Y8-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2840, 500, 2.19, 2500, 3200, 660, 4300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2910+.pdf",
//     description: "CF=2.91G BW=380 20dBRej@2602&3292MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6y9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2910%2B",
//     mfgPart: "BFCN-2910+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6Y9-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2910, 380, 2.62, 2602, 3292, 640, 4317],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-2975+.pdf",
//     description: "CF=2.975G BW=870 20dBRej@1800&3900MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a711-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-2975%2B",
//     mfgPart: "BFCN-2975+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A711D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A711-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2975, 870, 2.2, 1800, 3900, 1100, 5000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-3085+.pdf",
//     description: "CF=3.085G BW=600 20dBRej@1850&4000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a712-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-3085%2B",
//     mfgPart: "BFCN-3085+SMT",
//     models: "this part is missing models",
//     pn: "XM-A712-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [3085, 600, 1.65, 1850, 4000, 1130, 5000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-3085A+.pdf",
//     description: "CF=3.085G BW=1200 20dBRej@1850&4000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a713-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-3085A%2B",
//     mfgPart: "BFCN-3085A+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A713D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A713-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [3085, 1200, 1.5, 1850, 4000, 1150, 5030],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-3115+.pdf",
//     description: "CF=3.115G BW=1360 20dBRej@1900&4200MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a714-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-3115%2B",
//     mfgPart: "BFCN-3115+SMT",
//     models: "this part is missing models",
//     pn: "XM-A714-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [3115, 1360, 1.5, 1900, 4200, 1130, 5240],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-3700+.pdf",
//     description: "CF=3.7G BW=2300 20dBRej@2275&5400MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a715-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-3700%2B",
//     mfgPart: "BFCN-3700+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A715D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A715-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [3700, 2300, 1.5, 2275, 5400, 2070, 5700],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-4800+.pdf",
//     description: "CF=4.8G BW=2250 20dBRej@2430&7000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a716-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-4800%2B",
//     mfgPart: "BFCN-4800+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A716D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A716-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [4800, 2250, 0.67, 2430, 7000, 1270, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-5200+.pdf",
//     description: "CF=5.2G BW=3000 20dBRej@3500&7500MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a717-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-5200%2B",
//     mfgPart: "BFCN-5200+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A717D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A717-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [5200, 3000, 1.5, 3500, 7500, 570, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0029_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-5750+.pdf",
//     description: "CF=5.75G BW=1050 20dBRej@4500&7540MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a718-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-5750%2B",
//     mfgPart: "BFCN-5750+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A718D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A718-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [5750, 1050, 1.77, 4500, 7540, 3740, 8400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-7350+.pdf",
//     description: "CF=7.35G BW=1150 20dBRej@6450&8500MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a719-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-7350%2B",
//     mfgPart: "BFCN-7350+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A719D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A719-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [7350, 1150, 1.82, 6450, 8500, 130, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-7900+.pdf",
//     description: "CF=7.9G BW=1120 20dBRej@6950&9300MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a721-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-7900%2B",
//     mfgPart: "BFCN-7900+SMT",
//     models: "this part is missing models",
//     pn: "XM-A721-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [7900, 1120, 1.54, 6950, 9300, 9800, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-8350+.pdf",
//     description: "CF=8.35G BW=1120 20dBRej@7250&9600MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a722-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-8350%2B",
//     mfgPart: "BFCN-8350+SMT",
//     models: "this part is missing models",
//     pn: "XM-A722-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [8350, 1120, 1.5, 7250, 9600, 7080, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0029_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0615",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCV-5270+.pdf",
//     description: "CF=5.27G BW=3320 20dBRej@3350&7910MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0615A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7b1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCV-5270%2B",
//     mfgPart: "BFCV-5270+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7B1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7B1-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [5270, 3320, 1.18, 3350, 7910, 2240, 8565],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0615_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0769",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=12.82G BW=1850 20dBRej@na&naMHz",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0769A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7f9-0804d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "XMW BPFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7F9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7F9-0804D",
//     price: 8600,
//     priceType: "cents",
//     props: [12820, 1850, 0, 0, 0, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0242",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/FB-1300SM.pdf",
//     description: "CF=13G BW=2730 20dBRej@10360&15400MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0242C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7j1-1004d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/fb-1300smg.aspx",
//     mfgPart: "FB-1300SMG",
//     models: "this part is missing models",
//     pn: "XM-A7J1-1004D",
//     price: 28400,
//     priceType: "cents",
//     props: [13000, 2730, 1.81, 10360, 15400, 9000, 16460],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0242_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0473",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.mouser.com/ds/2/842/B3588-1092477.pdf",
//     description: "CF=915M BW=26 20dBRej@890&940MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0473A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7j2-0404d/",
//     mfgCode: "TDKCO",
//     mfgLandingPage:
//       "https://www.qualcomm.com/products/technology/modems/rffe-filter-products/about",
//     mfgPart: "B3588SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7J2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7J2-0404D",
//     price: 6000,
//     priceType: "cents",
//     props: [915, 26, 2.9, 890, 940, 885, 962.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0473_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0520",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B280LB0S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=28G BW=2800 20dBRej@25900&29760MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0520A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7l9-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B280LB0SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7L9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7L9-0404D",
//     price: 18200,
//     priceType: "cents",
//     props: [28000, 2800, 1.37, 25900, 29760, 25220, 30600],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0520_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0675",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B280LA0S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=28G BW=2250 20dBRej@26420&29160MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0675A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7w4-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B280LA0SSMT",
//     models: "this part is missing models",
//     pn: "XM-A7W4-0604D",
//     price: 24200,
//     priceType: "cents",
//     props: [28000, 2250, 2.18, 26420, 29160, 26000, 29500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0675_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0607",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B161LA0S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=16G BW=1360 20dBRej@15060&16870MHz",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0607A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7w5-0804d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B161LA0SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7W5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7W5-0804D",
//     price: 26800,
//     priceType: "cents",
//     props: [16000, 1360, 3.4, 15060, 16870, 14720, 16970],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0607_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0608",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B120MB1S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=12G BW=1590 20dBRej@11010&12920MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0608A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7w6-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B120MB1SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7W6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7W6-0604D",
//     price: 24000,
//     priceType: "cents",
//     props: [12000, 1590, 1.69, 11010, 12920, 10670, 13090],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0608_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0173",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B016MD6S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=1.575G BW=231 20dBRej@1380&1730MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0173H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7w9-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B016MD6SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7W9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7W9-0604D",
//     price: 18400,
//     priceType: "cents",
//     props: [1575, 231, 1.9, 1380, 1730, 1230, 1800],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0173_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0242",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/FB-1445SM.pdf",
//     description: "CF=14.45G BW=3300 20dBRej@11380&16830MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0242C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8g5-1004d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/fb-1445sm.aspx",
//     mfgPart: "FB-1445SMSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A8G5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A8G5-1004D",
//     price: 26100,
//     priceType: "cents",
//     props: [14450, 3300, 1.87, 11380, 16830, 9790, 18220],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0242_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-253+.pdf",
//     description: "CF=227M BW=186 20dBRej@150&409MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9f9-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-253%2B",
//     mfgPart: "RBP-253+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9F9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9F9-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [227, 186, 1.15, 150, 409, 120, 500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0780",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-BP-Series.pdf",
//     description: "CF=770M BW=250 20dBRej@500&1150MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0780A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9h5-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/bp-series-mlo-band-pass-filter/",
//     mfgPart: "BP0CA0770A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9H5-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [770, 250, 1.28, 500, 1150, 460, 1230],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0780",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-BP-Series.pdf",
//     description: "CF=1.1G BW=690 20dBRej@718&1600MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0780A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9h6-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/bp-series-mlo-band-pass-filter/",
//     mfgPart: "BP0CA1100A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9H6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [1100, 690, 1.34, 718, 1600, 673, 1703],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0783",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-BP-Series.pdf",
//     description: "CF=1.1G BW=690 20dBRej@718&1600MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0783A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9h7-0604d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/bp-series-mlo-band-pass-filter/",
//     mfgPart: "BP0FA1100A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9H7-0604D",
//     price: 10900,
//     priceType: "cents",
//     props: [1100, 690, 1.34, 718, 1600, 673, 1703],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0783_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0783",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-BP-Series.pdf",
//     description: "CF=1.13G BW=713 20dBRej@741&1655MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0783A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9h8-0604d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/bp-series-mlo-band-pass-filter/",
//     mfgPart: "BP0FA1130A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9H8-0604D",
//     price: 10900,
//     priceType: "cents",
//     props: [1130, 713, 1.36, 741, 1655, 680, 1740],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0783_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0783",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-BP-Series.pdf",
//     description: "CF=1.19G BW=665 20dBRej@823&1630MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0783A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9h9-0604d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/bp-series-mlo-band-pass-filter/",
//     mfgPart: "BP0FA1190A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9H9-0604D",
//     price: 10900,
//     priceType: "cents",
//     props: [1190, 665, 1.54, 823, 1630, 770, 1735],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0783_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0782",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-BP-Series.pdf",
//     description: "CF=3.43G BW=2225 20dBRej@2090&5190MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0782A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9j1-0604d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/bp-series-mlo-band-pass-filter/",
//     mfgPart: "BP0EA3430A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9J1-0604D",
//     price: 10900,
//     priceType: "cents",
//     props: [3430, 2225, 0.93, 2090, 5190, 1950, 5670],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0782_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0782",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-BP-Series.pdf",
//     description: "CF=4.44G BW=2620 20dBRej@3070&6440MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0782A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9j2-0604d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/bp-series-mlo-band-pass-filter/",
//     mfgPart: "BP0EA4440A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9J2-0604D",
//     price: 10900,
//     priceType: "cents",
//     props: [4440, 2620, 0.91, 3070, 6440, 2865, 7095],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0782_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0782",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-BP-Series.pdf",
//     description: "CF=4.6G BW=2635 20dBRej@3175&6640MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0782A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9j3-0604d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/bp-series-mlo-band-pass-filter/",
//     mfgPart: "BP0EA4600A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9J3-0604D",
//     price: 10900,
//     priceType: "cents",
//     props: [4600, 2635, 0.97, 3175, 6640, 2975, 7320],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0782_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0782",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-BP-Series.pdf",
//     description: "CF=4.68G BW=2760 20dBRej@3245&6830MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0782A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9j4-0604d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/bp-series-mlo-band-pass-filter/",
//     mfgPart: "BP0EA4680A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9J4-0604D",
//     price: 10900,
//     priceType: "cents",
//     props: [4680, 2760, 0.95, 3245, 6830, 3030, 7520],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0782_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-20R5+.pdf",
//     description: "CF=20.5M BW=4.2 20dBRej@17.5&25.5MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1g5-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-20R5%2B",
//     mfgPart: "SXBP-20R5+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1G5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1G5-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [20.5, 4.2, 2.25, 17.5, 25.5, 16.16, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1346",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-1262+.pdf",
//     description: "CF=12.6G BW=2150 20dBRej@1127&1420MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1346A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b214-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-1262%2B",
//     mfgPart: "BFCN-1262+SMT",
//     models: "this part is missing models",
//     pn: "XM-B214-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [12600, 2150, 4.1, 1127, 1420, 1050, 1480],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1346_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1015",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-V1000+.pdf",
//     description: "CF=1G BW=287 20dBRej@818&1197MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1015A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2f4-1206d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-V1000%2B",
//     mfgPart: "BPF-V1000+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2F4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2F4-1206D",
//     price: 23000,
//     priceType: "cents",
//     props: [1000, 287, 0.48, 818, 1197, 780, 1290],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1015_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1406",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFTC-415+.pdf",
//     description: "CF=415M BW=170 20dBRej@268&580MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2l8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFTC-415%2B",
//     mfgPart: "BFTC-415+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2L8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2L8-0404D",
//     price: 6900,
//     priceType: "cents",
//     props: [415, 170, 4, 268, 580, 240, 650],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1406",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFTC-500+.pdf",
//     description: "CF=500M BW=280 20dBRej@324&700MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2l9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFTC-500%2B",
//     mfgPart: "BFTC-500+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2L9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2L9-0404D",
//     price: 6900,
//     priceType: "cents",
//     props: [500, 280, 2.28, 324, 700, 290, 854],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1406",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFTC-615+.pdf",
//     description: "CF=615M BW=416 20dBRej@360&890MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2m1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFTC-615%2B",
//     mfgPart: "BFTC-615+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2M1-0404D",
//     price: 6300,
//     priceType: "cents",
//     props: [615, 416, 2.18, 360, 890, 320, 2230],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1406",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFTC-618+.pdf",
//     description: "CF=618M BW=400 20dBRej@380&910MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2m2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFTC-618%2B",
//     mfgPart: "BFTC-618+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2M2-0404D",
//     price: 6900,
//     priceType: "cents",
//     props: [618, 400, 2.03, 380, 910, 330, 980],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C138+.pdf",
//     description: "CF=138M BW=93 20dBRej@89&197MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2t5-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C138+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2T5-1007D",
//     price: 19000,
//     priceType: "cents",
//     props: [138, 93, 0.61, 89, 197, 85, 201],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C141+.pdf",
//     description: "CF=141M BW=100 20dBRej@95&208MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2t6-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C141+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2T6-1007D",
//     price: 19000,
//     priceType: "cents",
//     props: [141, 100, 0.84, 95, 208, 91, 215],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C45+.pdf",
//     description: "CF=45M BW=60 20dBRej@24&91MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2t7-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C45+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2T7-1007D",
//     price: 19000,
//     priceType: "cents",
//     props: [45, 60, 0.39, 24, 91, 21, 94],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C450+.pdf",
//     description: "CF=450M BW=270 20dBRej@330&674MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2t8-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C450+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2T8-1007D",
//     price: 19000,
//     priceType: "cents",
//     props: [450, 270, 0.89, 330, 674, 311, 732],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C495+.pdf",
//     description: "CF=495M BW=108 20dBRej@421&601MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2t9-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C495+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2T9-1007D",
//     price: 20500,
//     priceType: "cents",
//     props: [495, 108, 1.35, 421, 601, 387, 673],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C4R5+.pdf",
//     description: "CF=4.5M BW=9 20dBRej@.73&15MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2u1-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C4R5+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2U1-1007D",
//     price: 17700,
//     priceType: "cents",
//     props: [4.5, 9, 0.31, 0.73, 15, 0.512, 22],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C510+.pdf",
//     description: "CF=510M BW=1029 20dBRej@17.5&1102MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2u2-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C510+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2U2-1007D",
//     price: 20500,
//     priceType: "cents",
//     props: [510, 1029, 0.37, 17.5, 1102, 17.53, 1138],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C587+.pdf",
//     description: "CF=587M BW=288 20dBRej@445&787MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2u3-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C587+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2U3-1007D",
//     price: 19800,
//     priceType: "cents",
//     props: [587, 288, 0.67, 445, 787, 422, 903],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C59+.pdf",
//     description: "CF=59M BW=74 20dBRej@24&112MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2u4-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C59+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2U4-1007D",
//     price: 19700,
//     priceType: "cents",
//     props: [59, 74, 0.47, 24, 112, 21.5, 123],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C650+.pdf",
//     description: "CF=650M BW=333 20dBRej@330&841MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2u5-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C650+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2U5-1007D",
//     price: 20500,
//     priceType: "cents",
//     props: [650, 333, 1.12, 330, 841, 218, 879],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C670+.pdf",
//     description: "CF=670M BW=453 20dBRej@400&934MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2u6-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C670+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2U6-1007D",
//     price: 19700,
//     priceType: "cents",
//     props: [670, 453, 0.76, 400, 934, 374, 983],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C70+.pdf",
//     description: "CF=70M BW=4.5 20dBRej@66&74MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2u7-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C70+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2U7-1007D",
//     price: 21400,
//     priceType: "cents",
//     props: [70, 4.5, 5.33, 66, 74, 64, 77],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C73+.pdf",
//     description: "CF=73M BW=40 20dBRej@47&99MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2u8-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C73+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2U8-1007D",
//     price: 19000,
//     priceType: "cents",
//     props: [73, 40, 0.8, 47, 99, 42.7, 104],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C75+.pdf",
//     description: "CF=75M BW=37 20dBRej@50&99MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2u9-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C75+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2U9-1007D",
//     price: 19000,
//     priceType: "cents",
//     props: [75, 37, 0.97, 50, 99, 45.5, 105],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCN-5540+.pdf",
//     description: "CF=5.54G BW=3000 20dBRej@3580&7620MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCN-5540%2B",
//     mfgPart: "BFCN-5540+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5E8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5E8-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [5540, 3000, 1.7, 3580, 7620, 685, 8150],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0712",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.vectron.com/products/saw/pdf_saw/TFS602.pdf",
//     description: "CF=602M BW=30 20dBRej@560&640MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0712A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5g6-0404d/",
//     mfgCode: "VTRON",
//     mfgLandingPage: "https://www.vectron.com/products/saw.aspx",
//     mfgPart: "TFS602SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5G6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5G6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [602, 30, 2, 560, 640, 550, 650],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1555C+.pdf",
//     description: "CF=1.555G BW=140 20dBRej@1436&1670MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5m5-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1555C%2B",
//     mfgPart: "CBP-1555C+SMT",
//     models: "this part is missing models",
//     pn: "XM-B5M5-0808D",
//     price: 18300,
//     priceType: "cents",
//     props: [1555, 140, 1.03, 1436, 1670, 1380, 1780],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1228C+.pdf",
//     description: "CF=1.228G BW=87.3 20dBRej@1160&1304MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5m6-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1228C%2B",
//     mfgPart: "CBP-1228C+SMT",
//     models: "this part is missing models",
//     pn: "XM-B5M6-0808D",
//     price: 18300,
//     priceType: "cents",
//     props: [1228, 87.3, 1.07, 1160, 1304, 1120, 1375],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-100+.pdf",
//     description: "CF=100M BW=48 20dBRej@69&136MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5m9-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-100%2B",
//     mfgPart: "SXBP-100+SMT",
//     models: "this part is missing models",
//     pn: "XM-B5M9-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [100, 48, 1.51, 69, 136, 59, 158],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0606",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B060NC5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=6G BW=1300 20dBRej@6160&6820MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0606A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6n2-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B060NC5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B6N2-0604D",
//     price: 24700,
//     priceType: "cents",
//     props: [6000, 1300, 1.23, 6160, 6820, 4900, 7040],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0606_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A113+.pdf",
//     description: "CF=113M BW=20 20dBRej@95&130MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6n4-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A113%2B",
//     mfgPart: "BPF-A113+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6N4-1404D",
//     price: 18500,
//     priceType: "cents",
//     props: [113, 20, 1.88, 95, 130, 89, 146],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A1140+.pdf",
//     description: "CF=1.14G BW=710 20dBRej@736&1542MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6n5-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A1140%2B",
//     mfgPart: "BPF-A1140+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6N5-1404D",
//     price: 18500,
//     priceType: "cents",
//     props: [1140, 710, 1.21, 736, 1542, 703, 1586],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A120+.pdf",
//     description: "CF=120M BW=70 20dBRej@84&170MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6n6-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A120%2B",
//     mfgPart: "BPF-A120+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6N6-1404D",
//     price: 19500,
//     priceType: "cents",
//     props: [120, 70, 0.98, 84, 170, 78, 180],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A122+.pdf",
//     description: "CF=122M BW=6 20dBRej@113&132MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6n7-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A122%2B",
//     mfgPart: "BPF-A122+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6N7-1404D",
//     price: 19500,
//     priceType: "cents",
//     props: [122, 6, 3.33, 113, 132, 108, 134],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A127+.pdf",
//     description: "CF=127M BW=29 20dBRej@105&150MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6n8-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A127%2B",
//     mfgPart: "BPF-A127+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6N8-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [127, 29, 2.32, 105, 150, 100, 164],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A1340+.pdf",
//     description: "CF=1.34G BW=1200 20dBRej@800&2200MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6n9-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A1340%2B",
//     mfgPart: "BPF-A1340+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6N9-1404D",
//     price: 19500,
//     priceType: "cents",
//     props: [1340, 1200, 1.09, 800, 2200, 785, 2400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A1600+.pdf",
//     description: "CF=1.6G BW=517 20dBRej@1260&1920MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6p2-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A1600%2B",
//     mfgPart: "BPF-A1600+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6P2-1404D",
//     price: 19500,
//     priceType: "cents",
//     props: [1600, 517, 1.6, 1260, 1920, 1260, 2000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A332+.pdf",
//     description: "CF=332M BW=29 20dBRej@311&355MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6p6-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A332%2B",
//     mfgPart: "BPF-A332+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6P6-1404D",
//     price: 18500,
//     priceType: "cents",
//     props: [332, 29, 3.46, 311, 355, 297, 373],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A355+.pdf",
//     description: "CF=355M BW=146 20dBRej@249&437MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6p7-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A355%2B",
//     mfgPart: "BPF-A355+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6P7-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [355, 146, 1.08, 249, 437, 220, 456],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A400+.pdf",
//     description: "CF=400M BW=58 20dBRej@354&455MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6p8-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A400%2B",
//     mfgPart: "BPF-A400+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6P8-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [400, 58, 2.63, 354, 455, 331, 515],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A410+.pdf",
//     description: "CF=410M BW=140 20dBRej@324&500MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6p9-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A410%2B",
//     mfgPart: "BPF-A410+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6P9-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [410, 140, 2.3, 324, 500, 295, 519],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A490+.pdf",
//     description: "CF=490M BW=286 20dBRej@296&665MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6r1-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A490%2B",
//     mfgPart: "BPF-A490+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6R1-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [490, 286, 0.71, 296, 665, 260, 703],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A580+.pdf",
//     description: "CF=580M BW=175 20dBRej@449&705MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6r2-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A580%2B",
//     mfgPart: "BPF-A580+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6R2-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [580, 175, 2.01, 449, 705, 412, 733],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A60+.pdf",
//     description: "CF=60M BW=13 20dBRej@52&71MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6r3-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A60%2B",
//     mfgPart: "BPF-A60+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6R3-1404D",
//     price: 19500,
//     priceType: "cents",
//     props: [60, 13, 2.15, 52, 71, 50, 78],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A600+.pdf",
//     description: "CF=600M BW=275 20dBRej@400&375MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6r4-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A600%2B",
//     mfgPart: "BPF-A600+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6R4-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [600, 275, 0.78, 400, 375, 257, 770],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A69+.pdf",
//     description: "CF=69M BW=39 20dBRej@42&96MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6r5-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A69%2B",
//     mfgPart: "BPF-A69+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6R5-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [69, 39, 1.23, 42, 96, 34, 101],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A730+.pdf",
//     description: "CF=730M BW=150 20dBRej@617&582MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6r6-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A730%2B",
//     mfgPart: "BPF-A730+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6R6-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [730, 150, 2.41, 617, 582, 856, 964],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A75+.pdf",
//     description: "CF=75M BW=13 20dBRej@66&85MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6r7-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A75%2B",
//     mfgPart: "BPF-A75+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6R7-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [75, 13, 3.63, 66, 85, 63, 91],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A76+.pdf",
//     description: "CF=76M BW=12 20dBRej@67&85MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6r8-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A76%2B",
//     mfgPart: "BPF-A76+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6R8-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [76, 12, 2.63, 67, 85, 64, 90],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A800+.pdf",
//     description: "CF=800M BW=50 20dBRej@717&856MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6r9-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A800%2B",
//     mfgPart: "BPF-A800+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6R9-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [800, 50, 3.1, 717, 856, 683, 940],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0665",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-A950+.pdf",
//     description: "CF=950M BW=600 20dBRej@636&1269MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0665B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6t1-1404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-A950%2B",
//     mfgPart: "BPF-A950+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6T1-1404D",
//     price: 17700,
//     priceType: "cents",
//     props: [950, 600, 0.83, 636, 1269, 605, 1300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0665_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-B140N+.pdf",
//     description: "CF=140M BW=12 20dBRej@130&150MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6t3-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-B140N%2B",
//     mfgPart: "BPF-B140N+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6T3-1004D",
//     price: 12800,
//     priceType: "cents",
//     props: [140, 12, 4.25, 130, 150, 123, 159],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-B140W+.pdf",
//     description: "CF=140M BW=22 20dBRej@122&160MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6t4-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-B140W%2B",
//     mfgPart: "BPF-B140W+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6T4-1004D",
//     price: 12800,
//     priceType: "cents",
//     props: [140, 22, 2.07, 122, 160, 111, 177],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-B157+.pdf",
//     description: "CF=157M BW=24 20dBRej@135&179MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6t5-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-B157%2B",
//     mfgPart: "BPF-B157+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6T5-1004D",
//     price: 12800,
//     priceType: "cents",
//     props: [157, 24, 2.27, 135, 179, 123, 200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-B177+.pdf",
//     description: "CF=177M BW=28 20dBRej@155&202MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6t6-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-B177%2B",
//     mfgPart: "BPF-B177+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6T6-1004D",
//     price: 12800,
//     priceType: "cents",
//     props: [177, 28, 2.29, 155, 202, 141, 224],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-B190+.pdf",
//     description: "CF=190M BW=18 20dBRej@175&206MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6t7-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-B190%2B",
//     mfgPart: "BPF-B190+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6T7-1004D",
//     price: 12800,
//     priceType: "cents",
//     props: [190, 18, 3.5, 175, 206, 164, 220],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-B199+.pdf",
//     description: "CF=199M BW=19 20dBRej@183&216MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6t8-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-B199%2B",
//     mfgPart: "BPF-B199+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6T8-1004D",
//     price: 12800,
//     priceType: "cents",
//     props: [199, 19, 3.11, 183, 216, 173, 230],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-B48+.pdf",
//     description: "CF=48M BW=6 20dBRej@42&54MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6t9-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-B48%2B",
//     mfgPart: "BPF-B48+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6T9-1004D",
//     price: 15500,
//     priceType: "cents",
//     props: [48, 6, 2.3, 42, 54, 38, 61],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-B503+.pdf",
//     description: "CF=503M BW=52 20dBRej@452&547MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6u1-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-B503%2B",
//     mfgPart: "BPF-B503+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6U1-1004D",
//     price: 13100,
//     priceType: "cents",
//     props: [503, 52, 2.96, 452, 547, 421, 588],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-B59+.pdf",
//     description: "CF=59M BW=7 20dBRej@53&65MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6u2-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-B59%2B",
//     mfgPart: "BPF-B59+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6U2-1004D",
//     price: 15500,
//     priceType: "cents",
//     props: [59, 7, 3.38, 53, 65, 50, 70],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-B63+.pdf",
//     description: "CF=63M BW=7 20dBRej@56&70MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6u3-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-B63%2B",
//     mfgPart: "BPF-B63+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6U3-1004D",
//     price: 15500,
//     priceType: "cents",
//     props: [63, 7, 2.94, 56, 70, 52, 75],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-101+.pdf",
//     description: "CF=101M BW=34 20dBRej@82&124MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6u4-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-101%2B",
//     mfgPart: "SXBP-101+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6U4-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [101, 34, 1.75, 82, 124, 73, 145],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-1100+.pdf",
//     description: "CF=1.1G BW=540 20dBRej@56&1405MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6u5-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-1100%2B",
//     mfgPart: "SXBP-1100+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6U5-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [1100, 540, 0.56, 56, 1405, 5, 1675],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-140+.pdf",
//     description: "CF=140M BW=29 20dBRej@117&167MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6u6-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-140%2B",
//     mfgPart: "SXBP-140+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6U6-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [140, 29, 1.62, 117, 167, 105, 191],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-150+.pdf",
//     description: "CF=150M BW=34 20dBRej@125&181MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6u7-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-150%2B",
//     mfgPart: "SXBP-150+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6U7-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [150, 34, 1.51, 125, 181, 111, 209],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-157+.pdf",
//     description: "CF=157M BW=26 20dBRej@137&180MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6u8-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-157%2B",
//     mfgPart: "SXBP-157+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6U8-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [157, 26, 2.27, 137, 180, 123, 202],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-161R5+.pdf",
//     description: "CF=161M BW=36 20dBRej@134&200MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6u9-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-161R5%2B",
//     mfgPart: "SXBP-161R5+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6U9-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [161, 36, 1.77, 134, 200, 194, 221],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-162+.pdf",
//     description: "CF=162M BW=27 20dBRej@144&192MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6v1-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-162%2B",
//     mfgPart: "SXBP-162+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6V1-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [162, 27, 1.64, 144, 192, 134, 231],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-169+.pdf",
//     description: "CF=169M BW=33 20dBRej@141&193MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6v2-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-169%2B",
//     mfgPart: "SXBP-169+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6V2-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [169, 33, 1.74, 141, 193, 128, 218],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-178+.pdf",
//     description: "CF=178M BW=29 20dBRej@154&202MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6v4-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-178%2B",
//     mfgPart: "SXBP-178+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6V4-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [178, 29, 2.36, 154, 202, 141, 224],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-202+.pdf",
//     description: "CF=202M BW=40 20dBRej@168&236MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6v5-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-202%2B",
//     mfgPart: "SXBP-202+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6V5-1004D",
//     price: 13400,
//     priceType: "cents",
//     props: [202, 40, 2.9, 168, 236, 152, 271],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-240+.pdf",
//     description: "CF=240M BW=20 20dBRej@224&255MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6v6-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-240%2B",
//     mfgPart: "SXBP-240+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6V6-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [240, 20, 3.54, 224, 255, 213, 268],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-27R5+.pdf",
//     description: "CF=27.5M BW=11 20dBRej@20.2&35.5MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6v7-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-27R5%2B",
//     mfgPart: "SXBP-27R5+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6V7-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [27.5, 11, 0.89, 20.2, 35.5, 17.3, 42],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-29+.pdf",
//     description: "CF=29M BW=18 20dBRej@19&44.5MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6v8-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-29%2B",
//     mfgPart: "SXBP-29+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6V8-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [29, 18, 0.58, 19, 44.5, 15.5, 53.25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-310+.pdf",
//     description: "CF=310M BW=30 20dBRej@287.5&336MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6v9-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-310%2B",
//     mfgPart: "SXBP-310+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6V9-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [310, 30, 2.72, 287.5, 336, 272, 357],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-350+.pdf",
//     description: "CF=350M BW=72 20dBRej@294&413MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6w1-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-350%2B",
//     mfgPart: "SXBP-350+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6W1-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [350, 72, 1.29, 294, 413, 264, 473],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-35N+.pdf",
//     description: "CF=35M BW=20 20dBRej@22&55MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6w2-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-35N%2B",
//     mfgPart: "SXBP-35N+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6W2-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [35, 20, 0.49, 22, 55, 19, 65],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-35W+.pdf",
//     description: "CF=35M BW=34 20dBRej@16.5&68MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6w3-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-35W%2B",
//     mfgPart: "SXBP-35W+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6W3-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [35, 34, 0.31, 16.5, 68, 15.1, 82],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-404+.pdf",
//     description: "CF=404M BW=33 20dBRej@377&433MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6w4-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-404%2B",
//     mfgPart: "SXBP-404+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6W4-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [404, 33, 3.71, 377, 433, 358, 456],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-425+.pdf",
//     description: "CF=425M BW=45 20dBRej@390&464MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6w5-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-425%2B",
//     mfgPart: "SXBP-425+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6W5-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [425, 45, 2.27, 390, 464, 368, 494],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-45-75+.pdf",
//     description: "CF=45M BW=93 20dBRej@2.1&106MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6w6-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-45-75%2B",
//     mfgPart: "SXBP-45-75+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6W6-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [45, 93, 0.35, 2.1, 106, 0.85, 116],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-507+.pdf",
//     description: "CF=507M BW=190 20dBRej@334&595MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6w7-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-507%2B",
//     mfgPart: "SXBP-507+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6W7-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [507, 190, 1.81, 334, 595, 254, 617],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-615+.pdf",
//     description: "CF=615M BW=210 20dBRej@412&703MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6w8-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-615%2B",
//     mfgPart: "SXBP-615+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6W8-1004D",
//     price: 12300,
//     priceType: "cents",
//     props: [615, 210, 1.58, 412, 703, 315, 722],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-640+.pdf",
//     description: "CF=640M BW=123 20dBRej@531&726MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6w9-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-640%2B",
//     mfgPart: "SXBP-640+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6W9-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [640, 123, 2.51, 531, 726, 462, 760],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-69+.pdf",
//     description: "CF=69M BW=20 20dBRej@56&85MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6y1-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-69%2B",
//     mfgPart: "SXBP-69+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6Y1-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [69, 20, 1.01, 56, 85, 54, 88],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-70+.pdf",
//     description: "CF=70M BW=27 20dBRej@53&90MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6y2-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-70%2B",
//     mfgPart: "SXBP-70+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6Y2-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [70, 27, 0.9, 53, 90, 22, 174],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-707+.pdf",
//     description: "CF=707M BW=241 20dBRej@485&818MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6y3-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-707%2B",
//     mfgPart: "SXBP-707+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6Y3-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [707, 241, 2.07, 485, 818, 369, 841],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-70W+.pdf",
//     description: "CF=70M BW=115 20dBRej@.9&135MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6y4-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-70W%2B",
//     mfgPart: "SXBP-70W+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6Y4-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [70, 115, 0.2, 0.9, 135, null, 142],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-72+.pdf",
//     description: "CF=72M BW=13.7 20dBRej@62&84MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6y5-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-72%2B",
//     mfgPart: "SXBP-72+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6Y5-1004D",
//     price: 12800,
//     priceType: "cents",
//     props: [72, 13.7, 2.7, 62, 84, 56, 95],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0667",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-820+.pdf",
//     description: "CF=820M BW=405 20dBRej@502&1273MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0667B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6y6-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-820%2B",
//     mfgPart: "SXBP-820+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6Y6-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: [820, 405, 1.87, 502, 1273, 403, 1646],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0668",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBP-1420+.pdf",
//     description: "CF=1.42G BW=587 20dBRej@938&696MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0668B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6y7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBP-1420%2B",
//     mfgPart: "SYBP-1420+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6Y7-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [1420, 587, 1.69, 938, 696, 1915, 2032],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0668_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0668",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBP-2250+.pdf",
//     description: "CF=2.25G BW=1500 20dBRej@1316&1100MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0668B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6y8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBP-2250%2B",
//     mfgPart: "SYBP-2250+SMT",
//     models: "this part is missing models",
//     pn: "XM-B6Y8-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [2250, 1500, 1.22, 1316, 1100, 3580, 3760],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0668_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0668",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBP-2640+.pdf",
//     description: "CF=2.64G BW=1016 20dBRej@1800&3650MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0668B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7a1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBP-2640%2B",
//     mfgPart: "SYBP-2640+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7A1-0404D",
//     price: 7000,
//     priceType: "cents",
//     props: [2640, 1016, 1.45, 1800, 3650, 1130, 4600],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0668_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0668",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBP-820+.pdf",
//     description: "CF=820M BW=410 20dBRej@500&1262MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0668B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7a2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBP-820%2B",
//     mfgPart: "SYBP-820+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7A2-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [820, 410, 1.86, 500, 1262, 402, 1640],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0668_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0668",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBP-92+.pdf",
//     description: "CF=900M BW=684 20dBRej@546&1500MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0668B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7a3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBP-92%2B",
//     mfgPart: "SYBP-92+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7A3-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [900, 684, 1.78, 546, 1500, 416, 2360],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0668_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0674",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B058MD7S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=5.7G BW=1170 20dBRej@5020&6640MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0674A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7d5-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B058MD7SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7D5-0604D",
//     price: 26400,
//     priceType: "cents",
//     props: [5700, 1170, 1.2, 5020, 6640, 4685, 6950],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1141",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B039NC5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=3.95G BW=950 20dBRej@3365&4600MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1141A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7l2-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B039NC5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7L2-0604D",
//     price: 25400,
//     priceType: "cents",
//     props: [3950, 950, 1.75, 3365, 4600, 3125, 4780],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1141_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1141",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B070MB6S-Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=7.1G BW=780 20dBRej@6500&7560MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1141A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7l3-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B070MB6SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B7L3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B7L3-0604D",
//     price: 23900,
//     priceType: "cents",
//     props: [7100, 780, 1.79, 6500, 7560, 6300, 6760],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1141_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1141",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B070NC5S-Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=7G BW=1500 20dBRej@6150&8030MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1141A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7l4-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B070NC5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7L4-0604D",
//     price: 25500,
//     priceType: "cents",
//     props: [7000, 1500, 1.08, 6150, 8030, 5850, 8290],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1141_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1141",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B076MB6S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=7.6G BW=940 20dBRej@7010&8290MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1141A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7l5-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B076MB6SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7L5-0604D",
//     price: 25100,
//     priceType: "cents",
//     props: [7600, 940, 1.46, 7010, 8290, 6770, 8500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1141_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1142",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B024RF2S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=2.4G BW=1550 20dBRej@1580&3500MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1142A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7l6-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B024RF2SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7L6-0604D",
//     price: 31700,
//     priceType: "cents",
//     props: [2400, 1550, 1.04, 1580, 3500, 1275, 3660],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1142_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1142",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B031ND5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=3.125G BW=720 20dBRej@2640&3670MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1142A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7l7-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B031ND5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7L7-0604D",
//     price: 55800,
//     priceType: "cents",
//     props: [3125, 720, 1.4, 2640, 3670, 2400, 3850],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1142_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1142",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B032ND5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=3.24G BW=865 20dBRej@2660&3870MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1142A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7l8-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B032ND5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7L8-0604D",
//     price: 20900,
//     priceType: "cents",
//     props: [3240, 865, 1.15, 2660, 3870, 2400, 4050],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1142_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1142",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B040MB5S_Datasheet-Measured-updated.pdf.aspx?lang=en-US",
//     description: "CF=4G BW=950 20dBRej@3700&3610MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1142A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7l9-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B040MB5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7L9-0604D",
//     price: 25400,
//     priceType: "cents",
//     props: [4000, 950, 1.78, 3700, 3610, 3120, 4775],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1142_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1142",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B042ND4S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=4.25G BW=1480 20dBRej@3430&5250MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1142A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7m1-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B042OD4SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7M1-0604D",
//     price: 26800,
//     priceType: "cents",
//     props: [4250, 1480, 0.89, 3430, 5250, 3130, 5465],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1142_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1142",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B047MC5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=4.7G BW=975 20dBRej@4130&5375MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1142A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7m2-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B047MC5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7M2-0604D",
//     price: 25300,
//     priceType: "cents",
//     props: [4700, 975, 1.11, 4130, 5375, 3895, 5550],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1142_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1142",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B062MC5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=6.3G BW=1275 20dBRej@5520&7240MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1142A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7m3-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B062MC5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7M3-0604D",
//     price: 27200,
//     priceType: "cents",
//     props: [6300, 1275, 1.53, 5520, 7240, 5200, 7470],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1142_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1142",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B065NC5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=6.5G BW=1440 20dBRej@5600&7480MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1142A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7m4-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B065NC5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7M4-0604D",
//     price: 24300,
//     priceType: "cents",
//     props: [6500, 1440, 1.15, 5600, 7480, 5230, 7740],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1142_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1145",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B050ND4S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=5G BW=1430 20dBRej@4100&5930MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1145A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7m5-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B050ND4SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7M5-0404D",
//     price: 22700,
//     priceType: "cents",
//     props: [5000, 1430, 1.1, 4100, 5930, 3750, 6100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1145_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1145",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B052NC5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=5.2G BW=1200 20dBRej@4450&6000MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1145A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7m6-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B052NC5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7M6-0404D",
//     price: 21900,
//     priceType: "cents",
//     props: [5200, 1200, 1.1, 4450, 6000, 4130, 6160],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1145_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1145",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B055NC5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=5.5G BW=1450 20dBRej@4640&6485MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1145A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7m7-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B055NC5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7M7-0404D",
//     price: 21800,
//     priceType: "cents",
//     props: [5500, 1450, 0.97, 4640, 6485, 4260, 6685],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1145_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1145",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B057MC5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=5.7G BW=870 20dBRej@5100&6300MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1145A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7m8-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B057MC5SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7M8-0404D",
//     price: 22200,
//     priceType: "cents",
//     props: [5700, 870, 1.53, 5100, 6300, 4830, 6510],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1145_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1150",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B274MB1S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=28G BW=4130 20dBRej@25370&30150MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1150A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7n2-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B274MB1SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7N2-0604D",
//     price: 12300,
//     priceType: "cents",
//     props: [28000, 4130, 1.97, 25370, 30150, 24860, 30460],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1151",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B385MD0S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=38.5G BW=4950 20dBRej@36000&43060MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1151A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7n3-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B385MD0SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7N3-0404D",
//     price: 14800,
//     priceType: "cents",
//     props: [38500, 4950, 1.13, 36000, 43060, 35190, 45840],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1151_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1152",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B081RC0S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=8.5G BW=5700 20dBRej@5580&11870MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1152A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7n4-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B081RC0SSMT",
//     models: "this part is missing models",
//     pn: "XM-B7N4-0404D",
//     price: 19100,
//     priceType: "cents",
//     props: [8500, 5700, 1.29, 5580, 11870, 5240, 12020],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1152_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-75+.pdf",
//     description: "CF=75M BW=50 20dBRej@40&111MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7r7-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-75%2B",
//     mfgPart: "RBP-75+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7R7-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [75, 50, 1.92, 40, 111, 31, 143],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-98+.pdf",
//     description: "CF=103M BW=73 20dBRej@58&160MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7r8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-98%2B",
//     mfgPart: "RBP-98+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7R8-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [103, 73, 1.63, 58, 160, 45, 200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-130+.pdf",
//     description: "CF=130M BW=122 20dBRej@64&237MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7r9-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-130%2B",
//     mfgPart: "RBP-130+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7R9-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [130, 122, 1.24, 64, 237, 48, 305],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-135+.pdf",
//     description: "CF=135M BW=44 20dBRej@104&172MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7t1-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-135%2B",
//     mfgPart: "RBP-135+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7T1-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [135, 44, 2.45, 104, 172, 92, 184],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-140+.pdf",
//     description: "CF=140M BW=45 20dBRej@104&172MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7t2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-140%2B",
//     mfgPart: "RBP-140+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7T2-0604D",
//     price: 9900,
//     priceType: "cents",
//     props: [140, 45, 2.44, 104, 172, 92, 184],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-160+.pdf",
//     description: "CF=160M BW=90 20dBRej@85&280MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7t3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-160%2B",
//     mfgPart: "RBP-160+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7T3-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [160, 90, 1.24, 85, 280, 70, 340],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-173+.pdf",
//     description: "CF=173M BW=52 20dBRej@134&217MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7t4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-173%2B",
//     mfgPart: "RBP-173+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7T4-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [173, 52, 1.75, 134, 217, 125, 240],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-188+.pdf",
//     description: "CF=188M BW=136 20dBRej@101&295MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7t5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-188%2B",
//     mfgPart: "RBP-188+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7T5-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [188, 136, 1.4, 101, 295, 79, 374],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-204+.pdf",
//     description: "CF=204M BW=96 20dBRej@142&280MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7t6-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-204%2B",
//     mfgPart: "RBP-204+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7T6-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [204, 96, 2.08, 142, 280, 120, 330],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-220+.pdf",
//     description: "CF=220M BW=73 20dBRej@154&283MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7t7-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-220%2B",
//     mfgPart: "RBP-220+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7T7-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [220, 73, 1.63, 154, 283, 142, 311],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-220W+.pdf",
//     description: "CF=220M BW=102 20dBRej@157&300MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7t8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-220W%2B",
//     mfgPart: "RBP-220W+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7T8-0604D",
//     price: 9500,
//     priceType: "cents",
//     props: [220, 102, 1.06, 157, 300, 47, 327],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-263+.pdf",
//     description: "CF=263M BW=103 20dBRej@192&346MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7t9-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-263%2B",
//     mfgPart: "RBP-263+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7T9-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [263, 103, 1.19, 192, 346, 80, 371],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-280+.pdf",
//     description: "CF=280M BW=96 20dBRej@215&353MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7u2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-280%2B",
//     mfgPart: "RBP-280+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7U2-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [280, 96, 2.24, 215, 353, 192, 398],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-400+.pdf",
//     description: "CF=391M BW=244 20dBRej@241&580MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7u3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-400%2B",
//     mfgPart: "RBP-400+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7U3-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [391, 244, 1.31, 241, 580, 197, 696],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-415+.pdf",
//     description: "CF=415M BW=150 20dBRej@310&524MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7u4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-415%2B",
//     mfgPart: "RBP-415+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7U4-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [415, 150, 1.26, 310, 524, 145, 566],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-440+.pdf",
//     description: "CF=440M BW=126 20dBRej@346&532MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7u5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-440%2B",
//     mfgPart: "RBP-440+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7U5-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [440, 126, 1.42, 346, 532, 153, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RBP-650+.pdf",
//     description: "CF=650M BW=117 20dBRej@580&764MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7u6-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RBP-650%2B",
//     mfgPart: "RBP-650+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7U6-0604D",
//     price: 9700,
//     priceType: "cents",
//     props: [650, 117, 3.26, 580, 764, 531, 824],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1204",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-5350.pdf?v=032918",
//     description: "CF=53.5G BW=17 20dBRej@20700&85000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1204B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b839-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-5350.aspx",
//     mfgPart: "MFB-5350CHDIE",
//     models: "this part is missing models",
//     pn: "XM-B839-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [53500, 17, 1.28, 20700, 85000, null, 8000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1204_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1248",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-C550+.pdf",
//     description: "CF=550M BW=1000 20dBRej@84&1133MHz",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1248A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8t7-1007d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/RF-Filters.html",
//     mfgPart: "BPF-C550+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8T7-1007D",
//     price: 20100,
//     priceType: "cents",
//     props: [550, 1000, 0.42, 84, 1133, 81, 1162],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-3450.pdf?v=040418",
//     description: "CF=35G BW=23420 20dBRej@18000&47260MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b918-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-3450.aspx",
//     mfgPart: "MFB-3450CHDIE",
//     models: "this part is missing models",
//     pn: "XM-B918-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [35000, 23420, 1.54, 18000, 47260, 9330, 49180],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1609",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XBF-24+.pdf",
//     description: "CF=20G BW=12090 20dBRej@12480&26080MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1609A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b996-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XBF-24%2B",
//     mfgPart: "XBF-24+SMT",
//     models: "this part is missing models",
//     pn: "XM-B996-0404D",
//     price: 7900,
//     priceType: "cents",
//     props: [20000, 12090, 4.87, 12480, 26080, 11610, 27500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1609_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1432",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=6G BW=61 20dBRej@5947&6050MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1432A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9b1-1404d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "CavitySMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B9B1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B9B1-1404D",
//     price: 0,
//     priceType: "cents",
//     props: [6000, 61, 2.67, 5947, 6050, 5906, 6085],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1432",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=9G BW=97 20dBRej@8921&9082MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1432A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9b2-1404d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "CavitySMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B9B2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B9B2-1404D",
//     price: 0,
//     priceType: "cents",
//     props: [9000, 97, 3.28, 8921, 9082, 8868, 9142],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1432",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=15G BW=161 20dBRej@14850&15120MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1432A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9b4-1404d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "CavitySMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B9B4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B9B4-1404D",
//     price: 0,
//     priceType: "cents",
//     props: [15000, 161, 2.56, 14850, 15120, 14780, 15220],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1432",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=18G BW=202 20dBRej@17850&18160MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1432A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9b5-1404d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "CavitySMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B9B5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B9B5-1404D",
//     price: 0,
//     priceType: "cents",
//     props: [18000, 202, 2.23, 17850, 18160, 17740, 18270],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1609",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XBF-163+.pdf",
//     description: "CF=16G BW=9800 20dBRej@9900&20200MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1609A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9b9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XBF-163%2B",
//     mfgPart: "XBF-163+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9B9-0404D",
//     price: 7900,
//     priceType: "cents",
//     props: [16000, 9800, 4.5, 9900, 20200, 9220, 21300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1609_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1609",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XBF-183+.pdf",
//     description: "CF=18G BW=11740 20dBRej@11320&24000MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1609A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9c1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XBF-183%2B",
//     mfgPart: "XBF-183+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9C1-0404D",
//     price: 7900,
//     priceType: "cents",
//     props: [18000, 11740, 4.27, 11320, 24000, 10520, 25300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1609_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1515",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BPF-F184+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c362-1408d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BPF-F184%2B",
//     mfgPart: "BPF-F184+SMT",
//     models: "this is the new part",
//     pn: "XM-C362-1408D",
//     price: 25300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1323",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCV-3641+.pdf",
//     description: "CF=3.641G BW=450 20dBRej@2930&4650MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1323A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c374-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCV-3641%2B",
//     mfgPart: "BFCV-3641+SMT",
//     models: "this part is missing models",
//     pn: "XM-C374-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [3641, 450, 1.6, 2930, 4650, 500, 5200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1375",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B259MC1S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=26G BW=3690 20dBRej@23090&28030MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1375A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c376-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B259MC1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C376-0404D",
//     price: 9400,
//     priceType: "cents",
//     props: [26000, 3690, 1.62, 23090, 28030, 21090, 28640],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1375_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1204",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-3300.pdf",
//     description: "CF=33G BW=14000 20dBRej@21120&43390MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1204B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3r1-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-3300.aspx",
//     mfgPart: "MFB-3300CHDIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C3R1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C3R1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [33000, 14000, 1.35, 21120, 43390, 14570, 47180],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1204_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-2025.pdf?v=022219",
//     description: "CF=20.25G BW=7.65 20dBRej@12000&31500MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3t5-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-2025.aspx",
//     mfgPart: "MFB-2025CHDIE",
//     models: "this part is missing models",
//     pn: "XM-C3T5-0204D",
//     price: 34800,
//     priceType: "cents",
//     props: [20250, 7.65, 1.5, 12000, 31500, 5000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-2400.pdf?v=040418",
//     description: "CF=24G BW=9830 20dBRej@14420&29480MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3t6-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-2400.aspx",
//     mfgPart: "MFB-2400CHDIE",
//     models: "this part is missing models",
//     pn: "XM-C3T6-0204D",
//     price: 34800,
//     priceType: "cents",
//     props: [24000, 9830, 1.8, 14420, 29480, 7000, 30390],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-2500.pdf?v=040418",
//     description: "CF=25G BW=16560 20dBRej@12410&33500MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3t7-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-2500.aspx",
//     mfgPart: "MFB-2500CHDIE",
//     models: "this part is missing models",
//     pn: "XM-C3T7-0204D",
//     price: 34800,
//     priceType: "cents",
//     props: [25000, 16560, 1.53, 12410, 33500, 5740, 34390],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-2625.pdf?v=022219",
//     description: "CF=26.25G BW=8500 20dBRej@16000&39000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3t8-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-2625.aspx",
//     mfgPart: "MFB-2625CHDIE",
//     models: "this part is missing models",
//     pn: "XM-C3T8-0204D",
//     price: 34800,
//     priceType: "cents",
//     props: [26250, 8500, 1.5, 16000, 39000, 6000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-3175.pdf?v=022219",
//     description: "CF=31.75G BW=10100 20dBRej@20700&46300MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3t9-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-3175.aspx",
//     mfgPart: "MFB-3175CHDIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C3T9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C3T9-0204D",
//     price: 34800,
//     priceType: "cents",
//     props: [31750, 10100, 1.5, 20700, 46300, 10000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-3325.pdf?v=022219",
//     description: "CF=33.25G BW=2300 20dBRej@26500&39500MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3u1-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-3325.aspx",
//     mfgPart: "MFB-3325CHDIE",
//     models: "this part is missing models",
//     pn: "XM-C3U1-0204D",
//     price: 34800,
//     priceType: "cents",
//     props: [33250, 2300, 2.5, 26500, 39500, 25000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-3475.pdf?v=022219",
//     description: "CF=34.75G BW=10500 20dBRej@22500&50000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3u2-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-3475.aspx",
//     mfgPart: "MFB-3475CHDIE",
//     models: "this part is missing models",
//     pn: "XM-C3U2-0204D",
//     price: 34800,
//     priceType: "cents",
//     props: [34750, 10500, 2, 22500, 50000, 5000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1506",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B100MC5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=10G BW=1840 20dBRej@8935&11350MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1506A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c452-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B100MC5SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C452D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C452-0404D",
//     price: 16900,
//     priceType: "cents",
//     props: [10000, 1840, 1.3, 8935, 11350, 8540, 11730],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1505",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XBF-24-D+.pdf",
//     description: "CF=20G BW=13500 20dBRej@12610&27000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1505A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c453-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XBF-24-DG%2B",
//     mfgPart: "XBF-24-D+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C453D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C453-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [20000, 13500, 4.24, 12610, 27000, 11780, 28690],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1507",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-63M-D+.pdf",
//     description: "F=5.9G-26G IL=0.8dB 20dBRej@4210 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1507A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c454-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-63M-DG%2B",
//     mfgPart: "XHF-63M-D+DIE",
//     models: "this part is missing models",
//     pn: "XM-C454-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [5900, 26000, 0.8, 4750, 4210, 4010],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1507_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0982",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXBP-1430+.pdf",
//     description: "CF=1.43G BW=1791 20dBRej@596&2700MHz",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0982A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c579-0804d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-1430%2B",
//     mfgPart: "SXBP-1430+SMT",
//     models: "this part is missing models",
//     pn: "XM-C579-0804D",
//     price: 11500,
//     priceType: "cents",
//     props: [1430, 1791, 0.77, 596, 2700, 541, 2833],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0982_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0243",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/FB-0785SM.pdf",
//     description: "CF=7.85G BW=1200 20dBRej@6300&9550MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0243C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5a6-1404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/fb-0785sm.aspx",
//     mfgPart: "FB-0785SMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5A6-1404D",
//     price: 28300,
//     priceType: "cents",
//     props: [7850, 1200, 7.85, 6300, 9550, 5700, 10200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0243",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/FB-0850SM.pdf",
//     description: "CF=8.5G BW=1350 20dBRej@6000&11000MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0243C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5a7-1404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/fb-0850sm.aspx",
//     mfgPart: "FB-0850SMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5A7-1404D",
//     price: 28300,
//     priceType: "cents",
//     props: [8500, 1350, 8.5, 6000, 11000, 5150, 11850],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0243",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/FB-0900SM.pdf",
//     description: "CF=9G BW=1200 20dBRej@6600&11200MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0243C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5a8-1404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/fb-0900sm.aspx",
//     mfgPart: "FB-0900SMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5A8-1404D",
//     price: 28300,
//     priceType: "cents",
//     props: [9000, 1200, 9, 6600, 11200, 5800, 12000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0243",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/FB-0955SM.pdf",
//     description: "CF=9.55G BW=1300 20dBRej@6700&12100MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0243C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5a9-1404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/fb-0955sm.aspx",
//     mfgPart: "FB-0955SMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5A9-1404D",
//     price: 28300,
//     priceType: "cents",
//     props: [9550, 1300, 9.55, 6700, 12100, 5600, 13200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0243",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/FB-1050SM.pdf",
//     description: "CF=10.5G BW=1800 20dBRej@7600&13200MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0243C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5b1-1404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/fb-1050sm.aspx",
//     mfgPart: "FB-1050SMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5B1-1404D",
//     price: 28300,
//     priceType: "cents",
//     props: [10500, 1800, 10.5, 7600, 13200, 6300, 14400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0243",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/FB-1140SM.pdf",
//     description: "CF=11.4G BW=1900 20dBRej@8100&14400MHz",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0243C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5b2-1404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/fb-1140sm.aspx",
//     mfgPart: "FB-1140SMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5B2-1404D",
//     price: 28300,
//     priceType: "cents",
//     props: [11400, 1900, 11.4, 8100, 14400, 6900, 15500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0242",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/FB-1215SM.pdf",
//     description: "CF=12.15G BW=1600 20dBRej@9150&15000MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0242C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5b3-1004d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/fb-1215sm.aspx",
//     mfgPart: "FB-1215SMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5B3-1004D",
//     price: 26100,
//     priceType: "cents",
//     props: [12150, 1600, 12.15, 9150, 15000, 7850, 16000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0242_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0242",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/FB-1575SM.pdf",
//     description: "CF=15.75G BW=2300 20dBRej@11400&18900MHz",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0242C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5b4-1004d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/fb-1575sm.aspx",
//     mfgPart: "FB-1575SMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5B4-1004D",
//     price: 24100,
//     priceType: "cents",
//     props: [15750, 2300, 15.75, 11400, 18900, 9900, 20300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0242_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-1100.pdf",
//     description: "CF=11G BW=3000 20dBRej@6500&17000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5b5-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-1100.aspx",
//     mfgPart: "MFB-1100CHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5B5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [11000, 3000, 2, 6500, 17000, 2000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MFB-1600.pdf",
//     description: "CF=16G BW=6000 20dBRej@8700&24500MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5b6-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-1600.aspx",
//     mfgPart: "MFB-1600CHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5B6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [16000, 6000, 1.5, 8700, 24500, 3000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1566",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B144MB1S_Datasheet.pdf.aspx",
//     description: "CF=14.5G BW=2050 20dBRej@13020&15540MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1566A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5n2-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://dev.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B144MB1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C5N2-0404D",
//     price: 18600,
//     priceType: "cents",
//     props: [14500, 2050, 1.51, 13020, 15540, 12440, 15790],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1566_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1593",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B056MB5S-Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=5.6G BW=1050 20dBRej@5020&6280MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1593A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c712-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B056MB5SSMT",
//     models: "this part is missing models",
//     pn: "XM-C712-0604D",
//     price: 30900,
//     priceType: "cents",
//     props: [5600, 1050, 1.32, 5020, 6280, 4800, 6425],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1593_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1592",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B083LB6S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=8.25G BW=1200 20dBRej@7630&9200MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1592A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c713-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B083LB6SSMT",
//     models: "this part is missing models",
//     pn: "XM-C713-0604D",
//     price: 25000,
//     priceType: "cents",
//     props: [8250, 1200, 1.46, 7630, 9200, 7330, 9500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1592_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1590",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B089NC4S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=9.1G BW=2430 20dBRej@7525&10530MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1590A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c714-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B089NC4SSMT",
//     models: "this part is missing models",
//     pn: "XM-C714-0404D",
//     price: 28400,
//     priceType: "cents",
//     props: [9100, 2430, 1.24, 7525, 10530, 7040, 11000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1590_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1592",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B094LA2S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=9.42G BW=830 20dBRej@8980&10020MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1592A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c715-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B094LA2SSMT",
//     models: "this part is missing models",
//     pn: "XM-C715-0604D",
//     price: 24300,
//     priceType: "cents",
//     props: [9420, 830, 2.23, 8980, 10020, 8800, 10150],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1592_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1590",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B095MB1S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=9.5G BW=1770 20dBRej@8500&10700MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1590A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c716-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B095MB1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C716-0404D",
//     price: 25700,
//     priceType: "cents",
//     props: [9500, 1770, 1.18, 8500, 10700, 8130, 11080],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1590_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1590",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B097MB0S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=9.7G BW=1615 20dBRej@8840&11000MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1590A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c717-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B097MB0SSMT",
//     models: "this part is missing models",
//     pn: "XM-C717-0404D",
//     price: 19700,
//     priceType: "cents",
//     props: [9700, 1615, 1.5, 8840, 11000, 8460, 11330],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1590_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1592",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B102MC1S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=10.25G BW=1180 20dBRej@7620&9200MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1592A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c718-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B102MC1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C718-0604D",
//     price: 22000,
//     priceType: "cents",
//     props: [10250, 1180, 2.25, 7620, 9200, 7325, 9490],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1592_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1590",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B099NC4S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=10.25G BW=3090 20dBRej@8360&12220MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1590A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c719-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B099NC4SSMT",
//     models: "this part is missing models",
//     pn: "XM-C719-0404D",
//     price: 17600,
//     priceType: "cents",
//     props: [10250, 3090, 1.11, 8360, 12220, 7590, 12890],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1590_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1592",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B105MB5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=10.6G BW=2250 20dBRej@9200&12350MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1592A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c721-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B105MB5SSMT",
//     models: "this part is missing models",
//     pn: "XM-C721-0604D",
//     price: 19200,
//     priceType: "cents",
//     props: [10600, 2250, 1.24, 9200, 12350, 8680, 12970],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1592_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1590",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B112MB1S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=11.2G BW=1900 20dBRej@10280&12810MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1590A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c722-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B112MB1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C722-0404D",
//     price: 21900,
//     priceType: "cents",
//     props: [11200, 1900, 1.35, 10280, 12810, 9850, 13220],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1590_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1590",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B111NC4S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=11.4G BW=3190 20dBRej@9540&13500MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1590A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c723-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B111NC4SSMT",
//     models: "this part is missing models",
//     pn: "XM-C723-0404D",
//     price: 17100,
//     priceType: "cents",
//     props: [11400, 3190, 1.18, 9540, 13500, 8935, 14190],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1590_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1590",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B116NC5S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=11.65G BW=3300 20dBRej@9710&13810MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1590A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c724-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B116NC5SSMT",
//     models: "this part is missing models",
//     pn: "XM-C724-0404D",
//     price: 18300,
//     priceType: "cents",
//     props: [11650, 3300, 1.03, 9710, 13810, 9100, 14540],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1590_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1592",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B118LB4S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=11.8G BW=1180 20dBRej@11080&12770MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1592A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c725-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B118LB4SSMT",
//     models: "this part is missing models",
//     pn: "XM-C725-0604D",
//     price: 19900,
//     priceType: "cents",
//     props: [11800, 1180, 1.35, 11080, 12770, 10670, 13030],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1592_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1566",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B127MB2S-Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=12.75G BW=1880 20dBRej@11430&13720MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1566A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c726-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B127MB2SSMT",
//     models: "this part is missing models",
//     pn: "XM-C726-0404D",
//     price: 18600,
//     priceType: "cents",
//     props: [12750, 1880, 1.64, 11430, 13720, 10950, 13910],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1566_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1566",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B138MB1S-Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=13.75G BW=1875 20dBRej@12370&14700MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1566A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c727-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B138MB1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C727-0404D",
//     price: 18600,
//     priceType: "cents",
//     props: [13750, 1875, 1.59, 12370, 14700, 11860, 14920],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1566_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1594",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B138LA2S-Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=13.75G BW=1100 20dBRej@13110&14480MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1594A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c728-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B138LA2SSMT",
//     models: "this part is missing models",
//     pn: "XM-C728-0604D",
//     price: 21100,
//     priceType: "cents",
//     props: [13750, 1100, 2.1, 13110, 14480, 12840, 14660],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1594_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1595",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B150OG0S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=14.5G BW=3380 20dBRej@12500&16970MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1595A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c729-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B150OG0SSMT",
//     models: "this part is missing models",
//     pn: "XM-C729-0404D",
//     price: 16000,
//     priceType: "cents",
//     props: [14500, 3380, 0.89, 12500, 16970, 11080, 18410],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1595_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1592",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B148LA2S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=14.75G BW=1330 20dBRej@13640&15330MHz",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1592A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c731-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B148LA2SSMT",
//     models: "this part is missing models",
//     pn: "XM-C731-0604D",
//     price: 22200,
//     priceType: "cents",
//     props: [14750, 1330, 2.38, 13640, 15330, 13280, 15520],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1592_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1591",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B149MC1S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=15G BW=1330 20dBRej@13630&15330MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1591A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c732-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B149MC1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C732-0404D",
//     price: 17400,
//     priceType: "cents",
//     props: [15000, 1330, 3.1, 13630, 15330, 13280, 15520],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1591_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1566",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B160KA1S-Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=16G BW=1500 20dBRej@15030&16960MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1566A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c733-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B160KA1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C733-0404D",
//     price: 18400,
//     priceType: "cents",
//     props: [16000, 1500, 1.76, 15030, 16960, 14500, 17580],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1566_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1566",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B165LA1S-Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=16.5G BW=1940 20dBRej@15080&17500MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1566A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c734-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B165LA1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C734-0404D",
//     price: 18600,
//     priceType: "cents",
//     props: [16500, 1940, 1.76, 15080, 17500, 14540, 17780],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1566_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1591",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B168MB1S-Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=16.75G BW=3030 20dBRej@14520&18360MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1591A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c735-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B168MB1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C735-0404D",
//     price: 17400,
//     priceType: "cents",
//     props: [16750, 3030, 0.95, 14520, 18360, 13530, 18660],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1591_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1652",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B279KB1S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=27.9G BW=1620 20dBRej@26870&29160MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1652A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c737-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B279KB1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C737-0404D",
//     price: 9600,
//     priceType: "cents",
//     props: [27900, 1620, 2.95, 26870, 29160, 26000, 29650],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1652_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1596",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Bandpass-Filters/B280MC1S_Datasheet.pdf.aspx?lang=en-US",
//     description: "CF=28G BW=3500 20dBRej@25800&30380MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1596A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c738-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B280MC1SSMT",
//     models: "this part is missing models",
//     pn: "XM-C738-0404D",
//     price: 18200,
//     priceType: "cents",
//     props: [28000, 3500, 1.89, 25800, 30380, 24440, 31140],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-770C+.pdf",
//     description: "CF=770M BW=67 20dBRej@719&827MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c752-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-770C+%2B",
//     mfgPart: "CBP-770C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C752-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [770, 67, 1.3, 719, 827, 685, 870],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-840C+.pdf",
//     description: "CF=840M BW=200 20dBRej@690&1014MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c753-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-840C+%2B",
//     mfgPart: "CBP-840C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C753-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [840, 200, 0.51, 690, 1014, 622, 1110],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-893C+.pdf",
//     description: "CF=892.5M BW=125 20dBRej@785&1010MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c754-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-893C+%2B",
//     mfgPart: "CBP-893C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C754-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [892.5, 125, 0.71, 785, 1010, 730, 1090],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-915C+.pdf",
//     description: "CF=915M BW=85 20dBRej@840&988MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c755-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-915C+%2B",
//     mfgPart: "CBP-915C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C755-0808D",
//     price: 18300,
//     priceType: "cents",
//     props: [915, 85, 1.05, 840, 988, 797, 1050],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1034C+.pdf",
//     description: "CF=1.034G BW=272 20dBRej@830&1305MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c756-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1034C%2B",
//     mfgPart: "CBP-1034C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C756-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [1034, 272, 0.58, 830, 1305, 747, 1490],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1062C+.pdf",
//     description: "CF=1.062G BW=483 20dBRej@775&1526MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c757-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1062C%2B",
//     mfgPart: "CBP-1062C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C757-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [1062, 483, 0.39, 775, 1526, 677, 1717],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1090C+.pdf",
//     description: "CF=1.09G BW=145 20dBRej@978&1215MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c758-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1090C%2B",
//     mfgPart: "CBP-1090C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C758-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [1090, 145, 0.94, 978, 1215, 923, 1322],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1170C+.pdf",
//     description: "CF=1.17G BW=285 20dBRej@952&1460MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c759-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1170C%2B",
//     mfgPart: "CBP-1170C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C759-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [1170, 285, 0.51, 952, 1460, 860, 1665],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1260C+.pdf",
//     description: "CF=1.26G BW=272 20dBRej@1063&1540MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c761-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1260C%2B",
//     mfgPart: "CBP-1260C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C761-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [1260, 272, 0.75, 1063, 1540, 971, 1755],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1280C+.pdf",
//     description: "CF=1.28G BW=441 20dBRej@1000&1754MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c762-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1280C%2B",
//     mfgPart: "CBP-1280C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C762-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [1280, 441, 0.3, 1000, 1754, 894, 2000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1307C+.pdf",
//     description: "CF=1.3075G BW=380 20dBRej@1042&1719MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c763-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1307C%2B",
//     mfgPart: "CBP-1307C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C763-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [1307.5, 380, 0.52, 1042, 1719, 928, 1950],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1350C+.pdf",
//     description: "CF=1.35G BW=218 20dBRej@1164&1584MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c764-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1350C%2B",
//     mfgPart: "CBP-1350C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C764-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [1350, 218, 0.76, 1164, 1584, 1071, 1775],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1748C+.pdf",
//     description: "CF=1.748G BW=183 20dBRej@1606&1920MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c765-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1748C%2B",
//     mfgPart: "CBP-1748C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C765-0808D",
//     price: 19200,
//     priceType: "cents",
//     props: [1748, 183, 0.74, 1606, 1920, 1530, 2090],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-1950C+.pdf",
//     description: "CF=1.95G BW=217 20dBRej@1785&2140MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c766-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-1950C%2B",
//     mfgPart: "CBP-1950C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C766-0808D",
//     price: 19200,
//     priceType: "cents",
//     props: [1950, 217, 1.01, 1785, 2140, 1709, 2317],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-A1060C+.pdf",
//     description: "CF=1.06G BW=210 20dBRej@900&1275MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c767-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-A1060C%2B",
//     mfgPart: "CBP-A1060C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C767-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [1060, 210, 0.63, 900, 1275, 822, 1443],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-A1230C+.pdf",
//     description: "CF=1.23G BW=328 20dBRej@886&1569MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c768-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-A1230C%2B",
//     mfgPart: "CBP-A1230C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C768-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [1230, 328, 0.43, 886, 1569, 830, 1792],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1114",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CBP-B1230C+.pdf",
//     description: "CF=1.23G BW=393 20dBRej@958&1619MHz",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1114A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c772-0808d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-B1230C%2B",
//     mfgPart: "CBP-B1230C+SMT",
//     models: "this part is missing models",
//     pn: "XM-C772-0808D",
//     price: 17900,
//     priceType: "cents",
//     props: [1230, 393, 0.44, 958, 1619, 865, 1850],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1114_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1199",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=35G BW=8000 20dBRej@29830&40330MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1199A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7h3-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "https://quanticxmw.com/documentation/",
//     mfgPart: "ThinFilmBPFDIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C7H3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C7H3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [35000, 8000, 2.36, 29830, 40330, 24680, 42590],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0616",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BFCW-542+.pdf",
//     description: "CF=542M BW=1300 20dBRej@3300&8000MHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0616A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8t1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCW-542%2B",
//     mfgPart: "BFCW-542+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8T1-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [542, 1300, 1.3, 3300, 8000, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "7126",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://akoustis.com/product/akf-1938/",
//     mfgPart: "AKF-1938",
//     models: "this is the new part",
//     pn: "XM-A476-0304D",
//     price: 15600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "2061_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "7128",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://www.ctscorp.com/connect_product_line/band-pass-filter/?fwp_search=CER0273B",
//     mfgPart: "CER0273B",
//     models: "this is the new part",
//     pn: "XM-D1V8-0705D",
//     price: 11200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1906_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "6746",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B180MA1S",
//     models: "this is the new part",
//     pn: "XM-D269-0604D",
//     price: 23900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1594_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "6774",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B220LA0S",
//     models: "this is the new part",
//     pn: "XM-C1A7-0604D",
//     price: 25500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1970_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "6774",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters.aspx",
//     mfgPart: "B291MB0S",
//     models: "this is the new part",
//     pn: "XM-A484-0604D",
//     price: 22100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1970_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "7134",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CBP-2250A%2B",
//     mfgPart: "CBP-2250A+",
//     models: "this is the new part",
//     pn: "XM-C7M7-1006D",
//     price: 21000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "2058_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "4774",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXBP-1200%2B",
//     mfgPart: "SXBP-1200+",
//     models: "this is the new part",
//     pn: "XM-D1C4-1004D",
//     price: 12600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0667_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "7146",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/880273",
//     mfgPart: "880273",
//     models: "this is the new part",
//     pn: "XM-A475-0204D",
//     price: 60200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "2060_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "CF=4.25G BW=6800 20dBRej@38980&47840MHz",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0676A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B424MB1S",
//     models: "this part is missing models",
//     pn: "XM-B7D6-0404D",
//     price: 13100,
//     priceType: "cents",
//     props: [4250, 6800, 0.08, 38980, 47840, 38070, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0676_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "5922",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1247A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=THP-1050%2B",
//     mfgPart: "THP-1050+",
//     models: "this part is missing models",
//     pn: "XM-B8T6-0404D",
//     price: 9000,
//     priceType: "cents",
//     props: [null, null, 0.6, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/bp-series-mlo-band-pass-filter/",
//     mfgPart: "BP0EA1950A700",
//     models: "this is the new part",
//     pn: "XM-C7B1-0604D",
//     price: 13600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "0782_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B207LA0S",
//     models: "this is the new part",
//     pn: "XM-D2E9-0604D",
//     price: 21900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1594_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B192NB2S",
//     models: "this is the new part",
//     pn: "XM-D139-0604D",
//     price: 26400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1970_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B230LA0S",
//     models: "this is the new part",
//     pn: "XM-D2T9-0604D",
//     price: 26400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1970_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCQ-2872%2B",
//     mfgPart: "BFCQ-2872+",
//     models: "this is the new part",
//     pn: "XM-D3U5-0204D",
//     price: 9500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "2352_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BFCQ-1162%2B",
//     mfgPart: "BFCQ-1162+",
//     models: "this is the new part",
//     pn: "XM-D479-0204D",
//     price: 9500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "2352_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/mfb-1445sm.aspx",
//     mfgPart: "MFB-1445SM",
//     models: "this is the new part",
//     pn: "XM-D482-0204D",
//     price: 34800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "2458_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B038NC4S",
//     models: "this is the new part",
//     pn: "XM-D4C1-0604D",
//     price: 26400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "2546_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/admv8416.html",
//     mfgPart: "ADMV8416ACPZ",
//     models: "this is the new part",
//     pn: "XM-D3G5-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "2529_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/filters/am3135/",
//     mfgPart: "AM3135",
//     models: "this is the new part",
//     pn: "XM-D429-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "2530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandpass-Filters",
//     mfgPart: "B250LA0S",
//     models: "this is the new part",
//     pn: "XM-D4R5-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Band Pass Filters",
//     newBRDImage: "1970_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=3dB F=DC-20G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1m4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "WTVA0300N07SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1M4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1M4-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 3, -0.007, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=2dB F=DC-20G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1t3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "WTVA0200N07SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1T3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1T3-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 2, -0.007, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=4dB F=DC-20G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1t4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "WTVA0400N07SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1T4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1T4-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 4, -0.007, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=6dB F=DC-20G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1t5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "WTVA0600N07SMTFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1T5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1T5-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 6, -0.007, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0713",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=6dB F=DC-12.4G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0713A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5g5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0600N09SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5G5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5G5-0204D",
//     price: 9100,
//     priceType: "cents",
//     props: [null, 6, -0.009, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0713_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0713",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=2dB F=DC-12.4G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0713A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5v4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0200N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5V4-0204D",
//     price: 11200,
//     priceType: "cents",
//     props: [null, 2, -0.009, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0713_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0713",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=8dB F=DC-12.4G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0713A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5v5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0800N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5V5-0204D",
//     price: 11200,
//     priceType: "cents",
//     props: [null, 8, -0.009, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0713_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0713",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=7dB F=DC-12.4G TCA=-0.004 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0713A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7f1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0700N04SMT",
//     models: "this part is missing models",
//     pn: "XM-B7F1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.004, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0713_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=5dB F=DC-20G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c123-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "WTVA0500N03SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C123-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 5, -0.003, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=1dB F=DC-12.4G TCA=-0.002 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c389-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0100N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C389-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.002, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=1dB F=DC-12.4G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c391-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0100N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C391-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.003, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=1dB F=DC-12.4G TCA=-0.004 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c392-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0100N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C392-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.004, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=1dB F=DC-12.4G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c393-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0100N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C393-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.005, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=1dB F=DC-12.4G TCA=-0.006 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c394-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0100N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C394-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.006, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=1dB F=DC-12.4G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c395-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0100N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C395-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.007, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=1dB F=DC-12.4G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c396-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0100N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C396-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.009, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=2dB F=DC-12.4G TCA=-0.002 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c397-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0200N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C397-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.002, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=2dB F=DC-12.4G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c398-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0200N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C398-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.003, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=2dB F=DC-12.4G TCA=-0.004 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c399-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0200N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C399-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.004, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=2dB F=DC-12.4G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3a1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0200N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3A1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.005, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=2dB F=DC-12.4G TCA=-0.006 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3a2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0200N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3A2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.006, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=2dB F=DC-12.4G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3a3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0200N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3A3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.007, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=2dB F=DC-12.4G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3a4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0200N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3A4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.009, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=3dB F=DC-12.4G TCA=-0.002 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3a5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0300N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3A5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.002, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=3dB F=DC-12.4G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3a6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0300N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3A6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.003, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=3dB F=DC-12.4G TCA=-0.004 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3a7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0300N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3A7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.004, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=3dB F=DC-12.4G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3a8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0300N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3A8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.005, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=3dB F=DC-12.4G TCA=-0.006 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3a9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0300N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3A9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.006, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=3dB F=DC-12.4G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3b1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0300N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3B1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.007, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=3dB F=DC-12.4G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3b2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0300N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3B2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.009, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=4dB F=DC-12.4G TCA=-0.002 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3b3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0400N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3B3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.002, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=4dB F=DC-12.4G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3b4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0400N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3B4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.003, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=4dB F=DC-12.4G TCA=-0.004 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3b5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0400N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3B5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.004, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=4dB F=DC-12.4G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3b6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0400N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3B6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.005, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=4dB F=DC-12.4G TCA=-0.006 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3b7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0400N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3B7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.006, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=4dB F=DC-12.4G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3b8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0400N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3B8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.007, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=4dB F=DC-12.4G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3b9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0400N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3B9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.009, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=5dB F=DC-12.4G TCA=-0.002 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3c1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0500N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3C1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.002, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=5dB F=DC-12.4G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3c2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0500N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3C2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.003, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=5dB F=DC-12.4G TCA=-0.004 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3c3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0500N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3C3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.004, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=5dB F=DC-12.4G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3c4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0500N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3C4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.005, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=5dB F=DC-12.4G TCA=-0.006 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3c5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0500N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3C5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.006, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=5dB F=DC-12.4G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3c6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0500N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3C6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.007, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=5dB F=DC-12.4G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3c7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0500N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3C7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.009, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=6dB F=DC-12.4G TCA=-0.002 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3c8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0600N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3C8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.002, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=6dB F=DC-12.4G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3c9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0600N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3C9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.003, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=6dB F=DC-12.4G TCA=-0.004 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3d1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0600N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3D1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.004, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=6dB F=DC-12.4G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3d2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0600N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3D2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.005, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=6dB F=DC-12.4G TCA=-0.006 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3d3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0600N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3D3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.006, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=6dB F=DC-12.4G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3d4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0600N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3D4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.007, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=6dB F=DC-12.4G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3d5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0600N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3D5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.009, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=7dB F=DC-12.4G TCA=-0.002 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3d6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0700N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3D6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.002, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=7dB F=DC-12.4G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3d7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0700N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3D7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.003, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=7dB F=DC-12.4G TCA=-0.004 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3d8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0700N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3D8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.004, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=7dB F=DC-12.4G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3d9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0700N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3D9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.005, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=7dB F=DC-12.4G TCA=-0.006 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3e1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0700N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3E1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.006, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=7dB F=DC-12.4G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3e2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0700N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3E2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.007, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=7dB F=DC-12.4G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3e3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0700N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3E3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.009, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=8dB F=DC-12.4G TCA=-0.002 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3e4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0800N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3E4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.002, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=8dB F=DC-12.4G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3e5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0800N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3E5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.003, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=8dB F=DC-12.4G TCA=-0.004 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3e6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0800N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3E6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.004, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=8dB F=DC-12.4G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3e7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0800N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3E7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.005, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=8dB F=DC-12.4G TCA=-0.006 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3e8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0800N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3E8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.006, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=8dB F=DC-12.4G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3e9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0800N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3E9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.007, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=9dB F=DC-12.4G TCA=-0.002 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3f2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0900N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3F2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.002, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=9dB F=DC-12.4G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3f3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0900N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3F3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.003, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=9dB F=DC-12.4G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3f5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0900N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3F5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.005, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=9dB F=DC-12.4G TCA=-0.006 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3f6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0900N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3F6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.006, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=9dB F=DC-12.4G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3f7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0900N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3F7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.007, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0070",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyOTdf",
//     description: "Atten=9dB F=DC-12.4G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0070I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3f8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/thermopad®/",
//     mfgPart: "MTVA0900N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C3F8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.009, 200, 0, 12400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0070_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=3dB F=DC-20G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3g1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "WTVA0300N05SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C3G1-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 3, -0.005, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=-.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5n3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5N3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=-.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5n4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5N4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=-.004 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5n5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5N5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.004, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=-.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5n6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5N6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=-.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5n7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5N7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=-.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5n8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5N8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=-.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5n9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100N08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5N9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=-.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5p1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5P1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5p2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100P02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5P2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, 0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5p3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100P03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5P3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, 0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5p4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100P05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5P4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, 0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5p5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100P06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5P5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, 0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5p6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100P07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5P6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, 0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5p7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100P08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5P7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, 0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=1dB F=DC-6G TCA=.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5p8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0100P09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5P8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, 0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=-.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5p9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5P9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=-.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5r1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5R1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=-.004 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5r2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5R2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.004, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=-.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5r3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5R3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=-.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5r4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5R4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=-.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5r5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5R5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=-.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5r6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200N08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5R6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=-.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5r7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5R7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5r8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200P02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5R8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, 0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5r9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200P03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5R9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, 0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5t1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200P05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5T1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, 0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5t2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200P06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5T2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, 0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5t3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200P07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5T3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, 0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5t4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200P08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5T4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, 0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=2dB F=DC-6G TCA=.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5t5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0200P09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5T5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, 0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=-.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5t6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5T6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=-.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5t7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5T7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=-.004 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5t8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5T8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.004, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=-.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5t9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5T9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=-.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5u1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5U1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=-.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5u2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5U2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=-.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5u3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300N08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5U3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=-.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5u4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5U4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5u5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300P02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5U5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, 0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5u6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300P03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5U6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, 0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5u7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300P05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5U7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, 0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5u8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300P06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5U8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, 0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5u9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300P07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5U9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, 0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5v1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300P08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5V1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, 0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=3dB F=DC-6G TCA=.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5v2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0300P09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5V2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, 0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=-.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5v3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5V3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=-.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5v4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5V4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=-.004 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5v5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5V5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.004, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=-.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5v6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5V6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=-.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5v7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5V7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=-.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5v8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5V8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=-.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5v9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400N08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5V9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=-.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5w1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5W1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5w2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400P02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5W2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, 0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5w3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400P03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5W3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, 0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5w4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400P05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5W4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, 0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5w5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400P06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5W5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, 0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5w6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400P07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5W6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, 0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5w7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400P08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5W7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, 0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=4dB F=DC-6G TCA=.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5w8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0400P09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5W8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, 0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=-.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5w9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5W9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=-.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5y1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5Y1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=-.004 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5y2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5Y2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.004, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=-.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5y3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5Y3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=-.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5y4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5Y4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=-.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5y5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5Y5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=-.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5y6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500N08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5Y6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=-.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5y7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5Y7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5y8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500P02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5Y8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, 0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5y9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500P03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C5Y9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, 0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c611-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500P05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C611-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, 0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c612-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500P06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C612-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, 0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c613-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500P07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C613-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, 0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c614-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500P08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C614-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, 0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=5dB F=DC-6G TCA=.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c615-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0500P09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C615-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, 0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=-.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c616-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C616-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=-.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c617-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C617-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=-.004 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c618-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C618-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.004, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=-.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c619-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C619-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=-.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c621-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C621-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=-.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c622-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C622-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=-.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c623-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600N08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C623-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=-.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c624-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C624-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c625-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600P02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C625-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, 0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c626-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600P03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C626-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, 0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c627-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600P05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C627-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, 0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c628-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600P06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C628-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, 0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c629-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600P07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C629-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, 0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c631-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600P08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C631-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, 0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=6dB F=DC-6G TCA=.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c632-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0600P09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C632-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, 0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=-.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c633-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C633-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=-.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c634-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C634-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=-.004 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c635-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C635-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.004, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=-.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c636-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C636-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=-.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c637-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C637-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=-.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c638-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C638-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=-.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c639-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700N08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C639-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=-.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c641-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C641-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c642-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700P02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C642-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, 0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c643-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700P03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C643-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, 0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c644-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700P05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C644-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, 0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c645-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700P06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C645-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, 0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c646-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700P07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C646-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, 0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c647-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700P08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C647-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, 0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=7dB F=DC-6G TCA=.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c648-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0700P09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C648-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, 0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=-.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c649-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C649-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=-.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c651-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C651-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=-.004 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c652-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C652-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.004, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=-.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c653-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C653-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=-.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c654-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C654-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=-.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c655-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C655-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=-.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c656-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800N08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C656-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=-.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c657-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C657-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c658-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800P02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C658-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, 0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c659-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800P03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C659-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, 0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c661-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800P05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C661-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, 0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c662-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800P06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C662-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, 0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c663-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800P07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C663-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, 0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c664-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800P08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C664-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, 0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=8dB F=DC-6G TCA=.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c665-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0800P09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C665-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, 0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=-.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c666-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C666-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=-.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c667-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C667-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=-.004 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c668-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C668-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.004, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=-.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c669-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C669-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=-.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c671-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C671-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=-.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c672-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C672-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=-.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c673-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900N08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C673-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=-.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c674-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C674-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c675-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900P02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C675-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, 0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c676-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900P03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C676-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, 0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c677-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900P05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C677-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, 0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c678-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900P06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C678-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, 0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c679-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900P07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C679-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, 0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c681-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900P08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C681-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, 0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=9dB F=DC-6G TCA=.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c682-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA0900P09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C682-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, 0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=-.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c683-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000N02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C683-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, -0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=-.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c684-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000N03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C684-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, -0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=-.004 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c685-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000N04W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C685-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, -0.004, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=-.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c686-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000N05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C686-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, -0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=-.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c687-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000N06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C687-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, -0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=-.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c688-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000N07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C688-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, -0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=-.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c691-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000N09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C691-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, -0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=.002 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c692-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000P02W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C692-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, 0.002, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=.003 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c693-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000P03W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C693-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, 0.003, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=.005 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c694-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000P05W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C694-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, 0.005, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=.006 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c695-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000P06W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C695-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, 0.006, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=.007 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c696-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000P07W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C696-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, 0.007, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c697-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000P08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C697-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, 0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=.009 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c698-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000P09W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C698-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, 0.009, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1246",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.smithsinterconnect.com/getmedia/1b44a3ca-d0a0-4415-bfb0-b3f9ead4d0a6/TVA-Thermopad-Series",
//     description: "Atten=10dB F=DC-6G TCA=-.008 Pwr=2000mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1246A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c699-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "TVA1000N08W3SMT",
//     models: "this part is missing models",
//     pn: "XM-C699-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, -0.008, 2000, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "1246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=2dB F=18G-27G TCA=-0.003 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6b5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA02N035SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6B5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.003, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=2dB F=18G-27G TCA=-0.005 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6b6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA02N055SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6B6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.005, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=2dB F=18G-27G TCA=-0.006 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6b7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA02N065SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6B7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.006, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=2dB F=18G-27G TCA=-0.007 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6b8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA02N075SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6B8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.007, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=2dB F=27G-36G TCA=-0.003 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6b9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA02N036SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6B9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.003, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=2dB F=27G-36G TCA=-0.005 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6c1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA02N056SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6C1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.005, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=2dB F=27G-36G TCA=-0.006 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6c2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA02N066SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6C2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.006, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=2dB F=27G-36G TCA=-0.007 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6c3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA02N076SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6C3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 2, -0.007, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=3dB F=18G-27G TCA=-0.003 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6c4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA03N035SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6C4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.003, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=3dB F=18G-27G TCA=-0.005 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6c5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA03N055SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6C5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.005, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=3dB F=18G-27G TCA=-0.006 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6c6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA03N065SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6C6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.006, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=3dB F=18G-27G TCA=-0.007 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6c7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA03N075SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6C7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.007, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=3dB F=27G-36G TCA=-0.003 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6c8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA03N036SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6C8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.003, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=3dB F=27G-36G TCA=-0.005 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6c9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA03N056SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6C9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.005, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=3dB F=27G-36G TCA=-0.006 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6d1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA03N066SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6D1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.006, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=3dB F=27G-36G TCA=-0.007 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6d2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA03N076SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6D2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 3, -0.007, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=4dB F=18G-27G TCA=-0.003 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6d3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA04N035SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6D3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.003, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=4dB F=18G-27G TCA=-0.005 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6d4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA04N055SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6D4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.005, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=4dB F=18G-27G TCA=-0.006 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6d5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA04N065SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6D5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.006, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=4dB F=18G-27G TCA=-0.007 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6d6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA04N075SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6D6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.007, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=4dB F=27G-36G TCA=-0.003 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6d7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA04N036SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6D7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.003, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=4dB F=27G-36G TCA=-0.005 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6d8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA04N056SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6D8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.005, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=4dB F=27G-36G TCA=-0.006 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6d9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA04N066SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6D9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.006, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=4dB F=27G-36G TCA=-0.007 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6e1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA04N076SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6E1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 4, -0.007, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=5dB F=18G-27G TCA=-0.003 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6e2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA05N035SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6E2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.003, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=5dB F=18G-27G TCA=-0.005 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6e3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA05N055SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6E3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.005, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=5dB F=18G-27G TCA=-0.006 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6e4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA05N065SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6E4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.006, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=5dB F=18G-27G TCA=-0.007 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6e5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA05N075SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6E5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.007, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=5dB F=27G-36G TCA=-0.003 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6e6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA05N036SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6E6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.003, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=5dB F=27G-36G TCA=-0.005 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6e7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA05N056SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6E7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.005, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=5dB F=27G-36G TCA=-0.006 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6e8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA05N066SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6E8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.006, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=5dB F=27G-36G TCA=-0.007 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6e9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA05N076SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6E9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 5, -0.007, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=6dB F=18G-27G TCA=-0.003 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6f1-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA06N035SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6F1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.003, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=6dB F=18G-27G TCA=-0.005 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6f2-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA06N055SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6F2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.005, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=6dB F=18G-27G TCA=-0.006 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6f3-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA06N065SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6F3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.006, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=6dB F=18G-27G TCA=-0.007 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6f4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA06N075SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6F4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.007, 100, 18000, 27000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=6dB F=27G-36G TCA=-0.003 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6f5-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA06N036SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6F5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.003, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=6dB F=27G-36G TCA=-0.005 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6f6-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA06N056SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6F6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.005, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=6dB F=27G-36G TCA=-0.006 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6f7-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA06N066SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6F7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.006, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1140",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Atten=6dB F=27G-36G TCA=-0.007 Pwr=100mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1140B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6f8-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "KTVA06N076SMTSMT",
//     models: "this part is missing models",
//     pn: "XM-C6F8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 6, -0.007, 100, 27000, 36000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1570",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgyNzZf",
//     description: "Atten=3dB F=DC-6G TCA=-0.009 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1570A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6f9-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/thermopad%C2%AE/",
//     mfgPart: "AN7-3N9FSMT",
//     models: "this part is missing models",
//     pn: "XM-C6F9-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [null, 3, -0.009, 200, 0, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=2dB F=DC-20G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c831-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0200N03SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C831-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 2, -0.003, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=3dB F=DC-20G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c832-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0300N03SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C832-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 3, -0.003, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=4dB F=DC-20G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c833-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0400N03SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C833-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 4, -0.003, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=6dB F=DC-20G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c834-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0600N03SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C834-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 6, -0.003, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=7dB F=DC-20G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c835-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0700N03SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C835-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.003, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=8dB F=DC-20G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c836-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0800N03SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C836-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.003, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=9dB F=DC-20G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c837-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0900N03SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C837-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.003, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=10dB F=DC-20G TCA=-0.003 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c838-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA1000N03SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C838-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, -0.003, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=2dB F=DC-20G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c841-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0200N05SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C841-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 2, -0.005, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=4dB F=DC-20G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c842-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0400N05SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C842-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 4, -0.005, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=5dB F=DC-20G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c843-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0500N05SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C843-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 5, -0.005, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=6dB F=DC-20G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c844-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0600N05SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C844-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 6, -0.005, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=7dB F=DC-20G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c845-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0700N05SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C845-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.005, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=8dB F=DC-20G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c846-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0800N05SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C846-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.005, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=9dB F=DC-20G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c847-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0900N05SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C847-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.005, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=10dB F=DC-20G TCA=-0.005 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c848-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA1000N05SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C848-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 10, -0.005, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=5dB F=DC-20G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c851-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0500N07SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C851-0204D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 5, -0.007, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=7dB F=DC-20G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c852-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0700N07SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C852-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 7, -0.007, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=8dB F=DC-20G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c853-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0800N07SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C853-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8, -0.007, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=9dB F=DC-20G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c854-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA0900N07SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C854-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 9, -0.007, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0845",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDgzMTVf",
//     description: "Atten=1dB F=DC-20G TCA=-0.007 Pwr=200mW ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0845A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c855-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/53c1565e-dd02-4dce-8de1-14b9d47c97aa/WTVA-Thermopad",
//     mfgPart: "WTVA1000N07SMTFSMT",
//     models: "this part is missing models",
//     pn: "XM-C855-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1, -0.007, 200, 0, 20000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0845_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/9aad42a8-ea56-42f9-9e33-8d2b8c9aabc5/MTVA-Thermopad",
//     mfgPart: "MTVA0300N09W3F",
//     models: "this is the new part",
//     pn: "XM-D4Y6-0204D",
//     price: 11200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0713_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "TVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/getmedia/9aad42a8-ea56-42f9-9e33-8d2b8c9aabc5/MTVA-Thermopad",
//     mfgPart: "MTVA0400N07W3F",
//     models: "this is the new part",
//     pn: "XM-D4Y7-0204D",
//     price: 11200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Temp Variable",
//     newBRDImage: "0713_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0235",
//     catId: "VVA",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAT-010521-H1.pdf",
//     description: "F=17.6G-40G MaxAtten=30dB IL=2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0235M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a352-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MAAT-010521-H1",
//     mfgPart: "MAAT-010521SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A352D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A352-0404D",
//     price: 14800,
//     priceType: "cents",
//     props: [17600, 40000, 30, null, null, null, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "0235_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0235",
//     catId: "VVA",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAAV-011013.pdf",
//     description: "F=5G-45G MaxAtten=30dB IL=1.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0235M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3y8-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "http://www.macom.com/products/product-detail/MAAV-011013",
//     mfgPart: "MAAV-011013SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3Y8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3Y8-0404D",
//     price: 13700,
//     priceType: "cents",
//     props: [5000, 45000, 30, null, null, null, 1.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "0235_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0572",
//     catId: "VVA",
//     ctlBoard: "XM-A775-0304D",
//     datasheetURL:
//       "https://literature.cdn.keysight.com/litweb/pdf/5989-6201EN.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a4k2-0304d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://www.keysight.com/en/pd-1066245-pn-HMMC-1002/dc-50-ghz-variable-attenuator?cc=US&lc=eng&lsrch=true&searchT=HMMC-1002",
//     mfgPart: "TC721/HMMC-1002DIE",
//     models: "this is the new part",
//     pn: "XM-A4K2-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "0572_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0137",
//     catId: "VVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc973a.pdf",
//     description: "F=500M-5G MaxAtten=31dB IL=5.6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0137H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5r7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/voltage-variable-attenuators/hmc973a.html",
//     mfgPart: "HMC973ALP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5R7-0404D",
//     price: 12300,
//     priceType: "cents",
//     props: [500, 5000, 31, null, null, null, 5.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "0137_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0600",
//     catId: "VVA",
//     ctlBoard: "XM-A8E6-0404D",
//     datasheetURL: "https://www.idt.com/document/dst/f2250-datasheet-rev04",
//     description: "F=50M-6G MaxAtten=33.6dB IL=1.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0600B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7d5-0404d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-attenuators/f2250-wideband-voltage-variable-rf-attenuator",
//     mfgPart: "F2250NLGKSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7D5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7D5-0404D",
//     price: 14200,
//     priceType: "cents",
//     props: [50, 6000, 33.6, null, null, null, 1.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "0600_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0600",
//     catId: "VVA",
//     ctlBoard: "XM-A8E6-0404D",
//     datasheetURL: "https://www.idt.com/document/dst/f2255-datasheet",
//     description: "F=1M-3G MaxAtten=34.6dB IL=1.1dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0600B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7d6-0404d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/rf-products/rf-attenuators/f2255-wideband-voltage-variable-rf-attenuator",
//     mfgPart: "F2255NLGKSMT",
//     models: "this part is missing models",
//     pn: "XM-A7D6-0404D",
//     price: 14200,
//     priceType: "cents",
//     props: [1, 3000, 34.6, null, null, null, 1.1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "0600_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0820",
//     catId: "VVA",
//     ctlBoard: "XM-A9P6-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc812a.pdf",
//     description: "F=5G-30G MaxAtten=35dB IL=2.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0820B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9p5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/voltage-variable-attenuators/hmc812a.html",
//     mfgPart: "HMC812ALC4SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9P5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9P5-0404D",
//     price: 22100,
//     priceType: "cents",
//     props: [5000, 30000, 35, null, null, null, 2.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "0820_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0235",
//     catId: "VVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc712A.pdf",
//     description: "F=5G-26.5G MaxAtten=28dB IL=4.2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0235M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1k5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/attenuators/voltage-variable-attenuators/hmc712a.html",
//     mfgPart: "HMC712ALP3CESMT",
//     models: "this part is missing models",
//     pn: "XM-B1K5-0404D",
//     price: 18400,
//     priceType: "cents",
//     props: [5000, 26500, 28, null, null, null, 4.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "0235_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1196",
//     catId: "VVA",
//     ctlBoard: "XM-B1X6-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da001056",
//     description: "F=50M-18G MaxAtten=30dB IL=2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1196A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1x5-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/RFSA2113",
//     mfgPart: "RFSA2113SMT",
//     models: "this part is missing models",
//     pn: "XM-B1X5-0404D",
//     price: 17300,
//     priceType: "cents",
//     props: [50, 18000, 30, null, null, null, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "1196_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1179",
//     catId: "VVA",
//     ctlBoard: "XM-C7D3-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da001051",
//     description: "F=50M-6G MaxAtten=30dB IL=1.7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1179A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6h3-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/RFSA2013",
//     mfgPart: "RFSA2013SMT",
//     models: "this part is missing models",
//     pn: "XM-B6H3-0404D",
//     price: 13800,
//     priceType: "cents",
//     props: [50, 6000, 30, null, null, null, 1.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "1179_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1195",
//     catId: "VVA",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da001055",
//     description: "F=50M-6G MaxAtten=25dB IL=1dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1195A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6h4-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/RFSA2033",
//     mfgPart: "RFSA2033SMT",
//     models: "this part is missing models",
//     pn: "XM-B6H4-0404D",
//     price: 15400,
//     priceType: "cents",
//     props: [50, 6000, 25, null, null, null, 1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "1195_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1196",
//     catId: "VVA",
//     ctlBoard: "XM-B1X6-0404D",
//     datasheetURL: "https://www.qorvo.com/products/d/da001056",
//     description: "F=50M-18G MaxAtten=30dB IL=2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1196A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6h5-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/RFSA2113",
//     mfgPart: "RFSA2113SMT",
//     models: "this part is missing models",
//     pn: "XM-B6H5-0404D",
//     price: 17600,
//     priceType: "cents",
//     props: [50, 18000, 30, null, null, null, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "1196_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1312",
//     catId: "VVA",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.skyworksinc.com/-/media/SkyWorks/Documents/Products/2001-2100/SKY12236_11_202529H.pdf",
//     description: "F=2.6G-5G MaxAtten=25dB IL=2.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1312A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b821-0404d/",
//     mfgCode: "SKYWK",
//     mfgLandingPage:
//       "https://www.skyworksinc.com/products/attenuators/sky12236-11",
//     mfgPart: "SKY12236-11SMT",
//     models: "this part is missing models",
//     pn: "XM-B821-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [2600, 5000, 25, null, null, null, 2.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "1312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1214",
//     catId: "VVA",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd285c3-datasheet.pdf",
//     description: "F=DC-20G MaxAtten=33dB IL=3.2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1214A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8g2-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/cmd285c3-voltage-variable-attenuator/",
//     mfgPart: "CMD285C3SMT",
//     models: "this part is missing models",
//     pn: "XM-C8G2-0404D",
//     price: 24000,
//     priceType: "cents",
//     props: [0, 20000, 33, null, null, null, 3.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "E3",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "1214_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1312",
//     catId: "VVA",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.skyworksinc.com/-/media/SkyWorks/Documents/Products/2001-2100/SKY12236_11_202529H.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b821-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc346alc3b.html",
//     mfgPart: "HMC346ALC3B",
//     models: "this is the new part",
//     pn: "XM-B1H8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "0700_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VVA",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/cmd285c3-voltage-variable-attenuator/",
//     mfgPart: "CMD285C3",
//     models: "this is the new part",
//     pn: "XM-D4P4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Variable Attenuators",
//     newBRDImage: "2592_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0248",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BAL-0620SMG.pdf",
//     description: "F=6G-20G Ratio=1:2 Iso=2.6dB AmpBal=0.2dB PhaBal=4deg",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0248C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7a2-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/baluns/bal-0620smg.aspx",
//     mfgPart: "BAL-0620SMG",
//     models: "this part is missing models",
//     pn: "XM-A7A2-0404D",
//     price: 28200,
//     priceType: "cents",
//     props: [null, 6000, 20000, 12, 3, 2.6, 0.2, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "0248_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0610",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BAL-0620SMG.pdf",
//     description: "F=6G-20G Ratio=1:2 Iso=2.6dB AmpBal=0.2dB PhaBal=4deg",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0610B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7a5-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/baluns/bal-0620smg.aspx",
//     mfgPart: "BAL-0620SMG",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7A5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7A5-0409D",
//     price: 31600,
//     priceType: "cents",
//     props: [null, 6000, 20000, 12, 3, 2.6, 0.2, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0611",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BAL-0620SMG.pdf",
//     description: "F=6G-20G Ratio=1:2 Iso=2.6dB AmpBal=0.2dB PhaBal=4deg",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0611B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7b9-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/baluns/bal-0620smg.aspx",
//     mfgPart: "BAL-0620SMG",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7B9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7B9-0409D",
//     price: 31600,
//     priceType: "cents",
//     props: [null, 6000, 20000, 12, 3, 2.6, 0.2, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "0611_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1024",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/TC1-1-13M+.pdf",
//     description: "F=4.5M-3G Ratio=1 AmpBal=3dB PhaBal=0.5deg",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1024B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2f5-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=TC1-1-13M%2B",
//     mfgPart: "TC1-1-13M+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2F5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2F5-0409D",
//     price: 10000,
//     priceType: "cents",
//     props: [null, 4.5, 3000, 1, 3, null, 3, 0.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1090",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.mouser.com/datasheet/2/597/wbc-463492.pdf",
//     description: "F=0.4M-600M Ratio=1:1",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1090B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2m9-0409d/",
//     mfgCode: "COILC",
//     mfgLandingPage:
//       "https://www.mouser.com/ProductDetail/Coilcraft/WBC1-1LB?qs=ZYnrCdKdyefcVjlGMhnyxw%3D%3D",
//     mfgPart: "WBC1-1L",
//     models: "this part is missing models",
//     pn: "XM-B2M9-0409D",
//     price: 10600,
//     priceType: "cents",
//     props: [null, 0.4, 600, 11, 0.4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "1090_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1287",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/TCM2-33X+.pdf",
//     description: "F=30M-3G Ratio=1:2 AmpBal=0.5dB PhaBal=4deg",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1287A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5y8-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=TCM2-33X%2B",
//     mfgPart: "TCM2-33X+",
//     models: "this part is missing models",
//     pn: "XM-B5Y8-0409D",
//     price: 10000,
//     priceType: "cents",
//     props: [null, 30, 3000, 12, 1.5, null, 0.5, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "1287_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1720",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MBAL-1440CH.pdf?v=051818",
//     description: "F=14G-40G Ratio=50:50 Iso=13dB AmpBal=0.2dB PhaBal=181.1deg",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9g8-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/baluns/mbal-1440ch.aspx",
//     mfgPart: "MBAL-1440CH",
//     models: "this is the new part",
//     pn: "XM-B9G8-0409D",
//     price: 68400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "1720_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1067",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/BalunTransformers/BD1631J50100AHF/BD1631J50100AHF_DataSheet(Rev_L).pdf",
//     description: "F=1.6G-3.1G Ratio=50: 2x50 AmpBal=0.7dB PhaBal=2.3deg",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1067A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9v2-0409d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.anaren.com/catalog/xinger/balun-transformers",
//     mfgPart: "BD1631J50100AHF",
//     models: "this part is missing models",
//     pn: "XM-B9V2-0409D",
//     price: 9600,
//     priceType: "cents",
//     props: [null, 1600, 3100, 50250, 0.7, 0, 0.7, 2.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "1067_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1615",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/BalunTransformers/BD1631J50100AHF/BD1631J50100AHF_DataSheet(Rev_L).pdf",
//     description: "F=1.6G-3.1G Ratio=50: 2x50 AmpBal=0.7dB PhaBal=2.3deg",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1615A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9v3-0409d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.anaren.com/catalog/xinger/balun-transformers",
//     mfgPart: "BD1631J50100AHF",
//     models: "this part is missing models",
//     pn: "XM-B9V3-0409D",
//     price: 9600,
//     priceType: "cents",
//     props: [null, 1600, 3100, 50250, 0.7, null, 0.7, 2.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "1615_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1466",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "F=2.4G-16G Ratio=50:50",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c471-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/baluns-and-transformers/xm-c471-0409d",
//     mfgPart: "TC236P",
//     models: "this is the new part",
//     pn: "XM-C471-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1804",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "F=3.4G-16G Ratio=50:50",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4g9-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/baluns-and-transformers/xm-c4g9-0409d",
//     mfgPart: "TC236",
//     models: "this is the new part",
//     pn: "XM-C4G9-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "1804_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1492",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "F=5G-22G Ratio=2:1",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c4h7-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/baluns-and-transformers/xm-c4h7-0409d",
//     mfgPart: "TC250",
//     models: "this is the new part",
//     pn: "XM-C4H7-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "1492_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0247",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BAL-0003SMG.pdf",
//     description: "F=0.5M-3G Ratio=1:2 Iso=8dB AmpBal=0.3dB PhaBal=3deg",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c589-0804d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/baluns/bal-0003smg.aspx",
//     mfgPart: "BAL-0003SMG",
//     models: "this is the new part",
//     pn: "XM-C589-0804D",
//     price: 31800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0247",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BAL-0006SMG.pdf",
//     description: "F=0.5M-6G Ratio=1:2 Iso=8dB AmpBal=0.4dB PhaBal=3deg",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c591-0804d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/baluns/bal-0006smg.aspx",
//     mfgPart: "BAL-0006SMG",
//     models: "this is the new part",
//     pn: "XM-C591-0804D",
//     price: 33800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0610",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BAL-0416SMG.pdf",
//     description: "F=4G-16G Ratio=1:2 Iso=15dB AmpBal=0.4dB PhaBal=1deg",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0610B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c593-0409d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/baluns/bal-0416smg.aspx",
//     mfgPart: "BAL-0416SMG",
//     models: "this part is missing models",
//     pn: "XM-C593-0409D",
//     price: 25000,
//     priceType: "cents",
//     props: [null, 4000, 16000, 12, 3.3, 15, 0.4, 1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0247",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BALH-0003SMG.pdf",
//     description: "F=0.5M-3G Ratio=1:2 Iso=7dB AmpBal=0.2dB PhaBal=2deg",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c594-0804d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/baluns/balh-0003smg.aspx",
//     mfgPart: "BALH-0003SMG",
//     models: "this is the new part",
//     pn: "XM-C594-0804D",
//     price: 29100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0247",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BALH-0009SMG.pdf",
//     description: "F=0.5M-9G Ratio=1:2 Iso=6dB AmpBal=0.8dB PhaBal=5deg",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c595-0804d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/baluns/balh-0009smg.aspx",
//     mfgPart: "BALH-0009SMG",
//     models: "this is the new part",
//     pn: "XM-C595-0804D",
//     price: 36900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1481",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/TC4-1WX+.pdf",
//     description: "F=3M-800M Ratio=50:200",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1481A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8l3-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=TC4-1WX%2B",
//     mfgPart: "TC4-1WX+",
//     models: "this part is missing models",
//     pn: "XM-C8L3-0409D",
//     price: 10600,
//     priceType: "cents",
//     props: [null, 3, 800, 50200, 2, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=TC1-1-43%2B",
//     mfgPart: "TC1-1-43+",
//     models: "this is the new part",
//     pn: "XM-D2P7-0409D",
//     price: 9900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BLN",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=0.5M-6GHz P1dB=37dBm",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/baluns/bal-0006smg.aspx",
//     mfgPart: "BALH-0006SMG",
//     models: "this is the new part",
//     pn: "XM-D2V9-0609D",
//     price: 33800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Baluns and Transformers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a161-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A161-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [11.5, 13, 10, 250, null, -2.5, -1.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a161-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A161-0804D-SP",
//     price: 23600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0237G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a344-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A344-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [6.5, 10, 5, 60, null, -20, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a344-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A344-0604D-SP",
//     price: 18900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a378-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A378-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [11.5, 14, 10, 400, null, -2.5, 3.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a378-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A378-0804D-SP",
//     price: 23600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a379-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A379-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [9.5, 12, 8, 200, null, -2.5, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a379-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A379-0804D-SP",
//     price: 23400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0253C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a382-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A382-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [4.8, 5, 4, 138, null, -1.108, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a382-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A382-0404D-SP",
//     price: 13900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a384-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A384-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [9.5, 12, 8, 160, null, -2.5, 1.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a384-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A384-0804D-SP",
//     price: 23600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0237G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3c3-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A3C3-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [9.5, 12, 8, 75, null, -20, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3c3-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A3C3-0604D-SP",
//     price: 19400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0342",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0342C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3m7-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A3M7-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [7.2, 7.5, 6, 1100, null, -0.07, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0342",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3m7-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A3M7-0604D-SP",
//     price: 23100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0431",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0431C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3t6-0609d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-A3T6-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0431",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3t6-0609d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-A3T6-0609D-SP",
//     price: 32400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0942-A",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1555A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4f3-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A4F3-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1555_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1555",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a4f3-0304d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A4F3-0304D-SP",
//     price: 16700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1555_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4j3-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A4J3-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [11.5, 14, 10, 175, null, -2.5, 3.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a4j3-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A4J3-0804D-SP",
//     price: 23600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0894",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0894A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4k1-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A4K1-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0894_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0894",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a4k1-0304d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A4K1-0304D-SP",
//     price: 16100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0894_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0253C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4v7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A4V7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [10.9, 11, 10, 75, null, -40, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a4v7-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A4V7-0404D-SP",
//     price: 13900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0253C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4w2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A4W2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5.8, 6, 5, 80, null, -20, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a4w2-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A4W2-0404D-SP",
//     price: 13400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4w4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-A4W4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [6.5, 8, 5, 74, null, -20, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a4w4-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-A4W4-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1426",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a4y4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A4Y4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1426_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1426",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a4y4-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A4Y4-0404D-SP",
//     price: 17300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1426_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a581-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-A581-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a581-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-A581-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0889",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0889A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6e2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-A6E2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0889_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0889",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a6e2-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-A6E2-0404D-SP",
//     price: 17100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0889_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k3-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A6K3-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [9.5, 11, 8, 300, null, -20, 3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k3-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A6K3-0804D-SP",
//     price: 23600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0253C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A6K6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5.5, 6, 5, 89, null, -20, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k6-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A6K6-0404D-SP",
//     price: 13700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a738-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A738-0804D-SP",
//     price: 22800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a739-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A739-0604D-SP",
//     price: 14500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0552",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0552B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a752-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A752-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [76, 1210, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0552_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0552",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a752-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A752-0804D-SP",
//     price: 22300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0552_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0591",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0591A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a754-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A754-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0591_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0591",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a754-0304d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A754-0304D-SP",
//     price: 16200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0591_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a776-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A776-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [6.5, 8, 5, 250, null, -20, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a776-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A776-0804D-SP",
//     price: 18100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0237G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a794-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A794-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [6.5, 8, 5, 180, null, -20, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a794-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A794-0604D-SP",
//     price: 18700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7f5-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A7F5-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [13.5, 14.5, 12, 400, null, -20, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a7f5-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A7F5-0804D-SP",
//     price: 23600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0237G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7f7-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A7F7-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [6, 8, 5, 100, null, -20, 1.3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a7f7-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-A7F7-0604D-SP",
//     price: 19600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0463",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0463B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7p7-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A7P7-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [6.5, 8, 5, 220, null, -20, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0463_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0463",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a7p7-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A7P7-0804D-SP",
//     price: 25600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0463_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135-G",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0538A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8a4-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A8A4-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [6.5, 8, 5, 70, null, -20, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0135-G",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a8a4-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A8A4-0804D-SP",
//     price: 21400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b125-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B125-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [11, 11.25, 10, 225, null, -40, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b125-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B125-0404D-SP",
//     price: 21900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0253C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b126-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-B126-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [11, 11.2, 10, 200, null, -40.8, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b126-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-B126-0404D-SP",
//     price: 14100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b127-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B127-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [11, 11.2, 10, 280, null, -40.8, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b127-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B127-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b156-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B156-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [6.5, 8, 5, 280, null, -20, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b156-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B156-0404D-SP",
//     price: 20800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1c2-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1C2-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [11.5, 16.5, 10, 400, null, -0.55, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1c2-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-B1C2-0804D-SP",
//     price: 22900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1d1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1D1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5.5, 6.5, 5, 200, null, -20, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1d1-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B1D1-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1e1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1E1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [6.5, 8, 5, 65, null, -20, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1e1-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B1E1-0404D-SP",
//     price: 22100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0890",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0890B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1r3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1R3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0890",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1r3-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B1R3-0404D-SP",
//     price: 21300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1426",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1426A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1r7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1R7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1426_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1426",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1r7-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-B1R7-0404D-SP",
//     price: 19300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1426_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0942",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0942B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1u2-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1U2-0304D",
//     price: 0,
//     priceType: "cents",
//     props: [5.4, 8, 5, 65, null, -20, 1.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0942",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1u2-0304d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-B1U2-0304D-SP",
//     price: 14000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0253C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1u6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1U6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5.7, 5.9, 5, 74, null, -20, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1u6-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-B1U6-0404D-SP",
//     price: 13200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1v9-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1V9-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [9.5, 11, 8, 185, null, -1, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1v9-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-B1V9-0804D-SP",
//     price: 23200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0889",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0889A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1W3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 8, 3.5, 129, null, -10, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0889_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0889",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w3-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B1W3-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0889_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0889",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0889A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1W5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0889_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0889",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w5-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B1W5-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0889_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w7-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B1W7-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [8.5, 10, 7, 150, null, -20, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w7-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-B1W7-0804D-SP",
//     price: 28700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0890",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0890B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4t8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B4T8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 16.5, 315, 500, null, -2.5, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e5-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B5E5-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [16.5, 17, 15, 500, null, -2.5, 9.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e5-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-B5E5-0804D-SP",
//     price: 23600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0237G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e7-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-B5E7-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e7-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-B5E7-0604D-SP",
//     price: 19400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0253C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5k4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-B5K4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5.8, 6, 5, 92, null, -1.511, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5k4-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-B5K4-0404D-SP",
//     price: 13200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5l8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B5L8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5l8-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B5L8-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0253C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5m4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-B5M4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5.8, 6, 5, 98, null, -21.2, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0253",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5m4-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-B5M4-0404D-SP",
//     price: 13200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0253_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1144",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1144A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5p6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B5P6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1144_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1144",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5p6-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B5P6-0404D-SP",
//     price: 23300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1144_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0591",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0591A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5t4-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-B5T4-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0591_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0591",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5t4-0304d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-B5T4-0304D-SP",
//     price: 16200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0591_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0889",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0889A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5u7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B5U7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0889_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0889",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5u7-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B5U7-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0889_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5u8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B5U8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [6.5, 8, 5, 250, null, -20, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5u8-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B5U8-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6a1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B6A1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5.5, 7, 4, 45, null, -10.3, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b6a1-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B6A1-0404D-SP",
//     price: 22100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1316",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1316A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8t4-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B8T4-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1316",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b8t4-0304d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-B8T4-0304D-SP",
//     price: 19200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1529",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1529A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8u7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B8U7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1529",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b8u7-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-B8U7-0404D-SP",
//     price: 19000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1308",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1308A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8u9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B8U9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5.9, 5.96, 5, 600, null, -1.5, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1308_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1308",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b8u9-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-B8U9-0404D-SP",
//     price: 20100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1308_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b911-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B911-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [6.5, 8, 5, 280, null, -20, -20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b911-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-B911-0404D-SP",
//     price: 21200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1316",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1316A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b917-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B917-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1316",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b917-0304d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-B917-0304D-SP",
//     price: 19400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b936-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B936-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [11.5, 12, 10, 400, null, -20, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b936-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-B936-0804D-SP",
//     price: 23200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1538",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1538A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9a3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B9A3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1538_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1538",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9a3-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-B9A3-0404D-SP",
//     price: 17100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1538_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0591",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0591A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9l2-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-B9L2-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0591_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0591",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0591A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9l3-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-B9L3-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0591_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1467",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1467A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3t1-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-C3T1-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1467_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1467",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3t1-0304d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-C3T1-0304D-SP",
//     price: 15000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1467_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1426",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1426A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3t3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-C3T3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1426_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1426",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3t3-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-C3T3-0404D-SP",
//     price: 18300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1426_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1426",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1426A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3u4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-C3U4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1426_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1426",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3u4-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-C3U4-0404D-SP",
//     price: 19000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1426_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1316",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1316A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3v3-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-C3V3-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1316",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3v3-0304d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-C3V3-0304D-SP",
//     price: 19400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0942-A",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1555A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3w1-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-C3W1-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1555_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1555",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3w1-0304d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-C3W1-0304D-SP",
//     price: 13900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1555_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0889",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0889A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3w3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-C3W3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [4.5, 6, 3.5, 90, null, -20, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0889_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0889",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3w3-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-C3W3-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0889_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0135G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3w4-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-C3W4-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0135",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3w4-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-C3W4-0804D-SP",
//     price: 23600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3y8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-C3Y8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3y8-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-C3Y8-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1538",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1538A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c417-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-C417-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1538_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1538",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c417-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-C417-0404D-SP",
//     price: 16800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1538_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7b9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-C7B9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7b9-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-C7B9-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1316",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1316A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7p5-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-C7P5-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1316",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc980.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7p5-0304d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-amplifiers/rf-amplifiers-bias-controllers/hmc980lp4e.html",
//     mfgPart: "HMC980LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-C7P5-0304D-SP",
//     price: 18700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7p8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-C7P8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7p8-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-C7P8-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7y4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-C7Y4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7y4-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-C7Y4-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7y6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-C7Y6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7y6-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-C7Y6-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c894-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-C894-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5.5, 7, 4, 320, null, -20, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0943",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c894-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this is the new part",
//     pn: "XM-C894-0404D-SP",
//     price: 21200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0237G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9e8-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-C9E8-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0237",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc981.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9e8-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc981lp3e.html",
//     mfgPart: "HMC981LP3ESMT",
//     models: "this is the new part",
//     pn: "XM-C9E8-0604D-SP",
//     price: 19100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0237_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0890",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc920.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0890B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9p6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-amplifiers/rf-amplifiers-bias-controllers/hmc920.html",
//     mfgPart: "HMC920LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-C9P6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0420C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ADP7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this part is missing models",
//     pn: "XM-A3U6-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0420_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ADP7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this is the new part",
//     pn: "XM-A3U6-0804D-SP",
//     price: 19700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0420_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/LTC1983.html",
//     mfgPart: "LTC1983ES6-3#TRMPBF",
//     models: "this is the new part",
//     pn: "XM-C2T9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "2147_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/LTC1983.html",
//     mfgPart: "LTC1983ES6-3#TRMPBF",
//     models: "this is the new part",
//     pn: "XM-C2T9-0404D-SP",
//     price: 11000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "2147_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc980lp4e.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-D285-0404D-SP",
//     price: 20800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "2104_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc980lp4e.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-D285-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "2104_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc920.html",
//     mfgPart: "HMC920LP5E",
//     models: "this is the new part",
//     pn: "XM-D437-0404D-SP",
//     price: 21900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc920.html",
//     mfgPart: "HMC920LP5E",
//     models: "this is the new part",
//     pn: "XM-D437-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc980lp4e.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-D2G1-0404D-SP",
//     price: 23400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1308_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc980lp4e.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-D2G1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1308_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc980lp4e.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-A495-0404D-SP",
//     price: 21600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1426_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc980lp4e.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-A495-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1426_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc980lp4e.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-D178-0404D-SP",
//     price: 17700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "2135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc980lp4e.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-D178-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "2135_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc920.html",
//     mfgPart: "HMC920LP5E",
//     models: "this is the new part",
//     pn: "XM-D3K9-0404D-SP",
//     price: 21900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc920.html",
//     mfgPart: "HMC920LP5E",
//     models: "this is the new part",
//     pn: "XM-D3K9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc980lp4e.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-D1B9-0404D-SP",
//     price: 19900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1538_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc980lp4e.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-D1B9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Active Bias Controllers",
//     newBRDImage: "1538_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0594",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0594A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3m9-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "MADR-007097-000100SMT",
//     models: "this part is missing models",
//     pn: "XM-A3M9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0594",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3m9-0404d-sp/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "MADR-007097-000100SMT",
//     models: "this is the new part",
//     pn: "XM-A3M9-0404D-SP",
//     price: 11000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1706",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1706A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a498-0804d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-A498-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0590",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0590A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5d3-0204d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-A5D3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z1",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0590",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a5d3-0204d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-A5D3-0204D-SP",
//     price: 9000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0455",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3032ff.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0455D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/lt3032.html#product-overview",
//     mfgPart: "LT3032EDE-3.3#PBFSMT",
//     models: "this part is missing models",
//     pn: "XM-A6K1-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0455",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3032ff.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k1-0409d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/lt3032.html#product-overview",
//     mfgPart: "LT3032EDE-3.3#PBFSMT",
//     models: "this is the new part",
//     pn: "XM-A6K1-0409D-SP",
//     price: 18000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0487",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0487B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k5-0819d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-A6K5-0819D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0487_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0487",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k5-0819d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-A6K5-0819D-SP",
//     price: 40300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0487_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0532",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0532B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b132-0604d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-B132-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0532_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0532",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b132-0604d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B132-0604D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0532_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0533",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0533B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1b3-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-B1B3-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0533",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1b3-0409d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B1B3-0409D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0598",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0598A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1b9-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-B1B9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0598_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0598",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1b9-0404d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B1B9-0404D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0598_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0597",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0597A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1c1-0204d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-B1C1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z1",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0597",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1c1-0204d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B1C1-0204D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1315",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1315A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1e3-0405d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-B1E3-0405D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z5",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1315",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1e3-0405d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B1E3-0405D-SP",
//     price: 9500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0898",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1m1-0404d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B1M1-0404D-SP",
//     price: 13600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0898_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1194",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1p7-0509d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B1P7-0509D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1194_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1194",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1p7-0509d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B1P7-0509D-SP",
//     price: 18000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1194_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1522",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MADR-007097.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1522B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1r9-0409d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MADR-007097-000100",
//     mfgPart: "MADR-007097-000100SMT",
//     models: "this part is missing models",
//     pn: "XM-B1R9-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1522",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MADR-007097.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1r9-0409d-sp/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MADR-007097-000100",
//     mfgPart: "MADR-007097-000100SMT",
//     models: "this is the new part",
//     pn: "XM-B1R9-0409D-SP",
//     price: 17900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0947",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MADR-007097.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0947A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1t2-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MADR-007097-000100",
//     mfgPart: "MADR-007097-000100SMT",
//     models: "this part is missing models",
//     pn: "XM-B1T2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0947_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0947",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MADR-007097.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1t2-0404d-sp/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MADR-007097-000100",
//     mfgPart: "MADR-007097-000100SMT",
//     models: "this is the new part",
//     pn: "XM-B1T2-0404D-SP",
//     price: 12900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0947_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0941",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3060fc.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0941A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1t9-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC#TRMPBFSMT",
//     models: "this part is missing models",
//     pn: "XM-B1T9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z1",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0941",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3060fc.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1t9-0204d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC#TRMPBFSMT",
//     models: "this is the new part",
//     pn: "XM-B1T9-0204D-SP",
//     price: 7300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1364",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1364A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w1-0819d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-B1W1-0819D",
//     price: 0,
//     priceType: "cents",
//     props: [null, null, null, null, null, null, 0.5, 18],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1364_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1364",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w1-0819d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B1W1-0819D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1364_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1148",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1148A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w9-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-B1W9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1148",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w9-0404d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B1W9-0404D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1107",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/madr-010410.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1107A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2a7-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MADR-010410",
//     mfgPart: "MADR-010410SMT",
//     models: "this part is missing models",
//     pn: "XM-B2A7-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1107",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/madr-010410.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b2a7-0604d-sp/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MADR-010410",
//     mfgPart: "MADR-010410SMT",
//     models: "this is the new part",
//     pn: "XM-B2A7-0604D-SP",
//     price: 18000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0947",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MADR-007097.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0947A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2n6-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MADR-007097-000100",
//     mfgPart: "MADR-007097-000100SMT",
//     models: "this part is missing models",
//     pn: "XM-B2N6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0947_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0947",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MADR-007097.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b2n6-0404d-sp/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MADR-007097-000100",
//     mfgPart: "MADR-007097-000100SMT",
//     models: "this is the new part",
//     pn: "XM-B2N6-0404D-SP",
//     price: 14500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0947_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0590",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3060fc.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0590A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4u4-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC#TRMPBFSMT",
//     models: "this part is missing models",
//     pn: "XM-B4U4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z1",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0590",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3060fc.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b4u4-0204d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC#TRMPBFSMT",
//     models: "this is the new part",
//     pn: "XM-B4U4-0204D-SP",
//     price: 7400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0532",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0532B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e2-0604d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-B5E2-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0532_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0532",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e2-0604d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B5E2-0604D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0532_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1227",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/tps2660.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1227B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8d7-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage:
//       "https://www.digikey.com/product-detail/en/texas-instruments/TPS26600RHFT/296-45478-1-ND/6820982",
//     mfgPart: "TPS26600RHFTSMT",
//     models: "this part is missing models",
//     pn: "XM-B8D7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1227_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1227",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/tps2660.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b8d7-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage:
//       "https://www.digikey.com/product-detail/en/texas-instruments/TPS26600RHFT/296-45478-1-ND/6820982",
//     mfgPart: "TPS26600RHFTSMT",
//     models: "this is the new part",
//     pn: "XM-B8D7-0404D-SP",
//     price: 17000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1227_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1568",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b987-0807d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B987-0807D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1568",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b987-0807d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B987-0807D-SP",
//     price: 20600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1568",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9k6-0807d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B9K6-0807D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1568",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9k6-0807d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B9K6-0807D-SP",
//     price: 22600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0455",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9u3-0409d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-B9U3-0409D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1311",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/tps2660.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c121-0810d/",
//     mfgCode: "TXINS",
//     mfgLandingPage:
//       "https://www.digikey.com/product-detail/en/texas-instruments/TPS26600RHFT/296-45478-1-ND/6820982",
//     mfgPart: "TPS26600RHFTSMT",
//     models: "this is the new part",
//     pn: "XM-C121-0810D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1311_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1311",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/tps2660.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c121-0810d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage:
//       "https://www.digikey.com/product-detail/en/texas-instruments/TPS26600RHFT/296-45478-1-ND/6820982",
//     mfgPart: "TPS26600RHFTSMT",
//     models: "this is the new part",
//     pn: "XM-C121-0810D-SP",
//     price: 31100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1311_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0595",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0595A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c349-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-C349-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1339",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/624012fe.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c382-0810d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ltc6241.html",
//     mfgPart: "LTC6241HVIDD#PBFSMT",
//     models: "this is the new part",
//     pn: "XM-C382-0810D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1339_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1339",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/624012fe.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c382-0810d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ltc6241.html",
//     mfgPart: "LTC6241HVIDD#PBFSMT",
//     models: "this is the new part",
//     pn: "XM-C382-0810D-SP",
//     price: 30700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1339_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1339",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/tps2660.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3f9-0810d/",
//     mfgCode: "TXINS",
//     mfgLandingPage:
//       "https://www.digikey.com/product-detail/en/texas-instruments/TPS26600RHFT/296-45478-1-ND/6820982",
//     mfgPart: "TPS26600RHFTSMT",
//     models: "this is the new part",
//     pn: "XM-C3F9-0810D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1339_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1339",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/tps2660.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3f9-0810d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage:
//       "https://www.digikey.com/product-detail/en/texas-instruments/TPS26600RHFT/296-45478-1-ND/6820982",
//     mfgPart: "TPS26600RHFTSMT",
//     models: "this is the new part",
//     pn: "XM-C3F9-0810D-SP",
//     price: 30800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1339_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1452",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1452B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3p8-0409d/",
//     mfgCode: "MONZT",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "99215SMT",
//     models: "this part is missing models",
//     pn: "XM-C3P8-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1452",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3p8-0409d-sp/",
//     mfgCode: "MONZT",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "99215SMT",
//     models: "this is the new part",
//     pn: "XM-C3P8-0409D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0941",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3060fc.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0941A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3r2-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC#TRMPBFSMT",
//     models: "this part is missing models",
//     pn: "XM-C3R2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z1",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0941",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3060fc.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3r2-0204d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC#TRMPBFSMT",
//     models: "this is the new part",
//     pn: "XM-C3R2-0204D-SP",
//     price: 7300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1411",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3032ff.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1411B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3r9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/lt3032.html#product-overview",
//     mfgPart: "LT3032EDE-3.3#PBFSMT",
//     models: "this part is missing models",
//     pn: "XM-C3R9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1411_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1411",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3032ff.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3r9-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/lt3032.html#product-overview",
//     mfgPart: "LT3032EDE-3.3#PBFSMT",
//     models: "this is the new part",
//     pn: "XM-C3R9-0404D-SP",
//     price: 11900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1411_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1530",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1530B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3u6-0409d/",
//     mfgCode: "MONZT",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "99216SMT",
//     models: "this part is missing models",
//     pn: "XM-C3U6-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1530",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3u6-0409d-sp/",
//     mfgCode: "MONZT",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "99216SMT",
//     models: "this is the new part",
//     pn: "XM-C3U6-0409D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0593",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0593A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3v5-0304d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "NCP392ARFCCT1GSMT",
//     models: "this part is missing models",
//     pn: "XM-C3V5-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z2",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1540",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3060fc.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c411-0204d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC#TRMPBFSMT",
//     models: "this is the new part",
//     pn: "XM-C411-0204D-SP",
//     price: 9000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1552",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://monzite.com/wp-content/uploads/2018/09/Monzite_Datasheet_MDI2354Q_RevA.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1552A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c435-0409d/",
//     mfgCode: "MONZT",
//     mfgLandingPage: "https://monzite.com/products/",
//     mfgPart: "MDI2354QSMT",
//     models: "this part is missing models",
//     pn: "XM-C435-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1552_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1552",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://monzite.com/wp-content/uploads/2018/09/Monzite_Datasheet_MDI2354Q_RevA.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c435-0409d-sp/",
//     mfgCode: "MONZT",
//     mfgLandingPage: "https://monzite.com/products/",
//     mfgPart: "MDI2354QSMT",
//     models: "this is the new part",
//     pn: "XM-C435-0409D-SP",
//     price: 30900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1552_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1706",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1706A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c562-0804d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-C562-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1706",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c562-0804d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-C562-0804D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1310",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1310A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c577-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-C577-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1310_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1310",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c577-0404d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-C577-0404D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1310_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1533",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6a3-0407d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-C6A3-0407D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1533_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1533",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6a3-0407d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-C6A3-0407D-SP",
//     price: 13400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1533_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0378",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0378C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6a8-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-C6A8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0378_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0378",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6a8-0404d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-C6A8-0404D-SP",
//     price: 13300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0378_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1747",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6g8-0404d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-C6G8-0904D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1747_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1633",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/LT3094.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1633A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6h8-0609d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3094.html",
//     mfgPart: "LT3094EDD#PBFSMT",
//     models: "this part is missing models",
//     pn: "XM-C6H8-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1633",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/LT3094.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6h8-0609d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3094.html",
//     mfgPart: "LT3094EDD#PBFSMT",
//     models: "this is the new part",
//     pn: "XM-C6H8-0609D-SP",
//     price: 24700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0358",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0358D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6k6-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-C6K6-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0358",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6k6-0409d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-C6K6-0409D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1598",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c746-0404d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-C746-0404D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0790",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0790B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7f6-0604d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this part is missing models",
//     pn: "XM-C7F6-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0790",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7f6-0604d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-C7F6-0604D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0842",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7w3-0604d-sp/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-C7W3-0604D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0842_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0895",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://datasheets.maximintegrated.com/en/ds/MAX16052-MAX16053.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8m2-0404d-sp/",
//     mfgCode: "MAXIM",
//     mfgLandingPage:
//       "https://www.maximintegrated.com/en/products/power/supervisors-voltage-monitors-sequencers/MAX16053.html",
//     mfgPart: "MAX16053SMT",
//     models: "this is the new part",
//     pn: "XM-C8M2-0404D-SP",
//     price: 11100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0895_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1849",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/tps2660.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1849A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9g1-0405d/",
//     mfgCode: "TXINS",
//     mfgLandingPage:
//       "https://www.digikey.com/product-detail/en/texas-instruments/TPS26600RHFT/296-45478-1-ND/6820982",
//     mfgPart: "TPS26600RHFTSMT",
//     models: "this part is missing models",
//     pn: "XM-C9G1-0405D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1849_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "TBDSMT",
//     models: "this is the new part",
//     pn: "XM-C9K3-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1963_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN",
//     models: "this is the new part",
//     pn: "XM-D3D4-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2091_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN",
//     models: "this is the new part",
//     pn: "XM-D3D4-0804D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2091_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D3W8-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2513_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D3W8-0604D-SP",
//     price: 21500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2513_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0537A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798",
//     models: "this part is missing models",
//     pn: "XM-B1C4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0537_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798",
//     models: "this is the new part",
//     pn: "XM-B1C4-0404D-SP",
//     price: 11100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0537_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D2G5-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1274_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D2G5-0604D-SP",
//     price: 21500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1274_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D3P9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0836_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D3P9-0404D-SP",
//     price: 11600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0836_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-C9J6-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2123_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-C9J6-0609D-SP",
//     price: 20700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2123_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0592A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ada4084-1.html",
//     mfgPart: "ADA4084-1ARJZ",
//     models: "this part is missing models",
//     pn: "XM-A775-0304D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 0, 0, 0, 0, 0, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ada4084-1.html",
//     mfgPart: "ADA4084-1ARJZ",
//     models: "this is the new part",
//     pn: "XM-A775-0304D-SP",
//     price: 13700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0549A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3032.html",
//     mfgPart: "LT3032MPDE-5#PBF",
//     models: "this part is missing models",
//     pn: "XM-C569-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0549_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3032.html",
//     mfgPart: "LT3032MPDE-5#PBF",
//     models: "this is the new part",
//     pn: "XM-C569-0404D-SP",
//     price: 15800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0549_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1540A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC-1.8#TRMPBF",
//     models: "this part is missing models",
//     pn: "XM-C437-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y1",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC-1.8#TRMPBF",
//     models: "this is the new part",
//     pn: "XM-C437-0204D-SP",
//     price: 8700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC-3.3#TRMPBF",
//     models: "this is the new part",
//     pn: "XM-C2U7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1718_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC-3.3#TRMPBF",
//     models: "this is the new part",
//     pn: "XM-C2U7-0404D-SP",
//     price: 7800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1718_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0535B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC-5#TRMPBF",
//     models: "this part is missing models",
//     pn: "XM-B1B5-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0535_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3060.html",
//     mfgPart: "LT3060EDC-5#TRMPBF",
//     models: "this is the new part",
//     pn: "XM-B1B5-0409D-SP",
//     price: 17400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0535_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1310A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3065.html",
//     mfgPart: "LT3065EDD",
//     models: "this part is missing models",
//     pn: "XM-C578-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1310_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3065.html",
//     mfgPart: "LT3065EDD",
//     models: "this is the new part",
//     pn: "XM-C578-0404D-SP",
//     price: 8700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1310_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1156A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3462.html",
//     mfgPart: "LT3462AEDC#TRMPBF",
//     models: "this part is missing models",
//     pn: "XM-B1U7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [-5, null, null, null, null, null, 5, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3462.html",
//     mfgPart: "LT3462AEDC#TRMPBF",
//     models: "this is the new part",
//     pn: "XM-B1U7-0404D-SP",
//     price: 9800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0548B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B1E9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       5,
//       null,
//       null,
//       null,
//       null,
//       null,
//       5.5,
//       15,
//       null,
//       null,
//       0,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0548_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B1E9-0404D-SP",
//     price: 11200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0548_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1556A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C557-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C557-0404D-SP",
//     price: 13200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0548B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B1B6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       3.3,
//       null,
//       null,
//       null,
//       null,
//       null,
//       3.8,
//       15,
//       null,
//       null,
//       0,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0548_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B1B6-0404D-SP",
//     price: 11200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0548_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0548B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B5M2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       3.3,
//       null,
//       null,
//       null,
//       null,
//       null,
//       3.8,
//       15,
//       null,
//       null,
//       0,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0548_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B5M2-0404D-SP",
//     price: 11200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0548_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A414-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1962_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A414-0404D-SP",
//     price: 12500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1962_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0435C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-B1M6-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B1M6-0604D-SP",
//     price: 21400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0435C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-B1C6-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B1C6-0604D-SP",
//     price: 21400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B2J4-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0950_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B2J4-0804D-SP",
//     price: 20900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0950_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0435C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-A769-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-A769-0604D-SP",
//     price: 20200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1099A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B2E4-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1099_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B2E4-0804D-SP",
//     price: 17300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1099_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0435C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-B814-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B814-0604D-SP",
//     price: 19500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1104B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-C3V1-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-C3V1-0604D-SP",
//     price: 15600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1104B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B2G9-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B2G9-0604D-SP",
//     price: 15600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B2J2-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1103_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B2J2-0604D-SP",
//     price: 14300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1103_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0435C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B5G3-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B5G3-0604D-SP",
//     price: 19300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1105A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B128-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B128-0604D-SP",
//     price: 13300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1105A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B129-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B129-0604D-SP",
//     price: 19000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0946A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this part is missing models",
//     pn: "XM-A9P1-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this is the new part",
//     pn: "XM-A9P1-0604D-SP",
//     price: 12300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A4G7-0409D-SP",
//     price: 22900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0358D.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A4G7-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C6G4-0409D-SP",
//     price: 22900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0358D.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-C6G4-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A3R9-0409D-SP",
//     price: 26000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0383_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0383C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A3R9-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       null,
//       3.3,
//       5,
//       3.3,
//       null,
//       null,
//       6,
//       10,
//       null,
//       null,
//       null,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0383_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A3T4-0409D-SP",
//     price: 26200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0383_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0383C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A3T4-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       null,
//       3.3,
//       3.3,
//       3.3,
//       null,
//       null,
//       6,
//       10,
//       null,
//       null,
//       null,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0383_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A5R5-0409D-SP",
//     price: 24900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0383_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0383C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A5R5-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0383_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A762-0604D-SP",
//     price: 33900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0532_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0532B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A762-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0532_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A8D7-0409D-SP",
//     price: 13500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0533B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A8D7-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       2.5,
//       null,
//       null,
//       null,
//       null,
//       null,
//       5,
//       10,
//       null,
//       null,
//       null,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A8E5-0604D-SP",
//     price: 13300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0790B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A8E5-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A7L6-0604D-SP",
//     price: 15100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0827A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A7L6-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A8D8-0409D-SP",
//     price: 13500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0837_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0837A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A8D8-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0837_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C7A8-0409D-SP",
//     price: 13500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0837_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0837A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-C7A8-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0837_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B921-0404D-SP",
//     price: 10500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0852_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0852A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B921-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0852_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A8E2-0409D-SP",
//     price: 17200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0893_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0893A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A8E2-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0893_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B1M8-0404D-SP",
//     price: 10800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0935_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0935A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B1M8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0935_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B8E5-0404D-SP",
//     price: 10800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0935_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0935A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B8E5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0935_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A768-0409D-SP",
//     price: 11600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0953_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0953B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A768-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       5,
//       null,
//       null,
//       null,
//       null,
//       null,
//       5.5,
//       10,
//       null,
//       null,
//       0,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0953_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B6C3-0619D-SP",
//     price: 29100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1035B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B6C3-0619D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B2D7-0609D-SP",
//     price: 27800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1036A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B2D7-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B6C2-0609D-SP",
//     price: 26200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1036A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B6C2-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A7T8-0404D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1098_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1098A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A7T8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       3.3,
//       null,
//       null,
//       null,
//       null,
//       null,
//       3.8,
//       18,
//       null,
//       null,
//       0,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1098_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A8E6-0404D-SP",
//     price: 9900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1101_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1101A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A8E6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       5,
//       null,
//       null,
//       null,
//       null,
//       null,
//       5.5,
//       15,
//       null,
//       null,
//       0,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1101_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C7D3-0404D-SP",
//     price: 9900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1101_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1101A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-C7D3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1101_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A8E4-0404D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1108_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1108A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A8E4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1108_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B5R7-0509D-SP",
//     price: 17500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1194_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B5R7-0509D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1194_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A773-0604D-SP",
//     price: 14900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A773-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C6K4-0604D-SP",
//     price: 23200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1585A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-C6K4-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C6G2-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1617A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-C6G2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C443-0404D-SP",
//     price: 17300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1998_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C443-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1998_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A4V4-0404D-SP",
//     price: 10400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1100_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1100A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A4V4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1100_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B5W1-0804D-SP",
//     price: 17500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1099_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B5W1-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1099_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D2C3-0409D-SP",
//     price: 13500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D2C3-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this is the new part",
//     pn: "XM-A7K7-0404D-SP",
//     price: 10600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0740_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0740A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this part is missing models",
//     pn: "XM-A7K7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0740_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this is the new part",
//     pn: "XM-B2J6-0604D-SP",
//     price: 12300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0946A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this part is missing models",
//     pn: "XM-B2J6-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this is the new part",
//     pn: "XM-A5A9-0404D-SP",
//     price: 12000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1288A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this part is missing models",
//     pn: "XM-A5A9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this is the new part",
//     pn: "XM-A5A3-0419D-SP",
//     price: 25100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0178_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this is the new part",
//     pn: "XM-A5A3-0419D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0178_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this is the new part",
//     pn: "XM-B9D8-0404D-SP",
//     price: 6900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2441_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this is the new part",
//     pn: "XM-B9D8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2441_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B8R4-0404D-SP",
//     price: 15200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1222_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1222A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B8R4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       5,
//       null,
//       null,
//       null,
//       null,
//       -2.5,
//       5.5,
//       12,
//       -3,
//       -12,
//       0,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1222_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B9P2-0404D-SP",
//     price: 15200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1222_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1222A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B9P2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1222_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B8C5-0404D-SP",
//     price: 14700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1223A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B8C5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B9P3-0404D-SP",
//     price: 14700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1223A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B9P3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-A7M2-0604D-SP",
//     price: 15600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1401A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-A7M2-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B938-0604D-SP",
//     price: 20200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1508B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B938-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D232-0404D-SP",
//     price: 7400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D232-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1223_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-A7A6-0409D-SP",
//     price: 17100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0423D.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-A7A6-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-A749-0419D-SP",
//     price: 35100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0454_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0454C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-A749-0419D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0454_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-A745-0409D-SP",
//     price: 20800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0485_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0485B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-A745-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0485_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B1E2-0409D-SP",
//     price: 21300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0485_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0485B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-B1E2-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0485_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B9F3-0409D-SP",
//     price: 21300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0485_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0485B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-B9F3-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0485_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-C565-0409D-SP",
//     price: 20600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0485_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0485B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-C565-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0485_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-A4H5-0609D-SP",
//     price: 18100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0486B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-A4H5-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-A763-0604D-SP",
//     price: 19200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0842_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0842A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-A763-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0842_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-C8A9-0404D-SP",
//     price: 15300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1222_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1222A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-C8A9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1222_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B1L2-0409D-SP",
//     price: 20200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1367_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1367A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-B1L2-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1367_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-A833-0416D-SP",
//     price: 24000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1679_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-A833-0416D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1679_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B9E1-0416D-SP",
//     price: 24000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1679_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B9E1-0416D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1679_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-C379-0804D-SP",
//     price: 23900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0950_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0950B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-C379-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0950_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-C6N2-0406D-SP",
//     price: 12100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1982_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-C6N2-0406D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1982_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/HMC920LP5E.html",
//     mfgPart: "HMC920LP5E",
//     models: "this is the new part",
//     pn: "XM-C413-0404D-SP",
//     price: 23500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/HMC920LP5E.html",
//     mfgPart: "HMC920LP5E",
//     models: "this is the new part",
//     pn: "XM-C413-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/HMC920LP5E.html",
//     mfgPart: "HMC920LP5E",
//     models: "this is the new part",
//     pn: "XM-C7R3-0404D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0943A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/HMC920LP5E.html",
//     mfgPart: "HMC920LP5E",
//     models: "this part is missing models",
//     pn: "XM-C7R3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/HMC920LP5E.html",
//     mfgPart: "HMC920LP5E",
//     models: "this is the new part",
//     pn: "XM-B226-0404D-SP",
//     price: 21500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/HMC920LP5E.html",
//     mfgPart: "HMC920LP5E",
//     models: "this is the new part",
//     pn: "XM-B226-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/HMC980LP4E.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-D259-0604D-SP",
//     price: 25100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2301_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/HMC980LP4E.html",
//     mfgPart: "HMC980LP4E",
//     models: "this is the new part",
//     pn: "XM-D259-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2301_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-A7H9-0404D-SP",
//     price: 11300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0537_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0537A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this part is missing models",
//     pn: "XM-A7H9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0537_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-C6A9-0404D-SP",
//     price: 9800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0537_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-C6A9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0537_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-A349-0404D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0231D.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this part is missing models",
//     pn: "XM-A349-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-A3C5-0404D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0231D.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this part is missing models",
//     pn: "XM-A3C5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-A7A8-0404D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0231D.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this part is missing models",
//     pn: "XM-A7A8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-D1P7-0609D-SP",
//     price: 20700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2123_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-D1P7-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2123_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/lt3032.html#product-overview",
//     mfgPart: "LT3032EDE-3.3#PBF",
//     models: "this is the new part",
//     pn: "XM-A8E3-0404D-SP",
//     price: 15000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0549_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0549A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/lt3032.html#product-overview",
//     mfgPart: "LT3032EDE-3.3#PBF",
//     models: "this part is missing models",
//     pn: "XM-A8E3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       null,
//       3.3,
//       -3.3,
//       null,
//       null,
//       null,
//       3.8,
//       15,
//       -3.8,
//       15,
//       null,
//       null,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0549_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/lt3032.html#product-overview",
//     mfgPart: "LT3032EDE-3.3#PBF",
//     models: "this is the new part",
//     pn: "XM-A3W5-0404D-SP",
//     price: 15000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0549_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0549A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/lt3032.html#product-overview",
//     mfgPart: "LT3032EDE-3.3#PBF",
//     models: "this part is missing models",
//     pn: "XM-A3W5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0549_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A3E3-0604D-SP",
//     price: 21500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0325C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-A3E3-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [null, null, 5, 140, null, -0.75, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A8A2-0604D-SP",
//     price: 20800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0741C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-A8A2-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A725-0609D-SP",
//     price: 30100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0743_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0743A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-A725-0609D",
//     price: 0,
//     priceType: "cents",
//     props: [3.3, null, null, null, null, null, 3.8, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0743_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A727-0419D-SP",
//     price: 40400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0744_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0744D.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-A727-0419D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       7,
//       15,
//       -16,
//       -18,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0744_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C351-0612D-SP",
//     price: 39300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 10,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0811A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C351-0612D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A7D1-0409D-SP",
//     price: 24200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0826B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-A7D1-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A8E1-0404D-SP",
//     price: 11600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0836_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0836B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-A8E1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0836_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B1N3-0604D-SP",
//     price: 18700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0899_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0899A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B1N3-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0899_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B1U9-0404D-SP",
//     price: 12600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0951A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B1U9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B5K5-0609D-SP",
//     price: 25600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1046_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1046A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B5K5-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1046_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B2E6-0409D-SP",
//     price: 16900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1102_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1102A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B2E6-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1102_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B1N1-0404D-SP",
//     price: 17900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1109C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B1N1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B5R6-0409D-SP",
//     price: 17600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1159A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B5R6-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B8E6-0812D-SP",
//     price: 41700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B8E6-0812D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B6K5-0812D-SP",
//     price: 40200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B6K5-0812D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C111-0409D-SP",
//     price: 14600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1240_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1240A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C111-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1240_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C744-0604D-SP",
//     price: 18100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1274_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1274A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C744-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1274_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B916-0809D-SP",
//     price: 40600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1304_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1304C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B916-0809D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1304_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B915-0809D-SP",
//     price: 37100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1305_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1305B.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B915-0809D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1305_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C559-0609D-SP",
//     price: 31500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1354A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C559-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C743-0404D-SP",
//     price: 10800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1357A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C743-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C3Y4-0404D-SP",
//     price: 14000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1543_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1543A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C3Y4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1543_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C6A1-0404D-SP",
//     price: 11500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1553A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C6A1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B817-0404D-SP",
//     price: 10100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1576_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1576A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B817-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1576_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B9K4-0404D-SP",
//     price: 10100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1576_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1576A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B9K4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1576_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C6K1-0909D-SP",
//     price: 40800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C6K1-0909D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B4Y7-0604D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B4Y7-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C6G6-0604D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1583A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C6G6-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A517-0404D-SP",
//     price: 11200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A517-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C6B4-0806D-SP",
//     price: 15100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C6B4-0806D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C375-0604D-SP",
//     price: 15900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1627_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1627A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C375-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1627_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C6H6-0819D-SP",
//     price: 49100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 11,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1634A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C6H6-0819D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C7R4-0404D-SP",
//     price: 10100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1648A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C7R4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C8G1-0609D-SP",
//     price: 28700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1692A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C8G1-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C899-0404D-SP",
//     price: 12000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C899-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B1R1-0604D-SP",
//     price: 14900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2080_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B1R1-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2080_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D137-0404D-SP",
//     price: 15100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2134_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D137-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2134_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A4W8-0404D-SP",
//     price: 13400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0905_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0905A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-A4W8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0905_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A4Y1-0404D-SP",
//     price: 13400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0905_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A4Y1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0905_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C7W8-0807D-SP",
//     price: 22400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C7W8-0807D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C359-0604D-SP",
//     price: 22900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0325C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C359-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [null, null, 5, 120, null, -0.75, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C429-0604D-SP",
//     price: 22200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0325C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C429-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [null, null, 5, 160, null, -0.75, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D2D5-0404D-SP",
//     price: 11200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D2D5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C7N4-0604D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C7N4-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C7F5-0509D-SP",
//     price: 27900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2155_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C7F5-0509D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2155_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D1V7-0909D-SP",
//     price: 28700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2158_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D1V7-0909D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2158_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D1J3-0404D-SP",
//     price: 16600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2217_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D1J3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2217_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D3W6-0807D-SP",
//     price: 22400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D3W6-0807D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.susumu.co.jp/usa/product/category.php?cid=5",
//     mfgPart: "RR0510P-101-D",
//     models: "this is the new part",
//     pn: "XM-A729-0419D-SP",
//     price: 20500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0746A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.susumu.co.jp/usa/product/category.php?cid=5",
//     mfgPart: "RR0510P-101-D",
//     models: "this part is missing models",
//     pn: "XM-A729-0419D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0231D.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this part is missing models",
//     pn: "XM-B4V1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-B4V1-0404D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0231D.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this part is missing models",
//     pn: "XM-C7Y5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-C7Y5-0404D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0593A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ad8519.html",
//     mfgPart: "AD8519AKSZ",
//     models: "this part is missing models",
//     pn: "XM-A756-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "B2",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ad8519.html",
//     mfgPart: "AD8519AKSZ",
//     models: "this is the new part",
//     pn: "XM-A756-0304D-SP",
//     price: 13400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0895A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this part is missing models",
//     pn: "XM-A5C3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5, null, null, null, null, null, 6, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0895_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-A5C3-0404D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0895_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-A5C5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0895_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-A5C5-0404D-SP",
//     price: 11100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0895_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0895A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this part is missing models",
//     pn: "XM-B9H5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0895_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-B9H5-0404D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0895_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "NOPOP",
//     models: "this is the new part",
//     pn: "XM-A766-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0935_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "NOPOP",
//     models: "this is the new part",
//     pn: "XM-A766-0404D-SP",
//     price: 7500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0935_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C8N1-0604D-SP",
//     price: 19800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D1U4-0604D-SP",
//     price: 15100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D2F8-0404D-SP",
//     price: 13300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C9K3-0604D-SP",
//     price: 16200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1963_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MADR-009150",
//     mfgPart: "MADR-009150",
//     models: "this is the new part",
//     pn: "XM-D1U6-0409D-SP",
//     price: 19000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2289_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/max14691.html",
//     mfgPart: "MAX14691ATP+",
//     models: "this is the new part",
//     pn: "XM-C445-0405D-SP",
//     price: 7100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2440_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-A8E9-0404D-SP",
//     price: 7900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2443_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-A8E9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2443_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0596A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://www.murata.com/en-us/products/productdetail?partno=GRT188C81E475KE13%23",
//     mfgPart: "GRT188C81E475KE13D",
//     models: "this part is missing models",
//     pn: "XM-B1B8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://www.murata.com/en-us/products/productdetail?partno=GRT188C81E475KE13%23",
//     mfgPart: "GRT188C81E475KE13D",
//     models: "this is the new part",
//     pn: "XM-B1B8-0204D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1454A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B5V3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B5V3-0404D-SP",
//     price: 11600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0842A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B9A9-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z6",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0842_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B9A9-0604D-SP",
//     price: 14300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0842_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B9L7-0404D-SP",
//     price: 15300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0598_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C6J1-0404D-SP",
//     price: 11600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C7H4-0606D-SP",
//     price: 26700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C8L8-0409D-SP",
//     price: 17600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2103_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D120-0804D-SP",
//     price: 22700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2195_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://www.ti.com/product/TPS2663/part-details/TPS26630RGET",
//     mfgPart: "TPS26630RGET",
//     models: "this is the new part",
//     pn: "XM-D242-0505D-SP",
//     price: 17300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "1345_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://www.ti.com/product/LP38798/part-details/LP38798SD-ADJ/NOPB",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-D297-0909D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "2428_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D2U4-0609D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D2V7-0619D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://www.ti.com/product/LP38798/part-details/LP38798SD-ADJ/NOPB",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-D3N7-0404D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0231_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D3P2-0409D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D4M9-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D4M9-0604D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D4V1-0404D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Controllers",
//     newBRDImage: "0852_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1156",
//     catId: "CTL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/lt3462.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1156A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6l6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3462.html",
//     mfgPart: "LT3462ASMT",
//     models: "this part is missing models",
//     pn: "XM-C6L6-0404D",
//     price: 9200,
//     priceType: "cents",
//     props: [8, 10, -8, 5575, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "DC-DC Converters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0377",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3v4-0104d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-A3V4-0104D-SP",
//     price: 2400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0378",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3v5-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-A3V5-0404D-SP",
//     price: 4000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "0378_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0379",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3v6-0604d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-A3V6-0604D-SP",
//     price: 4500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0380",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3v7-0804d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-A3V7-0804D-SP",
//     price: 4200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0381",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3v8-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-A3V8-0409D-SP",
//     price: 4400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "0381_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1709",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a433-0304d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-A433-0304D-SP",
//     price: 3500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0378",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3v5-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-A9P6-0404D-SP",
//     price: 4000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "0378_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0378",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3v5-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-D435-0404D-SP",
//     price: 4000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "0378_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0378",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a3v5-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-D436-0404D-SP",
//     price: 4000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "0378_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-D1P1-0304D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-D2W4-0809D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "2035_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-D364-0909D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "2036_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GP Control Board",
//     models: "this is the new part",
//     pn: "XM-D431-0207D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "GP Control Boards",
//     newBRDImage: "2531_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a133-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A133-0404D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=10V Vin=10.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j4-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A2J4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [10, null, 10.5, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j4-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A2J4-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=12V Vin=12.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j5-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A2J5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [12, null, 12.5, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j5-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A2J5-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=15V Vin=15.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j6-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A2J6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [15, null, 15.5, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j6-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A2J6-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=2.5V Vin=3V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j7-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A2J7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [2.5, null, 3, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j7-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A2J7-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=3.3V Vin=4V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j8-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A2J8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [3.3, null, 4, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j8-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A2J8-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=5V Vin=5.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j9-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A2J9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5, null, 5.5, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j9-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A2J9-0404D-SP",
//     price: 8400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=7V Vin=7.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2k1-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A2K1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [7, null, 7.5, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a2k1-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A2K1-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=3V Vin=3.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2r9-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A2R9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [3, null, 3.5, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a2r9-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A2R9-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=8V Vin=8.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4e3-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A4E3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [8, null, 8.5, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a4e3-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A4E3-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=3.5V Vin=4V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6e9-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A6E9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [3.5, null, 4, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a6e9-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A6E9-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=2V Vin=2.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k2-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A6K2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [2, null, 2.5, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k2-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A6K2-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=5.5V Vin=6V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k7-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A6K7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5.5, null, 6, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a6k7-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A6K7-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0360",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADM7150.pdf",
//     description: "Vout=5V Vin=5.5V to 16V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0360C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7a1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/power-management/ldo-linear-regulators/positive-linear-regulators/adm7150.html",
//     mfgPart: "ADM7150ACPZ-5.0-R7",
//     models: "this part is missing models",
//     pn: "XM-A7A1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5, null, 5.5, 16, null, null, null, null, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0360_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0360",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADM7150.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a7a1-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/power-management/ldo-linear-regulators/positive-linear-regulators/adm7150.html",
//     mfgPart: "ADM7150ACPZ-5.0-R7",
//     models: "this is the new part",
//     pn: "XM-A7A1-0404D-SP",
//     price: 11700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0360_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0423",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7182.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a7a7-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-A7A7-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0423",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7182.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a7a7-0409d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-A7A7-0409D-SP",
//     price: 17100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=6V Vin=6.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7u1-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-A7U1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [6, null, 6.5, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a7u1-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-A7U1-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0812",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3045fa.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0812A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9h4-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-A9H4-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y6",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0812_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0812",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3045fa.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a9h4-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A9H4-0604D-SP",
//     price: 13500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0812_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0917",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7182.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0917A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9s3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this part is missing models",
//     pn: "XM-A9S3-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0917_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0917",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7182.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a9s3-0409d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-5.0-R7",
//     models: "this is the new part",
//     pn: "XM-A9S3-0409D-SP",
//     price: 13900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0917_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0534",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=3.5V Vin=4V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0534A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b123-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-B123-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [3.5, null, 4, 15, null, null, null, null, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0534_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0534",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b123-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-B123-0404D-SP",
//     price: 10600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0534_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0534",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b124-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-B124-0404D-SP",
//     price: 10600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0534_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0534",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b189-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-B189-0404D-SP",
//     price: 9000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0534_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0435",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7142.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0435C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1v7-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-B1V7-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z6",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0435",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7142.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1v7-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B1V7-0604D-SP",
//     price: 14900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0435_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1925",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1925A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1x6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-B1X6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1925",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1x6-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B1X6-0404D-SP",
//     price: 12500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=4V Vin=4.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5g7-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-B5G7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [4, null, 4.5, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5g7-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-B5G7-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0360",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADM7150.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0360C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6m1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/power-management/ldo-linear-regulators/positive-linear-regulators/adm7150.html#product-overview",
//     mfgPart: "ADM7150ACPZ",
//     models: "this part is missing models",
//     pn: "XM-B6M1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0360_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0360",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADM7150.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b6m1-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/power-management/ldo-linear-regulators/positive-linear-regulators/adm7150.html#product-overview",
//     mfgPart: "ADM7150ACPZ",
//     models: "this is the new part",
//     pn: "XM-B6M1-0404D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0360_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=9V Vin=9.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7p9-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-B7P9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [9, null, 9.5, 15, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b7p9-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-B7P9-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1255",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3045fa.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b8u2-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-B8U2-0404D-SP",
//     price: 12600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "1255_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1726",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7142.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9d6-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B9D6-0604D-SP",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1905",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7182.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9d7-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-B9D7-0604D-SP",
//     price: 14400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1694",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1694A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9f2-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-B9F2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1694",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9f2-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-B9F2-0404D-SP",
//     price: 9500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1447",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7182.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1447A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3t4-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-C3T4-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y9",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=6.5V Vin=7V to 15V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3w5-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-C3W5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [
//       6.5,
//       null,
//       7,
//       15,
//       null,
//       null,
//       null,
//       null,
//       null,
//       0,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1357",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/3045fa.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3y5-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C3Y5-0404D-SP",
//     price: 10800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1726",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7142.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1726A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c588-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this part is missing models",
//     pn: "XM-C588-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z6",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1726",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7142.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c588-0604d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C588-0604D-SP",
//     price: 14700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "Vout=4.5V Vin=5V to 20V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7j8-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-C7J8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [4.5, null, 5, 20, null, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7j8-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this is the new part",
//     pn: "XM-C7J8-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1692",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1692A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7y8-0609d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C7Y8-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z9",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1692",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7y8-0609d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C7Y8-0609D-SP",
//     price: 27500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0935-A",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1598A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c811-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C811-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0935-A",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c811-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C811-0404D-SP",
//     price: 11200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1940",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1940A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8m9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this part is missing models",
//     pn: "XM-C8M9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Z",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1940",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8m9-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C8M9-0404D-SP",
//     price: 13100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9f9-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD",
//     models: "this part is missing models",
//     pn: "XM-C9F9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0950",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7182.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0950B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9g5-0804d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this part is missing models",
//     pn: "XM-C9G5-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0950_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0950",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADP7182.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9g5-0804d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-C9G5-0804D-SP",
//     price: 21200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0950_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1710",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9g6-0716d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C9G6-0716D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1710",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9g6-0716d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C9G6-0716D-SP",
//     price: 49100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1710",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9g8-0716d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C9G8-0716D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9j1-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ",
//     models: "this part is missing models",
//     pn: "XM-C9J1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0113I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9j2-0404d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ",
//     models: "this part is missing models",
//     pn: "XM-C9J2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9j2-0404d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ",
//     models: "this is the new part",
//     pn: "XM-C9J2-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1848",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9m7-0405d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ",
//     models: "this is the new part",
//     pn: "XM-C9M7-0405D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "1848_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1848",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9m7-0405d-sp/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ",
//     models: "this is the new part",
//     pn: "XM-C9M7-0405D-SP",
//     price: 7600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "1848_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 5,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0457C.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SDSMT",
//     models: "this part is missing models",
//     pn: "XM-A5C8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "Y3",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0457_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/lp38798.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SDSMT",
//     models: "this is the new part",
//     pn: "XM-A5C8-0404D-SP",
//     price: 11800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0457_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9j1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B2Y3-0407D-SP",
//     price: 17400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "1964_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9j2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-B2Y3-0407D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "1964_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9j2-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D4D8-0304D-SP",
//     price: 10300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2016_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9j1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D4D8-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2016_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9j2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A492-0304D-SP",
//     price: 10300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2016_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9j2-0404d-sp/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A492-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2016_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-D3G2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-D3G2-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ltc3260.html",
//     mfgPart: "LTC3260EDE#PBF",
//     models: "this is the new part",
//     pn: "XM-D3G6-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2518_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ltc3260.html",
//     mfgPart: "LTC3260EDE#PBF",
//     models: "this is the new part",
//     pn: "XM-D3G6-0804D-SP",
//     price: 19000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2518_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A413-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "1962_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A413-0404D-SP",
//     price: 12500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "1962_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-D183-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-D183-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7182.html",
//     mfgPart: "ADP7182ACPZ-R7",
//     models: "this is the new part",
//     pn: "XM-C3T4-0409D-SP",
//     price: 18600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D193-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D193-0404D-SP",
//     price: 12500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D1A3-0807D-SP",
//     price: 21700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D1A3-0807D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D195-0404D-SP",
//     price: 12400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "1576_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D195-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "1576_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D146-0609D-SP",
//     price: 27200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D146-0609D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C6A7-0604D-SP",
//     price: 18500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C6A7-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C9M8-0404D-SP",
//     price: 11200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C9M8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D1B6-0404D-SP",
//     price: 12500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "1962_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D1B6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "1962_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D254-0304D-SP",
//     price: 11000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2016_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-D254-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2016_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A494-0604D-SP",
//     price: 14100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2128_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-A494-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2128_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/LP38798",
//     mfgPart: "LP38798SD-ADJ/NOPB",
//     models: "this is the new part",
//     pn: "XM-C9F9-0404D-SP",
//     price: 10200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0113_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C2U9-0404D-SP",
//     price: 10500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0852_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-C2U9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "0852_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D1T5-0604D-SP",
//     price: 23900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2238_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adp7142.html",
//     mfgPart: "ADP7142ACPZN-R7",
//     models: "this is the new part",
//     pn: "XM-D1T5-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "2238_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Vout=5V Vin=5.5V to 15V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C3R6-0806D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0113",
//     catId: "VRG",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "TXINS",
//     mfgLandingPage: "https://www.analog.com/en/products/lt3045.html",
//     mfgPart: "LT3045EDD#TRPBF",
//     models: "this is the new part",
//     pn: "XM-C3R6-0806D-SP",
//     price: 16200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Voltage Regulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0210",
//     catId: "BTE",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a2n8-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "BT-0010SMG-1SMT",
//     models: "this is the new part",
//     pn: "XM-A2N8-0404D",
//     price: 18200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0210",
//     catId: "BTE",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a357-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "BT-0010SMG-2SMT",
//     models: "this is the new part",
//     pn: "XM-A357-0404D",
//     price: 18200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0210",
//     catId: "BTE",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BT-0030SMG.pdf",
//     description: "F=20M-30G IL=1dB Iso=40dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0210J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3a9-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/bias-tees/bt-0030smg.aspx",
//     mfgPart: "BT-0030SMG-1SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3A9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3A9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 20, 30000, 1, 40, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J3",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0139",
//     catId: "BTE",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "http://www.piconics.com/wp-content/uploads/2018/03/SMT-Broadband-Conical-Data-Sheet.pdf",
//     description: "F=10M-30G IL=0.35dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0139I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4e8-0404d/",
//     mfgCode: "PCNCS",
//     mfgLandingPage: "http://www.piconics.com/smt/",
//     mfgPart: "CC19T40K240G5-CSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4E8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4E8-0404D",
//     price: 14900,
//     priceType: "cents",
//     props: [null, 10, 30000, 0.35, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J3",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0139_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0210",
//     catId: "BTE",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BT-0014SMG.pdf",
//     description: "F=0.5M-14G Iso=40dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0210J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4e9-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/bias-tees/bt-0014smg.aspx",
//     mfgPart: "BT-0014SMG-2SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4E9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4E9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 0.5, 14000, 0, 40, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J3",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0210",
//     catId: "BTE",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BT-0014SMG.pdf",
//     description: "F=0.5M-14G Iso=40dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0210J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8f3-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/bias-tees/bt-0014smg.aspx",
//     mfgPart: "BT-0014SMG-1SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A8F3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A8F3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 0.5, 14000, 0, 40, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J3",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0210",
//     catId: "BTE",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BT-0024SMG.pdf",
//     description: "F=0.5M-24G Iso=40dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0210J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8f4-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/bias-tees/bt-0024smg.aspx",
//     mfgPart: "BT-0024SMG-2SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A8F4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A8F4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 0.5, 24000, 0, 40, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J3",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0210",
//     catId: "BTE",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BT-0034SMG.pdf",
//     description: "F=0.5M-34G Iso=40dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0210J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8f5-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/bias-tees/bt-0034smg.aspx",
//     mfgPart: "BT-0034SMG-1SMT",
//     models: "this part is missing models",
//     pn: "XM-A8F5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 0.5, 34000, 0, 40, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J3",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0210",
//     catId: "BTE",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BT-0024SMG.pdf",
//     description: "F=0.5M-24G Iso=40dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0210J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8f6-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/bias-tees/bt-0024smg.aspx",
//     mfgPart: "BT-0024SMG-1SMT",
//     models: "this part is missing models",
//     pn: "XM-A8F6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 0.5, 24000, 0, 40, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J3",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0210",
//     catId: "BTE",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BT-0030SMG.pdf",
//     description: "F=20M-30G Iso=40dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0210J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8f7-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/bias-tees/bt-0030smg.aspx",
//     mfgPart: "BT-0030SMG-2SMT",
//     models: "this part is missing models",
//     pn: "XM-A8F7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 20, 30000, 0, 40, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J3",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0210",
//     catId: "BTE",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/BT-0034SMG.pdf",
//     description: "F=0.5M-34G Iso=40dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0210J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a8f8-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/bias-tees/bt-0034smg.aspx",
//     mfgPart: "BT-0034SMG-2SMT",
//     models: "this part is missing models",
//     pn: "XM-A8F8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 0.5, 34000, 0, 40, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J3",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0791",
//     catId: "BTE",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL: "https://www.coilcraft.com/pdfs/4310lc.pdf",
//     description: "F=1M-6G IL=0.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0791A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9h3-0406d/",
//     mfgCode: "COILC",
//     mfgLandingPage: "https://www.coilcraft.com/4310lc.cfm",
//     mfgPart: "4310LC-352KESMT",
//     models: "this part is missing models",
//     pn: "XM-A9H3-0406D",
//     price: 9300,
//     priceType: "cents",
//     props: [null, 1, 6000, 0.5, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J6",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0791_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1270",
//     catId: "BTE",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "http://www.piconics.com/wp-content/uploads/2018/03/SMT-Broadband-Conical-Data-Sheet.pdf",
//     description: "F=50M-30G IL=1dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1270B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8t9-0204d/",
//     mfgCode: "PCNCS",
//     mfgLandingPage: "http://www.piconics.com/smt/",
//     mfgPart: "CC19T40K240G5-CSMT",
//     models: "this part is missing models",
//     pn: "XM-B8T9-0204D",
//     price: 14400,
//     priceType: "cents",
//     props: [null, 50, 30000, 1, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J1",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "1270_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1259",
//     catId: "BTE",
//     ctlBoard: "XM-A3V4-0104D",
//     datasheetURL:
//       "http://www.piconics.com/wp-content/uploads/2019/02/Micro-SMT-Broadband-Conical-Data-Sheet.pdf",
//     description: "F=150M-64G IL=1dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1259A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c884-0204d/",
//     mfgCode: "PCNCS",
//     mfgLandingPage: "http://www.piconics.com/smt/",
//     mfgPart: "CC20T44K240G5-CSMT",
//     models: "this part is missing models",
//     pn: "XM-C884-0204D",
//     price: 13400,
//     priceType: "cents",
//     props: [null, 150, 64000, 1, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J1",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "1259_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1270",
//     catId: "BTE",
//     ctlBoard: "XM-A3V4-0104D",
//     datasheetURL:
//       "http://www.piconics.com/wp-content/uploads/2018/03/SMT-Broadband-Conical-Data-Sheet.pdf",
//     description: "F=200M-60G IL=0.7dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1270B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c885-0204d/",
//     mfgCode: "PCNCS",
//     mfgLandingPage: "http://www.piconics.com/smt/",
//     mfgPart: "CC45T47K240G5C2SMT",
//     models: "this part is missing models",
//     pn: "XM-C885-0204D",
//     price: 13300,
//     priceType: "cents",
//     props: [null, 200, 60000, 0.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "J1",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "1270_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BTE",
//     ctlBoard: "XM-A433-0304D-SP",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=MBT-283%2B",
//     mfgPart: "MBT-283+",
//     models: "this is the new part",
//     pn: "XM-A432-0304D",
//     price: 10100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BTE",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/bias-tees/btl-0012smg.aspx",
//     mfgPart: "BTL-0012SMG-2",
//     models: "this is the new part",
//     pn: "XM-D241-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BTE",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/bias-tees/btl-0026smg.aspx",
//     mfgPart: "BTL-0026SMG-1",
//     models: "this is the new part",
//     pn: "XM-D2E5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Bias Tees",
//     newBRDImage: "0210_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0883",
//     catId: "CIR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.jqlelectronics.com/wp-content/uploads/2015/08/JCM9190T10K0VM0R.pdf",
//     description: "F=9.19G-10G IL=0.4dB Iso=20dB",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0883B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7r4-0609d/",
//     mfgCode: "JQLEL",
//     mfgLandingPage: "http://www.jqlelectronics.com/product/jcm9190t10k0vm0r-3/",
//     mfgPart: "JCM9190T10K0VM0RSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7R4D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7R4-0609D",
//     price: 19700,
//     priceType: "cents",
//     props: [null, 9190, 10000, 0.4, 1000, 20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Circulators and Isolators",
//     newBRDImage: "0883_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0884",
//     catId: "CIR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.jqlelectronics.com/wp-content/uploads/2015/08/JCM9190T10K0VM0R.pdf",
//     description: "F=9.19G-10G IL=0.4dB Iso=20dB",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0884B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2k1-0609d/",
//     mfgCode: "JQLEL",
//     mfgLandingPage: "http://www.jqlelectronics.com/product/jcm9190t10k0vm0r-3/",
//     mfgPart: "JCM9190T10K0VM0RSMT",
//     models: "this part is missing models",
//     pn: "XM-B2K1-0609D",
//     price: 18900,
//     priceType: "cents",
//     props: [null, 9190, 10000, 0.4, 1000, 20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Circulators and Isolators",
//     newBRDImage: "0884_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0988",
//     catId: "CIR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.rf-ci.com/wp-content/themes/rfci/pdf/SMD_Isolator_Pin/SL5403-OSrA.pdf",
//     description: "F=1.52G-1.665G IL=0.25dB Iso=23dB",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0988A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5p4-1009d/",
//     mfgCode: "RFCII",
//     mfgLandingPage: "http://www.rf-ci.com/",
//     mfgPart: "RFSL5403SMT",
//     models: "this part is missing models",
//     pn: "XM-B5P4-1009D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 1520, 1665, 0.25, 20000, 23],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Circulators and Isolators",
//     newBRDImage: "0988_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0988",
//     catId: "CIR",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.rf-ci.com/wp-content/themes/rfci/pdf/SMD_Isolator_Pin/SL5314-OSrA.pdf",
//     description: "F=1.42G-1.52G IL=0.22dB Iso=23dB",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b5p5-1009d/",
//     mfgCode: "RFCII",
//     mfgLandingPage:
//       "http://www.rf-ci.com/isolators/smd-isolator/pin-style-solder-reflow/",
//     mfgPart: "RFSL5314SMT",
//     models: "this is the new part",
//     pn: "XM-B5P5-1009D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Circulators and Isolators",
//     newBRDImage: "0988_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0988",
//     catId: "CIR",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.rfmw.com/data/RFCI_SMD_PinStyle.pdf",
//     description: "F=920M-960M",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b7j2-1009d/",
//     mfgCode: "RFCII",
//     mfgLandingPage: "http://www.rf-ci.com/",
//     mfgPart: "RFSL5308SMT",
//     models: "this is the new part",
//     pn: "XM-B7J2-1009D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Circulators and Isolators",
//     newBRDImage: "0988_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CIR",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=3.3G-3.8G",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "SKYWK",
//     mfgLandingPage:
//       "https://store.skyworksinc.com/products/detail/skyfr001822-skyworks-solutions-inc/758879/",
//     mfgPart: "SKYFR-001822",
//     models: "this is the new part",
//     pn: "XM-D3K8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Circulators and Isolators",
//     newBRDImage: "2519_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0479",
//     catId: "CMP",
//     ctlBoard: "XM-A4H5-0609D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC874LC3C.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0479B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4h4-0609d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/analog-functions/comparators/high-speed-comparators-lessthan-100ns-propagation-delay/hmc874.html",
//     mfgPart: "HMC874LC3CSMT",
//     models: "this part is missing models",
//     pn: "XM-A4H4-0609D",
//     price: 30500,
//     priceType: "cents",
//     props: [1, 10000, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X9",
//     newCategoryDescription: "Comparators",
//     newBRDImage: "0479_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0799",
//     catId: "DMD",
//     ctlBoard: "XM-A9H4-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5380.pdf",
//     description: "LOlvl=0dBm RF=400M-6G LO=400M-6G IF=DC-390M ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0799A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a615-0819d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/iq-modulators-demodulators/iq-demodulators/adl5380.html",
//     mfgPart: "ADL5380ACPZ-WPSMT",
//     models: "this part is missing models",
//     pn: "XM-A615-0819D",
//     price: 34700,
//     priceType: "cents",
//     props: [0, 400, 6000, 400, 6000, 0, 390, null, null, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X9",
//     newCategoryDescription: "Demodulators",
//     newBRDImage: "0799_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0799",
//     catId: "DMD",
//     ctlBoard: "XM-A9H4-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADL5380.pdf",
//     description: "LOlvl=0dBm RF=400M-6G LO=400M-6G IF=DC-390M ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0799A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7f2-0819d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/iq-modulators-demodulators/iq-demodulators/adl5380.html",
//     mfgPart: "ADL5380ACPZ-WPSMT",
//     models: "this part is missing models",
//     pn: "XM-C7F2-0819D",
//     price: 34700,
//     priceType: "cents",
//     props: [0, 400, 6000, 400, 6000, 0, 390, 0, null, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X9",
//     newCategoryDescription: "Demodulators",
//     newBRDImage: "0799_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "DMD",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=JCIQ-176D%2B",
//     mfgPart: "JCIQ-176D+",
//     models: "this is the new part",
//     pn: "XM-D173-1009D",
//     price: 27400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Demodulators",
//     newBRDImage: "2199_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1525",
//     catId: "DNC",
//     ctlBoard: "XM-B8E6-0812D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADMV1014.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9u6-0919d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/admv1014.html",
//     mfgPart: "ADMV1014SMT",
//     models: "this is the new part",
//     pn: "XM-B9U6-0919D",
//     price: 54600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Downconverters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1526",
//     catId: "DNC",
//     ctlBoard: "XM-B916-0809D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADMV1014.pdf",
//     description: "LOlvl=0dBm RF=24G-44G LO=5.4G-10.25G IF=DC-6G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1526A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9u7-0909d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/admv1014.html",
//     mfgPart: "ADMV1014SMT",
//     models: "this part is missing models",
//     pn: "XM-B9U7-0909D",
//     price: 50500,
//     priceType: "cents",
//     props: [0, 5400, 10250, 24000, 44000, 0, 6000, 0, null, 17],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Downconverters",
//     newBRDImage: "1526_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1557",
//     catId: "DNC",
//     ctlBoard: "XM-C6J1-0609D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/HMC977.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6h9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc977.html",
//     mfgPart: "HMC977LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-C6H9-0409D",
//     price: 17800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Downconverters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1077",
//     catId: "MOD",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/trf370315.pdf",
//     description: "LOlvl=0dBm RF=350M-4G LO=350M-4G IF=DC-350M CL=3.345dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1077A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2m5-0819d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/TRF370315",
//     mfgPart: "TRF370315SMT",
//     models: "this part is missing models",
//     pn: "XM-B2M5-0819D",
//     price: 31300,
//     priceType: "cents",
//     props: [0, 350, 4000, 350, 4000, 0, 350, 3.345],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X9",
//     newCategoryDescription: "Modulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1077",
//     catId: "MOD",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "http://www.ti.com/lit/gpn/trf370417",
//     description: "LOlvl=0dBm RF=50M-6G LO=50M-6G IF=DC-1G CL=8dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1077A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c353-0819d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/TRF370417",
//     mfgPart: "TRF370417SMT",
//     models: "this part is missing models",
//     pn: "XM-C353-0819D",
//     price: 32500,
//     priceType: "cents",
//     props: [0, 50, 6000, 50, 6000, 0, 1000, 8, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X9",
//     newCategoryDescription: "Modulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1077",
//     catId: "MOD",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://www.ti.com/lit/ds/symlink/trf370317.pdf",
//     description: "LOlvl=0dBm RF=400M-4G LO=400M-4G IF=DC-350M ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1077A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c354-0819d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/TRF370317",
//     mfgPart: "TRF370317SMT",
//     models: "this part is missing models",
//     pn: "XM-C354-0819D",
//     price: 29100,
//     priceType: "cents",
//     props: [0, 400, 4000, 400, 4000, 0, 350, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X9",
//     newCategoryDescription: "Modulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1077",
//     catId: "MOD",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL: "http://www.ti.com/lit/gpn/TRF3705",
//     description: "RF=300M-4G LO=300M-4G IF=DC-1G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1077A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c355-0819d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/TRF3705",
//     mfgPart: "TRF3705SMT",
//     models: "this part is missing models",
//     pn: "XM-C355-0819D",
//     price: 28700,
//     priceType: "cents",
//     props: [0, 300, 4000, 300, 4000, 0, 1000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X9",
//     newCategoryDescription: "Modulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1077",
//     catId: "MOD",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL: "http://www.ti.com/lit/gpn/TRF37T05",
//     description: "RF=300M-4G LO=300M-4G IF=DC-1G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1077A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c356-0819d/",
//     mfgCode: "TXINS",
//     mfgLandingPage: "http://www.ti.com/product/TRF37T05",
//     mfgPart: "TRF37T05SMT",
//     models: "this part is missing models",
//     pn: "XM-C356-0819D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 300, 4000, 300, 4000, 0, 1000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X9",
//     newCategoryDescription: "Modulators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "2136",
//     catId: "UPC",
//     ctlBoard: "XM-B6K5-0812D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADMV1013.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9u4-0919d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/admv1013.html",
//     mfgPart: "ADMV1013SMT",
//     models: "this is the new part",
//     pn: "XM-B9U4-0919D",
//     price: 64400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Upconverters",
//     newBRDImage: "2136_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1528",
//     catId: "UPC",
//     ctlBoard: "XM-B915-0809D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADMV1013.pdf",
//     description: "LOlvl=0dBm RF=24G-44G LO=5.4G-10.25G IF=DC-6G ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1528A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9u5-0909d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/admv1013.html",
//     mfgPart: "ADMV1013SMT",
//     models: "this part is missing models",
//     pn: "XM-B9U5-0909D",
//     price: 50800,
//     priceType: "cents",
//     props: [0, 24000, 44000, 5400, 10250, 0, 6000, 0, null, 18],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Upconverters",
//     newBRDImage: "1528_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1571",
//     catId: "UPC",
//     ctlBoard: "XM-C6K1-0909D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF6780.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6j6-0909d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-upconverters-transmitters/adrf6780.html",
//     mfgPart: "ADRF6780ACPZNSMT",
//     models: "this is the new part",
//     pn: "XM-C6J6-0909D",
//     price: 45800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Upconverters",
//     newBRDImage: "1571_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1563",
//     catId: "UPC",
//     ctlBoard: "XM-C351-0612D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADRF6780.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6k7-0919d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/mixers/iq-upconverters-transmitters/adrf6780.html",
//     mfgPart: "ADRF6780ACPZNSMT",
//     models: "this is the new part",
//     pn: "XM-C6K7-0919D",
//     price: 58400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Upconverters",
//     newBRDImage: "1563_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0071",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC0710J5010AHF/DC0710J5010AHF_DataSheet(Rev_B).pdf",
//     description: "Directional CPL=-11dB F=700M-1G IL=0.6dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0071H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1f5-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC0710J5010AHF",
//     models: "this part is missing models",
//     pn: "XM-A1F5-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [700, 1000, -11, 0.6, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0071_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0071",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC0710J5020AHF/DC0710J5020AHF_DataSheet(Rev_E).pdf",
//     description: "Directional CPL=-21dB F=700M-1G IL=0.5dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0071H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1f6-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC0710J5020AHF",
//     models: "this part is missing models",
//     pn: "XM-A1F6-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [700, 1000, -21, 0.5, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0071_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0071",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC1722J5010AHF/DC1722J5010AHF_DataSheet(Rev_E).pdf",
//     description: "Directional CPL=-11dB F=1.7G-2.2G IL=0.7dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0071H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1f7-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC1722J5010AHF",
//     models: "this part is missing models",
//     pn: "XM-A1F7-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [1700, 2200, -11, 0.7, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0071_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0071",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC1722J5015AHF/DC1722J5015AHF_Datasheet(Rev_C).pdf",
//     description: "Directional CPL=-16dB F=1.7G-2.2G IL=0.5dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0071H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1f8-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC1722J5015AHF",
//     models: "this part is missing models",
//     pn: "XM-A1F8-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [1700, 2200, -16, 0.5, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0071_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0071",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC1722J5020AHF/DC1722J5020AHF_DataSheet(Rev_C).pdf",
//     description: "Directional CPL=-21dB F=1.7G-2.2G IL=0.4dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0071H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1f9-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC1722J5020AHF",
//     models: "this part is missing models",
//     pn: "XM-A1F9-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [1700, 2200, -21, 0.4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0071_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0071",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC2337J5010AHF/DC2337J5010AHF_DataSheet(Rev_C).pdf",
//     description: "Directional CPL=-12dB F=2.3G-3.7G IL=0.7dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0071H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1g1-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC2337J5010AHF",
//     models: "this part is missing models",
//     pn: "XM-A1G1-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [2300, 3700, -12, 0.7, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0071_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0071",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC2337J5020AHF/DC2337J5020AHF_DataSheet(Rev_E).pdf",
//     description: "Directional CPL=-22dB F=2.3G-3.7G IL=0.4dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0071H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1g2-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC2337J5020AHF",
//     models: "this part is missing models",
//     pn: "XM-A1G2-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [2300, 3700, -22, 0.4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0071_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0071",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC4759J5020AHF/DC4759J5020AHF_DataSheet(Rev_E).pdf",
//     description: "Directional CPL=-21dB F=4.7G-5.9G IL=0.6dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0071H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1g3-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC4759J5020AHF",
//     models: "this part is missing models",
//     pn: "XM-A1G3-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [4700, 5900, -21, 0.6, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0071_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C09P2-30S/X3C09P2-30S_Datasheet(Rev_C).pdf",
//     description: "Directional CPL=-31dB F=800M-1G IL=0.2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1g4-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C09P2-30S",
//     models: "this part is missing models",
//     pn: "XM-A1G4-0404D",
//     price: 10300,
//     priceType: "cents",
//     props: [800, 1000, -31, 0.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C19P2-30S/X3C19P2-30S_DataSheet(Rev_B).pdf",
//     description: "Directional CPL=-31dB F=1.4G-2.7G IL=0.2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1g5-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C19P2-30S",
//     models: "this part is missing models",
//     pn: "XM-A1G5-0404D",
//     price: 10300,
//     priceType: "cents",
//     props: [1400, 2700, -31, 0.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C26P1-30S/X3C26P1-30S_DataSheet(Rev_A).pdf",
//     description: "Directional CPL=-31dB F=2.3G-3.6G IL=0.2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1g6-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C26P1-30S",
//     models: "this part is missing models",
//     pn: "XM-A1G6-0404D",
//     price: 10300,
//     priceType: "cents",
//     props: [2300, 3600, -31, 0.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0074",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.richardsonrfpd.com/docs/rfpd/X3C07P1-05S_Data_Sheet_prelim.pdf",
//     description: "Directional CPL=-5.6dB F=600M-900M IL=1.8dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0074H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1g7-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C07P1-05S",
//     models: "this part is missing models",
//     pn: "XM-A1G7-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [600, 900, -5.6, 1.8, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0074",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C09P1-05S/X3C09P1-05S_DataSheet(Rev_A).pdf",
//     description: "Directional CPL=-5.2dB F=800M-1G IL=1.9dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0074H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1g8-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C09P1-05S",
//     models: "this part is missing models",
//     pn: "XM-A1G8-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [800, 1000, -5.2, 1.9, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0074",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C19P1-05S/X3C19P1-05S_DataSheet(Rev_C).pdf",
//     description: "Directional CPL=-5.1dB F=1.7G-2G IL=2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0074H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1g9-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C19P1-05S",
//     models: "this part is missing models",
//     pn: "XM-A1G9-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [1700, 2000, -5.1, 2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0074",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C21P1-05S/X3C21P1-05S_DataSheet(Rev_A).pdf",
//     description: "Directional CPL=-5.2dB F=2G-2.3G IL=2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0074H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1h1-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C21P1-05S",
//     models: "this part is missing models",
//     pn: "XM-A1H1-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [2000, 2300, -5.2, 2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0074",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C25P1-05S/X3C25P1-05S_DataSheet(Rev_B).pdf",
//     description: "Directional CPL=-5dB F=2.3G-2.7G IL=2.2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0074H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1h2-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C25P1-05S",
//     models: "this part is missing models",
//     pn: "XM-A1H2-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [2300, 2700, -5, 2.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0075",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC0710J5010AHF/DC0710J5010AHF_DataSheet(Rev_B).pdf",
//     description: "Directional CPL=-11dB F=700M-1G IL=0.6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0075H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2d8-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC0710J5010AHF",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2D8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2D8-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [700, 1000, -11, 0.6, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0075_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0075",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC0710J5020AHF/DC0710J5020AHF_DataSheet(Rev_E).pdf",
//     description: "Directional CPL=-21dB F=700M-1G IL=0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0075H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2d9-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC0710J5020AHF",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2D9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2D9-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [700, 1000, -21, 0.5, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0075_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0075",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC1722J5010AHF/DC1722J5010AHF_DataSheet(Rev_E).pdf",
//     description: "Directional CPL=-11dB F=1.7G-2.2G IL=0.7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0075H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2e1-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC1722J5010AHF",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2E1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2E1-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [1700, 2200, -11, 0.7, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0075_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0075",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC1722J5015AHF/DC1722J5015AHF_Datasheet(Rev_C).pdf",
//     description: "Directional CPL=-16dB F=1.7G-2.2G IL=0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0075H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2e2-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC1722J5015AHF",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2E2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2E2-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [1700, 2200, -16, 0.5, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0075_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0075",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC1722J5020AHF/DC1722J5020AHF_DataSheet(Rev_C).pdf",
//     description: "Directional CPL=-21dB F=1.7G-2.2G IL=0.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0075H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2e3-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC1722J5020AHF",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2E3D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2E3-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [1700, 2200, -21, 0.4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0075_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0075",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC2337J5010AHF/DC2337J5010AHF_DataSheet(Rev_C).pdf",
//     description: "Directional CPL=-12dB F=2.3G-3.7G IL=0.7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0075H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2e4-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC2337J5010AHF",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2E4D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2E4-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [2300, 3700, -12, 0.7, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0075_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0075",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC2337J5020AHF/DC2337J5020AHF_DataSheet(Rev_E).pdf",
//     description: "Directional CPL=-22dB F=2.3G-3.7G IL=0.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0075H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2e5-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC2337J5020AHF",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2E5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2E5-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [2300, 3700, -22, 0.4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0075_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0075",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/DC4759J5020AHF/DC4759J5020AHF_DataSheet(Rev_E).pdf",
//     description: "Directional CPL=-21dB F=4.7G-5.9G IL=0.6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0075H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2e6-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "DC4759J5020AHF",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2E6D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2E6-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [4700, 5900, -21, 0.6, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0075_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0076",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C09P2-30S/X3C09P2-30S_Datasheet(Rev_C).pdf",
//     description: "Directional CPL=-31dB F=800M-1G IL=0.2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0076H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2e7-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C09P2-30S",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2E7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2E7-0404D",
//     price: 10300,
//     priceType: "cents",
//     props: [800, 1000, -31, 0.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0076",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C19P2-30S/X3C19P2-30S_DataSheet(Rev_B).pdf",
//     description: "Directional CPL=-31dB F=1.4G-2.7G IL=0.2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0076H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2e8-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C19P2-30S",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2E8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2E8-0404D",
//     price: 10300,
//     priceType: "cents",
//     props: [1400, 2700, -31, 0.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0076",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C26P1-30S/X3C26P1-30S_DataSheet(Rev_A).pdf",
//     description: "Directional CPL=-31dB F=2.3G-3.6G IL=0.2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0076H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2e9-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C26P1-30S",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2E9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2E9-0404D",
//     price: 10300,
//     priceType: "cents",
//     props: [2300, 3600, -31, 0.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0078",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.richardsonrfpd.com/docs/rfpd/X3C07P1-05S_Data_Sheet_prelim.pdf",
//     description: "Directional CPL=-5.6dB F=600M-900M IL=1.8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0078H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2f1-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C07P1-05S",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2F1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2F1-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [600, 900, -5.6, 1.8, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0078",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C09P1-05S/X3C09P1-05S_DataSheet(Rev_A).pdf",
//     description: "Directional CPL=-5.2dB F=800M-1G IL=1.9dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0078H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2f2-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C09P1-05S",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2F2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2F2-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [800, 1000, -5.2, 1.9, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0078",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C19P1-05S/X3C19P1-05S_DataSheet(Rev_C).pdf",
//     description: "Directional CPL=-5.1dB F=1.7G-2G IL=2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0078H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2f3-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C19P1-05S",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2F3D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2F3-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [1700, 2000, -5.1, 2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0078",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C21P1-05S/X3C21P1-05S_DataSheet(Rev_A).pdf",
//     description: "Directional CPL=-5.2dB F=2G-2.3G IL=2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0078H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2f4-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C21P1-05S",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2F4D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2F4-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [2000, 2300, -5.2, 2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0078",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C25P1-05S/X3C25P1-05S_DataSheet(Rev_B).pdf",
//     description: "Directional CPL=-5dB F=2.3G-2.7G IL=2.2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0078H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2f5-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C25P1-05S",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2F5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2F5-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [2300, 2700, -5, 2.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0009",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-6-1R+.pdf",
//     description: "Directional CPL=-6.2dB F=5M-200M IL=1.8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0009H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2f9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-6-1R%2B",
//     mfgPart: "ADC-6-1R+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2F9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2F9-0404D",
//     price: 10800,
//     priceType: "cents",
//     props: [5, 200, -6.2, 1.8, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0009_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0009",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-10-1R+.pdf",
//     description: "Directional CPL=-10.5dB F=5M-900M IL=1dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0009H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2g1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-10-1R%2B",
//     mfgPart: "ADC-10-1R+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2G1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2G1-0404D",
//     price: 10800,
//     priceType: "cents",
//     props: [5, 900, -10.5, 1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0009_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0009",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-6-13+.pdf",
//     description: "Directional CPL=-6.7dB F=200M-1.3G IL=2dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0009H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2g2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-6-13%2B",
//     mfgPart: "ADC-6-13+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2G2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2G2-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [200, 1300, -6.7, 2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0009_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0009",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-10-4+.pdf",
//     description: "Directional CPL=-11.4dB F=5M-1G IL=1dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0009H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2g3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-10-4%2B",
//     mfgPart: "ADC-10-4+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2G3D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2G3-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [5, 1000, -11.4, 1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0009_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0009",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-15-4+.pdf",
//     description: "Directional CPL=-15.8dB F=5M-1G IL=0.8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0009H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2g4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-15-4%2B",
//     mfgPart: "ADC-15-4+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2G4D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2G4-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [5, 1000, -15.8, 0.8, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0009_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0009",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-20-4+.pdf",
//     description: "Directional CPL=-20dB F=5M-1G IL=0.7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0009H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2g5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-20-4%2B",
//     mfgPart: "ADC-20-4+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2G5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2G5-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [5, 1000, -20, 0.7, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0009_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0009",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-20-12+.pdf",
//     description: "Directional CPL=-20dB F=100M-1.2G IL=0.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0009H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2g6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-20-12%2B",
//     mfgPart: "ADC-20-12+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2G6D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2G6-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [100, 1200, -20, 0.5, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0009_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0009",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-26-52+.pdf",
//     description: "Directional CPL=-26.5dB F=10M-500M IL=0.2dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0009H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2g7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-26-52%2B",
//     mfgPart: "ADC-26-52+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2G7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2G7-0404D",
//     price: 10800,
//     priceType: "cents",
//     props: [10, 500, -26.5, 0.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0009_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0010",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-6-1R+.pdf",
//     description: "Directional CPL=-6.2dB F=5M-200M IL=1.8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0010H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2g8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-6-1R%2B",
//     mfgPart: "ADC-6-1R+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2G8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2G8-0404D",
//     price: 10800,
//     priceType: "cents",
//     props: [5, 200, -6.2, 1.8, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0010_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0010",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-10-1R+.pdf",
//     description: "Directional CPL=-10.5dB F=5M-900M IL=1dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0010H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2g9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-10-1R%2B",
//     mfgPart: "ADC-10-1R+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2G9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2G9-0404D",
//     price: 10800,
//     priceType: "cents",
//     props: [5, 900, -10.5, 1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0010_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0010",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-6-13+.pdf",
//     description: "Directional CPL=-6.7dB F=200M-1.3G IL=2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0010H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2h1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-6-13%2B",
//     mfgPart: "ADC-6-13+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2H1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2H1-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [200, 1300, -6.7, 2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0010_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0010",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-10-4+.pdf",
//     description: "Directional CPL=-11.4dB F=5M-1G IL=1dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0010H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2h2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-10-4%2B",
//     mfgPart: "ADC-10-4+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2H2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2H2-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [5, 1000, -11.4, 1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0010_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0010",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-15-4+.pdf",
//     description: "Directional CPL=-15.8dB F=5M-1G IL=0.8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0010H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2h3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-15-4%2B",
//     mfgPart: "ADC-15-4+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2H3D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2H3-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [5, 1000, -15.8, 0.8, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0010_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0010",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-20-4+.pdf",
//     description: "Directional CPL=-20dB F=5M-1G IL=0.7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0010H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2h4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-20-4%2B",
//     mfgPart: "ADC-20-4+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2H4D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2H4-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [5, 1000, -20, 0.7, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0010_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0010",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-20-12+.pdf",
//     description: "Directional CPL=-20dB F=100M-1.2G IL=0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0010H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2h5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-20-12%2B",
//     mfgPart: "ADC-20-12+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2H5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2H5-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [100, 1200, -20, 0.5, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0010_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0010",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-26-52+.pdf",
//     description: "Directional CPL=-26.5dB F=10M-500M IL=0.2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0010H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2h6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-26-52%2B",
//     mfgPart: "ADC-26-52+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2H6D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2H6-0404D",
//     price: 10800,
//     priceType: "cents",
//     props: [10, 500, -26.5, 0.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0010_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0185",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06073-Datasheet.pdf.aspx",
//     description: "Directional CPL=11dB F=4G-8G IL=1dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0185I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2y1-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06073",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2Y1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2Y1-0404D",
//     price: 12500,
//     priceType: "cents",
//     props: [4000, 8000, 11, 1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0185_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0185",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06076_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=-22dB F=4G-8G IL=0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0185I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2y2-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06076",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2Y2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2Y2-0404D",
//     price: 12400,
//     priceType: "cents",
//     props: [4000, 8000, -22, 0.5, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0185_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0188",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06074_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=12dB F=8G-12G IL=1dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0188H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2y3-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06074",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2Y3D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2Y3-0404D",
//     price: 13600,
//     priceType: "cents",
//     props: [8000, 12000, 12, 1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0188_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0188",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06077_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=25dB F=5G-14G IL=0.6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0188H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2y4-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06077",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2Y4D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2Y4-0404D",
//     price: 14900,
//     priceType: "cents",
//     props: [5000, 14000, 25, 0.6, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0188_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0189",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06075_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=12dB F=10G-20G IL=1.2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0189I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2y5-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06075",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2Y5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2Y5-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: [10000, 20000, 12, 1.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0189_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0189",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06078_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=21dB F=8G-22G IL=1dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0189I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2y6-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06078",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2Y6D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2Y6-0404D",
//     price: 13600,
//     priceType: "cents",
//     props: [8000, 22000, 21, 1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0189_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0813",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EDC21-24+.pdf",
//     description: "Directional CPL=21dB F=4G-20G IL=0.7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0813B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7j8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EDC21-24%2B",
//     mfgPart: "EDC21-24+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7J8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7J8-0404D",
//     price: 13100,
//     priceType: "cents",
//     props: [4000, 20000, 21, 0.7, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0813_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0670",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC07181_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=-20dB F=20G-40G IL=0.3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0670A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7l7-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC07181",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7L7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7L7-0404D",
//     price: 11400,
//     priceType: "cents",
//     props: [20000, 40000, -20, 0.3, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0670_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0670",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC07182_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=-10dB F=20G-40G IL=0.3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0670A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7l8-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC07182",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7L8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7L8-0404D",
//     price: 11300,
//     priceType: "cents",
//     props: [20000, 40000, -10, 0.3, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0670_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1355",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC07180-Datasheet.pdf?lang=en-US",
//     description: "Directional CPL=-20dB F=2G-18G IL=1.5dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1355A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7x4-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC07180",
//     models: "this part is missing models",
//     pn: "XM-A7X4-0604D",
//     price: 16200,
//     priceType: "cents",
//     props: [2000, 18000, -20, 1.5, null, null, null, null, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C6",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1355_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0684",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06913_Datasheet-(1).pdf.aspx?lang=en-US",
//     description: "Asymmetric CPL=-20dB F=6G-18G IL=1dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0684B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7x5-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06913",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7X5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7X5-0404D",
//     price: 11300,
//     priceType: "cents",
//     props: [6000, 18000, -20, 1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0689",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06882_DC-to-25GHz_30dB_Data_Sheet_Ver-2.pdf.aspx?lang=en-US",
//     description: "Directional CPL=25dB F=DC-25G IL=2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0689A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7x6-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06882",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7X6D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7X6-0404D",
//     price: 12500,
//     priceType: "cents",
//     props: [0, 25000, 25, 2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0689_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0689",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06881_DC-to-25GHz_20dB_Data_Sheet_Ver-2.pdf.aspx?lang=en-US",
//     description: "Directional CPL=20dB F=DC-25G IL=3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0689A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7x7-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06881",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7X7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7X7-0404D",
//     price: 11100,
//     priceType: "cents",
//     props: [0, 25000, 20, 3, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0689_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0685",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06719_Datasheet-(1).pdf.aspx?lang=en-US",
//     description: "Directional CPL=-10dB F=6G-18G IL=1dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0685A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7x8-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06719",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7X8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7X8-0404D",
//     price: 14400,
//     priceType: "cents",
//     props: [6000, 18000, -10, 1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0685_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0688",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06701_Datasheet.pdf.aspx?lang=en-US",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a7x9-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06701",
//     models: "this is the new part",
//     pn: "XM-A7X9-0404D",
//     price: 13800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0687",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06700_Datasheet.pdf.aspx?lang=en-US",
//     description: "Branchline CPL=-3dB F=5.9G-6.5G IL=3dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0687A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7y1-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06700",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7Y1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7Y1-0604D",
//     price: 17600,
//     priceType: "cents",
//     props: [5900, 6500, -3, 3, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C6",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1023",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/D17W+.pdf",
//     description: "Directional CPL=26dB F=700M-3.5G IL=0.4dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1023A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2e7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=D17W%2B",
//     mfgPart: "D17W+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2E7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2E7-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [700, 3500, 26, 0.4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1023_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1067",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/C0409J5003AHF/C0409J5003AHF_DataSheet(Rev_A).pdf",
//     description: "Hybrid CPL=3dB F=400M-900M IL=0.8dB ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1067A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2k5-0409d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "C0409J5003AHF",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2K5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2K5-0409D",
//     price: 14800,
//     priceType: "cents",
//     props: [400, 900, 3, 0.8, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1067_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1137",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://innovativepp.com/wp-content/drawings/ipp-7118.pdf",
//     description: "Hybrid CPL=3dB F=690M-6G IL=0.8dB ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1137B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5h4-0609d/",
//     mfgCode: "INNPP",
//     mfgLandingPage: "https://innovativepp.com/product/ipp-7118/",
//     mfgPart: "IPP-7118RT",
//     models: "this part is missing models",
//     pn: "XM-B5H4-0609D",
//     price: 33900,
//     priceType: "cents",
//     props: [690, 6000, 3, 0.8, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1137_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1067",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/C0727J5003AHF/C0727J5003AHF_DataSheet(Rev_B).pdf",
//     description: "Hybrid CPL=3dB F=700M-2.7G IL=0.8dB ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1067A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5l9-0409d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "C0727J5003AHF",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5L9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5L9-0409D",
//     price: 14800,
//     priceType: "cents",
//     props: [700, 2700, 3, 0.8, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1067_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1112",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/DCW-22-332+.pdf",
//     description: "Directional CPL=22dB F=1.2G-33G IL=0.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1112A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5n1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=DCW-22-332%2B",
//     mfgPart: "DCW-22-332+",
//     models: "this part is missing models",
//     pn: "XM-B5N1-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: [1200, 33000, 22, 0.4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0010",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADC-20-132+.pdf",
//     description: "Directional CPL=20dB F=100M-1.3G IL=0.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0010H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5n2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADC-20-132%2B",
//     mfgPart: "ADC-20-132+",
//     models: "this part is missing models",
//     pn: "XM-B5N2-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [100, 1300, 20, 0.4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0010_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1736",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06302_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=-22dB F=8G-12G IL=0.3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1736A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b611-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06302",
//     models: "this part is missing models",
//     pn: "XM-B611-0404D",
//     price: 15100,
//     priceType: "cents",
//     props: [8000, 12000, -22, 0.3, null, null, null, null, 15],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1736_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0188",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06302_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=-22dB F=8G-12G IL=0.3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0188H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6l7-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06302",
//     models: "this part is missing models",
//     pn: "XM-B6L7-0404D",
//     price: 15100,
//     priceType: "cents",
//     props: [8000, 12000, -22, 0.3, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0188_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCBD-10-63HP+.pdf",
//     description: "Bi-Directional CPL=16.2dB F=50M-6G IL=0.45dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7a4-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCBD-10-63HP%2B",
//     mfgPart: "SCBD-10-63HP+",
//     models: "this part is missing models",
//     pn: "XM-B7A4-0904D",
//     price: 19000,
//     priceType: "cents",
//     props: [50, 6000, 16.2, 0.45, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCBD-16-562HP+.pdf",
//     description: "Bi-Directional CPL=16.2dB F=2.7G-5.6G IL=0.4dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7a5-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCBD-16-562HP%2B",
//     mfgPart: "SCBD-16-562HP+",
//     models: "this part is missing models",
//     pn: "XM-B7A5-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [2700, 5600, 16.2, 0.4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCBD-16-63HP+.pdf",
//     description: "Bi-Directional CPL=16.2dB F=50M-6G IL=0.45dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7a6-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCBD-16-63HP%2B",
//     mfgPart: "SCBD-16-63HP+",
//     models: "this part is missing models",
//     pn: "XM-B7A6-0904D",
//     price: 19000,
//     priceType: "cents",
//     props: [50, 6000, 16.2, 0.45, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCBD-20-272HP+.pdf",
//     description: "Bi-Directional CPL=18.6dB F=1.75G-2.75G IL=0.25dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7a7-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCBD-20-272HP%2B",
//     mfgPart: "SCBD-20-272HP+",
//     models: "this part is missing models",
//     pn: "XM-B7A7-0904D",
//     price: 19000,
//     priceType: "cents",
//     props: [1750, 2750, 18.6, 0.25, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCBD-25-122HP+.pdf",
//     description: "Bi-Directional CPL=25dB F=800M-1.22G IL=0.1dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7a8-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCBD-25-122HP%2B",
//     mfgPart: "SCBD-25-122HP+",
//     models: "this part is missing models",
//     pn: "XM-B7A8-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [800, 1220, 25, 0.1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCBD-28-82HP+.pdf",
//     description: "Bi-Directional CPL=28dB F=600M-820M IL=0.1dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7a9-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCBD-28-82HP%2B",
//     mfgPart: "SCBD-28-82HP+",
//     models: "this part is missing models",
//     pn: "XM-B7A9-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [600, 820, 28, 0.1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCBD-30-62HP+.pdf",
//     description: "Bi-Directional CPL=31dB F=400M-620M IL=0.8dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7b1-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCBD-30-62HP%2B",
//     mfgPart: "SCBD-30-62HP+",
//     models: "this part is missing models",
//     pn: "XM-B7B1-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [400, 620, 31, 0.8, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-10-63HP+.pdf",
//     description: "Bi-Directional CPL=11.8dB F=2.7G-6G IL=0.6dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7b2-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-10-63HP%2B",
//     mfgPart: "SYBD-10-63HP+",
//     models: "this part is missing models",
//     pn: "XM-B7B2-0904D",
//     price: 24600,
//     priceType: "cents",
//     props: [2700, 6000, 11.8, 0.6, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-13-63HP+.pdf",
//     description: "Bi-Directional CPL=13.3dB F=2.7G-6G IL=0.4dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7b3-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-13-63HP%2B",
//     mfgPart: "SYBD-13-63HP+",
//     models: "this part is missing models",
//     pn: "XM-B7B3-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [2700, 6000, 13.3, 0.4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-14-272HP+.pdf",
//     description: "Bi-Directional CPL=14.5dB F=1.75G-2.7G IL=0.4dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7b4-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-14-272HP%2B",
//     mfgPart: "SYBD-14-272HP+",
//     models: "this part is missing models",
//     pn: "XM-B7B4-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [1750, 2700, 14.5, 0.4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-16-172HP+.pdf",
//     description: "Bi-Directional CPL=17dB F=1.4G-1.75G IL=0.22dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7b5-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-16-172HP%2B",
//     mfgPart: "SYBD-16-172HP+",
//     models: "this part is missing models",
//     pn: "XM-B7B5-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [1400, 1750, 17, 0.22, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-16-272HP+.pdf",
//     description: "Bi-Directional CPL=14.5dB F=1.75G-2.7G IL=0.4dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7b6-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-16-272HP%2B",
//     mfgPart: "SYBD-16-272HP+",
//     models: "this part is missing models",
//     pn: "XM-B7B6-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [1750, 2700, 14.5, 0.4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-16-53HP+.pdf",
//     description: "Bi-Directional CPL=16.3dB F=2.7G-5G IL=0.2dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7b7-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-16-53HP%2B",
//     mfgPart: "SYBD-16-53HP+",
//     models: "this part is missing models",
//     pn: "XM-B7B7-0904D",
//     price: 20600,
//     priceType: "cents",
//     props: [2700, 5000, 16.3, 0.2, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-18-142HP+.pdf",
//     description: "Bi-Directional CPL=19.5dB F=960M-1.4G IL=0.15dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7b8-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-18-142HP%2B",
//     mfgPart: "SYBD-18-142HP+",
//     models: "this part is missing models",
//     pn: "XM-B7B8-0904D",
//     price: 20600,
//     priceType: "cents",
//     props: [960, 1400, 19.5, 0.15, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-18-172HP+.pdf",
//     description: "Bi-Directional CPL=18dB F=1.4G-1.75G IL=0.1dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7b9-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-18-172HP%2B",
//     mfgPart: "SYBD-18-172HP+",
//     models: "this part is missing models",
//     pn: "XM-B7B9-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [1400, 1750, 18, 0.1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-20-13HP+.pdf",
//     description: "Bi-Directional CPL=10.8dB F=800M-1G IL=0.12dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7c1-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-20-13HP%2B",
//     mfgPart: "SYBD-20-13HP+",
//     models: "this part is missing models",
//     pn: "XM-B7C1-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [800, 1000, 10.8, 0.12, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-20-142HP+.pdf",
//     description: "Bi-Directional CPL=21dB F=960M-1.4G IL=0.8dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7c2-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-20-142HP%2B",
//     mfgPart: "SYBD-20-142HP+",
//     models: "this part is missing models",
//     pn: "XM-B7C2-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [960, 1400, 21, 0.8, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-20-272HP+.pdf",
//     description: "Bi-Directional CPL=19.8dB F=1.75G-2.7G IL=0.12dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7c3-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-20-272HP%2B",
//     mfgPart: "SYBD-20-272HP+",
//     models: "this part is missing models",
//     pn: "XM-B7C3-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [1750, 2700, 19.8, 0.12, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-22-172HP+.pdf",
//     description: "Bi-Directional CPL=22.3dB F=1.4G-1.75G IL=0.09dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7c4-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-22-172HP%2B",
//     mfgPart: "SYBD-22-172HP+",
//     models: "this part is missing models",
//     pn: "XM-B7C4-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [1400, 1750, 22.3, 0.09, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-22-82HP+.pdf",
//     description: "Bi-Directional CPL=22.8dB F=610M-810M IL=0.1dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7c5-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-22-82HP%2B",
//     mfgPart: "SYBD-22-82HP+",
//     models: "this part is missing models",
//     pn: "XM-B7C5-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [610, 810, 22.8, 0.1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-23-13HP+.pdf",
//     description: "Bi-Directional CPL=23.5dB F=800M-1G IL=0.06dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7c6-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-23-13HP%2B",
//     mfgPart: "SYBD-23-13HP+",
//     models: "this part is missing models",
//     pn: "XM-B7C6-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [800, 1000, 23.5, 0.06, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-24-142HP+.pdf",
//     description: "Bi-Directional CPL=22.5dB F=960M-1.4G IL=0.04dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7c7-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-24-142HP%2B",
//     mfgPart: "SYBD-24-142HP+",
//     models: "this part is missing models",
//     pn: "XM-B7C7-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [960, 1400, 22.5, 0.04, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-25-82HP+.pdf",
//     description: "Bi-Directional CPL=28.1dB F=610M-810M IL=0.02dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7c8-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-25-82HP%2B",
//     mfgPart: "SYBD-25-82HP+",
//     models: "this part is missing models",
//     pn: "XM-B7C8-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [610, 810, 28.1, 0.02, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-26-13HP+.pdf",
//     description: "Bi-Directional CPL=26.8dB F=800M-1G IL=0.03dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7c9-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-26-13HP%2B",
//     mfgPart: "SYBD-26-13HP+",
//     models: "this part is missing models",
//     pn: "XM-B7C9-0904D",
//     price: 20600,
//     priceType: "cents",
//     props: [800, 1000, 26.8, 0.03, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-26-62HP+.pdf",
//     description: "Bi-Directional CPL=27.2dB F=400M-610M IL=0.08dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7d1-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-26-62HP%2B",
//     mfgPart: "SYBD-26-62HP+",
//     models: "this part is missing models",
//     pn: "XM-B7D1-0904D",
//     price: 20600,
//     priceType: "cents",
//     props: [400, 610, 27.2, 0.08, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-28-62HP+.pdf",
//     description: "Bi-Directional CPL=28.8dB F=400M-610M IL=0.03dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7d2-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-28-62HP%2B",
//     mfgPart: "SYBD-28-62HP+",
//     models: "this part is missing models",
//     pn: "XM-B7D2-0904D",
//     price: 20600,
//     priceType: "cents",
//     props: [400, 610, 28.8, 0.03, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-28-82HP+.pdf",
//     description: "Bi-Directional CPL=28.1dB F=610M-810M IL=0.02dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7d3-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-28-82HP%2B",
//     mfgPart: "SYBD-28-82HP+",
//     models: "this part is missing models",
//     pn: "XM-B7D3-0904D",
//     price: 0,
//     priceType: "cents",
//     props: [610, 810, 28.1, 0.02, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0669",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SYBD-30-62HP+.pdf",
//     description: "Bi-Directional CPL=32.5dB F=400M-610M IL=0.02dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0669A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7d4-0904d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYBD-30-62HP%2B",
//     mfgPart: "SYBD-30-62HP+",
//     models: "this part is missing models",
//     pn: "XM-B7D4-0904D",
//     price: 20600,
//     priceType: "cents",
//     props: [400, 610, 32.5, 0.02, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0669_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1398",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC07802_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=30dB F=DC-40G IL=3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1398A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7e1-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC07802",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B7E1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B7E1-0404D",
//     price: 12300,
//     priceType: "cents",
//     props: [0, 40000, 30, 3, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1398_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1398",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC07803_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=20dB F=DC-40G IL=3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1398A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7e2-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC07803",
//     models: "this part is missing models",
//     pn: "XM-B7E2-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: [0, 40000, 20, 3, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1398_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0727",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://innovativepp.com/wp-content/drawings/ipp-7046.pdf",
//     description: "Hybrid CPL=3dB F=500M-1G IL=0.25dB ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0727A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7f6-0609d/",
//     mfgCode: "INNPP",
//     mfgLandingPage: "https://innovativepp.com/product/ipp-7046/",
//     mfgPart: "IPP-7046",
//     models: "this part is missing models",
//     pn: "XM-B7F6-0609D",
//     price: 0,
//     priceType: "cents",
//     props: [500, 1000, 3, 0.25, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0727_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0952",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://innovativepp.com/wp-content/drawings/ipp-7105it.pdf",
//     description: "Hybrid CPL=3dB F=1G-2.5G IL=0.5dB ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0952B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7h5-0409d/",
//     mfgCode: "INNPP",
//     mfgLandingPage: "https://innovativepp.com/product/ipp-7105it/",
//     mfgPart: "IPP-7151",
//     models: "this part is missing models",
//     pn: "XM-B7H5-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [1000, 2500, 3, 0.5, 14, null, 0.9, 905, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0989",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SEDC-10-63+.pdf",
//     description: "Directional CPL=11.8dB F=2.7G-6G IL=0.6dB ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0989A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7j3-1212d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SEDC-10-63%2B",
//     mfgPart: "SEDC-10-63+",
//     models: "this part is missing models",
//     pn: "XM-B7J3-1212D",
//     price: 46600,
//     priceType: "cents",
//     props: [2700, 6000, 11.8, 0.6, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0989_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1736",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06074_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=12dB F=8G-12G IL=1dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1736A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9y3-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06074",
//     models: "this part is missing models",
//     pn: "XM-B9Y3-0404D",
//     price: 13600,
//     priceType: "cents",
//     props: [8000, 12000, 12, 1, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1736_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1736",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Couplers/FPC06077_Datasheet.pdf.aspx?lang=en-US",
//     description: "Directional CPL=25dB F=5G-14G IL=0.6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1736A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9y4-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06077",
//     models: "this part is missing models",
//     pn: "XM-B9Y4-0404D",
//     price: 14900,
//     priceType: "cents",
//     props: [5000, 14000, 25, 0.6, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1736_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/1P510S/1P510S_DataSheet(Rev_B).pdf",
//     description: "Directional CPL=10dB F=1.7G-2G IL=0.25dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3h6-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "1P510S",
//     models: "this part is missing models",
//     pn: "XM-C3H6-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [1700, 2000, 10, 0.25, null, null, null, null, 20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/1P520S/1P520S_DataSheet(Rev_A).pdf",
//     description: "Directional F=1.7G-2G ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3h7-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "1P520S",
//     models: "this part is missing models",
//     pn: "XM-C3H7-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [1700, 2000, 0, 0, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/1P610S/1P610S_DataSheet(Rev_D).pdf",
//     description: "Directional F=2.3G-2.7G ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3h8-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "1P610S",
//     models: "this part is missing models",
//     pn: "XM-C3H8-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [2300, 2700, 0, 0, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/1P620S/1P620S_DataSheet(Rev_C).pdf",
//     description: "Directional F=2.3G-2.7G ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3h9-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "1P620S",
//     models: "this part is missing models",
//     pn: "XM-C3H9-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [2300, 2700, 0, 0, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/JP506S/JP506S_DataSheet(Rev_A).pdf",
//     description: "Directional F=2G-2.3G ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3j1-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "JP506S",
//     models: "this part is missing models",
//     pn: "XM-C3J1-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [2000, 2300, 0, 0, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/JP510S/JP510S_DataSheet(Rev_A).PDF",
//     description: "Directional F=2G-2.3G ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3j2-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "JP510S",
//     models: "this part is missing models",
//     pn: "XM-C3J2-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [2000, 2300, 0, 0, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/JP520S/JP520S_DataSheet(Rev_A).pdf",
//     description: "Directional F=2G-2.3G ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3j3-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "JP520S",
//     models: "this part is missing models",
//     pn: "XM-C3J3-0404D",
//     price: 9800,
//     priceType: "cents",
//     props: [2000, 2300, 0, 0, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C07P1-04S/X3C07P1-04S_DataSheet(Rev_A).pdf",
//     description: "Directional CPL=4dB F=600M-900M IL=0.22dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3j4-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C07P1-04S",
//     models: "this part is missing models",
//     pn: "XM-C3J4-0404D",
//     price: 10400,
//     priceType: "cents",
//     props: [600, 900, 4, 0.22, null, null, null, 904, 23],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C09P1-04S/X3C09P1-04S_DataSheet(Rev_B).pdf",
//     description: "Directional CPL=4dB F=800M-1G IL=0.2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3j5-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C09P1-04S",
//     models: "this part is missing models",
//     pn: "XM-C3J5-0404D",
//     price: 10400,
//     priceType: "cents",
//     props: [800, 1000, 4, 0.2, null, null, null, 904, 23],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C19P1-04S/X3C19P1-04S_DataSheet(RevD).pdf",
//     description: "Directional CPL=4dB F=1.5G-2G IL=0.15dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3j6-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C19P1-04S",
//     models: "this part is missing models",
//     pn: "XM-C3J6-0404D",
//     price: 10400,
//     priceType: "cents",
//     props: [1500, 2000, 4, 0.15, null, null, null, 904, 20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C21P1-04S/X3C21P1-04S_DataSheet(Rev_A).pdf",
//     description: "Directional CPL=4dB F=2G-2.3G IL=0.15dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3j7-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C21P1-04S",
//     models: "this part is missing models",
//     pn: "XM-C3J7-0404D",
//     price: 10400,
//     priceType: "cents",
//     props: [2000, 2300, 4, 0.15, null, null, null, null, 20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C25P1-02S/X3C25P1-02S_Datasheet(Rev_C).pdf",
//     description: "Directional CPL=1.9dB F=2.3G-2.7G IL=0.18dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3j8-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C25P1-02S",
//     models: "this part is missing models",
//     pn: "XM-C3J8-0404D",
//     price: 10400,
//     priceType: "cents",
//     props: [2300, 2700, 1.9, 0.18, null, null, null, 904, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/X3C25P1-04S/X3C25P1-04S_DataSheet(Rev_A).pdf",
//     description: "Directional CPL=4dB F=2.3G-2.7G IL=0.18dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3j9-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "X3C25P1-04S",
//     models: "this part is missing models",
//     pn: "XM-C3J9-0404D",
//     price: 10400,
//     priceType: "cents",
//     props: [2300, 2700, 4, 0.18, null, null, null, null, 20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/XC0900P-10S/XC0900P-10S_DataSheet(Rev_D).pdf",
//     description: "Directional CPL=10dB F=800M-1G IL=0.38dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3k1-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "XC0900P-10S",
//     models: "this part is missing models",
//     pn: "XM-C3K1-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [800, 1000, 10, 0.38, null, null, null, null, 14],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/XC2500P-20S/XC2500P-20S_DataSheet(Rev_A).pdf",
//     description: "Directional CPL=20dB F=2.3G-2.7G IL=0.2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3k2-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "XC2500P-20S",
//     models: "this part is missing models",
//     pn: "XM-C3K2-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [2300, 2700, 20, 0.2, null, null, null, null, 20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/XC3500P-20S/XC3500P-20S_DataSheet(Rev_A).pdf",
//     description: "Directional CPL=20dB F=3.3G-3.8G IL=0.2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3k3-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "XC3500P-20S",
//     models: "this part is missing models",
//     pn: "XM-C3K3-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [3300, 3800, 20, 0.2, null, null, null, null, 20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/DirectionalCouplers/XEC24P3-30G/XEC24P3-30G_Datasheet(Rev_C).pdf",
//     description: "Directional CPL=30dB F=2.4G-2.5G IL=0.1dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3k4-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage: "https://www.ttm.com/en/solutions/rfs-components",
//     mfgPart: "XEC24P3-30G",
//     models: "this part is missing models",
//     pn: "XM-C3K4-0404D",
//     price: 10400,
//     priceType: "cents",
//     props: [2400, 2500, 30, 0.1, null, null, null, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/1P503AS/1P503AS_DataSheet(Rev_A).pdf",
//     description: "Hybrid CPL=3dB F=1.7G-2G IL=0.25dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3k5-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "1P503AS",
//     models: "this part is missing models",
//     pn: "XM-C3K5-0404D",
//     price: 9900,
//     priceType: "cents",
//     props: [1700, 2000, 3, 0.25, 18, null, 0.45, 3, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/1P603AS/1P603AS_DataSheet(Rev_A).pdf",
//     description: "Hybrid CPL=3dB F=2.3G-2.7G IL=0.3dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3k6-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "1P603AS",
//     models: "this part is missing models",
//     pn: "XM-C3K6-0404D",
//     price: 9900,
//     priceType: "cents",
//     props: [2300, 2700, 3, 0.3, 20, null, 0.25, 3, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/JP503AS/JP503AS_DataSheet(Rev_A).pdf",
//     description: "Hybrid CPL=3dB F=2G-2.3G IL=0.3dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3k7-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "JP503AS",
//     models: "this part is missing models",
//     pn: "XM-C3K7-0404D",
//     price: 9900,
//     priceType: "cents",
//     props: [2000, 2300, 3, 0.3, 20, null, 0.25, 3, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/X3C07P1-03S/X3C07P1-03S_DataSheet(Rev_G).pdf",
//     description: "Hybrid CPL=3dB F=800M-1G IL=0.2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3k8-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "X3C07P1-03S",
//     models: "this part is missing models",
//     pn: "XM-C3K8-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [800, 1000, 3, 0.2, 23, null, 0.22, 904, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/X3C09P1-03S/X3C09P1-03S_DataSheet(Rev_E).pdf",
//     description: "Hybrid CPL=3dB F=800M-1G IL=0.22dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3k9-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "X3C09P1-03S",
//     models: "this part is missing models",
//     pn: "XM-C3K9-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [800, 1000, 3, 0.22, 23, null, 0.2, 904, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/X3C09P2-03S/X3C09P2-03S_DataSheet(Rev_F).pdf",
//     description: "Hybrid CPL=3dB F=800M-1G IL=0.2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3l1-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "X3C09P2-03S",
//     models: "this part is missing models",
//     pn: "XM-C3L1-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [800, 1000, 3, 0.2, 23, null, 0.22, 904, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/X3C14P1-03S/X3C14P1-03S_Datasheet(Rev_G).pdf",
//     description: "Hybrid CPL=3dB F=1.2G-1.7G IL=0.2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3l2-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "X3C14P1-03S",
//     models: "this part is missing models",
//     pn: "XM-C3L2-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [1200, 1700, 3, 0.2, 23, null, 0.35, 904, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/X3C19P1-03S/X3C19P1-03S_DataSheet(Rev_E).pdf",
//     description: "Hybrid CPL=3dB F=1.4G-1.5G IL=0.15dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3l3-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "X3C19P1-03S",
//     models: "this part is missing models",
//     pn: "XM-C3L3-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [1400, 1500, 3, 0.15, 20, null, 0.5, 904, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/X3C19P2-03S/X3C19P2-03S_DataSheet(Rev_E).pdf",
//     description: "Hybrid CPL=3dB F=1.7G-2G IL=0.22dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3l4-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "X3C19P2-03S",
//     models: "this part is missing models",
//     pn: "XM-C3L4-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [1700, 2000, 3, 0.22, 23, null, 0.22, 904, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/X3C21P1-03S/X3C21P1-03_Datasheet(Rev_D).pdf",
//     description: "Hybrid CPL=3dB F=2G-2.3G IL=0.22dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3l5-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "X3C21P1-03S",
//     models: "this part is missing models",
//     pn: "XM-C3L5-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [2000, 2300, 3, 0.22, 23, null, 0.22, 904, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/X3C21P2-03S/X3C21P2-03S_DataSheet(Rev_D).pdf",
//     description: "Hybrid CPL=3dB F=1.8G-2.3G IL=0.22dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3l6-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "X3C21P2-03S",
//     models: "this part is missing models",
//     pn: "XM-C3L6-0404D",
//     price: 10200,
//     priceType: "cents",
//     props: [1800, 2300, 3, 0.22, 23, null, 0.22, 904, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/X3C26P1-03S/X3C26P1-03_DataSheet(Rev_F).pdf",
//     description: "Hybrid CPL=3dB F=2.3G-2.91G IL=0.2dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3l7-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "X3C26P1-03S",
//     models: "this part is missing models",
//     pn: "XM-C3L7-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [2300, 2910, 3, 0.2, 20, null, 0.4, 904, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/XC0900P-03AS/XC0900P-03AS_DataSheet(Rev_D).pdf",
//     description: "Hybrid CPL=3dB F=800M-1G IL=0.25dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3l8-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "XC0900P-03AS",
//     models: "this part is missing models",
//     pn: "XM-C3L8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [800, 1000, 3, 0.25, 23, null, 0.3, 904, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/XC1400P-03S/XC1400P-03S_DataSheet(Rev_C).pdf",
//     description: "Hybrid CPL=3dB F=1.2G-2.6G IL=0.32dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3l9-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "XC1400P-03S",
//     models: "this part is missing models",
//     pn: "XM-C3L9-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: [1200, 2600, 3, 0.32, 23, null, 0.3, 904, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/XC2650P-03S/XC2650P-03S_DataSheet(Rev_A).pdf",
//     description: "Hybrid F=2.65G-2.8G ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3m1-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "XC2650P-03S",
//     models: "this part is missing models",
//     pn: "XM-C3M1-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: [2650, 2800, 0, 0, 20, null, 0, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0072",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/XC3500P-03S/XC3500P-03S_DataSheet(Rev_C).pdf",
//     description: "Hybrid CPL=3dBdB F=3.3G-3.85G IL=0.25dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0072H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3m2-0404d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "XC3500P-03S",
//     models: "this part is missing models",
//     pn: "XM-C3M2-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: [3300, 3850, 3, 0.25, 21, null, 0.25, 903, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0072_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1490",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Resistive F=DC-27G IL=4dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1490A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c447-0404d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://catalog.quanticxmw.com/request/x-mwblocks/couplers/xm-c447-0404d",
//     mfgPart: "Resistive Coupler",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C447D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C447-0404D",
//     price: 9500,
//     priceType: "cents",
//     props: [0, 27000, null, 4, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1615",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/C0409J5003AHF/C0409J5003AHF_DataSheet(Rev_A).pdf",
//     description: "Hybrid CPL=3dB F=400M-900M IL=0.8dB ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1615A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6l9-0409d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "C0409J5003AHF",
//     models: "this part is missing models",
//     pn: "XM-C6L9-0409D",
//     price: 14800,
//     priceType: "cents",
//     props: [400, 900, 3, 0.8, 0, null, 0, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1615_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0813",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EDC21-24+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0813B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7b4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EDC21-24%2B",
//     mfgPart: "EDC21-24+",
//     models: "this part is missing models",
//     pn: "XM-C7B4-0404D",
//     price: 13100,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "0813_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1456",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EDC10-273+.pdf",
//     description: "Wideband CPL=10dB F=6G-26.5G IL=1.5dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1456A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8l6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EDC10-273%2B",
//     mfgPart: "EDC10-273+",
//     models: "this part is missing models",
//     pn: "XM-C8L6-0404D",
//     price: 13400,
//     priceType: "cents",
//     props: [6000, 26500, 10, 1.5, null, null, null, null, 15],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1456_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1928",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://cdn.anaren.com/product-documents/Xinger/90DegreeHybridCouplers/C0727J5003AHF/C0727J5003AHF_DataSheet(Rev_B).pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9r4-0409d/",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "C0727J5003AHF",
//     models: "this is the new part",
//     pn: "XM-C9R4-0409D",
//     price: 15900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1928_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC07234",
//     models: "this is the new part",
//     pn: "XM-C7V2-0604D",
//     price: 15900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1355_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "X4C45J1-03G",
//     models: "this is the new part",
//     pn: "XM-A471-0409D",
//     price: 15900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1992_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "X3C26P1-03S",
//     models: "this is the new part",
//     pn: "XM-D393-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/index.php/en/solutions/rfs-components/xinger-components/90-degree-hybrid-couplers",
//     mfgPart: "XC3500P-03S",
//     models: "this is the new part",
//     pn: "XM-D394-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/en/solutions/rfs-components/xinger-components/directional-couplers",
//     mfgPart: "X4C30J1-20G",
//     models: "this is the new part",
//     pn: "XM-D4J7-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1992_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Couplers.aspx",
//     mfgPart: "FPC06076",
//     models: "this is the new part",
//     pn: "XM-D3L2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "1355_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "CPL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ANREN",
//     mfgLandingPage:
//       "https://www.ttm.com/en/solutions/rfs-components/xinger-components/directional-couplers",
//     mfgPart: "DC2327J5005AHF",
//     models: "this is the new part",
//     pn: "XM-D4J8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Couplers",
//     newBRDImage: "2586_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0133",
//     catId: "DTC",
//     ctlBoard: "XM-B1E9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/evaluation-documentation/AD8319.pdf#",
//     description: "F=1M-10G Pin=-44 to -4dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0133I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2u5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-power-detectors/log-detectors/ad8319.html",
//     mfgPart: "AD8319ACPZSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2U5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2U5-0404D",
//     price: 14700,
//     priceType: "cents",
//     props: [-44, -4, 1, 10000, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X3",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "0133_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1423",
//     catId: "DTC",
//     ctlBoard: "XM-A413-0404D",
//     datasheetURL: "https://www.mouser.com/datasheet/2/609/hmc602-1504081.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a411-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.mouser.com/ProductDetail/Analog-Devices/HMC602LP4E?qs=3izLlwrMQ7lH633xibLyvA%3D%3D",
//     mfgPart: "HMC602LP4ESMT",
//     models: "this is the new part",
//     pn: "XM-A411-0404D",
//     price: 21400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "1423_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "2015",
//     catId: "DTC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.guerrilla-rf.com/prodFiles/1201/GRF1201DS.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a593-0404d/",
//     mfgCode: "GURLA",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "GRF1201SMT",
//     models: "this is the new part",
//     pn: "XM-A593-0404D",
//     price: 12500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "2015_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0133",
//     catId: "DTC",
//     ctlBoard: "XM-B1E9-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/AD8317.pdf",
//     description: "F=1M-10G Pin=-54 to -4dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0133I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5w4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-power-detectors/log-detectors/ad8317.html",
//     mfgPart: "AD8317ACPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5W4-0404D",
//     price: 15400,
//     priceType: "cents",
//     props: [-54, -4, 1, 10000, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X3",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "0133_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0625",
//     catId: "DTC",
//     ctlBoard: "XM-B1E9-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/AD8318.pdf",
//     description: "F=1M-8G Pin=-58 to -1dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0625B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5w5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-power-detectors/log-detectors/ad8318.html",
//     mfgPart: "AD8318ACPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5W5-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [-58, -1, 1, 8000, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X3",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "0625_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0885",
//     catId: "DTC",
//     ctlBoard: "XM-B1E9-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADL6010.pdf",
//     description: "F=500M-43.5G Pin=-30 to 15dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0885B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a635-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/rf-power-detectors/envelope-peak-detectors/adl6010.html",
//     mfgPart: "ADL6010ACPZNSMT",
//     models: "this part is missing models",
//     pn: "XM-A635-0404D",
//     price: 34300,
//     priceType: "cents",
//     props: [-30, 15, 500, 43500, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X3",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "0885_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0683",
//     catId: "DTC",
//     ctlBoard: "XM-B1B6-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc948.pdf",
//     description: "F=1G-23G Pin=-47 to 15dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0683A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6e6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-power-detectors/log-detectors/hmc948.html",
//     mfgPart: "HMC948LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A6E6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [-47, 15, 1000, 23000, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X3",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "0683_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0683",
//     catId: "DTC",
//     ctlBoard: "XM-B1B6-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc948.pdf",
//     description: "F=1G-23G Pin=-40 to 0dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0683A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a797-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-power-detectors/log-detectors/hmc948.html",
//     mfgPart: "HMC948LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A797-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [-40, 0, 1000, 23000, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X3",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "0683_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1126",
//     catId: "DTC",
//     ctlBoard: "XM-B5M2-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/5596f.pdf",
//     description: "F=100M-40G Pin=-39 to 4.2dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1126A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5m1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-power-detectors/rms-responding-power-detectors/ltc5596.html",
//     mfgPart: "LTC5596SMT",
//     models: "this part is missing models",
//     pn: "XM-B5M1-0404D",
//     price: 23100,
//     priceType: "cents",
//     props: [-39, 4.2, 100, 40000, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X3",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "1126_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0959",
//     catId: "DTC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b7h8-0204d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://www.keysight.com/us/en/products/mmic-millimeter-wave-microwave-devices/millimeter-wave-microwave-gaas-diodes.html",
//     mfgPart: "TC621PSMT",
//     models: "this is the new part",
//     pn: "XM-B7H8-0204D",
//     price: 30700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "0959_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1197",
//     catId: "DTC",
//     ctlBoard: "XM-C7R4-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MACP-010573.pdf",
//     description: "F=10G-30G Pin=-18 to 20dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1197B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7r4-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MACP-010573",
//     mfgPart: "MACP-010573SMT",
//     models: "this part is missing models",
//     pn: "XM-B7R4-0404D",
//     price: 13400,
//     priceType: "cents",
//     props: [-18, 20, 10000, 30000, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X3",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1281",
//     catId: "DTC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADL5902.pdf",
//     description: "F=50M-9G Pin=-60 to 1dBm ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1281A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b822-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl5902.html",
//     mfgPart: "ADL5902ACPZSMT",
//     models: "this part is missing models",
//     pn: "XM-B822-0604D",
//     price: 22100,
//     priceType: "cents",
//     props: [-60, 1, 50, 9000, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X6",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1370",
//     catId: "DTC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MACP-010572.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b939-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MACP-010572",
//     mfgPart: "MACP-010572SMT",
//     models: "this is the new part",
//     pn: "XM-B939-0404D",
//     price: 12800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "1370_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1126",
//     catId: "DTC",
//     ctlBoard: "XM-B5M2-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/5596f.pdf",
//     description: "F=100M-40G Pin=-39 to 4.2dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1126A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9h3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/amplifiers/rf-power-detectors/rms-responding-power-detectors/ltc5596.html",
//     mfgPart: "LTC5596SMT",
//     models: "this part is missing models",
//     pn: "XM-B9H3-0404D",
//     price: 23100,
//     priceType: "cents",
//     props: [-39, 4.2, 100, 40000, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X3",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "1126_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1423",
//     catId: "DTC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc611.pdf",
//     description: "F=1M-10G Pin=-50 to 0dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1423A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c361-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc611lp4.html",
//     mfgPart: "HMC611LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-C361-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [-50, 0, 1, 10000, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X3",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "1423_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1518",
//     catId: "DTC",
//     ctlBoard: "XM-C557-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADL6012.pdf",
//     description: "F=2G-43.5G Pin=-15 to 15dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1518A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c455-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl6012.html",
//     mfgPart: "ADL6012SMT",
//     models: "this part is missing models",
//     pn: "XM-C455-0404D",
//     price: 27900,
//     priceType: "cents",
//     props: [-15, 15, 2000, 43500, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X3",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "1518_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1370",
//     catId: "DTC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MACP-010571.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c584-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MACP-010571",
//     mfgPart: "MACP-010571SMT",
//     models: "this is the new part",
//     pn: "XM-C584-0404D",
//     price: 12700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "1370_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1516",
//     catId: "DTC",
//     ctlBoard: "XM-C6A3-0407D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADL6012.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6a2-0509d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adl6012.html",
//     mfgPart: "ADL6012SMT",
//     models: "this is the new part",
//     pn: "XM-C6A2-0509D",
//     price: 35700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1705",
//     catId: "DTC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/LTC5582.pdf",
//     description: "F=45M-10G Pin=-43 to 15dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1705A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9f5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ltc5582.html",
//     mfgPart: "LTC5582SMT",
//     models: "this part is missing models",
//     pn: "XM-C9F5-0404D",
//     price: 19200,
//     priceType: "cents",
//     props: [-43, 15, 45, 10000, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "1705_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1516",
//     catId: "DTC",
//     ctlBoard: "XM-C6A3-0407D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADL6012.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6a2-0509d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/HMC662.html",
//     mfgPart: "HMC662LP3E",
//     models: "this is the new part",
//     pn: "XM-A412-0404D",
//     price: 149100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "1959_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "DTC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MADT-011000",
//     mfgPart: "MADT-011000",
//     models: "this is the new part",
//     pn: "XM-A7T2-0404D",
//     price: 14800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "1958_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "DTC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/lt5581.html",
//     mfgPart: "LT5581",
//     models: "this is the new part",
//     pn: "XM-D4K2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Detectors",
//     newBRDImage: "2585_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/DPX-1.pdf",
//     description: "F1=DC-850MMHzF2=1.4G-5GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6k3-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-1.aspx#product-details",
//     mfgPart: "DPX-1-1SMT",
//     models: "this part is missing models",
//     pn: "XM-B6K3-0404D",
//     price: 19800,
//     priceType: "cents",
//     props: [null, null, 0, 850, 0.5, 1400, 5000, 0.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/DPX-1.pdf",
//     description: "F1=DC-850MMHzF2=1.4G-5GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3g2-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-1.aspx#product-details",
//     mfgPart: "DPX-1-2SMT",
//     models: "this part is missing models",
//     pn: "XM-C3G2-0404D",
//     price: 19800,
//     priceType: "cents",
//     props: [null, null, 0, 850, 0, 1400, 5000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/DPX-2.pdf",
//     description: "F1=DC-1.5GMHzF2=2.7G-7GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3g4-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-2.aspx",
//     mfgPart: "DPX-2-1SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C3G4D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C3G4-0404D",
//     price: 19800,
//     priceType: "cents",
//     props: [null, null, 0, 1500, 0.6, 2700, 7000, 0.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/DPX-2.pdf",
//     description: "F1=DC-1.5GMHzF2=2.7G-7GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3g5-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-2.aspx",
//     mfgPart: "DPX-2-2SMT",
//     models: "this part is missing models",
//     pn: "XM-C3G5-0404D",
//     price: 19800,
//     priceType: "cents",
//     props: [null, null, 0, 1500, 0, 2700, 7000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/DPX-3.pdf",
//     description: "F1=DC-2.3GMHzF2=4.2G-8GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3g6-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-3.aspx",
//     mfgPart: "DPX-3-1SMT",
//     models: "this part is missing models",
//     pn: "XM-C3G6-0404D",
//     price: 19800,
//     priceType: "cents",
//     props: [null, null, 0, 2300, 0.7, 4200, 8000, 0.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/DPX-3.pdf",
//     description: "F1=DC-2.3GMHzF2=4.2G-8GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3g7-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-3.aspx",
//     mfgPart: "DPX-3-2SMT",
//     models: "this part is missing models",
//     pn: "XM-C3G7-0404D",
//     price: 19800,
//     priceType: "cents",
//     props: [null, null, 0, 2300, 0, 4200, 8000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/DPX-4.pdf",
//     description: "F1=DC-2.8GMHzF2=5.5G-12GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3g8-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-4.aspx",
//     mfgPart: "DPX-4-1SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C3G8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C3G8-0404D",
//     price: 19800,
//     priceType: "cents",
//     props: [null, null, 0, 2800, 0.8, 5500, 12000, 1.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.markimicrowave.com/Assets/datasheets/DPX-4.pdf",
//     description: "F1=DC-2.8GMHzF2=5.5G-12GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3g9-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-4.aspx",
//     mfgPart: "DPX-4-2SMT",
//     models: "this part is missing models",
//     pn: "XM-C3G9-0404D",
//     price: 19800,
//     priceType: "cents",
//     props: [null, null, 0, 2800, 0, 5500, 12000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/DPX-M50.pdf",
//     description: "F1=DC-35MMHzF2=70M-10GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3h1-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-m50.aspx",
//     mfgPart: "DPX-M50-1SMT",
//     models: "this part is missing models",
//     pn: "XM-C3H1-0404D",
//     price: 26600,
//     priceType: "cents",
//     props: [null, null, 0, 35, 0.7, 70, 10000, 0.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/DPX-M50.pdf",
//     description: "F1=DC-35MMHzF2=70M-10GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3h2-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-m50.aspx",
//     mfgPart: "DPX-M50-2SMT",
//     models: "this part is missing models",
//     pn: "XM-C3H2-0404D",
//     price: 26600,
//     priceType: "cents",
//     props: [null, null, 0, 35, 0, 70, 10000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/DPX-0R5.pdf",
//     description: "F1=DC-360MMHzF2=700M-8GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3h3-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-0r5.aspx",
//     mfgPart: "DPX-0R5-1SMT",
//     models: "this part is missing models",
//     pn: "XM-C3H3-0404D",
//     price: 23200,
//     priceType: "cents",
//     props: [null, null, 0, 360, 0.8, 700, 8000, 0.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0244",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/DPX-0R5.pdf",
//     description: "F1=DC-360MMHzF2=700M-8GMHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0244C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3h4-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/filters-diplexers/dpx-0r5.aspx",
//     mfgPart: "DPX-0R5-2SMT",
//     models: "this part is missing models",
//     pn: "XM-C3H4-0404D",
//     price: 23200,
//     priceType: "cents",
//     props: [null, null, 0, 360, 0, 700, 8000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "0244_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1567",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RDP-2R15+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c581-0709d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RDP-2R15%2B",
//     mfgPart: "RDP-2R15+SMT",
//     models: "this is the new part",
//     pn: "XM-C581-0709D",
//     price: 15700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1649",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RDP-2R15+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c876-0606d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RDP-2R15%2B",
//     mfgPart: "RDP-2R15+SMT",
//     models: "this is the new part",
//     pn: "XM-C876-0606D",
//     price: 11900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "1649_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1572",
//     catId: "DPX",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RDP-2R15+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c877-0709d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RDP-2R15%2B",
//     mfgPart: "RDP-2R15+SMT",
//     models: "this is the new part",
//     pn: "XM-C877-0709D",
//     price: 15700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Diplexers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0014",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc705.pdf",
//     description: "N=17 Fin=100M-6.5G Pin=-15-10dBm Pout=0dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0014I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a297-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc705.html",
//     mfgPart: "HMC705LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A297-0604D",
//     price: 24300,
//     priceType: "cents",
//     props: [17, 100, 6500, -15, 10, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0015",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc794.pdf",
//     description: "N=2 Fin=200M-2G Pin=-2-10dBm Pout=4.5dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0015H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a298-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc794.html",
//     mfgPart: "HMC794LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A298-0604D",
//     price: 19600,
//     priceType: "cents",
//     props: [2, 200, 2000, -2, 10, 4.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0015_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0016",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc862a.pdf",
//     description: "N=1, 2, 4, 8 Fin=100M-24G Pin=-15-10dBm Pout=-3dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0016H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a299-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc862a.html",
//     mfgPart: "HMC862ALP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A299-0404D",
//     price: 15300,
//     priceType: "cents",
//     props: [1248, 100, 24000, -15, 10, -3, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0016_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0018",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "http://www.onsemi.com/pub/Collateral/NC7SZ74-D.PDF",
//     description: "N=2 Fin=1M-300M Pin=7-17dBm Pout=12dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0018H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2a1-0404d/",
//     mfgCode: "FSEMI",
//     mfgLandingPage:
//       "https://www.fairchildsemi.com/ShoppingExperience/action/displayItems?itemType=SAMPLE&gpn=NC7SZ74",
//     mfgPart: "NC7SZ74SMT",
//     models: "this part is missing models",
//     pn: "XM-A2A1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 1, 300, 7, 17, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0018_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0019",
//     catId: "DIV",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc432.pdf",
//     description: "N=2 Fin=DC-8G Pin=-12-12dBm Pout=-6.5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0019H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2k6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc432.html",
//     mfgPart: "HMC432ESMT",
//     models: "this part is missing models",
//     pn: "XM-A2K6-0404D",
//     price: 9900,
//     priceType: "cents",
//     props: [2, 0, 8000, -12, 12, -6.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0019_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0017",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "http://www.onsemi.com/pub/Collateral/NC7SZ74-D.PDF",
//     description: "N=4,8,16 Fin=1M-300M Pin=7-17dBm Pout=12dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0017H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2k7-0804d/",
//     mfgCode: "FSEMI",
//     mfgLandingPage:
//       "https://www.fairchildsemi.com/ShoppingExperience/action/displayItems?itemType=SAMPLE&gpn=NC7SZ74",
//     mfgPart: "NC7SZ74SMT",
//     models: "this part is missing models",
//     pn: "XM-A2K7-0804D",
//     price: 0,
//     priceType: "cents",
//     props: [4816, 1, 300, 7, 17, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F9",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0017_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0014",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc705.pdf",
//     description: "N=2 Fin=100M-6.5G Pin=-15-10dBm Pout=0dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0014I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a439-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc705.html",
//     mfgPart: "HMC705LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A439-0604D",
//     price: 21100,
//     priceType: "cents",
//     props: [2, 100, 6500, -15, 10, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0014",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc705.pdf",
//     description: "N=4 Fin=100M-6.5G Pin=-15-10dBm Pout=0dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0014I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a441-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc705.html",
//     mfgPart: "HMC705LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A441-0604D",
//     price: 21100,
//     priceType: "cents",
//     props: [4, 100, 6500, -15, 10, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0014",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc705.pdf",
//     description: "N=8 Fin=100M-6.5G Pin=-15-10dBm Pout=0dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0014I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a442-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc705.html",
//     mfgPart: "HMC705LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A442-0604D",
//     price: 21100,
//     priceType: "cents",
//     props: [8, 100, 6500, -15, 10, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0014",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc705.pdf",
//     description: "N=16 Fin=100M-6.5G Pin=-15-10dBm Pout=0dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0014I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a443-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc705.html",
//     mfgPart: "HMC705LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A443-0604D",
//     price: 25400,
//     priceType: "cents",
//     props: [16, 100, 6500, -15, 10, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0504",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc437.pdf",
//     description: "N=3 Fin=DC-7G Pin=-12-12dBm Pout=-2dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0504A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5j1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc437.html",
//     mfgPart: "HMC437MS8GESMT",
//     models: "this part is missing models",
//     pn: "XM-A5J1-0404D",
//     price: 15300,
//     priceType: "cents",
//     props: [3, 0, 7000, -12, 12, -2, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0504",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc438.pdf",
//     description: "N=5 Fin=DC-7G Pin=-15-12dBm Pout=-1dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0504A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5j2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc438.html",
//     mfgPart: "HMC438MS8GESMT",
//     models: "this part is missing models",
//     pn: "XM-A5J2-0404D",
//     price: 15300,
//     priceType: "cents",
//     props: [5, 0, 7000, -15, 12, -1, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0502",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc492.pdf",
//     description: "N=2 Fin=50M-18G Pin=-20-5dBm Pout=-4dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0502A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5j5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc492.html",
//     mfgPart: "HMC492LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5J5-0404D",
//     price: 14000,
//     priceType: "cents",
//     props: [2, 50, 18000, -20, 5, -4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0502",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc493.pdf",
//     description: "N=4 Fin=DC-18G Pin=-20-10dBm Pout=-4dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0502A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5j6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc493.html",
//     mfgPart: "HMC493LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5J6-0404D",
//     price: 13500,
//     priceType: "cents",
//     props: [4, 0, 18000, -20, 10, -4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0502",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc494.pdf",
//     description: "N=8 Fin=DC-18G Pin=-20-10dBm Pout=-4dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0502A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5j7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc494.html",
//     mfgPart: "HMC494LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5J7-0404D",
//     price: 13500,
//     priceType: "cents",
//     props: [8, 0, 18000, -20, 10, -4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0501",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc361s8g.pdf",
//     description: "N=2 Fin=DC-10G Pin=-10-10dBm Pout=3dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0501A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5j8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc361s8g.html",
//     mfgPart: "HMC361S8GESMT",
//     models: "this part is missing models",
//     pn: "XM-A5J8-0404D",
//     price: 11600,
//     priceType: "cents",
//     props: [2, 0, 10000, -10, 10, 3, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0501",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc362.pdf",
//     description: "N=4 Fin=DC-12G Pin=-15-10dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0501A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5j9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc362s8g.html",
//     mfgPart: "HMC362S8GESMT",
//     models: "this part is missing models",
//     pn: "XM-A5J9-0404D",
//     price: 12400,
//     priceType: "cents",
//     props: [4, 0, 12000, -15, 10, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0501",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc363s8g.pdf",
//     description: "N=8 Fin=200M-13G Pin=-15-10dBm Pout=-6dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0501A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5k1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc363s8g.html",
//     mfgPart: "HMC363S8GESMT",
//     models: "this part is missing models",
//     pn: "XM-A5K1-0404D",
//     price: 12400,
//     priceType: "cents",
//     props: [8, 200, 13000, -15, 10, -6, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0501",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc365s8g.pdf",
//     description: "N=4 Fin=DC-13G Pin=-15-10dBm Pout=4dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0501A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5k2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc365s8g.html",
//     mfgPart: "HMC365S8GESMT",
//     models: "this part is missing models",
//     pn: "XM-A5K2-0404D",
//     price: 12400,
//     priceType: "cents",
//     props: [4, 0, 13000, -15, 10, 4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0500",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc361g8.pdf",
//     description: "N=2 Fin=10M-13G Pin=-15-10dBm Pout=1dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0500A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5k3-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc361g8.html",
//     mfgPart: "HMC361G8SMT",
//     models: "this part is missing models",
//     pn: "XM-A5K3-0604D",
//     price: 33100,
//     priceType: "cents",
//     props: [2, 10, 13000, -15, 10, 1, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0500",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc363g8.pdf",
//     description: "N=8 Fin=200M-13G Pin=-15-10dBm Pout=-4dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0500A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5k4-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc363g8.html",
//     mfgPart: "HMC363G8SMT",
//     models: "this part is missing models",
//     pn: "XM-A5K4-0604D",
//     price: 34500,
//     priceType: "cents",
//     props: [8, 200, 13000, -15, 10, -4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0500",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc365g8.pdf",
//     description: "N=4 Fin=DC-13G Pin=-15-10dBm Pout=7dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0500A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5k5-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc365g8.html",
//     mfgPart: "HMC365G8SMT",
//     models: "this part is missing models",
//     pn: "XM-A5K5-0604D",
//     price: 34500,
//     priceType: "cents",
//     props: [4, 0, 13000, -15, 10, 7, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0206",
//     catId: "DIV",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF5000.pdf",
//     description: "N=2 Fin=4G-18G Pin=-10-10dBm Pout=-5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0206I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5p2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/adf5000.html",
//     mfgPart: "ADF5000BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5P2-0404D",
//     price: 11400,
//     priceType: "cents",
//     props: [2, 4000, 18000, -10, 10, -5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0206_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0206",
//     catId: "DIV",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF5001.pdf",
//     description: "N=4 Fin=4G-18G Pin=-10-10dBm Pout=-5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0206I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5p3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/adf5001.html",
//     mfgPart: "ADF5001BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5P3-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [4, 4000, 18000, -10, 10, -5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0206_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0206",
//     catId: "DIV",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF5002.pdf",
//     description: "N=8 Fin=4G-18G Pin=-10-10dBm Pout=-5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0206I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5p4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/adf5002.html",
//     mfgPart: "ADF5002BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-A5P4-0404D",
//     price: 11400,
//     priceType: "cents",
//     props: [8, 4000, 18000, -10, 10, -5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0206_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0020",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc447.pdf",
//     description: "N=4 Fin=10G-26G Pin=15-10dBm Pout=-4dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0020H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5r9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc447.html",
//     mfgPart: "HMC447LC3SMT",
//     models: "this part is missing models",
//     pn: "XM-A5R9-0404D",
//     price: 15600,
//     priceType: "cents",
//     props: [4, 10000, 26000, 15, 10, -4, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0019",
//     catId: "DIV",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc434.pdf",
//     description: "N=8 Fin=200M-8G Pin=-10-10dBm Pout=-2dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0019H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5t1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc434.html",
//     mfgPart: "HMC434ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5T1-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [8, 200, 8000, -10, 10, -2, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0019_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0015",
//     catId: "DIV",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc905.pdf",
//     description: "N=1 to 4 Fin=400M-6G Pin=0-10dBm Pout=6dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0015H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5t2-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc905.html",
//     mfgPart: "HMC905LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5T2-0604D",
//     price: 22700,
//     priceType: "cents",
//     props: [14, 400, 6000, 0, 10, 6, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0015_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0019",
//     catId: "DIV",
//     ctlBoard: "XM-A2R9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc433.pdf",
//     description: "N=4 Fin=DC-8G Pin=-12-10dBm Pout=-3.5dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0019H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a691-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc433.html",
//     mfgPart: "HMC433ESMT",
//     models: "this part is missing models",
//     pn: "XM-A691-0404D",
//     price: 10100,
//     priceType: "cents",
//     props: [4, 0, 8000, -12, 10, -3.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0019_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1480",
//     catId: "DIV",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c1a1-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage: "https://marketing.xmicrowave.com/keysight-device-info",
//     mfgPart: "TC278PSMT",
//     models: "this is the new part",
//     pn: "XM-C1A1-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1480",
//     catId: "DIV",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c1a2-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage: "https://marketing.xmicrowave.com/keysight-device-info",
//     mfgPart: "TC278PSMT",
//     models: "this is the new part",
//     pn: "XM-C1A2-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1480",
//     catId: "DIV",
//     ctlBoard: "TBD",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c1a3-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage: "https://marketing.xmicrowave.com/keysight-device-info",
//     mfgPart: "TC278PSMT",
//     models: "this is the new part",
//     pn: "XM-C1A3-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1480",
//     catId: "DIV",
//     ctlBoard: "XM-C7A7-0409D",
//     datasheetURL: "https://marketing.xmicrowave.com/keysight-device-info",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7a6-0409d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage: "https://marketing.xmicrowave.com/keysight-device-info",
//     mfgPart: "TC278PSMT",
//     models: "this is the new part",
//     pn: "XM-C7A6-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0015",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc794.pdf",
//     description: "N=3 Fin=200M-2G Pin=-2-10dBm Pout=4.5dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0015H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7j1-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc794.html",
//     mfgPart: "HMC794LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-C7J1-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [3, 200, 2000, -2, 10, 4.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0015_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0015",
//     catId: "DIV",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc794.pdf",
//     description: "N=4 Fin=200M-2G Pin=-2-10dBm Pout=4.5dBm ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0015H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7j2-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-dividers-prescalers-counters/hmc794.html",
//     mfgPart: "HMC794LP3ESMT",
//     models: "this part is missing models",
//     pn: "XM-C7J2-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [4, 200, 2000, -2, 10, 4.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Dividers",
//     newBRDImage: "0015_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-18G Slope=1dB IL=-.25-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2h7-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ05467SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2H7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2H7-0204D",
//     price: 6400,
//     priceType: "cents",
//     props: [0, 18000, 1, -0.25, 0.25, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-18G Slope=1.5dB IL=-.25-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2h8-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ05468SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2H8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2H8-0204D",
//     price: 6700,
//     priceType: "cents",
//     props: [0, 18000, 1.5, -0.25, 0.25, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-18G Slope=2dB IL=-.25-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2h9-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ05469SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2H9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2H9-0204D",
//     price: 7100,
//     priceType: "cents",
//     props: [0, 18000, 2, -0.25, 0.25, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-18G Slope=2.5dB IL=-.25-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j1-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ05470SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2J1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2J1-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 18000, 2.5, -0.25, 0.25, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-18G Slope=3dB IL=-.25-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j2-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ05471SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2J2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2J2-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 18000, 3, -0.25, 0.25, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-18G Slope=3.5dB IL=-.25-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2j3-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ05472SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2J3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2J3-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 18000, 3.5, -0.25, 0.25, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0162",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-8G Slope=.6dB IL=-.8-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0162K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2v7-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ3042SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2V7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2V7-0204D",
//     price: 7000,
//     priceType: "cents",
//     props: [0, 8000, 0.6, -0.8, 0.25, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0162_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0162",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-8G Slope=1.5dB IL=-1.6-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0162K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2v8-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ3055SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2V8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2V8-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 8000, 1.5, -1.6, 0.25, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0162_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-27G Slope=3.25dB IL=-3.5-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2v9-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ06042SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2V9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2V9-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 27000, 3.25, -3.5, 0.25, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-14G Slope=3.5dB IL=3-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2w1-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ05246SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2W1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2W1-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [0, 14000, 3.5, 3, 0.25, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-14G Slope=2.25dB IL=-2.2-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2w2-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ05510SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2W2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2W2-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [0, 14000, 2.25, -2.2, 0.25, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-1-63+.pdf",
//     description: "F=DC-6G Slope=1.2dB IL=-1.57@10MHz--0.4@6GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2b8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-1-63%2B",
//     mfgPart: "EQY-1-63+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2B8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2B8-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 6000, 1.2, -1.571, -0.46, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-2-63+.pdf",
//     description: "F=DC-6G Slope=2.1dB IL=-2.50@10MHz--0.4@6GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2b9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-2-63%2B",
//     mfgPart: "EQY-2-63+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2B9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2B9-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 6000, 2.1, -2.501, -0.46, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-3-63+.pdf",
//     description: "F=DC-6G Slope=3.2dB IL=-3.8@10MHz--0.6@6GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2c1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-3-63%2B",
//     mfgPart: "EQY-3-63+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2C1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2C1-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 6000, 3.2, -3.81, -0.66, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-4-63+.pdf",
//     description: "F=DC-6G Slope=4.2dB IL=-4.8@10MHz--0.6@6GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2c2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-4-63%2B",
//     mfgPart: "EQY-4-63+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2C2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2C2-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 6000, 4.2, -4.81, -0.66, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-5-63+.pdf",
//     description: "F=DC-6G Slope=5dB IL=-6.0@10MHz--1.0@6GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2c3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-5-63%2B",
//     mfgPart: "EQY-5-63+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2C3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2C3-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 6000, 5, -6.01, -1.06, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-6-63+.pdf",
//     description: "F=DC-6G Slope=6.5dB IL=-6.7@10MHz--0.5@6GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2c4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-6-63%2B",
//     mfgPart: "EQY-6-63+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2C4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2C4-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 6000, 6.5, -6.71, -0.56, null, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-8-63+.pdf",
//     description: "F=DC-6G Slope=8.2dB IL=-8.7@10MHz--0.5@6GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2c5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-8-63%2B",
//     mfgPart: "EQY-8-63+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2C5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2C5-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 6000, 8.2, -8.71, -0.56, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-10-63+.pdf",
//     description: "F=DC-6G Slope=10.2dB IL=-11.16@10MHz--0.98@6GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2c6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-10-63%2B",
//     mfgPart: "EQY-10-63+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2C6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2C6-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 6000, 10.2, -11.161, -0.986, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-14ASM.pdf",
//     description: "F=DC-14G IL=-3@DC--0.8@14GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2c7-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq3-14asm.aspx",
//     mfgPart: "MEQ3-14ASMSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2C7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2C7-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 14000, null, -3, -0.814, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-14ASM.pdf",
//     description: "F=DC-14G IL=-6@DC--0.8@14GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2c8-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq6-14asm.aspx",
//     mfgPart: "MEQ6-14ASMSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2C8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2C8-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 14000, null, -6, -0.814, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-14ASM.pdf",
//     description: "F=DC-14G IL=-10@DC--0.8@14GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2c9-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/equalizers/meq10-14asm.aspx",
//     mfgPart: "MEQ10-14ASMSMT",
//     models: "this part is missing models",
//     pn: "XM-B2C9-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 14000, null, -10, -0.814, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ASM.pdf",
//     description: "F=DC-20G IL=-3@DC--1.2@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5d1-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq3-20asm.aspx",
//     mfgPart: "MEQ3-20ASMSMT",
//     models: "this part is missing models",
//     pn: "XM-B5D1-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 20000, null, -3, -1.22, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ASM.pdf",
//     description: "F=DC-20G IL=-5@DC--1.2@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5d2-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq5-20asm.aspx",
//     mfgPart: "MEQ5-20ASMSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5D2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5D2-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 20000, null, -5, -1.22, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ASM.pdf",
//     description: "F=DC-20G IL=-6@DC--1.2@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5d3-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq6-20asm.aspx",
//     mfgPart: "MEQ6-20ASMSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5D3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5D3-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 20000, null, -6, -1.22, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ASM.pdf",
//     description: "F=DC-20G IL=-7.5@DC--1.2@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5d4-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq7-20asm.aspx",
//     mfgPart: "MEQ7-20ASMSMT",
//     models: "this part is missing models",
//     pn: "XM-B5D4-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 20000, null, -7.5, -1.22, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ASM.pdf",
//     description: "F=DC-20G IL=-10@DC--1.2@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5d5-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/equalizers/meq10-20asm.aspx",
//     mfgPart: "MEQ10-20ASMSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5D5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5D5-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 20000, null, -10, -1.22, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ASM.pdf",
//     description: "F=DC-20G IL=-11@DC--1.2@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5d6-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/equalizers/meq11-20asm.aspx",
//     mfgPart: "MEQ11-20ASMSMT",
//     models: "this part is missing models",
//     pn: "XM-B5D6-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 20000, null, -11, -1.22, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-27G Slope=2.25dB IL=-2.2-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6k9-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     mfgPart: "AEQ2050SMT",
//     models: "this part is missing models",
//     pn: "XM-B6K9-0204D",
//     price: 6400,
//     priceType: "cents",
//     props: [0, 27000, 2.25, -2.2, 0.25, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-27G Slope=2.5dB IL=-3-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6l1-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Gain-Equalizers.aspx",
//     mfgPart: "AEQ2199SMT",
//     models: "this part is missing models",
//     pn: "XM-B6L1-0204D",
//     price: 7200,
//     priceType: "cents",
//     props: [0, 27000, 2.5, -3, 0.25, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0161",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     description: "F=DC-27G Slope=3.25dB IL=-3.5-0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0161K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6l2-0204d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/getattachment/69df7735-7ed2-4c4f-903b-a99acda4fa2d/Gain-Equalizers.aspx",
//     mfgPart: "AEQ2234SMT",
//     models: "this part is missing models",
//     pn: "XM-B6L2-0204D",
//     price: 6400,
//     priceType: "cents",
//     props: [0, 27000, 3.25, -3.5, 0.25, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0161_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-12-24+.pdf",
//     description: "F=DC-20G Slope=12dB IL=-13.39@10MHz--1.4@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b923-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-12-24%2B",
//     mfgPart: "EQY-12-24+SMT",
//     models: "this part is missing models",
//     pn: "XM-B923-0204D",
//     price: 8200,
//     priceType: "cents",
//     props: [0, 20000, 12, -13.391, -1.42, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-2-24+.pdf",
//     description: "F=DC-20G Slope=2.1dB IL=-3.04@10MHz--0.9@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b924-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-2-24%2B",
//     mfgPart: "EQY-2-24+SMT",
//     models: "this part is missing models",
//     pn: "XM-B924-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [0, 20000, 2.1, -3.041, -0.92, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-6-24+.pdf",
//     description: "F=DC-20G Slope=6.28dB IL=-6.82@10MHz--0.55@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b925-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-6-24%2B",
//     mfgPart: "EQY-6-24+SMT",
//     models: "this part is missing models",
//     pn: "XM-B925-0204D",
//     price: 8200,
//     priceType: "cents",
//     props: [0, 20000, 6.28, -6.821, -0.552, null, 31],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-0-24+.pdf",
//     description: "F=DC-20G Slope=-0.37dB IL=-0.02--0.39dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b926-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-0-24%2B",
//     mfgPart: "EQY-0-24+SMT",
//     models: "this part is missing models",
//     pn: "XM-B926-0204D",
//     price: 8200,
//     priceType: "cents",
//     props: [0, 20000, -0.37, -0.02, -0.39, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-0-63+.pdf",
//     description: "F=DC-6G Slope=-0.10dB IL=-0.03@10MHz--0.14dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b927-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-0-63%2B",
//     mfgPart: "EQY-0-63+SMT",
//     models: "this part is missing models",
//     pn: "XM-B927-0204D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 6000, -0.1, -0.031, -0.14, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-10-24+.pdf",
//     description: "F=DC-20G Slope=10.20dB IL=-11.13@10MHz--0.90@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b928-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-10-24%2B",
//     mfgPart: "EQY-10-24+SMT",
//     models: "this part is missing models",
//     pn: "XM-B928-0204D",
//     price: 8200,
//     priceType: "cents",
//     props: [0, 20000, 10.2, -11.131, -0.902, null, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-3-24+.pdf",
//     description: "F=DC-20G Slope=3.12dB IL=-3.81@10MHz--0.69@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b929-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-3-24%2B",
//     mfgPart: "EQY-3-24+SMT",
//     models: "this part is missing models",
//     pn: "XM-B929-0204D",
//     price: 8200,
//     priceType: "cents",
//     props: [0, 20000, 3.12, -3.811, -0.692, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-5-24+.pdf",
//     description: "F=DC-20G Slope=5.1dB IL=-5.8@10MHz--0.7@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b931-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-5-24%2B",
//     mfgPart: "EQY-5-24+SMT",
//     models: "this part is missing models",
//     pn: "XM-B931-0204D",
//     price: 8200,
//     priceType: "cents",
//     props: [0, 20000, 5.1, -5.81, -0.72, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0931",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-8-24+.pdf",
//     description: "F=DC-20G Slope=8.3dB IL=-9.1@10MHz--0.8@20GHzdB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0931A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b932-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-8-24%2B",
//     mfgPart: "EQY-8-24+SMT",
//     models: "this part is missing models",
//     pn: "XM-B932-0204D",
//     price: 8300,
//     priceType: "cents",
//     props: [0, 20000, 8.3, -9.11, -0.82, null, 34],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0931_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-7ASM.pdf",
//     description: "F=DC-7G IL=3-1.1dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5a2-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq3-7asm.aspx",
//     mfgPart: "MEQ3-7ASMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5A2-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 7000, null, 3, 1.1, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-7ASM.pdf",
//     description: "F=DC-7G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5a3-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq6-7asm.aspx",
//     mfgPart: "MEQ6-7ASMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5A3-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 7000, null, 0, 0, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-7ASM.pdf",
//     description: "F=DC-7G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5a4-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq10-7asm.aspx",
//     mfgPart: "MEQ10-7ASMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5A4-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 7000, null, 0, 0, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0933",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-7ASM.pdf",
//     description: "F=DC-7G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0933B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5a5-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq12-7asm.aspx",
//     mfgPart: "MEQ12-7ASMSMT",
//     models: "this part is missing models",
//     pn: "XM-C5A5-0204D",
//     price: 10000,
//     priceType: "cents",
//     props: [0, 7000, null, 0, 0, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "0933_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQ3-30A.pdf",
//     description: "F=DC-30G IL=3-0.6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5c8-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq3-30a.aspx",
//     mfgPart: "MEQ3-30ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5C8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 30000, 0, 3, 0.6, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQ6-30A.pdf",
//     description: "F=DC-30M IL=6-0.6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5c9-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq6-30a.aspx",
//     mfgPart: "MEQ6-30ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5C9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 30, 0, 6, 0.6, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQ10-30A.pdf",
//     description: "F=DC-30G IL=10-0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5d1-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq10-30a.aspx",
//     mfgPart: "MEQ10-30ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5D1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 30000, 0, 10, 0.5, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQ3-60A.pdf",
//     description: "F=DC-60G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5d2-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq3-60a.aspx",
//     mfgPart: "MEQ3-60ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5D2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 60000, null, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQ6-60A.pdf",
//     description: "F=DC-60G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5d3-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq6-60a.aspx",
//     mfgPart: "MEQ6-60ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5D3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 60000, null, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQ10-60A.pdf",
//     description: "F=DC-60G IL=10-1.6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5d4-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq10-60a.aspx",
//     mfgPart: "MEQ10-60ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5D4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 60000, null, 10, 1.6, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-7ACH.pdf",
//     description: "F=DC-7G IL=3-0.4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5d5-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq3-7ach.aspx",
//     mfgPart: "MEQ3-7ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5D5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 7000, 0, 3, 0.4, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-7ACH.pdf",
//     description: "F=DC-7G IL=6-0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5d6-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq6-7ach.aspx",
//     mfgPart: "MEQ6-7ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5D6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 7000, 0, 6, 0.5, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-7ACH.pdf",
//     description: "F=DC-7G IL=10-0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5d7-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq10-7ach.aspx",
//     mfgPart: "MEQ10-7ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5D7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 7000, 0, 10, 0.5, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-7ACH.pdf",
//     description: "F=DC-7G IL=12.5-0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5d8-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq12-7ach.aspx",
//     mfgPart: "MEQ12-7ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5D8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 7000, 0, 12.5, 0.5, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-14ACH.pdf",
//     description: "F=DC-14G IL=3-0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5d9-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq3-14ach.aspx",
//     mfgPart: "MEQ3-14ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5D9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 14000, 0, 3, 0.5, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-14ACH.pdf",
//     description: "F=DC-14G IL=6-0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5e1-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq6-14ach.aspx",
//     mfgPart: "MEQ6-14ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5E1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 14000, 0, 6, 0.5, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-14ACH.pdf",
//     description: "F=DC-14G IL=10-0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5e2-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/equalizers/meq10-14ach.aspx",
//     mfgPart: "MEQ10-14ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5E2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 14000, 0, 10, 0.5, null, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ACH.pdf",
//     description: "F=DC-20G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5e3-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq3-20ach.aspx",
//     mfgPart: "MEQ3-20ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5E3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 20000, null, 0, 0, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ACH.pdf",
//     description: "F=DC-20G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5e4-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq5-20ach.aspx",
//     mfgPart: "MEQ5-20ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5E4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 20000, null, 0, 0, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ACH.pdf",
//     description: "F=DC-20G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5e5-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq6-20ach.aspx",
//     mfgPart: "MEQ6-20ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5E5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 20000, null, 0, 0, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ACH.pdf",
//     description: "F=DC-20G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5e6-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/equalizers/meq7-20ach.aspx",
//     mfgPart: "MEQ7-20ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5E6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 20000, null, 0, 0, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ACH.pdf",
//     description: "F=DC-20G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5e7-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/equalizers/meq10-20ach.aspx",
//     mfgPart: "MEQ10-20ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5E7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 20000, null, 0, 0, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1230",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MEQX-20ACH.pdf",
//     description: "F=DC-20G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1230B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5e8-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/equalizers/meq11-20ach.aspx",
//     mfgPart: "MEQ11-20ACHDIE",
//     models: "this part is missing models",
//     pn: "XM-C5E8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 20000, null, 0, 0, null, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1230_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1712",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-9-453-D+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c991-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-9-453-DG%2B",
//     mfgPart: "EQY-9-453-D+DIE",
//     models: "this is the new part",
//     pn: "XM-C991-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1712_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1712",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-7-453-D+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c992-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-7-453-DG%2B",
//     mfgPart: "EQY-8-453-D+DIE",
//     models: "this is the new part",
//     pn: "XM-C992-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1712_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1712",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-7-453-D+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c993-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-7-453-DG%2B",
//     mfgPart: "EQY-7-453-D+DIE",
//     models: "this is the new part",
//     pn: "XM-C993-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1712_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1712",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-6-453-D+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c994-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-6-453-DG%2B",
//     mfgPart: "EQY-6-453-D+DIE",
//     models: "this is the new part",
//     pn: "XM-C994-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1712_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1712",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-5-453-D+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c995-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-5-453-DG%2B",
//     mfgPart: "EQY-5-453-D+DIE",
//     models: "this is the new part",
//     pn: "XM-C995-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1712_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1712",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-4-453-D+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c996-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-4-453-DG%2B",
//     mfgPart: "EQY-4-453-D+DIE",
//     models: "this is the new part",
//     pn: "XM-C996-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1712_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1712",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-3-453-D+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c997-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-3-453-DG%2B",
//     mfgPart: "EQY-3-453-D+DIE",
//     models: "this is the new part",
//     pn: "XM-C997-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1712_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1712",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EQY-10-453-D+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c998-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EQY-10-453-DG%2B",
//     mfgPart: "EQY-10-453-D+DIE",
//     models: "this is the new part",
//     pn: "XM-C998-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "1712_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/surface-mount-chip-equalizers/",
//     mfgPart: "CEHF3270P320SMTF",
//     models: "this is the new part",
//     pn: "XM-D3U8-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "2544_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "EQL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-mw-mmw-components/resistive-components/surface-mount-chip-equalizers/",
//     mfgPart: "CEHF3170P220SMTF",
//     models: "this is the new part",
//     pn: "XM-D3U9-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Equalizers",
//     newBRDImage: "2533_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0165",
//     catId: "BSF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://pdf1.alldatasheet.com/datasheet-pdf/view/622086/DILABS/N012ME9S.html",
//     description: "CF=1.227G 20dBRej@1200&1244MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0165H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2c4-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Bandstop-Filters",
//     mfgPart: "N012ME9SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2C4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2C4-0604D",
//     price: 23600,
//     priceType: "cents",
//     props: [1227, null, 1.5, null, null, 1200, 1244, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Stop Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "BSF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BSP-255310+.pdf",
//     description: "CF=282.5M 20dBRej@255&310MHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8c4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BSP-255310%2B",
//     mfgPart: "BSP-255310+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8C4-0604D",
//     price: 14700,
//     priceType: "cents",
//     props: [282.5, null, 48, null, null, 255, 310, 262, 295],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Band Stop Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0982",
//     catId: "BSF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/BSF-108+.pdf",
//     description: "CF=98M 20dBRej@88&109MHz",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0982A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8u1-0804d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=BSF-108%2B",
//     mfgPart: "BSF-108+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8U1-0804D",
//     price: 15400,
//     priceType: "cents",
//     props: [98, null, 0.28, null, null, 88, 109, 90, 106],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Band Stop Filters",
//     newBRDImage: "0982_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-103+.pdf",
//     description: "F=9.7G-15G IL=1.8dB 20dBRej@7325 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1908N/A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1c2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-103%2B",
//     mfgPart: "HFCN-103+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1C2-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [9700, 15000, 1.8, 8600, 7325, 5600],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-440+.pdf",
//     description: "F=500M-2.5G IL=2dB 20dBRej@360 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1c3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-440%2B",
//     mfgPart: "HFCN-440+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1C3-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [500, 2500, 2, 420, 360, 320],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-650+.pdf",
//     description: "F=710M-2.49G IL=2dB 20dBRej@500 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1c4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-650%2B",
//     mfgPart: "HFCN-650+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1C4-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [710, 2490, 2, 625, 500, 400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-740+.pdf",
//     description: "F=780M-2.8G IL=2dB 20dBRej@540 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1c5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-740%2B",
//     mfgPart: "HFCN-740+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1C5-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [780, 2800, 2, 670, 540, 440],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-880+.pdf",
//     description: "F=950M-3.2G IL=2dB 20dBRej@640 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1c6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-880%2B",
//     mfgPart: "HFCN-880+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1C6-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [950, 3200, 2, 800, 640, 470],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1000+.pdf",
//     description: "F=1.08G-4G IL=2.0dB 20dBRej@915 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1c7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1000%2B",
//     mfgPart: "HFCN-1000+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1C7-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1080, 4000, 2, 1100, 915, 720],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1100+.pdf",
//     description: "F=1.5G-3.9G IL=2.0dB 20dBRej@900 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1c8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1100%2B",
//     mfgPart: "HFCN-1100+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1C8-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1500, 3900, 2, 1100, 900, 680],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1200D+.pdf",
//     description: "F=1.22G-4.6G IL=2dB 20dBRej@970 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1c9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1200%2B",
//     mfgPart: "HFCN-1200+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1C9-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1220, 4600, 2, 1140, 970, 810],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1320+.pdf",
//     description: "F=1.4G-5G IL=2dB 20dBRej@1050 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1d1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1320%2B",
//     mfgPart: "HFCN-1320+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1D1-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1400, 5000, 2, 1270, 1050, 950],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1500+.pdf",
//     description: "F=1.6G-5.5G IL=2dB 20dBRej@1310 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1d2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1500%2B",
//     mfgPart: "HFCN-1500+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1D2-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1600, 5500, 2, 1500, 1310, 1160],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1600+.pdf",
//     description: "F=1.65G-5G IL=2dB 20dBRej@1250 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1d3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1600%2B",
//     mfgPart: "HFCN-1600+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1D3-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1650, 5000, 2, 1450, 1250, 1130],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1760+.pdf",
//     description: "F=1.9G-5.5G IL=2dB 20dBRej@1250 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1d4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1760%2B",
//     mfgPart: "HFCN-1760+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1D4-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1900, 5500, 2, 1560, 1250, 950],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1810+.pdf",
//     description: "F=1.95G-4.75G IL=2dB 20dBRej@1560 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1d5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1810%2B",
//     mfgPart: "HFCN-1810+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1D5-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1950, 4750, 2, 1750, 1560, 1410],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1910+.pdf",
//     description: "F=2G-5.2G IL=2.0dB 20dBRej@1440 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1d6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1910%2B",
//     mfgPart: "HFCN-1910+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1D6-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [2000, 5200, 2, 1750, 1440, 1020],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-2000.pdf",
//     description: "F=2.26G-6.25G IL=2dB 20dBRej@1530 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1d7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-2000%2B",
//     mfgPart: "HFCN-2000+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1D7-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [2260, 6250, 2, 1860, 1530, 1280],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-2100+.pdf",
//     description: "F=2.2G-6G IL=2dB 20dBRej@1600 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1d8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-2100%2B",
//     mfgPart: "HFCN-2100+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1D8-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [2200, 6000, 2, 2010, 1600, 1240],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-2275+.pdf",
//     description: "F=2.45G-7G IL=2dB 20dBRej@1770 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1d9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-2275%2B",
//     mfgPart: "HFCN-2275+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1D9-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [2450, 7000, 2, 2160, 1770, 1420],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-2700+.pdf",
//     description: "F=2.65G-6.5G IL=2dB 20dBRej@1900 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1e1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-2700%2B",
//     mfgPart: "HFCN-2700+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1E1-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [2650, 6500, 2, 2400, 1900, 1570],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-3800+.pdf",
//     description: "F=4.25G-10G IL=2dB 20dBRej@3300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1e2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-3800%2B",
//     mfgPart: "HFCN-3800+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1E2-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [4250, 10000, 2, 3670, 3300, 2300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-5500+.pdf",
//     description: "F=6G-11.5G IL=2dB 20dBRej@5100 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1e3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-5500%2B",
//     mfgPart: "HFCN-5500+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1E3-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [6000, 11500, 2, 5450, 5100, 3500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-8400+.pdf",
//     description: "F=9G-13G IL=2dB 20dBRej@7700 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1e4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-8400%2B",
//     mfgPart: "HFCN-8400+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1E4-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [9000, 13000, 2, 8180, 7700, 5200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-2700A+.pdf",
//     description: "F=2.9G-8.7G IL=2dB 20dBRej@2300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1e5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-2700A%2B",
//     mfgPart: "HFCN-2700A+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1E5-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [2900, 8700, 2, 2580, 2300, 2160],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-2700AD+.pdf",
//     description: "F=2.9G-8.7G IL=2dB 20dBRej@2320 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1e6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-2700AD%2B",
//     mfgPart: "HFCN-2700AD+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1E6-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [2900, 8700, 2, 2600, 2320, 2160],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-3100+.pdf",
//     description: "F=3.4G-9.9G IL=2dB 20dBRej@2630 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1e7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-3100%2B",
//     mfgPart: "HFCN-3100+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1E7-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [3400, 9900, 2, 3000, 2630, 1930],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-3500+.pdf",
//     description: "F=3.9G-9.8G IL=2dB 20dBRej@3050 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1e8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-3500%2B",
//     mfgPart: "HFCN-3500+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1E8-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [3900, 9800, 2, 3400, 3050, 2100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-4400+.pdf",
//     description: "F=5G-10.1G IL=2dB 20dBRej@4050 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1e9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-4400%2B",
//     mfgPart: "HFCN-4400+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1E9-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [5000, 10100, 2, 4485, 4050, 2650],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-4600+.pdf",
//     description: "F=5G-11G IL=2dB 20dBRej@4080 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1f1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-4600%2B",
//     mfgPart: "HFCN-4600+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1F1-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [5000, 11000, 2, 4485, 4080, 2825],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-5050+.pdf",
//     description: "F=5.5G-10G IL=2dB 20dBRej@4700 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1f2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-5050%2B",
//     mfgPart: "HFCN-5050+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1F2-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [5500, 10000, 2, 5100, 4700, 3275],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-6010+.pdf",
//     description: "F=6.3G-15G IL=2dB 20dBRej@5500 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1f3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-6010%2B",
//     mfgPart: "HFCN-6010+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1F3-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [6300, 15000, 2, 5900, 5500, 3885],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-7150+.pdf",
//     description: "F=7.9G-11G IL=2dB 20dBRej@6630 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1f4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-7150%2B",
//     mfgPart: "HFCN-7150+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1F4-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [7900, 11000, 2, 7050, 6630, 4460],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-23+.pdf",
//     description: "F=2.01G-10.1G IL=1.2dB 20dBRej@1080 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3h2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-23%2B",
//     mfgPart: "XHF-23+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3H2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3H2-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [2010, 10100, 1.2, 1530, 1080, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-252+.pdf",
//     description: "F=2.46G-10.4G IL=1.0dB 20dBRej@1370 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3h3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-252%2B",
//     mfgPart: "XHF-252+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3H3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3H3-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [2460, 10400, 1, 1900, 1370, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-392+.pdf",
//     description: "F=3.94G-11.5G IL=1.3dB 20dBRej@2240 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3h4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-392%2B",
//     mfgPart: "XHF-392+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3H4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3H4-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [3940, 11500, 1.3, 3070, 2240, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1080+.pdf",
//     description: "F=1.14G-4.24G IL=2dB 20dBRej@900 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6u2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1080%2B",
//     mfgPart: "HFCN-1080+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6U2-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1140, 4240, 2, 1100, 900, 730],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1150+.pdf",
//     description: "F=1.22G-4.5G IL=2.0dB 20dBRej@850 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6u3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1150%2B",
//     mfgPart: "HFCN-1150+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6U3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6U3-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1220, 4500, 2, 1100, 850, 750],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1200D+.pdf",
//     description: "F=1.22G-4.6G IL=2dB 20dBRej@950 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6u4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1200D%2B",
//     mfgPart: "HFCN-1200D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6U4-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1220, 4600, 2, 1100, 950, 810],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1300+.pdf",
//     description: "F=1.4G-5G IL=2dB 20dBRej@960 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6u5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1300%2B",
//     mfgPart: "HFCN-1300+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6U5-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1400, 5000, 2, 1140, 960, 820],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1300D+.pdf",
//     description: "F=1.4G-5G IL=2dB 20dBRej@1000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6u6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1300D%2B",
//     mfgPart: "HFCN-1300D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6U6-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1400, 5000, 2, 1250, 1000, 750],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1320D+.pdf",
//     description: "F=1.4G-5G IL=2dB 20dBRej@1100 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6u7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1320D%2B",
//     mfgPart: "HFCN-1320D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6U7-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1400, 5000, 2, 1250, 1100, 950],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1322+.pdf",
//     description: "F=14.3G-18.5G IL=1.5dB 20dBRej@12000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6u8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1322%2B",
//     mfgPart: "HFCN-1322+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6U8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6U8-0204D",
//     price: 6600,
//     priceType: "cents",
//     props: [14300, 18500, 1.5, 13000, 12000, 260],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0029_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1500D+.pdf",
//     description: "F=1.6G-5.5G IL=2dB 20dBRej@1310 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6u9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1500D%2B",
//     mfgPart: "HFCN-1500D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6U9-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1600, 5500, 2, 1500, 1310, 1160],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1600D+.pdf",
//     description: "F=1.65G-5G IL=2dB 20dBRej@1250 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6v1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1600D%2B",
//     mfgPart: "HFCN-1600D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6V1-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [1650, 5000, 2, 1450, 1250, 1130],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1810D+.pdf",
//     description: "F=1.95G-4.75G IL=2.0dB 20dBRej@1560 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6v2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1810D%2B",
//     mfgPart: "HFCN-1810D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6V2-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [1950, 4750, 2, 1750, 1560, 1410],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-1910D+.pdf",
//     description: "F=2G-5.2G IL=2dB 20dBRej@1440 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6v3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-1910D%2B",
//     mfgPart: "HFCN-1910D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6V3-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2000, 5200, 2, 1750, 1440, 1020],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-2100D+.pdf",
//     description: "F=2.2G-6G IL=2dB 20dBRej@1600 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6v4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-2100D%2B",
//     mfgPart: "HFCN-2100D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6V4-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [2200, 6000, 2, 2010, 1600, 1240],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-3100D+.pdf",
//     description: "F=3.4G-9.9G IL=2dB 20dBRej@2580 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6v5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-3100D%2B",
//     mfgPart: "HFCN-3100D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6V5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6V5-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [3400, 9900, 2, 2900, 2580, 2430],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-3500D+.pdf",
//     description: "F=3.9G-9.8G IL=2dB 20dBRej@3050 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6v6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-3500D%2B",
//     mfgPart: "HFCN-3500D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6V6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6V6-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [3900, 9800, 2, 3400, 3050, 2100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-3800D+.pdf",
//     description: "F=4.25G-10G IL=2dB 20dBRej@3300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6v7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-3800D%2B",
//     mfgPart: "HFCN-3800D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6V7-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [4250, 10000, 2, 3670, 3300, 2300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-4400D+.pdf",
//     description: "F=5G-10.1G IL=2dB 20dBRej@4050 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6v8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-4400D%2B",
//     mfgPart: "HFCN-4400D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6V8-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [5000, 10100, 2, 4485, 4050, 2650],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-5500D+.pdf",
//     description: "F=6G-11.5G IL=2dB 20dBRej@5100 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6v9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-5500D%2B",
//     mfgPart: "HFCN-5500D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6V9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6V9-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [6000, 11500, 2, 5450, 5100, 3500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-650D+.pdf",
//     description: "F=710M-2.49G IL=2dB 20dBRej@500 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6w1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-650D%2B",
//     mfgPart: "HFCN-650D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6W1-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [710, 2490, 2, 625, 500, 400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-740D+.pdf",
//     description: "F=780M-2.8G IL=2dB 20dBRej@540 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6w2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-740D%2B",
//     mfgPart: "HFCN-740D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6W2-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [780, 2800, 2, 670, 540, 440],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0027",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-8400D+.pdf",
//     description: "F=9G-13G IL=2dB 20dBRej@7700 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0027I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6w3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-8400D%2B",
//     mfgPart: "HFCN-8400D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6W3-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [9000, 13000, 2, 8180, 7700, 5200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0027_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-880D+.pdf",
//     description: "F=950M-3.2G IL=2dB 20dBRej@640 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6w4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-880D%2B",
//     mfgPart: "HFCN-880D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6W4-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [950, 3200, 2, 800, 640, 470],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0614",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Highpass-Filters/H140XHXS_Datasheet.pdf.aspx?lang=en-US",
//     description: "F=14.5G-28G IL=1.5dB 20dBRej@11600 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7b3-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Highpass-Filters.aspx",
//     mfgPart: "H140XHXSSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7B3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7B3-0604D",
//     price: 28700,
//     priceType: "cents",
//     props: [14500, 28000, 1.5, 13000, 11600, 9000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF2-1162+.pdf",
//     description: "F=11.6G-30G IL=1.0dB 20dBRej@8250 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7j7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF2-1162%2B",
//     mfgPart: "XHF2-1162+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7J7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7J7-0204D",
//     price: 7700,
//     priceType: "cents",
//     props: [11600, 30000, 1, 10040, 8250, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0614",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Highpass-Filters/H100XHXS_Datasheet.pdf?lang=en-US",
//     description: "F=10.5G-23G IL=.75dB 20dBRej@7650 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7k5-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Highpass-Filters.aspx",
//     mfgPart: "H100XHXSSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7K5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7K5-0604D",
//     price: 26800,
//     priceType: "cents",
//     props: [10500, 23000, 0.75, 9500, 7650, 4500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF2-1832+.pdf",
//     description: "F=18.3G-30G IL=1.3dB 20dBRej@9900 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7k9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF2-1832%2B",
//     mfgPart: "XHF2-1832+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7K9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7K9-0204D",
//     price: 8000,
//     priceType: "cents",
//     props: [18300, 30000, 1.3, 16500, 9900, 8700],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0661",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Highpass-Filters/H060XHXS_Datasheet.pdf.aspx?lang=en-US",
//     description: "F=6.5G-19.5G IL=.75dB 20dBRej@4600 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0661A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7l4-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Highpass-Filters.aspx",
//     mfgPart: "H060XHXSSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7L4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7L4-0604D",
//     price: 28700,
//     priceType: "cents",
//     props: [6500, 19500, 0.75, 5775, 4600, 2500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0661_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF2-1352+.pdf",
//     description: "F=13.5G-30G IL=1.3dB 20dBRej@7720 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7r1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF2-1352%2B",
//     mfgPart: "XHF2-1352+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7R1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7R1-0204D",
//     price: 8000,
//     priceType: "cents",
//     props: [13500, 30000, 1.3, 11900, 7720, 6750],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0614",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Highpass-Filters/H182XHXS_Datasheet.pdf.aspx?lang=en-US",
//     description: "F=18.75G-28G IL=1.0dB 20dBRej@16100 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0661A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7v9-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Highpass-Filters.aspx",
//     mfgPart: "H182XHXSSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7V9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7V9-0604D",
//     price: 28700,
//     priceType: "cents",
//     props: [18750, 28000, 1, 18000, 16100, 12670],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0614",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Highpass-Filters/H160XHXS_Datasheet.pdf.aspx?lang=en-US",
//     description: "F=16.5G-32.5G IL=1.0dB 20dBRej@13900 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0661A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7y3-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Highpass-Filters.aspx",
//     mfgPart: "H160XHXSSMT",
//     models: "this part is missing models",
//     pn: "XM-A7Y3-0604D",
//     price: 29000,
//     priceType: "cents",
//     props: [16500, 32500, 1, 15400, 13900, 10930],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0614",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Highpass-Filters/H120XHXS_Datasheet.pdf.aspx?lang=en-US",
//     description: "F=12.5G-30G IL=1.0dB 20dBRej@10000 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7y4-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Highpass-Filters.aspx",
//     mfgPart: "H120XHXSSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7Y4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7Y4-0604D",
//     price: 33300,
//     priceType: "cents",
//     props: [12500, 30000, 1, 11500, 10000, 7850],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0661",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Highpass-Filters/H080XHXS_Datasheet.pdf.aspx?lang=en-US",
//     description: "F=8.5G-22G IL=.75dB 20dBRej@6000 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0661A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7y6-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Highpass-Filters.aspx",
//     mfgPart: "H080XHXSSMT",
//     models: "this part is missing models",
//     pn: "XM-A7Y6-0604D",
//     price: 26600,
//     priceType: "cents",
//     props: [8500, 22000, 0.75, 7730, 6000, 3550],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0661_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_HP-Filters.pdf#page=3",
//     description: "F=740M-1.47G 20dBRej@540 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9m7-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-high-pass-filter/",
//     mfgPart: "HF0DA0740A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9M7-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [740, 1470, null, 650, 540, 520],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_HP-Filters.pdf#page=4",
//     description: "F=850M-1.94G IL=.77dB 20dBRej@640 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9m8-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-high-pass-filter/",
//     mfgPart: "HF0BA0850A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9M8-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [850, 1940, 0.77, 770, 640, 630],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_HP-Filters.pdf#page=5",
//     description: "F=930M-1.62G IL=.82dB 20dBRej@690 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9m9-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-high-pass-filter/",
//     mfgPart: "HF0BA0930A700SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9M9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9M9-0404D",
//     price: 11100,
//     priceType: "cents",
//     props: [930, 1620, 0.82, 820, 690, 680],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_HP-Filters.pdf#page=6",
//     description: "F=950M-2G 20dBRej@710 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9n1-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-high-pass-filter/",
//     mfgPart: "HF0BA0950A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9N1-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [950, 2000, null, 850, 710, 650],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_HP-Filters.pdf#page=7",
//     description: "F=1.44G-2.7G 20dBRej@1090 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9n2-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-high-pass-filter/",
//     mfgPart: "HF0BA1440A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9N2-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [1440, 2700, null, 1300, 1090, 1060],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_HP-Filters.pdf#page=8",
//     description: "F=1.5G-2.83G 20dBRej@1170 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9n3-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-high-pass-filter/",
//     mfgPart: "HF0BA1500A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9N3-0404D",
//     price: 11100,
//     priceType: "cents",
//     props: [1500, 2830, null, 1380, 1170, 1150],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_HP-Filters.pdf#page=9",
//     description: "F=1.54G-2.68G 20dBRej@1180 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9n4-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-high-pass-filter/",
//     mfgPart: "HF0BA1540A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9N4-0404D",
//     price: 11100,
//     priceType: "cents",
//     props: [1540, 2680, null, 1390, 1180, 1100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_HP-Filters.pdf#page=10",
//     description: "F=1.55G-2.93G 20dBRej@1190 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9n5-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-high-pass-filter/",
//     mfgPart: "HF0BA1550A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9N5-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [1550, 2930, null, 1410, 1190, 1100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_HP-Filters.pdf#page=11",
//     description: "F=1.84G-2.83G 20dBRej@1430 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9n6-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-high-pass-filter/",
//     mfgPart: "HF0BA1840A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9N6-0404D",
//     price: 8300,
//     priceType: "cents",
//     props: [1840, 2830, null, 1660, 1430, 1400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF2-153+.pdf",
//     description: "F=15.3G-30G IL=0.7dB 20dBRej@11200 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9t7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF2-153%2B",
//     mfgPart: "XHF2-153+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9T7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9T7-0204D",
//     price: 7700,
//     priceType: "cents",
//     props: [15300, 30000, 0.7, 13500, 11200, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0372",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF2-912+.pdf",
//     description: "F=9.1G-30G IL=1.4dB 20dBRej@6870 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0372D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9t8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF2-912%2B",
//     mfgPart: "XHF2-912+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9T8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9T8-0204D",
//     price: 7700,
//     priceType: "cents",
//     props: [9100, 30000, 1.4, 7900, 6870, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0372_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1406",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.minicircuits.com/pdfs/HFTC-9R5.pdf",
//     description: "F=1.3G-3.5G IL=1dB 20dBRej@750 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2l4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFTC-9R5%2B",
//     mfgPart: "HFTC-9R5+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2L4-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [1300, 3500, 1, 890, 750, 570],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1406",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.minicircuits.com/pdfs/HFTC-16.pdf",
//     description: "F=1.9G-2.7G IL=1dB 20dBRej@1300 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2l5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFTC-16%2B",
//     mfgPart: "HFTC-16+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2L5-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [1900, 2700, 1, 1520, 1300, 990],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1406",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.minicircuits.com/pdfs/HFTC-19.pdf",
//     description: "F=2.3G-5.5G IL=1dB 20dBRej@1650 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2l6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFTC-19%2B",
//     mfgPart: "HFTC-19+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2L6-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [2300, 5500, 1, 1900, 1650, 1450],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1406",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.minicircuits.com/pdfs/HFTC-26.pdf",
//     description: "F=3G-7G IL=1dB 20dBRej@2000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2l7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFTC-26%2B",
//     mfgPart: "HFTC-26+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2L7-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [3000, 7000, 1, 2370, 2000, 1450],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1451",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-63M+.pdf",
//     description: "F=5.9G-19G IL=0.8dB 20dBRej@4200 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1451A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5c6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/MCLStore/ModelSearch?model=XHF-63M%2B",
//     mfgPart: "XHF-63M+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5C6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5C6-0204D",
//     price: 8200,
//     priceType: "cents",
//     props: [5900, 19000, 0.8, 4650, 4200, 4080],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "1451_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0615",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCV-145+.pdf",
//     description: "F=140M-1.15G IL=1.5dB 20dBRej@95 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0615A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7u7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCV-145%2B",
//     mfgPart: "HFCV-145+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7U7-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [140, 1150, 1.5, 120, 95, 80],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0615_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-44+.pdf",
//     description: "F=95M-2.4G IL=0.3dB 20dBRej@32 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7u8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-44%2B",
//     mfgPart: "RHP-44+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7U8-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [95, 2400, 0.3, 41, 32, 25],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-65+.pdf",
//     description: "F=130M-2G IL=0.4dB 20dBRej@50 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7u9-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-65%2B",
//     mfgPart: "RHP-65+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7U9-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [130, 2000, 0.4, 62, 50, 39],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-73+.pdf",
//     description: "F=140M-2G IL=0.3dB 20dBRej@59 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7v1-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-73%2B",
//     mfgPart: "RHP-73+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7V1-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [140, 2000, 0.3, 70, 59, 45],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-92+.pdf",
//     description: "F=160M-2.5G IL=0.4dB 20dBRej@72 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7v2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-92%2B",
//     mfgPart: "RHP-92+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7V2-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [160, 2500, 0.4, 90, 72, 55],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-110+.pdf",
//     description: "F=185M-2.5G IL=0.3dB 20dBRej@84 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7v3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-110%2B",
//     mfgPart: "RHP-110+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7V3-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [185, 2500, 0.3, 101, 84, 62],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-122+.pdf",
//     description: "F=200M-3G IL=0.4dB 20dBRej@92 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7v4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-122%2B",
//     mfgPart: "RHP-122+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7V4-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [200, 3000, 0.4, 110, 92, 70],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-139+.pdf",
//     description: "F=225M-3G IL=0.5dB 20dBRej@105 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7v5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-139%2B",
//     mfgPart: "RHP-139+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7V5-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [225, 3000, 0.5, 130, 105, 80],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-147+.pdf",
//     description: "F=250M-3G IL=0.5dB 20dBRej@105 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7v6-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-147%2B",
//     mfgPart: "RHP-147+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7V6-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [250, 3000, 0.5, 140, 105, 84],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-180+.pdf",
//     description: "F=300M-3G IL=0.5dB 20dBRej@140 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7v7-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-180%2B",
//     mfgPart: "RHP-180+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7V7-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [300, 3000, 0.5, 170, 140, 110],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-225+.pdf",
//     description: "F=360M-3G IL=0.3dB 20dBRej@174 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7v8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-225%2B",
//     mfgPart: "RHP-225+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7V8-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [360, 3000, 0.3, 215, 174, 130],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-250+.pdf",
//     description: "F=400M-3G IL=0.4dB 20dBRej@190 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7v9-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-250%2B",
//     mfgPart: "RHP-250+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7V9-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [400, 3000, 0.4, 240, 190, 145],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-260+.pdf",
//     description: "F=300M-2.2G IL=0.5dB 20dBRej@200 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7w1-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-260%2B",
//     mfgPart: "RHP-260+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7W1-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [300, 2200, 0.5, 250, 200, 160],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-290+.pdf",
//     description: "F=430M-3.1G IL=0.5dB 20dBRej@225 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7w2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-290%2B",
//     mfgPart: "RHP-290+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7W2-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [430, 3100, 0.5, 280, 225, 170],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-305+.pdf",
//     description: "F=420M-3.2G IL=0.4dB 20dBRej@234 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7w3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-305%2B",
//     mfgPart: "RHP-305+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7W3-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [420, 3200, 0.4, 290, 234, 175],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-395+.pdf",
//     description: "F=650M-2.75G IL=0.5dB 20dBRej@305 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7w4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-395%2B",
//     mfgPart: "RHP-395+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7W4-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [650, 2750, 0.5, 375, 305, 230],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RHP-755+.pdf",
//     description: "F=1.2G-3.4G IL=0.5dB 20dBRej@605 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7w5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RHP-755%2B",
//     mfgPart: "RHP-755+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7W5-0604D",
//     price: 10300,
//     priceType: "cents",
//     props: [1200, 3400, 0.5, 750, 605, 445],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1451",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-14M+.pdf",
//     description: "F=9.9G-20G IL=1.0dB 20dBRej@7700 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1451A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7w6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-14M%2B",
//     mfgPart: "XHF-14M+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7W6-0204D",
//     price: 8200,
//     priceType: "cents",
//     props: [9900, 20000, 1, 8500, 7700, 7400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "1451_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1451",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-143M+.pdf",
//     description: "F=13.9G-19G IL=1dB 20dBRej@9650 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1451A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7w7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-143M%2B",
//     mfgPart: "XHF-143M+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7W7-0204D",
//     price: 7700,
//     priceType: "cents",
//     props: [13900, 19000, 1, 10700, 9650, 9200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "1451_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0814",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-53H+.pdf",
//     description: "F=5G-11G IL=1.1dB 20dBRej@3300 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0814A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9c8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-53H%2B",
//     mfgPart: "XHF-53H+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9C8-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [5000, 11000, 1.1, 3770, 3300, 3150],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0814_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0814",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-292M+.pdf",
//     description: "F=2.9G-8.7G IL=0.7dB 20dBRej@1996 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0814A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9c9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-292M%2B",
//     mfgPart: "XHF-292M+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9C9-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [2900, 8700, 0.7, 2200, 1996, 1915],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0814_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-482M+.pdf",
//     description: "F=4.8G-19.4G IL=1.3dB 20dBRej@3590 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9d1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-482M%2B",
//     mfgPart: "XHF-482M+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9D1-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [4800, 19400, 1.3, 4100, 3590, 3450],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1409",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-581M+.pdf",
//     description: "F=580M-3G IL=0.6dB 20dBRej@335 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1409A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9d2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-581M%2B",
//     mfgPart: "XHF-581M+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9D2-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [580, 3000, 0.6, 400, 335, 150],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "1409_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-652M+.pdf",
//     description: "F=6.6G-16.2G IL=1.5dB 20dBRej@5080 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9d3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-652M%2B",
//     mfgPart: "XHF-652M+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9D3-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [6600, 16200, 1.5, 5750, 5080, 3775],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1409",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-721M+.pdf",
//     description: "F=700M-5G IL=0.7dB 20dBRej@450 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1409A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9d4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-721M%2B",
//     mfgPart: "XHF-721M+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9D4-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [700, 5000, 0.7, 530, 450, 200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "1409_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1247",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/THP-700+.pdf",
//     description: "F=700M-4G IL=0.5dB 20dBRej@430 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1247A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9e6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=THP-700%2B",
//     mfgPart: "THP-700+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9E6-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [700, 4000, 0.5, 530, 430, 350],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1247",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/THP-825+.pdf",
//     description: "F=825M-4G IL=0.6dB 20dBRej@510 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1247A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9e7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=THP-825%2B",
//     mfgPart: "THP-825+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9E7-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [825, 4000, 0.6, 635, 510, 430],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1247",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/THP-1225+.pdf",
//     description: "F=12.25G-4G IL=0.5dB 20dBRej@785 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1247A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9e8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=THP-1225%2B",
//     mfgPart: "THP-1225+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9E8-0404D",
//     price: 9100,
//     priceType: "cents",
//     props: [12250, 4000, 0.5, 960, 785, 640],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1247",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/THP-1500+.pdf",
//     description: "F=1.5G-4G IL=0.7dB 20dBRej@1310 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1247A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9e9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=THP-1500%2B",
//     mfgPart: "THP-1500+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9E9-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [1500, 4000, 0.7, 1530, 1310, 1170],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XHF-73M+.pdf",
//     description: "F=7G-16.4G IL=1.5dB 20dBRej@5400 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c386-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XHF-73M%2B",
//     mfgPart: "XHF-73M+SMT",
//     models: "this part is missing models",
//     pn: "XM-C386-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [7000, 16400, 1.5, 6010, 5400, 5160],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1908",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/HFCN-9700+.pdf",
//     description: "F=9.7G-16.98G IL=.8dB 20dBRej@7830 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c556-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCN-9700%2B",
//     mfgPart: "HFCN-9700+SMT",
//     models: "this part is missing models",
//     pn: "XM-C556-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [9700, 16980, 0.8, 8990, 7830, 6600],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0614",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Highpass-Filters/H168XHXS_Datasheet.pdf",
//     description: "F=17.5G-27G IL=1.5dB 20dBRej@15000 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7t1-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Highpass-Filters.aspx",
//     mfgPart: "H168XHXSSMT",
//     models: "this part is missing models",
//     pn: "XM-C7T1-0604D",
//     price: 36900,
//     priceType: "cents",
//     props: [17500, 27000, 1.5, 16500, 15000, 12000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXHP-2%2B",
//     mfgPart: "SXHP-2+",
//     models: "this is the new part",
//     pn: "XM-D143-0804D",
//     price: 11100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "0982_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCG-1500%2B",
//     mfgPart: "HFCG-1500+",
//     models: "this is the new part",
//     pn: "XM-C8Y5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "2328_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "HPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=HFCG-3000%2B",
//     mfgPart: "HFCG-3000+",
//     models: "this is the new part",
//     pn: "XM-D2B2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "High Pass Filters",
//     newBRDImage: "2328_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-80+.pdf",
//     description: "F=DC-80M IL=1.0dB 20dBRej@200 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a182-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-80%2B",
//     mfgPart: "LFCN-80+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A182D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A182-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [0, 80, null, 1, 145, 200, 225],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-105+.pdf",
//     description: "F=DC-105M IL=1.0dB 20dBRej@250 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a183-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-105%2B",
//     mfgPart: "LFCN-105+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A183D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A183-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [0, 105, null, 1, 180, 250, 265],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-113+.pdf",
//     description: "F=DC-11G IL=2.5dB 20dBRej@14000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a184-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-113%2B",
//     mfgPart: "LFCN-113+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A184D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A184-0204D",
//     price: 5600,
//     priceType: "cents",
//     props: [0, 11000, null, 2.5, 12250, 14000, 14500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-123+.pdf",
//     description: "F=DC-12G IL=2.5dB 20dBRej@15000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a185-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-123%2B",
//     mfgPart: "LFCN-123+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A185D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A185-0204D",
//     price: 5600,
//     priceType: "cents",
//     props: [0, 12000, null, 2.5, 13000, 15000, 15500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-160+.pdf",
//     description: "F=DC-160M IL=1.0dB 20dBRej@330 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a186-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-160%2B",
//     mfgPart: "LFCN-160+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A186D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A186-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 160, null, 1, 230, 330, 480],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-180+.pdf",
//     description: "F=DC-180M IL=1.0dB 20dBRej@370 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a187-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-180%2B",
//     mfgPart: "LFCN-180+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A187D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A187-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 180, null, 1, 270, 370, 525],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2000D+.pdf",
//     description: "F=DC-2G IL=1.5dB 20dBRej@3000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a188-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-225%2B",
//     mfgPart: "LFCN-225+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A188D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A188-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 2000, null, 1.5, 2275, 3000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-320+.pdf",
//     description: "F=DC-320M IL=1.0dB 20dBRej@560 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a189-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-320%2B",
//     mfgPart: "LFCN-320+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A189D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A189-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 320, null, 1, 460, 560, 640],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-400+.pdf",
//     description: "F=DC-400M IL=1.0dB 20dBRej@660 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a191-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-400%2B",
//     mfgPart: "LFCN-400+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A191D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A191-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 400, null, 1, 560, 660, 680],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-530+.pdf",
//     description: "F=DC-530M IL=1.2dB 20dBRej@820 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a192-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-530%2B",
//     mfgPart: "LFCN-530+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A192D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A192-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 530, null, 1.2, 700, 820, 945],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-630+.pdf",
//     description: "F=DC-630M IL=1.2dB 20dBRej@1000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a193-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-630%2B",
//     mfgPart: "LFCN-630+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A193D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A193-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 630, null, 1.2, 830, 1000, 1050],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-800+.pdf",
//     description: "F=DC-800M IL=1.3dB 20dBRej@1400 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a194-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-800%2B",
//     mfgPart: "LFCN-800+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A194D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A194-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 800, null, 1.3, 990, 1400, 1635],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-900+.pdf",
//     description: "F=DC-850M IL=1.3dB 20dBRej@1275 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a195-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-900%2B",
//     mfgPart: "LFCN-900+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A195D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A195-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 850, null, 1.3, 1075, 1275, 1900],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1000+.pdf",
//     description: "F=DC-1G IL=1.0dB 20dBRej@1550 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a196-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1000%2B",
//     mfgPart: "LFCN-1000+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A196D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A196-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 1000, null, 1, 1300, 1550, 1750],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1200+.pdf",
//     description: "F=DC-1.2G IL=1.0dB 20dBRej@1865 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a197-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1200%2B",
//     mfgPart: "LFCN-1200+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A197D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A197-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 1200, null, 1, 1530, 1865, 2375],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1325+.pdf",
//     description: "F=DC-1.325G IL=1.3dB 20dBRej@2100 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a198-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1325%2B",
//     mfgPart: "LFCN-1325+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A198D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A198-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 1325, null, 1.3, 1560, 2100, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1400+.pdf",
//     description: "F=DC-1.4G IL=1.0dB 20dBRej@1850 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a199-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1400%2B",
//     mfgPart: "LFCN-1400+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A199D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A199-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 1400, null, 1, 1700, 1850, 1950],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1500+.pdf",
//     description: "F=DC-1.5G IL=1.0dB 20dBRej@2100 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1a1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1500%2B",
//     mfgPart: "LFCN-1500+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1A1-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 1500, null, 1, 1825, 2100, 2500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1700+.pdf",
//     description: "F=DC-1.7G IL=1.2dB 20dBRej@2250 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1a2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1700%2B",
//     mfgPart: "LFCN-1700+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1A2-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 1700, null, 1.2, 2025, 2250, 2400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1800+.pdf",
//     description: "F=DC-1.8G IL=1.0dB 20dBRej@2425 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1a3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1800%2B",
//     mfgPart: "LFCN-1800+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1A3-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 1800, null, 1, 2125, 2425, 2900],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2000+.pdf",
//     description: "F=DC-2G IL=1.5dB 20dBRej@3000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1a4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2000%2B",
//     mfgPart: "LFCN-2000+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1A4-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 2000, null, 1.5, 2275, 3000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2250+.pdf",
//     description: "F=DC-2.2G IL=1.2dB 20dBRej@2900 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1a5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2250%2B",
//     mfgPart: "LFCN-2250+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1A5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1A5-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 2200, null, 1.2, 2575, 2900, 3400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2400+.pdf",
//     description: "F=DC-2.4G IL=1.5dB 20dBRej@3600 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1a6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2400%2B",
//     mfgPart: "LFCN-2400+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1A6-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 2400, null, 1.5, 2800, 3600, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2500+.pdf",
//     description: "F=DC-2.5G IL=1.0dB 20dBRej@3675 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1a7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2500%2B",
//     mfgPart: "LFCN-2500+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1A7-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 2500, null, 1, 3075, 3675, 3850],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2600+.pdf",
//     description: "F=DC-2.6G IL=1.2dB 20dBRej@3750 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1a8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2600%2B",
//     mfgPart: "LFCN-2600+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1A8-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 2600, null, 1.2, 3125, 3750, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2750+.pdf",
//     description: "F=DC-2.75G IL=1.0dB 20dBRej@4000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1a9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2750%2B",
//     mfgPart: "LFCN-2750+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1A9-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 2750, null, 1, 3150, 4000, 4400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2850+.pdf",
//     description: "F=DC-2.8G IL=1.5dB 20dBRej@4000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1b1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2850%2B",
//     mfgPart: "LFCN-2850+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1B1-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 2800, null, 1.5, 3300, 4000, 4100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-3000+.pdf",
//     description: "F=DC-3G IL=1.2dB 20dBRej@4550 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1b2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-3000%2B",
//     mfgPart: "LFCN-3000+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1B2-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 3000, null, 1.2, 3600, 4550, 4780],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-3400+.pdf",
//     description: "F=DC-3.4G IL=1.5dB 20dBRej@4300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1b3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-3400%2B",
//     mfgPart: "LFCN-3400+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1B3-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 3400, null, 1.5, 3950, 4300, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-3800+.pdf",
//     description: "F=DC-3.9G IL=1.5dB 20dBRej@5800 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1b4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-3800%2B",
//     mfgPart: "LFCN-3800+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1B4-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 3900, null, 1.5, 4850, 5800, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-4400+.pdf",
//     description: "F=DC-4.4G IL=1.0dB 20dBRej@6700 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1b5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-4400%2B",
//     mfgPart: "LFCN-4400+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1B5-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 4400, null, 1, 5290, 6700, 7000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-5000+.pdf",
//     description: "F=DC-5G IL=1.0dB 20dBRej@6420 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1b6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-5000%2B",
//     mfgPart: "LFCN-5000+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1B6-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 5000, null, 1, 5580, 6420, 6700],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-5850+.pdf",
//     description: "F=DC-5.85G IL=2.0dB 20dBRej@6865 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1b7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-5850%2B",
//     mfgPart: "LFCN-5850+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1B7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1B7-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 5850, null, 2, 6540, 6865, 8175],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-6000+.pdf",
//     description: "F=DC-6G IL=1.2dB 20dBRej@8500 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1b8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-6000%2B",
//     mfgPart: "LFCN-6000+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1B8-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 6000, null, 1.2, 6800, 8500, 8700],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-6700+.pdf",
//     description: "F=DC-6.7G IL=1.2dB 20dBRej@9300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1b9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-6700%2B",
//     mfgPart: "LFCN-6700+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1B9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1B9-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 6700, null, 1.2, 7600, 9300, 9500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-7200+.pdf",
//     description: "F=DC-7.2G IL=2.0dB 20dBRej@9500 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1c1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-7200%2B",
//     mfgPart: "LFCN-7200+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1C1-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 7200, null, 2, 8150, 9500, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-8400+.pdf",
//     description: "F=DC-8.4G IL=1.6dB 20dBRej@10300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1h3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-8400%2B",
//     mfgPart: "LFCN-8400+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1H3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1H3-0204D",
//     price: 5600,
//     priceType: "cents",
//     props: [0, 8400, null, 1.6, 9100, 10300, 10500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0155",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-9.7G IL=1dB 20dBRej@18700 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0155G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2b4-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "XMW LPFSMT",
//     models: "this part is missing models",
//     pn: "XM-A2B4-0404D",
//     price: 5800,
//     priceType: "cents",
//     props: [0, 9700, null, 1, 11400, 18700, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0157",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-16.6G IL=1dB 20dBRej@30000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0157G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2b5-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "XMW LPFSMT",
//     models: "this part is missing models",
//     pn: "XM-A2B5-0404D",
//     price: 5800,
//     priceType: "cents",
//     props: [0, 16600, null, 1, 21500, 30000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0156",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-14.3G IL=1dB 20dBRej@22700 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0156G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2b6-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "XMW LPFSMT",
//     models: "this part is missing models",
//     pn: "XM-A2B6-0404D",
//     price: 5800,
//     priceType: "cents",
//     props: [0, 14300, null, 1, 16250, 22700, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0167",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L254XF3S-Datasheet.pdf.aspx?lang=en-US",
//     description: "F=DC-25.4G IL=1.83dB 20dBRej@27540 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0167I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2b7-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Lowpass-Filters.aspx",
//     mfgPart: "L254XF3SSMT",
//     models: "this part is missing models",
//     pn: "XM-A2B7-0404D",
//     price: 18600,
//     priceType: "cents",
//     props: [0, 25400, null, 1.83, 25870, 27540, 33380],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0167_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0167",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L204XF4S-Datasheet.pdf.aspx?lang=en-US",
//     description: "F=DC-20.4G IL=1.81dB 20dBRej@22230 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0167I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2b8-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Lowpass-Filters.aspx",
//     mfgPart: "L204XF4SSMT",
//     models: "this part is missing models",
//     pn: "XM-A2B8-0404D",
//     price: 22900,
//     priceType: "cents",
//     props: [0, 20400, null, 1.81, 20760, 22230, 23470],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0167_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0167",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L157XG3S-Datasheet.pdf.aspx?lang=en-US",
//     description: "F=DC-15.7G IL=1.58dB 20dBRej@18020 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0167I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2b9-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Lowpass-Filters.aspx",
//     mfgPart: "L157XG3SSMT",
//     models: "this part is missing models",
//     pn: "XM-A2B9-0404D",
//     price: 18100,
//     priceType: "cents",
//     props: [0, 15700, null, 1.58, 16700, 18020, 19290],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0167_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0163",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L050XF9S-Datasheet.pdf.aspx?lang=en-US",
//     description: "F=DC-5G IL=2.02dB 20dBRej@5600 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0163H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2c1-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Lowpass-Filters.aspx",
//     mfgPart: "L050XF9SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2C1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2C1-0404D",
//     price: 31800,
//     priceType: "cents",
//     props: [0, 5000, null, 2.02, 5100, 5600, 5920],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0163_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0167",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L095XG9S-Datasheet.pdf.aspx?lang=en-US",
//     description: "F=DC-9.5G IL=2.82dB 20dBRej@10800 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0167I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2f6-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Lowpass-Filters.aspx",
//     mfgPart: "L095XG9SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2F6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2F6-0404D",
//     price: 22900,
//     priceType: "cents",
//     props: [0, 9500, null, 2.82, 9600, 10800, 11600],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0167_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0167",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L117XH4S_Data_Sheet_Apr15.pdf.aspx?lang=en-US",
//     description: "F=DC-11.7G IL=1.17dB 20dBRej@15280 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0167I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2f7-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Lowpass-Filters.aspx",
//     mfgPart: "L117XH4SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2F7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2F7-0404D",
//     price: 15400,
//     priceType: "cents",
//     props: [0, 11700, null, 1.17, 12640, 15280, 17550],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0167_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0167",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L128XH4S-Datasheet.pdf.aspx?lang=en-US",
//     description: "F=DC-12.8G IL=1.15dB 20dBRej@16000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0167I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2f8-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Lowpass-Filters.aspx",
//     mfgPart: "L128XH4SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2F8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2F8-0404D",
//     price: 15400,
//     priceType: "cents",
//     props: [0, 12800, null, 1.15, 13630, 16000, 18680],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0167_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0163",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L065XG9S-Datasheet.pdf.aspx?lang=en-US",
//     description: "F=DC-6.5G IL=2.01dB 20dBRej@7400 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0163H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2t9-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Lowpass-Filters.aspx",
//     mfgPart: "L065XG9SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2T9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2T9-0404D",
//     price: 15900,
//     priceType: "cents",
//     props: [0, 6500, null, 2.01, 6650, 7400, 7925],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0163_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-530D+.pdf",
//     description: "F=DC-5.5G IL=2.0dB 20dBRej@6550 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2w9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-5500%2B",
//     mfgPart: "LFCN-5500+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2W9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2W9-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 5500, null, 2, 6200, 6550, 18360],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-6400+.pdf",
//     description: "F=DC-6.4G IL=2.0dB 20dBRej@8300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a375-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-6400%2B",
//     mfgPart: "LFCN-6400+SMT",
//     models: "this part is missing models",
//     pn: "XM-A375-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 6400, null, 2, 7200, 8300, 8900],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-151+.pdf",
//     description: "F=DC-150M IL=1.4dB 20dBRej@3200 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3f1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-151%2B",
//     mfgPart: "XLF-151+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3F1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3F1-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [0, 150, null, 1.4, 280, 3200, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-221+.pdf",
//     description: "F=DC-220M IL=1.4dB 20dBRej@4000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3f2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-221%2B",
//     mfgPart: "XLF-221+SMT",
//     models: "this part is missing models",
//     pn: "XM-A3F2-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [0, 220, null, 1.4, 370, 4000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-421+.pdf",
//     description: "F=DC-420M IL=1.4dB 20dBRej@6500 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3f3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-421%2B",
//     mfgPart: "XLF-421+SMT",
//     models: "this part is missing models",
//     pn: "XM-A3F3-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [0, 420, null, 1.4, 610, 6500, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-551+.pdf",
//     description: "F=DC-550M IL=1.4dB 20dBRej@7400 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3f4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-551%2B",
//     mfgPart: "XLF-551+SMT",
//     models: "this part is missing models",
//     pn: "XM-A3F4-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [0, 550, null, 1.4, 770, 7400, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-861+.pdf",
//     description: "F=DC-860M IL=1.4dB 20dBRej@11000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3f5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-861%2B",
//     mfgPart: "XLF-861+SMT",
//     models: "this part is missing models",
//     pn: "XM-A3F5-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [0, 860, null, 1.4, 1150, 11000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-122+.pdf",
//     description: "F=DC-1.15G IL=1.4dB 20dBRej@12600 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3f6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-122%2B",
//     mfgPart: "XLF-122+SMT",
//     models: "this part is missing models",
//     pn: "XM-A3F6-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [0, 1150, null, 1.4, 1510, 12600, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-192+.pdf",
//     description: "F=DC-1.9G IL=1.4dB 20dBRej@15000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3f7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-192%2B",
//     mfgPart: "XLF-192+SMT",
//     models: "this part is missing models",
//     pn: "XM-A3F7-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [0, 1900, null, 1.4, 2400, 15000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-252+.pdf",
//     description: "F=DC-2.5G IL=1.3dB 20dBRej@5000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3f8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-252%2B",
//     mfgPart: "XLF-252+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3F8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3F8-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [0, 2500, null, 1.3, 3220, 5000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-332+.pdf",
//     description: "F=DC-3.25G IL=1.4dB 20dBRej@23500 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3f9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-332%2B",
//     mfgPart: "XLF-332+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3F9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3F9-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [0, 3250, null, 1.4, 4120, 23500, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-732+.pdf",
//     description: "F=DC-7.3G IL=1.1dB 20dBRej@16000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3g1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-732%2B",
//     mfgPart: "XLF-732+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3G1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3G1-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [0, 7300, null, 1.1, 9800, 16000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-982+.pdf",
//     description: "F=DC-9.8G IL=1.4dB 20dBRej@21000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3g2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-982%2B",
//     mfgPart: "XLF-982+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3G2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3G2-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [0, 9800, null, 1.4, 13100, 21000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-63+.pdf",
//     description: "F=DC-6G IL=1.3dB 20dBRej@10500 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3g3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-63%2B",
//     mfgPart: "XLF-63+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3G3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3G3-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 6000, null, 1.3, 8100, 10500, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-73+.pdf",
//     description: "F=DC-7G IL=1.1dB 20dBRej@13000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3g4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-73%2B",
//     mfgPart: "XLF-73+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3G4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3G4-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 7000, null, 1.1, 9900, 13000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-762+.pdf",
//     description: "F=DC-7.6G IL=1.1dB 20dBRej@13900 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3g5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-762%2B",
//     mfgPart: "XLF-762+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3G5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3G5-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 7600, null, 1.1, 11000, 13900, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-962+.pdf",
//     description: "F=DC-9.6G IL=1.4dB 20dBRej@15500 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3g6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-962%2B",
//     mfgPart: "XLF-962+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3G6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3G6-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 9600, null, 1.4, 12400, 15500, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-14+.pdf",
//     description: "F=DC-10G IL=1.6dB 20dBRej@17000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3g7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-14%2B",
//     mfgPart: "XLF-14+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3G7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3G7-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 10000, null, 1.6, 13200, 17000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-123+.pdf",
//     description: "F=DC-12.2G IL=1.8dB 20dBRej@19000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3g8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-123%2B",
//     mfgPart: "XLF-123+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3G8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3G8-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 12200, null, 1.8, 15000, 19000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-133+.pdf",
//     description: "F=DC-13.1G IL=2.0dB 20dBRej@20000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3g9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-133%2B",
//     mfgPart: "XLF-133+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3G9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3G9-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 13100, null, 2, 15800, 20000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-173+.pdf",
//     description: "F=DC-17G IL=2.3dB 20dBRej@24000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3h1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-173%2B",
//     mfgPart: "XLF-173+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3H1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3H1-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 17000, null, 2.3, 18000, 24000, 25000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1000D+.pdf",
//     description: "F=DC-1G IL=1.0dB 20dBRej@1600 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6l1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1000D%2B",
//     mfgPart: "LFCN-1000D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6L1-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 1000, null, 1, 1300, 1600, 1880],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-120+.pdf",
//     description: "F=DC-120M IL=1.0dB 20dBRej@280 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6l2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-120%2B",
//     mfgPart: "LFCN-120+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6L2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6L2-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [0, 120, null, 1, 195, 280, 300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1200D+.pdf",
//     description: "F=DC-1.2G IL=1.0dB 20dBRej@1865 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6l3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1200D%2B",
//     mfgPart: "LFCN-1200D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6L3-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 1200, null, 1, 1530, 1865, 2265],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1400D+.pdf",
//     description: "F=DC-1.4G IL=1.0dB 20dBRej@2100 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6l4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1400D%2B",
//     mfgPart: "LFCN-1400D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6L4-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 1400, null, 1, 1700, 2100, 3000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1450+.pdf",
//     description: "F=DC-1.45G IL=1.0dB 20dBRej@2025 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6l5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1450%2B",
//     mfgPart: "LFCN-1450+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6L5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6L5-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 1450, null, 1, 1825, 2025, 2100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1500D+.pdf",
//     description: "F=DC-1.5G IL=1.0dB 20dBRej@2100 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6l6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1500D%2B",
//     mfgPart: "LFCN-1500D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6L6-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 1500, null, 1, 1825, 2100, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1525+.pdf",
//     description: "F=DC-1.525G IL=1.2dB 20dBRej@2040 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6l7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1525%2B",
//     mfgPart: "LFCN-1525+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6L7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6L7-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 1525, null, 1.2, 1750, 2040, 3200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1525D+.pdf",
//     description: "F=DC-1.525G IL=1.2dB 20dBRej@2150 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6l8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1525D%2B",
//     mfgPart: "LFCN-1525D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6L8-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 1525, null, 1.2, 1750, 2150, 2450],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1575+.pdf",
//     description: "F=DC-1.575G IL=1.0dB 20dBRej@2175 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6l9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1575%2B",
//     mfgPart: "LFCN-1575+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6L9-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 1575, null, 1, 1875, 2175, 2500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1575D+.pdf",
//     description: "F=DC-1.575G IL=1.0dB 20dBRej@2275 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6m1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1575D%2B",
//     mfgPart: "LFCN-1575D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6M1-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 1575, null, 1, 1825, 2275, 2325],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1700D+.pdf",
//     description: "F=DC-1.7G IL=1.0dB 20dBRej@2375 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6m2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1700D%2B",
//     mfgPart: "LFCN-1700D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6M2-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 1700, null, 1, 2050, 2375, 3500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1800D+.pdf",
//     description: "F=DC-1.8G IL=1.0dB 20dBRej@2550 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6m3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1800D%2B",
//     mfgPart: "LFCN-1800D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6M3-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 1800, null, 1, 2125, 2550, 3000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1800D-1+.pdf",
//     description: "F=DC-1.8G IL=1.0dB 20dBRej@2550 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6m4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1800D-1%2B",
//     mfgPart: "LFCN-1800D-1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6M4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6M4-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 1800, null, 1, 2125, 2550, 3000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-190+.pdf",
//     description: "F=DC-190M IL=1.0dB 20dBRej@400 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6m5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-190%2B",
//     mfgPart: "LFCN-190+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6M5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6M5-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 190, null, 1, 280, 400, 510],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2000D+.pdf",
//     description: "F=DC-2G IL=1.5dB 20dBRej@3000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6m6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2000D%2B",
//     mfgPart: "LFCN-2000D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6M6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6M6-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 2000, null, 1.5, 2275, 3000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2250D+.pdf",
//     description: "F=DC-2.2G IL=1.2dB 20dBRej@2900 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6m7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2250D%2B",
//     mfgPart: "LFCN-2250D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6M7-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 2200, null, 1.2, 2575, 2900, 3000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-225D+.pdf",
//     description: "F=DC-225M IL=1.0dB 20dBRej@485 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6m8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-225D%2B",
//     mfgPart: "LFCN-225D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6M8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6M8-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 225, null, 1, 350, 485, 510],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2400D+.pdf",
//     description: "F=DC-2.4G IL=1.5dB 20dBRej@3700 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6m9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2400D%2B",
//     mfgPart: "LFCN-2400D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6M9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6M9-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 2400, null, 1.5, 2800, 3700, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2400D-1+.pdf",
//     description: "F=DC-2.4G IL=1.5dB 20dBRej@3600 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6n1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2400D-1%2B",
//     mfgPart: "LFCN-2400D-1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6N1-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 2400, null, 1.5, 2800, 3600, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2500D+.pdf",
//     description: "F=DC-2.5G IL=1.2dB 20dBRej@3675 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6n2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2500D%2B",
//     mfgPart: "LFCN-2500D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6N2-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 2500, null, 1.2, 2950, 3675, 3775],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2600D+.pdf",
//     description: "F=DC-2.6G IL=1.2dB 20dBRej@3750 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6n3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2600D%2B",
//     mfgPart: "LFCN-2600D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6N3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6N3-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 2600, null, 1.2, 3025, 3750, 3800],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2750D+.pdf",
//     description: "F=DC-2.75G IL=1.2dB 20dBRej@4000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6n4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2750D%2B",
//     mfgPart: "LFCN-2750D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6N4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6N4-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 2750, null, 1.2, 3300, 4000, 4100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2850D+.pdf",
//     description: "F=DC-2.8G IL=1.5dB 20dBRej@4000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6n5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2850D%2B",
//     mfgPart: "LFCN-2850D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6N5-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 2800, null, 1.5, 3120, 4000, 4000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-3000D+.pdf",
//     description: "F=DC-3G IL=1.2dB 20dBRej@4280 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6n6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-3000D%2B",
//     mfgPart: "LFCN-3000D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6N6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6N6-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 3000, null, 1.2, 3650, 4280, 4540],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-320D+.pdf",
//     description: "F=DC-320M IL=1.0dB 20dBRej@610 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6n7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-320D%2B",
//     mfgPart: "LFCN-320D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6N7-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 320, null, 1, 460, 610, 640],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-3400D+.pdf",
//     description: "F=DC-3.4G IL=1.5dB 20dBRej@4300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6n8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-3400D%2B",
//     mfgPart: "LFCN-3400D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6N8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6N8-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 3400, null, 1.5, 3950, 4300, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-3400D+.pdf",
//     description: "F=DC-3.4G IL=1.5dB 20dBRej@4300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6n9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-3400D-1%2B",
//     mfgPart: "LFCN-3400D-1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6N9-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 3400, null, 1.5, 3950, 4300, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-3800D+.pdf",
//     description: "F=DC-3.9G IL=1.5dB 20dBRej@5800 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6p1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-3800D%2B",
//     mfgPart: "LFCN-3800D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6P1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6P1-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 3900, null, 1.5, 4850, 5800, 6000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-400D+.pdf",
//     description: "F=DC-400M IL=1.0dB 20dBRej@700 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6p2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-400D%2B",
//     mfgPart: "LFCN-400D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6P2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6P2-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 400, null, 1, 560, 700, 725],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-4400D+.pdf",
//     description: "F=DC-4.4G IL=1.0dB 20dBRej@6700 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6p3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-4400D%2B",
//     mfgPart: "LFCN-4400D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6P3-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 4400, null, 1, 5290, 6700, 7000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-490+.pdf",
//     description: "F=DC-490M IL=1.2dB 20dBRej@800 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6p4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-490%2B",
//     mfgPart: "LFCN-490+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6P4-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 490, null, 1.2, 650, 800, 880],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-490D+.pdf",
//     description: "F=DC-490M IL=1.0dB 20dBRej@840 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6p5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-490D%2B",
//     mfgPart: "LFCN-490D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6P5-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 490, null, 1, 650, 840, 800],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-5000.pdf",
//     description: "F=DC-5G IL=1.0dB 20dBRej@6450 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6p6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-5000",
//     mfgPart: "LFCN-5000SMT",
//     models: "this part is missing models",
//     pn: "XM-A6P6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 5000, null, 1, 5580, 6450, 6730],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-5000D+.pdf",
//     description: "F=DC-5G IL=1.0dB 20dBRej@6420 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6p7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-5000D%2B",
//     mfgPart: "LFCN-5000D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6P7-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 5000, null, 1, 5580, 6420, 6850],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-530D+.pdf",
//     description: "F=DC-530M IL=1.0dB 20dBRej@870 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6p8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-530D%2B",
//     mfgPart: "LFCN-530D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6P8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6P8-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 530, null, 1, 700, 870, 945],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-5500D+.pdf",
//     description: "F=DC-5.5G IL=2.0dB 20dBRej@6550 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6p9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-5500D%2B",
//     mfgPart: "LFCN-5500D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6P9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6P9-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 5500, null, 2, 6200, 6550, 18360],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-575+.pdf",
//     description: "F=DC-575M IL=1.2dB 20dBRej@945 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6r2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-575%2B",
//     mfgPart: "LFCN-575+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6R2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6R2-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 575, null, 1.2, 770, 945, 1050],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-575D+.pdf",
//     description: "F=DC-575M IL=1.0dB 20dBRej@945 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6r3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-575D%2B",
//     mfgPart: "LFCN-575D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6R3-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 575, null, 1, 770, 945, 1050],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-5850D+.pdf",
//     description: "F=DC-5.85G IL=2.0dB 20dBRej@6865 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6r4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-5850D%2B",
//     mfgPart: "LFCN-5850D+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6R4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6R4-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 5850, null, 2, 6540, 6865, 8175],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-6000.pdf",
//     description: "F=DC-6G IL=1.2dB 20dBRej@8500 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6r5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-6000",
//     mfgPart: "LFCN-6000SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6R5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6R5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 6000, null, 1.2, 6800, 8500, 8700],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-6000D+.pdf",
//     description: "F=DC-6G IL=1.2dB 20dBRej@8500 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6r6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-6000D%2B",
//     mfgPart: "LFCN-6000D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6R6-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 6000, null, 1.2, 6800, 8500, 8700],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-630.pdf",
//     description: "F=DC-630M IL=1.2dB 20dBRej@1000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6r7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-630",
//     mfgPart: "LFCN-630SMT",
//     models: "this part is missing models",
//     pn: "XM-A6R7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 630, null, 1.2, 830, 1000, 1050],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-630D+.pdf",
//     description: "F=DC-630M IL=1.0dB 20dBRej@1000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6r8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-630D%2B",
//     mfgPart: "LFCN-630D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6R8-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 630, null, 1, 830, 1000, 1050],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-6400D+.pdf",
//     description: "F=DC-6.4G IL=2.0dB 20dBRej@8300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6r9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-6400D%2B",
//     mfgPart: "LFCN-6400D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6R9-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 6400, null, 2, 7200, 8300, 8900],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-6700.pdf",
//     description: "F=DC-6.7G IL=1.2dB 20dBRej@9300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6t1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-6700",
//     mfgPart: "LFCN-6700SMT",
//     models: "this part is missing models",
//     pn: "XM-A6T1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 6700, null, 1.2, 7600, 9300, 9500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-6700D+.pdf",
//     description: "F=DC-6.7G IL=1.2dB 20dBRej@9300 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6t2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-6700D%2B",
//     mfgPart: "LFCN-6700D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6T2-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 6700, null, 1.2, 7600, 9300, 9500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-7200D+.pdf",
//     description: "F=DC-7.2G IL=2.0dB 20dBRej@9500 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6t3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-7200D%2B",
//     mfgPart: "LFCN-7200D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6T3-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 7200, null, 2, 8150, 9500, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-722+.pdf",
//     description: "F=DC-7.2G IL=1.9dB 20dBRej@8980 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6t4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-722%2B",
//     mfgPart: "LFCN-722+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6T4-0204D",
//     price: 5600,
//     priceType: "cents",
//     props: [0, 7200, null, 1.9, 7940, 8980, 9000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-80+.pdf",
//     description: "F=DC-800M IL=1.3dB 20dBRej@1400 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6t5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-800",
//     mfgPart: "LFCN-800SMT",
//     models: "this part is missing models",
//     pn: "XM-A6T5-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 800, null, 1.3, 990, 1400, 1635],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-800D+.pdf",
//     description: "F=DC-800M IL=1.3dB 20dBRej@1400 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6t6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-800D%2B",
//     mfgPart: "LFCN-800D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6T6-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 800, null, 1.3, 990, 1400, 1635],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-900.pdf",
//     description: "F=DC-850M IL=1.3dB 20dBRej@1275 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6t7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-900",
//     mfgPart: "LFCN-900SMT",
//     models: "this part is missing models",
//     pn: "XM-A6T7-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 850, null, 1.3, 1075, 1275, 1800],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-900D+.pdf",
//     description: "F=DC-850M IL=1.3dB 20dBRej@1275 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6t8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-900D%2B",
//     mfgPart: "LFCN-900D+SMT",
//     models: "this part is missing models",
//     pn: "XM-A6T8-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 850, null, 1.3, 1075, 1275, 1350],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-900D-1+.pdf",
//     description: "F=DC-850M IL=1.3dB 20dBRej@1275 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6t9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-900D-1%2B",
//     mfgPart: "LFCN-900D-1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6T9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6T9-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 850, null, 1.3, 1075, 1275, 1350],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-95+.pdf",
//     description: "F=DC-95M IL=1.0dB 20dBRej@240 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6u1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-95%2B",
//     mfgPart: "LFCN-95+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A6U1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A6U1-0204D",
//     price: 6300,
//     priceType: "cents",
//     props: [0, 95, null, 1, 165, 240, 255],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0337",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-13G IL=nadB 20dBRej@na ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0337B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7a3-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "XMW LPFSMT",
//     models: "this part is missing models",
//     pn: "XM-A7A3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 13000, null, 0, 0, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0337_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0338",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-16G IL=nadB 20dBRej@na ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0338B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7a4-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "XMW LPFSMT",
//     models: "this part is missing models",
//     pn: "XM-A7A4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 16000, null, 0, 0, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0616",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCW-1062+.pdf",
//     description: "F=DC-10.6G IL=2.8dB 20dBRej@12000 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0616A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7b2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCW-1062%2B",
//     mfgPart: "LFCW-1062+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7B2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7B2-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 10600, null, 2.8, 10800, 12000, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0167",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L185XF4S-Datasheet.pdf.aspx?lang=en-US",
//     description: "F=DC-18G IL=1.66dB 20dBRej@19800 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0167I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7g2-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Lowpass-Filters.aspx",
//     mfgPart: "L185XF4SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7G2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7G2-0404D",
//     price: 18300,
//     priceType: "cents",
//     props: [0, 18000, null, 1.66, 18500, 19800, 21000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0167_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-312H+.pdf",
//     description: "F=DC-3.53G IL=1.4dB 20dBRej@8300 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7k8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-312H%2B",
//     mfgPart: "XLF-312H+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7K8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7K8-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 3530, null, 1.4, 6700, 8300, 9500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0616",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCW-1142+.pdf",
//     description: "F=DC-11.4G IL=2.2dB 20dBRej@12700 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0616A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7p8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCW-1142%2B",
//     mfgPart: "LFCW-1142+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7P8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7P8-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 11400, null, 2.2, 11700, 12700, 13860],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0616",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCW-133+.pdf",
//     description: "F=DC-13.25G IL=2.2dB 20dBRej@14760 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0616A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7p9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCW-133%2B",
//     mfgPart: "LFCW-133+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7P9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7P9-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 13250, null, 2.2, 13650, 14760, 15500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=3",
//     description: "F=DC-1.61G IL=.9dB 20dBRej@2220 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9j5-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA1610A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9J5-0404D",
//     price: 8100,
//     priceType: "cents",
//     props: [0, 1610, null, 0.9, 1800, 2220, 2320],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=4",
//     description: "F=DC-2.3G IL=0.9dB 20dBRej@2960 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9j6-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA2300A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9J6-0404D",
//     price: 8100,
//     priceType: "cents",
//     props: [0, 2300, null, 0.9, 2560, 2960, 3260],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=5",
//     description: "F=DC-3.16G IL=0.8dB 20dBRej@4000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9j7-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA3160A700SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9J7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9J7-0404D",
//     price: 8100,
//     priceType: "cents",
//     props: [0, 3160, null, 0.8, 3470, 4000, 4470],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=6",
//     description: "F=DC-4.37G IL=0.8dB 20dBRej@5610 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9j8-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA4370A700SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9J8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9J8-0404D",
//     price: 8100,
//     priceType: "cents",
//     props: [0, 4370, null, 0.8, 4840, 5610, 6320],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=7",
//     description: "F=DC-6.16G IL=0.9dB 20dBRej@8170 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9j9-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA6160A700SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9J9D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9J9-0404D",
//     price: 8100,
//     priceType: "cents",
//     props: [0, 6160, null, 0.9, 7090, 8170, 8820],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=8",
//     description: "F=DC-790M IL=0.9dB 20dBRej@1050 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9k1-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0BA0790A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9K1-0404D",
//     price: 11100,
//     priceType: "cents",
//     props: [0, 790, null, 0.9, 940, 1050, 1120],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=9",
//     description: "F=DC-960M IL=0.9dB 20dBRej@1280 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9k2-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0BA0960A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9K2-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [0, 960, null, 0.9, 1150, 1280, 1360],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=10",
//     description: "F=DC-1.01G IL=0.9dB 20dBRej@1360 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9k3-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0BA1010A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9K3-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [0, 1010, null, 0.9, 1220, 1360, 1440],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=11",
//     description: "F=DC-1.03G IL=0.9dB 20dBRej@1520 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9k4-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0BA1030A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9K4-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [0, 1030, null, 0.9, 1300, 1520, 1710],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=12",
//     description: "F=DC-1.22G IL=0.8dB 20dBRej@1500 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9k5-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0BA1220A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9K5-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [0, 1220, null, 0.8, 1370, 1500, 1890],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=13",
//     description: "F=DC-1.33G IL=0.9dB 20dBRej@1670 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9k6-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0BA1330A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9K6-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [0, 1330, null, 0.9, 1520, 1670, 1750],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0778",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=14",
//     description: "F=DC-1.39G IL=0.9dB 20dBRej@1730 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0778A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9k7-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0BA1390A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9K7-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [0, 1390, null, 0.9, 1570, 1730, 1840],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0778_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0780",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=15",
//     description: "F=DC-550M IL=0.9dB 20dBRej@770 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0780A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9k8-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0CA0550A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9K8-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [0, 550, null, 0.9, 670, 770, 810],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=16",
//     description: "F=DC-410M IL=0.9dB 20dBRej@550 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9k9-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA0410A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9K9-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [0, 410, null, 0.9, 490, 550, 600],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=17",
//     description: "F=DC-1.78G IL=0.9dB 20dBRej@2120 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9l1-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA1780A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9L1-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [0, 1780, null, 0.9, 1970, 2120, 2320],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=19",
//     description: "F=DC-1.81G IL=0.8dB 20dBRej@2200 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9l3-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA1810A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9L3-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [0, 1810, null, 0.8, 2040, 2200, 2480],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=20",
//     description: "F=DC-1.84G IL=0.7dB 20dBRej@2200 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9l4-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA1840A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9L4-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [0, 1840, null, 0.7, 2040, 2200, 2440],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=21",
//     description: "F=DC-1.88G IL=0.8dB 20dBRej@2190 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9l5-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA1880A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9L5-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [0, 1880, null, 0.8, 2050, 2190, 2420],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=23",
//     description: "F=DC-1.95G IL=0.8dB 20dBRej@2460 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9l7-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA1950A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9L7-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [0, 1950, null, 0.8, 2200, 2460, 2510],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=24",
//     description: "F=DC-2.1G IL=0.8dB 20dBRej@2580 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9l8-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA2100A700SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A9L8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A9L8-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [0, 2100, null, 0.8, 2370, 2580, 2720],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=25",
//     description: "F=DC-2.14G IL=0.7dB 20dBRej@2600 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9l9-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA2140A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9L9-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [0, 2140, null, 0.7, 2380, 2600, 2720],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=27",
//     description: "F=DC-2.19G IL=0.6dB 20dBRej@2680 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9m2-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA2190A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9M2-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [0, 2190, null, 0.6, 2440, 2680, 2930],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=28",
//     description: "F=DC-2.2G IL=0.8dB 20dBRej@2720 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9m3-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA2200A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9M3-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [0, 2200, null, 0.8, 2500, 2720, 2930],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=29",
//     description: "F=DC-2.21G IL=0.8dB 20dBRej@2890 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9m4-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA2210A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9M4-0404D",
//     price: 11100,
//     priceType: "cents",
//     props: [0, 2210, null, 0.8, 2600, 2890, 3250],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=30",
//     description: "F=DC-2.26G IL=0.8dB 20dBRej@2860 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9m5-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA2260A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9M5-0404D",
//     price: 16200,
//     priceType: "cents",
//     props: [0, 2260, null, 0.8, 2500, 2860, 4000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0783",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=31",
//     description: "F=DC-600M IL=1dB 20dBRej@880 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0783A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9m6-0604d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0FA0600A700SMT",
//     models: "this part is missing models",
//     pn: "XM-A9M6-0604D",
//     price: 10700,
//     priceType: "cents",
//     props: [0, 600, null, 1, 770, 880, 960],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0783_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0814",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-252H+.pdf",
//     description: "F=DC-2.5G IL=1.2dB 20dBRej@5600 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0814A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b131-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-252H%2B",
//     mfgPart: "XLF-252H+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B131D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B131-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [0, 2500, null, 1.2, 4600, 5600, 5800],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0814_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-63H+.pdf",
//     description: "F=DC-6.82G IL=1.2dB 20dBRej@14500 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b136-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-63H%2B",
//     mfgPart: "XLF-63H+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B136D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B136-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 6820, null, 1.2, 10500, 14500, 18000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0976",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-177.5M IL=1dB 20dBRej@283 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0976A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1f4-0604d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "XMW LPFSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1F4D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1F4-0604D",
//     price: 8300,
//     priceType: "cents",
//     props: [0, 177.5, null, 1, 207, 283, 421],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0976_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1165",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-92+.pdf",
//     description: "F=DC-990M IL=0.4dB 20dBRej@1600 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1165A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2f7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-92%2B",
//     mfgPart: "LFCG-92+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2F7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2F7-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 990, null, 0.4, 1400, 1600, 1750],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1165_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1014",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-612+.pdf",
//     description: "F=4.9G-6.1G IL=1.0dB 20dBRej@8200 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1014A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2f8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-612%2B",
//     mfgPart: "LFCG-612+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2F8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2F8-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [4900, 6100, null, 1, 7500, 8200, 9800],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0712-A",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFTC-850+.pdf",
//     description: "F=DC-850M IL=1dB 20dBRej@1500 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2k6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFTC-850%2B",
//     mfgPart: "LFTC-850+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2K6-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 850, null, 1, 1078, 1500, 2000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0712-A",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.minicircuits.com/pdfs/LFTC-1350.pdf",
//     description: "F=DC-1.35G IL=1dB 20dBRej@2100 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2k7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFTC-1350%2B",
//     mfgPart: "LFTC-1350+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2K7-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 1350, null, 1, 1550, 2100, 2700],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0712-A",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.minicircuits.com/pdfs/LFTC-1700.pdf",
//     description: "F=DC-1.7G IL=1dB 20dBRej@2700 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2k8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFTC-1700%2B",
//     mfgPart: "LFTC-1700+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2K8-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 1700, null, 1, 1980, 2700, 3300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0712-A",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.minicircuits.com/pdfs/LFTC-2000.pdf",
//     description: "F=DC-2G IL=1dB 20dBRej@3450 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2k9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFTC-2000%2B",
//     mfgPart: "LFTC-2000+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2K9-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 2000, null, 1, 2430, 3450, 4500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0712-A",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFTC-3300+.pdf",
//     description: "F=DC-3.3G IL=1dB 20dBRej@5600 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2l1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFTC-3300%2B",
//     mfgPart: "LFTC-3300+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2L1-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 3300, null, 1, 4100, 5600, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0712-A",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.minicircuits.com/pdfs/LFTC-4000.pdf",
//     description: "F=DC-4G IL=1dB 20dBRej@7250 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2l2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFTC-4000%2B",
//     mfgPart: "LFTC-4000+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2L2-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 4000, null, 1, 5325, 7250, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0712-A",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.minicircuits.com/pdfs/LFTC-5400.pdf",
//     description: "F=DC-5.4G IL=1dB 20dBRej@8200 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1406A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2l3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFTC-5400%2B",
//     mfgPart: "LFTC-5400+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2L3-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [0, 5400, null, 1, 6410, 8200, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1406_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-264+.pdf",
//     description: "F=DC-264M IL=2dB 20dBRej@365 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5a6-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-264%2B",
//     mfgPart: "RLP-264+SMT",
//     models: "this part is missing models",
//     pn: "XM-B5A6-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 264, null, 2, 288, 365, 425],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0982",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SXLP-10.7+.pdf",
//     description: "F=DC-11M IL=1.0dB 20dBRej@19 ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0982A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5b9-0804d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SXLP-10.7%2B",
//     mfgPart: "SXLP-10.7+SMT",
//     models: "this part is missing models",
//     pn: "XM-B5B9-0804D",
//     price: 10700,
//     priceType: "cents",
//     props: [0, 11, null, 1, 14, 19, 24],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0982_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1409",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-641M+.pdf",
//     description: "F=DC-500M IL=1.9dB 20dBRej@1100 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1409A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5d7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-641M%2B",
//     mfgPart: "XLF-641M+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5D7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5D7-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [0, 500, null, 1.9, 640, 1100, 3000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1409_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0167",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L288XC3S_Datasheet.pdf?lang=en-US",
//     description: "F=DC-28.8G IL=1.73dB 20dBRej@30500 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0167I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5k3-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Lowpass-Filters.aspx",
//     mfgPart: "L288XC3SSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5K3D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5K3-0404D",
//     price: 18700,
//     priceType: "cents",
//     props: [0, 28800, null, 1.73, 29560, 30500, 31000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0167_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0976",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-11G 20dBRej@16700 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0976A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5y5-0604d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "Lumped LPFSMT",
//     models: "this part is missing models",
//     pn: "XM-B5Y5-0604D",
//     price: 8000,
//     priceType: "cents",
//     props: [0, 11000, null, 0, 12400, 16700, 24000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0976_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1014",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-1000+.pdf",
//     description: "F=DC-1G IL=0.8dB 20dBRej@1510 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1014A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7j6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-1000%2B",
//     mfgPart: "LFCG-1000+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7J6-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 1000, null, 0.8, 1370, 1510, 1750],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1014",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-1575+.pdf",
//     description: "F=DC-1.575G IL=0.9dB 20dBRej@2175 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1014A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7j7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-1575%2B",
//     mfgPart: "LFCG-1575+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7J7-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 1575, null, 0.9, 1850, 2175, 2400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1014",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-1700+.pdf",
//     description: "F=DC-1.7G IL=0.9dB 20dBRej@2400 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1014A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7j8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-1700%2B",
//     mfgPart: "LFCG-1700+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7J8-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 1700, null, 0.9, 2025, 2400, 2500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1014",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-2250+.pdf",
//     description: "F=DC-2.25G IL=1.2dB 20dBRej@2800 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1014A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7j9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-2250%2B",
//     mfgPart: "LFCG-2250+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7J9-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 2250, null, 1.2, 2500, 2800, 3600],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1014",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-2850+.pdf",
//     description: "F=DC-2.85G IL=0.9dB 20dBRej@3800 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1014A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7k1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-2850%2B",
//     mfgPart: "LFCG-2850+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7K1-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 2850, null, 0.9, 3250, 3800, 5000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1014",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-320+.pdf",
//     description: "F=DC-320M IL=1.0dB 20dBRej@525 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1014A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7k2-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-320%2B",
//     mfgPart: "LFCG-320+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7K2-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 320, null, 1, 440, 525, 800],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1014",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-400+.pdf",
//     description: "F=DC-400M IL=1.0dB 20dBRej@635 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1014A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7k3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-400%2B",
//     mfgPart: "LFCG-400+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7K3-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 400, null, 1, 520, 635, 722],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1014",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-530+.pdf",
//     description: "F=DC-530M IL=1.0dB 20dBRej@830 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1014A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7k4-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-530%2B",
//     mfgPart: "LFCG-530+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7K4-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 530, null, 1, 670, 830, 1200],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1014",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-575+.pdf",
//     description: "F=DC-575M IL=1.0dB 20dBRej@850 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1014A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7k5-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-575%2B",
//     mfgPart: "LFCG-575+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7K5-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 575, null, 1, 725, 850, 1150],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1165",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-42+.pdf",
//     description: "F=DC-435M IL=0.9dB 20dBRej@580 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1165A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7n9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-42%2B",
//     mfgPart: "LFCG-42+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7N9-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 435, null, 0.9, 475, 580, 625],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1165_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0615",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCV-45+.pdf",
//     description: "F=DC-45M IL=1.2dB 20dBRej@120 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0615A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7w8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCV-45%2B",
//     mfgPart: "LFCV-45+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7W8-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 45, null, 1.2, 77, 120, 150],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0615_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0615",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCV-52+.pdf",
//     description: "F=DC-52M IL=1.2dB 20dBRej@140 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0615A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7w9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCV-52%2B",
//     mfgPart: "LFCV-52+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7W9-0204D",
//     price: 5900,
//     priceType: "cents",
//     props: [0, 52, null, 1.2, 93, 140, 170],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0615_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0615",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCV-1450+.pdf",
//     description: "F=DC-1.45G IL=2.2dB 20dBRej@1650 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0615A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7y1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCV-1450%2B",
//     mfgPart: "LFCV-1450+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7Y1-0204D",
//     price: 6000,
//     priceType: "cents",
//     props: [0, 1450, null, 2.2, 1500, 1650, 1800],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0615_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LPF-B0R3+.pdf",
//     description: "F=DC-0.3M IL=1.5dB 20dBRej@0.56 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7y2-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LPF-B0R3%2B",
//     mfgPart: "LPF-B0R3+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7Y2-1004D",
//     price: 11200,
//     priceType: "cents",
//     props: [0, 0.3, null, 1.5, 0.46, 0.56, 0.61],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LPF-B0R35+.pdf",
//     description: "F=DC-0.35M IL=1.5dB 20dBRej@0.7 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7y3-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LPF-B0R35%2B",
//     mfgPart: "LPF-B0R35+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7Y3-1004D",
//     price: 11200,
//     priceType: "cents",
//     props: [0, 0.35, null, 1.5, 0.57, 0.7, 0.79],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LPF-B0R5+.pdf",
//     description: "F=DC-0.5M IL=1.5dB 20dBRej@.1 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7y4-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LPF-B0R5%2B",
//     mfgPart: "LPF-B0R5+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7Y4-1004D",
//     price: 11200,
//     priceType: "cents",
//     props: [0, 0.5, null, 1.5, 0.81, 0.1, 1.12],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LPF-B0R6+.pdf",
//     description: "F=DC-0.6M IL=1.5dB 20dBRej@1.08 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7y5-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LPF-B0R6%2B",
//     mfgPart: "LPF-B0R6+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7Y5-1004D",
//     price: 11200,
//     priceType: "cents",
//     props: [0, 0.6, null, 1.5, 0.88, 1.08, 1.23],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LPF-B35+.pdf",
//     description: "F=DC-35M IL=0.85dB 20dBRej@45 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7y8-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LPF-B35%2B",
//     mfgPart: "LPF-B35+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7Y8-1004D",
//     price: 12400,
//     priceType: "cents",
//     props: [0, 35, null, 0.85, 40, 45, 47],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LPF-B50+.pdf",
//     description: "F=DC-50M IL=0.8dB 20dBRej@65 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7y9-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LPF-B50%2B",
//     mfgPart: "LPF-B50+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7Y9-1004D",
//     price: 12400,
//     priceType: "cents",
//     props: [0, 50, null, 0.8, 55, 65, 70],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LPF-B375+.pdf",
//     description: "F=DC-375M IL=1.0dB 20dBRej@420 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8a1-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LPF-B375%2B",
//     mfgPart: "LPF-B375+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8A1-1004D",
//     price: 12400,
//     priceType: "cents",
//     props: [0, 375, null, 1, 395, 420, 450],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LPF-B500+.pdf",
//     description: "F=DC-500M IL=1.2dB 20dBRej@585 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8a2-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LPF-B500%2B",
//     mfgPart: "LPF-B500+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8A2-1004D",
//     price: 12400,
//     priceType: "cents",
//     props: [0, 500, null, 1.2, 515, 585, 750],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-40+.pdf",
//     description: "F=DC-40M IL=2dB 20dBRej@70 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8a3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-40%2B",
//     mfgPart: "RLP-40+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8A3-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 40, null, 2, 56, 70, 80],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-50+.pdf",
//     description: "F=DC-50M IL=2dB 20dBRej@78 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8a4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-50%2B",
//     mfgPart: "RLP-50+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8A4-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 50, null, 2, 59, 78, 91],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-70+.pdf",
//     description: "F=DC-70M IL=2dB 20dBRej@100 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8a5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-70%2B",
//     mfgPart: "RLP-70+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8A5-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 70, null, 2, 77, 100, 115],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-83+.pdf",
//     description: "F=DC-83M IL=2dB 20dBRej@118 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8a6-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-83%2B",
//     mfgPart: "RLP-83+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8A6-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 83, null, 2, 93, 118, 135],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-105+.pdf",
//     description: "F=DC-105M IL=2dB 20dBRej@145 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8a7-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-105%2B",
//     mfgPart: "RLP-105+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8A7-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 105, null, 2, 116, 145, 165],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-120+.pdf",
//     description: "F=DC-120M IL=2dB 20dBRej@170 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8a8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-120%2B",
//     mfgPart: "RLP-120+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8A8-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 120, null, 2, 132, 170, 205],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-137+.pdf",
//     description: "F=DC-137M IL=2dB 20dBRej@190 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8a9-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-137%2B",
//     mfgPart: "RLP-137+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8A9-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 137, null, 2, 150, 190, 215],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-158+.pdf",
//     description: "F=DC-158M IL=2dB 20dBRej@220 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8b1-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-158%2B",
//     mfgPart: "RLP-158+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8B1-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 158, null, 2, 172, 220, 255],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-176+.pdf",
//     description: "F=DC-176M IL=2dB 20dBRej@245 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8b2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-176%2B",
//     mfgPart: "RLP-176+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8B2-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 176, null, 2, 191, 245, 285],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-190+.pdf",
//     description: "F=DC-190M IL=2dB 20dBRej@264 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8b3-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-190%2B",
//     mfgPart: "RLP-190+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8B3-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 190, null, 2, 220, 264, 300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-216+.pdf",
//     description: "F=DC-216M IL=2dB 20dBRej@295 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8b4-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-216%2B",
//     mfgPart: "RLP-216+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8B4-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 216, null, 2, 232, 295, 340],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-288+.pdf",
//     description: "F=DC-288M IL=2dB 20dBRej@390 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8b5-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-288%2B",
//     mfgPart: "RLP-288+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8B5-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 288, null, 2, 310, 390, 455],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-290+.pdf",
//     description: "F=DC-290M IL=1.2dB 20dBRej@350 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8b6-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-290%2B",
//     mfgPart: "RLP-290+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8B6-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 290, null, 1.2, 310, 350, 370],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-320+.pdf",
//     description: "F=DC-320M IL=2dB 20dBRej@440 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8b7-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-320%2B",
//     mfgPart: "RLP-320+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8B7-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 320, null, 2, 345, 440, 510],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-340+.pdf",
//     description: "F=DC-340M IL=2dB 20dBRej@475 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8b8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-340%2B",
//     mfgPart: "RLP-340+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8B8-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 340, null, 2, 365, 475, 560],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-470+.pdf",
//     description: "F=DC-470M IL=2dB 20dBRej@650 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8b9-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-470%2B",
//     mfgPart: "RLP-470+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8B9-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 470, null, 2, 510, 650, 780],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-900+.pdf",
//     description: "F=DC-900M IL=2dB 20dBRej@1300 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8c1-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-900%2B",
//     mfgPart: "RLP-900+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8C1-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 900, null, 2, 1000, 1300, 1750],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0282",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLP-1094+.pdf",
//     description: "F=DC-1.094G IL=0.7dB 20dBRej@1700 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0282D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8c2-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLP-1094%2B",
//     mfgPart: "RLP-1094+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8C2-0604D",
//     price: 8800,
//     priceType: "cents",
//     props: [0, 1094, null, 0.7, 1380, 1700, 1900],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A6",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0282_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-362H+.pdf",
//     description: "F=DC-3.91G IL=1.3dB 20dBRej@10000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8c3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-362H%2B",
//     mfgPart: "XLF-362H+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8C3-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 3910, null, 1.3, 6300, 10000, 12000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-4.7.pdf",
//     description: "F=DC-4.7M IL=1dB 20dBRej@6.6 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b942-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-4.7%2B",
//     mfgPart: "SCLF-4.7+SMT",
//     models: "this part is missing models",
//     pn: "XM-B942-0609D",
//     price: 13200,
//     priceType: "cents",
//     props: [0, 4.7, null, 1, 5.6, 6.6, 7.6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-5+.pdf",
//     description: "F=DC-5M IL=1.0dB 20dBRej@8 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b943-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-5%2B",
//     mfgPart: "SCLF-5+SMT",
//     models: "this part is missing models",
//     pn: "XM-B943-0609D",
//     price: 14400,
//     priceType: "cents",
//     props: [0, 5, null, 1, 5.8, 8, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-8+.pdf",
//     description: "F=DC-8M IL=1.0dB 20dBRej@12.5 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b944-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-8%2B",
//     mfgPart: "SCLF-8+SMT",
//     models: "this part is missing models",
//     pn: "XM-B944-0609D",
//     price: 13900,
//     priceType: "cents",
//     props: [0, 8, null, 1, 9.2, 12.5, 16.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-10+.pdf",
//     description: "F=DC-10M IL=1.0dB 20dBRej@13.5 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b946-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-10%2B",
//     mfgPart: "SCLF-10+SMT",
//     models: "this part is missing models",
//     pn: "XM-B946-0609D",
//     price: 13200,
//     priceType: "cents",
//     props: [0, 10, null, 1, 12.2, 13.5, 16],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-10.7.pdf",
//     description: "F=DC-11M IL=1.0dB 20dBRej@15.4 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b947-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-10.7%2B",
//     mfgPart: "SCLF-10.7+SMT",
//     models: "this part is missing models",
//     pn: "XM-B947-0609D",
//     price: 13900,
//     priceType: "cents",
//     props: [0, 11, null, 1, 14, 15.4, 20.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-21.4.pdf",
//     description: "F=DC-22M IL=1.0dB 20dBRej@32 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b948-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-21.4%2B",
//     mfgPart: "SCLF-21.4+SMT",
//     models: "this part is missing models",
//     pn: "XM-B948-0609D",
//     price: 13700,
//     priceType: "cents",
//     props: [0, 22, null, 1, 24.5, 32, 41],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-25+.pdf",
//     description: "F=DC-25M IL=1.0dB 20dBRej@36 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b951-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-25%2B",
//     mfgPart: "SCLF-25+SMT",
//     models: "this part is missing models",
//     pn: "XM-B951-0609D",
//     price: 13700,
//     priceType: "cents",
//     props: [0, 25, null, 1, 28, 36, 47],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-27+.pdf",
//     description: "F=DC-27M IL=1.0dB 20dBRej@36 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b952-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-27%2B",
//     mfgPart: "SCLF-27+SMT",
//     models: "this part is missing models",
//     pn: "XM-B952-0609D",
//     price: 13200,
//     priceType: "cents",
//     props: [0, 27, null, 1, 30, 36, 41],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-30+.pdf",
//     description: "F=DC-30M IL=1.0dB 20dBRej@47 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b953-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-30%2B",
//     mfgPart: "SCLF-30+SMT",
//     models: "this part is missing models",
//     pn: "XM-B953-0609D",
//     price: 13700,
//     priceType: "cents",
//     props: [0, 30, null, 1, 35, 47, 61],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-36+.pdf",
//     description: "F=DC-36M IL=1.0dB 20dBRej@50 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b954-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-36%2B",
//     mfgPart: "SCLF-36+SMT",
//     models: "this part is missing models",
//     pn: "XM-B954-0609D",
//     price: 13200,
//     priceType: "cents",
//     props: [0, 36, null, 1, 40, 50, 57],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-44+.pdf",
//     description: "F=DC-44M IL=1.0dB 20dBRej@59 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b955-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-44%2B",
//     mfgPart: "SCLF-44+SMT",
//     models: "this part is missing models",
//     pn: "XM-B955-0609D",
//     price: 13200,
//     priceType: "cents",
//     props: [0, 44, null, 1, 48.5, 59, 65.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-45+.pdf",
//     description: "F=DC-45M IL=1.0dB 20dBRej@70 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b956-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-45%2B",
//     mfgPart: "SCLF-45+SMT",
//     models: "this part is missing models",
//     pn: "XM-B956-0609D",
//     price: 13700,
//     priceType: "cents",
//     props: [0, 45, null, 1, 55, 70, 90],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-65+.pdf",
//     description: "F=DC-65M IL=1.0dB 20dBRej@86 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b957-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-65%2B",
//     mfgPart: "SCLF-65+SMT",
//     models: "this part is missing models",
//     pn: "XM-B957-0609D",
//     price: 13200,
//     priceType: "cents",
//     props: [0, 65, null, 1, 71, 86, 96],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-95+.pdf",
//     description: "F=DC-95M IL=1.0dB 20dBRej@146 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b958-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-95%2B",
//     mfgPart: "SCLF-95+SMT",
//     models: "this part is missing models",
//     pn: "XM-B958-0609D",
//     price: 13700,
//     priceType: "cents",
//     props: [0, 95, null, 1, 108, 146, 189],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-135.pdf",
//     description: "F=DC-135M IL=1.0dB 20dBRej@210 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b959-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-135",
//     mfgPart: "SCLF-135SMT",
//     models: "this part is missing models",
//     pn: "XM-B959-0609D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 135, null, 1, 155, 210, 300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-135.pdf",
//     description: "F=DC-135M IL=1.0dB 20dBRej@210 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b961-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-135%2B",
//     mfgPart: "SCLF-135+SMT",
//     models: "this part is missing models",
//     pn: "XM-B961-0609D",
//     price: 13700,
//     priceType: "cents",
//     props: [0, 135, null, 1, 155, 210, 300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-190+.pdf",
//     description: "F=DC-190M IL=1.0dB 20dBRej@290 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b962-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-190%2B",
//     mfgPart: "SCLF-190+SMT",
//     models: "this part is missing models",
//     pn: "XM-B962-0609D",
//     price: 13700,
//     priceType: "cents",
//     props: [0, 190, null, 1, 210, 290, 390],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-225+.pdf",
//     description: "F=DC-225M IL=1.0dB 20dBRej@340 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b963-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-225%2B",
//     mfgPart: "SCLF-225+SMT",
//     models: "this part is missing models",
//     pn: "XM-B963-0609D",
//     price: 13700,
//     priceType: "cents",
//     props: [0, 225, null, 1, 250, 340, 440],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-380.pdf",
//     description: "F=DC-380M IL=1.0dB 20dBRej@580 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b965-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-380%2B",
//     mfgPart: "SCLF-380+SMT",
//     models: "this part is missing models",
//     pn: "XM-B965-0609D",
//     price: 13700,
//     priceType: "cents",
//     props: [0, 380, null, 1, 440, 580, 750],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-420.pdf",
//     description: "F=DC-420M IL=1.0dB 20dBRej@750 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b966-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-420%2B",
//     mfgPart: "SCLF-420+SMT",
//     models: "this part is missing models",
//     pn: "XM-B966-0609D",
//     price: 13700,
//     priceType: "cents",
//     props: [0, 420, null, 1, 470, 750, 920],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-550.pdf",
//     description: "F=DC-550M IL=1.0dB 20dBRej@800 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b967-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-550%2B",
//     mfgPart: "SCLF-550+SMT",
//     models: "this part is missing models",
//     pn: "XM-B967-0609D",
//     price: 13900,
//     priceType: "cents",
//     props: [0, 550, null, 1, 605, 800, 1050],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-700+.pdf",
//     description: "F=DC-700M IL=1.0dB 20dBRej@1000 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b968-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-700%2B",
//     mfgPart: "SCLF-700+SMT",
//     models: "this part is missing models",
//     pn: "XM-B968-0609D",
//     price: 13900,
//     priceType: "cents",
//     props: [0, 700, null, 1, 770, 1000, 1300],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1332",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCLF-1000+.pdf",
//     description: "F=DC-1G IL=1.0dB 20dBRej@1620 ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1332A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b969-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCLF-1000%2B",
//     mfgPart: "SCLF-1000+SMT",
//     models: "this part is missing models",
//     pn: "XM-B969-0609D",
//     price: 13900,
//     priceType: "cents",
//     props: [0, 1000, null, 1, 1200, 1620, 2100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1332_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0814",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-13H+.pdf",
//     description: "F=DC-1G IL=1.9dB 20dBRej@1950 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0814A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9c2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-13H%2B",
//     mfgPart: "XLF-13H+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9C2-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [0, 1000, null, 1.9, 1150, 1950, 2500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0814_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1409",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-42M+.pdf",
//     description: "F=DC-300M IL=2.3dB 20dBRej@660 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1409A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9c3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-42M%2B",
//     mfgPart: "XLF-42M+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9C3-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [0, 300, null, 2.3, 350, 660, 1500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1409_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0814",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-112H+.pdf",
//     description: "F=DC-1.05G IL=1.9dB 20dBRej@2100 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0814A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9c4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-112H%2B",
//     mfgPart: "XLF-112H+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9C4-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [0, 1050, null, 1.9, 1200, 2100, 2210],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0814_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0814",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-122H+.pdf",
//     description: "F=DC-1.2G IL=1.9dB 20dBRej@2480 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0814A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9c5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-122H%2B",
//     mfgPart: "XLF-122H+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9C5-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [0, 1200, null, 1.9, 1400, 2480, 2500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0814_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0814",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-132H+.pdf",
//     description: "F=DC-1.3G IL=1.8dB 20dBRej@2900 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0814A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9c6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-132H%2B",
//     mfgPart: "XLF-132H+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9C6-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [0, 1300, null, 1.8, 1680, 2900, 3000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0814_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-662M+.pdf",
//     description: "F=DC-6G IL=1.3dB 20dBRej@8500 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9c7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-662M%2B",
//     mfgPart: "XLF-662M+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9C7-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [0, 6000, null, 1.3, 6740, 8500, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0029-J",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-9170+.pdf",
//     description: "F=DC-9.17G IL=1.0dB 20dBRej@10600 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c387-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-9170%2B",
//     mfgPart: "LFCN-9170+SMT",
//     models: "this part is missing models",
//     pn: "XM-C387-0204D",
//     price: 5600,
//     priceType: "cents",
//     props: [0, 9170, null, 1, 9800, 10600, 11040],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1430",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L117XH4W_Datasheet.pdf.aspx?lang=en-US",
//     description: "F=DC-17G IL=0.99dB 20dBRej@15660 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1430A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3r5-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Lowpass-Filters.aspx",
//     mfgPart: "L117XH4WDIE",
//     models: "this part is missing models",
//     pn: "XM-C3R5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 17000, null, 0.99, 13110, 15660, 17640],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1430_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0814",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/XLF-172H+.pdf",
//     description: "F=DC-2G IL=1.7dB 20dBRej@3600 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0814A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c566-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-172H%2B",
//     mfgPart: "XLF-172H+SMT",
//     models: "this part is missing models",
//     pn: "XM-C566-0404D",
//     price: 7800,
//     priceType: "cents",
//     props: [0, 2000, null, 1.7, 2350, 3600, 3800],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0814_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1908",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-1282+.pdf",
//     description: "F=DC-12.8G IL=1.2dB 20dBRej@16200 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0029K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c782-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-1282%2B",
//     mfgPart: "LFCN-1282+SMT",
//     models: "this part is missing models",
//     pn: "XM-C782-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 12800, null, 1.2, 13900, 16200, 15800],
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1430",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L157XF3W_Datasheet.pdf?lang=en-US",
//     description: "F=DC-15G IL=1.24dB 20dBRej@19200 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1430A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7r7-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "L157XF3WDIE",
//     models: "this part is missing models",
//     pn: "XM-C7R7-0404D",
//     price: 48600,
//     priceType: "cents",
//     props: [0, 15000, null, 1.24, 17400, 19200, 20500],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1430_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1430",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L065XG9W_Datasheet.pdf?lang=en-US",
//     description: "F=DC-6.5G IL=1.8dB 20dBRej@7400 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1430A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7r8-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "L065XG9WDIE",
//     models: "this part is missing models",
//     pn: "XM-C7R8-0404D",
//     price: 38700,
//     priceType: "cents",
//     props: [0, 6500, null, 1.8, 6700, 7400, 8000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1430_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1430",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Lowpass-Filters/L185XF4W_Datasheet.pdf?lang=en-US",
//     description: "F=DC-18.5G IL=1.88dB 20dBRej@20000 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1430A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7r9-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "L185XF4WDIE",
//     models: "this part is missing models",
//     pn: "XM-C7R9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 18500, null, 1.88, 18750, 20000, 21100],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1430_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0030",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-2290+.pdf",
//     description: "F=DC-2.29G IL=0.9dB 20dBRej@3110 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0030I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7v9-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2290%2B",
//     mfgPart: "LFCN-2290+SMT",
//     models: "this part is missing models",
//     pn: "XM-C7V9-0204D",
//     price: 5700,
//     priceType: "cents",
//     props: [0, 2290, null, 0.9, 2590, 3110, 3400],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0030_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0616",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCW-8400+.pdf",
//     description: "F=DC-8.4G IL=1.2dB 20dBRej@12200 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0616A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8g6-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCW-8400%2B",
//     mfgPart: "LFCW-8400+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8G6-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 8400, null, 1.2, 9800, 12200, 12600],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0616",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCW-5000+.pdf",
//     description: "F=DC-5G IL=1.6dB 20dBRej@7200 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0616A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8g7-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCW-5000%2B",
//     mfgPart: "LFCW-5000+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8G7-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 5000, null, 1.6, 5750, 7200, 7600],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0616",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCW-6000+.pdf",
//     description: "F=DC-6G IL=1.6dB 20dBRej@8200 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0616A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8g8-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCW-6000%2B",
//     mfgPart: "LFCW-6000+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8G8-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 6000, null, 1.6, 6800, 8200, 9000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1014",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCG-2600+.pdf",
//     description: "F=DC-2.6G IL=1.1dB 20dBRej@3450 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1014A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8h1-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-2600%2B",
//     mfgPart: "LFCG-2600+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8H1-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: [0, 2600, null, 1.1, 3000, 3450, 3850],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=12",
//     description: "F=DC-2.49G IL=.7dB 20dBRej@3980 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c918-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA2490A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C918-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: [0, 2490, null, 0.7, 2800, 3980, 3790],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=15",
//     description: "F=DC-1.68G IL=.7dB 20dBRej@2320 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c923-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA1680A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C923-0404D",
//     price: 8200,
//     priceType: "cents",
//     props: [0, 1680, null, 0.7, 2010, 2320, 2380],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=18",
//     description: "F=DC-1.59G IL=.8dB 20dBRej@2110 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c927-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA1590A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C927-0404D",
//     price: 8100,
//     priceType: "cents",
//     props: [0, 1590, null, 0.8, 1840, 2110, 2150],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0783",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=22",
//     description: "F=DC-57M IL=.9dB 20dBRej@90 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0783A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c932-0604d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0FA0057A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C932-0604D",
//     price: 10700,
//     priceType: "cents",
//     props: [0, 57, null, 0.9, 73, 90, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0783_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0782",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=23",
//     description: "F=DC-76M IL=.9dB 20dBRej@115 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0782A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c933-0604d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0EA0076A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C933-0604D",
//     price: 13100,
//     priceType: "cents",
//     props: [0, 76, null, 0.9, 96, 115, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0782_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0782",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=25",
//     description: "F=DC-82M IL=.8dB 20dBRej@125 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0782A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c935-0604d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0EA0082A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C935-0604D",
//     price: 10600,
//     priceType: "cents",
//     props: [0, 82, null, 0.8, 105, 125, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0782_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=26",
//     description: "F=DC-102M IL=.9dB 20dBRej@155 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c936-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA0102A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C936-0404D",
//     price: 11100,
//     priceType: "cents",
//     props: [0, 102, null, 0.9, 132, 155, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0781",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=28",
//     description: "F=DC-112M IL=.9dB 20dBRej@167 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0781A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c938-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0DA0112A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C938-0404D",
//     price: 8700,
//     priceType: "cents",
//     props: [0, 112, null, 0.9, 143, 167, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0781_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=29",
//     description: "F=DC-141M IL=.8dB 20dBRej@212 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c939-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA0141A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C939-0404D",
//     price: 11300,
//     priceType: "cents",
//     props: [0, 141, null, 0.8, 172, 212, 2000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=31",
//     description: "F=DC-149M IL=.8dB 20dBRej@224 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c942-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA0149A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C942-0404D",
//     price: 8200,
//     priceType: "cents",
//     props: [0, 149, null, 0.8, 182, 224, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=32",
//     description: "F=DC-153M IL=.8dB 20dBRej@231 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c943-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA0153A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C943-0404D",
//     price: 11300,
//     priceType: "cents",
//     props: [0, 153, null, 0.8, 189, 231, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=35",
//     description: "F=DC-171M IL=.8dB 20dBRej@255 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c946-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA0171A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C946-0404D",
//     price: 11300,
//     priceType: "cents",
//     props: [0, 171, null, 0.8, 207, 255, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=38",
//     description: "F=DC-194M IL=.8dB 20dBRej@289 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c949-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA0194A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C949-0404D",
//     price: 11300,
//     priceType: "cents",
//     props: [0, 194, null, 0.8, 241, 289, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=39",
//     description: "F=DC-204M IL=.8dB 20dBRej@293 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c951-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA0204A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C951-0404D",
//     price: 11300,
//     priceType: "cents",
//     props: [0, 204, null, 0.8, 245, 293, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=41",
//     description: "F=DC-279M IL=.8dB 20dBRej@398 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c953-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA0279A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C953-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: [0, 279, null, 0.8, 339, 398, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=43",
//     description: "F=DC-299M IL=.8dB 20dBRej@427 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c955-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA0299A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C955-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 299, null, 0.8, 363, 427, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0777",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "http://datasheets.avx.com/AVX-MLO_LP-Filters.pdf#page=44",
//     description: "F=DC-316M IL=.8dB 20dBRej@446 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0777A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c956-0404d/",
//     mfgCode: "AVXCO",
//     mfgLandingPage:
//       "http://www.avx.com/products/rfmicrowave/filters/multilayer-organic-mlo-low-pass-filter/",
//     mfgPart: "LP0AA0316A700SMT",
//     models: "this part is missing models",
//     pn: "XM-C956-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: [0, 316, null, 0.8, 378, 446, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0777_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1908",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LFCN-8440+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9p3-0204d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-8440%2B",
//     mfgPart: "LFCN-8440+SMT",
//     models: "this is the new part",
//     pn: "XM-C9P3-0204D",
//     price: 5600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1908_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1320A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc881a.html",
//     mfgPart: "HMC881A",
//     models: "this part is missing models",
//     pn: "XM-C7C3-0404D",
//     price: 28100,
//     priceType: "cents",
//     props: [null, 2400, 4000, 3, 4500, 5000, 7000],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc882a.html",
//     mfgPart: "HMC882ALP5E",
//     models: "this is the new part",
//     pn: "XM-D2D9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-1400%2B",
//     mfgPart: "LFCG-1400+",
//     models: "this is the new part",
//     pn: "XM-C8Y4-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-630%2B",
//     mfgPart: "LFCG-630+",
//     models: "this is the new part",
//     pn: "XM-C8Y3-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-3500%2B",
//     mfgPart: "LFCG-3500+",
//     models: "this is the new part",
//     pn: "XM-D177-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1165_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=XLF-73%2B",
//     mfgPart: "XLF-73+",
//     models: "this is the new part",
//     pn: "XM-D115-0405D",
//     price: 8700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1943_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-3052%2B",
//     mfgPart: "LFCN-3052+",
//     models: "this is the new part",
//     pn: "XM-D141-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "2261_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCV-1652%2B",
//     mfgPart: "LFCV-1652+",
//     models: "this is the new part",
//     pn: "XM-D245-0204D",
//     price: 6200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "2299_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCW-143%2B",
//     mfgPart: "LFCW-143+",
//     models: "this is the new part",
//     pn: "XM-D262-0204D",
//     price: 6100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCW-6300%2B",
//     mfgPart: "LFCW-6300+",
//     models: "this is the new part",
//     pn: "XM-D277-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCW-103%2B",
//     mfgPart: "LFCW-103+",
//     models: "this is the new part",
//     pn: "XM-D3C3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCW-123%2B",
//     mfgPart: "LFCW-123+",
//     models: "this is the new part",
//     pn: "XM-D3C4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCW-1292%2B",
//     mfgPart: "LFCW-1292+",
//     models: "this is the new part",
//     pn: "XM-D462-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-3800%2B",
//     mfgPart: "LFCG-3800+",
//     models: "this is the new part",
//     pn: "XM-D2A9-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-2500%2B",
//     mfgPart: "LFCG-2500+",
//     models: "this is the new part",
//     pn: "XM-D2B1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-4800%2B",
//     mfgPart: "LFCG-4800+",
//     models: "this is the new part",
//     pn: "XM-D2D6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-3700%2B",
//     mfgPart: "LFCG-3700+",
//     models: "this is the new part",
//     pn: "XM-D485-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "L254XF3S",
//     models: "this is the new part",
//     pn: "XM-C2W7-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0616_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCN-2352%2B",
//     mfgPart: "LFCN-2352+",
//     models: "this is the new part",
//     pn: "XM-D142-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "2261_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCV-1452%2B",
//     mfgPart: "LFCV-1452+",
//     models: "this is the new part",
//     pn: "XM-D3R1-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "2299_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0666",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LPF-B0R8+.pdf",
//     description: "F=DC-0.8M IL=1.5dB 20dBRej@1.55 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0666A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7y7-1004d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LPF-B0R8%2B",
//     mfgPart: "LPF-B0R8+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7Y7-1004D",
//     price: 0,
//     priceType: "cents",
//     props: [0, 0.8, null, 1.5, 1.25, 1.55, 1.75],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A9",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "0666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-3400%2B",
//     mfgPart: "LFCG-3400+",
//     models: "this is the new part",
//     pn: "XM-D4N6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "LPF",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LFCG-2750%2B",
//     mfgPart: "LFCG-2750+",
//     models: "this is the new part",
//     pn: "XM-D4U8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Low Pass Filters",
//     newBRDImage: "1014_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0797",
//     catId: "BPT",
//     ctlBoard: "XM-A7L6-0604D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM3043_Datasheet_Latest.pdf",
//     description: "F=6.5G-17G IL=6dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0797B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7l5-0604d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "http://www.atlantamicro.com/index.php/filters/am3043.html",
//     mfgPart: "AM3043SMT",
//     models: "this part is missing models",
//     pn: "XM-A7L5-0604D",
//     price: 21400,
//     priceType: "cents",
//     props: [6500, 17000, 6, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X6",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1484",
//     catId: "BPT",
//     ctlBoard: "XM-C6A7-0604D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADMV8416.pdf",
//     description: "F=7G-16G IL=8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1484A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6a4-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/admv8416.html",
//     mfgPart: "ADMV8416SMT",
//     models: "this part is missing models",
//     pn: "XM-C6A4-0604D",
//     price: 28800,
//     priceType: "cents",
//     props: [7000, 16000, 8, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X6",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "1484_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1484",
//     catId: "BPT",
//     ctlBoard: "XM-C6A7-0604D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADMV8432.pdf",
//     description: "F=16G-32G IL=9dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1484A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6a5-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/admv8432.html",
//     mfgPart: "ADMV8432SMT",
//     models: "this part is missing models",
//     pn: "XM-C6A5-0604D",
//     price: 31200,
//     priceType: "cents",
//     props: [16000, 32000, 9, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X6",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "1484_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1482",
//     catId: "BPT",
//     ctlBoard: "XM-C6A8-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADMV8420.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c6a6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/admv8420.html",
//     mfgPart: "ADMV8420SMT",
//     models: "this is the new part",
//     pn: "XM-C6A6-0404D",
//     price: 26000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "1482_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1584",
//     catId: "BPT",
//     ctlBoard: "XM-C6K4-0604D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM3103_Datasheet_Latest.pdf",
//     description: "F=1G-3G IL=3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1584A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6k3-0604d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/filters/am3103/",
//     mfgPart: "AM3103SMT",
//     models: "this part is missing models",
//     pn: "XM-C6K3-0604D",
//     price: 26200,
//     priceType: "cents",
//     props: [1000, 3000, 3, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X6",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "1584_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1631",
//     catId: "BPT",
//     ctlBoard: "XM-C7H4-0609D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/06/AM3089-Datasheet-Latest.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7g4-0609d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/filters/am3089/",
//     mfgPart: "AM3089SMT",
//     models: "this is the new part",
//     pn: "XM-C7G4-0609D",
//     price: 76500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "1631_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1584",
//     catId: "BPT",
//     ctlBoard: "XM-C6K4-0604D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM3102-Datasheet-Latest.pdf",
//     description: "F=330M-1.2G IL=2.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1584A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7g6-0604d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/filters/am3102/",
//     mfgPart: "AM3102SMT",
//     models: "this part is missing models",
//     pn: "XM-C7G6-0604D",
//     price: 27900,
//     priceType: "cents",
//     props: [330, 1200, 2.5, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X6",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "2235_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1584",
//     catId: "BPT",
//     ctlBoard: "XM-C6K4-0604D",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/05/AM3104_Datasheet_Latest.pdf",
//     description: "F=2.5G-6.5G IL=2.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1584A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7g8-0604d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/filters/am3104/",
//     mfgPart: "AM3104SMT",
//     models: "this part is missing models",
//     pn: "XM-C7G8-0604D",
//     price: 26100,
//     priceType: "cents",
//     props: [2500, 6500, 2.5, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "X6",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "1584_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPT",
//     ctlBoard: "XM-D120-0804D",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/admv8818.html",
//     mfgPart: "ADMV8818",
//     models: "this is the new part",
//     pn: "XM-A546-0804D",
//     price: 114700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "2003_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPT",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/filters/am3064/",
//     mfgPart: "AM3064",
//     models: "this is the new part",
//     pn: "XM-C7G1-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "2151_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPT",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/filters/am3136/",
//     mfgPart: "AM3136",
//     models: "this is the new part",
//     pn: "XM-D441-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "2530_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "BPT",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ATLMC",
//     mfgLandingPage: "https://www.atlantamicro.com/products/filters/am3186/",
//     mfgPart: "AM3186",
//     models: "this is the new part",
//     pn: "XM-D4T2-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Tunable Filters",
//     newBRDImage: "2222_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1236",
//     catId: null,
//     ctlBoard: "XM-C111-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ad8302.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b6k6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ad8302.html",
//     mfgPart: "AD8302SMT",
//     models: "this is the new part",
//     pn: "XM-B6K6-0409D",
//     price: 18700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Gain and Phase Detectors",
//     newBRDImage: "1236_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLM-33+.pdf",
//     description: "F=30M-3G Flat Leakage=12dBm Pmax=30dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a258-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLM-33%2B",
//     mfgPart: "RLM-33+",
//     models: "this part is missing models",
//     pn: "XM-A258-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [30, 3000, 0.23, 12, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLM-33-2W+.pdf",
//     description: "F=0.2M-2G Flat Leakage=12dBm Pmax=33dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a259-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLM-33-2W%2B",
//     mfgPart: "RLM-33-2W+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A259D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A259-0404D",
//     price: 11200,
//     priceType: "cents",
//     props: [0.2, 2000, 0.25, 12, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLM-63-2W+.pdf",
//     description: "F=30M-6G Flat Leakage=12dBm Pmax=32dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a261-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLM-63-2W%2B",
//     mfgPart: "RLM-63-2W+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A261D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A261-0404D",
//     price: 11800,
//     priceType: "cents",
//     props: [30, 6000, 0.3, 12, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLM-521-2WL+.pdf",
//     description: "F=0.5M-520M Flat Leakage=9dBm Pmax=32dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a262-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLM-521-2WL%2B",
//     mfgPart: "RLM-521-2WL+",
//     models: "this part is missing models",
//     pn: "XM-A262-0404D",
//     price: 11600,
//     priceType: "cents",
//     props: [0.5, 520, 0.2, 9, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLM-751-2WL+.pdf",
//     description: "F=3M-750M Flat Leakage=5dBm Pmax=33dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a263-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLM-751-2WL%2B",
//     mfgPart: "RLM-751-2WL+",
//     models: "this part is missing models",
//     pn: "XM-A263-0404D",
//     price: 11400,
//     priceType: "cents",
//     props: [3, 750, 0.2, 5, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0031",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CLM-83-2W+.pdf",
//     description: "F=30M-8.2G Flat Leakage=11.5dBm Pmax=32dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0031I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a264-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CLM-83-2W%2B",
//     mfgPart: "CLM-83-2W+",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A264D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A264-0404D",
//     price: 12100,
//     priceType: "cents",
//     props: [30, 8200, 0.5, 11.5, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0031_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0226",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe45140ds.pdf",
//     description: "F=20M-2G Flat Leakage=22dBm Pmax=32dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0226H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3l1-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/power-limiters/pe45140",
//     mfgPart: "PE45140",
//     models: "this part is missing models",
//     pn: "XM-A3L1-0404D",
//     price: 12400,
//     priceType: "cents",
//     props: [20, 2000, 1, 22, 32],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0226_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0226",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe45450ds.pdf",
//     description: "F=9K-6G Flat Leakage=25dBm Pmax=35dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0226H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3l2-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/power-limiters/pe45450",
//     mfgPart: "PE45450",
//     models: "this part is missing models",
//     pn: "XM-A3L2-0404D",
//     price: 11700,
//     priceType: "cents",
//     props: [0.009, 6000, 1, 25, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0226_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0226",
//     catId: "PLM",
//     ctlBoard: "XM-A766-0404D",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe45361ds.pdf",
//     description: "F=10M-6G Flat Leakage=7 to 13dBm Pmax=30dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0226H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a765-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.psemi.com/products/power-limiters/pe45361",
//     mfgPart: "PE45361",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A765D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A765-0404D",
//     price: 12500,
//     priceType: "cents",
//     props: [10, 6000, 0.65, 713, 30],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0226_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0773",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da004982",
//     description: "F=2G-20G Flat Leakage=18dBm Pmax=35dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0773A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a774-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGL2208-SM",
//     mfgPart: "TGL2208-SM",
//     models: "this part is missing models",
//     pn: "XM-A774-0404D",
//     price: 18300,
//     priceType: "cents",
//     props: [2000, 20000, 1, 18, 35],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0773_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0902",
//     catId: "PLM",
//     ctlBoard: "XM-B1U9-0404D",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/DataSheets/DLM-10SM.pdf",
//     description: "F=DC-10G Flat Leakage=10dBm Pmax=27dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0902A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1l7-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/accessories/dlm-10sm.aspx",
//     mfgPart: "DLM-10SM",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1L7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1L7-0404D",
//     price: 17800,
//     priceType: "cents",
//     props: [0, 10000, 1.4, 10, 27],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0938",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da004998",
//     description: "F=100M-20G Flat Leakage=18.5dBm Pmax=33dBm ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0938B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1p6-0204d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGL2217",
//     mfgPart: "TGL2217",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1P6D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1P6-0204D",
//     price: 41200,
//     priceType: "cents",
//     props: [100, 20000, 0.5, 18.5, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0938_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0903",
//     catId: "PLM",
//     ctlBoard: "XM-B1W9-0404D",
//     datasheetURL:
//       "https://www.keysight.com/upload/cmc_upload/All/TC231-1GC1-8053.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b1w8-0404d/",
//     mfgCode: "KSIGH",
//     mfgLandingPage:
//       "https://www.keysight.com/us/en/lib/resources/miscellaneous/external-ics-web-lookup-table-3093552.html",
//     mfgPart: "TC231 / 1GC1-8053",
//     models: "this is the new part",
//     pn: "XM-B1W8-0404D",
//     price: 64500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0903_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0709",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.qorvo.com/products/d/da005002",
//     description: "F=100M-20G Flat Leakage=18.5dBm Pmax=33dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0709A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2d2-0404d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGL2217-SM",
//     mfgPart: "TGL2217-SM",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2D2D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2D2-0404D",
//     price: 15400,
//     priceType: "cents",
//     props: [100, 20000, 0.9, 18.5, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0709_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLM-33H+.pdf",
//     description: "F=30M-3G Flat Leakage=18dBm Pmax=33dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5y4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLM-33H%2B",
//     mfgPart: "RLM-33H+",
//     models: "this part is missing models",
//     pn: "XM-B5Y4-0404D",
//     price: 11400,
//     priceType: "cents",
//     props: [30, 3000, 0.23, 18, 33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLM-43-5W+.pdf",
//     description: "F=20M-4G Flat Leakage=12dBm Pmax=37dBm ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9a4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLM-43-5W%2B",
//     mfgPart: "RLM-43-5W+",
//     models: "this part is missing models",
//     pn: "XM-B9A4-0404D",
//     price: 13100,
//     priceType: "cents",
//     props: [20, 4000, 0.36, 12, 37],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A3",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1911",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RLM-23-1WL+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8h2-0706d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RLM-23-1WL%2B",
//     mfgPart: "RLM-23-1WL+",
//     models: "this is the new part",
//     pn: "XM-C8H2-0706D",
//     price: 19000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGL2210-SM",
//     mfgPart: "TGL2210-SM",
//     models: "this is the new part",
//     pn: "XM-B975-0404D",
//     price: 18600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGL2209-SM",
//     mfgPart: "TGL2209-SM",
//     models: "this is the new part",
//     pn: "XM-C9Y4-0404D",
//     price: 17700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "1697_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/accessories/hlm-40u.aspx",
//     mfgPart: "HLM-40CH",
//     models: "this is the new part",
//     pn: "XM-C1A4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "1949_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "SKYWK",
//     mfgLandingPage: "https://www.skyworksinc.com/Products/Diodes/CLA4606-085LF",
//     mfgPart: "CLA4606-085LF",
//     models: "this is the new part",
//     pn: "XM-D1U7-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "2290_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PLM",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/accessories/hlm-40psm.aspx",
//     mfgPart: "HLM-40PSM",
//     models: "this is the new part",
//     pn: "XM-D4H7-0304D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Limiters",
//     newBRDImage: "2620_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0197",
//     catId: "50O",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-30G Load=50Ohm",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0197H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a354-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/rcx-series-resistors/",
//     mfgPart: "RCQ-0302PW50R0G",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A354D.s1p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A354-0204D",
//     price: 5400,
//     priceType: "cents",
//     props: [null, 0, 30000, 50],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Loads",
//     newBRDImage: "0197_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1410",
//     catId: "50O",
//     ctlBoard: "TBD",
//     datasheetURL: "https://od.lk/f/MzBfMTQ3NDcyODNf",
//     description: "F=DC-15G Load=50Ohm",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1410A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5g4-0204d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.smithsinterconnect.com/products/rf-microwave-components/resistive-components/diamond-rf-resistives%C2%AE/",
//     mfgPart: "CT0603DW2SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5G4D.s1p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5G4-0204D",
//     price: 15300,
//     priceType: "cents",
//     props: [null, 0, 15000, 50],
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Loads",
//     newBRDImage: "1410_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1562",
//     catId: "50O",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2019/01/N-series_modelithics.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1562A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6j2-0204d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage: "https://ims-resistors.com/products/n-series-resistors/",
//     mfgPart: "ND3-0505WA50R0GSMT",
//     models: "this part is missing models",
//     pn: "XM-C6J2-0204D",
//     price: 5400,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "A1",
//     newCategoryDescription: "Loads",
//     newBRDImage: "1562_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-93+.pdf",
//     description: "x3 Fin=1.8G-3G CL=15 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a124-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-93%2B",
//     mfgPart: "RMK-3-93+SMT",
//     models: "this part is missing models",
//     pn: "XM-A124-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: [3, 1800, 3000, 15, null, 7, 11, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-662+.pdf",
//     description: "x3 Fin=1.6G-2.2G CL=15 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a125-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-662%2B",
//     mfgPart: "RMK-3-662+SMT",
//     models: "this part is missing models",
//     pn: "XM-A125-0404D",
//     price: 10300,
//     priceType: "cents",
//     props: [3, 1600, 2200, 15, null, 9, 13, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-722+.pdf",
//     description: "x3 Fin=1.525G-2.4G CL=14 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a126-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-722%2B",
//     mfgPart: "RMK-3-722+SMT",
//     models: "this part is missing models",
//     pn: "XM-A126-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: [3, 1525, 2400, 14, null, 7, 11, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-1052+.pdf",
//     description: "x3 Fin=2.2G-3.5G CL=18 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a127-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-1052%2B",
//     mfgPart: "RMK-3-1052+SMT",
//     models: "this part is missing models",
//     pn: "XM-A127-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [3, 2200, 3500, 18, null, 7, 11, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-1262+.pdf",
//     description: "x3 Fin=2.25G-4.2G CL=15.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a128-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-1262%2B",
//     mfgPart: "RMK-3-1262+SMT",
//     models: "this part is missing models",
//     pn: "XM-A128-0404D",
//     price: 11000,
//     priceType: "cents",
//     props: [3, 2250, 4200, 15.5, null, 7, 11, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0038",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KSX2-14+.pdf",
//     description: "x2 Fin=2.5G-5G CL=12 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0038K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a129-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KSX2-14%2B",
//     mfgPart: "KSX2-14+SMT",
//     models: "this part is missing models",
//     pn: "XM-A129-0404D",
//     price: 11000,
//     priceType: "cents",
//     props: [2, 2500, 5000, 12, null, 13, 17, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0038_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0038",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KSX2-24+.pdf",
//     description: "x2 Fin=5G-10G CL=15 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0038K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a131-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KSX2-24%2B",
//     mfgPart: "KSX2-24+SMT",
//     models: "this part is missing models",
//     pn: "XM-A131-0404D",
//     price: 11200,
//     priceType: "cents",
//     props: [2, 5000, 10000, 15, null, 10, 13, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0038_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0038",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KSX2-442+.pdf",
//     description: "x2 Fin=600M-2.2G CL=11 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0038K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a132-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KSX2-442%2B",
//     mfgPart: "KSX2-442+SMT",
//     models: "this part is missing models",
//     pn: "XM-A132-0404D",
//     price: 11000,
//     priceType: "cents",
//     props: [2, 600, 2200, 11, null, 7, 13, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0038_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0038",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/KSX2-722+.pdf",
//     description: "x2 Fin=1.3G-3.6G CL=11.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0038K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a134-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=KSX2-722%2B",
//     mfgPart: "KSX2-722+SMT",
//     models: "this part is missing models",
//     pn: "XM-A134-0404D",
//     price: 11000,
//     priceType: "cents",
//     props: [2, 1300, 3600, 11.5, null, 9, 13, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0038_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-212+.pdf",
//     description: "x3 Fin=400M-700M CL=14 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a245-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-212%2B",
//     mfgPart: "RMK-3-212+SMT",
//     models: "this part is missing models",
//     pn: "XM-A245-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: [3, 400, 700, 14, null, 12, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-33+.pdf",
//     description: "x3 Fin=700M-1G CL=14.1 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a246-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-33%2B",
//     mfgPart: "RMK-3-33+SMT",
//     models: "this part is missing models",
//     pn: "XM-A246-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: [3, 700, 1000, 14.1, null, 12, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-14+.pdf",
//     description: "x3 Fin=2.2G-3.3G CL=14.5 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a247-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-14%2B",
//     mfgPart: "RMK-3-14+SMT",
//     models: "this part is missing models",
//     pn: "XM-A247-0404D",
//     price: 10600,
//     priceType: "cents",
//     props: [3, 2200, 3300, 14.5, null, 13, 17, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-451+.pdf",
//     description: "x3 Fin=100M-150M CL=13.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a248-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-451%2B",
//     mfgPart: "RMK-3-451+SMT",
//     models: "this part is missing models",
//     pn: "XM-A248-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: [3, 100, 150, 13.2, null, 12, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-92+.pdf",
//     description: "x3 Fin=150M-300M CL=14 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a249-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-92%2B",
//     mfgPart: "RMK-3-92+SMT",
//     models: "this part is missing models",
//     pn: "XM-A249-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: [3, 150, 300, 14, null, 12, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-5-13+.pdf",
//     description: "x5 Fin=150M-200M CL=21.2 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a251-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-5-13%2B",
//     mfgPart: "RMK-5-13+SMT",
//     models: "this part is missing models",
//     pn: "XM-A251-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [5, 150, 200, 21.2, null, 17, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-5-52+.pdf",
//     description: "x5 Fin=50M-100M CL=22 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a252-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-5-52%2B",
//     mfgPart: "RMK-5-52+SMT",
//     models: "this part is missing models",
//     pn: "XM-A252-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [5, 50, 100, 22, null, 17, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-5-2251+.pdf",
//     description: "x5 Fin=300M-450M CL=21 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a253-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-5-2251%2B",
//     mfgPart: "RMK-5-2251+SMT",
//     models: "this part is missing models",
//     pn: "XM-A253-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [5, 300, 450, 21, null, 17, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-5-352+.pdf",
//     description: "x5 Fin=550M-700M CL=22 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a254-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-5-352%2B",
//     mfgPart: "RMK-5-352+SMT",
//     models: "this part is missing models",
//     pn: "XM-A254-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [5, 550, 700, 22, null, 17, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-5-52+.pdf",
//     description: "x5 Fin=50M-100M CL=22 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a255-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-5-52%2B",
//     mfgPart: "RMK-5-152-2+SMT",
//     models: "this part is missing models",
//     pn: "XM-A255-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 50, 100, 22, null, 17, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-5-751+.pdf",
//     description: "x5 Fin=100M-150M CL=22 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a256-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-5-751%2B",
//     mfgPart: "RMK-5-751+SMT",
//     models: "this part is missing models",
//     pn: "XM-A256-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [5, 100, 150, 22, null, 17, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-5-2751+.pdf",
//     description: "x5 Fin=450M-550M CL=22 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a257-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-5-2751%2B",
//     mfgPart: "RMK-5-2751+SMT",
//     models: "this part is missing models",
//     pn: "XM-A257-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [5, 450, 550, 22, null, 17, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0961",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc578.pdf",
//     description: "x2 Fin=11.5G-16.5G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0961A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a399-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc578lc3b.html",
//     mfgPart: "HMC578LC3BSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A399D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A399-0404D",
//     price: 20600,
//     priceType: "cents",
//     props: [2, 11500, 16500, null, null, 0, 6, 15],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0961_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0409",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/CSFD25.pdf",
//     description: "x2 Fin=10M-2.4G CL=11.5 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0409E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3a8-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/CSFD25",
//     mfgPart: "CSFD25SMT",
//     models: "this part is missing models",
//     pn: "XM-A3A8-0604D",
//     price: 27800,
//     priceType: "cents",
//     props: [2, 10, 2400, 11.5, null, 10, 10, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0409_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0961",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc576.pdf",
//     description: "x2 Fin=9G-14.5G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0961A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3d9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc576lc3b.html",
//     mfgPart: "HMC576LC3BSMT",
//     models: "this part is missing models",
//     pn: "XM-A3D9-0404D",
//     price: 20800,
//     priceType: "cents",
//     props: [2, 9000, 14500, null, null, 0, 6, 15],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0961_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0192",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MLD-0416SM.pdf",
//     description: "x2 Fin=2G-8G CL=12 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0192J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3j5-0404d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/multipliers/mld-0416sm.aspx",
//     mfgPart: "MLD-0416SMSMT",
//     models: "this part is missing models",
//     pn: "XM-A3J5-0404D",
//     price: 18200,
//     priceType: "cents",
//     props: [2, 2000, 8000, 12, null, 6, 14, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0961",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc814.pdf",
//     description: "x2 Fin=6.5G-12.3G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0961A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3k2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc814lc3b.html",
//     mfgPart: "HMC814LC3BSMT",
//     models: "this part is missing models",
//     pn: "XM-A3K2-0404D",
//     price: 19300,
//     priceType: "cents",
//     props: [2, 6500, 12300, null, null, 0, 6, 17],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0961_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0540",
//     catId: "MUL",
//     ctlBoard: "XM-C2V3-0204D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc579.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0540A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3k3-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc579.html",
//     mfgPart: "HMC579DIE",
//     models: "this part is missing models",
//     pn: "XM-A3K3-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0540_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0347",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/XX1002-QH.pdf",
//     description: "x2 Fin=1.25G-3G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0347C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3l8-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/XX1002-QH",
//     mfgPart: "XX1002-QHSMT",
//     models: "this part is missing models",
//     pn: "XM-A3L8-0404D",
//     price: 12500,
//     priceType: "cents",
//     props: [2, 1250, 3000, null, null, -3, 3, 16],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0348",
//     catId: "MUL",
//     ctlBoard: "XM-A6K6-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc573.pdf",
//     description: "x2 Fin=4G-10G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0348B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3l9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc573.html",
//     mfgPart: "HMC573LC3BSMT",
//     models: "this part is missing models",
//     pn: "XM-A3L9-0404D",
//     price: 22300,
//     priceType: "cents",
//     props: [2, 4000, 10000, null, null, 3, 9, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0164",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MLPNC-7100S1.pdf",
//     description: "Fin=75M-250M CL=15 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0164K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3r5-0804d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MLPNC-7100S1-SMT580",
//     mfgPart: "MLPNC-7100S1-SMT580SMT",
//     models: "this part is missing models",
//     pn: "XM-A3R5-0804D",
//     price: 353900,
//     priceType: "cents",
//     props: [null, 75, 250, 15, null, 18, 24, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F9",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0164_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0164",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MLPNC-7102S1.pdf",
//     description: "x0 Fin=300M-700M CL=8 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0164K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3r6-0804d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MLPNC-7102S1-SMT580",
//     mfgPart: "MLPNC-7102S1-SMT580SMT",
//     models: "this part is missing models",
//     pn: "XM-A3R6-0804D",
//     price: 278000,
//     priceType: "cents",
//     props: [0, 300, 700, 8, null, 0, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F9",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0164_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0164",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MLPNC-7103S1.pdf",
//     description: "x0 Fin=600M-1.5G CL=7 ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0164K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3r7-0804d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MLPNC-7103S1-SMT580",
//     mfgPart: "MLPNC-7103S1SMT580SMT",
//     models: "this part is missing models",
//     pn: "XM-A3R7-0804D",
//     price: 278000,
//     priceType: "cents",
//     props: [0, 600, 1500, 7, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F9",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0164_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0422",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MLPNC-7102.pdf",
//     description: "Fin=300M-700M ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0422B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3w7-0804d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MLPNC-7102-SMT680",
//     mfgPart: "MLPNC-7102-SMT680SMT",
//     models: "this part is missing models",
//     pn: "XM-A3W7-0804D",
//     price: 414600,
//     priceType: "cents",
//     props: [0, 300, 700, 0, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F9",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0422_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0422",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/MLPNC-7103.pdf",
//     description: "Fin=600M-1.5G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0422B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3w8-0804d/",
//     mfgCode: "MACOM",
//     mfgLandingPage:
//       "https://www.macom.com/products/product-detail/MLPNC-7103-SMT680",
//     mfgPart: "MLPNC-7103-SMT680SMT",
//     models: "this part is missing models",
//     pn: "XM-A3W8-0804D",
//     price: 414600,
//     priceType: "cents",
//     props: [0, 600, 1500, 0, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F9",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0422_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0409",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/CSFD26.pdf",
//     description: "x2 Fin=50M-3.3G CL=12 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0409E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4a1-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/CSFD26",
//     mfgPart: "CSFD26SMT",
//     models: "this part is missing models",
//     pn: "XM-A4A1-0604D",
//     price: 31000,
//     priceType: "cents",
//     props: [2, 50, 3300, 12, null, 10, 10, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0409_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0395",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/XX1002-QH.pdf",
//     description: "x2 Fin=2.5G-6G CL=16 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0395C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4c6-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/XX1002-QH",
//     mfgPart: "XX1002-QHSMT",
//     models: "this part is missing models",
//     pn: "XM-A4C6-0404D",
//     price: 13100,
//     priceType: "cents",
//     props: [2, 2500, 6000, 16, null, -3, 3, 16],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0278",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/XX1007-QT.pdf",
//     description: "x2 Fin=13.5G-17G CL=12 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0278F.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4c7-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/XX1007-QT",
//     mfgPart: "XX1007-QTSMT",
//     models: "this part is missing models",
//     pn: "XM-A4C7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 13500, 17000, 12, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0278_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0507",
//     catId: "MUL",
//     ctlBoard: "XM-C899-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/XX1000-QT.pdf",
//     description: "x2 Fin=7.5G-22.5G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0507A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4c8-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/XX1000-QT",
//     mfgPart: "XX1000-QTSMT",
//     models: "this part is missing models",
//     pn: "XM-A4C8-0404D",
//     price: 27800,
//     priceType: "cents",
//     props: [2, 7500, 22500, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0507_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0278",
//     catId: "MUL",
//     ctlBoard: "XM-C7J8-0404D",
//     datasheetURL: "https://cdn.macom.com/datasheets/XX1010-QT.pdf",
//     description: "x2 Fin=14.625G-15G CL=14 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0278F.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4c9-0404d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/XX1010-QT",
//     mfgPart: "XX1010-QTSMT",
//     models: "this part is missing models",
//     pn: "XM-A4C9-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 14625, 15000, 14, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0278_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0212",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/FD25_FD25C_FD25E_SFD25.pdf",
//     description: "x2 Fin=5M-2.4G CL=12 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0212K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4d1-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SFD25",
//     mfgPart: "SFD25SMT",
//     models: "this part is missing models",
//     pn: "XM-A4D1-0604D",
//     price: 41300,
//     priceType: "cents",
//     props: [2, 5, 2400, 12, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0212_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0212",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/FD25H_FD25HC_SFD25H.pdf",
//     description: "x2 Fin=5M-2.4G CL=12 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0212K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4d2-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SFD25H",
//     mfgPart: "SFD25HSMT",
//     models: "this part is missing models",
//     pn: "XM-A4D2-0604D",
//     price: 40300,
//     priceType: "cents",
//     props: [2, 5, 2400, 12, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0212_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0212",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/FD26.pdf",
//     description: "x2 Fin=100M-3.3G CL=12 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0212K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4d3-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/SFD26",
//     mfgPart: "SFD26SMT",
//     models: "this part is missing models",
//     pn: "XM-A4D3-0604D",
//     price: 41300,
//     priceType: "cents",
//     props: [2, 100, 3300, 12, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0212_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0409",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://cdn.macom.com/datasheets/CSFD25H.pdf",
//     description: "x2 Fin=10M-2.4G CL=12 ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0409E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4d5-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/CSFD25H",
//     mfgPart: "CSFD25HSMT",
//     models: "this part is missing models",
//     pn: "XM-A4D5-0604D",
//     price: 28800,
//     priceType: "cents",
//     props: [2, 10, 2400, 12, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F6",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0409_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0562",
//     catId: "MUL",
//     ctlBoard: "XM-A4F3-0304D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc598.pdf",
//     description: "x2 Fin=11G-23G ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0562A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4f2-0304d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc598.html",
//     mfgPart: "HMC598DIE",
//     models: "this part is missing models",
//     pn: "XM-A4F2-0304D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 11000, 23000, null, null, 0, 6, 15],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F2",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0562_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0814",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CY2-143+.pdf",
//     description: "x2 Fin=2G-7G CL=12 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0814A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4g5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CY2-143%2B",
//     mfgPart: "CY2-143+SMT",
//     models: "this part is missing models",
//     pn: "XM-A4G5-0404D",
//     price: 10300,
//     priceType: "cents",
//     props: [2, 2000, 7000, 12, null, 12, 18, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0814_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0499",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc577.pdf",
//     description: "x2 Fin=13.5G-15.5G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0499A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5k6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc577.html",
//     mfgPart: "HMC577LC4BSMT",
//     models: "this part is missing models",
//     pn: "XM-A5K6-0404D",
//     price: 17600,
//     priceType: "cents",
//     props: [2, 13500, 15500, null, null, -2, 6, 20],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0499_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0499",
//     catId: "MUL",
//     ctlBoard: "XM-C7J8-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc942.pdf",
//     description: "x2 Fin=12.5G-15.5G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0499A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5k7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc942.html",
//     mfgPart: "HMC942LP4ESMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5K7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5K7-0404D",
//     price: 15900,
//     priceType: "cents",
//     props: [2, 12500, 15500, null, null, 0, 6, 21],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0499_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0498",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc370.pdf",
//     description: "x4 Fin=3.6G-4.1G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0498A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5k8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc370.html",
//     mfgPart: "HMC370LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5K8-0404D",
//     price: 16200,
//     priceType: "cents",
//     props: [4, 3600, 4100, null, null, -15, 5, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0498",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc443.pdf",
//     description: "x4 Fin=2.45G-2.8G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0498A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5k9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc443.html",
//     mfgPart: "HMC443LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5K9-0404D",
//     price: 15800,
//     priceType: "cents",
//     props: [4, 2450, 2800, null, null, -15, 5, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0498",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc444.pdf",
//     description: "x8 Fin=1.2375G-1.4G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0498A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5l1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc444.html",
//     mfgPart: "HMC444LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5L1-0404D",
//     price: 26900,
//     priceType: "cents",
//     props: [8, 1237.5, 1400, null, null, -15, 5, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0498",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc445.pdf",
//     description: "x16 Fin=618.75M-687.5M ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0498A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5l2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc445.html",
//     mfgPart: "HMC445LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5L2-0404D",
//     price: 23700,
//     priceType: "cents",
//     props: [16, 618.75, 687.5, null, null, -15, 5, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0498",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc695.pdf",
//     description: "x4 Fin=2.85G-3.3G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0498A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5l3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc695.html",
//     mfgPart: "HMC695LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5L3-0404D",
//     price: 16200,
//     priceType: "cents",
//     props: [4, 2850, 3300, null, null, -15, 5, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0529",
//     catId: "MUL",
//     ctlBoard: "XM-A6K6-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc573.pdf",
//     description: "x2 Fin=4G-11G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0529B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5n6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc573.html",
//     mfgPart: "HMC573LC3BSMT",
//     models: "this part is missing models",
//     pn: "XM-A5N6-0404D",
//     price: 21600,
//     priceType: "cents",
//     props: [2, 4000, 11000, null, null, 0, 6, 12],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0932",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd225c3_ver_1.1_1017.pdf",
//     description: "x2 Fin=4G-8G CL=13 ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0932B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a732-0204d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/cmd225c3-passive-frequency-doubler/",
//     mfgPart: "CMD225C3SMT",
//     models: "this part is missing models",
//     pn: "XM-A732-0204D",
//     price: 15200,
//     priceType: "cents",
//     props: [2, 4000, 8000, 13, null, 15, 15, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F1",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0932_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-153+.pdf",
//     description: "x3 Fin=2.6G-5G CL=17 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7c8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-153%2B",
//     mfgPart: "RMK-3-153+SMT",
//     models: "this part is missing models",
//     pn: "XM-A7C8-0404D",
//     price: 11100,
//     priceType: "cents",
//     props: [3, 2600, 5000, 17, null, 7, 11, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0932",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd226c3_ver_1.1_0817%20(1).pdf",
//     description: "x2 Fin=7G-11G CL=9 ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0932B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9w6-0204d/",
//     mfgCode: "QORVO",
//     mfgLandingPage:
//       "https://www.custommmic.com/cmd226c3-passive-frequency-doubler/",
//     mfgPart: "CMD226C3SMT",
//     models: "this part is missing models",
//     pn: "XM-A9W6-0204D",
//     price: 16700,
//     priceType: "cents",
//     props: [2, 7000, 11000, 9, null, 15, 15, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F1",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0932_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0540",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc578chips.pdf",
//     description: "x2 Fin=12G-16.5G ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0540A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1j8-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc578-die.html",
//     mfgPart: "HMC578DIE",
//     models: "this part is missing models",
//     pn: "XM-B1J8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 12000, 16500, null, null, 0, 6, 17],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F1",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0540_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0932",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMD-1030HSM.pdf",
//     description: "x2 Fin=5G-15G CL=12 ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0932B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1t6-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/multipliers/mmd-1030hsm.aspx",
//     mfgPart: "MMD-1030HSMSMT",
//     models: "this part is missing models",
//     pn: "XM-B1T6-0204D",
//     price: 20600,
//     priceType: "cents",
//     props: [2, 5000, 15000, 12, null, 10, 17, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F1",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0932_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-5-83+.pdf",
//     description: "x5 Fin=1G-1.6G CL=28 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2f3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-5-83%2B",
//     mfgPart: "RMK-5-83+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2F3-0404D",
//     price: 12700,
//     priceType: "cents",
//     props: [5, 1000, 1600, 28, null, 7, 11, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0546",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1105.pdf",
//     description: "x2 Fin=20G-40G CL=11 ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0546C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2g2-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc1105.html",
//     mfgPart: "HMC1105DIE",
//     models: "this part is missing models",
//     pn: "XM-B2G2-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 20000, 40000, 11, null, 15, 15, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F1",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0546_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1123",
//     catId: "MUL",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc448.pdf",
//     description: "x2 Fin=10G-11G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1123A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5h5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc448lc3b.html",
//     mfgPart: "HMC448LC3BSMT",
//     models: "this part is missing models",
//     pn: "XM-B5H5-0404D",
//     price: 19400,
//     priceType: "cents",
//     props: [2, 10000, 11000, null, null, -4, 6, 9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0932",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.custommmic.com/resources/cmd227c3_passive_frequency_doubler_8-15_ghz.pdf",
//     description: "x2 Fin=8G-15G CL=11 ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0932B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5l4-0204d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.custommmic.com/cmd227c3/",
//     mfgPart: "CMD227C3SMT",
//     models: "this part is missing models",
//     pn: "XM-B5L4-0204D",
//     price: 16200,
//     priceType: "cents",
//     props: [2, 8000, 15000, 11, null, 15, 15, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F1",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0932_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1229",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMD-2060L.pdf",
//     description: "x2 Fin=10G-30G CL=11 ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1229A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b824-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/multipliers/mmd-2060l.aspx",
//     mfgPart: "MMD-2060LCHDIE",
//     models: "this part is missing models",
//     pn: "XM-B824-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 10000, 30000, 11, null, 10, 15, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F1",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "1229_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1229",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMD-2060H.pdf",
//     description: "x2 Fin=10G-30G CL=12 ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1229A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b825-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/multipliers/mmd-2060h.aspx",
//     mfgPart: "MMD-2060HCHDIE",
//     models: "this part is missing models",
//     pn: "XM-B825-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 10000, 30000, 12, null, 10, 15, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F1",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "1229_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CY2-283+.pdf",
//     description: "x2 Fin=3.5G-12G CL=13 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8r5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CY2-283%2B",
//     mfgPart: "CY2-283+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8R5-0404D",
//     price: 10800,
//     priceType: "cents",
//     props: [2, 3500, 12000, 13, null, 12, 18, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0312",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/CY2-44+.pdf",
//     description: "x2 Fin=6.2G-16G CL=14 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0312D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8r6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=CY2-44%2B",
//     mfgPart: "CY2-44+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8R6-0404D",
//     price: 11300,
//     priceType: "cents",
//     props: [2, 6200, 16000, 14, null, 12, 18, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0312_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-5-51+.pdf",
//     description: "x5 Fin=7.5G-10.5G CL=22.9 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9k1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-5-51%2B",
//     mfgPart: "RMK-5-51+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9K1-0404D",
//     price: 12300,
//     priceType: "cents",
//     props: [5, 7500, 10500, 22.9, null, 0, 5, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0569",
//     catId: "MUL",
//     ctlBoard: "XM-B9L2-0304D",
//     datasheetURL: "https://www.qorvo.com/products/d/da004469",
//     description: "x2 Fin=9G-22G CL=-6 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0569C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9l4-0304d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA4040",
//     mfgPart: "TGA4040 (x2)DIE",
//     models: "this part is missing models",
//     pn: "XM-B9L4-0304D",
//     price: 0,
//     priceType: "cents",
//     props: [2, 9000, 22000, -6, null, 9, 9, 15],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F2",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0569_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0569",
//     catId: "MUL",
//     ctlBoard: "XM-B9L3-0304D",
//     datasheetURL: "https://www.qorvo.com/products/d/da004469",
//     description: "x3 Fin=6G-12G CL=-6 ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0569C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9l5-0304d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGA4040",
//     mfgPart: "TGA4040 (x3)DIE",
//     models: "this part is missing models",
//     pn: "XM-B9L5-0304D",
//     price: 0,
//     priceType: "cents",
//     props: [3, 6000, 12000, -6, null, 9, 9, 15],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F2",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0569_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc576chips.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3y6-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/frequency-dividers-multipliers-detectors/frequency-multipliers/hmc576-die.html",
//     mfgPart: "HMC576-DIEDIE",
//     models: "this is the new part",
//     pn: "XM-C3Y6-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0544_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1646",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MMD-1648L.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c5c4-0204d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/multipliers/mmd-1648l.aspx",
//     mfgPart: "MMD-1648LCHDIE",
//     models: "this is the new part",
//     pn: "XM-C5C4-0204D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "1646_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0039",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/RMK-3-332+.pdf",
//     description: "x3 Fin=700M-1.1G CL=15.6 ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0039J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7h5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=RMK-3-332%2B",
//     mfgPart: "RMK-3-332+SMT",
//     models: "this part is missing models",
//     pn: "XM-C7H5-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: [3, 700, 1100, 15.6, null, 10, 15, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "F3",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "0039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/hmc561lp3.html#product-overview",
//     mfgPart: "HMC561LP3E",
//     models: "this is the new part",
//     pn: "XM-B5M3-0404D",
//     price: 17800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/hmc1110.html#product-overview",
//     mfgPart: "HMC1110",
//     models: "this is the new part",
//     pn: "XM-B213-0404D",
//     price: 43100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "1342_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc8342.html",
//     mfgPart: "HMC8342LS6",
//     models: "this is the new part",
//     pn: "XM-D415-0404D",
//     price: 28100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "2039_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SYK-2R%2B",
//     mfgPart: "SYK-2R+",
//     models: "this is the new part",
//     pn: "XM-D1C5-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "2242_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "MUL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/XX1000-BD",
//     mfgPart: "XX1000-BD-000V",
//     models: "this is the new part",
//     pn: "XM-D318-0404D",
//     price: 26800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Multipliers",
//     newBRDImage: "2404_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D141_customer.pdf",
//     description: "TCXO F=100M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2l2-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-100.0M",
//     models: "this part is missing models",
//     pn: "XM-A2L2-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [100, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0083",
//     catId: "OSC",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "http://www.nelfc.com/pdf/1319a.pdf",
//     description: "OCXO F=100M Accuracy=+/-0.05ppm PN=-175at10K",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0083J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2l3-0809d/",
//     mfgCode: "NELFC",
//     mfgLandingPage: "http://www.nelfc.com/ocxo/index.html",
//     mfgPart: "O-CS8-0LS58EWU-L/100.000MHz",
//     models: "this part is missing models",
//     pn: "XM-A2L3-0809D",
//     price: 122000,
//     priceType: "cents",
//     props: [100, 0.05, 10, -17510, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0083_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://synergymwave.com/products/vco/datasheet/MFC170195-5.pdf",
//     description: "F=1.7G-1.95G Pout=12dBm Vtune=0.5-10V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2l4-0809d/",
//     mfgCode: "SYNRG",
//     mfgLandingPage: "https://synergymwave.com/products/vco/",
//     mfgPart: "MFC170195-5",
//     models: "this part is missing models",
//     pn: "XM-A2L4-0809D",
//     price: 27600,
//     priceType: "cents",
//     props: [null, 1700, 1950, 12, 37, 0.5, 10, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0083",
//     catId: "OSC",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "http://www.nelfc.com/pdf/1319a.pdf",
//     description: "OCXO F=80M Accuracy=+/-0.05ppm PN=-175at10K",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0083J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2n5-0809d/",
//     mfgCode: "NELFC",
//     mfgLandingPage: "http://www.nelfc.com/ocxo/index.html",
//     mfgPart: "O-CS8-0LS58EWU-L/80.000MHz",
//     models: "this part is missing models",
//     pn: "XM-A2N5-0809D",
//     price: 0,
//     priceType: "cents",
//     props: [80, 0.05, 10, -17510, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0083_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0083",
//     catId: "OSC",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "http://www.nelfc.com/pdf/1319a.pdf",
//     description: "OCXO F=110M Accuracy=+/-0.05ppm PN=-175at10K",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0083J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2n6-0809d/",
//     mfgCode: "NELFC",
//     mfgLandingPage: "http://www.nelfc.com/ocxo/index.html",
//     mfgPart: "O-CS8-0LS58EWU-L/110.000MHz",
//     models: "this part is missing models",
//     pn: "XM-A2N6-0809D",
//     price: 0,
//     priceType: "cents",
//     props: [110, 0.05, 15, -17510, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0083_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0083",
//     catId: "OSC",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "http://www.nelfc.com/pdf/1319a.pdf",
//     description: "OCXO F=120M Accuracy=+/-0.05ppm PN=-175at10K",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0083J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2n7-0809d/",
//     mfgCode: "NELFC",
//     mfgLandingPage: "http://www.nelfc.com/ocxo/index.html",
//     mfgPart: "O-CS8-0LS58EWU-L/120.000MHz",
//     models: "this part is missing models",
//     pn: "XM-A2N7-0809D",
//     price: 0,
//     priceType: "cents",
//     props: [120, 0.05, 10, -17510, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0083_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D141_customer.pdf",
//     description: "TCXO F=50M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2n9-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-50.0M",
//     models: "this part is missing models",
//     pn: "XM-A2N9-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [50, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D141_customer.pdf",
//     description: "TCXO F=53.125M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2p1-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-53.125M",
//     models: "this part is missing models",
//     pn: "XM-A2P1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [53.125, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D141_customer.pdf",
//     description: "TCXO F=66M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2p2-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-66M",
//     models: "this part is missing models",
//     pn: "XM-A2P2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [66, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D141_customer.pdf",
//     description: "TCXO F=66.667M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2p3-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-66.667M",
//     models: "this part is missing models",
//     pn: "XM-A2P3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [66.667, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D141_customer.pdf",
//     description: "TCXO F=75M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2p4-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-75M",
//     models: "this part is missing models",
//     pn: "XM-A2P4-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [75, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D141_customer.pdf",
//     description: "TCXO F=77.76M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2p5-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-77.76M",
//     models: "this part is missing models",
//     pn: "XM-A2P5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [77.76, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D141_customer.pdf",
//     description: "TCXO F=80M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2p6-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-80M",
//     models: "this part is missing models",
//     pn: "XM-A2P6-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [80, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D141_customer.pdf",
//     description: "TCXO F=106.25M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2p7-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-106.25M",
//     models: "this part is missing models",
//     pn: "XM-A2P7-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [106.25, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D142_customer.pdf",
//     description: "TCXO F=125M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2p8-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D142-125.0M",
//     models: "this part is missing models",
//     pn: "XM-A2P8-0404D",
//     price: 7600,
//     priceType: "cents",
//     props: [125, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D142_customer.pdf",
//     description: "TCXO F=133M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2p9-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-133M",
//     models: "this part is missing models",
//     pn: "XM-A2P9-0404D",
//     price: 6700,
//     priceType: "cents",
//     props: [133, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D142_customer.pdf",
//     description: "TCXO F=155.52M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2r1-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-155.52M",
//     models: "this part is missing models",
//     pn: "XM-A2R1-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [155.52, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D142_customer.pdf",
//     description: "TCXO F=156.25M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2r2-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-156.25M",
//     models: "this part is missing models",
//     pn: "XM-A2R2-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [156.25, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0159",
//     catId: "OSC",
//     ctlBoard: "XM-A2J8-0404D",
//     datasheetURL:
//       "https://www.xmicrowave.com/wp-content/uploads/SM77D142_customer.pdf",
//     description: "TCXO F=161.1328M Accuracy=+/-20ppm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0159H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2r3-0404d/",
//     mfgCode: "PLENA",
//     mfgLandingPage: "http://www.pletronics.com/",
//     mfgPart: "SM77D141-161.1328M",
//     models: "this part is missing models",
//     pn: "XM-A2R3-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [161.1328, 20, null, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0159_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc632.pdf",
//     description: "F=23.8G-26.8G Pout=8dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2w4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc632.html",
//     mfgPart: "HMC632LP5E",
//     models: "this part is missing models",
//     pn: "XM-A2W4-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 23800, 26800, 8, 250, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0234",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc733.pdf",
//     description: "F=10G-20G Pout=3dBm Vtune=0-.25-23V",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0234K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a353-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc733.html",
//     mfgPart: "HMC733LC4B",
//     models: "this part is missing models",
//     pn: "XM-A353-0404D",
//     price: 59300,
//     priceType: "cents",
//     props: [null, 10000, 20000, 3, 550, 0.25, 23, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0234_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0234",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc732.pdf",
//     description: "F=6G-12G Pout=1dBm Vtune=0-23V",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0234K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3a5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc732.html",
//     mfgPart: "HMC732LC4B",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3A5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3A5-0404D",
//     price: 36700,
//     priceType: "cents",
//     props: [null, 6000, 12000, 1, 450, 0, 23, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0234_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc632.pdf",
//     description: "F=7.125G-15.65G Pout=5dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4g6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc632.html",
//     mfgPart: "HMC632LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4G6-0409D",
//     price: 17000,
//     priceType: "cents",
//     props: [null, 7125, 15650, 5, 250, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0234",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc587.pdf",
//     description: "F=5G-10G Pout=5dBm Vtune=0-18V",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0234K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4k3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc587.html",
//     mfgPart: "HMC587LC4B",
//     models: "this part is missing models",
//     pn: "XM-A4K3-0404D",
//     price: 38900,
//     priceType: "cents",
//     props: [null, 5000, 10000, 5, 450, 0, 18, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0234_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0234",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc586.pdf",
//     description: "F=4G-8G Pout=5dBm Vtune=0-18V",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0234K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4k4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc586.html",
//     mfgPart: "HMC586LC4B",
//     models: "this part is missing models",
//     pn: "XM-A4K4-0404D",
//     price: 46300,
//     priceType: "cents",
//     props: [null, 4000, 8000, 5, 450, 0, 18, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0234_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0234",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc588.pdf",
//     description: "F=8G-12.5G Pout=5dBm Vtune=0-13V",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0234K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4k5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc588.html",
//     mfgPart: "HMC588LC4B",
//     models: "this part is missing models",
//     pn: "XM-A4K5-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 8000, 12500, 5, 450, 0, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0234_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1160.pdf",
//     description: "F=8.45G-9.3G Pout=12dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4k6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1160.html",
//     mfgPart: "HMC1160LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4K6-0404D",
//     price: 14700,
//     priceType: "cents",
//     props: [null, 8450, 9300, 12, 250, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1161.pdf",
//     description: "F=8.71G-9.55G Pout=11dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4k7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1161.html",
//     mfgPart: "HMC1161LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4K7-0404D",
//     price: 14700,
//     priceType: "cents",
//     props: [null, 8710, 9550, 11, 250, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL: "https://www.analog.com/en/products/hmc1162.html",
//     description: "F=9.25G-10.1G Pout=11dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4k8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1162.html",
//     mfgPart: "HMC1162LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4K8-0404D",
//     price: 14700,
//     priceType: "cents",
//     props: [null, 9250, 10100, 11, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1163.pdf",
//     description: "F=9.65G-10.41G Pout=11dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4k9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1163.html",
//     mfgPart: "HMC1163LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4K9-0404D",
//     price: 14500,
//     priceType: "cents",
//     props: [null, 9650, 10410, 11, 300, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1164.pdf",
//     description: "F=10.38G-11.3G Pout=8dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4l1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1164.html",
//     mfgPart: "HMC1164LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4L1-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 10380, 11300, 8, 300, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1165.pdf",
//     description: "F=11.07G-11.62G Pout=8dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4l2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1165.html",
//     mfgPart: "HMC1165LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4L2-0404D",
//     price: 14700,
//     priceType: "cents",
//     props: [null, 11070, 11620, 8, 310, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1166.pdf",
//     description: "F=11.41G-12.62G Pout=11dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4l3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1166.html",
//     mfgPart: "HMC1166LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4L3-0404D",
//     price: 14300,
//     priceType: "cents",
//     props: [null, 11410, 12620, 11, 325, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1167.pdf",
//     description: "F=12.17G-13.3G Pout=10dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4l4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1167.html",
//     mfgPart: "HMC1167LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4L4-0404D",
//     price: 14700,
//     priceType: "cents",
//     props: [null, 12170, 13300, 10, 350, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1168.pdf",
//     description: "F=12.47G-13.72G Pout=10dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4l5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1168.html",
//     mfgPart: "HMC1168LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4L5-0404D",
//     price: 14700,
//     priceType: "cents",
//     props: [null, 12470, 13720, 10, 350, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1169.pdf",
//     description: "F=12.92G-14.07G Pout=11dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4l6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1169.html",
//     mfgPart: "HMC1169LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4L6-0404D",
//     price: 14700,
//     priceType: "cents",
//     props: [null, 12920, 14070, 11, 350, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc734.pdf",
//     description: "F=12.5G-13.9G Pout=18dBm Vtune=1-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4l7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc734.html",
//     mfgPart: "HMC734LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4L7-0404D",
//     price: 17600,
//     priceType: "cents",
//     props: [null, 12500, 13900, 18, 150, 1, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc735.pdf",
//     description: "F=10.5G-12.2G Pout=17dBm Vtune=1-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4l8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc735.html",
//     mfgPart: "HMC735LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4L8-0404D",
//     price: 17000,
//     priceType: "cents",
//     props: [null, 10500, 12200, 17, 200, 1, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc582.pdf",
//     description: "F=11.1G-12.4G Pout=9dBm Vtune=2-12V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4m1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc582.html",
//     mfgPart: "HMC582LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4M1-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 11100, 12400, 9, 200, 2, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc530.pdf",
//     description: "F=9.5G-10.8G Pout=11dBm Vtune=2-12V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4m2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc530.html",
//     mfgPart: "HMC530LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4M2-0404D",
//     price: 15900,
//     priceType: "cents",
//     props: [null, 9500, 10800, 11, 150, 2, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc512.pdf",
//     description: "F=9.6G-10.8G Pout=9dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4m3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc512.html",
//     mfgPart: "HMC512LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4M3-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 9600, 10800, 9, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc584.pdf",
//     description: "F=12.5G-13.9G Pout=10dBm Vtune=2-12V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4m4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc584.html",
//     mfgPart: "HMC584LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4M4-0404D",
//     price: 15900,
//     priceType: "cents",
//     props: [null, 12500, 13900, 10, 250, 2, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc583.pdf",
//     description: "F=11.5G-12.8G Pout=11dBm Vtune=2-12V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4m5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc583.html",
//     mfgPart: "HMC583LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4M5-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 11500, 12800, 11, 200, 2, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc534.pdf",
//     description: "F=10.6G-11.8G Pout=11dBm Vtune=2-12V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4m6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc534.html",
//     mfgPart: "HMC534LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4M6-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 10600, 11800, 11, 200, 2, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc510.pdf",
//     description: "F=8.45G-9.55G Pout=13dBm Vtune=2-12V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4m7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc510.html",
//     mfgPart: "HMC510LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4M7-0404D",
//     price: 15900,
//     priceType: "cents",
//     props: [null, 8450, 9550, 13, 150, 2, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc531.pdf",
//     description: "F=13.6G-14.9G Pout=10dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4m8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc531.html",
//     mfgPart: "HMC531LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4M8-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 13600, 14900, 10, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc529.pdf",
//     description: "F=12.4G-13.4G Pout=8dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4m9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc529.html",
//     mfgPart: "HMC529LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4M9-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 12400, 13400, 8, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc515.pdf",
//     description: "F=11.5G-12.5G Pout=10dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4n1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc515.html",
//     mfgPart: "HMC515LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4N1-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 11500, 12500, 10, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc514.pdf",
//     description: "F=11.17G-12.02G Pout=7dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4n2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc514.html",
//     mfgPart: "HMC514LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4N2-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 11170, 12020, 7, 100, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc513.pdf",
//     description: "F=10.43G-11.46G Pout=7dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4n3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc513.html",
//     mfgPart: "HMC513LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4N3-0404D",
//     price: 15200,
//     priceType: "cents",
//     props: [null, 10430, 11460, 7, 125, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc511.pdf",
//     description: "F=9.05G-10.15G Pout=13dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4n4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc511.html",
//     mfgPart: "HMC511LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4N4-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 9050, 10150, 13, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc509.pdf",
//     description: "F=7.8G-8.8G Pout=13dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4n5-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc509.html",
//     mfgPart: "HMC509LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4N5-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 7800, 8800, 13, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc508.pdf",
//     description: "F=7.3G-8.2G Pout=15dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4n6-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc508.html",
//     mfgPart: "HMC508LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4N6-0404D",
//     price: 15200,
//     priceType: "cents",
//     props: [null, 7300, 8200, 15, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0201",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc507.pdf",
//     description: "F=6.65G-7.65G Pout=13dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0201K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4n7-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc507.html",
//     mfgPart: "HMC507LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4N7-0404D",
//     price: 15000,
//     priceType: "cents",
//     props: [null, 6650, 7650, 13, 100, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0201_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0521",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc737.pdf",
//     description: "F=14.9G-15.5G Pout=9dBm Vtune=1-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0521A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4n8-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc737.html",
//     mfgPart: "HMC737LP4E",
//     models: "this part is missing models",
//     pn: "XM-A4N8-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 14900, 15500, 9, 100, 1, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0521",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc736.pdf",
//     description: "F=14.5G-15G Pout=9dBm Vtune=1-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0521A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4n9-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc736.html",
//     mfgPart: "HMC736LP4E",
//     models: "this part is missing models",
//     pn: "XM-A4N9-0404D",
//     price: 15400,
//     priceType: "cents",
//     props: [null, 14500, 15000, 9, 100, 1, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0521",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc738.pdf",
//     description: "F=20.9G-23.9G Pout=9dBm Vtune=1-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0521A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4p1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc738.html",
//     mfgPart: "HMC738LP4E",
//     models: "this part is missing models",
//     pn: "XM-A4P1-0404D",
//     price: 19000,
//     priceType: "cents",
//     props: [null, 20900, 23900, 9, 400, 1, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0521",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc739.pdf",
//     description: "F=23.8G-26.8G Pout=8dBm Vtune=1-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0521A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4p2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc739.html",
//     mfgPart: "HMC739LP4E",
//     models: "this part is missing models",
//     pn: "XM-A4P2-0404D",
//     price: 19200,
//     priceType: "cents",
//     props: [null, 23800, 26800, 8, 400, 1, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0521",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc533.pdf",
//     description: "F=23.8G-24.8G Pout=12dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0521A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4p3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc533.html",
//     mfgPart: "HMC533LP4E",
//     models: "this part is missing models",
//     pn: "XM-A4P3-0404D",
//     price: 23900,
//     priceType: "cents",
//     props: [null, 23800, 24800, 12, 200, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1160.pdf",
//     description: "F=8.45G-9.3G Pout=12dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4p6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1160.html",
//     mfgPart: "HMC1160LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4P6-0409D",
//     price: 17300,
//     priceType: "cents",
//     props: [null, 8450, 9300, 12, 250, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1161.pdf",
//     description: "F=8.71G-9.55G Pout=11dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4p7-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1161.html",
//     mfgPart: "HMC1161LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4P7-0409D",
//     price: 17800,
//     priceType: "cents",
//     props: [null, 8710, 9550, 11, 250, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL: "https://www.analog.com/en/products/hmc1162.html",
//     description: "F=9.25G-10.1G Pout=11dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4p8-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1162.html",
//     mfgPart: "HMC1162LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4P8-0409D",
//     price: 18100,
//     priceType: "cents",
//     props: [null, 9250, 10100, 11, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1163.pdf",
//     description: "F=9.65G-10.41G Pout=11dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4p9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1163.html",
//     mfgPart: "HMC1163LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4P9-0409D",
//     price: 17400,
//     priceType: "cents",
//     props: [null, 9650, 10410, 11, 300, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1164.pdf",
//     description: "F=10.38G-11.3G Pout=8dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4r1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1164.html",
//     mfgPart: "HMC1164LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4R1-0409D",
//     price: 18100,
//     priceType: "cents",
//     props: [null, 10380, 11300, 8, 300, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1165.pdf",
//     description: "F=11.07G-11.62G Pout=8dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4r2-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1165.html",
//     mfgPart: "HMC1165LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4R2-0409D",
//     price: 18100,
//     priceType: "cents",
//     props: [null, 11070, 11620, 8, 310, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1166.pdf",
//     description: "F=11.41G-12.62G Pout=11dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4r3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1166.html",
//     mfgPart: "HMC1166LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4R3-0409D",
//     price: 17300,
//     priceType: "cents",
//     props: [null, 11410, 12620, 11, 325, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC1167.pdf",
//     description: "F=12.17G-13.3G Pout=10dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4r4-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1167.html",
//     mfgPart: "HMC1167LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4R4-0409D",
//     price: 17400,
//     priceType: "cents",
//     props: [null, 12170, 13300, 10, 350, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1168.pdf",
//     description: "F=12.47G-13.72G Pout=10dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4r5-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1168.html",
//     mfgPart: "HMC1168LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4R5-0409D",
//     price: 17800,
//     priceType: "cents",
//     props: [null, 12470, 13720, 10, 350, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc1169.pdf",
//     description: "F=12.92G-14.07G Pout=11dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4r6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-w-output-dividers/hmc1169.html",
//     mfgPart: "HMC1169LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4R6-0409D",
//     price: 17300,
//     priceType: "cents",
//     props: [null, 12920, 14070, 11, 350, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc734.pdf",
//     description: "F=12.5G-13.9G Pout=18dBm Vtune=1-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4r7-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc734.html",
//     mfgPart: "HMC734LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4R7-0409D",
//     price: 19600,
//     priceType: "cents",
//     props: [null, 12500, 13900, 18, 150, 1, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc582.pdf",
//     description: "F=11.1G-12.4G Pout=9dBm Vtune=2-12V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4t1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc582.html",
//     mfgPart: "HMC582LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4T1-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 11100, 12400, 9, 200, 2, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc530.pdf",
//     description: "F=9.5G-10.8G Pout=11dBm Vtune=2-12V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4t2-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc530.html",
//     mfgPart: "HMC530LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4T2-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 9500, 10800, 11, 150, 2, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc512.pdf",
//     description: "F=9.6G-10.8G Pout=9dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4t3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc512.html",
//     mfgPart: "HMC512LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4T3-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 9600, 10800, 9, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc584.pdf",
//     description: "F=12.5G-13.9G Pout=10dBm Vtune=2-12V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4t4-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc584.html",
//     mfgPart: "HMC584LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4T4-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 12500, 13900, 10, 250, 2, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc583.pdf",
//     description: "F=11.5G-12.8G Pout=11dBm Vtune=2-12V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4t5-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc583.html",
//     mfgPart: "HMC583LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4T5-0409D",
//     price: 18100,
//     priceType: "cents",
//     props: [null, 11500, 12800, 11, 200, 2, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc534.pdf",
//     description: "F=10.6G-11.8G Pout=11dBm Vtune=2-12V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4t6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc534.html",
//     mfgPart: "HMC534LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4T6-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 10600, 11800, 11, 200, 2, 12, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc510.pdf",
//     description: "F=8.45G-9.55G Pout=13dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4t7-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc510.html",
//     mfgPart: "HMC510LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4T7-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 8450, 9550, 13, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc531.pdf",
//     description: "F=13.6G-14.9G Pout=10dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4t8-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc531.html",
//     mfgPart: "HMC531LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4T8-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 13600, 14900, 10, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc529.pdf",
//     description: "F=12.4G-13.4G Pout=8dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4t9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc529.html",
//     mfgPart: "HMC529LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4T9-0409D",
//     price: 17900,
//     priceType: "cents",
//     props: [null, 12400, 13400, 8, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc515.pdf",
//     description: "F=11.5G-12.5G Pout=10dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4u1-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc515.html",
//     mfgPart: "HMC515LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4U1-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 11500, 12500, 10, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc514.pdf",
//     description: "F=11.17G-12.02G Pout=7dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4u2-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc514.html",
//     mfgPart: "HMC514LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4U2-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 11170, 12020, 7, 100, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc513.pdf",
//     description: "F=10.43G-11.46G Pout=7dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4u3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc513.html",
//     mfgPart: "HMC513LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4U3-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 10430, 11460, 7, 125, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc511.pdf",
//     description: "F=9.05G-10.15G Pout=13dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4u4-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc511.html",
//     mfgPart: "HMC511LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4U4-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 9050, 10150, 13, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc509.pdf",
//     description: "F=7.8G-8.8G Pout=13dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4u5-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc509.html",
//     mfgPart: "HMC509LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4U5-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 7800, 8800, 13, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc508.pdf",
//     description: "F=7.3G-8.2G Pout=15dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4u6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc508.html",
//     mfgPart: "HMC508LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4U6-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 7300, 8200, 15, 150, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0359",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc507.pdf",
//     description: "F=6.65G-7.65G Pout=13dBm Vtune=2-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0359C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4u7-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc507.html",
//     mfgPart: "HMC507LP5E",
//     models: "this part is missing models",
//     pn: "XM-A4U7-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: [null, 6650, 7650, 13, 100, 2, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0523",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc737.pdf",
//     description: "F=14.9G-15.5G Pout=9dBm Vtune=1-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0523A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6f6-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc737.html",
//     mfgPart: "HMC737LP4E",
//     models: "this part is missing models",
//     pn: "XM-A6F6-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 14900, 15500, 9, 100, 1, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0523",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc736.pdf",
//     description: "F=14.5G-15G Pout=9dBm Vtune=1-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0523A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6f7-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc736.html",
//     mfgPart: "HMC736LP4E",
//     models: "this part is missing models",
//     pn: "XM-A6F7-0409D",
//     price: 18500,
//     priceType: "cents",
//     props: [null, 14500, 15000, 9, 100, 1, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0523",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc738.pdf",
//     description: "F=20.9G-23.9G Pout=9dBm Vtune=1-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0523A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6f8-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc738.html",
//     mfgPart: "HMC738LP4E",
//     models: "this part is missing models",
//     pn: "XM-A6F8-0409D",
//     price: 22100,
//     priceType: "cents",
//     props: [null, 20900, 23900, 9, 400, 1, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0523",
//     catId: "OSC",
//     ctlBoard: "XM-A7A1-0404D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc739.pdf",
//     description: "F=23.8G-26.8G Pout=8dBm Vtune=1-13V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0523A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a6f9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc739.html",
//     mfgPart: "HMC739LP4E",
//     models: "this part is missing models",
//     pn: "XM-A6F9-0409D",
//     price: 21200,
//     priceType: "cents",
//     props: [null, 23800, 26800, 8, 400, 1, 13, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0750",
//     catId: "OSC",
//     ctlBoard: "XM-A7T8-0404D",
//     datasheetURL: "http://www.conwin.com/datasheets/tx/tx380.pdf",
//     description: "TCXO F=50M Accuracy=+/-0.28ppm PN=-145at10K",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0750A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7j3-0404d/",
//     mfgCode: "CONOR",
//     mfgLandingPage: "http://www.conwin.com/oscillators-tcxo.html",
//     mfgPart: "TB602-050.0M",
//     models: "this part is missing models",
//     pn: "XM-A7J3-0404D",
//     price: 11200,
//     priceType: "cents",
//     props: [50, 0.28, null, -14510, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0750_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0750",
//     catId: "OSC",
//     ctlBoard: "XM-A7T8-0404D",
//     datasheetURL: "http://www.conwin.com/datasheets/tx/tx380.pdf",
//     description: "TCXO F=100M Accuracy=+/-1ppm PN=-142at10KHz",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0750A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7t7-0404d/",
//     mfgCode: "CONOR",
//     mfgLandingPage: "http://www.conwin.com/oscillators-tcxo.html",
//     mfgPart: "TB522-100.0M",
//     models: "this part is missing models",
//     pn: "XM-A7T7-0404D",
//     price: 10400,
//     priceType: "cents",
//     props: [100, 1, null, -14210, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0750_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0234",
//     catId: "OSC",
//     ctlBoard: "XM-B1E5-0404D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/HMC6380.pdf",
//     description: "F=8G-16G Pout=6dBm Vtune=0-23V",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0234K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1d4-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc6380.html",
//     mfgPart: "HMC6380LC4B",
//     models: "this part is missing models",
//     pn: "XM-B1D4-0404D",
//     price: 49900,
//     priceType: "cents",
//     props: [null, 8000, 16000, 6, 450, 0, 23, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0234_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1249",
//     catId: "OSC",
//     ctlBoard: "XM-A2J5-0404D",
//     datasheetURL: "http://www.wenzel.com/wp-content/parts/501-31724.pdf",
//     description: "Crystal F=100M PN= -176 dBc/Hz at 10kHz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b2r4-1009d/",
//     mfgCode: "WNZEL",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "501-31724-11",
//     models: "this is the new part",
//     pn: "XM-B2R4-1009D",
//     price: 168900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "1249_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0832",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc466.pdf",
//     description: "F=6.1G-6.72G Pout=5dBm Vtune=0-10V",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0832A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7h1-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc466.html",
//     mfgPart: "HMC466LP4",
//     models: "this part is missing models",
//     pn: "XM-B7H1-0404D",
//     price: 10900,
//     priceType: "cents",
//     props: [null, 6100, 6720, 5, 100, 0, 10, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0832_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0832",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc505.pdf",
//     description: "F=6.8G-7.4G Pout=11dBm Vtune=1-11V",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0832A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7h2-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc505.html",
//     mfgPart: "HMC505LP4",
//     models: "this part is missing models",
//     pn: "XM-B7H2-0404D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 6800, 7400, 11, 100, 1, 11, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0832_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0832",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc506.pdf",
//     description: "F=7.8G-8.7G Pout=14dBm Vtune=1-11V",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0832A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7h3-0404d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/vcos-plos/vcos-with-single-output/hmc506.html",
//     mfgPart: "HMC506LP4",
//     models: "this part is missing models",
//     pn: "XM-B7H3-0404D",
//     price: 12700,
//     priceType: "cents",
//     props: [null, 7800, 8700, 14, 120, 1, 11, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H3",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0832_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1249",
//     catId: "OSC",
//     ctlBoard: "XM-A2J5-0404D",
//     datasheetURL: "http://www.wenzel.com/wp-content/parts/501-31724.pdf",
//     description: "Crystal F=100M PN=-173 dBc/Hz",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9g7-1009d/",
//     mfgCode: "WNZEL",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "501-31724-01",
//     models: "this is the new part",
//     pn: "XM-B9G7-1009D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "1249_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://synergymwave.com/products/vco/datasheet/DCMO1027.pdf",
//     description: "F=100M-270M Pout=4dBm Vtune=0.5-24V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9k5-0809d/",
//     mfgCode: "SYNRG",
//     mfgLandingPage: "https://synergymwave.com/products/vco/",
//     mfgPart: "DCMO1027",
//     models: "this part is missing models",
//     pn: "XM-B9K5-0809D",
//     price: 25100,
//     priceType: "cents",
//     props: [null, 100, 270, 4, 7.45, 0.5, 24, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-200-719R+.pdf",
//     description: "F=144M-200M Pout=9dBm Vtune=0.5-16V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c127-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ROS-200-719R%2B",
//     mfgPart: "ROS-200-719R+",
//     models: "this part is missing models",
//     pn: "XM-C127-0809D",
//     price: 25300,
//     priceType: "cents",
//     props: [null, 144, 200, 9, 5, 0.5, 16, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "1979_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-244R+.pdf",
//     description: "F=170M-244M Pout=10dBm Vtune=2-16V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c128-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ROS-244R%2B",
//     mfgPart: "ROS-244R+",
//     models: "this part is missing models",
//     pn: "XM-C128-0809D",
//     price: 25300,
//     priceType: "cents",
//     props: [null, 170, 244, 10, 10, 2, 16, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "1979_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-435-119+.pdf",
//     description: "F=425M-435M Pout=2.5dBm Vtune=0.5-10V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c135-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ROS-435-119%2B",
//     mfgPart: "ROS-435-119+",
//     models: "this part is missing models",
//     pn: "XM-C135-0809D",
//     price: 23600,
//     priceType: "cents",
//     props: [null, 425, 435, 2.5, 6, 0.5, 10, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-1445-219+.pdf",
//     description: "F=1.145G-1.445G Pout=8dBm Vtune=1-20V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c152-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-1445-219%2B",
//     mfgPart: "ROS-1445-219+",
//     models: "this part is missing models",
//     pn: "XM-C152-0809D",
//     price: 23600,
//     priceType: "cents",
//     props: [null, 1145, 1445, 8, 42, 1, 20, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-2160W+.pdf",
//     description: "F=1.16G-2.16G Pout=5dBm Vtune=0.5-20V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c162-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2160W%2B",
//     mfgPart: "ROS-2160W+",
//     models: "this part is missing models",
//     pn: "XM-C162-0809D",
//     price: 20900,
//     priceType: "cents",
//     props: [null, 1160, 2160, 5, 80, 0.5, 20, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-3360R+.pdf",
//     description: "F=2.12G-3.36G Pout=9dBm Vtune=0.5-18V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c187-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-3360R%2B",
//     mfgPart: "ROS-3360R+",
//     models: "this part is missing models",
//     pn: "XM-C187-0809D",
//     price: 24400,
//     priceType: "cents",
//     props: [null, 2120, 3360, 9, 123, 0.5, 18, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-3600-419R+.pdf",
//     description: "F=3.3G-3.605G Pout=8.5dBm Vtune=0.5-8V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c189-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-3600-419R%2B",
//     mfgPart: "ROS-3600-419R+",
//     models: "this part is missing models",
//     pn: "XM-C189-0809D",
//     price: 22000,
//     priceType: "cents",
//     props: [null, 3300, 3605, 8.5, 78, 0.5, 8, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-3800-119R+.pdf",
//     description: "F=1.9G-3.7G Pout=6dBm Vtune=0.5-20V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c192-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-3800-119R%2B",
//     mfgPart: "ROS-3800-119R+",
//     models: "this part is missing models",
//     pn: "XM-C192-0809D",
//     price: 27100,
//     priceType: "cents",
//     props: [null, 1900, 3700, 6, 150, 0.5, 20, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-4000-419R+.pdf",
//     description: "F=3.85G-4G Pout=5dBm Vtune=0.5-10V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c194-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-4000-419R%2B",
//     mfgPart: "ROS-4000-419R+",
//     models: "this part is missing models",
//     pn: "XM-C194-0809D",
//     price: 22000,
//     priceType: "cents",
//     props: [null, 3850, 4000, 5, 36, 0.5, 10, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-4415-119R+.pdf",
//     description: "F=4.214G-4.415G Pout=5dBm Vtune=1-10V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c195-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-4415-119R%2B",
//     mfgPart: "ROS-4415-119R+",
//     models: "this part is missing models",
//     pn: "XM-C195-0809D",
//     price: 22800,
//     priceType: "cents",
//     props: [null, 4214, 4415, 5, 57, 1, 10, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-365-119R+.pdf",
//     description: "F=335M-365M Pout=6.5dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c216-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-365-119R%2B",
//     mfgPart: "ROS-365-119R+",
//     models: "this part is missing models",
//     pn: "XM-C216-0809D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 335, 365, 6.5, 12, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "1979_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-485-119R+.pdf",
//     description: "F=450M-485M Pout=4.5dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c224-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-485-119R%2B",
//     mfgPart: "ROS-485-119R+",
//     models: "this part is missing models",
//     pn: "XM-C224-0809D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 450, 485, 4.5, 12, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "1979_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-498-119R+.pdf",
//     description: "F=449M-499M Pout=5dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c225-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-498-119R%2B",
//     mfgPart: "ROS-498-119R+",
//     models: "this part is missing models",
//     pn: "XM-C225-0809D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 449, 499, 5, 13, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "1979_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-615R+.pdf",
//     description: "F=580M-615M Pout=-1dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c232-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-615R%2B",
//     mfgPart: "ROS-615R+",
//     models: "this part is missing models",
//     pn: "XM-C232-0809D",
//     price: 0,
//     priceType: "cents",
//     props: [null, 580, 615, -1, 13, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "1979_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-1700-819R+.pdf",
//     description: "F=1.69G-1.74G Pout=1dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c264-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-1700-819R%2B",
//     mfgPart: "ROS-1700-819R+",
//     models: "this part is missing models",
//     pn: "XM-C264-0809D",
//     price: 23600,
//     priceType: "cents",
//     props: [null, 1690, 1740, 1, 22, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-2045-219R+.pdf",
//     description: "F=1.9G-2G Pout=6.5dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c271-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2045-219R%2B",
//     mfgPart: "ROS-2045-219R+",
//     models: "this part is missing models",
//     pn: "XM-C271-0809D",
//     price: 24400,
//     priceType: "cents",
//     props: [null, 1900, 2000, 6.5, 56, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-5815C-119+.pdf",
//     description: "F=5.685G-5.815G Pout=3.5dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c285-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-5815C-119%2B",
//     mfgPart: "ROS-5815C-119+",
//     models: "this part is missing models",
//     pn: "XM-C285-0809D",
//     price: 24400,
//     priceType: "cents",
//     props: [null, 5685, 5815, 3.5, 80, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-39702PH19R+.pdf",
//     description: "F=3.79G-3.97G Pout=3.5dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c287-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-39702PH19R%2B",
//     mfgPart: "ROS-39702PH19R+",
//     models: "this part is missing models",
//     pn: "XM-C287-0809D",
//     price: 21100,
//     priceType: "cents",
//     props: [null, 3790, 3970, 3.5, 63, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-890CR+.pdf",
//     description: "F=875M-890M Pout=6.2dBm Vtune=0.5-11V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c288-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-890CR%2B",
//     mfgPart: "ROS-890CR+",
//     models: "this part is missing models",
//     pn: "XM-C288-0809D",
//     price: 23600,
//     priceType: "cents",
//     props: [null, 875, 890, 6.2, 5, 0.5, 11, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-1150C-119R+.pdf",
//     description: "F=1.146G-1.154G Pout=3dBm Vtune=0.5-11V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c291-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-1150C-119R%2B",
//     mfgPart: "ROS-1150C-119R+",
//     models: "this part is missing models",
//     pn: "XM-C291-0809D",
//     price: 23600,
//     priceType: "cents",
//     props: [null, 1146, 1154, 3, 3, 0.5, 11, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-1801C-119+.pdf",
//     description: "F=1.8G-1.8G Pout=6dBm Vtune=0.5-9.5V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c294-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-1801C-119%2B",
//     mfgPart: "ROS-1801C-119+",
//     models: "this part is missing models",
//     pn: "XM-C294-0809D",
//     price: 23600,
//     priceType: "cents",
//     props: [null, 1800, 1800, 6, 1.5, 0.5, 9.5, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-2760C-119R+.pdf",
//     description: "F=2.6G-2.76G Pout=4dBm Vtune=0.5-18V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c312-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2760C-119R%2B",
//     mfgPart: "ROS-2760C-119R+",
//     models: "this part is missing models",
//     pn: "XM-C312-0809D",
//     price: 23600,
//     priceType: "cents",
//     props: [null, 2600, 2760, 4, 16, 0.5, 18, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-4861C-119+.pdf",
//     description: "F=4.859G-4.861G Pout=7.5dBm Vtune=0.5-8V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c316-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-4861C-119%2B",
//     mfgPart: "ROS-4861C-119+",
//     models: "this part is missing models",
//     pn: "XM-C316-0809D",
//     price: 25300,
//     priceType: "cents",
//     props: [null, 4859, 4861, 7.5, 6, 0.5, 8, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-800C-119R+.pdf",
//     description: "F=798M-803M Pout=5.3dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c317-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-800C-119R%2B",
//     mfgPart: "ROS-800C-119R+",
//     models: "this part is missing models",
//     pn: "XM-C317-0809D",
//     price: 22700,
//     priceType: "cents",
//     props: [null, 798, 803, 5.3, 3.5, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-809C-119+.pdf",
//     description: "F=768M-798M Pout=4dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c318-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-809C-119%2B",
//     mfgPart: "ROS-809C-119+",
//     models: "this part is missing models",
//     pn: "XM-C318-0809D",
//     price: 23600,
//     priceType: "cents",
//     props: [null, 768, 798, 4, 13, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-933C-119+.pdf",
//     description: "F=933M-933M Pout=0dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c319-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-933C-119%2B",
//     mfgPart: "ROS-933C-119+",
//     models: "this part is missing models",
//     pn: "XM-C319-0809D",
//     price: 23600,
//     priceType: "cents",
//     props: [null, 933, 933, 0, 3.8, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-2488C-119+.pdf",
//     description: "F=2.488G-2.488G Pout=8.5dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c325-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2488C-119%2B",
//     mfgPart: "ROS-2488C-119+",
//     models: "this part is missing models",
//     pn: "XM-C325-0809D",
//     price: 23200,
//     priceType: "cents",
//     props: [null, 2488, 2488, 8.5, 4.4, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ROS-6030C-219R+.pdf",
//     description: "F=5.89G-6.01G Pout=3.5dBm",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c339-0809d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-6030C-219R%2B",
//     mfgPart: "ROS-6030C-219R+",
//     models: "this part is missing models",
//     pn: "XM-C339-0809D",
//     price: 24400,
//     priceType: "cents",
//     props: [null, 5890, 6010, 3.5, 73, 0, 0, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0084",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.crystek.com/microwave/admin/webapps/welcome/files/vco/CVCO55CC-1443-1523.pdf",
//     description: "F=1.443G-1.523G Pout=2dBm Vtune=1-11V",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0084I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c381-0809d/",
//     mfgCode: "CRSTK",
//     mfgLandingPage:
//       "https://www.crystek.com/home/vco/cvcodetail.aspx?pn=CVCO55CC-1443-1523",
//     mfgPart: "CVCO55CC-1443-1523",
//     models: "this part is missing models",
//     pn: "XM-C381-0809D",
//     price: 23200,
//     priceType: "cents",
//     props: [null, 1443, 1523, 2, 10, 1, 11, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1353",
//     catId: "OSC",
//     ctlBoard: "XM-C746-0404D",
//     datasheetURL:
//       "https://www.crystek.com/crystal/spec-sheets/vcxo/CVCSO-914-1000.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c745-0804d/",
//     mfgCode: "CRSTK",
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "CVCSO-914-1000",
//     models: "this is the new part",
//     pn: "XM-C745-0804D",
//     price: 19800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "1353_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1353",
//     catId: "OSC",
//     ctlBoard: "XM-C746-0404D",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c7y9-0804d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "CVCSO-914-640",
//     models: "this is the new part",
//     pn: "XM-C7Y9-0804D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "1353_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0750",
//     catId: "OSC",
//     ctlBoard: "XM-A7T8-0404D",
//     datasheetURL: "http://www.conwin.com/datasheets/tx/tx380.pdf",
//     description: "TCXO F=10M Accuracy=0.28ppm PN=-151at10KHz XM-A7T8-0404D",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0750A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c9a5-0404d/",
//     mfgCode: "CONOR",
//     mfgLandingPage: "http://www.conwin.com/oscillators-tcxo.html",
//     mfgPart: "T602-010.0M",
//     models: "this part is missing models",
//     pn: "XM-C9A5-0404D",
//     price: 9900,
//     priceType: "cents",
//     props: [10, 0.28, null, -15110, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0750_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/adf5709.html#product-overview",
//     mfgPart: "ADF5709",
//     models: "this is the new part",
//     pn: "XM-B9A5-0404D",
//     price: 49700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0234_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=3.9G - 4.45G Pout=5dBm Vtune=0-10V",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc391.html",
//     mfgPart: "HMC391LP4E",
//     models: "this is the new part",
//     pn: "XM-D123-0404D",
//     price: 10500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0832_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc358.html",
//     mfgPart: "HMC358MS8GE",
//     models: "this is the new part",
//     pn: "XM-A516-0404D",
//     price: 13000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "2078_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-1121V%2B",
//     mfgPart: "ROS-1121V+",
//     models: "this is the new part",
//     pn: "XM-C147-0809D",
//     price: 20200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-1836%2B",
//     mfgPart: "ROS-1836+",
//     models: "this is the new part",
//     pn: "XM-C266-0809D",
//     price: 20400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-1910-419%2B",
//     mfgPart: "ROS-1910-419+",
//     models: "this is the new part",
//     pn: "XM-C268-0809D",
//     price: 21400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2015%2B",
//     mfgPart: "ROS-2015+",
//     models: "this is the new part",
//     pn: "XM-C269-0809D",
//     price: 22100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2350-519%2B",
//     mfgPart: "ROS-2350-519+",
//     models: "this is the new part",
//     pn: "XM-C166-0809D",
//     price: 20200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2435%2B",
//     mfgPart: "ROS-2435+",
//     models: "this is the new part",
//     pn: "XM-C324-0809D",
//     price: 21800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2450C%2B",
//     mfgPart: "ROS-2450C+",
//     models: "this is the new part",
//     pn: "XM-C297-0809D",
//     price: 20000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2490C%2B",
//     mfgPart: "ROS-2490C+",
//     models: "this is the new part",
//     pn: "XM-C298-0809D",
//     price: 20000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2500-2319%2B",
//     mfgPart: "ROS-2500-2319+",
//     models: "this is the new part",
//     pn: "XM-C278-0809D",
//     price: 20400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2536C-119%2B",
//     mfgPart: "ROS-2536C-119+",
//     models: "this is the new part",
//     pn: "XM-C326-0809D",
//     price: 20700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2600-1119%2B",
//     mfgPart: "ROS-2600-1119+",
//     models: "this is the new part",
//     pn: "XM-C174-0809D",
//     price: 21400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2600C%2B",
//     mfgPart: "ROS-2600C+",
//     models: "this is the new part",
//     pn: "XM-C299-0809D",
//     price: 20000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2650-519%2B",
//     mfgPart: "ROS-2650-519+",
//     models: "this is the new part",
//     pn: "XM-C279-0809D",
//     price: 20400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2700-1819%2B",
//     mfgPart: "ROS-2700-1819+",
//     models: "this is the new part",
//     pn: "XM-C177-0809D",
//     price: 21400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2750%2B",
//     mfgPart: "ROS-2750+",
//     models: "this is the new part",
//     pn: "XM-C311-0809D",
//     price: 20400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2920-219%2B",
//     mfgPart: "ROS-2920-219+",
//     models: "this is the new part",
//     pn: "XM-C281-0809D",
//     price: 20400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-2960C-119%2B",
//     mfgPart: "ROS-2960C-119+",
//     models: "this is the new part",
//     pn: "XM-B2D8-0809D",
//     price: 23200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-3000-619%2B",
//     mfgPart: "ROS-3000-619+",
//     models: "this is the new part",
//     pn: "XM-C282-0809D",
//     price: 22700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-3000-819%2B",
//     mfgPart: "ROS-3000-819+",
//     models: "this is the new part",
//     pn: "XM-C182-0809D",
//     price: 21400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-933C-119%2B",
//     mfgPart: "ROS-3050C+",
//     models: "this is the new part",
//     pn: "XM-B2D9-0809D",
//     price: 23200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-3146-119%2B",
//     mfgPart: "ROS-3146-119+",
//     models: "this is the new part",
//     pn: "XM-C329-0809D",
//     price: 20700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-3570-319%2B",
//     mfgPart: "ROS-3570-319+",
//     models: "this is the new part",
//     pn: "XM-C348-0809D",
//     price: 20200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-386-119%2B",
//     mfgPart: "ROS-386-119+",
//     models: "this is the new part",
//     pn: "XM-C133-0809D",
//     price: 20200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-4303-119%2B",
//     mfgPart: "ROS-4303-119+",
//     models: "this is the new part",
//     pn: "XM-C283-0809D",
//     price: 20400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-5363C-119%2B",
//     mfgPart: "ROS-5363C-119+",
//     models: "this is the new part",
//     pn: "XM-C335-0809D",
//     price: 22300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-5580-119%2B",
//     mfgPart: "ROS-5580-119+",
//     models: "this is the new part",
//     pn: "XM-C337-0809D",
//     price: 22300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-5776-119%2B",
//     mfgPart: "ROS-5776-119+",
//     models: "this is the new part",
//     pn: "XM-C338-0809D",
//     price: 22300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-6520C-119%2B",
//     mfgPart: "ROS-6520C-119+",
//     models: "this is the new part",
//     pn: "XM-C341-0809D",
//     price: 22300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/modelSearch.html?model=ROS-6840C-119%2B",
//     mfgPart: "ROS-6840C-119+",
//     models: "this is the new part",
//     pn: "XM-C343-0809D",
//     price: 22300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0084_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc632.html",
//     mfgPart: "HMC632LP5E",
//     models: "this is the new part",
//     pn: "XM-A743-0409D",
//     price: 18400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://www.zcomm.com/products/voltage-controlled-oscillator-vco/cro-series/",
//     mfgPart: "CRO6835Z",
//     models: "this is the new part",
//     pn: "XM-D152-0809D",
//     price: 26200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "2131_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "SYNRG",
//     mfgLandingPage: "https://synergymwave.com/products/dro/",
//     mfgPart: "SDRO1024-8",
//     models: "this is the new part",
//     pn: "XM-C6B3-0909D",
//     price: 75400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "SYNRG",
//     mfgLandingPage: "https://synergymwave.com/products/dro/",
//     mfgPart: "SDRO900-8",
//     models: "this is the new part",
//     pn: "XM-C5K1-0909D",
//     price: 75400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "CRSTK",
//     mfgLandingPage:
//       "https://www.crystek.com/home/oscillator/clockdetail.aspx?pn=CCHD-575",
//     mfgPart: "CCHD-575X-25-100",
//     models: "this is the new part",
//     pn: "XM-A841-0404D",
//     price: 9400,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "2092_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "OSC",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "CRSTK",
//     mfgLandingPage:
//       "https://www.crystek.com/home/oscillator/vcxodetail.aspx?pn=CVSS-945",
//     mfgPart: "CVSS-945X-100.000",
//     models: "this is the new part",
//     pn: "XM-C6G5-0609D",
//     price: 17900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "1574_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0083",
//     catId: "OSC",
//     ctlBoard: "XM-A2J9-0404D",
//     datasheetURL: "http://www.nelfc.com/pdf/1319a.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a2l3-0809d/",
//     mfgCode: "NELFC",
//     mfgLandingPage: "http://www.nelfc.com/ocxo/index.html",
//     mfgPart: "O-CS8-0LT58EWU-L/100.000MHz",
//     models: "this is the new part",
//     pn: "XM-D4E2-0809D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Oscillators",
//     newBRDImage: "0083_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1677",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.ti.com/lit/ds/symlink/tmp421.pdf",
//     description:
//       "Temp Sensor, TMP421AQDCNRQ1 [PCB: 1677] <br>±1°C Remote and Local Temperature Sensor",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8a4-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "http://www.ti.com/product/TMP421",
//     mfgPart: "TMP421AQDCNRQ1 TI SMT",
//     models: "this is the new part",
//     pn: "XM-C8A4-0404D",
//     price: 7100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Other",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "Temp Sensor, LTC2991CMS#PBF [PCB: 2422]",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/ltc2991.html",
//     mfgPart: "LTC2991CMS#PBF",
//     models: "this is the new part",
//     pn: "XM-D2Y9-0604D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Other",
//     newBRDImage: "2422_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0301",
//     catId: "PAC",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe46120ds.pdf",
//     description: "F=1.8G-2.2G MaxAtten=7.5dB AttenStep=.5dB PhaseStep=2.8deg",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0301B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3l3-0609d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "http://www.psemi.com/products/monolithic-phase-amplitude-controllers-mpac/pe46120",
//     mfgPart: "PE46120SMT",
//     models: "this part is missing models",
//     pn: "XM-A3L3-0609D",
//     price: 16000,
//     priceType: "cents",
//     props: [1800, 2200, 7.5, 0.5, 87.2, 2.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K9",
//     newCategoryDescription: "Phase Amplitude Controllers",
//     newBRDImage: "0301_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0301",
//     catId: "PAC",
//     ctlBoard: "TBD",
//     datasheetURL: "http://www.psemi.com/pdf/datasheets/pe46130ds.pdf",
//     description: "F=2.3G-2.7G MaxAtten=7.5dB AttenStep=.5dB PhaseStep=2.8deg",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0301B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3l4-0609d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "http://www.psemi.com/products/monolithic-phase-amplitude-controllers-mpac/pe46130",
//     mfgPart: "PE46130SMT",
//     models: "this part is missing models",
//     pn: "XM-A3L4-0609D",
//     price: 16400,
//     priceType: "cents",
//     props: [2300, 2700, 7.5, 0.5, 87.2, 2.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K9",
//     newCategoryDescription: "Phase Amplitude Controllers",
//     newBRDImage: "0301_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0301",
//     catId: "PAC",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.psemi.com/pdf/datasheets/pe46140ds.pdf",
//     description: "F=2.3G-2.7G MaxAtten=7.5dB AttenStep=.5dB PhaseStep=2.8deg",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0301B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3l5-0609d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "http://www.psemi.com/products/monolithic-phase-amplitude-controllers-mpac/pe46140",
//     mfgPart: "PE46140SMT",
//     models: "this part is missing models",
//     pn: "XM-A3L5-0609D",
//     price: 16400,
//     priceType: "cents",
//     props: [2300, 2700, 7.5, 0.5, 87.2, 2.8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K9",
//     newCategoryDescription: "Phase Amplitude Controllers",
//     newBRDImage: "0301_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0828",
//     catId: "PAC",
//     ctlBoard: "XM-B1F1-1908D",
//     datasheetURL:
//       "https://www.idt.com/document/sds/f6101-advance-short-form-datasheet",
//     description: "",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0828A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9g1-0819d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/interface-connectivity/millimeter-and-microwave-solutions-mmwave/f6101-ku-band-satcom-receive-sige-ic",
//     mfgPart: "F6101SMT",
//     models: "this part is missing models",
//     pn: "XM-A9G1-0819D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Phase Amplitude Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0828",
//     catId: "PAC",
//     ctlBoard: "XM-B1F1-1908D",
//     datasheetURL:
//       "https://www.idt.com/document/sds/f6102-advance-short-form-datasheet",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0828A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9g2-0819d/",
//     mfgCode: "IDEVT",
//     mfgLandingPage:
//       "https://www.idt.com/products/interface-connectivity/millimeter-and-microwave-solutions-mmwave/f6102-ka-band-satcom-receive-sige-ic",
//     mfgPart: "F6102SMT",
//     models: "this part is missing models",
//     pn: "XM-A9G2-0819D",
//     price: 0,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Phase Amplitude Controllers",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1818",
//     catId: "PAC",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADAR1000.pdf",
//     description: "",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1818A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b933-1919d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/en/products/adar1000.html#product-overview",
//     mfgPart: "ADAR1000SMT",
//     models: "this part is missing models",
//     pn: "XM-B933-1919D",
//     price: 350000,
//     priceType: "cents",
//     props: "this part is missing props",
//     quote: "",
//     sparamLinks: [],
//     testCode: "#N/A",
//     newCategoryDescription: "Phase Amplitude Controllers",
//     newBRDImage: "1818_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0357",
//     catId: "PLL",
//     ctlBoard: "XM-A4G7-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4169.pdf",
//     description: "F=9.65G-10.41G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0357D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3x3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4169.html#product-overview",
//     mfgPart: "ADF4169CCPZ-RL7SMT",
//     models: "this part is missing models",
//     pn: "XM-A3X3-0409D",
//     price: 23600,
//     priceType: "cents",
//     props: [9650, 10410, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "0357_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0357",
//     catId: "PLL",
//     ctlBoard: "XM-A4G7-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4169.pdf",
//     description: "F=4G-8G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0357D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3x3-0409d-01/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4169.html#product-overview",
//     mfgPart: "ADF4169CCPZ-RL7SMT",
//     models: "this part is missing models",
//     pn: "XM-A3X3-0409D-01",
//     price: 0,
//     priceType: "cents",
//     props: [4000, 8000, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "0357_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0357",
//     catId: "PLL",
//     ctlBoard: "XM-A4G7-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4169.pdf",
//     description: "F=9.05G-10.15G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0357D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3x3-0409d-02/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4169.html#product-overview",
//     mfgPart: "ADF4169CCPZ-RL7SMT",
//     models: "this part is missing models",
//     pn: "XM-A3X3-0409D-02",
//     price: 0,
//     priceType: "cents",
//     props: [9050, 10150, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "0357_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0357",
//     catId: "PLL",
//     ctlBoard: "XM-A4G7-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4169.pdf",
//     description: "F=8.45G-9.55G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0357D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3x3-0409d-03/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4169.html#product-overview",
//     mfgPart: "ADF4169CCPZ-RL7SMT",
//     models: "this part is missing models",
//     pn: "XM-A3X3-0409D-03",
//     price: 0,
//     priceType: "cents",
//     props: [8450, 9550, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "0357_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0357",
//     catId: "PLL",
//     ctlBoard: "XM-A746-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4169.pdf",
//     description: "F=10G-20G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0357D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3x3-0409d-04/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4169.html#product-overview",
//     mfgPart: "ADF4169CCPZ-RL7SMT",
//     models: "this part is missing models",
//     pn: "XM-A3X3-0409D-04",
//     price: 0,
//     priceType: "cents",
//     props: [10000, 20000, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "0357_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0357",
//     catId: "PLL",
//     ctlBoard: "XM-A4G7-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/ADF4169.pdf",
//     description: "F=14.25G-15.65G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0357D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3x3-0409d-05/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4169.html#product-overview",
//     mfgPart: "ADF4169CCPZ-RL7SMT",
//     models: "this part is missing models",
//     pn: "XM-A3X3-0409D-05",
//     price: 0,
//     priceType: "cents",
//     props: [14250, 15650, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "0357_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0357",
//     catId: "PLL",
//     ctlBoard: "XM-A4G7-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADF4159.pdf",
//     description: "F=DC-13G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0357D.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5n5-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/fractional-n-pll/adf4159.html",
//     mfgPart: "ADF4159SMT",
//     models: "this part is missing models",
//     pn: "XM-A5N5-0409D",
//     price: 23200,
//     priceType: "cents",
//     props: [0, 13000, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "0357_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0825",
//     catId: "PLL",
//     ctlBoard: "XM-A7D1-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc440.pdf",
//     description: "F=10M-2.8G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0825A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7c9-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/clock-and-timing/phase-locked-loop/integer-n-pll/hmc440.html",
//     mfgPart: "HMC440QS16GESMT",
//     models: "this part is missing models",
//     pn: "XM-A7C9-0409D",
//     price: 37700,
//     priceType: "cents",
//     props: [10, 2800, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1092",
//     catId: "PLL",
//     ctlBoard: "XM-B2E6-0409D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC3716.pdf",
//     description: "F=10M-1.3G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1092B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2e5-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "http://www.analog.com/en/products/hmc3716.html",
//     mfgPart: "HMC3716LP4ESMT",
//     models: "this part is missing models",
//     pn: "XM-B2E5-0409D",
//     price: 24500,
//     priceType: "cents",
//     props: [10, 1300, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1573",
//     catId: "PLL",
//     ctlBoard: "XM-C6G4-0409D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADF4002.pdf",
//     description: "F=40M-400M ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1573A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6g3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adf4002.html",
//     mfgPart: "ADF4002BCPZSMT",
//     models: "this part is missing models",
//     pn: "XM-C6G3-0409D",
//     price: 21400,
//     priceType: "cents",
//     props: [40, 400, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1327",
//     catId: "PLL",
//     ctlBoard: "XM-C7Y8-0609D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADF41513.pdf",
//     description: "F=1G-26.5G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1327A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7c2-0609d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adf41513.html",
//     mfgPart: "ADF41513SMT",
//     models: "this part is missing models",
//     pn: "XM-C7C2-0609D",
//     price: 35200,
//     priceType: "cents",
//     props: [1000, 26500, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1327",
//     catId: "PLL",
//     ctlBoard: "XM-C8G1-0609D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/ADF41513.pdf",
//     description: "F=1G-26.5G ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1327A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8f9-0609d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adf41513.html",
//     mfgPart: "ADF41513SMT",
//     models: "this part is missing models",
//     pn: "XM-C8F9-0609D",
//     price: 35200,
//     priceType: "cents",
//     props: [1000, 26500, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "H9",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1715",
//     catId: "PLL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc699.pdf",
//     description: "F=160M-7G ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1715B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c989-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc699.html",
//     mfgPart: "HMC699LP5SMT",
//     models: "this part is missing models",
//     pn: "XM-C989-0409D",
//     price: 38500,
//     priceType: "cents",
//     props: [160, 7000, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "1715_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1715",
//     catId: "PLL",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc699.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9g3-0409d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc699.html",
//     mfgPart: "HMC699LP5SMT",
//     models: "this is the new part",
//     pn: "XM-C9G3-0409D",
//     price: 38300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "1715_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PLL",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc699.html",
//     mfgPart: "HMC699LP5E",
//     models: "this is the new part",
//     pn: "XM-D446-0409D",
//     price: 38000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Lock Loops",
//     newBRDImage: "1715_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0407",
//     catId: "PSH",
//     ctlBoard: "XM-B9A9-0604D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAPS-010146.pdf",
//     description: "F=8G-12G MaxShift=360deg Step=22.5deg IL=6.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0407C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3v9-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAPS-010146",
//     mfgPart: "MAPS-010146SMT",
//     models: "this part is missing models",
//     pn: "XM-A3V9-0604D",
//     price: 19900,
//     priceType: "cents",
//     props: [8000, 12000, 360, 22.5, null, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "0407_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0407",
//     catId: "PSH",
//     ctlBoard: "XM-B9A9-0604D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAPS-010166.pdf",
//     description: "F=8G-12G MaxShift=360deg Step=5.6deg IL=6.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0407C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3w1-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAPS-010166",
//     mfgPart: "MAPS-010166SMT",
//     models: "this part is missing models",
//     pn: "XM-A3W1-0604D",
//     price: 28500,
//     priceType: "cents",
//     props: [8000, 12000, 360, 5.6, null, 6.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "0407_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0441",
//     catId: "PSH",
//     ctlBoard: "XM-A4Y8-0404D",
//     datasheetURL: "https://www.custommmic.com/resources/cmd175p4-0518.pdf",
//     description: "F=2G-4G MaxShift=360deg Step=11.25deg IL=7.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0441B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4y7-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD175P4",
//     mfgPart: "CMD175P4SMT",
//     models: "this part is missing models",
//     pn: "XM-A4Y7-0604D",
//     price: 27700,
//     priceType: "cents",
//     props: [2000, 4000, 360, 11.25, null, 7.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0442",
//     catId: "PSH",
//     ctlBoard: "XM-A5A1-0404D",
//     datasheetURL:
//       "https://www.custommmic.com/resources/CMD176P4_ver_1.5_0719.pdf",
//     description: "F=13G-17G MaxShift=360deg Step=22.5deg IL=8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0442B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4y9-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD176P4",
//     mfgPart: "CMD176P4SMT",
//     models: "this part is missing models",
//     pn: "XM-A4Y9-0604D",
//     price: 27000,
//     priceType: "cents",
//     props: [13000, 17000, 360, 22.5, null, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0515",
//     catId: "PSH",
//     ctlBoard: "XM-A762-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/HMC647ALP6E.pdf",
//     description: "F=2.5G-3.1G MaxShift=360deg Step=5.625deg IL=4dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0515A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5f6-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/phase-shifters-vector-modulators/digital-phase-shifter/hmc647.html",
//     mfgPart: "HMC647ALP6ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5F6-0604D",
//     price: 29600,
//     priceType: "cents",
//     props: [2500, 3100, 360, 5.625, null, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0515",
//     catId: "PSH",
//     ctlBoard: "XM-A762-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc648a.pdf",
//     description: "F=2.9G-3.9G MaxShift=360deg Step=5.625deg IL=5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0515A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5f7-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/phase-shifters-vector-modulators/digital-phase-shifter/hmc648a.html",
//     mfgPart: "HMC648ALP6ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5F7-0604D",
//     price: 28500,
//     priceType: "cents",
//     props: [2900, 3900, 360, 5.625, null, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0515",
//     catId: "PSH",
//     ctlBoard: "XM-A762-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc649a.pdf",
//     description: "F=3G-6G MaxShift=360deg Step=5.625deg IL=8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0515A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5f8-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/phase-shifters-vector-modulators/digital-phase-shifter/hmc649a.html",
//     mfgPart: "HMC649ALP6ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5F8-0604D",
//     price: 30500,
//     priceType: "cents",
//     props: [3000, 6000, 360, 5.625, null, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0515",
//     catId: "PSH",
//     ctlBoard: "XM-B132-0604D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc936a.pdf",
//     description: "F=1.2G-1.4G MaxShift=360deg Step=5.625deg IL=5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0515A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5f9-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/phase-shifters-vector-modulators/digital-phase-shifter/hmc936a.html",
//     mfgPart: "HMC936ALP6ESMT",
//     models: "this part is missing models",
//     pn: "XM-A5F9-0604D",
//     price: 27400,
//     priceType: "cents",
//     props: [1200, 1400, 360, 5.625, null, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0738",
//     catId: "PSH",
//     ctlBoard: "XM-A773-0604D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005069",
//     description: "F=6G-18G MaxShift=360deg Step=5.625deg IL=10dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0738B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a772-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGP2105-SM",
//     mfgPart: "TGP2105-SMSMT",
//     models: "this part is missing models",
//     pn: "XM-A772-0604D",
//     price: 28700,
//     priceType: "cents",
//     props: [6000, 18000, 360, 5.625, null, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "0738_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0407",
//     catId: "PSH",
//     ctlBoard: "XM-B9A9-0604D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAPS-010164.pdf",
//     description: "F=2.3G-3.8G MaxShift=360deg Step=5.6deg IL=3.2dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0407C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a911-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAPS-010164",
//     mfgPart: "MAPS-010164SMT",
//     models: "this part is missing models",
//     pn: "XM-A911-0604D",
//     price: 23100,
//     priceType: "cents",
//     props: [2300, 3800, 360, 5.6, null, 3.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "0407_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0407",
//     catId: "PSH",
//     ctlBoard: "XM-B9A9-0604D",
//     datasheetURL: "https://cdn.macom.com/datasheets/MAPS-010163.pdf",
//     description: "F=1.4G-2.4G MaxShift=360deg Step=5.6deg IL=5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0407C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9p3-0604d/",
//     mfgCode: "MACOM",
//     mfgLandingPage: "https://www.macom.com/products/product-detail/MAPS-010163",
//     mfgPart: "MAPS-010163SMT",
//     models: "this part is missing models",
//     pn: "XM-A9P3-0604D",
//     price: 21800,
//     priceType: "cents",
//     props: [1400, 2400, 360, 5.6, null, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "0407_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1131",
//     catId: "PSH",
//     ctlBoard: "XM-B1B8-0204D",
//     datasheetURL:
//       "http://www.analog.com/media/en/technical-documentation/data-sheets/hmc247.pdf",
//     description: "F=5G-18G MaxShift=400deg IL=8dB ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1131A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a9p8-0204d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "http://www.analog.com/en/products/rf-microwave/phase-shifters-vector-modulators/analog-phase-shifter/hmc247-die.html",
//     mfgPart: "HMC247DIE",
//     models: "this part is missing models",
//     pn: "XM-A9P8-0204D",
//     price: 0,
//     priceType: "cents",
//     props: [5000, 18000, 400, 0, null, 8],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K1",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "1131_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1268",
//     catId: "PSH",
//     ctlBoard: "XM-B1V7-0604D",
//     datasheetURL: "https://www.psemi.com/pdf/datasheets/pe44820ds.pdf",
//     description: "F=1.7G-2.2G MaxShift=358.6deg Step=1.4deg IL=6dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1268A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1v6-0604d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://www.psemi.com/products/digital-phase-shifter-dps/pe44820",
//     mfgPart: "PE44820SMT",
//     models: "this part is missing models",
//     pn: "XM-B1V6-0604D",
//     price: 14400,
//     priceType: "cents",
//     props: [1700, 2200, 358.6, 1.4, null, 6],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "1268_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1268",
//     catId: "PSH",
//     ctlBoard: "XM-B228-0604D",
//     datasheetURL: "https://www.psemi.com/pdf/datasheets/pe44820ds.pdf",
//     description: "F=1.7G-2.2G MaxShift=358.6deg Step=1.4deg IL=6dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1268A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b227-0604d/",
//     mfgCode: {},
//     mfgLandingPage:
//       "https://www.psemi.com/products/digital-phase-shifter-dps/pe44820",
//     mfgPart: "PE44820SMT",
//     models: "this part is missing models",
//     pn: "XM-B227-0604D",
//     price: 10500,
//     priceType: "cents",
//     props: [1700, 2200, 358.6, 1.4, null, 6, 8, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "1268_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1011",
//     catId: "PSH",
//     ctlBoard: "XM-B2A7-0604D",
//     datasheetURL: "https://www.qorvo.com/products/d/da005062",
//     description: "F=32G-37G MaxShift=348.75deg IL=7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1011B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2a6-0604d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/TGP2102",
//     mfgPart: "TGP2102DIE",
//     models: "this part is missing models",
//     pn: "XM-B2A6-0604D",
//     price: 0,
//     priceType: "cents",
//     props: [32000, 37000, 348.75, 0, null, 7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "1011_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1079",
//     catId: "PSH",
//     ctlBoard: "XM-B5E2-0604D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc1133.pdf",
//     description: "F=4.8G-6.2G MaxShift=360deg Step=5.625deg IL=5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1079A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5e1-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/hmc1133.html",
//     mfgPart: "HMC1133LP5ESMT",
//     models: "this part is missing models",
//     pn: "XM-B5E1-0604D",
//     price: 27400,
//     priceType: "cents",
//     props: [4800, 6200, 360, 5.625, null, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1427",
//     catId: "PSH",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SPHSA-152%2B",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3n5-0806d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/PhaseShifters.html",
//     mfgPart: "SPHSA-152+SMT",
//     models: "this is the new part",
//     pn: "XM-C3N5-0806D",
//     price: 20300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "1427_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1427",
//     catId: "PSH",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SPHSA-251%2B",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3n6-0806d/",
//     mfgCode: "MINIC",
//     mfgLandingPage: "https://www.minicircuits.com/WebStore/PhaseShifters.html",
//     mfgPart: "SPHSA-251+SMT",
//     models: "this is the new part",
//     pn: "XM-C3N6-0806D",
//     price: 17900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "1427_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1600",
//     catId: "PSH",
//     ctlBoard: "XM-C8N1-0604D",
//     datasheetURL:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc642a.pdf",
//     description: "F=9G-12.5G MaxShift=360deg Step=5.625deg IL=7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1600A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c786-0604d/",
//     mfgCode: "ADINC",
//     mfgLandingPage:
//       "https://www.analog.com/media/en/technical-documentation/data-sheets/hmc642a.pdf",
//     mfgPart: "HMC642ALC5SMT",
//     models: "this part is missing models",
//     pn: "XM-C786-0604D",
//     price: 47200,
//     priceType: "cents",
//     props: [9000, 12500, 360, 5.625, null, 7, 6, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "K6",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PSH",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SPHSA-152%2B",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3n5-0806d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/CMD174P4",
//     mfgPart: "CMD174P4",
//     models: "this is the new part",
//     pn: "XM-A4Y5-0604D",
//     price: 20800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "PSH",
//     ctlBoard: "XM-A3V5-0404D",
//     datasheetURL:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SPHSA-251%2B",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c3n6-0806d/",
//     mfgCode: "SYNRG",
//     mfgLandingPage: "https://synergymwave.com/products/hybrids/",
//     mfgPart: "DQS-10-50",
//     models: "this is the new part",
//     pn: "XM-D153-0909D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Phase Shifters",
//     newBRDImage: "2141_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0045",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-1+.pdf",
//     description: "2-Way F=10M-400M IL=3.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0045H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1h4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-1%2B",
//     mfgPart: "ADP-2-1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1H4-0404D",
//     price: 7500,
//     priceType: "cents",
//     props: [10, 400, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0045_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0045",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-1W+.pdf",
//     description: "2-Way F=1M-650M IL=3.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0045H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1h5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-1W%2B",
//     mfgPart: "ADP-2-1W+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1H5-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [1, 650, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0045_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0045",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-4+.pdf",
//     description: "2-Way F=10M-1G IL=3.8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0045H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1h6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-4%2B",
//     mfgPart: "ADP-2-4+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1H6-0404D",
//     price: 8300,
//     priceType: "cents",
//     props: [10, 1000, 3.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0045_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0045",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-9+.pdf",
//     description: "2-Way F=200M-900M IL=3.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0045H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1h7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-9%2B",
//     mfgPart: "ADP-2-9+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1H7-0404D",
//     price: 8300,
//     priceType: "cents",
//     props: [200, 900, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0045_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0045",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-10+.pdf",
//     description: "2-Way F=5M-1G IL=3.8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0045H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1h8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-10%2B",
//     mfgPart: "ADP-2-10+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1H8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1H8-0404D",
//     price: 8300,
//     priceType: "cents",
//     props: [5, 1000, 3.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0045_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0045",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-20+.pdf",
//     description: "2-Way F=20M-2G IL=3.8dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0045H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1h9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-20%2B",
//     mfgPart: "ADP-2-20+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1H9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1H9-0404D",
//     price: 8900,
//     priceType: "cents",
//     props: [20, 2000, 3.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0045_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0047",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-1+.pdf",
//     description: "2-Way F=10M-400M IL=3.5dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0047H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1j1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-1%2B",
//     mfgPart: "ADP-2-1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1J1-0404D",
//     price: 7500,
//     priceType: "cents",
//     props: [10, 400, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0047_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0047",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-1W+.pdf",
//     description: "2-Way F=1M-650M IL=3.5dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0047H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1j2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-1W%2B",
//     mfgPart: "ADP-2-1W+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1J2-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [1, 650, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0047_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0047",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-4+.pdf",
//     description: "2-Way F=10M-1G IL=3.8dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0047H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1j3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-4%2B",
//     mfgPart: "ADP-2-4+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1J3-0404D",
//     price: 8300,
//     priceType: "cents",
//     props: [10, 1000, 3.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0047_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0047",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-9+.pdf",
//     description: "2-Way F=200M-900M IL=3.5dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0047H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1j4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-9%2B",
//     mfgPart: "ADP-2-9+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1J4-0404D",
//     price: 8300,
//     priceType: "cents",
//     props: [200, 900, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0047_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0047",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-10+.pdf",
//     description: "2-Way F=5M-1G IL=3.8dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0047H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1j5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-10%2B",
//     mfgPart: "ADP-2-10+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1J5-0404D",
//     price: 8300,
//     priceType: "cents",
//     props: [5, 1000, 3.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0047_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0047",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-20+.pdf",
//     description: "2-Way F=20M-2G IL=3.8dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0047H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1j6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-20%2B",
//     mfgPart: "ADP-2-20+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1J6-0404D",
//     price: 8900,
//     priceType: "cents",
//     props: [20, 2000, 3.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0047_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0049",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-1+.pdf",
//     description: "2-Way F=10M-400M IL=3.5dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0049H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1j7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-1%2B",
//     mfgPart: "ADP-2-1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1J7-0404D",
//     price: 7500,
//     priceType: "cents",
//     props: [10, 400, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0049_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0049",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-1W+.pdf",
//     description: "2-Way F=1M-650M IL=3.5dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0049H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1j8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-1W%2B",
//     mfgPart: "ADP-2-1W+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1J8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1J8-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [1, 650, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0049_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0049",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-4+.pdf",
//     description: "2-Way F=10M-1G IL=3.8dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0049H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1j9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-4%2B",
//     mfgPart: "ADP-2-4+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1J9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1J9-0404D",
//     price: 8300,
//     priceType: "cents",
//     props: [10, 1000, 3.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0049_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0049",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-9+.pdf",
//     description: "2-Way F=200M-900M IL=3.5dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0049H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1k1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-9%2B",
//     mfgPart: "ADP-2-9+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1K1-0404D",
//     price: 8300,
//     priceType: "cents",
//     props: [200, 900, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0049_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0049",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-10+.pdf",
//     description: "2-Way F=5M-1G IL=3.8dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0049H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1k2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-10%2B",
//     mfgPart: "ADP-2-10+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1K2-0404D",
//     price: 8300,
//     priceType: "cents",
//     props: [5, 1000, 3.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0049_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0049",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-20+.pdf",
//     description: "2-Way F=20M-2G IL=3.8dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0049H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1k3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-20%2B",
//     mfgPart: "ADP-2-20+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1K3-0404D",
//     price: 8900,
//     priceType: "cents",
//     props: [20, 2000, 3.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0049_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0046",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2S+.pdf",
//     description: "2-Way F=800M-2.1G IL=3.8dB AmpBal=0.2dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0046H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1k4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2S%2B",
//     mfgPart: "GP2S+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1K4-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [800, 2100, 3.8, null, 0.2, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0046_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0046",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2S1+.pdf",
//     description: "2-Way F=500M-2.5G IL=4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0046H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1k5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2S1%2B",
//     mfgPart: "GP2S1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1K5-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [500, 2500, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0046_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0046",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2X+.pdf",
//     description: "2-Way F=2.9G-9.8G IL=4.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0046H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1k6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2X%2B",
//     mfgPart: "GP2X+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1K6-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [2900, 9800, 4.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0046_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0046",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2X1+.pdf",
//     description: "2-Way F=2.8G-5.5G IL=4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0046H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1k7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2X1%2B",
//     mfgPart: "GP2X1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1K7-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [2800, 5500, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0046_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0046",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2Y+.pdf",
//     description: "2-Way F=1.6G-3.3G IL=4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0046H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1k8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2Y%2B",
//     mfgPart: "GP2Y+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1K8-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [1600, 3300, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0046_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0046",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2Y1+.pdf",
//     description: "2-Way F=1.55G-4.4G IL=4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0046H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1k9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2Y1%2B",
//     mfgPart: "GP2Y1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1K9-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [1550, 4400, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0046_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0048",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2S+.pdf",
//     description: "2-Way F=800M-2.1G IL=3.8dB AmpBal=0.2dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0048H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1l1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2S%2B",
//     mfgPart: "GP2S+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1L1-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [800, 2100, 3.8, null, 0.2, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0048_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0048",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2S1+.pdf",
//     description: "2-Way F=500M-2.5G IL=4dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0048H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1l2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2S1%2B",
//     mfgPart: "GP2S1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1L2-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [500, 2500, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0048_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0048",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2X+.pdf",
//     description: "2-Way F=2.9G-6.2G IL=4.5dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0048H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1l3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2X%2B",
//     mfgPart: "GP2X+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1L3-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [2900, 6200, 4.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0048_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0048",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2X1+.pdf",
//     description: "2-Way F=2.8G-5.5G IL=4dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0048H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1l4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2X1%2B",
//     mfgPart: "GP2X1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1L4-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [2800, 5500, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0048_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0048",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2Y+.pdf",
//     description: "2-Way F=1.6G-3.3G IL=4dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0048H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1l5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2Y%2B",
//     mfgPart: "GP2Y+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1L5-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [1600, 3300, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0048_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0048",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2Y1+.pdf",
//     description: "2-Way F=1.55G-4.4G IL=4dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0048H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1l6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2Y1%2B",
//     mfgPart: "GP2Y1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1L6-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [1550, 4400, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0048_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0050",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2S+.pdf",
//     description: "2-Way F=800M-2.1G IL=3.8dB AmpBal=0.2dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0050I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1l7-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2S%2B",
//     mfgPart: "GP2S+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1L7-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [800, 2100, 3.8, null, 0.2, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0050_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0050",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2S1+.pdf",
//     description: "2-Way F=500M-2.5G IL=4dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0050I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1l8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2S1%2B",
//     mfgPart: "GP2S1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1L8-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [500, 2500, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0050_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0050",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2X+.pdf",
//     description: "2-Way F=2.9G-9.8G IL=4.5dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0050I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1l9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2X%2B",
//     mfgPart: "GP2X+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1L9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1L9-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [2900, 9800, 4.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0050_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0050",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2X1+.pdf",
//     description: "2-Way F=2.8G-5.5G IL=4dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0050I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1m1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2X1%2B",
//     mfgPart: "GP2X1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A1M1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A1M1-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [2800, 5500, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0050_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0050",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2Y+.pdf",
//     description: "2-Way F=1.6G-3.3G IL=4dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0050I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1m2-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2Y%2B",
//     mfgPart: "GP2Y+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1M2-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [1600, 3300, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0050_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0050",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2Y1+.pdf",
//     description: "2-Way F=1.55G-4.4G IL=4dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0050I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1m3-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2Y1%2B",
//     mfgPart: "GP2Y1+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1M3-0404D",
//     price: 6500,
//     priceType: "cents",
//     props: [1550, 4400, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0050_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0051",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-3-13.pdf",
//     description: "3-Way F=750M-1.325G IL=5.8dB AmpBal=0.3dB PhaBal=1deg ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0051H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1m4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-3-13%2B",
//     mfgPart: "SCN-3-13+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1M4-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: [750, 1325, 5.8, 12, 0.3, 1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0051_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0051",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-3-16+.pdf",
//     description: "3-Way F=950M-1.6G IL=5.4dB AmpBal=0.2dB PhaBal=3deg ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0051H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1m5-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-3-16%2B",
//     mfgPart: "SCN-3-16+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1M5-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: [950, 1600, 5.4, 15, 0.2, 3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0051_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0051",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-3-28.pdf",
//     description: "3-Way F=1.6G-2.8G IL=5.6dB AmpBal=0.2dB PhaBal=5deg ",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0051H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a1m6-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-3-28%2B",
//     mfgPart: "SCN-3-28+SMT",
//     models: "this part is missing models",
//     pn: "XM-A1M6-0404D",
//     price: 10000,
//     priceType: "cents",
//     props: [1600, 2800, 5.6, 12, 0.2, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0051_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0086",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://ims-resistors.com/wp-content/uploads/2018/01/IPS-Series-2-way-_1-10-18.pdf",
//     description: "2-Way F=DC-15G IL=6.7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0086I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a214-0404d/",
//     mfgCode: "IMSYS",
//     mfgLandingPage:
//       "https://ims-resistors.com/products/6db-2-way-power-splitter/",
//     mfgPart: "IPS2640-CSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A214D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A214-0404D",
//     price: 6600,
//     priceType: "cents",
//     props: [0, 15000, 6.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0086_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0086",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://emc-rflabs.com/Rflabs/media/products/datasheets/Resistive%20Power%20Dividers/RPD0212F.pdf?ext=.pdf?v=173",
//     description: "2-Way F=DC-10G IL=6.7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0086I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a215-0404d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.rfmw.com/ProductDetail/RPD0212F-EMC-Technology-Inc/457468/",
//     mfgPart: "RPD0212FSMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A215D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A215-0404D",
//     price: 7700,
//     priceType: "cents",
//     props: [0, 10000, 6.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0086_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0153",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "2-Way F=2G-13G IL=4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0153H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2a9-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "Splitter, XMWSMT",
//     models: "this part is missing models",
//     pn: "XM-A2A9-0404D",
//     price: 6900,
//     priceType: "cents",
//     props: [2000, 13000, 4, null, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0153_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0166",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW05758_Datasheet-(1).pdf.aspx",
//     description: "2-Way F=4.5G-18G IL=4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0166K.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2b1-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW05758SMT",
//     models: "this part is missing models",
//     pn: "XM-A2B1-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [4500, 18000, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0166_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0154",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "2-Way F=2G-13G IL=4dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0154G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2b2-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "Splitter, XMWSMT",
//     models: "this part is missing models",
//     pn: "XM-A2B2-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [2000, 13000, 4, null, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0160",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDR05848_Datasheet.pdf.aspx",
//     description: "2-Way F=DC-40G IL=6.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0160J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2n2-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers.aspx",
//     mfgPart: "PDR05848SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2N2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2N2-0404D",
//     price: 7900,
//     priceType: "cents",
//     props: [0, 40000, 6.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0160_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0169",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW06038_Datasheet.pdf.aspx",
//     description: "2-Way F=2G-10G IL=3.6dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0169I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2t8-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06038SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2T8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2T8-0604D",
//     price: 20900,
//     priceType: "cents",
//     props: [2000, 10000, 3.6, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C6",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0169_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0094",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2S+.pdf",
//     description: "Regenerative F=800M-2.1G IL=3.8dB AmpBal=0.2dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0094G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2u9-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2S%2B",
//     mfgPart: "GP2S+SMT",
//     models: "this part is missing models",
//     pn: "XM-A2U9-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [800, 2100, 3.8, null, 0.2, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0094_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0096",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2S+.pdf",
//     description: "2-Way F=800M-2.1G IL=3.8dB AmpBal=0.2dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0096H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2v1-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2S%2B",
//     mfgPart: "GP2S+SMT",
//     models: "this part is missing models",
//     pn: "XM-A2V1-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [800, 2100, 3.8, null, 0.2, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0096_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0093",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-10+.pdf",
//     description: "2-Way F=5M-1G IL=3.8dB ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0093H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2v5-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-10%2B",
//     mfgPart: "ADP-2-10+SMT",
//     models: "this part is missing models",
//     pn: "XM-A2V5-0409D",
//     price: 11600,
//     priceType: "cents",
//     props: [5, 1000, 3.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0093_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0095",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-10+.pdf",
//     description: "2-Way F=5M-1G IL=3.8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0095H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2v6-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-10%2B",
//     mfgPart: "ADP-2-10+SMT",
//     models: "this part is missing models",
//     pn: "XM-A2V6-0409D",
//     price: 11600,
//     priceType: "cents",
//     props: [5, 1000, 3.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0095_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0176",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW05758_Datasheet-(1).pdf.aspx",
//     description: "4-Way F=4.5G-18G IL=4dB ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0176J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a326-0419d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW05758SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A326D.s4p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A326-0419D",
//     price: 32700,
//     priceType: "cents",
//     props: [4500, 18000, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0176_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0323",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDR05848_Datasheet.pdf.aspx",
//     description: "4-Way F=DC-40G IL=6.5dB ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0323B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3e4-0419d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers.aspx",
//     mfgPart: "PDR05848SMT",
//     models: "this part is missing models",
//     pn: "XM-A3E4-0419D",
//     price: 21000,
//     priceType: "cents",
//     props: [0, 40000, 6.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0364",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K1+.pdf",
//     description: "2-Way F=2G-26.5G IL=4.7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0364C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3r3-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K1%2B",
//     mfgPart: "EP2K1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3R3D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3R3-0409D",
//     price: 15000,
//     priceType: "cents",
//     props: [2000, 26500, 4.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0364_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0095",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-1W+.pdf",
//     description: "2-Way F=1M-650M IL=3.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0095H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3t1-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-1W%2B",
//     mfgPart: "ADP-2-1W+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3T1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3T1-0409D",
//     price: 10700,
//     priceType: "cents",
//     props: [1, 650, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0095_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0421",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDR05848_Datasheet.pdf.aspx",
//     description: "2-Way F=DC-40G IL=6.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0421A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a3u7-0409d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers.aspx",
//     mfgPart: "PDR05848SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A3U7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A3U7-0409D",
//     price: 11100,
//     priceType: "cents",
//     props: [0, 40000, 6.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0421_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1296",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SP-2G+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a438-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SP-2G%2B",
//     mfgPart: "SP-2G+SMT",
//     models: "this is the new part",
//     pn: "XM-A438-0604D",
//     price: 8000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1296_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0480",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2W+.pdf",
//     description: "2-Way F=700M-6G IL=4.3dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0480A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4h2-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2W%2B",
//     mfgPart: "EP2W+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4H2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4H2-0409D",
//     price: 12600,
//     priceType: "cents",
//     props: [700, 6000, 4.3, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0480_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0489",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW05758_Datasheet-(1).pdf.aspx",
//     description: "2-Way F=4.5G-18G IL=4dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0489C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a4h7-0409d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW05758SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A4H7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A4H7-0409D",
//     price: 15000,
//     priceType: "cents",
//     props: [4500, 18000, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0489_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1753",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a511-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K%2B",
//     mfgPart: "EP2K+SMT",
//     models: "this is the new part",
//     pn: "XM-A511-0404D",
//     price: 11000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1753_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1754",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K+.pdf",
//     description: "2-Way F=5G-20G IL=4.7dB AmpBal=0.1dB PhaBal=4.2deg ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1754A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a512-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K%2B",
//     mfgPart: "EP2K+SMT",
//     models: "this part is missing models",
//     pn: "XM-A512-0404D",
//     price: 11000,
//     priceType: "cents",
//     props: [5000, 20000, 4.7, 20, 0.1, 4.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1754_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1755",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K+.pdf",
//     description: "2-Way F=5G-20G IL=4.7dB AmpBal=0.1dB PhaBal=4.2deg ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1755A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a513-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K%2B",
//     mfgPart: "EP2K+SMT",
//     models: "this part is missing models",
//     pn: "XM-A513-0404D",
//     price: 11000,
//     priceType: "cents",
//     props: [5000, 20000, 4.7, 20, 0.1, 4.2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1755_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0493",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDR05848_Datasheet.pdf.aspx",
//     description: "2-Way F=DC-40G IL=6.5dB ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0493A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a5d1-0409d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers.aspx",
//     mfgPart: "PDR05848SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A5D1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A5D1-0409D",
//     price: 11200,
//     priceType: "cents",
//     props: [0, 40000, 6.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0493_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0621",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW06089_Datasheet.pdf.aspx",
//     description: "4-Way F=6G-18G IL=7dB ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0621A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7c5-0619d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06089SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7C5D.s4p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7C5-0619D",
//     price: 38700,
//     priceType: "cents",
//     props: [6000, 18000, 7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0621_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0364",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K+.pdf",
//     description: "2-Way F=5G-20G IL=4.7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0364C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7j9-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K%2B",
//     mfgPart: "EP2K+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7J9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7J9-0409D",
//     price: 14300,
//     priceType: "cents",
//     props: [5000, 20000, 4.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0364_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0664",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW07069_Datasheet.pdf.aspx",
//     description: "4-Way F=25G-32G IL=3.7dB AmpBal=0.25dB PhaBal=5deg ",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0664A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7y7-0619d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW07069SMT",
//     models: "this part is missing models",
//     pn: "XM-A7Y7-0619D",
//     price: 26400,
//     priceType: "cents",
//     props: [25000, 32000, 3.7, null, 0.25, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0664_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0663",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW06984_Datasheet.pdf.aspx",
//     description: "2-Way F=25G-32G IL=0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0663A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7y8-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06984SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7Y8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7Y8-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [25000, 32000, 0.25, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0663_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0617",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW06407_Datasheet.pdf.aspx",
//     description: "2-Way F=2G-18G IL=5dB ",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0617A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7y9-0604d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06407SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7Y9D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7Y9-0604D",
//     price: 19700,
//     priceType: "cents",
//     props: [2000, 18000, 5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C6",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0617_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0619",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW06401_Datasheet.pdf.aspx",
//     description: "2-Way F=15G-17G IL=3.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0619A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7z1-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06401SMT",
//     models: "this part is missing models",
//     pn: "XM-A7Z1-0404D",
//     price: 8900,
//     priceType: "cents",
//     props: [15000, 17000, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0620",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW06400_Datasheet.pdf.aspx",
//     description: "2-Way F=11G-13G IL=3.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0620A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7z2-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06400SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A7Z2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A7Z2-0404D",
//     price: 8500,
//     priceType: "cents",
//     props: [11000, 13000, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0620_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0609",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW06398_Datasheet.pdf.aspx",
//     description: "2-Way F=5G-7G IL=3.3dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0609B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a7z4-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06398SMT",
//     models: "this part is missing models",
//     pn: "XM-A7Z4-0404D",
//     price: 9600,
//     priceType: "cents",
//     props: [5000, 7000, 3.3, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1666",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-152+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a9f1-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-152%2B",
//     mfgPart: "QCS-152+SMT",
//     models: "this is the new part",
//     pn: "XM-A9F1-0409D",
//     price: 10900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1666",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-332+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a9f2-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-332%2B",
//     mfgPart: "QCS-332+SMT",
//     models: "this is the new part",
//     pn: "XM-A9F2-0409D",
//     price: 11300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1666",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-442+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a9f3-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-442%2B",
//     mfgPart: "QCS-442+SMT",
//     models: "this is the new part",
//     pn: "XM-A9F3-0409D",
//     price: 11300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1666",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-592+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a9f4-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-592%2B",
//     mfgPart: "QCS-592+SMT",
//     models: "this is the new part",
//     pn: "XM-A9F4-0409D",
//     price: 11300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1666",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-722+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a9f5-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-772%2B",
//     mfgPart: "QCS-722+SMT",
//     models: "this is the new part",
//     pn: "XM-A9F5-0409D",
//     price: 11300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1666",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-981+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a9f6-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-981%2B",
//     mfgPart: "QCS-981+SMT",
//     models: "this is the new part",
//     pn: "XM-A9F6-0409D",
//     price: 10900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1666",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-83+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-a9f7-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-83%2B",
//     mfgPart: "QCS-83+SMT",
//     models: "this is the new part",
//     pn: "XM-A9F7-0409D",
//     price: 10900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0480",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2W1+.pdf",
//     description: "2-Way F=500M-9.5G IL=5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0480A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1m5-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2W1%2B",
//     mfgPart: "EP2W1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1M5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1M5-0409D",
//     price: 13000,
//     priceType: "cents",
//     props: [500, 9500, 5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0480_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0948",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K+.pdf",
//     description: "4-Way F=5G-20G IL=4.7dB ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0948A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b1v3-0419d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K%2B",
//     mfgPart: "EP2K+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B1V3D.s4p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B1V3-0419D",
//     price: 30700,
//     priceType: "cents",
//     props: [5000, 20000, 4.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0948_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0853",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LRPS-2-11.pdf",
//     description: "2-Way F=20M-2G IL=3.7dB ",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0853A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2b6-0419d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LRPS-2-11",
//     mfgPart: "LRPS-2-11+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2B6-0419D",
//     price: 19100,
//     priceType: "cents",
//     props: [20, 2000, 3.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0949",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K+.pdf",
//     description: "4-Way F=5G-20G IL=4.7dB ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0949A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2d1-0419d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K%2B",
//     mfgPart: "EP2K+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2D1-0419D",
//     price: 21000,
//     priceType: "cents",
//     props: [5000, 20000, 4.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0949_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0849",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/JSPQ-350.pdf",
//     description: "2-Way F=150M-350M IL=4dB AmpBal=1.5dB PhaBal=5deg ",
//     eccn: "",
//     ffNum: 10,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0849A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2d3-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=JSPQ-350",
//     mfgPart: "JSPQ-350+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2D3-0609D",
//     price: 14400,
//     priceType: "cents",
//     props: [150, 350, 4, null, 1.5, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0984",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SP-2W1+.pdf",
//     description: "2-Way F=2.875G-4.2G IL=0.8dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0984A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2f1-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SP-2W1%2B",
//     mfgPart: "SP-2W1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2F1D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2F1-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [2875, 4200, 0.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0984_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1021",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2C+.pdf",
//     description: "2-Way F=1.8G-12.5G IL=4dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1021A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2f2-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2C%2B",
//     mfgPart: "EP2C+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2F2D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2F2-0409D",
//     price: 11000,
//     priceType: "cents",
//     props: [1800, 12500, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0964",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCPQ-60.pdf",
//     description: "2-Way F=30M-60M IL=0.15dB ",
//     eccn: "",
//     ffNum: 10,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0964A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2g8-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCPQ-60%2B",
//     mfgPart: "SCPQ-60+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B2G8D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B2G8-0609D",
//     price: 14400,
//     priceType: "cents",
//     props: [30, 60, 0.15, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1267",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/JSPQW-100.pdf",
//     description: "2-Way F=40M-100M IL=3.2dB ",
//     eccn: "",
//     ffNum: 16,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1267A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2t2-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=JSPQW-100%2B",
//     mfgPart: "JSPQW-100+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2T2-0609D",
//     price: 15800,
//     priceType: "cents",
//     props: [40, 100, 3.2, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1267_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1267",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/JSPQW-100A.pdf",
//     description: "2-Way F=30M-100M IL=3.5dB ",
//     eccn: "",
//     ffNum: 16,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1267A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b2t3-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=JSPQW-100A%2B",
//     mfgPart: "JSPQW-100A+SMT",
//     models: "this part is missing models",
//     pn: "XM-B2T3-0609D",
//     price: 15900,
//     priceType: "cents",
//     props: [30, 100, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "2160_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0999",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SP-2G+.pdf",
//     description: "2-Way F=1.42G-1.66G IL=3.5dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0999A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4y8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SP-2G%2B",
//     mfgPart: "SP-2G+SMT",
//     models: "this part is missing models",
//     pn: "XM-B4Y8-0404D",
//     price: 6300,
//     priceType: "cents",
//     props: [1420, 1660, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1000",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SP-2G+.pdf",
//     description: "2-Way F=1.42G-1.66G IL=3.5dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1000A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b4y9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SP-2G%2B",
//     mfgPart: "SP-2G+SMT",
//     models: "this part is missing models",
//     pn: "XM-B4Y9-0404D",
//     price: 6300,
//     priceType: "cents",
//     props: [1420, 1660, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1001",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LRPS-2-1.pdf",
//     description: "2-Way F=5M-500M IL=3.25dB ",
//     eccn: "",
//     ffNum: 15,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1001A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5a8-0604d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LRPS-2-1",
//     mfgPart: "LRPS-2-1+SMT",
//     models: "this part is missing models",
//     pn: "XM-B5A8-0604D",
//     price: 9300,
//     priceType: "cents",
//     props: [5, 500, 3.25, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C6",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1001_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0937",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K1+.pdf",
//     description: "2-Way F=2G-26.5G IL=4.7dB ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0937A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5c5-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K1%2B",
//     mfgPart: "EP2K1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5C5D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5C5-0409D",
//     price: 15000,
//     priceType: "cents",
//     props: [2000, 26500, 4.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0937_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1082",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LRPS-2-11J.pdf",
//     description: "2-Way F=20M-2G IL=3.7dB ",
//     eccn: "",
//     ffNum: 12,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1082A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5c7-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LRPS-2-11J%2B",
//     mfgPart: "LRPS-2-11J+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5C7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5C7-0609D",
//     price: 16300,
//     priceType: "cents",
//     props: [20, 2000, 3.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1149",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCH-63B+.pdf",
//     description: "Hybrid F=800M-6G IL=2.5dB ",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1149A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5l6-0619d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCH-63B%2B",
//     mfgPart: "QCH-63B+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B5L6D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B5L6-0619D",
//     price: 0,
//     priceType: "cents",
//     props: [800, 6000, 2.5, null, null, null, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0984",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SP-2G+.pdf",
//     description: "2-Way F=1.42G-1.66G IL=3.5dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0984A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5p8-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SP-2G%2B",
//     mfgPart: "SP-2G+SMT",
//     models: "this part is missing models",
//     pn: "XM-B5P8-0409D",
//     price: 9700,
//     priceType: "cents",
//     props: [1420, 1660, 3.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0984_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1191",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/06/AM4008_Datasheet_Latest.pdf",
//     description: "2-Way F=2G-24G IL=2dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1191B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b5r8-0409d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage:
//       "https://www.atlantamicro.com/products/splitters-couplers/am4008/",
//     mfgPart: "AM4008SMT",
//     models: "this part is missing models",
//     pn: "XM-B5R8-0409D",
//     price: 13300,
//     priceType: "cents",
//     props: [2000, 24000, 2, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1191_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0714",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://emc-rflabs.com/Rflabs/media/products/datasheets/Resistive%20Power%20Dividers/RPD0212F.pdf?ext=.pdf?v=173",
//     description: "2-Way F=DC-10G IL=6.7dB ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0714A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6c7-0409d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.rfmw.com/ProductDetail/RPD0212F-EMC-Technology-Inc/457468/",
//     mfgPart: "RPD0212FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6C7-0409D",
//     price: 11000,
//     priceType: "cents",
//     props: [0, 10000, 6.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0754",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://emc-rflabs.com/Rflabs/media/products/datasheets/Resistive%20Power%20Dividers/RPD0212F.pdf?ext=.pdf?v=173",
//     description: "2-Way F=DC-10G IL=6.7dB ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0754B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6c8-0409d/",
//     mfgCode: "EMCTE",
//     mfgLandingPage:
//       "https://www.rfmw.com/ProductDetail/RPD0212F-EMC-Technology-Inc/457468/",
//     mfgPart: "RPD0212FSMT",
//     models: "this part is missing models",
//     pn: "XM-B6C8-0409D",
//     price: 11000,
//     priceType: "cents",
//     props: [0, 10000, 6.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0160",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDR06380_Datasheet.pdf.aspx?lang=en-US",
//     description: "2-Way F=DC-40G IL=0.5dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0160J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b6k8-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "http://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers.aspx",
//     mfgPart: "PDR06380SMT",
//     models: "this part is missing models",
//     pn: "XM-B6K8-0404D",
//     price: 7900,
//     priceType: "cents",
//     props: [0, 40000, 0.5, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0160_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0986",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/LRPS-2-1.pdf",
//     description: "2-Way F=5M-500M IL=3.25dB ",
//     eccn: "",
//     ffNum: 10,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0986A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7j1-0609d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=LRPS-2-1-75J%2B",
//     mfgPart: "LRPS-2-1SMT",
//     models: "this part is missing models",
//     pn: "XM-B7J1-0609D",
//     price: 0,
//     priceType: "cents",
//     props: [5, 500, 3.25, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1116",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/TCP-2-33W+.pdf",
//     description: "4-Way F=50M-3G IL=3dB ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1116A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7l1-0419d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=TCP-2-33W%2B",
//     mfgPart: "TCP-2-33W+SMT",
//     models: "this part is missing models",
//     pn: "XM-B7L1-0419D",
//     price: 18100,
//     priceType: "cents",
//     props: [50, 3000, 3, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1116_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1153",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW07691_Datasheet.pdf.aspx",
//     description: "2-Way F=18G-20G AmpBal=0.25dB PhaBal=5deg ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1153A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7n5-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW07691SMT",
//     models: "this part is missing models",
//     pn: "XM-B7N5-0404D",
//     price: 8400,
//     priceType: "cents",
//     props: [18000, 20000, 0, null, 0.25, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1153_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1154",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW07630_Datasheet.pdf.aspx",
//     description: "2-Way F=25G-32G IL=3.25dB AmpBal=0.25dB PhaBal=5deg ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1154A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7n6-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW07630SMT",
//     models: "this part is missing models",
//     pn: "XM-B7N6-0404D",
//     price: 9200,
//     priceType: "cents",
//     props: [25000, 32000, 3.25, null, 0.25, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1154_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1155",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDR06390_Datasheet.pdf.aspx?lang=en-US",
//     description: "2-Way F=DC-20G IL=0.25dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1155A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7n7-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDR06390SMT",
//     models: "this part is missing models",
//     pn: "XM-B7N7-0404D",
//     price: 10900,
//     priceType: "cents",
//     props: [0, 20000, 0.25, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1155_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1061",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2KA+.pdf",
//     description: "2-Way F=10G-43.5G IL=4dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1061A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b7p4-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2KA%2B",
//     mfgPart: "EP2KA+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B7P4D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B7P4-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [10000, 43500, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1061_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1190",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.atlantamicro.com/wp-content/uploads/2018/06/AM4008_Datasheet_Latest.pdf",
//     description: "2-Way F=2G-24G IL=2dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1190B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8u3-0404d/",
//     mfgCode: "ATLMC",
//     mfgLandingPage:
//       "https://www.atlantamicro.com/products/splitters-couplers/am4008/",
//     mfgPart: "AM4008SMT",
//     models: "this part is missing models",
//     pn: "XM-B8U3-0404D",
//     price: 10300,
//     priceType: "cents",
//     props: [2000, 24000, 2, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1190_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0948",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K1+.pdf",
//     description: "4-Way F=2G-26.5G IL=4.7dB ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0948A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8u4-0419d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K1%2B",
//     mfgPart: "EP2K1+SMT",
//     models: "this part is missing models",
//     pn: "XM-B8U4-0419D",
//     price: 32700,
//     priceType: "cents",
//     props: [2000, 26500, 4.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0948_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0949",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K1+.pdf",
//     description: "4-Way F=2G-26.5G IL=4.7dB ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0949A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b8u5-0419d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K1%2B",
//     mfgPart: "EP2K1+SMT",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/B8U5D.s4p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-B8U5-0419D",
//     price: 32700,
//     priceType: "cents",
//     props: [2000, 26500, 4.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0949_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1300",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCH-63+.pdf",
//     description: "Hybrid F=2G-6G IL=2.8dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1300B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b912-0509d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCH-63+",
//     mfgPart: "QCH-63+SMT",
//     models: "this part is missing models",
//     pn: "XM-B912-0509D",
//     price: 0,
//     priceType: "cents",
//     props: [2000, 6000, 2.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1300_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1666",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-312+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9a1-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-312%2B",
//     mfgPart: "QCS-312+SMT",
//     models: "this is the new part",
//     pn: "XM-B9A1-0409D",
//     price: 11300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1666_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0267",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SP-2P1+.pdf",
//     description: "4-Way F=1.35G-2.25G IL=0.5dB AmpBal=0.2dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0267E.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9k2-0419d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SP-2P1%2B",
//     mfgPart: "SP-2P1+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9K2-0419D",
//     price: 16700,
//     priceType: "cents",
//     props: [1350, 2250, 0.5, 20, 0.2, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0267_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0095",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-20+.pdf",
//     description: "2-Way F=20M-2G IL=3.8dB AmpBal=0.3dB PhaBal=3deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0095H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9l9-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-20%2B",
//     mfgPart: "ADP-2-20+SMT",
//     models: "this part is missing models",
//     pn: "XM-B9L9-0409D",
//     price: 12200,
//     priceType: "cents",
//     props: [20, 2000, 3.8, 18, 0.3, 3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0095_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1397",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K1+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9n6-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K1%2B",
//     mfgPart: "EP2K1+SMT",
//     models: "this is the new part",
//     pn: "XM-B9N6-0409D",
//     price: 15000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1397_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1608",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K1+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-b9n7-0411d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K1%2B",
//     mfgPart: "EP2K1+SMT",
//     models: "this is the new part",
//     pn: "XM-B9N7-0411D",
//     price: 16100,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1489",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "2-Way ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1489A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9r3-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "Resistive SplitterSMT",
//     models: "this part is missing models",
//     pn: "XM-B9R3-0404D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 0, 0, 0, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1490",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "2-Way ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1490A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-b9r4-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "Resistive SplitterSMT",
//     models: "this part is missing models",
//     pn: "XM-B9R4-0404D",
//     price: 6200,
//     priceType: "cents",
//     props: [0, 0, 0, 0, 0, 0],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1341",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2KA+.pdf",
//     description: "2-Way F=10G-43.5G IL=4dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1341A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c357-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2KA%2B",
//     mfgPart: "EP2KA+SMT",
//     models: "this part is missing models",
//     pn: "XM-C357-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [10000, 43500, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1341_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1352",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2KA+.pdf",
//     description: "2-Way F=10G-43.5G IL=4dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1352A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c358-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2KA%2B",
//     mfgPart: "EP2KA+SMT",
//     models: "this part is missing models",
//     pn: "XM-C358-0404D",
//     price: 12200,
//     priceType: "cents",
//     props: [10000, 43500, 4, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1352_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1300",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCH-63+.pdf",
//     description: "Hybrid F=2G-6G IL=2.8dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1300B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c372-0509d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCH-63+",
//     mfgPart: "QCH-63+SMT",
//     models: "this part is missing models",
//     pn: "XM-C372-0509D",
//     price: 0,
//     priceType: "cents",
//     props: [2000, 6000, 2.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1300_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1300",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCH-63+.pdf",
//     description: "Hybrid F=2G-6G IL=2.8dB ",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1300B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c373-0509d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCH-63+",
//     mfgPart: "QCH-63+SMT",
//     models: "this part is missing models",
//     pn: "XM-C373-0509D",
//     price: 19500,
//     priceType: "cents",
//     props: [2000, 6000, 2.8, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1300_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1755",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K1+.pdf",
//     description: "2-Way F=2G-26.5G IL=4.7dB ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1755A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c383-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K1%2B",
//     mfgPart: "EP2K1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C383-0404D",
//     price: 11600,
//     priceType: "cents",
//     props: [2000, 26500, 4.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1755_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1754",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K1+.pdf",
//     description: "2-Way F=2G-26.5G IL=4.7dB ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1754A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c384-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K1%2B",
//     mfgPart: "EP2K1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C384-0404D",
//     price: 11600,
//     priceType: "cents",
//     props: [2000, 26500, 4.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1754_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1753",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2K1+.pdf",
//     description: "2-Way F=2G-26.5G IL=4.7dB ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1753B.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c385-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2K1%2B",
//     mfgPart: "EP2K1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C385-0404D",
//     price: 11600,
//     priceType: "cents",
//     props: [2000, 26500, 4.7, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1753_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1476",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2KA-D+.pdf",
//     description: "2-Way F=10G-43.5G ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1476A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3u7-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2KA-DG%2B",
//     mfgPart: "EP2KA-DG+DIE",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C3U7D.s3p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C3U7-0409D",
//     price: 0,
//     priceType: "cents",
//     props: [10000, 43500, 0, null, null, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1476_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0247",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/PBR-0003SMG.pdf",
//     description: "2-Way F=10M-3G IL=4.5dB AmpBal=0.08dB ",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0247C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5b7-0804d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/power-dividers/pbr-0003smg.aspx",
//     mfgPart: "PBR-0003SMGSMT",
//     models: "this part is missing models",
//     pn: "XM-C5B7-0804D",
//     price: 29900,
//     priceType: "cents",
//     props: [10, 3000, 4.5, null, 0.08, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0247",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/PBR-0006SMG.pdf",
//     description: "2-Way F=10M-6G ",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0247C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5b8-0804d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/power-dividers/pbr-0006smg.aspx",
//     mfgPart: "PBR-0006SMGSMT",
//     models: "this part is missing models",
//     pn: "XM-C5B8-0804D",
//     price: 31900,
//     priceType: "cents",
//     props: [10, 6000, 0, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0247",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/PBR-0012SMG.pdf",
//     description: "2-Way F=10M-12G ",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0247C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5b9-0804d/",
//     mfgCode: "MARKI",
//     mfgLandingPage:
//       "https://www.markimicrowave.com/power-dividers/pbr-0012smg.aspx",
//     mfgPart: "PBR-0012SMGSMT",
//     models: "this part is missing models",
//     pn: "XM-C5B9-0804D",
//     price: 33900,
//     priceType: "cents",
//     props: [10, 12000, 0, null, 0, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "0618",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW06399_Datasheet.pdf.aspx",
//     description: "2-Way F=9G-11G IL=3.3dB AmpBal=0.25dB PhaBal=5deg ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0618C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c5n1-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06399SMT",
//     models: "this part is missing models",
//     pn: "XM-C5N1-0404D",
//     price: 8600,
//     priceType: "cents",
//     props: [9000, 11000, 3.3, null, 0.25, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "obviously it is not working",
//   },
//   {
//     assyDwg: "",
//     bn: "1613",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-981+.pdf",
//     description: "Hybrid F=540M-980M IL=0.6dB AmpBal=0.35dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1613A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6m1-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-981%2B",
//     mfgPart: "QCS-981+SMT",
//     models: "this part is missing models",
//     pn: "XM-C6M1-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [540, 980, 0.6, null, 0.35, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1613_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1614",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-981+.pdf",
//     description: "Hybrid F=540M-980M IL=0.6dB AmpBal=0.35dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6m2-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-981%2B",
//     mfgPart: "QCS-981+SMT",
//     models: "this part is missing models",
//     pn: "XM-C6M2-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [540, 980, 0.6, null, 0.35, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0095",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-1+.pdf",
//     description: "2-Way F=0.5M-400M IL=3.5dB AmpBal=0.2dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0095H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c6m8-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-1%2B",
//     mfgPart: "ADP-2-1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C6M8-0409D",
//     price: 10900,
//     priceType: "cents",
//     props: [0.5, 400, 3.5, 25, 0.2, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0095_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0094",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2S1+.pdf",
//     description: "2-Way F=500M-2.5G IL=4dB AmpBal=0.2dB PhaBal=5deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0094G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7h6-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2S1%2B",
//     mfgPart: "GP2S1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C7H6-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [500, 2500, 4, null, 0.2, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0094_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0096",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2S1+.pdf",
//     description: "2-Way F=500M-2.5G IL=4dB AmpBal=0.2dB PhaBal=5deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0096H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7h7-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2S1%2B",
//     mfgPart: "GP2S1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C7H7-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [500, 2500, 4, null, 0.2, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0096_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0094",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2X1+.pdf",
//     description: "2-Way F=2.8G-7.2G IL=4dB AmpBal=0.4dB PhaBal=10deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0094G.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7h8-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2X1%2B",
//     mfgPart: "GP2X1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C7H8-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [2800, 7200, 4, null, 0.4, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0094_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0096",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/GP2X1+.pdf",
//     description: "2-Way F=2.8G-7.2G IL=4dB AmpBal=0.4dB PhaBal=10deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0096H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c7h9-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=GP2X1%2B",
//     mfgPart: "GP2X1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C7H9-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [2800, 7200, 4, null, 0.4, 10],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0096_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1698",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-2-11.pdf",
//     description: "2-Way F=800M-1.175G IL=0.5dB AmpBal=0.1dB PhaBal=1deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1698A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c812-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-2-11%2B",
//     mfgPart: "SCN-2-11+SMT",
//     models: "this part is missing models",
//     pn: "XM-C812-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [800, 1175, 0.5, 20, 0.1, 1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1698_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1698",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-2-15.pdf",
//     description: "2-Way F=1.1G-1.45G IL=0.5dB AmpBal=0.25dB PhaBal=1.5deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1698A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c813-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-2-15%2B",
//     mfgPart: "SCN-2-15+SMT",
//     models: "this part is missing models",
//     pn: "XM-C813-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [1100, 1450, 0.5, 23, 0.25, 1.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1698_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1698",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-2-19.pdf",
//     description: "2-Way F=1.425G-1.9G IL=0.5dB AmpBal=0.25dB PhaBal=2.5deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1698A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c814-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-2-19%2B",
//     mfgPart: "SCN-2-19+SMT",
//     models: "this part is missing models",
//     pn: "XM-C814-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [1425, 1900, 0.5, 23, 0.25, 2.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1698_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1698",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-2-22.pdf",
//     description: "2-Way F=1.85G-2.2G IL=0.5dB AmpBal=0.25dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1698A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c815-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-2-22%2B",
//     mfgPart: "SCN-2-22+SMT",
//     models: "this part is missing models",
//     pn: "XM-C815-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [1850, 2200, 0.5, 22, 0.25, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1698_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1698",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-2-27.pdf",
//     description: "2-Way F=2.225G-2.7G IL=0.5dB AmpBal=0.6dB PhaBal=3.5deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1698A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c816-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-2-27%2B",
//     mfgPart: "SCN-2-27+SMT",
//     models: "this part is missing models",
//     pn: "XM-C816-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [2225, 2700, 0.5, 21, 0.6, 3.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1698_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1698",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-2-35.pdf",
//     description: "2-Way F=2.825G-3.7G IL=0.4dB AmpBal=0.1dB PhaBal=1deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1698A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c817-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-2-35%2B",
//     mfgPart: "SCN-2-35+SMT",
//     models: "this part is missing models",
//     pn: "XM-C817-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [2825, 3700, 0.4, 25, 0.1, 1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1698_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1698",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-2-45.pdf",
//     description: "2-Way F=3.7G-4.2G IL=0.7dB AmpBal=0.2dB PhaBal=1.5deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1698A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c818-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-2-45%2B",
//     mfgPart: "SCN-2-45+SMT",
//     models: "this part is missing models",
//     pn: "XM-C818-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [3700, 4200, 0.7, 21, 0.2, 1.5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1698_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1698",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-2-10.pdf",
//     description: "2-Way F=600M-1G IL=0.5dB AmpBal=0.1dB PhaBal=1.7deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1698A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c819-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-2-10%2B",
//     mfgPart: "SCN-2-10+SMT",
//     models: "this part is missing models",
//     pn: "XM-C819-0409D",
//     price: 9900,
//     priceType: "cents",
//     props: [600, 1000, 0.5, 15, 0.1, 1.7],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1698_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1698",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/SCN-2-65.pdf",
//     description: "2-Way F=5.5G-6.5G IL=0.8dB AmpBal=0.1dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1698A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c821-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=SCN-2-65%2B",
//     mfgPart: "SCN-2-65+SMT",
//     models: "this part is missing models",
//     pn: "XM-C821-0409D",
//     price: 9800,
//     priceType: "cents",
//     props: [5500, 6500, 0.8, 17, 0.1, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1698_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1613",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-152+.pdf",
//     description: "Hybrid F=820M-1.6G IL=0.5dB AmpBal=0.5dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1613A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c857-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-152%2B",
//     mfgPart: "QCS-152+SMT",
//     models: "this part is missing models",
//     pn: "XM-C857-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [820, 1600, 0.5, 20, 0.5, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1613_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1614",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-152+.pdf",
//     description: "Hybrid F=820M-1.6G IL=0.5dB AmpBal=0.5dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c858-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-152%2B",
//     mfgPart: "QCS-152+SMT",
//     models: "this part is missing models",
//     pn: "XM-C858-0409D",
//     price: 10600,
//     priceType: "cents",
//     props: [820, 1600, 0.5, 20, 0.5, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1613",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-312+.pdf",
//     description: "Hybrid F=1.7G-3.1G IL=0.5dB AmpBal=0.5dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1613A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c859-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-312%2B",
//     mfgPart: "QCS-312+SMT",
//     models: "this part is missing models",
//     pn: "XM-C859-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [1700, 3100, 0.5, 25, 0.5, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1613_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1614",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-312+.pdf",
//     description: "Hybrid F=1.7G-3.1G IL=0.5dB AmpBal=0.5dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c861-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-312%2B",
//     mfgPart: "QCS-312+SMT",
//     models: "this part is missing models",
//     pn: "XM-C861-0409D",
//     price: 10600,
//     priceType: "cents",
//     props: [1700, 3100, 0.5, 25, 0.5, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1613",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-332+.pdf",
//     description: "Hybrid F=1.8G-3.3G IL=0.5dB AmpBal=0.5dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1613A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c862-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-332%2B",
//     mfgPart: "QCS-332+SMT",
//     models: "this part is missing models",
//     pn: "XM-C862-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [1800, 3300, 0.5, 25, 0.5, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1613_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1614",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-332+.pdf",
//     description: "Hybrid F=1.8G-3.3G IL=0.5dB AmpBal=0.5dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c863-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-332%2B",
//     mfgPart: "QCS-332+SMT",
//     models: "this part is missing models",
//     pn: "XM-C863-0409D",
//     price: 10600,
//     priceType: "cents",
//     props: [1800, 3300, 0.5, 25, 0.5, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1613",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-442+.pdf",
//     description: "Hybrid F=2.8G-4.4G IL=0.5dB AmpBal=0.5dB PhaBal=3deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1613A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c864-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-442%2B",
//     mfgPart: "QCS-442+SMT",
//     models: "this part is missing models",
//     pn: "XM-C864-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [2800, 4400, 0.5, 22, 0.5, 3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1613_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1614",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-442+.pdf",
//     description: "Hybrid F=2.8G-4.4G IL=0.5dB AmpBal=0.5dB PhaBal=3deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c865-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-442%2B",
//     mfgPart: "QCS-442+SMT",
//     models: "this part is missing models",
//     pn: "XM-C865-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [2800, 4400, 0.5, 22, 0.5, 3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1613",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-592+.pdf",
//     description: "Hybrid F=3.1G-5.9G IL=0.5dB AmpBal=0.5dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1613A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c866-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-592%2B",
//     mfgPart: "QCS-592+SMT",
//     models: "this part is missing models",
//     pn: "XM-C866-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [3100, 5900, 0.5, 27, 0.5, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1613_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1614",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-592+.pdf",
//     description: "Hybrid F=3.1G-5.9G IL=0.5dB AmpBal=0.5dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c867-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-592%2B",
//     mfgPart: "QCS-592+SMT",
//     models: "this part is missing models",
//     pn: "XM-C867-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [3100, 5900, 0.5, 27, 0.5, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1613",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-722+.pdf",
//     description: "Hybrid F=4G-7.2G IL=0.7dB AmpBal=0.2dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1613A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c868-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-772%2B",
//     mfgPart: "QCS-722+SMT",
//     models: "this part is missing models",
//     pn: "XM-C868-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [4000, 7200, 0.7, 23, 0.2, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1613_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1614",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-722+.pdf",
//     description: "Hybrid F=4G-7.2G IL=0.7dB AmpBal=0.2dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c869-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-772%2B",
//     mfgPart: "QCS-722+SMT",
//     models: "this part is missing models",
//     pn: "XM-C869-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [4000, 7200, 0.7, 23, 0.2, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1613",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-83+.pdf",
//     description: "Hybrid F=4G-8G IL=0.7dB AmpBal=0.8dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1613A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c871-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-83%2B",
//     mfgPart: "QCS-83+SMT",
//     models: "this part is missing models",
//     pn: "XM-C871-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [4000, 8000, 0.7, 19, 0.8, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1613_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1614",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/QCS-83+.pdf",
//     description: "Hybrid F=4G-8G IL=0.7dB AmpBal=0.8dB PhaBal=4deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1614A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c872-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCS-83%2B",
//     mfgPart: "QCS-83+SMT",
//     models: "this part is missing models",
//     pn: "XM-C872-0409D",
//     price: 10200,
//     priceType: "cents",
//     props: [4000, 8000, 0.7, 19, 0.8, 4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1614_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1154",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW08323_Datasheet.pdf.aspx",
//     description: "2-Way F=37G-42G IL=3.5dB AmpBal=0.3dB PhaBal=3deg ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1154A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c874-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW08323SMT",
//     models: "this part is missing models",
//     pn: "XM-C874-0404D",
//     price: 7400,
//     priceType: "cents",
//     props: [37000, 42000, 3.5, null, 0.3, 3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1154_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1654",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW08604_Preliminary_Datasheet.pdf.aspx",
//     description: "2-Way F=10.6G-11.8G IL=3.3dB AmpBal=0.2dB PhaBal=2.0deg ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1654A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c875-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW08604SMT",
//     models: "this part is missing models",
//     pn: "XM-C875-0404D",
//     price: 0,
//     priceType: "cents",
//     props: [10600, 11800, 3.3, null, 0.2, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1654_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1661",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW06399_Datasheet.pdf.aspx",
//     description: "2-Way F=9G-11G IL=3.3dB AmpBal=0.25dB PhaBal=5deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1661A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8j6-0409d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06399SMT",
//     models: "this part is missing models",
//     pn: "XM-C8J6-0409D",
//     price: 11900,
//     priceType: "cents",
//     props: [9000, 11000, 3.3, null, 0.25, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1661_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1662",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW06399_Datasheet.pdf.aspx",
//     description: "2-Way F=9G-11G IL=3.3dB AmpBal=0.25dB PhaBal=5deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1662A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8j7-0409d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06399SMT",
//     models: "this part is missing models",
//     pn: "XM-C8J7-0409D",
//     price: 11900,
//     priceType: "cents",
//     props: [9000, 11000, 3.3, null, 0.25, 5],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1662_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1637",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2W1+.pdf",
//     description: "2-Way F=500M-9.5G IL=5dB AmpBal=0.1dB PhaBal=0.9deg ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1637A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8j8-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2W1%2B",
//     mfgPart: "EP2W1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8J8-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [500, 9500, 5, null, 0.1, 0.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1637_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1638",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2W1+.pdf",
//     description: "2-Way F=500M-9.5G IL=5dB AmpBal=0.1dB PhaBal=0.9deg ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1638A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8j9-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2W1%2B",
//     mfgPart: "EP2W1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8J9-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [500, 9500, 5, null, 0.1, 0.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1638_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1636",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2W1+.pdf",
//     description: "2-Way F=500M-9.5G IL=5dB AmpBal=0.1dB PhaBal=0.9deg ",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1636A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8k1-0404d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2W1%2B",
//     mfgPart: "EP2W1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8K1-0404D",
//     price: 9700,
//     priceType: "cents",
//     props: [500, 9500, 5, null, 0.1, 0.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1636_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1629",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2W1+.pdf",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8k2-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2W1%2B",
//     mfgPart: "EP2W1+SMT",
//     models: "this is the new part",
//     pn: "XM-C8K2-0409D",
//     price: 13000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1629_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1630",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2W1+.pdf",
//     description: "2-Way F=500M-9.5G IL=5dB AmpBal=0.1dB PhaBal=0.9deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1630A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8k3-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2W1%2B",
//     mfgPart: "EP2W1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8K3-0409D",
//     price: 13000,
//     priceType: "cents",
//     props: [500, 9500, 5, null, 0.1, 0.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1630_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1623",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW05758_Datasheet-(1).pdf.aspx",
//     description: "2-Way F=4.5G-18G IL=4dB AmpBal=0.025dB PhaBal=3deg ",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1623A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8k4-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW05758SMT",
//     models: "this part is missing models",
//     pn: "XM-C8K4-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [4500, 18000, 4, null, 0.025, 3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1623_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1624",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW05758_Datasheet-(1).pdf.aspx",
//     description: "2-Way F=4.5G-18G IL=4dB AmpBal=0.025dB PhaBal=3deg ",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1624A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8k5-0404d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW05758SMT",
//     models: "this part is missing models",
//     pn: "XM-C8K5-0404D",
//     price: 12600,
//     priceType: "cents",
//     props: [4500, 18000, 4, null, 0.025, 3],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C3",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1624_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1620",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/EP2W1+.pdf",
//     description: "2-Way F=500M-9.5G IL=5dB AmpBal=0.1dB PhaBal=0.9deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1620A.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8k6-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP2W1%2B",
//     mfgPart: "EP2W1+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8K6-0409D",
//     price: 13000,
//     priceType: "cents",
//     props: [500, 9500, 5, null, 0.1, 0.9],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1620_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0095",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-10+.pdf",
//     description: "2-Way F=5M-1G IL=3.8dB AmpBal=0.2dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0095H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8r1-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-10%2B",
//     mfgPart: "ADP-2-10+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8R1-0409D",
//     price: 11600,
//     priceType: "cents",
//     props: [5, 1000, 3.8, 20, 0.2, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0095_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0093",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "https://www.minicircuits.com/pdfs/ADP-2-10+.pdf",
//     description: "2-Way F=5M-1G IL=3.8dB AmpBal=0.2dB PhaBal=2deg ",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0093H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c8r2-0409d/",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADP-2-10%2B",
//     mfgPart: "ADP-2-10+SMT",
//     models: "this part is missing models",
//     pn: "XM-C8R2-0409D",
//     price: 11600,
//     priceType: "cents",
//     props: [5, 1000, 3.8, 20, 0.2, 2],
//     quote: "",
//     sparamLinks: [],
//     testCode: "C9",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0093_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1639",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "http://www.knowlescapacitors.com/getattachment/Products/Microwave-Products/Power-Dividers/PDW06407_Datasheet.pdf.aspx",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9j4-0409d/",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06407SMT",
//     models: "this is the new part",
//     pn: "XM-C9J4-0409D",
//     price: 21300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1639_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1898",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL:
//       "https://www.markimicrowave.com/Assets/datasheets/MQS-0218SM.pdf?v=060320",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c9n4-0509d/",
//     mfgCode: "MARKI",
//     mfgLandingPage: "https://www.markimicrowave.com/hybrids/mqs-0218sm.aspx",
//     mfgPart: "MQS-0218SMSMT",
//     models: "this is the new part",
//     pn: "XM-C9N4-0509D",
//     price: 28900,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1898_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06041",
//     models: "this is the new part",
//     pn: "XM-D278-0604D",
//     price: 18500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "0169_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCN-13D%2B",
//     mfgPart: "QCN-13D+",
//     models: "this is the new part",
//     pn: "XM-D1D6-0409D",
//     price: 11300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1473_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=EP4RKU%2B",
//     mfgPart: "EP4RKU+",
//     models: "this is the new part",
//     pn: "XM-B9M8-0409D",
//     price: 21600,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1869_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=JPS-3-1%2B",
//     mfgPart: "JPS-3-1+",
//     models: "this is the new part",
//     pn: "XM-C9A6-0414D",
//     price: 17800,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1876_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW07069",
//     models: "this is the new part",
//     pn: "XM-C2U4-0619D",
//     price: 26500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "2097_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=ADPQ-2-250%2B",
//     mfgPart: "ADPQ-2-250+",
//     models: "this is the new part",
//     pn: "XM-D174-0509D",
//     price: 13300,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "2198_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "DLINC",
//     mfgLandingPage:
//       "https://www.knowlescapacitors.com/Products/Microwave-Products/Power-Dividers",
//     mfgPart: "PDW06984",
//     models: "this is the new part",
//     pn: "XM-C2Y1-0409D",
//     price: 11700,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "2057_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCN-3%2B",
//     mfgPart: "QCN-3+",
//     models: "this is the new part",
//     pn: "XM-D3A8-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "1473_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "SP2",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "MINIC",
//     mfgLandingPage:
//       "https://www.minicircuits.com/WebStore/dashboard.html?model=QCV-151%2B",
//     mfgPart: "QCV-151+",
//     models: "this is the new part",
//     pn: "XM-A9Y1-0409D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Splitters and Combiners",
//     newBRDImage: "2480_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0058",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: "",
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0058I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2m5-0204d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_Straight",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2M5D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2M5-0204D",
//     price: 2400,
//     priceType: "cents",
//     props: [null, 0, 50000, 0.33],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T1",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0058_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0060",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 3,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0060H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2m6-0304d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_Straight",
//     models: "this part is missing models",
//     pn: "XM-A2M6-0304D",
//     price: 2700,
//     priceType: "cents",
//     props: [null, 0, 50000, 0.55],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T2",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0060_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0306",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0306C.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2m7-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_Straight",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2M7D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2M7-0404D",
//     price: 2700,
//     priceType: "cents",
//     props: [null, 0, 50000, 0.51],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T3",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0306_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0061",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 2,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0061H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2m8-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_90deg",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2M8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2M8-0404D",
//     price: 2700,
//     priceType: "cents",
//     props: [null, 0, 50000, 0.53],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T3",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0061_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0059",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 7,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0059H.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2m9-0209d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_U-Turn",
//     models: "this part is missing models",
//     pn: "XM-A2M9-0209D",
//     price: 3000,
//     priceType: "cents",
//     props: [null, 0, 50000, 1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T4",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0059_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0184",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 8,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0184J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a2n1-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_U-Turn",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A2N1D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A2N1-0409D",
//     price: 4200,
//     priceType: "cents",
//     props: [null, 0, 50000, 1],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T9",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0184_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0202",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 4,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0202J.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a316-0604d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_Straight",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A316D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A316-0604D",
//     price: 3400,
//     priceType: "cents",
//     props: [null, 0, 50000, 0.95],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T6",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0202_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0203",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 5,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0203I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a317-0804d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_Straight",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A317D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A317-0804D",
//     price: 3900,
//     priceType: "cents",
//     props: [null, 0, 50000, 1.18],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T9",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0203_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0204",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 6,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0204I.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a318-1004d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_Straight",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A318D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A318-1004D",
//     price: 4400,
//     priceType: "cents",
//     props: [null, 0, 50000, 1.4],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T9",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0204_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0182",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 9,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0182L.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a319-0405d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_90deg",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A319D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A319-0405D",
//     price: 3200,
//     priceType: "cents",
//     props: [null, 0, 50000, 0.77],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T5",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0182_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0181",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 10,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0181M.svg",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-a321-0405d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_90deg",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/A321D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-A321-0405D",
//     price: 3200,
//     priceType: "cents",
//     props: [null, 0, 50000, 0.77],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T5",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0181_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "0577",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 1,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/",
//       },
//     ],
//     landingPage: "https://www.xmicrowave.com/product/xm-c3w8-0404d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_Straight",
//     models: [
//       {
//         dataType: "SNP",
//         dataURL: "/C3W8D.s2p",
//         dataVariant: "",
//       },
//     ],
//     pn: "XM-C3W8-0404D",
//     price: 15800,
//     priceType: "cents",
//     props: [null, 0, 50000, 0.52],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T3",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0577_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1581",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8p6-0205d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-LinePCB",
//     models: "this is the new part",
//     pn: "XM-C8P6-0205D",
//     price: 2500,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "1581_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1470",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8p7-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-LinePCB",
//     models: "this is the new part",
//     pn: "XM-C8P7-0409D",
//     price: 4200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "1470_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1471",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c8p8-0409d/",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-LinePCB",
//     models: "this is the new part",
//     pn: "XM-C8P8-0409D",
//     price: 4200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "1471_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_Straight",
//     models: "this is the new part",
//     pn: "XM-A2M4-0104D",
//     price: 2200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0063_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 13,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/0761A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_Straight",
//     models: "this part is missing models",
//     pn: "XM-B9K3-1404D",
//     price: 5400,
//     priceType: "cents",
//     props: [null, 0, 50000, null],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T9",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "0761_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: 14,
//     filtermask: 3,
//     images: [
//       {
//         imageType: "BRD",
//         imageURL: "/svg/brd/1293A.svg",
//       },
//     ],
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-Line_Straight",
//     models: "this part is missing models",
//     pn: "XM-C6H4-0504D",
//     price: 3200,
//     priceType: "cents",
//     props: [null, 0, 67000, 0.52],
//     quote: "",
//     sparamLinks: [],
//     testCode: "T5",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "1293_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: "TML",
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "F=DC-50G",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: {},
//     mfgLandingPage: "Wrong URL",
//     mfgPart: "T-LinePCB",
//     models: "this is the new part",
//     pn: "XM-D3G4-0224D",
//     price: 8000,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Transmission Lines",
//     newBRDImage: "2505_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "1517",
//     catId: null,
//     ctlBoard: "XM-C451-0809D",
//     datasheetURL: "https://www.qorvo.com/products/d/da006463",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "https://www.xmicrowave.com/product/xm-c449-0809d/",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPM1002",
//     mfgPart: "QPM1002SMT",
//     models: "this is the new part",
//     pn: "XM-C449-0809D",
//     price: 43200,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Transmit-Receive with Switch",
//     newBRDImage: "1517_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "ADINC",
//     mfgLandingPage: "https://www.analog.com/en/products/adtr1107.html",
//     mfgPart: "ADTR1107",
//     models: "this is the new part",
//     pn: "XM-C8K9-0809D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Transmit-Receive with Switch",
//     newBRDImage: "1524_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPM2637",
//     mfgPart: "QPM2637",
//     models: "this is the new part",
//     pn: "XM-D1G1-0809D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Transmit-Receive with Switch",
//     newBRDImage: "2246_WEB.svg",
//   },
//   {
//     assyDwg: "",
//     bn: "",
//     catId: null,
//     ctlBoard: "TBD",
//     datasheetURL: "",
//     description: "",
//     eccn: "",
//     ffNum: "",
//     filtermask: 0,
//     images: "image array to be calculated",
//     landingPage: "Missing URL",
//     mfgCode: "QORVO",
//     mfgLandingPage: "https://www.qorvo.com/products/p/QPF5010",
//     mfgPart: "QPF5010",
//     models: "this is the new part",
//     pn: "XM-D351-0809D",
//     price: 0,
//     priceType: "cents",
//     props: "this is the new part",
//     quote: "",
//     sparamLinks: [],
//     testCode: "",
//     newCategoryDescription: "Transmit-Receive with Switch",
//     newBRDImage: "2510_WEB.svg",
//   },
// ];
// export default fetchParts;
