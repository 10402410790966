import "./App.css";
import config from "./config/config";
import React, { useState, useEffect } from "react";
// import fetchParts from "./config/parts";
import { fetchParts } from "./config/parts";
// import fetchFormFactor from './config/formFactors';
import { fetchFormFactor } from "./config/formFactors";
import IframeDisplay from "./components/Iframe";
import HeaderContainer from "./components/HeaderContainer";
import PartsTable from "./components/PartsTable";
import KonvaContainer from "./components/KonvaContainer";
import calculateSize from "./utils/sizeCalc";
import FormFactorEditor from "./components/FormFactorEditor";

function App() {
  const [selectedCatId, setSelectedCatId] = useState("AMP");
  const [items, setItems] = useState([]);
  const [parts, setParts] = useState([]);
  const [formFactor, setFormFactor] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({
    widthPx: 0,
    heightPx: 0,
  });
  // const [dimensions, setDimensions] = useState({
  //   widthUnits: 0,
  //   heightUnits: 0,
  // });

  const fetchPartsData = async () =>{
    setParts(await fetchParts());
  }

  useEffect(() => {
    // setParts(fetchParts);
    fetchPartsData()
    // setFormFactor(fetchFormFactor);
    // fetchFormFactorData()
  }, []);

  useEffect(() => {
     console.log({parts});
     const filteredItems = parts.filter((item) => item.catId === selectedCatId);
     setItems(filteredItems);
  }, [selectedCatId, parts]);

  useEffect(() => {
    if (selectedPart) {
      const { widthPx, heightPx, widthUnits, heightUnits } = calculateSize(
        selectedPart.pn
      );
      setImageDimensions({ widthPx, heightPx });
      // setDimensions({ widthUnits, heightUnits });
    }
  }, [selectedPart]);

  const handleSelectCategory = (catId) => {
    setSelectedCatId(catId);
  };

  const handleRowSelect = async (partNumber) => {
    const part = parts.find((p) => p.pn === partNumber);
    console.log(part);
    setSelectedPart(part);
    const result = await fetchFormFactor(part);
    console.log(result);
    setFormFactor(result);
  };

  // const fetchFormFactorData = useCallback(async () => {
  //   if (selectedPart) {
  //     console.log(selectedPart);
  //     const result = await fetchFormFactor(selectedPart);
  //     console.log(result);
  //     setFormFactor(result);
  //   }
  // }, [selectedPart]);
  
  // useEffect(() => {
  //   fetchFormFactorData();
  // }, [fetchFormFactorData]);

  const handleFormFactorUpdate = (updatedPart) => {
    console.log("Updated Part:", updatedPart);
  };

  return (
    <div className="app">
      <HeaderContainer onSelect={handleSelectCategory} />
      <div className="main-content">
        <div className="left-container">
          <PartsTable items={items} onRowSelect={handleRowSelect} />
          {selectedPart && formFactor && (
            <FormFactorEditor
              // className="form-factor-editor"
              selectedPart={selectedPart}
              formFactor={formFactor}
              updateFormFactor={handleFormFactorUpdate} />
          )}
        </div>
        {selectedPart && (
          <div className="right-container">
            <div className="grid-container">
              <KonvaContainer
                imageUrl={
                  selectedPart && selectedPart.images && selectedPart.images[0]
                    ? `${config.BASE_IMAGE_URL}${selectedPart.images[0].imageURL}`
                    : 'logo512.png'
                }
                width={imageDimensions.widthPx}
                height={imageDimensions.heightPx} />
              <KonvaContainer
                imageUrl={
                  formFactor && formFactor.images && formFactor.images[0]
                    ? `${config.BASE_IMAGE_URL}${formFactor.images[0].imageURL}`
                    : 'logo512.png'
                }
                width={formFactor.w*26.5}
                height={formFactor.h*26.5} />
            </div>
            {selectedPart.landingPage!=="Missing URL" && (
              <IframeDisplay landingPage={selectedPart.landingPage}/>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
