import React from "react";
import categories from "../config/categories";
import config from "../config/config";

const HeaderContainer = ({ onSelect }) => {
  const handleClick = (catId) => {
    console.log(`Category selected: ${catId}`);
    onSelect(catId); // Pass the category ID to the parent component
  };

  return (
    <div className="header">
      {Object.entries(categories).map(([key, value]) => (
        <button
          key={key}
          title={value.desc}
          onClick={() => handleClick(key)} // Pass the key (category ID)
        >
          <img
            src={`${config.BASE_IMAGE_URL}${value.imgURL}`}
            alt={value.desc}
            className="img-small"
          />
        </button>
      ))}
    </div>
  );
};

export default HeaderContainer;
