const categories = {
  "50O": {
    desc: "Load",
    imgURL: "/svg/xLoad.svg",
  },
  AMP: {
    desc: "Amplifier",
    imgURL: "/svg/xAmp.svg",
  },
  BLN: {
    desc: "Transformer",
    imgURL: "/svg/xBalun.svg",

    shortName: "Balun",
  },
  BPF: {
    desc: "Band Pass Filter",
    imgURL: "/svg/xBPF.svg",
  },
  BPT: {
    desc: "Band Pass Tunable Filter",
    imgURL: "/svg/TunableFilter.svg",
  },
  BSF: {
    desc: "Band Stop Filter",
    imgURL: "/svg/xBSF.svg",
  },
  BTE: {
    desc: "Bias Tee",
    imgURL: "/svg/xBiasTee.svg",
  },
  CIR: {
    desc: "Circulator",
    imgURL: "/svg/xCIR.svg",
  },
  CMP: {
    desc: "Comparator",
    imgURL: "/svg/xCMP.svg",
  },
  CPL: {
    desc: "Coupler",
    imgURL: "/svg/xDC.svg",
  },
  CTL: {
    desc: "Active Bias Controller",
    imgURL: "/svg/xCTL.svg",
  },
  DIO: {
    desc: "Diode",
    imgURL: "/svg/xDIO.svg",
  },
  DIV: {
    desc: "Divider",
    imgURL: "/svg/xDiv.svg",
  },
  DMD: {
    desc: "Demodulator",
    imgURL: "/svg/xDemod.svg",
  },
  DNC: {
    desc: "Downconverter",
    imgURL: "/svg/xDNC.svg",
  },
  DPX: {
    desc: "Diplexer",
    imgURL: "/svg/xDiplexer.svg",
  },
  DSA: {
    desc: "Digital Step Attenuator",
    imgURL: "/svg/xDSA.svg",
  },
  DTC: {
    desc: "Detector",
    imgURL: "/svg/xDTC.svg",
  },
  EQL: {
    desc: "Equalizer",
    imgURL: "/svg/xEQL.svg",
  },
  HOU: {
    desc: "Housing",
    imgURL: "/svg/Housing_0404.svg",
  },
  HPF: {
    desc: "High Pass Filter",
    imgURL: "/svg/xHPF.svg",
  },

  LPF: {
    desc: "Low Pass Filter",
    imgURL: "/svg/xLPF.svg",
  },
  MOD: {
    desc: "Modulator",
    imgURL: "/svg/xMod.svg",
  },
  MUL: {
    desc: "Multiplier",
    imgURL: "/svg/xMult.svg",
  },
  MXR: {
    desc: "Mixer",
    imgURL: "/svg/xMXR.svg",
  },
  OSC: {
    desc: "Oscillator",
    imgURL: "/svg/xOSC.svg",
  },
  PAC: {
    desc: "Phased Array Controller",
    imgURL: "/svg/xPAC.svg",
  },
  PAD: {
    desc: "Attenuator",
    imgURL: "/svg/xPad.svg",
  },
  PLL: {
    desc: "PLL",
    imgURL: "/svg/xPLL.svg",
  },
  PLM: {
    desc: "Limiter",
    imgURL: "/svg/xPLM.svg",
  },
  PSH: {
    desc: "Phase Shifter",
    imgURL: "/svg/xPSH.svg",
  },
  SP2: {
    desc: "Splitter",
    imgURL: "/svg/xPS.svg",
  },
  SW2: {
    desc: "Switch",
    imgURL: "/svg/xSwitch.svg",
  },
  SYN: {
    desc: "PLL-VCO",
    imgURL: "/svg/xSYN.svg",
  },
  TML: {
    desc: "T-Line",
    imgURL: "/svg/xTML.svg",
  },
  TVA: {
    desc: "TVA",
    imgURL: "/svg/xTVA.svg",
  },
  UPC: {
    desc: "Upconverter",
    imgURL: "/svg/xUPC.svg",
  },
  VCO: {
    desc: "VCO",
    imgURL: "/svg/xVCO.svg",
  },
  VRG: {
    desc: "Voltage Regulator",
    imgURL: "/svg/xVRG.svg",
  },
  VVA: {
    desc: "Voltage Variable Attenuator VVA",
    imgURL: "/svg/xVVA.svg",
  },
};

export default categories;
