// import React, { useState } from "react";
// import Modal from "react-modal";

// const AddPortForm = ({ isOpen, onRequestClose, onSave }) => {
//   const [tag, setTag] = useState("");
//   const [type, setType] = useState("");
//   const [x, setX] = useState(0);
//   const [y, setY] = useState(0);

//   const handleSubmit = () => {

//     const newPort = { tag, type, x, y };

//     onSave(newPort);

//     onRequestClose();
//   };

//   return (
//     <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal-content" overlayClassName="modal-overlay">
//       <div className="add-port-form">
//         <table className="form-table">
//           <tr>
//             <td>
//               <label className="tagNameModal">
//                 Tag:
//               </label>
//             </td>
//             <td><input type="text" className="form-table-elements" value={tag} onChange={(e) => setTag(e.target.value)} /></td>
//           </tr>
//           <tr>
//             <td>
//               <label className="typeNameModal">
//                 Type:
//               </label>
//             </td>
//             <td><input type="text" className="form-table-elements" value={type} onChange={(e) => setType(e.target.value)} /></td>
//           </tr>
//           <tr>
//             <td>
//               <label className="xcoordinateModal">
//                 X Coordinate:
//               </label>
//             </td>
//             <td><input type="number" className="form-table-elements" value={x} onChange={(e) => setX(e.target.value)} /></td>
//           </tr>
//           <tr>
//             <td>
//               <label className="ycoordinateModal">
//                 Y Coordinate:
//               </label>
//             </td>
//             <td><input type="number" className="form-table-elements" value={y} onChange={(e) => setY(e.target.value)} /></td>
//           </tr>
//           <tr>
//         <td colSpan="2" style={{ textAlign: "center" }}>
//           <button className="saveModalBtn" onClick={handleSubmit}>Save</button>
//           <button className="cancelModalBtn" onClick={onRequestClose}>Cancel</button>
//         </td>
//       </tr>
//         </table>
//       </div>
//     </Modal>
//   );
// };

// export default AddPortForm;

import React, { useState, useEffect } from "react";
import Modal from "react-modal";

const AddPortForm = ({ isOpen, onRequestClose, onSave, port, addOrEdit }) => {
  const [tag, setTag] = useState("");
  const [type, setType] = useState("");
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    if (addOrEdit) {
      setTag("");
      setType("");
      setX(0);
      setY(0);
    } else {
      setTag(port.tag);
      setType(port.type);
      setX(port.x);
      setY(port.y);
    }
  }, [ addOrEdit, port]);

  const handleSubmit = () => {
    const newPort = { tag, type, x, y };
    const portt = port;
    onSave(newPort, portt);
    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal-content" overlayClassName="modal-overlay">
      <div className="add-port-form">
        <table className="form-table">
          <tr>
            <td>
              <label className="tagNameModal">Tag:</label>
            </td>
            <td><input type="text" className="form-table-elements" value={tag} onChange={(e) => setTag(e.target.value)} /></td>
          </tr>
          <tr>
            <td>
              <label className="typeNameModal">Type:</label>
            </td>
            <td><input type="text" className="form-table-elements" value={type} onChange={(e) => setType(e.target.value)} /></td>
          </tr>
          <tr>
            <td>
              <label className="xcoordinateModal">X Coordinate:</label>
            </td>
            <td><input type="number" className="form-table-elements" value={x} onChange={(e) => setX(e.target.value)} /></td>
          </tr>
          <tr>
            <td>
              <label className="ycoordinateModal">Y Coordinate:</label>
            </td>
            <td><input type="number" className="form-table-elements" value={y} onChange={(e) => setY(e.target.value)} /></td>
          </tr>
          <tr>
            <td colSpan="2" style={{ textAlign: "center" }}>
              <button className="saveModalBtn" onClick={handleSubmit}>Save</button>
              <button className="cancelModalBtn" onClick={onRequestClose}>Cancel</button>
            </td>
          </tr>
        </table>
      </div>
    </Modal>
  );
};

export default AddPortForm;
