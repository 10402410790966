import React from 'react';

const IframeDisplay = ({ landingPage }) => {
  return (
    <div>
      <iframe
        title="Product Display"
        src={landingPage}
        width="100%"
        height="600"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default IframeDisplay;
