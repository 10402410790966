import React, { useState } from "react";
import "./PartsTable.css";

const PartsTable = ({ items, onRowSelect }) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleRowClick = (index, partNumber) => {
    setSelectedRowIndex(index);
    onRowSelect(partNumber);
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr className="tableNames">
            <th>Part Number</th>
            <th>FF Num</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody className="tableContent">
          {items.map((item, index) => (
            <tr
              key={item.pn}
              className={selectedRowIndex === index ? "selected-row" : ""}
              onClick={() => handleRowClick(index, item.pn)}
            >
              <td>{item.pn}</td>
              <td>{item.ffNum}</td>
              <td>
                {/* Check if 'images' array exists and has items before rendering */}
                {item.images && item.images.length > 0 && item.images[0].imageURL}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartsTable;
