// import React, { useRef, useEffect } from "react";
// import Konva from "konva";

// const KonvaContainer = ({ imageUrl, hybridImageUrl, width, height }) => {
//   const containerRef = useRef();

//   useEffect(() => {
//     const stage = new Konva.Stage({
//       container: containerRef.current,
//       width: width,
//       height: height,
//     });

//     const layer = new Konva.Layer();
//     stage.add(layer);

//     // Load and display the main image
//     const imageObj = new Image();
//     imageObj.onload = () => {
//       const konvaImage = new Konva.Image({
//         image: imageObj,
//         x: 0,
//         y: 0,
//         width: width,
//         height: height,
//       });
//       layer.add(konvaImage);
//       layer.batchDraw();
//     };
//     imageObj.src = imageUrl;

//     // Load and display the hybrid image if it exists
//     if (hybridImageUrl) {
//       const hybridImageObj = new Image();
//       hybridImageObj.onload = () => {
//         const konvaHybridImage = new Konva.Image({
//           image: hybridImageObj,
//           x: 0, // adjust if necessary
//           y: 0, // adjust if necessary
//           width: width, // adjust if necessary
//           height: height, // adjust if necessary
//           // You may want to scale the hybrid image or position it differently
//         });
//         layer.add(konvaHybridImage);
//         layer.batchDraw();
//       };
//       hybridImageObj.src = hybridImageUrl;
//     }

//     return () => stage.destroy();
//   }, [imageUrl, hybridImageUrl, width, height]);

//   return <div ref={containerRef} />;
// };

// export default KonvaContainer;

import React, { useRef, useEffect } from "react";
import Konva from "konva";

const KonvaContainer = ({ imageUrl, width, height }) => {
  const containerRef = useRef();

  useEffect(() => {
    const stage = new Konva.Stage({
      container: containerRef.current,
      width: width,
      height: height,
    });

    const layer = new Konva.Layer();
    stage.add(layer);

    const loadAndDisplayImage = (url) => {
      const imageObj = new Image();
      imageObj.onload = () => {
        const konvaImage = new Konva.Image({
          image: imageObj,
          x: 0,
          y: 0,
          width: width,
          height: height,
        });
        layer.add(konvaImage);
        layer.batchDraw();
      };
      imageObj.onerror = () => {
        console.error("Failed to load image:", url);
      };
      imageObj.src = url;
    };

    loadAndDisplayImage(imageUrl);

    return () => stage.destroy();
  }, [imageUrl, width, height]);

  return <div ref={containerRef} />;
};

export default KonvaContainer;


