export async function fetchFormFactor(selectedPart) {
  try {
    // const url = `https://layout2d-api-dev.zipcpq.com/api/v1/objects/parts/${partNumber}`;
    const url = 'https://layout2d-api-dev.zipcpq.com/api/v1/formfactors/get';
    const data = { ffId: selectedPart.ffId };

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if required
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    console.log('Response Data:', responseData.h);
    return responseData; // Array of objects
  } catch (error) {
    console.error('Error:', error);
    return {}; // Return an empty array in case of an error
  }
}

// export async function fetchFormFactor(partNumber) {
//     try {
//       const postData = {
//         ffId: `${partNumber.ffId}`
//       };
      
//       // Define the options for the fetch call
//       const fetchOptions = {
//         method: 'POST', // or 'PUT' if your server is set up to use PUT for updates
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(postData), // convert your JavaScript object to a JSON string
//       };
      
//       // Make the fetch call
//       fetch('http://localhost:3333/api/v1/formfactors', fetchOptions)
//         .then(response => {
//           if (!response.ok) {
//             throw new Error('Network response was not ok ' + response.statusText);
//           }
//           return response.json(); // or `response.text()` if the server sends non-JSON data
//         })
//         .then(data => {
//           console.log('Success:', data);
//           // Do something with the data
//         })
//         .catch((error) => {
//           console.error('Error:', error);
//         });}
//         catch (error) {
//           //     console.error('Error:', error);
//           //     return {}; // Return an empty array in case of an error
//           //   }
//   }
// }

// const fetchFormFactor={
//   "_id": {
//     "$oid": "658284e3cff88e1965183121"
//   },
//   "catId": "50O",
//   "h": 4,
//   "w": 2,
//   "images": [
//     {
//       "imageType": "HYB",
//       "imageURL": "/svg/hyb/TERM_0204.svg"
//     },
//     {
//       "imageType": "CFG",
//       "imageURL": "/svg/cfg/TERM_0204_PUR.svg"
//     }
//   ],
//   "ports": [
//     {
//       "dat": 0,
//       "pUnit": "XUN",
//       "tag": "",
//       "type": "INP",
//       "x": 0,
//       "y": 2
//     }
//   ],
//   "szUnit": "XUN",
//   "tag": "",
//   "ffNum": 0,
//   "ffId": "50O_0204_0",
//   "approved": true
// }

// export default fetchFormFactor;
