// import React, { useState, useEffect } from "react";
// import AddPortForm from "./AddPortForm";

// const FormFactorEditor = ({ formFactor, selectedPart }) => {
//   const [ports, setPorts] = useState([]);
//   const [isAddPortFormOpen, setIsAddPortFormOpen] = useState(false);

//   useEffect(() => {
//     if (formFactor && formFactor.ports) {
//       setPorts(formFactor.ports);
//     }
//   }, [formFactor]);

//   const openAddPortForm = () => {
//     setIsAddPortFormOpen(true);
//   };

//   const closeAddPortForm = () => {
//     setIsAddPortFormOpen(false);
//   };

//   const handleSavePort = (newPort) => {
//     setPorts([...ports, newPort]);
//     closeAddPortForm();
//   };

  // const submitBtn = async () => {
  //   console.log('Sending PATCH request...');
  //   try {
  //       const response = await fetch(`https://layout2d-api-dev.zipcpq.com/api/v1/objects/parts/${selectedPart.pn}`, {
  //           method: 'PATCH', 
  //           headers: {
  //               'Content-Type': 'application/json' 
  //           },
  //           body: JSON.stringify({ approved: true }) 
  //       });
  //       console.log(`Response Status: ${response.status} ${response.statusText}`);
  //       if (!response.ok) {
  //           throw new Error(`Error: ${response.status}`);
  //       }
  //       const data = await response.json();
  //       console.log('Response Data:', data);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

//   return (
//     <div className="form-factor-editor">
//       {formFactor && (
//       <div className="input-group">
//         <table>
//           <tbody className="tableInfo">
//             <tr>
//               <th>Height: {formFactor.h}</th>
//               <th>Width: {formFactor.w}</th>
//             </tr>
//             {formFactor.ports && formFactor.ports.map((port, index) => (
//               <tr key={index}>
//                 <td>Port {index + 1}</td>
//                 <td>Tag: {port.tag}</td>
//                 <td>Type: {port.type}</td>
//                 <td>X Coordinate: {port.x}</td>
//                 <td>Y Coordinate: {port.y}</td>
//               </tr>
//             ))}
//             <tr>
//               <td>
//                 <AddPortForm
//                   isOpen={isAddPortFormOpen}
//                   onRequestClose={closeAddPortForm}
//                   onSave={handleSavePort}
//                 />
//               </td>
//             </tr>
//           </tbody>
//         </table>
//         <button onClick={openAddPortForm} className="portBtn">
//           Add Port
//         </button>
//         <button onClick={submitBtn} className="portBtn">Submit</button>
//       </div>
//       )};
//     </div>
//   );
// };

// export default FormFactorEditor;


// import React, { useState, useEffect } from "react";
// import AddPortForm from "./AddPortForm";

// const FormFactorEditor = ({ formFactor }) => {
//   const initialPorts = Array.isArray(formFactor?.ports) ? formFactor.ports : [];
//   const [ports, setPorts] = useState(initialPorts);
//   const [isAddPortFormOpen, setIsAddPortFormOpen] = useState(false);
//   const [submitSuccess, setSubmitSuccess] = useState(false);
//   const [isEditing, setIsEditing] = useState(false);
//   const [editedPortData, setEditedPortData] = useState({});
//   const [formFactorCopy, setFormFactorCopy] = useState({});
//   const [isAdd, setIsAdd] = useState(false); // New state for recognizing add/edit

//   useEffect(() => {
//     if (formFactor && Array.isArray(formFactor.ports)) {
//       setPorts(formFactor.ports);
//       setFormFactorCopy({ ...formFactor, ports: [...formFactor.ports] });
//     }
//   }, [formFactor]);

//   const openAddPortForm = (portData = null) => {
//     if (portData) {
//       setIsEditing(true);
//       setEditedPortData(portData);
//       setIsAddPortFormOpen(true);
//     } else {
//       setIsEditing(false);
//       setEditedPortData({});
//       setIsAddPortFormOpen(true);
//       setIsAdd(true); // Set isAdd to true when adding a new port
//     }
//   };

//   const closeAddPortForm = () => {
//     setIsAddPortFormOpen(false);
//   };

//   const handleSavePort = (editedPort) => {
//     let updatedPorts;
//     if (!isEditing) {
//       setIsEditing(true);
//       updatedPorts = [...ports, editedPort];
//     } else {
//       updatedPorts = ports.map((port) => (port === editedPortData ? editedPort : port));
//       setEditedPortData({});
//     }

//     setPorts(updatedPorts);

//     const updatedFormFactor = { ...formFactor, ports: updatedPorts };
//     setFormFactorCopy({ ...updatedFormFactor });
//     console.log('FormFactorCopy after update:', formFactorCopy); // Log updated formFactorCopy
//     closeAddPortForm();
//   };

//   const showSubmitSuccess = () => {
//     setSubmitSuccess(true);
//     setTimeout(() => {
//       setSubmitSuccess(false);
//     }, 3000);
//   };

//   const submitBtn = () => {
//     showSubmitSuccess();
//     // Implement logic for submission
//   };

//   return (
//     <div className="form-factor-editor">
//       {formFactor.ports && formFactorCopy.ports && (
//         <div className="input-group">
//           <table>
//             <thead>
//               <tr>
//                 <td>Width: {formFactor.w}</td>
//                 <td>Height: {formFactor.h}</td>
//               </tr>
//             </thead>
//             <tbody className="tableInfo">
//               {(isEditing ? formFactorCopy.ports : formFactor.ports).map(
//                 (port, index) => (
//                   <tr key={index}>
//                     <td>Port {index + 1}</td>
//                     <td>Tag: {port.tag}</td>
//                     <td>Type: {port.type}</td>
//                     <td>X Coordinate: {port.x}</td>
//                     <td>Y Coordinate: {port.y}</td>
//                     <td>
//                       <button onClick={() => openAddPortForm(port)}>
//                         EDIT
//                       </button>
//                     </td>
//                   </tr>
//                 )
//               )}
//             </tbody>
//             <tfoot>
//               <tr>
//                 <td>
//                   <AddPortForm
//                     isOpen={isAddPortFormOpen}
//                     onRequestClose={closeAddPortForm}
//                     onSave={handleSavePort}
//                     isEditing={isEditing}
//                     editPort={editedPortData}
//                     isAdd={isAdd} // Pass isAdd as a prop to AddPortForm
//                   />
//                 </td>
//               </tr>
//             </tfoot>
//           </table>
//           <button onClick={openAddPortForm} className="portBtn">
//             Add Port
//           </button>
//           <button onClick={submitBtn} className="portBtn">
//             Submit
//           </button>
//           {submitSuccess && (
//             <div className="submit-success-popup">
//               <p>Submitted successfully!</p>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default FormFactorEditor;

import React, { useState, useEffect } from "react";
import AddPortForm from "./AddPortForm";

const FormFactorEditor = ({ formFactor }) => {
  // const initialPorts = Array.isArray(formFactor?.ports) ? formFactor.ports : [];
  // const [ports, setPorts] = useState();
  const [isAddPortFormOpen, setIsAddPortFormOpen] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedPortData, setEditedPortData] = useState({});
  const [formFactorCopy, setFormFactorCopy] = useState({});
  const [addOrEdit, setAddOrEdit] = useState({});

  useEffect(() => {
    if (formFactor && Array.isArray(formFactor.ports)) {
      setFormFactorCopy({ ...formFactor });
      // setPorts(formFactor.ports);
      // setFormFactorCopy({ ...formFactor, ports: [...formFactor.ports] });
    }
  }, [formFactor]);

  const openAddPortForm = (portData = null) => {
    setAddOrEdit(true);
    setIsAddPortFormOpen(true);
  };

  const editPort = (port = null) => {
    setAddOrEdit(false);
    setIsAddPortFormOpen(true);
    setEditedPortData(port);
  };

  const closeAddPortForm = () => {
    setIsAddPortFormOpen(false);
  };

  const handleSavePort = (editedPortData, portt) => {
    if (!isEditing) {
      setIsEditing(true);
    }
    if(addOrEdit) {
      formFactorCopy.ports.push(editedPortData);
    }else {
      formFactorCopy.ports.forEach(port => {
        if (port === portt) {
          port.tag = editedPortData.tag;
          port.type = editedPortData.type;
          port.x = editedPortData.x;
          port.y = editedPortData.y;
        }
      });      
    }
    console.log(formFactorCopy.ports);
    closeAddPortForm();
    // let updatedPorts;
    // if (!isEditing) {
    //   setIsEditing(true);
    //   updatedPorts = [...ports, editedPort];
    // } else {
    //   updatedPorts = ports.map((port) => (port === editedPortData ? editedPort : port));
    //   setEditedPortData({});
    // }
    // setPorts(updatedPorts);
    // const updatedFormFactor = { ...formFactor, ports: updatedPorts };
    // setFormFactorCopy({ ...updatedFormFactor });
    // closeAddPortForm();
  };

  const showSubmitSuccess = () => {
    setSubmitSuccess(true);
    setTimeout(() => {
      setSubmitSuccess(false);
    }, 3000);
  };

  const submitBtn = () => {
    showSubmitSuccess();
  };

  return (
    <div className="form-factor-editor">
      {formFactor.ports && formFactorCopy.ports && (
        <div className="input-group">
          <table>
            <thead>
              <tr>
                <td>Width: {formFactor.w}</td>
                <td>Height: {formFactor.h}</td>
              </tr>
            </thead>
            <tbody className="tableInfo">
              {(!isEditing ? formFactor.ports : formFactorCopy.ports).map(
                (port, index) => (
                  <tr key={index}>
                    <td>Port {index + 1}</td>
                    <td>Tag: {port.tag}</td>
                    <td>Type: {port.type}</td>
                    <td>X Coordinate: {port.x}</td>
                    <td>Y Coordinate: {port.y}</td>
                    <td>
                      <button onClick={() => editPort(port)}>
                        EDIT
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <AddPortForm
                    isOpen={isAddPortFormOpen}
                    onRequestClose={closeAddPortForm}
                    onSave={handleSavePort}
                    port={editedPortData}
                    addOrEdit={addOrEdit}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
          <button onClick={openAddPortForm} className="portBtn">
            Add Port
          </button>
          <button onClick={submitBtn} className="portBtn">
            Submit
          </button>
          {submitSuccess && (
            <div className="submit-success-popup">
              <p>Submitted successfully!</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FormFactorEditor;
