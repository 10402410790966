// sizeCalc.js

const unitSizeInch = 0.135; // Size of one unit in inches
const zoomFactor = 2; // Zoom factor for visibility on the screen

const calculateSize = (partNumber) => {
  const secondDashIndex = partNumber.indexOf("-", partNumber.indexOf("-") + 1);
  const widthUnits = parseInt(
    partNumber.substring(secondDashIndex + 1, secondDashIndex + 3),
    10
  );
  const heightUnits = parseInt(
    partNumber.substring(secondDashIndex + 3, secondDashIndex + 5),
    10
  );

  const widthInch = widthUnits * unitSizeInch * zoomFactor;
  const heightInch = heightUnits * unitSizeInch * zoomFactor;
  console.log(widthInch, heightInch);
  const ppi = 96; // Pixels per inch
  const widthPx = Math.round(widthInch * ppi);
  const heightPx = Math.round(heightInch * ppi);

  return {
    widthPx,
    heightPx,
    widthUnits, // Added width in units
    heightUnits, // Added height in units
  };
};

export default calculateSize;
