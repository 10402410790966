// src/config/config.js
const config = {
  BASE_API_URL: "https://api.example.com",
  DEFAULT_TIMEOUT: 5000,
  //BASE_IMAGE_URL: "https://xmicro-assets.s3.amazonaws.com/cascade",
  BASE_IMAGE_URL:
    "https://xmicro-assets-stg.s3.us-west-2.amazonaws.com/cascade",
  // ... other small config variables https://xmicro-assets-stg.s3.us-west-2.amazonaws.com/cascade/img/brd/0001G.svg
};

export default config;
